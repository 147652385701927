import { showForFWTooling } from '../helpers/envServer';
import lineSummaryReportFieldMapping from './fieldMappings/lineSummaryReportFieldMapping';
import lineSummaryReportFieldProperties from './fieldProperties/lineSummaryReportFieldProperties';

const lsFieldProps = { ...lineSummaryReportFieldProperties };
const lsFieldMapping = { ...lineSummaryReportFieldMapping };
const lineSummaryReportFields = [
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GEOGRAPHY_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PONUMBER],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_LINE_NUMBER],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_TR_CO_PONUMBER],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_VENDOR],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DIVISION_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PRODUCT_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_ITEM_QTY],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GAC_REASON_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GRC_USECASE_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_SEASON_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_SEASON_YEAR],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_LAUNCH],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_MIDSOLE_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_OUTSOLE_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PROMO_ONLY_INDICATOR],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_ORDER_REASON_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_STYLE_NUMBER],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CURRENT_EVENT],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_NEXT_EVENT],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CUSTOMER_PO],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DOC_TYPE_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PURCHASE_ORG_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PURCHASE_GROUP_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_COLOR_DESC],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CREATED_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DOC_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CHANGED_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_OGAC],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GAC],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_LAUNCH_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CURRENT_EVENT_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_NEXT_EVENT_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_ACCEPT_PO_ACTUAL_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_FACTORY_DELIVERY_ACTUAL_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_EX_FACTORY_ACTUAL_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_MRGAC],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_FX_ADJ_REQUIRED_INDICATOR],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PLANT_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_FOB_MISMATCH_INDICATOR],
  (showForFWTooling) && lsFieldProps[
    lsFieldMapping.LINE_SUMMARY_TOOLING_MISMATCH_INDICATOR
  ],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_SIZE_MISMATCH_ID],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CUSTOMER_REQ_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_VAS_ITEM],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_R3_DOC_TYPE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CREATED_BY],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_TITLE_TRANSFER_MODEL_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_MFG_ORIGIN_COUNTRY_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PO_COMPANY_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_TR_CO_COMPANY_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_ITEM_STATUS],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_UNIT_OF_MEASURE_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DESTINATION_COUNTRY_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_TR_CO_PLANT_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_TRCO_RELEVANT_INDICATOR],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_TRANSPORT_MODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_SHIPPING_TYPE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DIVERT_FLAG],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DIVERTED_FROM],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DIVERTED_TO],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DIRECT_SALES_ORDER_NUMBER],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DIRECT_SALES_ORDER_ITEM_NUMBER],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DELIVERY_COMPLETE_INDICATOR],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CANCEL_DATE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CUST_ISO_COUNTRY_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_SALES_ORDER_NUMBER],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_SALES_ORDER_ITEM_NUMBER],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_ITEM_TEXT_DETAIL],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CATEGORY_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_SUB_CATEGORY_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GLOBAL_CATEGORY_FOCUS_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GLOABL_CATEGORY_SUMMARY_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GENDER_AGE_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_MARKETING_ID],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_SILHOUTTE_TYPE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_DIMENSION_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PRODUCT_REFILL_CLASS_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_LEAGUE_ID_DESC],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PO_REJECTION_CODE],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_OVERDUE_INDICATOR],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_ADDRESS_OVERRIDE_INDICATOR],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_CUSTOMER_SHIP_TO],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GLOBAL_PRODUCT_GROUP],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GLOBAL_PRODUCT_CLASS],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PLANNING_PRIORITY_NUMBER],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_GAC_VS_MRGAC],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_OGAC_VS_MRGAC],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_MRGAC_CAPACITY_CONSUMPTION_WEEK],
  lsFieldProps[lsFieldMapping.LINE_SUMMARY_PMO_DEC_CODE]
].filter((el) => el !== false);
const lineSummaryReportsHeader = lineSummaryReportFields;

export default lineSummaryReportsHeader;
