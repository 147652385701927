const destinationCountryValue = 'Destination Country';

export default {
  poNumber: {
    primary: 'poNumber', secondary: '', type: 'string', link: false, label: 'PO #', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  itemNumber: {
    primary: 'itemNumber', secondary: '', type: 'string', link: false, label: 'PO Item #', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  tradingPoNumber: {
    primary: 'tradingPoNumber', secondary: '', type: 'string', link: false, label: 'Trading PO #', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  profileID: {
    primary: 'profileID', secondary: '', type: 'string', link: false, label: 'Profile ID', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  geo: {
    primary: 'geo', secondary: '', type: 'string', link: false, label: 'GEO', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  plantID: {
    primary: 'plantID', secondary: '', type: 'string', link: false, label: 'Plant ID', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  shipTo: {
    primary: 'shipTo', secondary: '', type: 'string', link: false, label: 'Ship to ID', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  division: {
    primary: 'division', secondary: '', type: 'string', link: false, label: 'Division', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  mode: {
    primary: 'mode', secondary: '', type: 'string', link: false, label: 'MoT', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  buyGroup: {
    primary: 'buyGroup', secondary: '', type: 'string', link: false, label: 'Buy Group', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  ttmi: {
    primary: 'ttmi', secondary: '', type: 'string', link: false, label: 'TTMI', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  destCountry: {
    primary: 'destCountry', secondary: '', type: 'string', link: false, label: destinationCountryValue, wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  originCountry: {
    primary: 'originCountry', secondary: '', type: 'string', link: false, label: 'Origin Country', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
