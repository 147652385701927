const gacReasonUsecasesMock = {
  id: '',
  resourceType: '',
  links: {
    self: ''
  },
  pages: {
    next: 'offset=1000, count=1000',
    prev: '',
    totalPages: 1,
    totalResources: 50
  },
  objects: [
    {
      id: '6c16b3ca-aaea-4ea0-9fad-9d26f54dd28c1234',
      reasonCode: '3',
      reasonCodeDescription: 'N - Deprioritized PO',
      isActive: true,
      modifiedBy: 'dpom.pmo01@nike.com',
      modifiedDate: '2022-01-09T16:56:10.012Z',
      useCaseCode: '3.1',
      divType: '10',
      useCaseDescription: 'Nike directed factory to prioritize production of a different PO'

    },
    {
      id: '6c16b3ca-aaea-4ea0-9fad-9d26f54dd28c123224',
      reasonCode: '3',
      reasonCodeDescription: 'N - Deprioritized PO',
      isActive: false,
      modifiedBy: 'dpom.pmo01@nike.com',
      modifiedDate: '2022-01-09T16:56:10.012Z',
      useCaseCode: '3.2',
      divType: '20',
      useCaseDescription: 'Nike directed factory to prioritize production of a different PO'

    }
  ]
};

export default gacReasonUsecasesMock;
