/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import { poSearchResultActions } from '../poSearch/poSearchResultSlice';
import { poReportActions } from '../poReport/poReportSlice';
import {
  conversationThreadReportActions
} from '../conversationThreadReport/conversationThreadReportSlice';
import {
  gacChangeReportActions
} from '../gacChangeHistoryReport/gacChangeHistoryReportSlice';
import {
  lineSummaryActions
} from '../lineSummaryReport/lineSummaryReportSlice';
import { poChangeReportActions } from '../poChangeReport/poChangeReportSlice';
import {
  ppmReportRequestActions
} from '../ppmReport/ppmReportRequestSlice';
import {
  airFreightReportActions
} from '../airFreightReport/airFreightReportSlice';

const name = 'scheduler';

function createInitialState() {
  return {
    schedulerData: {}
  };
}
export const initialState = createInitialState();

function createReducers() {
  function setSchedulerJob(state: Object, action: Object) {
    return {
      ...state,
      schedulerData: action.payload
    };
  }

  function resetSchedulerData(state: Object) {
    return {
      ...state,
      schedulerData: initialState
    };
  }

  return {
    setSchedulerJob,
    resetSchedulerData
  };
}

export const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function callCreateSchedulerApi() {
    return createAsyncThunk(
      `${name}/callCreateSchedulerApi`,
      async ({
        schedulerData,
        callbackFn
      }, { getState, dispatch }) => {
        const data = {
          largeFileScheduleData: [{ ...schedulerData }]
        };
        dispatch(toggleSpinner(true));
        request({
          method: 'post',
          api: 'createSchedular',
          data,
          suppressErrorDialog: true
        }, dispatch, getState).then((response) => {
          if (callbackFn) {
            callbackFn(response.data);
          }
          const state = getState();
          dispatch(toggleSpinner(false));
          if (schedulerData?.saveSearchID === state.PoSearch.savedSearchId) {
            dispatch(poSearchResultActions.resetPOSearchRequest());
            dispatch(poSearchResultActions.resetPoSearchResult());
            dispatch(poSearchResultActions.resetFiltersToInitialState());
          }
        }).catch((error) => {
          if (callbackFn) {
            callbackFn(null, error);
          }
          dispatch(toggleSpinner(false));
        });
      }
    );
  }

  function callImmediatlySchedulerApi() {
    return createAsyncThunk(
      `${name}/callImmediatlySchedulerApi`,
      async (
        {
          callbackFn,
          exportReport
        }, { dispatch }
      ) => {
        dispatch(exportReport({
          callback: (response) => {
            if (callbackFn) {
              callbackFn(response?.data);
            }
          },
          filetype: 'CSV'
        }));
      }
    );
  }
  function createSchedulerJob() {
    return createAsyncThunk(
      `${name}/createSchedulerJob`,
      async ({
        schedulerData, callbackFn,
        type = 'POSEARCH',
        schedulerCallImmediatlySchedulerApi,
        schedulercallCreateSchedulerApi
      }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        let exportReport;
        if (schedulerData?.isExecuteImmediatly) {
          if (type === 'ITEMSUMMARYREPORT') {
            exportReport = lineSummaryActions.exportPOReport;
            dispatch(schedulerCallImmediatlySchedulerApi({
              callbackFn, exportReport
            }));
          } else if (type === 'CHANGEREPORT') {
            exportReport = poChangeReportActions.exportPoChangeReport;
            dispatch(schedulerCallImmediatlySchedulerApi({
              callbackFn, exportReport
            }));
          } else if (type === 'COLLABREPORT') {
            exportReport = poReportActions.exportPOReport;
            dispatch(schedulerCallImmediatlySchedulerApi({
              callbackFn, exportReport
            }));
          } else if (type === 'GACCHANGEHISTORYREPORT') {
            exportReport = gacChangeReportActions.exportGACChangeHistoryReport;
            dispatch(schedulerCallImmediatlySchedulerApi({
              callbackFn, exportReport
            }));
          } else if (type === 'CONVERSATIONREPORT') {
            exportReport = conversationThreadReportActions.exportConversationThreadReport;
            dispatch(schedulerCallImmediatlySchedulerApi({
              callbackFn, exportReport
            }));
          } else if (type === 'AIRFREIGHTREPORT') {
            exportReport = airFreightReportActions.exportAirFreightReport;
            dispatch(schedulerCallImmediatlySchedulerApi({
              callbackFn, exportReport
            }));
          } else {
            exportReport = ppmReportRequestActions.exportPOReport;
            dispatch(schedulerCallImmediatlySchedulerApi({
              callbackFn, exportReport
            }));
          }
        } else {
          dispatch(schedulercallCreateSchedulerApi({ schedulerData, callbackFn }));
        }
      }
    );
  }
  function updateSchedulerJob() {
    return createAsyncThunk(
      `${name}/updateSchedulerJob`,
      async ({
        schedulerData, callback, type = 'POSEARCH'
      }, { dispatch, getState }) => {
        console.log('type', type);
        dispatch(toggleSpinner(true));
        const data = {
          type: 'update',
          largeFileScheduleData: [{ ...schedulerData }]
        };
        request({
          method: 'put',
          api: 'updateSchedular',
          data,
          suppressErrorDialog: true
        }, dispatch, getState).then((response) => {
          if (callback) {
            callback(response.data);
          }
          const state = getState();
          dispatch(toggleSpinner(false));
          if (schedulerData?.saveSearchID === state.PoSearch.savedSearchId) {
            dispatch(poSearchResultActions.resetPOSearchRequest());
            dispatch(poSearchResultActions.resetPoSearchResult());
            dispatch(poSearchResultActions.resetFiltersToInitialState());
          }
        }).catch((error) => {
          if (callback) {
            callback(null, error);
          }
          dispatch(toggleSpinner(false));
        });
      }
    );
  }

  return {
    callCreateSchedulerApi: callCreateSchedulerApi(),
    updateSchedulerJob: updateSchedulerJob(),
    createSchedulerJob: createSchedulerJob(),
    callImmediatlySchedulerApi: callImmediatlySchedulerApi()
  };
}

export const extraActions = createExtraActions();

export const schedulerActions = { ...actions, ...extraActions };
export const schedulerReducer = slice.reducer;
