export default {
  GAC_REASON_USESCASES_REASON_CODE: 'usecaseGacReasonCode',
  GAC_REASON_USESCASES_REASON_CODE_DESCRIPTION: 'reasonCodeDescription',
  GAC_REASON_USESCASES_DIVISION: 'divType',
  GAC_REASON_USESCASES_CODE: 'useCaseCode',
  GAC_REASON_USESCASES_CODE_DESCRIPTION: 'useCaseDescription',
  GAC_REASON_USESCASES_STATUS: 'isActive',
  GAC_REASON_USESCASES_LAST_UPDATED_BY: 'modifiedBy',
  GAC_REASON_USESCASES_LAST_UPDATED_DATE: 'modifiedDate'
};
