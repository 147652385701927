export default {
  poNumber: {
    primary: 'poNumber', secondary: '', type: 'string', link: false, label: 'PO Number', wrap: true, numeric: 'true', disablePadding: false
  },
  itemNumber: {
    primary: 'itemNumber', secondary: '', type: 'string', link: false, label: 'PO Item Number', wrap: true, numeric: 'true', disablePadding: false
  },
  wipEventCode: {
    primary: 'wipEventCode', secondary: '', type: 'string', link: false, label: 'WIP Event', wrap: true, numeric: 'false', disablePadding: false
  },
  expectedDate: {
    primary: 'expectedDate', secondary: '', type: 'date', link: false, label: 'Expected Date', wrap: true, numeric: 'false', disablePadding: false
  },
  actualDate: {
    primary: 'actualDate', secondary: '', type: 'date', link: false, label: 'Actual Date', wrap: true, numeric: 'false', disablePadding: false
  },
  reasonForFailure: {
    primary: 'reasonForFailure', secondary: '', type: 'string', link: false, label: 'Reason for failure', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
