export default {
  id: '',
  resourceType: '',
  links: {
    self: ''
  },
  pages: {
    next: '',
    prev: '',
    totalPages: 1,
    totalResources: 1
  },
  objects: [
    {
      poHeader: {
        poNumber: '6000000037'
      },
      poLine: {
        itemNumber: '100'
      },
      dpomGacHistoryData: [
        {
          goodsAtConsolidatorChangeTimestamp: '2022-08-16',
          goodsAtConsolidatorDate: '2021-06-07',
          goodsAtConsolidatorReasonCode: '53',
          goodsAtConsolidatorReasonDescription: 'N-GAC Meets OGAC Week',
          delay: '15',
          liability: 'N',
          nikeDelay: '12',
          factoryDelay: '3'
        },
        {
          goodsAtConsolidatorChangeTimestamp: '2022-06-01',
          goodsAtConsolidatorDate: '2021-06-01',
          goodsAtConsolidatorReasonCode: '53',
          goodsAtConsolidatorReasonDescription: 'N-GAC Meets OGAC Week',
          delay: '9',
          liability: 'N',
          nikeDelay: '7',
          factoryDelay: '2'
        }
      ],
      id: '6000000037-100',
      tsUpdate: 1664361692
    }
  ]
};
