export default {
  PO_CHANGE_VENDOR_CODE: 'vendorCode',
  PO_CHANGE_PO_NUMBER: 'poNumber',
  PO_CHANGE_ITEM_NUMBER: 'itemNumber',
  PO_CHANGE_SCHEDULE_LINE_ITEM_NUMBER: 'scheduleLineItemNumber',
  PO_CHANGE_SIZE_DESC: 'sizeDescription',
  PO_CHANGE_CHANGE_DATE: 'changeDate',
  PO_CHANGE_DOC_TYPE_CODE: 'poDocTypeCode',
  PO_CHANGE_DOC_TYPE_DESC: 'poDocumentTypeDescription',
  PO_CHANGE_DOCUMENT_DATE: 'documentDate',
  PO_CHANGE_CHANGED_TYPE: 'changeTypeDescription',
  PO_CHANGE_CHANGED_FROM: 'changedFrom',
  PO_CHANGE_CHANGED_TO: 'changedTo',
  PO_CHANGE_PRODUCT_CODE: 'productCode',
  PO_CHANGE_OGAC_DATE: 'originalGoodsAtConsolidatorDate',
  PO_CHANGE_GAC_DATE: 'goodsAtConsolidatorDate',
  PO_CHANGE_GAC_CODE: 'goodsAtConsolidatorReasonCode',
  PO_CHANGE_CURRENCY_CODE: 'currencyCode',
  PO_CHANGE_CANCEL_REASON: 'cancelReason',
  PO_CHANGE_CANCEL_REASON_DESC: 'cancelReasonDescription',
  PO_CHANGE_TTMI: 'titleTransferModelCode',
  PO_CHANGE_SIZE_QUANTITY: 'sizeQuantity',
  PO_CHANGE_PLANT_CODE: 'plantCode',
  PO_CHANGE_ITEM_TEXT: 'itemText',
  PO_CHANGE_SHIP_TO_NUMBER: 'shipToNumber',
  PO_CHANGE_CUSTOMER_PO: 'customerPoNumber',
  PO_CHANGE_SALES_ORDER_NUMBER: 'directshipSalesOrderNumber',
  PO_CHANGE_SALES_ORDER_ITEM_NUMBER: 'directShipSalesOrderItemNumber',
  PO_CHANGE_SALES_SCHEDULE_ITEM_NUMBER: 'directShipSalesOrderScheduleItemNumber',
  PO_CHANGE_PURCHASE_ORG: 'purchaseOrgCode',
  PO_CHANGE_PURCHASE_ORG_NAME: 'purchaseOrgName',
  PO_CHANGE_PURCHASE_GROUP_CODE: 'purchaseGroupCode',
  PO_CHANGE_PURCHASE_GROUP_NAME: 'purchaseGroupName',
  PO_CHANGE_ACTUAL_DATE: 'acceptPoActualDate',
  PO_CHANGE_MRGAC: 'mrgacDate',
  PO_CHANGE_PLANNING_TARGET_IPD: 'planningTargetIpd',
  PO_CHANGE_PLANNING_PRIORITY_CODE: 'planningPriorityCode',
  PO_CHANGE_PLANNING_PRIORITY_DESC: 'planningPriorityDescription',
  PO_CHANGE_SEASON_CODE: 'planningSeasonCode',
  PO_CHANGE_SEASON_YEAR: 'planningSeasonYear',
  PO_CHANGE_MCO: 'manufacturingCountryOfOrigin',
  PO_CHANGE_OUTSOLE_CODE: 'outsoleCode',
  REPORT_ID: 'id',
  PO_CHANGE_FOB_COST: 'fobCost',
  PO_CHANGE_PO_REASON_CODE: 'poReasonCode',
  PO_CHANGE_PO_REASON_CODE_DESCRIPTION: 'poReasonCodeDescription',
  PO_CHANGE_PO_SHIPPING_INSTRUCTION_CODE: 'poShippingInstructionCode',
  PURCHASING_ACCOUNT_ASSIGNMENT_CATEGORY: 'purchasingAccountAssignmentCategoryCode',
  PO_CHANGE_QUALITY_CODE: 'qualityCode',
  PO_CHANGE_VAS_INDICATOR: 'vasIndicator',
  PO_CHANGE_ITEM_VARIANT_NUMBER: 'itemVariantNumber'
};
