/* @flow */
const setFieldSrcSysNm = (requestBody: Object) => {
  let updatedPayload = requestBody;
  if (requestBody?.fields && !requestBody?.fields.includes('srcSysNm')) {
    updatedPayload = { ...requestBody, fields: [...requestBody.fields, 'srcSysNm'] };
  }
  return updatedPayload;
};

export default setFieldSrcSysNm;
