const destinationCountryValue = 'Destination Country';

export default {
  profileID: {
    primary: 'profileID', secondary: '', type: 'string', link: false, label: 'Profile ID', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  shipToID: {
    primary: 'shipToID', secondary: '', type: 'string', link: false, label: 'Ship To', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  plantID: {
    primary: 'plantID', secondary: '', type: 'string', link: false, label: 'Plant ID', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  division: {
    primary: 'division', secondary: '', type: 'string', link: false, label: 'Division', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  buyGroup: {
    primary: 'buyGroup', secondary: '', type: 'string', link: false, label: 'Buy Group', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  mode: {
    primary: 'mode', secondary: '', type: 'string', link: false, label: 'Mode of Transport', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  ttmi: {
    primary: 'ttmi', secondary: '', type: 'string', link: false, label: 'TTMI', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  'originCountry.code': {
    primary: 'originCountry.code',
    secondary: 'originCountry._@ttribute',
    type: 'string',
    link: false,
    label: 'Country of Origin',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  'destCountry.code': {
    primary: 'destCountry.code',
    secondary: 'destCountry._@ttribute',
    type: 'string',
    link: false,
    label: destinationCountryValue,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  geo: {
    primary: 'geo', secondary: '', type: 'string', link: false, label: 'GEO', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  deliveryInstruction: {
    primary: 'deliveryInstruction', secondary: '', type: 'string', link: false, label: 'Delivery Instructions', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
