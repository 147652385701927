/**
 * This is a functional component which is being
 * used for OKTA_AUTHORISED LOGIN.
 * @author {Cognizant Technology Solution}
 * @flow
 */
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import SessionContext from '../../features/sessionContext/sessionContextSlice';
import DPOMAuth from '../../DPOMAuth';
import imagesPath from '../../constants/imagesPath';
import NikeSpinner from '../../nikeCustomComponents/nikeSpinner';
import NotificationDialog from '../NotificationDialog';
import './styles.scss';
import messageText from '../../constants/messageText';
import { validatePathName } from '../../helpers/dataHelpers';
import { prodOkta, showForFeatureFlag } from '../../helpers/envServer';
import { setAuthKey } from '../../features/auth/authSlice';
import { settingsActions } from '../../features/profileSettings/settingSlice';
import { featureFlagActions } from '../../features/featureFlag/featureFlagSlice';

const App = React.lazy(() => import('../../App'));

export const getAuthKey = (key: string) => (dispatch: Dispatch, getState: Function) => {
  const state = getState();
  try {
    return state.auth[key];
  } catch (e) {
    console.log('Err', e);
  }
  return null;
};

export const setOktaValue = (key: string, value: Object, dispatch: Dispatch) => {
  dispatch(setAuthKey({ [key]: value }));
};

export function WithAuthorization() {
  const dispatch = useDispatch();
  const [redirectUri, query] = window.location.href.split('?');
  const uriWithTrailingSlash = (redirectUri.endsWith('/')) ? redirectUri : `${redirectUri}/`;
  const DPOMAuthClient = new DPOMAuth({
    clientId: process.env.REACT_APP_DPOM_CLIENT_ID,
    prod: prodOkta,
    storage: {
      getItem: (key) => dispatch(getAuthKey(key)),
      setItem: (key, value) => setOktaValue(key, value, dispatch)
    },
    redirectUri: uriWithTrailingSlash
  });

  return () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [email, setEmail] = useState(null);

    /**
      * This function returns the appropriate component for
      * the login status.
      * renders the component passed only if it is logged in,
      * and shows an error dialog on failure. and a spinner on intermediate state.
      */

    const getComponent = () => {
      if (isLoggedIn === null) {
        return (
          <NikeSpinner className="loginSpinner" open={isLoggedIn === null}>
            {messageText.authReq}
          </NikeSpinner>
        );
      }
      if (!isLoggedIn) {
        return (
          <NotificationDialog
            open
            className="loginNotification"
            image={imagesPath.nikeShoeBox}
            title={messageText.authFailed}
            description={messageText.authMsg}
          />
        );
      }
      return (
        <SessionContext.Provider value={email}>
          <Suspense fallback={<div />}>
            <App session={{ email, isLoggedIn }} />
          </Suspense>
        </SessionContext.Provider>
      );
    };

    const userProfileUpdateRefreshTime = () => dispatch(
      settingsActions.userProfileUpdateRefreshTime()
    );

    const lastLogin = () => {
      setInterval(() => {
        userProfileUpdateRefreshTime();
      }, 3600000);
    };

    useEffect(() => {
      const params = new URLSearchParams(query);
      const collabId = params.get('collabid') || 'null';
      const pathName = window.location.pathname;
      const bookmarkId = params.get('savedsearchid') || 'null';
      const validCollabPattern = /^[A-Za-z0-9_-]{10}$/;
      const validBookmarkIdPattern = /^[A-Za-z0-9_-]{36}$/;
      const hasCollabId = validatePathName(pathName, collabId);
      const hasBookmarkId = validatePathName(pathName, bookmarkId);

      if (hasCollabId
        && collabId.match(validCollabPattern)) {
        sessionStorage.setItem('cid', collabId);
      } else if (hasBookmarkId
        && bookmarkId.match(validBookmarkIdPattern)) {
        sessionStorage.setItem('bid', bookmarkId);
      }
      const userProfileUpdateTime = () => dispatch(settingsActions.userProfileUpdateTime());
      const fetchFeatureFlag = () => dispatch(featureFlagActions.fetchProviderData());
      DPOMAuthClient.initialize()
        .then(async () => {
          // Otherwise, check if the app already has a valid token
          // If so, get the id token from storage and set the component state
          const isAlreadyLoggedIn = await DPOMAuthClient.isLoggedIn();
          if (isAlreadyLoggedIn) {
            const idToken = await DPOMAuthClient.getIdToken();
            setIsLoggedIn(true);
            setEmail(idToken.claims.email);
          }
          // dispatch(userProfileUpdateTime());
          userProfileUpdateTime();
          if (showForFeatureFlag) fetchFeatureFlag();
          lastLogin();
        })
        .catch((error) => {
          console.log('Error: ', error);
        });

      if (window.location.search.includes('error')) {
        setIsLoggedIn(false);
      } else if (!window.location.search.includes('code=')) {
        if (isLoggedIn === null) {
          DPOMAuthClient.login();
        }
      }
    }, []);

    return (getComponent());
  };
}

export default function AuthWrapper() {
  const AuthorizedApp = WithAuthorization();
  return (<AuthorizedApp />);
}
