export default {
  id: 'id',
  poCreatedOn: 'poCreatedOn',
  documentDate: 'documentDate',
  originalGoodsAtConsolidatorDate: 'originalGoodsAtConsolidatorDate',
  goodsAtConsolidatorDate: 'goodsAtConsolidatorDate',
  geographyCode: 'geographyCode',
  vendorCode: 'vendorCode',
  poDocTypeCode: 'poDocTypeCode',
  productCode: 'productCode',
  seasonCode: 'seasonCode',
  seasonYear: 'seasonYear',
  purchaseOrgCode: 'purchaseOrgCode',
  categoryCode: 'categoryCode',
  subCategoryCode: 'subCategoryCode',
  productionNikeLiaisonOffice: 'productionNikeLiaisonOffice',
  geo: 'geo',
  division: 'divisionCode',
  material: 'productCode',
  seasonyear: 'seasonyear',
  subCategory: 'subCategory'
};
