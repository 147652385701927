/**
 * This component can be used for all the notification style
 * dialogs.
 * @exports NotificationDialog
 * @author {Cognizant Technology Solution}
 * @flow
 */
import React from 'react';
import NikeButton from '../../nikeCustomComponents/nikeButton';
import NikeDialog from '../../nikeCustomComponents/nikeDialog';
import Message from '../../constants/messageText';
import './styles.scss';

interface Action {
  buttonText: string;
  onClick: Function;
  testid?: string;
}

interface Props {
  image: {
    src: Object;
    alt: string;
  };
  title?: string;
  description?: string;
  actions: Array<Action>;
  open: boolean;
  //  onEscapeKeyDown?: Function;
  //  onBackdropClick?: Function;
  className?: string;
  onClose?: Function;
  renderCustomContent?: Function;
}

export const dialogAction = (setRequestError: Function, errorCodeText: Object) => {
  if (errorCodeText && errorCodeText.errSessionTimeout) {
    return ([{
      buttonText: Message.Refresh,
      onClick: () => { window.location.href = '/'; }
    }]);
  }
  return ([{
    buttonText: Message.Dismiss,
    onClick: () => setRequestError(false)
  }]);
};

export default function NotificationDialog(props: Props) {
  const {
    image,
    title,
    description,
    actions,
    open,
    // onEscapeKeyDown,
    // onBackdropClick,
    className,
    onClose,
    renderCustomContent
  } = props;

  const handleClose = (event, reason) => {
    if (onClose && ((reason === 'escapeKeyDown') || (reason === 'backdropClick'))) {
      onClose(event, reason);
    }
  };
  /**
    * This method is used as handler for rendering
    * the notification dialog content.
    */
  const dialogContent = (
    <>
      {
        image && (
          <div className="imageContainer">
            <img src={image.src} alt={image.alt} />
          </div>
        )
      }
      <div className="textContainer">
        <div className="title">{title}</div>
        <span className="description">
          {description}
        </span>
        {
          renderCustomContent && renderCustomContent()
        }
      </div>
    </>
  );
  /**
     * This method is used as handler for rendering
     * the notification dialog actions like close, download etc.
     */
  const renderActions = (
    <div>
      {
        actions && actions.map((action) => (
          <NikeButton
            key={action.buttonText}
            buttonText={action.buttonText}
            onClick={action.onClick}
            variant="rounded-black"
            testid={action.testid || ''}
          />
        ))
      }
    </div>
  );

  return (
    <NikeDialog
      // // onBackdropClick={onBackdropClick}
      // // onEscapeKeyDown={onEscapeKeyDown}
      onClose={handleClose}
      open={open}
      className={`notificationDialog ${className || ''}`}
      renderContent={dialogContent}
      renderActions={renderActions}
    />
  );
}

NotificationDialog.defaultProps = {
  actions: [],
  onClose: () => {
    // Do Something
  }
};
