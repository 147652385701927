/**
 * This file is used as Mock data for Conversation Thread.
 * @exports (conversationCategory, searchCategory, getConversationThreads)
 * @author {Cognizant Technology Solution}
 */

export const conversationCategory = {
  categoryList: {
    'PO Reject': {
      buyNotReady: '01 | PO Reject - Buy Not Ready',
      incorrectSeason: '02 | PO Reject - Incorrect Season',
      invalidSizeMatrix: '03 | PO Reject - Invalid Size matrix',
      highSurcharge: '04 | PO Reject - High Surcharge'
    },
    factoryNotAccepting: 'Factory Not Accepting',
    ogac: 'OGAC',
    wrongFactoryAssigned: 'Wrong Factory Assigned',
    gACGACReasonCode: 'GAC / GAC Reason Code'
  },
  emaiList: []
};

export const searchCategory = {
  categoryList: {
    'Conversation Topics': {
      'PO Reject': {
        buyNotReady: '01 | PO Reject - Buy Not Ready',
        incorrectSeason: '02 | PO Reject - Incorrect Season',
        invalidSizeMatrix: '03 | PO Reject - Invalid Size matrix',
        highSurcharge: '04 | PO Reject - High Surcharge'
      },
      factoryNotAccepting: 'Factory Not Accepting',
      ogac: 'OGAC',
      wrongFactoryAssigned: 'Wrong Factory Assigned',
      gACGACReasonCode: 'GAC / GAC Reason Code'
    },
    lastUpdate: 'Last Updated',
    'Thread Status': {
      open: 'Open',
      close: 'Close'
    },
    collab: 'Collab #',
    vendorCode: 'Vendor Codes'
  }
};

export const getConversationThreads = {
  objects: [
    {
      collaborationID: 'Collab #23432',
      stakeHolders: [
        {
          emailID: 'abc4@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-06-23T09:07:21.205-07:00'
        },
        {
          emailID: 'John@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-09-03T09:07:21.205-07:00'
        },
        {
          emailID: 'abc1@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-12-13T09:07:21.205-07:00'
        }
      ],
      collaborationStatus: 'OPEN',
      collaborationTopic: [
        {
          code: '01',
          topicName: 'PO Reject - Buy Not Ready'
        }
      ],
      createdDate: '2016-06-23T09:07:21.205-07:00',
      modifiedDate: '2016-06-23T09:07:21.205-07:00',
      lastConversationDate: '2016-06-23T09:07:21.205-07:00',
      stakeHoldersCount: '3',
      poLineItemsCount: '2',
      attachmentsCount: '4',
      poData: [
        {
          id: '3502683494-24',
          'poHeader.poNumber': '3502683494',
          'poLineItem.poLineItemNumber': '1'
        },
        {
          id: '47634345457-12',
          'poHeader.poNumber': '3502683494',
          'poLineItem.poLineItemNumber': '1'
        },
        {
          id: '2346564543-51',
          'poHeader.poNumber': '3502683494',
          'poLineItem.poLineItemNumber': '1'
        }
      ],
      vendorCodes: [
        'AB'
      ],
      attachments: [
        {
          fileName: 'SampleDocument.pdf',
          fileLocation: 'http://azurestorageurl/objectid',
          email: 'abc2@nike.com',
          uploadedBy: 'John Doe',
          uploadedDate: '2016-06-23T09:07:21.205-07:00'
        },
        {
          fileName: 'demo.pdf',
          fileLocation: 'http://azurestorageurl/objectid',
          email: 'abc3@nike.com',
          uploadedBy: 'Rock Doe',
          uploadedDate: '2016-01-12T09:07:21.205-07:00'
        },
        {
          fileName: 'sample.pdf',
          fileLocation: 'http://azurestorageurl/objectid',
          email: 'abc4@nike.com',
          uploadedBy: 'Mike Doe',
          uploadedDate: '2016-08-23T09:07:21.205-07:00'
        }
      ],
      conversations: [
        {
          messageText: 'Hello, Teams lets start to close the conversation to close this PO',
          messageSenderEMail: 'abc5@nike.com',
          messageSenderName: 'John Doe',
          messageSenderUserType: 'PMO',
          sentTimeStamp: '2016-06-23T09:07:21.205-07:00'
        },
        {
          messageText: 'Close the conversation to close this PO lets start to close the conversation to close this PO',
          messageSenderEMail: 'john@nike.com',
          messageSenderName: 'John Doe',
          messageSenderUserType: 'PMO',
          sentTimeStamp: '2016-06-23T09:07:21.205-07:00'
        },
        {
          messageText: 'Hello, Teams lets start to close the conversation to close this PO',
          messageSenderEMail: 'abc6@nike.com',
          messageSenderName: 'John Doe',
          messageSenderUserType: 'PMO',
          sentTimeStamp: '2016-06-23T09:07:21.205-07:00'
        },
        {
          messageText: 'Close the conversation to close this PO lets start to close the conversation to close this PO',
          messageSenderEMail: 'john@nike.com',
          messageSenderName: 'John Doe',
          messageSenderUserType: 'PMO',
          sentTimeStamp: '2016-06-23T09:07:21.205-07:00'
        }
      ],
      id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
      resourceType: 'domain/resource',
      links: {
        self: 'domain/resource/v1/1d9c74ff-3643-494a-a898-ec6cca7368f3'
      }
    },
    {
      collaborationID: 'Collab #45432',
      stakeHolders: [
        {
          emailID: 'abc7@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-12-12T09:07:21.205-07:00'
        }
      ],
      collaborationStatus: 'OPEN',
      collaborationTopic: [
        {
          code: '01',
          topicName: 'PO Reject - Buy Not Ready'
        }
      ],
      createdDate: '2016-06-23T09:07:21.205-07:00',
      modifiedDate: '2016-06-23T09:07:21.205-07:00',
      lastConversationDate: '2016-12-12T09:07:21.205-07:00',
      stakeHoldersCount: '2',
      poLineItemsCount: '1',
      attachmentsCount: '6',
      poData: [
        {
          id: '3502683494-1',
          'poHeader.poNumber': '3502683494',
          'poLineItem.poLineItemNumber': '1'
        }
      ],
      vendorCodes: [
        'AB'
      ],
      attachments: [
        {
          fileName: 'SampleDocument.pdf',
          fileLocation: 'http://azurestorageurl/objectid',
          email: 'abc8@nike.com',
          uploadedBy: 'John Doe',
          uploadedDate: '2016-06-23T09:07:21.205-07:00'
        }
      ],
      conversations: [
        {
          messageText: 'Close the conversation to close this PO lets start to close the conversation to close this PO',
          messageSenderEMail: 'john@nike.com',
          messageSenderName: 'John Doe',
          messageSenderUserType: 'PMO',
          sentTimeStamp: '2016-06-23T09:07:21.205-07:00'
        },
        {
          messageText: 'Hello, Teams lets start to close the conversation to close this PO',
          messageSenderEMail: 'abc9@nike.com',
          messageSenderName: 'John Doe',
          messageSenderUserType: 'PMO',
          sentTimeStamp: '2016-06-23T09:07:21.205-07:00'
        },
        {
          messageText: 'Hello',
          messageSenderEMail: 'abc9@nike.com',
          messageSenderName: 'John Doe',
          messageSenderUserType: 'PMO',
          sentTimeStamp: '2016-06-23T09:07:21.205-07:00'
        }
      ],
      id: '1d9c74ff-3643-494a-a898-ec6cca7368f4',
      resourceType: 'domain/resource',
      links: {
        self: 'domain/resource/v1/1d9c74ff-3643-494a-a898-ec6cca7368f4'
      }
    },
    {
      collaborationID: 'Collab #576489',
      stakeHolders: [
        {
          emailID: 'abc8@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-06-23T09:07:21.205-07:00'
        }
      ],
      collaborationStatus: 'OPEN',
      collaborationTopic: [
        {
          code: '01',
          topicName: 'PO Reject - Buy Not Ready'
        }
      ],
      createdDate: '2016-06-23T09:07:21.205-07:00',
      modifiedDate: '2016-06-23T09:07:21.205-07:00',
      lastConversationDate: '2016-08-03T09:07:21.205-07:00',
      stakeHoldersCount: '2',
      poLineItemsCount: '4',
      attachmentsCount: '3',
      poData: [
        {
          id: '3502683494-1',
          'poHeader.poNumber': '3502683494',
          'poLineItem.poLineItemNumber': '1'
        }
      ],
      vendorCodes: [
        'AB'
      ],
      attachments: [
        {
          fileName: 'SampleDocument.pdf',
          fileLocation: 'http://azurestorageurl/objectid',
          email: 'abc7@nike.com',
          uploadedBy: 'John Doe',
          uploadedDate: '2016-06-23T09:07:21.205-07:00'
        }
      ],
      conversations: [
        {
          messageText: 'Hello',
          messageSenderEMail: 'abc6@nike.com',
          messageSenderName: 'John Doe',
          messageSenderUserType: 'PMO',
          sentTimeStamp: '2016-06-23T09:07:21.205-07:00'
        }
      ],
      id: '1d9c74ff-3643-494a-a898-ec6cca7368f5',
      resourceType: 'domain/resource',
      links: {
        self: 'domain/resource/v1/1d9c74ff-3643-494a-a898-ec6cca7368f5'
      }
    },
    {
      collaborationID: 'Collab #67439',
      stakeHolders: [
        {
          emailID: 'abc5@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-06-23T09:07:21.205-07:00'
        },
        {
          emailID: 'def@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-06-23T09:07:21.205-07:00'
        },
        {
          emailID: 'johnMike@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-06-23T09:07:21.205-07:00'
        },
        {
          emailID: 'johnMike0@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-06-23T09:07:21.205-07:00'
        },
        {
          emailID: 'johnMike1@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-06-23T09:07:21.205-07:00'
        },
        {
          emailID: 'johnMike2@nike.com',
          userType: 'PMO',
          lastConvoMsgViewedDate: '2016-06-23T09:07:21.205-07:00'
        }
      ],
      collaborationStatus: 'OPEN',
      collaborationTopic: [
        {
          code: '01',
          topicName: 'PO Reject - Buy Not Ready'
        },
        {
          code: '02',
          topicName: 'PO Reject - Buy Not Ready'
        }
      ],
      createdDate: '2016-06-23T09:07:21.205-07:00',
      modifiedDate: '2016-06-23T09:07:21.205-07:00',
      lastConversationDate: '2016-04-13T09:07:21.205-07:00',
      stakeHoldersCount: '3',
      poLineItemsCount: '4',
      attachmentsCount: '2',
      poData: [
        {
          id: '3502683494-1',
          'poHeader.poNumber': '3502683494',
          'poLineItem.poLineItemNumber': '1'
        },
        {
          id: '3502683494-12',
          'poHeader.poNumber': '3502683494',
          'poLineItem.poLineItemNumber': '1'
        },
        {
          id: '3502683494-41',
          'poHeader.poNumber': '3502683494',
          'poLineItem.poLineItemNumber': '1'
        },
        {
          id: '3502683494-42',
          'poHeader.poNumber': '3502683484',
          'poLineItem.poLineItemNumber': '2'
        }
      ],
      vendorCodes: [
        'AB'
      ],
      attachments: [
        {
          fileName: 'SampleDocument.pdf',
          fileLocation: 'http://azurestorageurl/objectid',
          email: 'abc0@nike.com',
          uploadedBy: 'John Doe',
          uploadedDate: '2016-06-23T09:07:21.205-07:00'
        },
        {
          fileName: 'demo.pdf',
          fileLocation: 'http://azurestorageurl/objectid',
          email: 'abc1@nike.com',
          uploadedBy: 'Rock Doe',
          uploadedDate: '2016-01-12T09:07:21.205-07:00'
        },
        {
          fileName: 'demo1.pdf',
          fileLocation: 'http://azurestorageurl/objectid',
          email: 'abc1@nikee.com',
          uploadedBy: 'Rock Doe',
          uploadedDate: '2017-01-12T09:07:21.205-07:00'
        }
      ],
      conversations: [
        {
          messageText: 'Hello',
          messageSenderEMail: 'abc3@nike.com',
          messageSenderName: 'John Doe',
          messageSenderUserType: 'PMO',
          sentTimeStamp: '2016-06-23T09:07:21.205-07:00'
        }
      ],
      id: '1d9c74ff-3643-494a-a898-ec6cca7368f6',
      resourceType: 'domain/resource',
      links: {
        self: 'domain/resource/v1/1d9c74ff-3643-494a-a898-ec6cca7368f6'
      }
    }
  ]
};
