export default {
  lastConversationDate: {
    primary: 'lastConversationDate', secondary: '', type: 'date', label: 'Last Updated', requestDataType: 'dynamicDate'
  },
  collaborationStatus: {
    primary: 'collaborationStatus', secondary: '', type: 'string', label: 'Thread Status'
  },
  vendorCodes: {
    primary: 'vendorCodes', secondary: '', type: 'string', label: 'Vendor Code'
  },
  collaborationId: {
    primary: 'collaborationId', secondary: '', type: 'string', label: 'Conversation ID #'
  },
  poNumbers: {
    primary: 'poNumbers', secondary: '', type: 'string', label: 'Purchase Order Number'
  },
  'collaborationTopic.code': {
    primary: 'collaborationTopic.code', secondary: '', type: 'string', label: 'Conversation Topic'
  },
  isStakeholder: {
    primary: 'isStakeholder', secondary: '', type: 'boolean', label: 'Search my conversations only'
  },
  itemNumbers: {
    primary: 'itemNumbers', secondary: '', type: 'string', label: 'PO Line Item Number'
  }
};
