/**
 * @flow
 */
import { validateEmailId } from '../../helpers/common';

const destinationCountryValue = 'Destination Country';
export function validate(value: string) {
  if (this.isRequired && !value) {
    return 'This is a mandatory field.';
  }
  switch (this.primary) {
    case 'email': {
      return validateEmailId(value);
    }
    default: {
      return '';
    }
  }
}
export default {
  rule: {
    primary: 'rule', secondary: '', type: 'string', link: false, label: 'Rule', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  ruleName: {
    primary: 'ruleName', secondary: '', type: 'string', link: false, label: 'Rules', wrap: true, numeric: 'false', disablePadding: false, group: true, validate
  },
  destinationCountryCode: {
    primary: 'destinationCountryCode', secondary: 'destinationCountry', type: 'string', link: false, label: destinationCountryValue, wrap: true, validate, numeric: 'false', disablePadding: false, group: true, isRequired: true
  },
  division: {
    primary: 'division', secondary: '', type: 'string', link: false, label: 'Division', wrap: true, numeric: 'false', disablePadding: false, group: true, validate, isRequired: true
  },
  plantCode: {
    primary: 'plantCode', secondary: '', type: 'string', link: false, label: 'Plant Code', wrap: true, numeric: 'false', disablePadding: false, group: true, validate, isRequired: true
  },
  docType: {
    primary: 'docType',
    secondary: 'docTypeDescription',
    type: 'string',
    link: false,
    label: 'Doc Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true,
    validate,
    isRequired: true
  },
  purchaseGroup: {
    primary: 'purchaseGroup', secondary: '', type: 'string', link: false, label: 'Purchase Group', wrap: true, numeric: 'false', disablePadding: false, group: true, validate, isRequired: true
  },
  email: {
    primary: 'email', secondary: '', type: 'string', link: false, label: 'Email', wrap: true, numeric: 'false', disablePadding: false, group: true, validate, isEditable: true, gridSpan: 2, isRequired: true
  },
  emailType: {
    primary: 'emailType', secondary: '', type: 'string', link: false, label: 'Email Type', wrap: true, numeric: 'false', width: 100, disablePadding: false, group: true, isEditable: true, validate, isDropdownAvailable: true, gridSpan: 2, isRequired: true
  },
  name: {
    primary: 'name', secondary: '', type: 'string', link: false, label: 'Name', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false, validate
  },
  lastEditedBy: {
    primary: 'modifiedBy', secondary: '', type: 'string', link: false, label: 'Last Edited By', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  lastEditTimeStamp: {
    primary: 'modifiedDate', secondary: '', type: 'string', link: false, label: 'Last Edited On', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
