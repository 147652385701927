export default {
  GEO_GLOBAL_RULE_NAME: 'ruleName',
  GEO_GLOBAL_RULE: 'rule',
  GEO_GLOBAL_DESTINATION_COUNTRY: 'destinationCountry',
  GEO_GLOBAL_DESTINATION_COUNTRY_CODE: 'destinationCountryCode',
  GEO_GLOBAL_DIVISION: 'division',
  GEO_GLOBAL_PLANT_CODE: 'plantCode',
  GEO_GLOBAL_DOCTYPE: 'docType',
  GEO_GLOBAL_PURCHASE_GROUP: 'purchaseGroup',
  GEO_GLOBAL_EMAIL: 'email',
  GEO_GLOBAL_EMAIL_TYPE: 'emailType',
  GEO_GLOBAL_CONTACT_NAME: 'name'
};
