/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import { getFilterDropdownValuesFromResponse, getFilteredResults, sortDataAscendingConfig } from '../../helpers/dataHelpers';

const name = 'geoEmailGroup';

function createInitialState() {
  return {
    dataId: '',
    dataRows: [],
    settingsFilterId: '',
    selectedFilters: {
      emailID: [],
      geoEmailList: []
    },
    filterDropdownValues: {
    },
    geoEmailConfigScreenData: null,
    unfilteredConfigScreenData: null
  };
}

export const initialState = createInitialState();

function createReducers() {
  function setGeoEmailGroups(state: Object, action: Object) {
    const dataId = cryptoRand().toString().substr(2, 8);
    const dataRows = action.payload.objects;
    return {
      ...state,
      dataRows,
      dataId
    };
  }
  function updateGeoEmailScreenData(state: Object, action: Object) {
    const payload = {
      data: action.payload.flattenedResponse,
      filteredResults: action.payload.filteredResults || action.payload.flattenedResponse
    };

    return {
      ...state,
      geoEmailConfigScreenData: payload.filteredResults,
      unfilteredConfigScreenData: payload.data,
      dataId: cryptoRand().toString().substr(2, 8)
    };
  }

  function clearGeoEmailFilters(state: Object) {
    return {
      ...state,
      selectedFilters: {
        ...initialState.selectedFilters
      },
      geoEmailConfigScreenData: state.unfilteredConfigScreenData
    };
  }

  function updateFilteredGeoEmailConfigScreen(state: Object, action: Object) {
    const payload = {
      data: action.payload
    };

    return {
      ...state,
      geoEmailConfigScreenData: payload.data,
      dataId: cryptoRand().toString().substr(2, 8)
    };
  }

  function updateFilterValues(state: Object, action: Object) {
    if (action.payload.values instanceof Array && action.payload.filter) {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.filter]: action.payload.values
        }
      };
    }
    return { ...state };
  }

  function updateFilterDropdownValues(state: Object, action: Object) {
    // SETTINGS_UPDATE_FILTER_DROPDOWN_VALUES

    if (
      action.payload && Object.keys(action.payload).length > 0
    ) {
      return {
        ...state,
        filterDropdownValues: sortDataAscendingConfig(action.payload)
      };
    }
    return { ...state };
  }

  function updateGeoEmailRequestFilterId(state: Object) {
    // SET_SETTINGS_FILTER_ID

    return { ...state, settingsFilterId: cryptoRand().toString().substr(2, 8) };
  }

  return {
    setGeoEmailGroups,
    updateGeoEmailScreenData,
    clearGeoEmailFilters,
    updateFilteredGeoEmailConfigScreen,
    updateFilterValues,
    updateFilterDropdownValues,
    updateGeoEmailRequestFilterId
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function getGeoEmailGroupDataByEmailID() {
    return createAsyncThunk(
      `${name}/getGeoEmailGroupDataByEmailID`,
      async (
        {
          email,
          callback
        },
        { dispatch }
      ) => {
        dispatch(toggleSpinner(true));
        const query = `?filter=emailID(${email})`;
        request(
          {
            api: 'getGeoEmailGroup',
            method: 'get',
            routeParams: { query }
          },
          dispatch
        )
          .then((response) => {
            const flattenedResponse = getflattenResponse(response.data);
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(flattenedResponse);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            console.log(error);
            callback(error);
          });
      }
    );
  }

  function getGeoEmailGroupData() {
    return createAsyncThunk(
      `${name}/getGeoEmailGroupData`,
      async (
        { callback },
        { getState, dispatch }
      ) => {
        dispatch(toggleSpinner(true));
        const query = '?filter=isActive(true)';
        request(
          {
            api: 'getGeoEmailGroup',
            method: 'get',
            routeParams: { query }
          },
          dispatch
        )
          .then((response) => {
            const state = getState();
            const flattenedResponse = getflattenResponse(response.data);
            const { selectedFilters } = state.geoEmail;
            const filteredResults = getFilteredResults(flattenedResponse.objects, selectedFilters, 'geoEmailGroup');
            dispatch(actions.updateGeoEmailScreenData(
              { flattenedResponse: flattenedResponse.objects, filteredResults }
            ));
            dispatch(actions.updateFilterDropdownValues(
              getFilterDropdownValuesFromResponse(flattenedResponse.objects, [
                'emailID',
                'geoEmailList'
              ], 'geoEmailGroup')
            ));
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(response);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            console.log(error);
            callback(error);
          });
      }
    );
  }

  function addNewGeoGroupEmail() {
    return createAsyncThunk(
      `${name}/addNewGeoGroupEmail`,
      async (
        {
          data,
          callback
        },
        { dispatch }
      ) => {
        let groupEmail;
        dispatch(toggleSpinner(true));
        const geoMembersData = [];
        data.forEach((row, index) => {
          if (index === 0) {
            groupEmail = row.emailID;
          }
          if (row.geoEmailList && row.name) {
            geoMembersData.push({
              emailID: row.geoEmailList,
              name: row.name
            });
          }
        });
        const geoEmailRequestBody = {
          emailID: groupEmail,
          geoEmailList: geoMembersData
        };
        request(
          {
            api: 'geoEmailGroup',
            method: 'post',
            data: geoEmailRequestBody,
            cancellable: true
          },
          dispatch
        )
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(response);
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(error);
          });
      }
    );
  }

  function updateGeoEmailGroupData() {
    return createAsyncThunk(
      `${name}/updateGeoEmailGroupData`,
      async (
        {
          data,
          callback
        },
        { dispatch }
      ) => {
        const updateRequestBody = {
          emailGroupListData: data
        };
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'geoEmailGroup',
            method: 'put',
            data: updateRequestBody,
            cancellable: true
          },
          dispatch
        )
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(response.data);
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(error);
          });
      }
    );
  }

  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async (
        { payload },
        { getState, dispatch }
      ) => {
        try {
          dispatch(actions.updateFilterValues(payload));
          const state = getState();
          const { selectedFilters, unfilteredConfigScreenData } = state.geoEmail;
          const filteredResults = getFilteredResults(unfilteredConfigScreenData, selectedFilters, 'geoEmailGroup');
          dispatch(actions.updateFilteredGeoEmailConfigScreen(filteredResults));
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  function clearFilters() {
    return createAsyncThunk(
      `${name}/clearFilters`,
      async (_, { dispatch }) => {
        dispatch(actions.clearGeoEmailFilters());
      }
    );
  }

  return {
    getGeoEmailGroupDataByEmailID: getGeoEmailGroupDataByEmailID(),
    getGeoEmailGroupData: getGeoEmailGroupData(),
    addNewGeoGroupEmail: addNewGeoGroupEmail(),
    updateGeoEmailGroupData: updateGeoEmailGroupData(),
    applyFilters: applyFilters(),
    clearFilters: clearFilters()
  };
}

export const extraActions = createExtraActions();

export const geoEmailGroupActions = { ...actions, ...extraActions };
export const geoEmailGroupReducer = slice.reducer;
