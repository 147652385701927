const destinationCountryValue = 'Destination Country';

export default {
  profileID: {
    primary: 'profileId', secondary: '', type: 'string', link: false, label: 'Profile ID', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  id: {
    primary: 'id', secondary: '', type: 'string', link: false, label: 'Profile ID', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventCreateTS: {
    primary: 'eventCreateTS', secondary: '', type: 'datetime', link: false, label: 'Request Submitted', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventStatus: {
    primary: 'eventStatus', secondary: '', type: 'string', link: false, label: 'Status', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  totalErrorCount: {
    primary: 'totalErrorCount',
    secondary: '',
    type: 'numeric',
    link: false,
    label: 'Errors Found',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  pdfDownloadLink: {
    primary: 'pdfDownloadLink', secondary: '', type: 'string', link: false, label: 'Download Link', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  requestedPdf: {
    primary: 'requestedPdf', secondary: '', type: 'string', link: false, label: 'Requested PDFs', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  totalReceivedItems: {
    primary: 'totalReceivedItems', secondary: '', type: 'numeric', link: false, label: 'Received PDFs', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventType: {
    primary: 'eventType', secondary: '', type: 'string', link: false, label: 'Requested Source', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  plantId: {
    primary: 'plantId', secondary: '', type: 'string', link: false, label: 'Plant ID', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  shipToId: {
    primary: 'shipToId', secondary: '', type: 'string', link: false, label: 'Ship to', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  division: {
    primary: 'division', secondary: '', type: 'string', link: false, label: 'Division', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  mode: {
    primary: 'mode', secondary: '', type: 'string', link: false, label: 'Mode of Transportation', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  buyGroup: {
    primary: 'buyGroup', secondary: '', type: 'string', link: false, label: 'Buy Group', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  ttmi: {
    primary: 'ttmi', secondary: '', type: 'string', link: false, label: 'TTMI', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  destCountry: {
    primary: 'destCountry', secondary: '', type: 'string', link: false, label: destinationCountryValue, wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  originCountry: {
    primary: 'originCountry', secondary: '', type: 'string', link: false, label: 'Country of Origin', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  reason: {
    primary: 'reason', secondary: '', type: 'string', link: false, label: 'Failure Reason', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventErrorList: {
    primary: 'eventErrorList', secondary: '', type: 'string', link: false, label: 'Action', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  poNumber: {
    primary: 'poNumber', secondary: '', type: 'string', link: false, label: 'PO Number', wrap: true, numeric: 'true', disablePadding: false
  }
};
