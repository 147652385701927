import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import request from '../../services/request';
import pmoDecFieldProperties from '../../constants/fieldProperties/pmoDecFieldProperties';
// import { cryptoRand } from '../../helpers/common';
import { searchPanelDataActions } from '../searchPanelData/searchPanelDataSlice';
import getflattenResponse, { getEndpointURL, cryptoRand } from '../../helpers/common';
import { getFilterValuesFromResponse, mapFiltersToRequest } from '../../helpers/dataHelpers';

const name = 'ProfileSetting';
function createInitialState() {
  return {
    DateFormat: 'MM/dd/yyyy',
    loadingSpinner: false,
    savingSpinner: false,
    userProfile: [],
    filterDropdownValues: {
      factoryCode: ['TX']
    },
    selectedFilters: {
      factoryCode: [],
      pmodec: [],
      nikeId: [],
      emailId: [],
      analystName: []
    },
    settingsFilterId: 'none',
    SettingsData: [],
    SearchRequestBody: null,
    updatedProfileSettings: false
  };
}

function createReducers() {
  function setSettingFilterId(state) {
    return { ...state, settingsFilterId: cryptoRand().toString().substr(2, 8) };
  }
  function settingUpdateFilterDropDownValue(state, action) {
    if (
      action.payload
      && Object.keys(action.payload).length > 0
    ) {
      return {
        ...state,
        filterDropdownValues: action.payload
      };
    }
    return { ...state };
  }
  function settingSearchData(state, action) {
    console.log('A: action=', action);
    return {
      ...state,
      SettingsData: action.payload.objects
    };
  }
  function addSettingFilterValues(state, action) {
    if (action.payload.values instanceof Array && action.payload.filter) {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.filter]: action.payload.values
        }
      };
    }
    return { ...state };
  }
  function updateSettingFilterInRequest(state, action) {
    const updatedBody = { ...state.body, filter: action.payload };
    return { ...state, SearchRequestBody: updatedBody };
  }
  function updateSettingSearchRequestBody(state, action) {
    return {
      ...state,
      SearchRequestBody: action.payload
    };
  }
  function dateFormat(state, action) {
    return {
      ...state,
      DateFormat: action.payload
    };
  }
  function userProfile(state, action) {
    return {
      ...state,
      userProfile: action.payload
    };
  }
  function toggleSettingSpinner(state) {
    return {
      ...state,
      loadingSpinner: true
    };
  }
  function toggleSettingSpinnerSuccess(state) {
    return {
      ...state,
      loadingSpinner: false
    };
  }
  function toggleSavingSettingSpinner(state) {
    return {
      ...state,
      savingSpinner: true
    };
  }
  function toggleSavingSettingSpinnerSuccess(state) {
    return {
      ...state,
      savingSpinner: false
    };
  }

  return {
    setSettingFilterId,
    settingUpdateFilterDropDownValue,
    settingSearchData,
    addSettingFilterValues,
    updateSettingFilterInRequest,
    updateSettingSearchRequestBody,
    dateFormat,
    userProfile,
    toggleSettingSpinner,
    toggleSettingSpinnerSuccess,
    toggleSavingSettingSpinner,
    toggleSavingSettingSpinnerSuccess
  };
}

const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };
const filterFunc = (filter) => {
  const filterInResponse = {};
  const fieldProperties = pmoDecFieldProperties[filter] || {};
  if (!fieldProperties) {
    return null;
  }
  filterInResponse.primary = fieldProperties.primary;
  if (fieldProperties.secondary) {
    filterInResponse.secondary = fieldProperties.secondary;
  }
  return filterInResponse;
};
function createExtraActions() {
  function userProfileSettings() {
    return createAsyncThunk(
      `${name}/userProfileSettings`,
      async (props, { getState, dispatch }) => {
        dispatch(actions.toggleSavingSettingSpinner());
        const { requestBody, callback } = props;
        request({
          api: 'updateUserProfile',
          method: 'put',
          data: requestBody
        },
        dispatch,
        getState).then((response) => {
          dispatch(actions.toggleSavingSettingSpinnerSuccess());
          if (response) {
            dispatch(actions.userProfile([response.data]));
            const callerType = Object.keys(requestBody)?.[0];
            const callerTypes = [
              {
                filter: 'selectedFilterListForPPMReport',
                column: 'selectedColumnListForPPMReport'
              },
              {
                filter: 'selectedFilterListForLineItemReport',
                column: 'selectedColumnListForLineItemReport'
              },
              {
                filter: 'selectedFilterListForPOSearch',
                column: 'selectedColumnListForPOSearch'
              }
            ];
            let filterValue = '';
            let columnValue = '';
            const matchedCallerType = callerTypes
              .find(({ filter, column }) => callerType.includes(filter)
                || callerType.includes(column));
            if (matchedCallerType) {
              filterValue = matchedCallerType.filter;
              columnValue = matchedCallerType.column;
            } else if (callerType.includes('selectedColumnList')) {
              columnValue = callerType;
            } else if (callerType.includes('selectedFilter')) {
              filterValue = callerType;
            }
            dispatch(searchPanelDataActions.getFilterAndColumnOrderList({
              columnType: columnValue, filterType: filterValue
            }));
            if (callback) {
              callback(response);
            }
          }
        })
          .catch((error) => {
            dispatch(actions.toggleSettingSpinnerSuccess());
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }
  function createUserProfile() {
    return createAsyncThunk(
      `${name}/createUserProfile`,
      async (callback, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'CREATEUSERPROFILE',
          method: 'post'
        },
        dispatch,
        getState).then((response) => {
          if (callback) {
            callback(response);
          }
        })
          .catch((error) => {
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }
  function getUserProfile() {
    return createAsyncThunk(
      `${name}/getUserProfile`,
      async ({ callback }, { getState, dispatch }) => {
        dispatch(actions.toggleSettingSpinner());
        request({
          api: 'getUserProfile',
          method: 'get'
        },
        dispatch,
        getState).then((response) => {
          dispatch(actions.toggleSettingSpinnerSuccess());
          dispatch(actions.userProfile(response.data.objects));
          if (callback) {
            callback(response);
          }
        })
          .catch((error) => {
            dispatch(actions.toggleSettingSpinnerSuccess());
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }
  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async (props, { getState, dispatch }) => {
        const { payload, applyFiltersCallback, shouldRequestFilterValues } = props;
        dispatch(toggleSpinner(true));
        dispatch(actions.addSettingFilterValues(payload));
        let state = getState();
        const filtersRequest = mapFiltersToRequest(state.PMODECLookup.selectedFilters);
        dispatch(actions.updateSettingFilterInRequest(filtersRequest));
        state = getState();
        const requestBody = { ...state.PMODECLookup.SearchRequestBody };
        const selectedFilterKeys = Object.keys(state.PMODECLookup.selectedFilters)
          .filter((filter) => state.PMODECLookup.selectedFilters[filter]);
        if (shouldRequestFilterValues && selectedFilterKeys.length > 0) {
          requestBody.filtersInResponse = selectedFilterKeys.map((filter) => filterFunc(filter))
            .filter((element) => element);
        } else {
          delete requestBody.filtersInResponse;
        }
        request({
          url: getEndpointURL('SettingsSearch'),
          method: 'post',
          data: requestBody
        },
        dispatch,
        getState).then((response) => {
          dispatch(toggleSpinner(false));
          if (response.data.filters) {
            dispatch(actions.settingUpdateFilterDropDownValue(
              getFilterValuesFromResponse(response.data.filters)
            ));
          }
          dispatch(actions.setSettingFilterId());
          dispatch(actions.updateSettingSearchRequestBody(requestBody));
          dispatch(actions.settingSearchData(getflattenResponse(response.data)));
          if (applyFiltersCallback) {
            applyFiltersCallback(response);
          }
        })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            if (applyFiltersCallback) {
              applyFiltersCallback(null, error);
            }
          });
      }
    );
  }
  function userProfileUpdateTime() {
    return createAsyncThunk(
      `${name}/userProfileUpdateTime`,
      async (_, { getState, dispatch }) => {
        dispatch(actions.toggleSavingSettingSpinner());
        const requestBody = {
          userLastLoginAt: new Date()
        };
        request({
          api: 'updateUserProfile',
          method: 'put',
          data: requestBody
        },
        dispatch,
        getState).then(() => {
          dispatch(actions.toggleSavingSettingSpinnerSuccess());
        })
          .catch((error) => {
            console.log(error);
            dispatch(actions.toggleSettingSpinnerSuccess());
          });
      }
    );
  }
  function userProfileUpdateRefreshTime() {
    return createAsyncThunk(
      `${name}/userProfileUpdateRefreshTime`,
      async (_, { getState, dispatch }) => {
        dispatch(actions.toggleSavingSettingSpinner());
        const requestBody = {
          userLastRefreshAt: new Date()
        };
        request({
          api: 'updateUserProfile',
          method: 'put',
          data: requestBody
        },
        dispatch,
        getState).then(() => {
          dispatch(actions.toggleSavingSettingSpinnerSuccess());
        })
          .catch((error) => {
            console.log(error);
            dispatch(actions.toggleSettingSpinnerSuccess());
          });
      }
    );
  }
  return {
    createUserProfile: createUserProfile(),
    getUserProfile: getUserProfile(),
    userProfileSettings: userProfileSettings(),
    applyFilters: applyFilters(),
    userProfileUpdateTime: userProfileUpdateTime(),
    userProfileUpdateRefreshTime: userProfileUpdateRefreshTime()
  };
}

const extraActions = createExtraActions();

export const settingsActions = { ...actions, ...extraActions };
export const settingsReducer = slice.reducer;
