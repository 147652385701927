/**
 * @flow
 */
import {
  validateAlphanumeric,
  validateMultiCheckbox,
  validateDateTime
} from '../../helpers/common';

export function validate(value: any) {
  if (this.isRequired && !value) {
    return 'This is a mandatory field.';
  }
  switch (this.primary) {
    case 'featureTag':
    case 'featureName': {
      return validateAlphanumeric(value);
    }
    case 'groups':
    case 'factoryAffiliations': {
      return validateMultiCheckbox(value);
    }
    case 'startTime': {
      return validateDateTime(value);
    }
    default: {
      return '';
    }
  }
}

export default {
  featureName: {
    primary: 'featureName', secondary: '', type: 'string', link: false, label: 'Feature Name', validate, wrap: true, disablePadding: false, width: 180, isEditable: false, gridSpan: 2, isRequired: true
  },
  featureTag: {
    primary: 'featureTag', secondary: '', type: 'string', link: false, label: 'Feature Tag', validate, wrap: true, disablePadding: false, width: 180, isEditable: false, gridSpan: 2, isRequired: true
  },
  status: {
    primary: 'status', secondary: '', type: 'string', link: false, label: 'Status', wrap: true, disablePadding: false, width: 180, isEditable: false, gridSpan: 2
  },
  groups: {
    primary: 'groups', secondary: '', type: 'string', link: false, label: 'Groups', validate, wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false, gridSpan: 2, isRequired: true
  },
  factoryAffiliations: {
    primary: 'factoryAffiliations', secondary: '', type: 'string', link: false, label: 'Factory Affiliations', validate, wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: true, gridSpan: 2, isRequired: true
  },
  startTime: {
    primary: 'startTime', secondary: '', type: 'string', link: false, label: 'Start Time', validate, wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false, gridSpan: 2, isRequired: true
  },
  endTime: {
    primary: 'endTime', secondary: '', type: 'string', link: false, label: 'End Time', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: true, gridSpan: 2
  },
  dependencyFeatures: {
    primary: 'dependencyFeatures', secondary: '', type: 'string', link: false, label: 'Dependency', wrap: true, disablePadding: false, width: 180, isEditable: false, gridSpan: 2
  },
  lastUpdatedBy: {
    primary: 'lastUpdatedBy', secondary: '', type: 'string', link: false, label: 'Last Updated By', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  },
  lastUpdatedAt: {
    primary: 'lastUpdatedAt', secondary: '', type: 'datetime', link: false, label: 'Last updated At', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  }
};
