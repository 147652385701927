/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import {
  initialGACChangeHistoryReportFieldState,
  gacChangeHistoryReportFieldList
} from '../../constants/staticData';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import appConfig from '../../appConfig';
import {
  mapInputFieldsToRequest, mapRequestToInputFields, checkForColumnData, setValidFields
} from '../../helpers/dataHelpers';
import gacChangeHistoryReportHeader from '../../constants/gacChangeHistoryReportHeader';
import gacChangeHistoryReportFieldMapping from '../../constants/fieldMappings/gacChangeHistoryReportFieldMapping';
import { toggleSpinner, togglePaginationSpinner, updateDialogStates } from '../dialogStates/dialogStatesSlice';
import getFieldsList from './gacChangeHelper';
import gacChangeHistoryReportFieldProperties from '../../constants/fieldProperties/gacChangeHistoryReportFieldProperties';
import { showForTypePayload } from '../../helpers/envServer';

const name = 'gacChangeHistoryReport';

function createInitialState() {
  return {
    // requestReducer initialstate
    isSavedSearchModified: false,
    gacChangeHistoryReportRequestBody: null,
    gacChangeHistoryReportInputFieldData: { ...initialGACChangeHistoryReportFieldState },
    gacChangeHistoryReportSelectedFields: [],
    gacChangeHistoryReportBookmarkName: '',
    gacChangeHistoryReportBookmarkId: '',
    requestError: false,
    gacChangeHistorySubtableRequestBody: null,
    // resultReducer initialstate
    gacChangeHistoryReportResultData: {},
    gacChangeHistoryReportSearchId: '',
    gacChangeHistoryReportTotalCount: 0,
    gacChangeHistoryReportNextResults: {},
    gacChangeHistoryReportPrevResults: {},
    page: 0,
    rowsPerPage: 100,
    gacChangeHistorySubtableResults: {}
  };
}
export const initialState = createInitialState();
function createReducers() {
  // request Reducer
  function updateGACChangeHistoryReportRequestBody(state: Object, action: Object) {
    const requestExist = JSON.stringify(state.gacChangeHistoryReportRequestBody);
    return {
      ...state,
      gacChangeHistoryReportRequestBody: action.payload,
      isSavedSearchModified: Boolean(
        state.gacChangeHistoryReportBookmarkId && requestExist !== JSON.stringify(action.payload)
      )
    };
  }
  function updateGACChangeHistorySubtableRequestBody(state: Object, action: Object) {
    return {
      ...state,
      gacChangeHistorySubtableRequestBody: action.payload
    };
  }
  function updateIsSavedSearchModified(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: Boolean(action.payload)
    };
  }
  function updateGACChangeHistoryRequestBodyOnSameBookmark(state: Object, action: Object) {
    return {
      ...state,
      gacChangeHistoryReportRequestBody: action.payload
    };
  }
  function updateGACChangeHistoryReportFields(state: Object, action: Object) {
    return {
      ...state, gacChangeHistoryReportSelectedFields: action.payload
    };
  }
  function updateGACChangeHistoryReportInputValues(state: Object, action: Object) {
    return {
      ...state, gacChangeHistoryReportInputFieldData: action.payload
    };
  }
  function resetGACChangeHistoryReportInputData(state: Object) {
    return {
      ...state,
      gacChangeHistoryReportInputFieldData: initialState.gacChangeHistoryReportInputFieldData
    };
  }
  function updateGACChangeHistoryReportBookmarkId(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      gacChangeHistoryReportBookmarkId: action.payload
    };
  }
  function updateGACChangeHistoryReportBookmarkName(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      gacChangeHistoryReportBookmarkName: action.payload
    };
  }
  function resetGACChangeHistoryReportRequest(state: Object) {
    return {
    //   ...initialState,
      ...state,
      isSavedSearchModified: false,
      gacChangeHistoryReportRequestBody: null,
      gacChangeHistoryReportInputFieldData: { ...initialGACChangeHistoryReportFieldState },
      gacChangeHistoryReportSelectedFields: [],
      gacChangeHistoryReportBookmarkName: '',
      gacChangeHistoryReportBookmarkId: '',
      requestError: false,
      gacChangeHistorySubtableRequestBody: null
    };
  }
  function clearSavedGACChangeHistoryReport(state: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      gacChangeHistoryReportBookmarkId: '',
      gacChangeHistoryReportBookmarkName: ''
    };
  }
  function resetGACChangeHistoryRequestForClearSearch(state: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      gacChangeHistoryReportRequestBody: null,
      gacChangeHistoryReportInputFieldData: { ...initialGACChangeHistoryReportFieldState },
      gacChangeHistoryReportSelectedFields: [
        ...new Set(state.gacChangeHistoryReportSelectedFields)
      ],
      gacChangeHistoryReportBookmarkName: '',
      gacChangeHistoryReportBookmarkId: '',
      requestError: false,
      gacChangeHistorySubtableRequestBody: null
    };
  }
  // result reducer
  function gacChangeHistoryReportOffset(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action?.payload?.data)
    };
    return {
      ...state,
      gacChangeHistoryReportResultData: payload.data,
      gacChangeHistoryReportTotalCount: payload?.data?.pages?.totalResources,
      gacChangeHistoryReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      gacChangeHistoryReportNextResults: null,
      gacChangeHistoryReportPrevResults: null
    };
  }
  function updateGACChangeHistoryReportResults(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.data),
      dataNext: null
    };
    return {
      ...state,
      gacChangeHistoryReportResultData: payload.data,
      gacChangeHistoryReportTotalCount: payload?.data?.pages?.totalResources,
      gacChangeHistoryReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      gacChangeHistoryReportNextResults: null,
      gacChangeHistoryReportPrevResults: null
    };
  }
  function updateGACChangeHistorySubtableResults(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.data),
      dataNext: null
    };
    return {
      ...state,
      gacChangeHistorySubtableResults: payload
    };
  }
  function updateGACChangeHistoryReportSearchId(state: Object) {
    return { ...state, gacChangeHistoryReportSearchId: cryptoRand().toString().substr(2, 8) };
  }
  function updateGACChangeHistoryReportPage(state: Object, action: Object) {
    return { ...state, page: action.payload };
  }
  function updateGACChangeHistoryReportRowsPerPage(state: Object, action: Object) {
    return { ...state, rowsPerPage: action.payload };
  }
  function updateGACChangeHistoryReportNextResult(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataNext: getflattenResponse(action.payload.response.data)
    };
    return {
      ...state,
      gacChangeHistoryReportResultData: payload.data,
      gacChangeHistoryReportNextResults: payload.dataNext,
      gacChangeHistoryReportTotalPages: payload.data.pages.totalPages,
      gacChangeHistoryReportTotalCount: payload.data.pages.totalResources
    };
  }
  function ChangeReportAppendNextResultSet(state: Object) {
    const updatedCurrentResultSet = state.gacChangeHistoryReportResultData;
    const updatedNextResultSet = state.gacChangeHistoryReportNextResults;
    const payload = {
      dataPrev: updatedCurrentResultSet,
      data: updatedNextResultSet
    };
    return {
      ...state,
      gacChangeHistoryReportResultData: payload.data,
      gacChangeHistoryReportPrevResults: payload.dataPrev,
      gacChangeHistoryReportNextResults: null
    };
  }
  function updateGACChangeHistoryReportPrevResult(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataPrev: getflattenResponse(action.payload.response.data)
    };
    return {
      ...state,
      gacChangeHistoryReportResultData: payload.data,
      gacChangeHistoryReportPrevResults: payload.dataPrev,
      gacChangeHistoryReportTotalPages: payload?.data?.pages?.totalPages,
      gacChangeHistoryReportTotalCount: payload?.data?.pages?.totalResources
    };
  }
  function ChangeReportAppendPrevResultSet(state: Object) {
    const updatedPrevResultSet = state.gacChangeHistoryReportPrevResults;
    const updatedCurrentResultSet = state.gacChangeHistoryReportResultData;
    const payload = {
      data: updatedPrevResultSet,
      dataNext: updatedCurrentResultSet
    };

    return {
      ...state,
      gacChangeHistoryReportResultData: payload.data,
      gacChangeHistoryReportNextResults: payload.dataNext,
      gacChangeHistoryReportPrevResults: null
    };
  }

  function resetGACChangeHistoryReportResults(state: Object) {
    return {
      // ...initialState correct
      ...state,
      gacChangeHistoryReportResultData: {},
      gacChangeHistoryReportSearchId: '',
      gacChangeHistoryReportTotalCount: 0,
      gacChangeHistoryReportNextResults: {},
      gacChangeHistoryReportPrevResults: {},
      page: 0,
      rowsPerPage: 100,
      gacChangeHistorySubtableResults: {}
    };
  }

  return {
    updateGACChangeHistoryReportRequestBody,
    updateIsSavedSearchModified,
    updateGACChangeHistorySubtableRequestBody,
    updateGACChangeHistoryRequestBodyOnSameBookmark,
    updateGACChangeHistoryReportFields,
    updateGACChangeHistoryReportInputValues,
    resetGACChangeHistoryReportInputData,
    updateGACChangeHistoryReportBookmarkId,
    updateGACChangeHistoryReportBookmarkName,
    resetGACChangeHistoryReportRequest,
    clearSavedGACChangeHistoryReport,
    resetGACChangeHistoryRequestForClearSearch,
    // result
    gacChangeHistoryReportOffset,
    updateGACChangeHistoryReportResults,
    updateGACChangeHistorySubtableResults,
    updateGACChangeHistoryReportSearchId,
    updateGACChangeHistoryReportPage,
    updateGACChangeHistoryReportRowsPerPage,
    updateGACChangeHistoryReportNextResult,
    ChangeReportAppendNextResultSet,
    updateGACChangeHistoryReportPrevResult,
    ChangeReportAppendPrevResultSet,
    resetGACChangeHistoryReportResults
  };
}

export const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function fetchGACChangeHistoryReports() {
    return createAsyncThunk(
      `${name}/fetchGACChangeHistoryReports`,
      async ({
        data,
        callback,
        modifiedrequestBody = {}
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const secondaryFields = [];
        getFieldsList(secondaryFields);
        const state = getState();
        let requestData = {};
        const {
          selectedColumnOrderOptions: columnOrderOptions,
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};
        if (checkForColumnData(state, true)) {
          gacChangeHistoryReportHeader.forEach((headRow: Object) => {
            if (headRow.includeInRequest) {
              columnOrderOptions.push(headRow.primary);
            }
          });
        }
        const fieldsForRequest = checkForColumnData(state, true)
          ? [...new Set(columnOrderOptions)]
          : secondaryFields;
        if (data) {
          requestData = mapInputFieldsToRequest(data, 'gacChangeHistoryReport');
          const appliedGACChangeHistorySearchField = [
            ...new Set(requestData.map((field) => field.fieldName))
          ];
          dispatch(actions.updateGACChangeHistoryReportInputValues(data));
          dispatch(actions.updateGACChangeHistoryReportFields(gacChangeHistoryReportFieldList
            .map((field) => field.key)
            .filter((field) => appliedGACChangeHistorySearchField.includes(field))));
        } else {
          requestData = state.gacChangeHistoryReport.gacChangeHistoryReportRequestBody.search;
        }

        if (requestData && requestData.map
          && Array.isArray(requestData)
          && Object.isExtensible(requestData)) {
          requestData.push(
            {
              fieldName: gacChangeHistoryReportFieldMapping.GAC_CHANGE_HISTORY_INITIAL_GAC_DATE,
              function: 'IS_DEFINED'
            }
          );
          if (!requestData.map((field) => field.fieldName === 'poHeader.poCreatedOn').some((value) => value === true)) {
            requestData.push(
              {
                fieldName: 'poHeader.poCreatedOn',
                operator: '>',
                fieldValue: appConfig.gacChangeHiddenCreateDate
              }
            );
          }
        }
        let requestBody = (state.gacChangeHistoryReport.gacChangeHistoryReportRequestBody)
          ? {
            ...state.gacChangeHistoryReport.gacChangeHistoryReportRequestBody,
            search: requestData,
            offset: '0',
            ...modifiedrequestBody
          } : {
            fields: fieldsForRequest,
            search: requestData,
            count: appConfig.SearchThresholdLimit,
            offset: '0',
            ...modifiedrequestBody
          };
        requestBody = setValidFields(gacChangeHistoryReportFieldProperties, requestBody);

        request({
          api: 'gacChangeHistorySearch',
          method: 'post',
          data: showForTypePayload ? { ...requestBody, type: 'gacChangeHistory' } : requestBody,
          cancellable: true
        }, dispatch).then((response) => {
          dispatch(actions.updateGACChangeHistoryReportRequestBody(requestBody));
          dispatch(toggleSpinner(false));
          callback(response.data);
          dispatch(actions.updateGACChangeHistoryReportRowsPerPage(rowsPerPageOption));
          dispatch(actions.updateGACChangeHistoryReportResults(response));
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          callback(null, error);
        });
      }
    );
  }

  function fetchGACChangeHistorySubTable() {
    return createAsyncThunk(
      `${name}/fetchGACChangeHistorySubTable`,
      async ({
        rowId, callback
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const fields = [
          gacChangeHistoryReportFieldMapping.GAC_CHANGE_HISTORY_PO_NUMBER,
          gacChangeHistoryReportFieldMapping.GAC_CHANGE_HISTORY_ITEM_NUMBER,
          'dpomGacHistoryData'
        ];
        const state = getState();
        const requestData = state.gacChangeHistoryReport.gacChangeHistoryReportRequestBody.search;
        const requestBody = {
          search: requestData,
          offset: '0',
          fields: [...fields],
          count: appConfig.SearchThresholdLimit
        };
        request({
          api: 'gacChangeHistorySubtable',
          method: 'post',
          data: showForTypePayload ? { ...requestBody, type: 'gacChangeHistory' } : requestBody,
          cancellable: true
        }, dispatch).then((response) => {
          dispatch(actions.updateGACChangeHistorySubtableRequestBody(requestBody));
          const filteredData = response.data.objects.filter((x) => x.id === rowId);
          callback(filteredData);
          dispatch(actions.updateGACChangeHistorySubtableResults({ data: filteredData }));
          dispatch(toggleSpinner(false));
        }).catch((error) => {
          callback(null, error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }
  function exportGACChangeHistoryReport() {
    return createAsyncThunk(
      `${name}/exportGACChangeHistoryReport`,
      async ({
        callback,
        filetype
      }, { getState, dispatch }) => {
        const state = getState();
        dispatch(toggleSpinner(true));
        const searchRequestBody = {
          ...state.gacChangeHistoryReport.gacChangeHistoryReportRequestBody
        };
        const tempRequestData = JSON.parse(JSON.stringify(searchRequestBody));
        tempRequestData.fields.push(...appConfig.includeFieldsGACHistorySubTable);

        const removeAdditionalFields = [
          ...gacChangeHistoryReportHeader.filter((column) => column.additionalFields)
            .map((headRow) => headRow.additionalFields[0])
        ];
        const filedsIncludingDuplicate = tempRequestData.fields.filter(
          (item) => !removeAdditionalFields.includes(item)
        );
        const duplicateKeysAre = filedsIncludingDuplicate.filter(
          (keys, index) => filedsIncludingDuplicate.indexOf(keys) !== index
        );
        const fieldToExcludes = [
          ...appConfig.excludedPOFieldsInExportedFile,
          ...gacChangeHistoryReportHeader.filter((column) => column.includeInRequest)
            .map((headRow) => headRow.primary)
        ];
        let distinctAppConfigKeys = [];
        if (duplicateKeysAre.length === 0) {
          distinctAppConfigKeys = [...fieldToExcludes];
        } else {
          distinctAppConfigKeys = fieldToExcludes.filter((duplicated) => (
            !duplicateKeysAre.includes(duplicated)
          ));
        }
        const gacChangeHistoryReportRequestBody = {
          search: [...(searchRequestBody.search) || []],
          fields: filedsIncludingDuplicate.filter((field: string) => (
            !distinctAppConfigKeys.includes(field)
          )),
          searchType: searchRequestBody.searchType,
          fileFormat: filetype,
          type: 'gacChangeHistory'
        };
        request({
          api: 'gacChangeHistoryReportExportLargeFile',
          method: 'post',
          data: showForTypePayload ? { ...gacChangeHistoryReportRequestBody, type: 'gacChangeHistory' } : gacChangeHistoryReportRequestBody
        }, dispatch).then((response) => {
          callback(response);
          dispatch(toggleSpinner(false));
        }).catch((error) => {
          callback(null, error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }
  function fetchSavedGACChangeHistoryReportResults() {
    return createAsyncThunk(
      `${name}/fetchSavedGACChangeHistoryReportResults`,
      async ({
        gacChangeHistoryReportRequestData, callback
      }, { getState, dispatch }) => {
        const state = getState();
        let requestBody = { ...gacChangeHistoryReportRequestData.searchCriteria, offset: '0' };
        requestBody = setValidFields(gacChangeHistoryReportFieldProperties, requestBody);

        if (requestBody.search
          && requestBody.search.map
          && !requestBody.search.find((criteria) => (
            criteria.fieldName === gacChangeHistoryReportFieldMapping
              .GAC_CHANGE_HISTORY_INITIAL_GAC_DATE
          ))
        ) {
          requestBody.search.push({
            fieldName: gacChangeHistoryReportFieldMapping.GAC_CHANGE_HISTORY_INITIAL_GAC_DATE,
            function: 'IS_DEFINED'
          });
        }
        const {
          rowPerPageOption: rowsPerPageOption
        } = state?.searchpaneldata || {};
        dispatch(toggleSpinner(true));
        dispatch(updateDialogStates({ executeBookmarkSpinner: true }));
        request({
          api: 'gacChangeHistorySearch',
          method: 'post',
          data: showForTypePayload ? {
            savedSearchID: gacChangeHistoryReportRequestData.id,
            ...requestBody,
            type: 'gacChangeHistory'
          } : {
            savedSearchID: gacChangeHistoryReportRequestData.id,
            ...requestBody
          },
          cancellable: true
        }, dispatch).then((response) => {
          const fields = mapRequestToInputFields([
            ...gacChangeHistoryReportRequestData.searchCriteria.search
          ]);
          const gacChangeHistorySearchFields = [...new Set(
            gacChangeHistoryReportRequestData.searchCriteria.search.map((field) => field.fieldName)
          )];
          dispatch(actions.updateGACChangeHistoryReportInputValues(
            fields
          ));
          dispatch(actions.updateGACChangeHistoryReportRequestBody(requestBody));
          dispatch(actions.updateGACChangeHistoryReportFields(gacChangeHistoryReportFieldList
            .map((field) => field.key)
            .filter((field) => gacChangeHistorySearchFields.includes(field))));
          dispatch(actions.updateGACChangeHistoryReportResults(response));
          dispatch(actions.updateGACChangeHistoryReportSearchId());
          dispatch(actions.updateGACChangeHistoryReportPage(0));
          dispatch(actions.updateGACChangeHistoryReportRowsPerPage(rowsPerPageOption));
          dispatch(actions.updateGACChangeHistoryReportBookmarkId(
            gacChangeHistoryReportRequestData.id
          ));
          dispatch(
            actions.updateGACChangeHistoryReportBookmarkName(
              gacChangeHistoryReportRequestData.name
            )
          );
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          if (callback) {
            callback(response);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          console.log(error);
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }
  function fetchGACChangeHistoryReportsResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchGACChangeHistoryReportsResultsOffset`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.gacChangeHistoryReport.gacChangeHistoryReportRequestBody
                    && state.gacChangeHistoryReport.gacChangeHistoryReportResultData) {
          let gacChangeHistoryReportUpdatedRequest = state.gacChangeHistoryReport
            .gacChangeHistoryReportRequestBody;
          gacChangeHistoryReportUpdatedRequest = {
            ...gacChangeHistoryReportUpdatedRequest,
            offset: '0'
          };
          dispatch(toggleSpinner(true));
          request({
            api: 'gacChangeHistorySearch',
            method: 'post',
            data: showForTypePayload ? { ...gacChangeHistoryReportUpdatedRequest, type: 'gacChangeHistory' } : gacChangeHistoryReportUpdatedRequest
          }, dispatch).then((response) => {
            dispatch(actions.gacChangeHistoryReportOffset(response));
            dispatch(toggleSpinner(false));
            dispatch(actions.updateGACChangeHistoryReportSearchId());
          }).catch((error) => {
            console.log('NEXT Error ', error);
            dispatch(toggleSpinner(false));
          });
        }
      }
    );
  }
  function gacChangeHistoryReportDataOffset() {
    return createAsyncThunk(
      `${name}/gacChangeHistoryReportDataOffset`,
      async (
        {
          gacFetchGACChangeHistoryReportsResultsOffset
        }, { dispatch }
      ) => {
        dispatch(gacFetchGACChangeHistoryReportsResultsOffset());
      }
    );
  }
  function fetchGACChangeHistoryReportNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchGACChangeHistoryReportNextResultSet`,
      async (props, { getState, dispatch }) => {
        const state = getState();
        dispatch(togglePaginationSpinner({ isFetchingNextData: true }));
        if (state.gacChangeHistoryReport.gacChangeHistoryReportRequestBody
    && state.gacChangeHistoryReport.gacChangeHistoryReportResultData
    && state.gacChangeHistoryReport.gacChangeHistoryReportResultData.pages.next) {
          const currentResultSet = state.gacChangeHistoryReport
            .gacChangeHistoryReportResultData;
          let updatedRequest = state.gacChangeHistoryReport
            .gacChangeHistoryReportRequestBody;
          const searchResultNextOffset = state.gacChangeHistoryReport
            .gacChangeHistoryReportResultData.pages.next;
          const resNext = searchResultNextOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultNextOffset.slice(7, resNext)
          };
          request({
            api: 'gacChangeHistorySearch',
            method: 'post',
            data: showForTypePayload ? { ...updatedRequest, type: 'gacChangeHistory' } : updatedRequest
          }, dispatch).then((response) => {
            dispatch(actions.updateGACChangeHistoryRequestBodyOnSameBookmark(updatedRequest));
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
            dispatch(toggleSpinner(false));
            dispatch(actions.updateGACChangeHistoryReportNextResult({
              response, currentResultSet
            }));
          }).catch((error) => {
            dispatch(toggleSpinner(false));
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
            console.log('NEXT Error ', error);
          });
        }
      }
    );
  }
  function ChangeReportsDataNextSet() {
    return createAsyncThunk(
      `${name}/ChangeReportsDataNextSet`,
      async (
        {
          gacFetchGACChangeHistoryReportNextResultSet
        }, { dispatch }
      ) => {
        dispatch(gacFetchGACChangeHistoryReportNextResultSet());
      }
    );
  }
  function fetchReportPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportPrevResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.gacChangeHistoryReport
                && state.gacChangeHistoryReport.gacChangeHistoryReportResultData
                && state.gacChangeHistoryReport.gacChangeHistoryReportResultData.pages.prev) {
          const currentResultSet = state.gacChangeHistoryReport
            .gacChangeHistoryReportResultData;
          let updatedRequest = state.gacChangeHistoryReport
            .gacChangeHistoryReportRequestBody;
          const searchResultPrevOffset = state.gacChangeHistoryReport
            .gacChangeHistoryReportResultData.pages.prev;
          const res = searchResultPrevOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultPrevOffset.slice(7, res)
          };
          dispatch(togglePaginationSpinner({ isFetchingPrevData: true }));
          request({
            api: 'gacChangeHistorySearch',
            method: 'post',
            data: showForTypePayload ? { ...updatedRequest, type: 'gacChangeHistory' } : updatedRequest
          }, dispatch).then((response) => {
            dispatch(actions.updateGACChangeHistoryRequestBodyOnSameBookmark(updatedRequest));
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
            dispatch(toggleSpinner(false));
            dispatch(actions.updateGACChangeHistoryReportPrevResult({
              response, currentResultSet
            }));
          }).catch((error) => {
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
            dispatch(toggleSpinner(false));
            console.log('PREV Error ', error);
          });
        }
      }
    );
  }
  function ChangeReportsDataPrevSet() {
    return createAsyncThunk(
      `${name}/ChangeReportsDataPrevSet`,
      async (
        {
          gacfetchReportPrevResultSet
        }, { dispatch }
      ) => {
        dispatch(gacfetchReportPrevResultSet());
      }
    );
  }
  function getGacReasonCode() {
    return createAsyncThunk(
      `${name}/getGacReasonCode`,
      async ({
        callback
      }, { dispatch }) => {
        const payload = {};
        dispatch(toggleSpinner(true));
        request({
          api: 'getGACReasonCode',
          method: 'get'
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          payload.gacReasonCode = response.data;
          if (callback) {
            callback(response);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }
  return {
    fetchGACChangeHistoryReports: fetchGACChangeHistoryReports(),
    fetchGACChangeHistorySubTable: fetchGACChangeHistorySubTable(),
    exportGACChangeHistoryReport: exportGACChangeHistoryReport(),
    fetchSavedGACChangeHistoryReportResults: fetchSavedGACChangeHistoryReportResults(),
    fetchGACChangeHistoryReportsResultsOffset: fetchGACChangeHistoryReportsResultsOffset(),
    gacChangeHistoryReportDataOffset: gacChangeHistoryReportDataOffset(),
    fetchGACChangeHistoryReportNextResultSet: fetchGACChangeHistoryReportNextResultSet(),
    ChangeReportsDataNextSet: ChangeReportsDataNextSet(),
    fetchReportPrevResultSet: fetchReportPrevResultSet(),
    ChangeReportsDataPrevSet: ChangeReportsDataPrevSet(),
    getGacReasonCode: getGacReasonCode()
  };
}

export const extraActions = createExtraActions();

export const gacChangeReportActions = { ...actions, ...extraActions };
export const gacChangeReportReducer = slice.reducer;
