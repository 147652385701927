/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import {
  updateDialogStates,
  toggleSpinner, togglePaginationSpinner
} from '../dialogStates/dialogStatesSlice';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import { poReportsHeader } from '../../constants/ppmReportsHeader';
import {
  mapInputFieldsToRequest, mapRequestToInputFields, checkForColumnData, setValidFields
} from '../../helpers/dataHelpers';
import { poReportFieldList, initialReportFieldsState } from '../../constants/staticData';
import appConfig from '../../appConfig';
import collabReportFieldProperties from '../../constants/fieldProperties/collabReportFieldProperties';

export const name = 'poReport';

function createInitialState() {
  return {
    collabReportResultData: {},
    collabReportSearchId: '',
    collabReportTotalCount: 0,
    collabReportNextResults: {},
    collabReportPrevResults: {},
    page: 0,
    rowsPerPage: 100,

    isSavedSearchModified: false,
    collabReportRequestBody: null,
    collabReportInputFieldData: { ...initialReportFieldsState },
    collabReportSelectedFields: [],
    collabReportBookmarkName: '',
    collabReportBookmarkId: '',
    requestError: false
  };
}

export const initialState = createInitialState();

function createReducers() {
  function updateCollabReportPage(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_PAGE
    return {
      ...state,
      page: action.payload?.page ? action.payload?.page : action.payload
    };
  }

  function updateCollabReportRowsPerPage(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_ROWS_PER_PAGE
    return {
      ...state,
      rowsPerPage: action.payload?.rowsPerPage ? action.payload?.rowsPerPage : action.payload
    };
  }

  function updateCollabReportRequestBody(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_REQUEST_BODY
    const payload = {
      collabReportRequestBody: action.payload.collabReportRequestBody,
      isSavedSearchModified: action.payload.isSavedSearchModified
    };

    const requestExist = JSON.stringify(state.collabReportRequestBody);
    return {
      ...state,
      collabReportRequestBody: payload.collabReportRequestBody,
      isSavedSearchModified: Boolean(
        state.collabReportBookmarkId && requestExist !== JSON.stringify(
          payload.collabReportRequestBody
        )
      )
    };
  }

  function updateIsSavedSearchModified(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: Boolean(action.payload)
    };
  }

  function updateCollabReportRequestBodyOnSameBookmark(state: Object, action: Object) {
    // UPDATE_COLLAB_SEARCH_REQUEST_ON_SAME_BOOKMARK
    return {
      ...state,
      collabReportRequestBody: action.payload
    };
  }

  function resetReportResults(state: Object) {
    // RESET_REPORT_RESULTS
    return {
      ...state,
      collabReportResultData: {},
      collabReportSearchId: '',
      collabReportTotalCount: 0,
      collabReportNextResults: {},
      collabReportPrevResults: {},
      page: 0,
      rowsPerPage: 100
    };
  }

  function resetReportRequest() {
    // RESET_REPORT_REQUEST
    return {
      isSavedSearchModified: false,
      collabReportRequestBody: null,
      collabReportInputFieldData: { ...initialReportFieldsState },
      collabReportSelectedFields: [],
      collabReportBookmarkName: '',
      collabReportBookmarkId: '',
      requestError: false
    };
  }

  function resetPOReportRequestForClearSearch(state: Object) {
    // RESET_PO_REPORT_REQUEST_DATA_CLEAR_SEARCH
    return {
      ...state,
      isSavedSearchModified: false,
      collabReportRequestBody: null,
      collabReportInputFieldData: { ...initialReportFieldsState },
      collabReportSelectedFields: [...new Set(state.collabReportSelectedFields)],
      collabReportBookmarkName: '',
      collabReportBookmarkId: '',
      requestError: false
    };
  }

  function updateCollabReportFields(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_SELETED_FIELDS
    return {
      ...state, collabReportSelectedFields: action.payload
    };
  }

  function updateCollabReportInputValues(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_INPUT_VALUES
    return { ...state, collabReportInputFieldData: action.payload };
  }

  function updateCollabBookmarkId(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_BOOKMARK_ID
    return { ...state, isSavedSearchModified: false, collabReportBookmarkId: action.payload };
  }

  function clearSavedCollabReport(state: Object) {
    // CLEAR_COLLAB_REPORT_BOOKMARK
    return {
      ...state,
      isSavedSearchModified: false,
      collabReportBookmarkId: '',
      collabReportBookmarkName: ''
    };
  }

  function updateCollabBookmarkName(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_BOOKMARK_NAME
    return { ...state, isSavedSearchModified: false, collabReportBookmarkName: action.payload };
  }

  function updateCollabReportResults(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.data),
      dataNext: null
    };

    return {
      ...state,
      collabReportResultData: payload.data,
      collabReportTotalCount: payload.data.pages.totalResources,
      collabReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      collabReportNextResults: null,
      collabReportPrevResults: null
    };
  }

  function updateCollabReportSearchId(state: Object) {
    // UPDATE_COLLAB_REPORT_SEARCH_ID
    return { ...state, collabReportSearchId: cryptoRand().toString().substr(2, 8) };
  }

  function resetCollabReportInputData(state: Object) {
    // RESET_COLLAB_REPORT_INPUT_VALUES
    return {
      ...state,
      collabReportInputFieldData: { ...initialReportFieldsState }
    };
  }

  function updateCollabReportNextResult(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_NEXT_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataNext: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      collabReportResultData: payload.data,
      collabReportNextResults: payload.dataNext,
      collabReportTotalPages: payload.data.pages.totalPages,
      collabReportTotalCount: payload.data.pages.totalResources
    };
  }

  function updateCollabReportPrevResult(state: Object, action: Object) {
    // UPDATE_COLLAB_REPORT_PREV_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataPrev: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      collabReportResultData: payload.data,
      collabReportPrevResults: payload.dataPrev,
      collabReportTotalPages: payload.data.pages.totalPages,
      collabReportTotalCount: payload.data.pages.totalResources
    };
  }

  function PoReportOffset(state: Object, action: Object) {
    // PO_REPORT_RESULT_OFFSET
    const payload = {
      data: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      collabReportResultData: payload.data,
      collabReportTotalCount: payload.data.pages.totalResources,
      collabReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      collabReportNextResults: null,
      collabReportPrevResults: null
    };
  }

  function appendNextResultSet(state: Object) {
    // UPDATE_COLLAB_REPORT_NEXT_APPEND
    const updatedCurrentResultSet = state.collabReportResultData;
    const updatedNextResultSet = state.collabReportNextResults;

    const payload = {
      dataPrev: updatedCurrentResultSet,
      data: updatedNextResultSet
    };

    return {
      ...state,
      collabReportResultData: payload.data,
      collabReportPrevResults: payload.dataPrev,
      collabReportNextResults: null
    };
  }
  // poSearchResultReducer
  // function PoSearchResultsPrev(state: Object, action: Object) {
  //   // PO_SEARCH_RESULT_PREV_SUCCESS
  //   const payload = {
  //     data: action.payload.currentResultSet,
  //     dataPrev: getflattenResponse(action.payload.response.data)
  //   };
  // }

  function appendPrevResultSet(state: Object) {
    // UPDATE_COLLAB_REPORT_PREV_APPEND
    const updatedPrevResultSet = state.collabReportPrevResults;
    const updatedCurrentResultSet = state.collabReportResultData;

    const payload = {
      data: updatedPrevResultSet,
      dataNext: updatedCurrentResultSet
    };

    return {
      ...state,
      collabReportResultData: payload.data,
      collabReportNextResults: payload.dataNext,
      collabReportPrevResults: null
    };
  }

  return {
    updateCollabReportPage,
    updateCollabReportRowsPerPage,
    updateCollabReportRequestBody,
    updateIsSavedSearchModified,
    updateCollabReportRequestBodyOnSameBookmark,
    resetReportResults,
    resetReportRequest,
    resetPOReportRequestForClearSearch,
    updateCollabReportFields,
    updateCollabReportInputValues,
    updateCollabBookmarkId,
    clearSavedCollabReport,
    updateCollabBookmarkName,
    updateCollabReportResults,
    updateCollabReportSearchId,
    resetCollabReportInputData,
    updateCollabReportNextResult,
    updateCollabReportPrevResult,
    PoReportOffset,
    appendNextResultSet,
    // PoSearchResultsPrev,
    appendPrevResultSet
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function fetchPOReports() {
    return createAsyncThunk(
      `${name}/fetchPOReports`,
      async ({
        data,
        callback,
        modifiedrequestBody = {},
        snapshot = false
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const secondaryFields = [];
        const fields = poReportsHeader
          .filter((header) => header.default)
          .map((header) => {
            if (header.secondary) {
              secondaryFields.push(header.secondary);
            }
            return header.primary;
          });
        const state = getState();
        let requestData = {};
        const {
          selectedColumnOrderOptions: columnOrderOptions,
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};

        const fieldsForRequest = checkForColumnData(state, true)
          ? columnOrderOptions
          : [...fields, ...secondaryFields];
        if (data) {
          requestData = mapInputFieldsToRequest(data, 'collabReport');
          const appliedSearchReportField = [...new Set(
            requestData.map((field) => field.fieldName)
          )];
          dispatch(actions.updateCollabReportInputValues(data));
          dispatch(actions.updateCollabReportFields(poReportFieldList
            .map((field) => field.key)
            .filter((field) => appliedSearchReportField.includes(field))));
        } else {
          requestData = state.poReport.collabReportRequestBody.search;
        }

        const requestSearchType = snapshot ? 'COLLAB_REPORT_RESTRICT_USERID' : 'COLLAB_REPORT';
        const isSavedSearchModifiedCheck = snapshot !== true;
        let requestBody = (state.poReport.collabReportRequestBody)
          ? {
            ...state.poReport.collabReportRequestBody,
            search: requestData,
            searchType: requestSearchType,
            offset: '0',
            ...modifiedrequestBody
          }
          : {
            fields: fieldsForRequest,
            search: requestData,
            filter: [],
            count: appConfig.SearchThresholdLimit,
            offset: '0',
            searchType: requestSearchType,
            ...modifiedrequestBody
          };
        requestBody = setValidFields(collabReportFieldProperties, requestBody);

        request({
          api: 'collaborationReportSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            dispatch(
              actions.updateCollabReportRequestBody({
                collabReportRequestBody: requestBody,
                isSavedSearchModified: isSavedSearchModifiedCheck
              })
            );
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(response.data);
            }
            dispatch(actions.updateCollabReportResults(response));
            dispatch(actions.updateCollabReportRowsPerPage(rowsPerPageOption));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(null, error);
            }
            console.log(error);
          });
      }
    );
  }

  function exportPOReport() {
    return createAsyncThunk(
      `${name}/exportPOReport`,
      async ({
        callback,
        filetype
      }, { getState, dispatch }) => {
        const state = getState();
        dispatch(toggleSpinner(true));
        const searchRequestBody = {
          ...state.poReport.collabReportRequestBody
        };
        const requestBody = {
          search: [...(searchRequestBody.search) || []],
          fields: [...(searchRequestBody.fields) || []].filter((field: string) => (
            !appConfig.excludedPOReportFieldsInExportedFile.includes(field)
          )),
          filter: [...(searchRequestBody.filter) || []],
          searchType: searchRequestBody.searchType,
          fileFormat: filetype
        };
        request({
          api: 'collaborationReportExportLargeFile',
          method: 'post',
          data: requestBody
        }, dispatch, getState)
          .then((response) => {
            if (callback) {
              callback(response);
            }
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            if (callback) {
              callback(null, error);
            }
            dispatch(toggleSpinner(false));
          });
      }
    );
  }

  function fetchSavedCollabReportResults() {
    return createAsyncThunk(
      `${name}/fetchSavedCollabReportResults`,
      async ({
        collabReportRequestData,
        callback
      }, { getState, dispatch }) => {
        const state = getState();
        let requestBody = { ...collabReportRequestData.searchCriteria, offset: '0' };
        requestBody = setValidFields(collabReportFieldProperties, requestBody);
        dispatch(toggleSpinner(true));
        dispatch(updateDialogStates({ executeBookmarkSpinner: true }));
        const {
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};
        request({
          api: 'collaborationReportSearch',
          method: 'post',
          data: {
            savedSearchID: collabReportRequestData.id,
            ...requestBody
          },
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            const fields = mapRequestToInputFields([
              ...collabReportRequestData.searchCriteria.search
            ]);
            const requestedSearchReportField = [...new Set(
              collabReportRequestData.searchCriteria.search.map((field) => field.fieldName)
            )];
            dispatch(actions.updateCollabReportInputValues(
              fields
            ));
            dispatch(actions.updateCollabReportRequestBody({
              collabReportRequestBody: requestBody
            }));
            dispatch(actions.updateCollabReportFields(poReportFieldList
              .map((field) => field.key)
              .filter((field) => requestedSearchReportField.includes(field))));
            dispatch(actions.updateCollabReportResults(response));
            dispatch(actions.updateCollabReportSearchId());
            dispatch(actions.updateCollabReportPage(0));
            dispatch(actions.updateCollabReportRowsPerPage(rowsPerPageOption));
            dispatch(actions.updateCollabBookmarkId(collabReportRequestData.id));
            dispatch(actions.updateCollabBookmarkName(collabReportRequestData.name));
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
            if (callback) {
              callback(response);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function fetchPOreportsResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchPOreportsResultsOffset`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        // TODO
        if (state.poReport.collabReportRequestBody
          && state.poReport.collabReportResultData) {
          let updatedRequest = state.poReport.collabReportRequestBody;
          // delete updatedRequest.offset;
          updatedRequest = { ...updatedRequest, offset: '0' };

          dispatch(toggleSpinner(true));
          request({
            api: 'collaborationReportSearch',
            method: 'post',
            data: updatedRequest
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.PoReportOffset({ response }));
              dispatch(toggleSpinner(false));
              dispatch(actions.updateCollabReportSearchId());
            })
            .catch((error) => {
              console.log('NEXT Error ', error);
              dispatch(toggleSpinner(false));
            });
        }
      }
    );
  }

  function PoReportDataOffset() {
    return createAsyncThunk(
      `${name}/PoReportDataOffset`,
      async ({
        poFetchPOreportsResultsOffset
      }, { dispatch }) => {
        dispatch(poFetchPOreportsResultsOffset());
      }
    );
  }

  function fetchReportNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportNextResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        // TODO
        if (state.poReport.collabReportRequestBody && state.poReport.collabReportResultData
          && state.poReport.collabReportResultData.pages.next) {
          const currentResultSet = state.poReport.collabReportResultData;
          let updatedRequest = state.poReport.collabReportRequestBody;
          const searchResultNextOffset = state.poReport.collabReportResultData.pages.next;
          // delete updatedRequest.offset;
          const resNext = searchResultNextOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultNextOffset.slice(7, resNext)
          };

          dispatch(togglePaginationSpinner({ isFetchingNextData: true }));
          request({
            api: 'collaborationReportSearch',
            method: 'post',
            data: updatedRequest
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.updateCollabReportRequestBodyOnSameBookmark(updatedRequest));
              dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
              dispatch(toggleSpinner(false));
              dispatch(actions.updateCollabReportNextResult({ response, currentResultSet }));
            })
            .catch((error) => {
              dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
              console.log('NEXT Error ', error);
            });
        }
      }
    );
  }

  function reportsDataNextSet() {
    return createAsyncThunk(
      `${name}/reportsDataNextSet`,
      async ({
        poFetchReportNextResultSet
      }, { dispatch }) => {
        dispatch(poFetchReportNextResultSet());
      }
    );
  }

  function fetchReportPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportPrevResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.poReport && state.poReport.collabReportResultData
          && state.poReport.collabReportResultData.pages.prev) {
          const currentResultSet = state.poReport.collabReportResultData;
          let updatedRequest = state.poReport.collabReportRequestBody;
          const searchResultPrevOffset = state.poReport.collabReportResultData.pages.prev;
          // delete updatedRequest.offset;
          const res = searchResultPrevOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultPrevOffset.slice(7, res)
          };

          dispatch(togglePaginationSpinner({ isFetchingPrevData: true }));
          request({
            api: 'collaborationReportSearch',
            method: 'post',
            data: updatedRequest
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.updateCollabReportRequestBodyOnSameBookmark(updatedRequest));
              dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
              dispatch(toggleSpinner(false));
              dispatch(actions.updateCollabReportPrevResult({ response, currentResultSet }));
            })
            .catch((error) => {
              dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
              console.log('PREV Error ', error);
            });
        }
      }
    );
  }

  function reportsDataPrevSet() {
    return createAsyncThunk(
      `${name}/reportsDataPrevSet`,
      async ({
        poFetchReportPrevResultSet
      }, { dispatch }) => {
        dispatch(poFetchReportPrevResultSet());
      }
    );
  }

  return {
    fetchPOReports: fetchPOReports(),
    exportPOReport: exportPOReport(),
    fetchSavedCollabReportResults: fetchSavedCollabReportResults(),
    fetchPOreportsResultsOffset: fetchPOreportsResultsOffset(),
    PoReportDataOffset: PoReportDataOffset(),
    fetchReportNextResultSet: fetchReportNextResultSet(),
    reportsDataNextSet: reportsDataNextSet(),
    fetchReportPrevResultSet: fetchReportPrevResultSet(),
    reportsDataPrevSet: reportsDataPrevSet()
  };
}

export const extraActions = createExtraActions();

export const poReportActions = { ...actions, ...extraActions };
export const poReportReducer = slice.reducer;
