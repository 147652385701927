import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import fieldMapping from '../../constants/fieldMappings/poSearchFieldMapping';
import request from '../../services/request';
import collabReportFieldMapping from '../../constants/fieldMappings/collabReportFieldMapping';
import { myRequestTableHeader } from '../../constants/dataTableHeader';
import myRequestTableFieldProperties from '../../constants/fieldProperties/myRequestTableFieldProperties';
import { mapFiltersToRequest, getFilterValuesFromResponse } from '../../helpers/dataHelpers';
import appConfig from '../../appConfig';
import { settingsActions } from '../profileSettings/settingSlice';
import {
  toggleSpinner
} from '../dialogStates/dialogStatesSlice';
import { conversationThreadsActions } from '../conversationThread/conversationThreadSlice';
import { fileUDActions } from '../fileUD/fileUDSlice';
import { externalLinkActions } from '../externalLink/externalLinkSlice';

const name = 'Dashboard';
function createInitialState() {
  return {
    dashboardResult: [],
    spinner: false,
    requestSpinner: false,
    error: false,
    request: [],
    requestNextResults: {},
    requestPrevResults: {},
    requestTotalCount: 0,
    filterDropdownValues: {},
    myRequestFilterId: 'none',
    selectedFilters: {
      // [fieldMapping.PO_CHANGE_STATUS]: [],
      [fieldMapping.PRODUCT_CODE_REQUEST]: [],
      [fieldMapping.DOC_TYPE_CODE]: [],
      [fieldMapping.CURRENT_GAC_REASON_CODE]: [],
      [fieldMapping.VENDOR_CODE_REQUEST]: []
      // [fieldMapping.PO_DATA_ITEM_NUMBER]: []
    },
    body: null,
    announcement: {}
  };
}
function createReducers() {
  function updateDashboardRequestFilterId(state) {
    return { ...state, myRequestFilterId: cryptoRand().toString().substr(2, 8) };
  }
  // function dashboardData(state, action) {
  //   return {
  //     ...state,
  //     DashboardData: action.payload
  //   };
  // }
  function dashboardAnnouncement(state, action) {
    return {
      ...state,
      announcement: action.payload
    };
  }
  function requestPageSpinner(state, action) {
    return {
      ...state,
      requestSpinner: action.payload
    };
  }
  function dashboardResult(state, action) {
    return {
      ...state,
      dashboardResult: action.payload
    };
  }
  function dashboardSpinner(state, action) {
    return {
      ...state,
      spinner: action.payload
    };
  }
  function dashboardFailure(state, action) {
    return {
      ...state,
      error: action.payload,
      spinner: false
    };
  }
  function addAllFilterValue(state, action) {
    if (action.payload.values instanceof Array && action.payload.filter) {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.filter]: action.payload.values
        }
      };
    }
    return { ...state };
  }
  function updateApprovalsFiltersInRequest(state, action) {
    const updatedBody = { ...state.body, filter: action.payload };
    return { ...state, body: updatedBody };
  }
  function updateSearchRequestBody(state, action) {
    return {
      ...state,
      body: action.payload
    };
  }
  function resetFilterToInitialState(state) {
    return {
      ...state,
      dashboardResult: [],
      filterDropdownValues: {}
    };
  }
  function requestUpdateFilterDropdownValues(state, action) {
    if (
      action.payload
      && Object.keys(action.payload).length > 0
    ) {
      return {
        ...state,
        filterDropdownValues: action.payload
      };
    }
    return { ...state };
  }

  function dashboardRequestPage(state, action) {
    return {
      ...state,
      request: action.payload.objects,
      dashboardMyRequestPrevOffset: action.payload.pages.prev,
      dashboardMyRequestNextOffset: action.payload.pages.next,
      selectedRecordsCount: 0,
      requestTotalCount: action.payload.pages.totalResources,
      dashboardMyRequestNextResults: null,
      dashboardMyRequestPrevResults: null
    };
  }
  function updateDashboardMyRequestNextResult(state, action) {
    return {
      ...state,
      request: action.payload.data,
      requestNextResults: action.payload.dataNext.objects,
      dashboardMyRequestPrevOffset: action.payload.dataNext.pages.prev,
      dashboardMyRequestNextOffset: action.payload.dataNext.pages.next,
      dashboardMyRequestTotalPages: action.payload.dataNext.pages.totalPages,
      requestTotalCount: action.payload.dataNext.pages.totalResources
    };
  }
  function updateApprovalNextResultsAppend(state, action) {
    return {
      ...state,
      request: action.payload.data,
      requestPrevResults: action.payload.dataPrev,
      requestNextResults: null
    };
  }
  function updateDashboardApprovalPrevResult(state, action) {
    return {
      ...state,
      request: action.payload.data,
      requestPrevResults: action.payload.dataPrev.objects,
      dashboardMyRequestPrevOffset: action.payload.dataPrev.pages.prev,
      dashboardMyRequestNextOffset: action.payload.dataPrev.pages.next,
      dashboardMyRequestTotalPages: action.payload.dataPrev.pages.totalPages,
      requestTotalCount: action.payload.dataPrev.pages.totalResources

    };
  }
  function updateApprovalPrevResultsAppend(state, action) {
    return {
      ...state,
      request: action.payload.data,
      requestNextResults: action.payload.dataNext,
      requestPrevResults: null
    };
  }
  function updateApprovalContinuationToken(state, action) {
    return {
      ...state,
      continuationToken: action.payload
    };
  }
  function dashboardMyRequestOffset(state, action) {
    return {
      ...state,
      request: action.payload.objects,
      dashboardMyRequestPrevOffset: action.payload.pages.prev,
      dashboardMyRequestNextOffset: action.payload.pages.next,
      selectedRecordsCount: 0,
      requestTotalCount: action.payload.pages.totalResources,
      dashboardMyRequestNextResults: null,
      dashboardMyRequestPrevResults: null
    };
  }
  function appendPrevResultSet(state) {
    const updatedCurrentResultSet = state.request;
    const updatedPrevResultSet = state.requestPrevResults;
    const payload = {
      data: updatedCurrentResultSet,
      dataNext: updatedPrevResultSet
    };

    return {
      ...state,
      request: payload.data,
      requestNextResults: payload.dataNext,
      requestPrevResults: null
    };
  }
  function appendDashboardNextResultSet(state) {
    const updatedCurrentResultSet = state.request;
    const updatedNextResultSet = state.requestNextResults;
    const payload = {
      data: updatedCurrentResultSet,
      dataNext: updatedNextResultSet
    };

    return {
      ...state,
      request: payload.data,
      requestPrevResults: payload.dataPrev,
      requestNextResults: null
    };
  }

  return {
    resetFilterToInitialState,
    requestUpdateFilterDropdownValues,
    dashboardRequestPage,
    updateDashboardRequestFilterId,
    dashboardSpinner,
    dashboardFailure,
    updateApprovalNextResultsAppend,
    dashboardResult,
    dashboardAnnouncement,
    requestPageSpinner,
    // dashboardData,
    addAllFilterValue,
    updateApprovalContinuationToken,
    updateApprovalPrevResultsAppend,
    updateDashboardApprovalPrevResult,
    updateDashboardMyRequestNextResult,
    updateApprovalsFiltersInRequest,
    updateSearchRequestBody,
    dashboardMyRequestOffset,
    appendPrevResultSet,
    appendDashboardNextResultSet
  };
}

export const initialState = createInitialState();
export const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };
const filterFunc = (filter) => {
  const filterInResponse = {};
  const fieldProperties = myRequestTableFieldProperties[filter] || {};
  if (!fieldProperties) {
    return null;
  }
  filterInResponse.primary = fieldProperties.primary;
  if (fieldProperties.secondary) {
    filterInResponse.secondary = fieldProperties.secondary;
  }
  return filterInResponse;
};
function createExtraActions() {
  function getDashboardResults() {
    return createAsyncThunk(
      `${name}/getDashboardResults`,
      async (_, { dispatch }) => {
        dispatch(actions.dashboardSpinner(true));
        request({
          api: 'DASHBOARD',
          method: 'get'
        }, dispatch).then((response) => {
          if (Object.keys(response.data).length === 0) {
            dispatch(settingsActions.createUserProfile((profile) => {
              if (profile) {
                dispatch(getDashboardResults());
              } else {
                dispatch(actions.dashboardSpinner(false));
              }
            }));
          } else {
            dispatch(actions.dashboardSpinner(false));
            dispatch(actions.dashboardResult(response.data));
            // dispatch(callback(response));
          }
        }).catch((error) => {
          console.log('Error: ', error);
          dispatch(actions.dashboardFailure(error));
        });
      }
    );
  }
  function getDashboardAnnouncementData() {
    return createAsyncThunk(
      `${name}/getDashboardAnnouncementData`,
      async (_, { dispatch }) => {
        request({
          api: 'ANNOUNCEMENT',
          method: 'get'
        }, dispatch).then((response) => {
          dispatch(actions.dashboardAnnouncement(response?.data.announcement));
        }).catch((error) => {
          console.log('Error: ', error);
        });
      }
    );
  }
  function fetchDashboardRequestResults() {
    return createAsyncThunk(
      `${name}/fetchDashboardRequestResults`,
      async (props, { getState, dispatch }) => {
        const { statusCode, searchtype, callback } = props;
        dispatch(actions.resetFilterToInitialState());
        dispatch(actions.requestPageSpinner(true));
        const state = getState();
        const fields = [];
        myRequestTableHeader.forEach((headRow) => {
          fields.push(headRow.primary);
          const secondaryFields = {};
          if (headRow.secondary && !secondaryFields[headRow.secondary]) {
            secondaryFields[headRow.secondary] = true;
            fields.push(headRow.secondary);
          }
        });
        const requestBody = {
          search: [{
            fieldName: 'poData.sizes.changeStatusCode',
            operator: '=',
            fieldValue: statusCode
          }],
          fields: [...fields, collabReportFieldMapping.REPORT_ISLINE],
          filter: [],
          filtersInResponse: Object.keys(state.Dashboard.selectedFilters)
            .filter((filter) => state.Dashboard.selectedFilters[filter])
            .map((filter) => filterFunc(filter))
            .filter((element) => element),
          count: appConfig.SearchThresholdLimit,
          offset: 0,
          searchType: searchtype
        };
        if (statusCode === 'OverDueGAC') {
          requestBody.search = [{
            fieldName: 'poData.sizes.changeStatusCode',
            operator: '=',
            fieldValue: 'Pending'
          },
          {
            fieldName: 'poData.sizes.gacOverdueIndicator',
            operator: '=',
            fieldValue: 'Yes'
          }
          ];
        }
        request({
          api: 'collaborationReportSearch',
          method: 'post',
          data: requestBody
        }, dispatch).then((response) => {
          if (response) {
            dispatch(actions.requestPageSpinner(false));
            dispatch(actions.updateSearchRequestBody(requestBody));
            dispatch(actions.dashboardRequestPage(getflattenResponse(response.data)));
            dispatch(actions.requestUpdateFilterDropdownValues(
              getFilterValuesFromResponse(response.data.filters)
            ));
            callback(response);
          }
        }).catch((error) => {
          console.log('Error: ', error);
          dispatch(actions.requestPageSpinner(false));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }
  function approveOrRejectRequest() {
    return createAsyncThunk(
      `${name}/approveOrRejectRequest`,
      async ({
        statusCode, type, ids, approveOrRejectCallback
      }, { getState, dispatch }) => {
        const state = getState();
        dispatch(actions.requestPageSpinner(true));
        const requestBody = {
          ...state.Dashboard.body, changeStatusCode: statusCode
        };
        if (type && ids.length > 0) {
          requestBody.search.push(
            {
              fieldName: 'poData.sizes.sizeId',
              operator: type ? '!=' : '=',
              fieldValue: ids
            }
          );
        }
        request({
          api: 'POAPPROVEORREJECT',
          method: 'put',
          data: type ? requestBody : { ...ids, changeStatusCode: statusCode }
        }, dispatch).then((response) => {
          if (response) {
            dispatch(actions.requestPageSpinner(false));
            approveOrRejectCallback(response);
          }
        }).catch((error) => {
          dispatch(actions.requestPageSpinner(false));
          if (approveOrRejectCallback) {
            approveOrRejectCallback(null, error);
          }
        });
      }
    );
  }
  function exportPoCollab() {
    return createAsyncThunk(
      `${name}/exportPoCollab`,
      async ({
        poCollabCallback, fileType
      }, { getState, dispatch }) => {
        const state = getState();
        const poCollabSearchRequestBody = {
          ...state.Dashboard.body
        };
        const poCollabRequestBody = {
          search: [...(poCollabSearchRequestBody.search) || []],
          fields: [...(poCollabSearchRequestBody.fields) || []].filter((field) => (
            !appConfig.excludedPOReportFieldsInExportedFile.includes(field)
          )),
          filter: [...(poCollabSearchRequestBody.filter) || []],
          searchType: poCollabSearchRequestBody.searchType,
          fileFormat: fileType
        };
        request({
          api: 'collaborationReportExport',
          method: 'post',
          data: poCollabRequestBody
        }, dispatch).then((response) => {
          if (response) {
            poCollabCallback(response);
          }
        }).catch((error) => {
          if (poCollabCallback) {
            poCollabCallback(null, error);
          }
        });
      }
    );
  }
  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async (props, { getState, dispatch }) => {
        const {
          payload,
          applyFiltersCallback,
          shouldRequestFilterValues
        } = props;
        dispatch(actions.requestPageSpinner(true));
        dispatch(actions.addAllFilterValue(payload));
        let state = getState();
        const filtersRequest = mapFiltersToRequest(state.Dashboard.selectedFilters);
        dispatch(actions.updateApprovalsFiltersInRequest(filtersRequest));
        state = getState();
        const requestBody = { ...state.Dashboard.body };
        const selectedFilterKeys = Object.keys(state.Dashboard.selectedFilters)
          .filter((filter) => state.Dashboard.selectedFilters[filter]);
        if (shouldRequestFilterValues && selectedFilterKeys.length > 0) {
          requestBody.filtersInResponse = selectedFilterKeys.map((filter) => filterFunc(filter))
            .filter((element) => element);
        } else {
          delete requestBody.filtersInResponse;
        }
        request({
          api: 'collaborationReportSearch',
          method: 'post',
          data: requestBody
        }, dispatch).then((response) => {
          dispatch(actions.requestPageSpinner(false));
          if (response.data.filters) {
            dispatch(actions.requestUpdateFilterDropdownValues(
              getFilterValuesFromResponse(response.data.filters)
            ));
          }
          dispatch(actions.updateDashboardRequestFilterId());
          dispatch(actions.updateSearchRequestBody(requestBody));
          dispatch(actions.dashboardRequestPage(getflattenResponse(response.data)));
          if (applyFiltersCallback) {
            applyFiltersCallback(response);
          }
        }).catch((error) => {
          dispatch(actions.requestPageSpinner(false));
          if (applyFiltersCallback) {
            applyFiltersCallback(null, error);
          }
        });
      }
    );
  }
  function fetchMyDashboardRequestResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchMyDashboardRequestResultsOffset`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.Dashboard.body && state.Dashboard.request) {
          const updatedRequest = state.Dashboard.body;
          delete updatedRequest.offset;
          updatedRequest.offset = '0';
          dispatch(toggleSpinner(true));
          request({
            api: 'collaborationReportSearch',
            method: 'post',
            data: updatedRequest
          }, dispatch, getState).then((response) => {
            dispatch(actions.dashboardMyRequestOffset(getflattenResponse(response.data)));
            dispatch(toggleSpinner(false));
          }).catch((error) => {
            console.log('Error: ', error);
            dispatch(toggleSpinner(false));
          });
        }
      }
    );
  }
  function dashboardMyRequestDataNextSet() {
    return createAsyncThunk(
      `${name}/dashboardMyRequestDataNextSet`,
      async (
        { dashboardfetchReportNextResultSet },
        { dispatch }
      ) => {
        dispatch(dashboardfetchReportNextResultSet());
      }
    );
  }
  function fetchReportNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportNextResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.Dashboard.body && state.Dashboard.request
          && state.Dashboard.dashboardMyRequestNextOffset) {
          const currentResultSet = state.Dashboard.request;
          const updatedRequest = state.Dashboard.body;
          const searchResultNextOffset = state.Dashboard.dashboardMyRequestNextOffset;
          delete updatedRequest.offset;
          const resNext = searchResultNextOffset.indexOf(',');
          updatedRequest.offset = searchResultNextOffset.slice(7, resNext);

          request({
            api: 'collaborationReportSearch',
            method: 'post',
            data: updatedRequest
          }, dispatch, getState).then((response) => {
            dispatch(actions.updateSearchRequestBody(updatedRequest));
            dispatch(toggleSpinner(false));
            dispatch(actions.updateDashboardMyRequestNextResult(response, currentResultSet));
          }).catch((error) => {
            console.log('Error: ', error);
          });
        }
      }
    );
  }
  function resetDashboard() {
    return createAsyncThunk(
      `${name}/resetDashboard`,
      (currentPage, { getState, dispatch }) => {
        const state = getState();
        const dashboardResult = state && state.Dashboard && state.Dashboard.dashboardResult;
        if (dashboardResult && dashboardResult.id !== null && currentPage !== 'DASHBOARD') {
          dispatch(actions.resetFilterToInitialState());
        }
      }
    );
  }
  function resetAllReduxState() {
    return createAsyncThunk(
      `${name}/resetAllReduxState`,
      (currentPage, { dispatch }) => {
        resetDashboard(currentPage);
        dispatch(conversationThreadsActions.resetConversation(currentPage));
        dispatch(fileUDActions.resetBulkUpload(currentPage));
        dispatch(externalLinkActions.resetExternalLink(currentPage));
      }
    );
  }

  function dashboardDataPrevSet() {
    return createAsyncThunk(
      `${name}/dashboardDataPrevSet`,
      async (
        { dashboardfetchReportPrevResultSet },
        { dispatch }
      ) => {
        dispatch(dashboardfetchReportPrevResultSet());
      }
    );
  }
  function fetchReportPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportPrevResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.Dashboard.body && state.Dashboard.request
          && state.Dashboard.dashboardMyRequestPrevOffset) {
          const currentResultSet = state.Dashboard.request;
          const updatedRequest = state.Dashboard.body;
          const searchResultPrevOffset = state.Dashboard.dashboardMyRequestPrevOffset;
          delete updatedRequest.offset;
          const res = searchResultPrevOffset.indexOf(',');
          updatedRequest.offset = searchResultPrevOffset.slice(7, res);
          request({
            api: 'collaborationReportSearch',
            method: 'post',
            data: updatedRequest
          }, dispatch, getState).then((response) => {
            dispatch(actions.updateSearchRequestBody(updatedRequest));
            dispatch(toggleSpinner(false));
            dispatch(actions.updateDashboardApprovalPrevResult(response, currentResultSet));
          }).catch((error) => {
            console.log('PREV Error ', error);
          });
        }
      }
    );
  }
  // function dashboardMyRequestDataOffset() {
  //   return createAsyncThunk(
  //     `${name}/dashboardMyRequestDataOffset`,
  //     async (_, { getState, dispatch }) => {
  //       request({
  //         api: 'DASHBOARD',
  //         method: 'get'
  //       }, dispatch, getState).then((response) => {
  //         console.log(response);
  //       }).catch((error) => {
  //         console.log('Error: ', error);
  //       });
  //     }
  //   );
  // }
  function dashboardMyRequestDataOffset() {
    return createAsyncThunk(
      `${name}/dashboardMyRequestDataOffset`,
      async (
        { dashboardfetchMyDashboardRequestResultsOffset },
        { dispatch }
      ) => {
        dispatch(dashboardfetchMyDashboardRequestResultsOffset());
      }
    );
  }

  return {
    dashboardMyRequestDataNextSet: dashboardMyRequestDataNextSet(),
    getDashboardResults: getDashboardResults(),
    getDashboardAnnouncementData: getDashboardAnnouncementData(),
    fetchDashboardRequestResults: fetchDashboardRequestResults(),
    approveOrRejectRequest: approveOrRejectRequest(),
    exportPoCollab: exportPoCollab(),
    applyFilters: applyFilters(),
    fetchMyDashboardRequestResultsOffset: fetchMyDashboardRequestResultsOffset(),
    fetchReportNextResultSet: fetchReportNextResultSet(),
    resetAllReduxState: resetAllReduxState(),
    resetDashboard: resetDashboard(),
    dashboardDataPrevSet: dashboardDataPrevSet(),
    fetchReportPrevResultSet: fetchReportPrevResultSet(),
    dashboardMyRequestDataOffset: dashboardMyRequestDataOffset()
  };
}

export const extraActions = createExtraActions();

export const dashboardActions = { ...actions, ...extraActions };
export const dashboardReducer = slice.reducer;
