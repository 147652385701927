/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import { cryptoRand } from '../../helpers/common';
import featureFlagMock from '../mock/featureFlagMock';
import serviceAPI from '../../helpers/serviceAPI';
import appConfig from '../../appConfig';

const name = 'featureFlag';

function createInitialState() {
  return {
    selectedFilters: {
      factoryCode: [],
      managingOffice: [],
      // managingOfficeName: [],
      managingUserID: [],
      managingUserEmail: [],
      managingUser: []
    },
    providerData: [],
    providerList: [],
    featureFlagList: [],
    pmoConfigScreenData: null,
    unfilteredConfigScreenData: null,
    dataId: '',
    featureFlagHistorySubtableResults: {},
    addFactoryDetailDropdownValues: {
      factoryAffiliations: []
    },
    dependencyDropdownValues: {
      dependencyList: []
    }
  };
}

function createReducers() {
  function updatePMOConfigScreen(state: Object, action: Object) {
    // UPDATE_PMO_CONFIG_SCREEN_RESULTS

    const payload = {
      data: action.payload.flattenedResponse,
      filteredResults: action.payload.filteredResults || action.payload.flattenedResponse
    };

    return {
      ...state,
      pmoConfigScreenData: payload.filteredResults,
      unfilteredConfigScreenData: payload.data,
      dataId: cryptoRand().toString().substr(2, 8)
    };
  }

  function updateProviderData(state: Object, action: Object) {
    return {
      ...state,
      providerData: action.payload
    };
  }

  function updateProviderList(state: Object, action: Object) {
    return {
      ...state,
      providerList: action.payload
    };
  }

  function updateFeatureFlagList(state: Object, action: Object) {
    const updatedFeatureFlagList = action.payload.map((item) => {
      const status = item.endTime > item.startTime ? 'Active' : 'Inactive';
      return {
        ...item,
        status
      };
    });
    return {
      ...state,
      featureFlagList: updatedFeatureFlagList
    };
  }

  function resetToInitialState() {
    return {
      ...createInitialState()
    };
  }

  function updateFeatureTogglingHistorySubtableResults(state: Object, action: Object) {
    // UPDATE_FEATURE_TOGGLING__HISTORY_SUBTABLE_RESULTS
    const updatedPayload = action.payload.data.map((item) => {
      // TODO: CONVERT TO LOCAL TIME
      const status = item.endTime > item.startTime ? 'Active' : 'Inactive';
      return {
        ...item,
        status
      };
    });
    const payload = {
      data: updatedPayload,
      dataNext: null
    };
    return {
      ...state,
      featureFlagHistorySubtableResults: payload
    };
  }

  function updatetFactoryAffiliationsDropdown(state: Object, action: Object) {
    return {
      ...state,
      addFactoryDetailDropdownValues: {
        ...state.addFactoryDetailDropdownValues,
        factoryAffiliations: action.payload
      }
    };
  }

  function updateDependencyDropdown(state: Object, action: Object) {
    const values = action.payload?.map((row) => ({
      id: row.featureTag,
      value: row.featureTag
    }));
    return {
      ...state,
      dependencyDropdownValues: {
        ...state.dependencyDropdownValues,
        dependencyList: values
      }
    };
  }

  return {
    updatePMOConfigScreen,
    updateProviderData,
    updateProviderList,
    updateFeatureFlagList,
    resetToInitialState,
    updateFeatureTogglingHistorySubtableResults,
    updatetFactoryAffiliationsDropdown,
    updateDependencyDropdown
  };
}

export const initialState = createInitialState();
export const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function fetchFeatureFlagList() {
    return createAsyncThunk(
      `${name}/fetchFeatureFlagList`,
      async (_, { dispatch }) => {
        dispatch(toggleSpinner(true));
        const query = `?fields=${
          appConfig.featureFlagFields.join(',')
        }&filter=versionType(CURRENT)`;
        request({
          api: 'getFeatureFlag',
          method: 'get',
          routeParams: { query }
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          const configData = response.data.objects;
          // const modifiedConfigData = configData.map((item) => ({
          //   ...item,
          //   dependencyFeatures: ['UPLOAD_TEST', 'PPM_REPORT']
          // }));
          // const featureFlagData = [
          //   ...response.data.objects,
          //   { featureTag: 'ANNOUNCEMENT', endTime: '2024-04-10 11:56:00' },
          //   { featureTag: 'LINKTOOL', endTime: '2024-04-05 11:44:00' }
          // ];
          // featureFlagData
          //   .forEach((item) => {
          //     configData[item.featureTag || item.featureName] = (
          //       // change it to >= to test the feature flag
          //       new Date(item.endTime).getTime()) > (new Date().getTime()
          //     );
          //   });
          dispatch(actions.updateFeatureFlagList(configData));
          dispatch(actions.updateDependencyDropdown(configData));
        }).catch((error) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }

  function fetchFeatureTogglingHistorySubTable() {
    return createAsyncThunk(
      `${name}/fetchFeatureTogglingHistorySubTable`,
      async ({
        rowId, callback
      }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        if (serviceAPI('getFeatureFlag').mock) {
          const filteredData = featureFlagMock.data.objects.filter(
            (x) => x.featureName === rowId
          );
          callback(filteredData);
          dispatch(actions.updateFeatureTogglingHistorySubtableResults({ data: filteredData }));
          dispatch(toggleSpinner(false));
        } else {
          const query = `?fields=${
            appConfig.featureFlagFields.join(',')
          }&filter=versionType(HISTORY),featureTag(${rowId})`;
          request({
            api: 'getFeatureFlag',
            method: 'get',
            routeParams: { query },
            cancellable: true
          }, dispatch).then((response) => {
            // TODO BACKEND NOT PROVIDING EXACT DATA FOR FEATURETAG
            const filteredData = response.data.objects.filter(
              (x) => x.featureTag === rowId
            ).filter((item) => item.versionType === 'HISTORY');
            // const updatedPayload = filteredData.map((item) => ({
            //   ...item,
            //   dependencyFeatures: ['UPLOAD_TEST', 'PPM_REPORT']
            // }));
            callback(filteredData);
            dispatch(actions.updateFeatureTogglingHistorySubtableResults({ data: filteredData }));
            dispatch(toggleSpinner(false));
          }).catch((error) => {
            callback(null, error);
            dispatch(toggleSpinner(false));
          });
        }
      }
    );
  }

  function fetchFeatureFlagFactoryAffiliationsData() {
    return createAsyncThunk(
      `${name}/fetchFeatureFlagFactoryAffiliationsData`,
      async (
        { callback },
        { getState, dispatch }
      ) => {
        request(
          {
            api: 'dropdownLookup',
            method: 'get'
          },
          dispatch,
          getState
        )
          .then((response) => {
            const { partnerNumber } = response.data;
            const factoryCodeList = partnerNumber.map
              ? partnerNumber.map((option) => ({
                id: option.partnerNo,
                value: option.partnerNo
              }))
              : [];
            dispatch(actions.updatetFactoryAffiliationsDropdown(factoryCodeList));
            if (callback) {
              callback(response.data);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function updateFeatureFlagList() {
    return createAsyncThunk(
      `${name}/updateFeatureFlagList`,
      async (
        {
          data,
          callback
        },
        { getState, dispatch }
      ) => {
        // eslint-disable-next-line
        data.forEach((obj) => delete obj.isActive);
        data.forEach((obj) => {
          if (obj.endTime === '' || obj.endTime === null) {
            // eslint-disable-next-line
            delete obj.endTime;
          }
        });
        const updateRequestBody = {
          featureFlag: data
        };
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'featureFlag',
            method: 'put',
            data: updateRequestBody,
            cancellable: true
          },
          dispatch,
          getState
        )
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(response.data);
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function addNewFeatureFlagDetails() {
    return createAsyncThunk(
      `${name}/addNewFeatureFlagDetails`,
      async (
        {
          data,
          callback
        },
        { getState, dispatch }
      ) => {
        const temp = { ...data[0] };
        Object.keys(temp).forEach((key) => {
          if (key === 'groups') {
            temp[key] = temp[key].map((item) => item.id);
          }
          if (key === 'dependencyFeatures') {
            temp[key] = temp[key].map((item) => item.id);
          }
          if (key === 'factoryAffiliations') {
            const factoryValues = temp[key].map((item) => item.id);
            temp[key] = factoryValues;
          }
          if (key === 'startTime') {
            temp[key] = new Date(temp[key]).toISOString();
          }
          if (key === 'endTime') {
            if (temp[key]) {
              temp[key] = new Date(temp[key]).toISOString();
            }
          }
        });
        if (temp.endTime === '' || temp.endTime === null) {
          delete temp.endTime;
        }
        if (temp.dependencyFeatures.length === 0) {
          delete temp.dependencyFeatures;
        }
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'featureFlag',
            method: 'post',
            data: temp,
            cancellable: true
          },
          dispatch,
          getState
        )
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(response.data);
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function fetchProviderData() {
    return createAsyncThunk(
      `${name}/fetchProviderData`,
      async (_, { dispatch }) => {
        dispatch(toggleSpinner(true));
        const query = '?fields=featureTag,endTime,dependencyFeatures&filter=isActive(true)';
        request({
          api: 'getFeatureFlag',
          method: 'get',
          routeParams: { query }
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          const configData = {};
          const featureFlagData = [
            ...response.data.objects
            // { featureTag: 'LINKTOOL', endTime: '2024-04-24 19:50:00' }
          ];
          featureFlagData
            .forEach((item) => {
              configData[item.featureTag] = item.endTime ? (
                // change it to >= to test the feature flag
                new Date(item.endTime).getTime()) > (new Date().getTime()
              ) : true;
            });
          dispatch(actions.updateProviderData(configData));
          dispatch(actions.updateProviderList(featureFlagData));
        }).catch((error) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }

  return {
    fetchFeatureFlagList: fetchFeatureFlagList(),
    fetchProviderData: fetchProviderData(),
    fetchFeatureTogglingHistorySubTable: fetchFeatureTogglingHistorySubTable(),
    fetchFeatureFlagFactoryAffiliationsData: fetchFeatureFlagFactoryAffiliationsData(),
    updateFeatureFlagList: updateFeatureFlagList(),
    addNewFeatureFlagDetails: addNewFeatureFlagDetails()
  };
}

const extraActions = createExtraActions();

export const featureFlagActions = { ...actions, ...extraActions };
export const featureFlagReducer = slice.reducer;
