/**
 * This file is used as Mock data for Conversation Thread.
 * @exports (poItemStatusSelect, vendorFactoryCode, pmoDecCode)
 * @author {Cognizant Technology Solution}
 */
// data for PO item status input from the SearchCriteria Component
export const poItemStatusSelect = [
  { value: 'Unissued', text: 'Unissued', group: 'Open' },
  { value: 'Unaccepted', text: 'Unaccepted', group: 'Open' },
  { value: 'Accepted', text: 'Accepted', group: 'Open' },
  { value: 'Rejected', text: 'Rejected', group: 'Open' },
  { value: 'Closed', text: 'Closed' },
  { value: 'Cancelled', text: 'Cancelled' }
  // { value: 'Unissued,Unaccepted,Accepted,Rejected,Closed,Cancelled', text: 'All' }
];

// data for Vendor (Factory Code) input from the SearchCriteria Component
export const vendorFactoryCode = [
  { id: 'A04', value: 'A04 | MARUBENI-SONGGANG' },
  { id: 'A21', value: 'A21 | WACOAL-JAPAN' },
  { id: 'A22', value: 'A22 | WACOAL-VIETNAM' },
  { id: 'A3', value: 'A3 | ZZ - M.V. SHOES S.R.L.' },
  { id: 'A35', value: 'A35 | NITS-RIKI JAPAN' },
  { id: 'A36', value: 'A36 | RIKI-DIRECT JAPAN' },
  { id: 'A38', value: 'A38 | NITS-KISHI' },
  { id: 'A39', value: 'A39 | NORINO COSER CO., LTD' },
  { id: 'A4', value: 'A4 | ZZ - ADLER CO., LTD' },
  { id: 'A40', value: 'A40 | NITS-YOUNGAR' },
  { id: 'A41', value: 'A41 | NITS-BIG RIVER' },
  { id: 'A42', value: 'A42 | NITS-RIKI THAILAND' },
  { id: 'A44', value: 'A44 | NITS-SATO KNIT' },
  { id: 'A48', value: 'A48 | NITS-GUNZE' },
  { id: 'A50', value: 'A50 | NITS-OTOYO' },
  { id: 'A61', value: 'A61 | ECHO MARK' },
  { id: 'A75', value: 'A75 | U CORPORATION' },
  { id: 'A77', value: 'A77 | ECHO MARK KIRYU' },
  { id: 'AAP', value: 'AAP | APG SRL-F' },
  { id: 'ACA', value: 'ACA | ASPEN CREW SPORTSGEAR' },
  { id: 'ACL', value: 'ACL | ELTA COMPANY LIMITED' },
  { id: 'ADM', value: 'ADM | AUDIMAS AB' },
  { id: 'AF', value: 'AF | DASS ELDORADO SRL' },
  { id: 'AFF', value: 'AFF | ART FX INC' },
  { id: 'AFN', value: 'AFN | ART FX INC' },
  { id: 'AFX', value: 'AFX | ART FX INC' },
  { id: 'AGH', value: 'AGH | ASI GLOBAL LIMITED' },
  { id: 'AGT', value: 'AGT | ANGORA TEXTILE PVT LTD' },
  { id: 'AH', value: 'AH | TECNOSPORT LATINOAMERICANA SA' },
  { id: 'AJB', value: 'AJB | Jiangsu Asian Sourcing Headwear MFG' },
  { id: 'AJP', value: 'AJP | HUAIAN YUANTONG HEADWEAR' },
  { id: 'AKK', value: 'AKK | AKKANAT DURHAL' },
  { id: 'AKN', value: 'AKN | A & K DESIGNS INC' },
  { id: 'AMA', value: 'AMA | AMG APPAREL MFG GROUP LTD.' },
  { id: 'AMJ', value: 'AMJ | N.I. TEIJIN SHOII CO., LTD' },
  { id: 'AML', value: 'AML | ARVIND MILLS LTD KNIT FABRIC' },
  { id: 'AOG', value: 'AOG | COMERCIO E SERVICOS TEXTEIS SA' },
  { id: 'AOM', value: 'AOM | COMERCIO E SERVICOS TEXTEIS S.A.' },
  { id: 'AOT', value: 'AOT | PETRATEX CONFECCOES SA' },
  { id: 'APL', value: 'APL | AMERICAN APPAREL' },
  { id: 'APP', value: 'APP | Aquasea Inc' },
  { id: 'AQ', value: 'AQ | COOPERSHOES COOPERATIVA DE CALCADOS' },
  { id: 'AQU', value: 'AQU | AQUA PRO' },
  { id: 'ASB', value: 'ASB | LISTON TEXTIELDRUKKERIJ BV' },
  { id: 'ASD', value: 'ASD | ASIA DOWN CNTR PANYU LTD' },
  { id: 'ASH', value: 'ASH | ASI GLOBAL LIMITED' },
  { id: 'ASI', value: 'ASI | Y-Brand Athletics Inc.' },
  { id: 'ATE', value: 'ATE | TEXTILES AVANTE' },
  { id: 'ATF', value: 'ATF | ANTAEUS SPORTSWEAR' },
  { id: 'ATM', value: 'ATM | ANTAEUS SPORTSWEAR MEXICO' },
  { id: 'AU', value: 'AU | ZZ - ALPARGATAS CALZADOS S.A. - TUC' },
  { id: 'AV', value: 'AV | ZZ - ALPARGATAS CALZADOS - F. VAREL' },
  { id: 'AW', value: 'AW | ZZ - CALZADO CATAMARCA S.A. (ALP-GR' },
  { id: 'AYT', value: 'AYT | AYTEMIZLER' },
  { id: 'B4', value: 'B4 | ANIGER CALCADOS SUPRIMENTOS E' },
  { id: 'B6', value: 'B6 | DASS NORDESTE CALCADOS E ARTIGOS' },
  { id: 'B8', value: 'B8 | DASS NORDESTE CALCADOS E ARTIGOS' },
  { id: 'BA', value: 'BA | ZZ - BANGKOK RUBBER PUBLIC CO LTD' },
  { id: 'BB', value: 'BB | ZZ - CALCADOS REIFER LTDA' },
  { id: 'BBG', value: 'BBG | BOOLIM BANGLADESH-F2' },
  { id: 'BCO', value: 'BCO | BOOLIM CHINA' },
  { id: 'BD', value: 'BD | DASS NORDESTE CALCADOS' },
  { id: 'BDP', value: 'BDP | BORDAPRINT SA DE CV' },
  { id: 'BEL', value: 'BEL | NINGBO BELL GARMENT INDUSTRIAL CO.,' },
  { id: 'BF', value: 'BF | ZZ - CALCADOS DILLY S.A.' },
  { id: 'BFC', value: 'BFC | WEALTHY KNITTING CO.' },
  { id: 'BFL', value: 'BFL | BEAUTY FOREST LIMITED' },
  { id: 'BG', value: 'BG | COOPERATIVA DE TRABALHO E INDUSTRIA' },
  { id: 'BH', value: 'BH | ZZ - DASS NORDESTE CALCADOS' },
  { id: 'BID', value: 'BID | BOOLIM - KUKDONG INTL, PT' },
  { id: 'BIE', value: 'BIE | BIESSECI' },
  { id: 'BIS', value: 'BIS | BOOLIM GIBOR' },
  { id: 'BJG', value: 'BJG | Tien Pou International Ltd. Taiwan' },
  { id: 'BJV', value: 'BJV | Tien Pou International Ltd. Taiwan' },
  { id: 'BK', value: 'BK | ZZ - CALCADOS REIFER LTDA' },
  { id: 'BL', value: 'BL | ZZ - Beeline' },
  { id: 'BM', value: 'BM | ZZ - CALCADOS REIFER LTDA' },
  { id: 'BML', value: 'BML | BOMBAY LABEL TEXTILE' },
  { id: 'BMX', value: 'BMX | BOOLIM-WOOCHANG' },
  { id: 'BOA', value: 'BOA | SHANTOU YOUNGMAY GARMENT MFG FTY CO' },
  { id: 'BOB', value: 'BOB | BOOLIM BANGLADESH' },
  { id: 'BOC', value: 'BOC | SHANTOU YOUNG MAY GARMENT MFG' },
  { id: 'BOD', value: 'BOD | BIHQ PTE LTD' },
  { id: 'BOG', value: 'BOG | BOOLIM BUYING CORPORATION' },
  { id: 'BOI', value: 'BOI | BOOLIM BUYING CORPORATION-F1' },
  { id: 'BOL', value: 'BOL | BOOLIM BUYING CORPORATION-F2' },
  { id: 'BOO', value: 'BOO | BOOLIM BUYING CORPORATION-F' },
  { id: 'BOS', value: 'BOS | JIANGSU BOSIDENG CO. LTD' },
  { id: 'BOT', value: 'BOT | BOOLIM BUYING CORPORATION TAIWAN' },
  { id: 'BOV', value: 'BOV | BOOLIM BUYING CORPORATION VIETNAM' },
  { id: 'BP', value: 'BP | ANIGER - CALCADOS SUPRIMENTOS E' },
  { id: 'BPG', value: 'BPG | PROVEL LTD.' },
  { id: 'BPM', value: 'BPM | FABRICA DE ARTIGOS DE VESTUARIO VIR' },
  { id: 'BPR', value: 'BPR | BOWKER PACIFIC GARMENTS LTD' },
  { id: 'BQ', value: 'BQ | ZZ - COOPERSHOES COOPERATIVA DE CAL' },
  { id: 'BRV', value: 'BRV | SHANGHAI BIG RIVER KNITWEAR CO., LT' },
  { id: 'BSK', value: 'BSK | BOOLIM SRI LANKA' },
  { id: 'BSL', value: 'BSL | BOOLIM SRI LANKA-F' },
  { id: 'BSR', value: 'BSR | ATOMIK TEKSTIL SAN TIC AS' },
  { id: 'BT', value: 'BT | ZZ - CALCADOS REIFER LTDA' },
  { id: 'BTH', value: 'BTH | BOOLIM NAN YANG THAILAND' },
  { id: 'BUS', value: 'BUS | PT BUSANA PERKASA GARMENTS' },
  { id: 'BV', value: 'BV | ZZ - CALCADOS ANIGER NORDESTE LTDA' },
  { id: 'BVN', value: 'BVN | BOOLIM VIETNAM' },
  { id: 'BW', value: 'BW | ZZ - CALCADOS ANIGER NORDESTE LTDA' },
  { id: 'BWT', value: 'BWT | BEST WALLET and CO SRL' },
  { id: 'BX', value: 'BX | ZZ - CLASSICO IND ARTIGOS ESP LTDA' },
  { id: 'BZ', value: 'BZ | ZZ - CALCADOS ANIGER NORDESTE LTDA' },
  { id: 'CCD', value: 'CCD | CHUAN CHENG HAT CO LTD' },
  { id: 'CCH', value: 'CCH | Chuan Cheng Hat Co., Ltd' },
  { id: 'CCP', value: 'CCP | CCH TOP (VN) Co., Ltd' },
  { id: 'CCV', value: 'CCV | Chuan Cheng Hat Co., Ltd' },
  { id: 'CDA', value: 'CDA | CHANG DAE APPAREL' },
  { id: 'CDE', value: 'CDE | CD EMBRIODERY' },
  { id: 'CDM', value: 'CDM | CD EMBROIDERY-F' },
  { id: 'CDP', value: 'CDP | ECLAT TEXTILE CO LTD (DEMAND PULL)' },
  { id: 'CEI', value: 'CEI | CREATIVE ENTERPRISES HONG KONG LTD' },
  { id: 'CEM', value: 'CEM | ECLAT TEXTILE CO LTD (EMERGING MARK' },
  { id: 'CEU', value: 'CEU | ECLAT TEXTILE CO LTD (EUROPE - SLT)' },
  { id: 'CHB', value: 'CHB | Chuan Cheng Hat Co Limited (Boolim' },
  { id: 'CHI', value: 'CHI | PT. CCH INDONESIA' },
  { id: 'CHN', value: 'CHN | PERUSAHAAN CHAN CHOO SING' },
  { id: 'CIH', value: 'CIH | CIA HERING' },
  { id: 'CIN', value: 'CIN | INCREDIBLE' },
  { id: 'CIT', value: 'CIT | PT CITRA ABADI SEJATI' },
  { id: 'CKT', value: 'CKT | CEYLON KNIT TREND LTD' },
  { id: 'CLH', value: 'CLH | BF KNITTERS LTD' },
  { id: 'CLI', value: 'CLI | CARLETON INVESTMENT LTD' },
  { id: 'CLR', value: 'CLR | CARLETON INVESTMENT LTD-F1' },
  { id: 'CNR', value: 'CNR | ECLAT TEXTILE CO LTD (NORTH AMERICA' },
  { id: 'CRS', value: 'CRS | CELROSE' },
  { id: 'CRT', value: 'CRT | CRAMPTON LTD' },
  { id: 'CSC', value: 'CSC | Dongguan Ye Ji Industrial Co., Ltd.' },
  { id: 'CSS', value: 'CSS | DASS NORDESTE CALC ART ESP S.A.' },
  { id: 'CSV', value: 'CSV | Crystal SL Global Pte Ltd' },
  { id: 'CUE', value: 'CUE | CUTTING EDGE' },
  { id: 'CV', value: 'CV | FALCON UNIVERSAL SERVICES' },
  { id: 'CVB', value: 'CVB | CCH TOP (VN) CO., LTD (BOOLIM PRODU' },
  { id: 'CVT', value: 'CVT | ECLAT TEXTILE CO LTD' },
  { id: 'CYL', value: 'CYL | CEYLAN GIYIM SANAYI VE TICARET' },
  { id: 'D1', value: 'D1 | ZZ - NIKE IHM' },
  { id: 'DAV', value: 'DAV | QUANTUM CLOTHING GROUP LTD' },
  { id: 'DAY', value: 'DAY | Imex Industries Inc.' },
  { id: 'DDB', value: 'DDB | Unipax Company Limited (Boolim Prod' },
  { id: 'DDC', value: 'DDC | Paxico - Dada' },
  { id: 'DDN', value: 'DDN | DADA (SAVAR) LTD' },
  { id: 'DDV', value: 'DDV | Hwaseung Crown Co., Ltd.' },
  { id: 'DEC', value: 'DEC | JIAXING DUOLING GARMENTS CO., LTD.' },
  { id: 'DER', value: 'DER | DER WILL SA' },
  { id: 'DEU', value: 'DEU | UNIPAX CO LTD (EUROPE - SLT)' },
  { id: 'DEX', value: 'DEX | DECOTEX' },
  { id: 'DGT', value: 'DGT | Delta Alfa Corap Ve Tekstil Sanayi' },
  { id: 'DGV', value: 'DGV | DELTA GALIL INDUSTRIES LTD' },
  { id: 'DHA', value: 'DHA | DEWHIRST SPORTSWEAR LIMITED' },
  { id: 'DHB', value: 'DHB | Dewhirst Sportswear LTD' },
  { id: 'DHG', value: 'DHG | Dewhirst Sportswear LTD' },
  { id: 'DHI', value: 'DHI | DH INTERNATIONAL-F' },
  { id: 'DHP', value: 'DHP | DEWHIRST SPORTSWEAR LTD (DEMAND PUL' },
  { id: 'DHQ', value: 'DHQ | DEWHIRST SPORTSWEAR LTD.' },
  { id: 'DJJ', value: 'DJJ | DEHEN JACKETS INC' },
  { id: 'DL3', value: 'DL3 | DELTA GALIL INDUSTRIES LTD. -' },
  { id: 'DLB', value: 'DLB | DELTA GALIL INDUSTRIES LTD' },
  { id: 'DLD', value: 'DLD | DELTA DEMAND PULL' },
  { id: 'DLI', value: 'DLI | Delta Alfa Corap Ve Tekstil Sanayi' },
  { id: 'DLS', value: 'DLS | DELTA GALIL INDUSTRIES LTD. -' },
  { id: 'DLT', value: 'DLT | DELTA GALIL INDUSTRIES LTD. -' },
  { id: 'DMX', value: 'DMX | DOMEX CO LTD' },
  { id: 'DNF', value: 'DNF | DECOTEX' },
  { id: 'DRT', value: 'DRT | DRASTOSA' },
  { id: 'DS', value: 'DS | CHANGSHIN, INC.' },
  { id: 'DSD', value: 'DSD | DELTA GALIL INDUSTRIES LTD' },
  { id: 'DSL', value: 'DSL | DIANSHANHU SILK KNITTING' },
  { id: 'DST', value: 'DST | DOUBLE STAR KNITTING' },
  { id: 'DTB', value: 'DTB | DELTA TEXTILE BULGARIA LTD' },
  { id: 'DUS', value: 'DUS | UNIPAX CO LTD (NORTH AMERICA - SLT)' },
  { id: 'DZD', value: 'DZD | DELTA GALIL INDUSTRIES LTD (EU - SL' },
  { id: 'DZH', value: 'DZH | Delta Galil Industries Limited' },
  { id: 'DZK', value: 'DZK | DZ INTERNATIONAL LTD.-KING DEER' },
  { id: 'DZQ', value: 'DZQ | DELTA GALIL INDUSTRIES LTD' },
  { id: 'E09', value: 'E09 | FENG TAY ENTERPRISES CO.,LTD.' },
  { id: 'E0I', value: 'E0I | A\'Grace International Ltd' },
  { id: 'E0S', value: 'E0S | South Asia Knitting' },
  { id: 'E1E', value: 'E1E | SUMMITOP INDUSTRIAL CO., LTD.' },
  { id: 'E1F', value: 'E1F | SUMMITOP INDUSTRIAL CO., LTD.' },
  { id: 'E1G', value: 'E1G | PREMIER PLASTICS' },
  { id: 'E1H', value: 'E1H | PACIFIC MARKET INTL CG' },
  { id: 'E2I', value: 'E2I | EUROBOTTLE' },
  { id: 'E2P', value: 'E2P | PCH INTERNATIONAL LIMITED' },
  { id: 'E2T', value: 'E2T | TOP-FLITE GOLF COMPANY' },
  { id: 'E3B', value: 'E3B | TRADE HANDBAG PRODUCTS CO. LTD' },
  { id: 'E3G', value: 'E3G | Gibor Sport Active Wear LTD' },
  { id: 'E3T', value: 'E3T | TOP-FLIGHT GOLF COMPANY' },
  { id: 'E3U', value: 'E3U | USL INTERNATIONAL LTD' },
  { id: 'E4I', value: 'E4I | Hochmuth GmbH & Co. KG' },
  { id: 'E4Q', value: 'E4Q | QUICKEX AB' },
  { id: 'E5C', value: 'E5C | KITAI CO., LTD.' },
  { id: 'E5T', value: 'E5T | Nike Vision, Timing and TechLab, LP' },
  { id: 'E6T', value: 'E6T | TAROKO INTERNATIONAL CO., LTD' },
  { id: 'E7I', value: 'E7I | LEDWAR (PVT) LIMITED' },
  { id: 'E9I', value: 'E9I | Procession and Promotion Co. Ltd' },
  { id: 'E9S', value: 'E9S | SPENTA INTERNATIONAL LTD' },
  { id: 'EA3', value: 'EA3 | Aspen Crew Sports Gear' },
  { id: 'EA4', value: 'EA4 | Sports Gear Co., Ltd Taiwan Branch' },
  { id: 'EA9', value: 'EA9 | Sanspareils Greenlands PVT LTD' },
  { id: 'EAB', value: 'EAB | AML GROUP LIMITED' },
  { id: 'EAE', value: 'EAE | PEGATRON MAINTEK SUZHOU' },
  { id: 'EAG', value: 'EAG | Eagle Nice Development Limited' },
  { id: 'EAJ', value: 'EAJ | Atoms' },
  { id: 'EAN', value: 'EAN | FANCO S.A.' },
  { id: 'EAP', value: 'EAP | TWIN LAKES CO., LTD.' },
  { id: 'EAS', value: 'EAS | ASG MANUFACTURING (B.V.I.) LTD' },
  { id: 'EAT', value: 'EAT | EASTMAN EXPORTS GLOBAL CLOTHING (P)' },
  { id: 'EAU', value: 'EAU | YUE MEI GARMENT LTD' },
  { id: 'EAV', value: 'EAV | Eagle Nice Development Limited' },
  { id: 'EAW', value: 'EAW | EAGLE NICE DEVELOPMENT LIMITED' },
  { id: 'EB2', value: 'EB2 | Bridgestone Sporting Co.' },
  { id: 'EB3', value: 'EB3 | Bridgestone Sports Co.' },
  { id: 'EB5', value: 'EB5 | BONAR PLASTICS, INC.' },
  { id: 'EB7', value: 'EB7 | THAT\'S MY BALL' },
  { id: 'EBD', value: 'EBD | BRIDGESTONE SPORTS CO.' },
  { id: 'EBF', value: 'EBF | TOPBALL ENTERPRISES LIMITED' },
  { id: 'EBJ', value: 'EBJ | Bridgestone Sports Co.' },
  { id: 'EC2', value: 'EC2 | CO-UNION INDUSTRY CO., LTD.' },
  { id: 'EC3', value: 'EC3 | CORTINA INTERNATIONAL CORP., Ltd.' },
  { id: 'EC5', value: 'EC5 | CHANG WONG INDUSTRIAL CO., LTD.' },
  { id: 'EC6', value: 'EC6 | Celestica LLC' },
  { id: 'EC7', value: 'EC7 | California Torque Products Inc.' },
  { id: 'EC8', value: 'EC8 | Croster Co. Ltd' },
  { id: 'EC9', value: 'EC9 | Der Ju Trading Company Ltd.' },
  { id: 'ECA', value: 'ECA | CHUAN CHENG HAT CO. LTD.' },
  { id: 'ECC', value: 'ECC | CHUAN CHENG HAT CO., LTD.' },
  { id: 'ECE', value: 'ECE | CE composites' },
  { id: 'ECH', value: 'ECH | CHARLESTON HOSIERY INC' },
  { id: 'ECL', value: 'ECL | ECLAT-V' },
  { id: 'ECP', value: 'ECP | CHOU\'S INDUSTRIAL CO., LTD.' },
  { id: 'ECR', value: 'ECR | CREATIVE ENTERPRISES HONG KONG LTD.' },
  { id: 'ECU', value: 'ECU | CO UNION INDUSTRY CO LTD' },
  { id: 'ECX', value: 'ECX | EASTMAN CLOTHING COMPANY (P) LTD' },
  { id: 'ED1', value: 'ED1 | PRO-CHIEF MANAGEMENT CO. LTD.' },
  { id: 'ED3', value: 'ED3 | IMEX INDUSTRIES, INC.' },
  { id: 'ED4', value: 'ED4 | DELTA GALIL INDUSTRIES LTD' },
  { id: 'ED5', value: 'ED5 | DRASTOSA SA INDUSTRIAS TEXTEIS' },
  { id: 'ED7', value: 'ED7 | PT DAYUP INDO' },
  { id: 'ED9', value: 'ED9 | DER WILL SA' },
  { id: 'EDC', value: 'EDC | ABUNDANT REWARD CO., LTD.' },
  { id: 'EDG', value: 'EDG | FOO-GUO INTERNATIONAL LIMITED' },
  { id: 'EDL', value: 'EDL | DELTA GALIL INDUSTRIES LTD' },
  { id: 'EDO', value: 'EDO | DOMENICO INTERNACIONAL' },
  { id: 'EDP', value: 'EDP | APLUS MANAGEMENT CO., LTD' },
  { id: 'EDS', value: 'EDS | DELTA GALIL INDUSTRIES LTD. -' },
  { id: 'EDT', value: 'EDT | DEJU TRADE COMPANY LTD' },
  { id: 'EDU', value: 'EDU | IMEX INDUSTRIES, INC.' },
  { id: 'EDV', value: 'EDV | DELTA GALIL INDUSTRIES LTD' },
  { id: 'EDY', value: 'EDY | IMEX INDUSTRIES, INC.' },
  { id: 'EE3', value: 'EE3 | Dong Guan Wei Bao Yung Dong' },
  { id: 'EEA', value: 'EEA | EVA OVERSEAS INTERNATIONAL LIMITED' },
  { id: 'EEJ', value: 'EEJ | Jasper Outdoor Products Ltd' },
  { id: 'EEO', value: 'EEO | EVA OVERSEAS INTERNATIONAL LIMITED' },
  { id: 'EES', value: 'EES | Pro Chief Management Co Ltd' },
  { id: 'EEU', value: 'EEU | ETF TEKSTIL KONF IHR TUR SAN VE TIC' },
  { id: 'EF', value: 'EF | FLEXTRONICS MARKETING L LTD' },
  { id: 'EF3', value: 'EF3 | FANTASTIC INTERNATIONAL LTD.' },
  { id: 'EF4', value: 'EF4 | DAISY GOLD GROUP LTD.' },
  { id: 'EF6', value: 'EF6 | KORRUN INTERNATIONAL PTE. LTD' },
  { id: 'EF9', value: 'EF9 | FAIR LEGEND ELECTRONICS LTD' },
  { id: 'EFC', value: 'EFC | Qingdao Fantom Golf Co., Ltd' },
  { id: 'EFL', value: 'EFL | FANTASTIC INTERNATIONAL LTD.' },
  { id: 'EFN', value: 'EFN | FANTASTIC INTERNATIONAL LTD.' },
  { id: 'EFR', value: 'EFR | TEAM EFFORT INC' },
  { id: 'EFW', value: 'EFW | FANWARE S.A.' },
  { id: 'EFX', value: 'EFX | FLEXTRONICS MFG (ZHUHAI) CO, LTD' },
  { id: 'EG0', value: 'EG0 | GILDAN USA INC.' },
  { id: 'EG1', value: 'EG1 | US Nike Golf' },
  { id: 'EG2', value: 'EG2 | Grown-Up Mfg Ltd' },
  { id: 'EG3', value: 'EG3 | Gibor Sport Alpha Socks  Ltd' },
  { id: 'EG5', value: 'EG5 | Eva Overseas International Ltd.' },
  { id: 'EG6', value: 'EG6 | GTS Sporting Inc' },
  { id: 'EG7', value: 'EG7 | DELTA GALIL INDUSTRIES LTD' },
  { id: 'EG8', value: 'EG8 | GILDAN USA INC.' },
  { id: 'EGB', value: 'EGB | GILON SPORTWEAR LTD' },
  { id: 'EGC', value: 'EGC | GREAT VALLEY INTERNATIONAL LTD.' },
  { id: 'EGE', value: 'EGE | DELTA GALIL INDUSTRIES LTD. -' },
  { id: 'EGH', value: 'EGH | APL LOGISTICS HONG KONG' },
  { id: 'EGI', value: 'EGI | GIBOR SPORT ALPHA SOCKS LTD.' },
  { id: 'EGN', value: 'EGN | GUANG DER GROUP HOLDINGS CO.,' },
  { id: 'EGP', value: 'EGP | GROWN UP MANUFACTORY LIMITED' },
  { id: 'EGT', value: 'EGT | SUMMITOP INDUSTRIAL CO., LTD.' },
  { id: 'EGU', value: 'EGU | Carry Gear Solutions' },
  { id: 'EGW', value: 'EGW | (주)송원화공' },
  { id: 'EGY', value: 'EGY | EVA OVERSEAS INTERNATIONAL LIMITED' },
  { id: 'EH1', value: 'EH1 | Harriss & Covington' },
  { id: 'EH2', value: 'EH2 | D.H. INTERNATIONAL' },
  { id: 'EH3', value: 'EH3 | S&H Pad and Print' },
  { id: 'EH4', value: 'EH4 | YEGIN INTERNATIONAL LIMITED' },
  { id: 'EHD', value: 'EHD | Healthcare Technology' },
  { id: 'EHH', value: 'EHH | 대호콜렉션상사주식회사' },
  { id: 'EHI', value: 'EHI | D.H. INTERNATIONAL LTD.' },
  { id: 'EHL', value: 'EHL | D.H. INTERNATIONAL' },
  { id: 'EHM', value: 'EHM | NIKE IHM, Inc.' },
  { id: 'EHP', value: 'EHP | HOPE LAND ENTERPRISE CO.,LTD.' },
  { id: 'EHQ', value: 'EHQ | HOPE LAND ENTERPRISE CO.,LTD.' },
  { id: 'EIB', value: 'EIB | INGOL IND E COM ART ESPORT LTDA ME' },
  { id: 'EIC', value: 'EIC | C0-UNION INDUSTRY CO., LTD.' },
  { id: 'EID', value: 'EID | ZHONGSHAN WEI LI TEXTILE CO. LTD.' },
  { id: 'EIM', value: 'EIM | IMEX INDUSTRIES, INC.' },
  { id: 'EIP', value: 'EIP | INTERLOOP LIMITED' },
  { id: 'EIS', value: 'EIS | Texlan Center (Private) Limited' },
  { id: 'EIU', value: 'EIU | INTERLOOP LIMITED' },
  { id: 'EJ3', value: 'EJ3 | BARBARA INTERNATIONAL TRADING LIMIT' },
  { id: 'EJ5', value: 'EJ5 | CROSTER CO., LTD.' },
  { id: 'EJ6', value: 'EJ6 | TAIHEI BAT' },
  { id: 'EJF', value: 'EJF | JING FULL ENTERPRISE CO., LTD.' },
  { id: 'EJH', value: 'EJH | JPM RESOURCES (HK) LTD.' },
  { id: 'EJI', value: 'EJI | PACIFIC MARKET INTL LLC (PMI)' },
  { id: 'EJJ', value: 'EJJ | TOYAMA SPORTS FACTORY' },
  { id: 'EJL', value: 'EJL | Joyful Long International Ltd' },
  { id: 'EJM', value: 'EJM | JMI INTERNATIONAL LTD' },
  { id: 'EJN', value: 'EJN | JMI INTERNATIONAL' },
  { id: 'EJP', value: 'EJP | JPM RESOURCES (HK) LTD.' },
  { id: 'EJQ', value: 'EJQ | HATAKEYEMA' },
  { id: 'EJR', value: 'EJR | J P M RESOURCES (HK) LTD' },
  { id: 'EJU', value: 'EJU | N.V. WEVERIJ JULES CLARYSSE' },
  { id: 'EJV', value: 'EJV | DH INTERNATIONAL LTD.' },
  { id: 'EJW', value: 'EJW | GUANG DER GROUP HOLDINGS CO.,' },
  { id: 'EK2', value: 'EK2 | KANAAN CO., LTD.' },
  { id: 'EK3', value: 'EK3 | KANAAN CO., LTD.' },
  { id: 'EK4', value: 'EK4 | Sakarai Athletic Mfg Co, Ltd' },
  { id: 'EK5', value: 'EK5 | Jiaxing Shinsung ( USA SLT)' },
  { id: 'EK6', value: 'EK6 | Jiaxing Shinsung ( EMEA SLT)' },
  { id: 'EK9', value: 'EK9 | Yield Express' },
  { id: 'EKA', value: 'EKA | PRIMA SEJATI SEJAHTERA PT' },
  { id: 'EKB', value: 'EKB | Jiaxing Shinsung Kanaan Co., Ltd' },
  { id: 'EKD', value: 'EKD | Kanaan Co., Ltd.' },
  { id: 'EKJ', value: 'EKJ | Jiaxing Shinsung' },
  { id: 'EKN', value: 'EKN | KANAAN CO., LTD' },
  { id: 'EKP', value: 'EKP | PUNGKOOK CORPORATION' },
  { id: 'EKR', value: 'EKR | KAYSER-ROTH CORPORATION' },
  { id: 'EKS', value: 'EKS | KINMAX INTERNATIONAL CO LTD' },
  { id: 'EKV', value: 'EKV | KANAAN SAIGON CO. LTD.' },
  { id: 'EKY', value: 'EKY | Kanaan Co., Ltd.' },
  { id: 'EL1', value: 'EL1 | LUPO S/A' },
  { id: 'EL2', value: 'EL2 | Solaris Industries Group Limited' },
  { id: 'EL3', value: 'EL3 | BABY WORLD PLASTIC MFY. CO., LTD' },
  { id: 'EL4', value: 'EL4 | Rabia Leather Industries Pvt Ltd' },
  { id: 'EL5', value: 'EL5 | Linyi Sino Golf Co., LTD' },
  { id: 'EL7', value: 'EL7 | Spirit Leatherworks' },
  { id: 'ELN', value: 'ELN | Growth-Link Overseas Co. Ltd.' },
  { id: 'ELS', value: 'ELS | LEE SPORTS INTERNATIONAL CO LTD' },
  { id: 'EM3', value: 'EM3 | MARUEM MATSUZAKI INTERNATIONAL CO.,' },
  { id: 'EM4', value: 'EM4 | MICROCELL COMPOSITE COMPANY' },
  { id: 'EM6', value: 'EM6 | MICROCELL COMPOSITE COMPANY' },
  { id: 'EM7', value: 'EM7 | Takes Inc.' },
  { id: 'EM8', value: 'EM8 | MIDAS GOLF LTD.' },
  { id: 'EMB', value: 'EMB | CORTINA INTERNATIONAL CORP., LTD.' },
  { id: 'EMH', value: 'EMH | KORRUN INTERNATIONAL PTE. LTD' },
  { id: 'EMO', value: 'EMO | Shanghai Wande Sporting Goods Co.,' },
  { id: 'EMP', value: 'EMP | MEADWESTVACO CONSUMER' },
  { id: 'EMR', value: 'EMR | MARCHON EYEWEAR INC' },
  { id: 'EMS', value: 'EMS | EMSIG' },
  { id: 'EMT', value: 'EMT | DH INTERNATIONAL LTD' },
  { id: 'EMW', value: 'EMW | MENLO WORLDWIDE' },
  { id: 'EMZ', value: 'EMZ | MARUEM MATSUZAKI INTERNATIONAL CO.,' },
  { id: 'EN2', value: 'EN2 | NS Sock KNitting Factory' },
  { id: 'EN3', value: 'EN3 | NS Sock Knitting Factory' },
  { id: 'END', value: 'END | ENDO MANUFACTURING CO., LTD.' },
  { id: 'ENH', value: 'ENH | Hope Land enterprises' },
  { id: 'ENK', value: 'ENK | C S Alliance International Co Ltd' },
  { id: 'ENL', value: 'ENL | NORTH LAND CO., LTD' },
  { id: 'ENT', value: 'ENT | Jasper Outdoor Products Ltd' },
  { id: 'ENW', value: 'ENW | 남광상사' },
  { id: 'EO1', value: 'EO1 | ENDO MANUFACTURING CO., LTD.' },
  { id: 'EO2', value: 'EO2 | O-TA PRECISION INDUSTRY CO LTD' },
  { id: 'EOA', value: 'EOA | FANCO S.A.' },
  { id: 'EOJ', value: 'EOJ | JONG LIH PLASTIC ENTERPRISE' },
  { id: 'EOL', value: 'EOL | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'EOX', value: 'EOX | CIMA-TECHNOLGIAS TEXTEIS LDA' },
  { id: 'EP1', value: 'EP1 | PCH International Ltd.' },
  { id: 'EP3', value: 'EP3 | PRACAL TEXTIL, S.L.' },
  { id: 'EP4', value: 'EP4 | J&P LEATHERWARE CO. LTD' },
  { id: 'EP6', value: 'EP6 | PODIUM S.A.' },
  { id: 'EP9', value: 'EP9 | Pian S.R.L.' },
  { id: 'EPB', value: 'EPB | KING SPORTS INC.' },
  { id: 'EPF', value: 'EPF | Pumchangmosan' },
  { id: 'EPJ', value: 'EPJ | Pittards PLC TA Daines and Hathaway' },
  { id: 'EPK', value: 'EPK | PERFECT & GLORY ENTERPRISE CO., LTD' },
  { id: 'EPL', value: 'EPL | Captive Plastics Inc.' },
  { id: 'EPM', value: 'EPM | CGS LLC' },
  { id: 'EPT', value: 'EPT | Phitac Industrial Ltd.' },
  { id: 'EPU', value: 'EPU | PRACAL TEXTIL, S.L' },
  { id: 'EQ1', value: 'EQ1 | Team Effort Incorp' },
  { id: 'EQ2', value: 'EQ2 | IMEX INDUSTRIES' },
  { id: 'EQ3', value: 'EQ3 | TOP CROWN LIMITED' },
  { id: 'EQB', value: 'EQB | Esquel Enterprises Limited-F2 (Bool' },
  { id: 'EQD', value: 'EQD | IMEX INDUSTRIES' },
  { id: 'EQE', value: 'EQE | Team Effort Inc.' },
  { id: 'EQL', value: 'EQL | Guangdong Esquel Textiles Co., Ltd.' },
  { id: 'EQT', value: 'EQT | TOP CROWN LIMITED' },
  { id: 'ER1', value: 'ER1 | Renfro Mexico SA de CV' },
  { id: 'ER2', value: 'ER2 | Renfro Corporation' },
  { id: 'ER3', value: 'ER3 | RENFRO CORP SAN. VE TIC. LTD STI.' },
  { id: 'ER9', value: 'ER9 | TEXTIL ROCLAN S.A.' },
  { id: 'ERC', value: 'ERC | RENFRO CORPORATION' },
  { id: 'ERI', value: 'ERI | Toledo Sports Equipment, Inc.' },
  { id: 'ERJ', value: 'ERJ | UCHINO INTERNATIONAL PTE., LTD.' },
  { id: 'ERL', value: 'ERL | Roy Lowe & Sons Limited' },
  { id: 'ERM', value: 'ERM | RED MOUNTAIN TRADE B.V.' },
  { id: 'ERN', value: 'ERN | RENFRO CORPORATION' },
  { id: 'ERU', value: 'ERU | UCHINO INTERNATIONAL PTE., LTD.' },
  { id: 'ERV', value: 'ERV | RIETVELD SERIGRAFIE BV' },
  { id: 'ERY', value: 'ERY | Raraya Co., Ltd.' },
  { id: 'ES0', value: 'ES0 | Sansei Sangyo Co., LTD.' },
  { id: 'ES4', value: 'ES4 | Guang Der Corporation Ltd.' },
  { id: 'ES5', value: 'ES5 | SUMMITOP INDUSTRIAL CO., LTD' },
  { id: 'ES9', value: 'ES9 | ShinWha' },
  { id: 'ESB', value: 'ESB | SUBLIME SOCCER (PVT) LIMITED' },
  { id: 'ESC', value: 'ESC | ESCHLER AG' },
  { id: 'ESF', value: 'ESF | Fujian Saga Sports Company Limited' },
  { id: 'ESK', value: 'ESK | PT. SKYCAMPING INDONESIA-TEST' },
  { id: 'ESL', value: 'ESL | ESQUEL ENTERPRISES LTD-F' },
  { id: 'ESM', value: 'ESM | GUANG DER GROUP HOLDINGS CO.,' },
  { id: 'ESN', value: 'ESN | Staunton Capital Inc DBA Slane' },
  { id: 'ESP', value: 'ESP | EAGLE SPEED MARKETING CO LTD-F' },
  { id: 'ESQ', value: 'ESQ | ESQUEL ENTERPRISES LTD' },
  { id: 'ESS', value: 'ESS | SAGA SPORTS (PVT) LTD.' },
  { id: 'ESU', value: 'ESU | SUMMITOP INDUSTRIAL CO LTD' },
  { id: 'ESV', value: 'ESV | ESQUEL ENTERPRISES LTD-F3' },
  { id: 'ESZ', value: 'ESZ | 선일통상' },
  { id: 'ET1', value: 'ET1 | THAI SOCK CO., LTD.' },
  { id: 'ET2', value: 'ET2 | PHITAC LTD.' },
  { id: 'ET4', value: 'ET4 | TEAM EFFORT INC' },
  { id: 'ET5', value: 'ET5 | TEAM EFFORT, INC.' },
  { id: 'ET6', value: 'ET6 | Stamperia Alicese' },
  { id: 'ET7', value: 'ET7 | GTS Sporting Taiwan Co., Ltd' },
  { id: 'ET9', value: 'ET9 | TEXTILES PANTER LIMITADA' },
  { id: 'ETB', value: 'ETB | THAI SOCK CO. LTD.' },
  { id: 'ETC', value: 'ETC | TEAM EFFORT INCORP' },
  { id: 'ETE', value: 'ETE | TEAM EFFORT INC' },
  { id: 'ETF', value: 'ETF | ETF Tekstil Konf. IHR. TUR. SAN. VE' },
  { id: 'ETG', value: 'ETG | THE EASTERN GARMENT MFG COMPAN' },
  { id: 'ETH', value: 'ETH | TAY HSIN KNITTING CO., LTD.' },
  { id: 'ETI', value: 'ETI | TAY HSIN KNITTING CO LTD' },
  { id: 'ETK', value: 'ETK | TEAM EFFORT INCORP' },
  { id: 'ETN', value: 'ETN | TATTOO DISTRIBUTING, INC.' },
  { id: 'ETP', value: 'ETP | TAIWAN PAIHO LIMITED' },
  { id: 'ETU', value: 'ETU | TEAM EFFORT INCORPORATED' },
  { id: 'EUC', value: 'EUC | EUROCAP' },
  { id: 'EUI', value: 'EUI | DAVE SHEPARD ENTERPRISES' },
  { id: 'EUL', value: 'EUL | PACIFIC MARKET INTL.' },
  { id: 'EUS', value: 'EUS | UNIVERSAL SPORTING GOODS (THAI)CO.,' },
  { id: 'EV1', value: 'EV1 | SAMYANG TONGSANG CO, LTD' },
  { id: 'EV7', value: 'EV7 | VISION INTERNATIONAL CO., LTD' },
  { id: 'EVB', value: 'EVB | ESQUEL ENTERPRISES LTD - F3 (BOOLIM' },
  { id: 'EVE', value: 'EVE | SILVER STAR ENTERPRISES (PVT) LTD' },
  { id: 'EVI', value: 'EVI | GREAT VALLEY INTERNATIONAL LTD.' },
  { id: 'EVL', value: 'EVL | Glory Garment' },
  { id: 'EVM', value: 'EVM | VEGA BALLS MANUFACTURING CO LTD' },
  { id: 'EVO', value: 'EVO | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'EVR', value: 'EVR | ECLAT' },
  { id: 'EVY', value: 'EVY | EVERYOUNG UTAMA' },
  { id: 'EW1', value: 'EW1 | Team Effort Inc.' },
  { id: 'EW2', value: 'EW2 | ZHONGSHAN WEI LI TEXTILE CO., LTD.' },
  { id: 'EW4', value: 'EW4 | TIGER GROWTH ASSETS LTD.' },
  { id: 'EWB', value: 'EWB | WINBALL IND E COM DE ART ESPOR LTDA' },
  { id: 'EWD', value: 'EWD | TIGER GROWTH ASSETS LIMITED' },
  { id: 'EWE', value: 'EWE | IDT Technology Limited' },
  { id: 'EWF', value: 'EWF | Wang Fat Enterprise Co.' },
  { id: 'EWG', value: 'EWG | Wellpower Commerce Holding Co Ltd' },
  { id: 'EWI', value: 'EWI | WILSON SPORTING GOODS CO' },
  { id: 'EWN', value: 'EWN | GUANG DER GROUP HOLDINGS CO.,' },
  { id: 'EWO', value: 'EWO | TIGER GROWTH ASSETS LTD.' },
  { id: 'EWP', value: 'EWP | Team Effort Inc.' },
  { id: 'EWS', value: 'EWS | SUPERIOR LEATHER LTD.' },
  { id: 'EWT', value: 'EWT | Walt Technology Group Co. Ltd.' },
  { id: 'EWU', value: 'EWU | Walt USA LLC' },
  { id: 'EX9', value: 'EX9 | Thermos KK' },
  { id: 'EXA', value: 'EXA | NI TEIJINSHOJI CO., LTD.' },
  { id: 'EXK', value: 'EXK | XIAMEN KYOSEI SPORTING' },
  { id: 'EXP', value: 'EXP | YEGIN INTERNATIONAL LIMITED' },
  { id: 'EXT', value: 'EXT | Tabata Co., Ltd.' },
  { id: 'EXU', value: 'EXU | Uchino Co., Ltd.' },
  { id: 'EXW', value: 'EXW | Okamoto Textile (Thailand) Co., Ltd' },
  { id: 'EXY', value: 'EXY | Yorks Co., Ltd.' },
  { id: 'EY2', value: 'EY2 | Team Effort Inc.' },
  { id: 'EYE', value: 'EYE | EYECATCHER' },
  { id: 'EYF', value: 'EYF | CHUEN HING ATHLETIC APPLIANCES TRAD' },
  { id: 'EYG', value: 'EYG | Guangzhou Sino Concept Golf' },
  { id: 'EYL', value: 'EYL | GREAT VALLEY INTERNATIONAL LTD.' },
  { id: 'EYM', value: 'EYM | Yamaya  Sangyo Co.,Ltd.' },
  { id: 'EYP', value: 'EYP | LONG WAY ENTERPRISE CO., LTD' },
  { id: 'EYT', value: 'EYT | WESTAWAY INTERNATIONAL LIMITED' },
  { id: 'EYV', value: 'EYV | YEGIN INTERNATIONAL LIMITED' },
  { id: 'EYY', value: 'EYY | GOODWAY COMPANY LTD' },
  { id: 'EZ1', value: 'EZ1 | SOCKIT MANUFACTURING PTY LTD' },
  { id: 'EZ2', value: 'EZ2 | GRAND MARKET LIMITED' },
  { id: 'EZ4', value: 'EZ4 | Zonson Company Inc.' },
  { id: 'EZ5', value: 'EZ5 | Millennium Sock SA CC' },
  { id: 'EZ6', value: 'EZ6 | Shen Zhen Zin Hwa Sports Corp.' },
  { id: 'EZB', value: 'EZB | Topball Sports Inc.' },
  { id: 'EZE', value: 'EZE | Zen International Co., Ltd.' },
  { id: 'EZH', value: 'EZH | DELTA GALIL INDUSTRIES LTD' },
  { id: 'EZU', value: 'EZU | DELTA GALIL INDUSTRIES LTD' },
  { id: 'EZW', value: 'EZW | EXTENSOR WORLD TRADING CO LTD' },
  { id: 'F1', value: 'F1 | FOOT TECHNO' },
  { id: 'FAA', value: 'FAA | FANWARE SA' },
  { id: 'FAH', value: 'FAH | FAR EAST INTL GARMENT LTD' },
  { id: 'FAN', value: 'FAN | FANCO SA-F' },
  { id: 'FC', value: 'FC | PANYU PEGASUS FOOTWEAR CO., LTD' },
  { id: 'FD', value: 'FD | ZZ - CAPITAL CONCORD ENTERPRISES LT' },
  { id: 'FEC', value: 'FEC | FE NEW CENTURY INDUSTRY' },
  { id: 'FES', value: 'FES | Far Eastern Apparel (Holding) Limit' },
  { id: 'FET', value: 'FET | FAR EASTERN NEW CENTURY CORPORATION' },
  { id: 'FEU', value: 'FEU | Far Eastern Apparel (Holding) Limit' },
  { id: 'FFJ', value: 'FFJ | FASHION FORCE - VIETNAM' },
  { id: 'FFK', value: 'FFK | FASHION FORCE LIMITED-F' },
  { id: 'FFS', value: 'FFS | FORTUNE FASHIONS INC' },
  { id: 'FFW', value: 'FFW | FASHION FORCE LIMITED' },
  { id: 'FGL', value: 'FGL | HIRDARAMANI INTERNATIONAL EXPORTS (' },
  { id: 'FHK', value: 'FHK | FAR EAST INTL GARMENT LTD-F' },
  { id: 'FHS', value: 'FHS | FRANKHAUS INTERNATIONAL CORP' },
  { id: 'FJ', value: 'FJ | ZZ - CAPITAL CONCORD ENTERPRISES LT' },
  { id: 'FJD', value: 'FJD | Fjord, Ltd' },
  { id: 'FJJ', value: 'FJJ | FUN TEES - F1' },
  { id: 'FKW', value: 'FKW | FOOK WAH KUN KEE KNITTING FTY. LTD' },
  { id: 'FLS', value: 'FLS | FASHIONLINE SAIGON LTD- VIETNAM' },
  { id: 'FMS', value: 'FMS | Formosa Handbag Company Limited' },
  { id: 'FNR', value: 'FNR | Far Eastern Apparel (Holding) Limit' },
  { id: 'FOP', value: 'FOP | FOX PRODUCTIONS BV' },
  { id: 'FS', value: 'FS | PANYU PEGASUS FOOTWEAR CO., LTD' },
  { id: 'FSS', value: 'FSS | FRAMA Y SAGO, S.L.' },
  { id: 'FT', value: 'FT | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'FTG', value: 'FTG | ORIENT INTERNATIONAL HOLDING SHANGH' },
  { id: 'FTX', value: 'FTX | FUN TEES-F2' },
  { id: 'FUD', value: 'FUD | FUN TEES DEMAND PULL' },
  { id: 'FUF', value: 'FUF | FUN TEES-F' },
  { id: 'FUG', value: 'FUG | FU SON GARMENT FACTORY (CHINA)' },
  { id: 'FUJ', value: 'FUJ | FUN TEES' },
  { id: 'FUN', value: 'FUN | FUN TEES-F' },
  { id: 'FUR', value: 'FUR | FU SON GARMENT FACTORY' },
  { id: 'FUS', value: 'FUS | FU SON GARMENT FACTORY (MACAU)' },
  { id: 'FVN', value: 'FVN | FE NEW CENTURY INDUSTRY' },
  { id: 'FVP', value: 'FVP | Far Eastern Apparel (Holding) Limit' },
  { id: 'GAT', value: 'GAT | GIANT' },
  { id: 'GBB', value: 'GBB | GILON SPORTSWEAR, LTD.' },
  { id: 'GCS', value: 'GCS | GELAL CORAP SAN. VE TIC. A.S.' },
  { id: 'GEX', value: 'GEX | GOKALDAS EXPORTS' },
  { id: 'GIB', value: 'GIB | GIBOR SPORT ALPHA SOCK LTD' },
  { id: 'GIE', value: 'GIE | GIBOR SPORT ACTIVE WEAR GSAW LTD' },
  { id: 'GIH', value: 'GIH | GIBOR DEMAND PULL' },
  { id: 'GIN', value: 'GIN | G III APPAREL GROUP LTD' },
  { id: 'GIT', value: 'GIT | GIBOR ALFA CORAP VE TEKSTIL SANAYII' },
  { id: 'GLK', value: 'GLK | GROWTH LANKA PVT LTD' },
  { id: 'GNT', value: 'GNT | GIANT MERCHANDISING' },
  { id: 'GOI', value: 'GOI | M/S GOKALDAS IMAGES LTD' },
  { id: 'GOM', value: 'GOM | GRUPO M-F' },
  { id: 'GON', value: 'GON | GLOBAL ONE' },
  { id: 'GPB', value: 'GPB | Industrias de Exporatacion S.A. de' },
  { id: 'GPS', value: 'GPS | GPS PLANNING' },
  { id: 'GRB', value: 'GRB | PT GREENTEX INDONESIA UTAMA (BOOLIM' },
  { id: 'GRS', value: 'GRS | GREEN TEXTILE CO., LTD.  (DEMAND PU' },
  { id: 'GRT', value: 'GRT | SnogenGreen Co., Ltd' },
  { id: 'GRV', value: 'GRV | SnogenGreen Co., Ltd' },
  { id: 'GSP', value: 'GSP | GILDAN APPAREL USA INC.' },
  { id: 'GTF', value: 'GTF | EGYPTIAN TEE FACTORY' },
  { id: 'GTI', value: 'GTI | GARMENT TECH INTERNATIONAL CO., LTD' },
  { id: 'GUN', value: 'GUN | GRAPHIC UNDERGROUND' },
  { id: 'GVB', value: 'GVB | GREEN VINA (BOOLIM PRODUCT)' },
  { id: 'HAB', value: 'HAB | HANSAE CO LTD (BOOLIM PRODUCT)' },
  { id: 'HAC', value: 'HAC | Honways International Limited' },
  { id: 'HAD', value: 'HAD | New York Corporation' },
  { id: 'HAP', value: 'HAP | Honways International Limited' },
  { id: 'HAV', value: 'HAV | HANSAE CO LTD' },
  { id: 'HBB', value: 'HBB | NEW HOLLAND APPAREL' },
  { id: 'HBD', value: 'HBD | NEW HOLLAND APPAREL (DEMAND PULL)' },
  { id: 'HBF', value: 'HBF | NEW HOLLAND APPAREL' },
  { id: 'HBN', value: 'HBN | NEW HOLLAND APPAREL' },
  { id: 'HCO', value: 'HCO | 28 COMPANY AGTEX CO' },
  { id: 'HEG', value: 'HEG | HONG SENG KNITTING' },
  { id: 'HEM', value: 'HEM | HELA CLOTHING PVT LTD' },
  { id: 'HEU', value: 'HEU | HI-TECH APPAREL CO., LTD. (EUROPE -' },
  { id: 'HFG', value: 'HFG | HO FUNG FASHION LIMITED' },
  { id: 'HIC', value: 'HIC | HI TECH APPAREL CO LTD' },
  { id: 'HID', value: 'HID | HIRDARAMANI INDUSTRIES LTD' },
  { id: 'HIG', value: 'HIG | HI TECH APPAREL CO LTD' },
  { id: 'HIN', value: 'HIN | HINDUJA SPORTSWEAR' },
  { id: 'HIP', value: 'HIP | HI TECH - DEMAND PULL LOC' },
  { id: 'HIT', value: 'HIT | HI TECH APPAREL CO LTD' },
  { id: 'HJ', value: 'HJ | ZZ - NIKE (SUZHOU) SPORTS CO., LTD.' },
  { id: 'HJH', value: 'HJH | TEXTIL H.J. HERING' },
  { id: 'HJS', value: 'HJS | Providence Industries, Inc. d/b/a M' },
  { id: 'HKC', value: 'HKC | WHAMPOA GARMENT MFG (GUIGANG) COMPA' },
  { id: 'HKH', value: 'HKH | HONG SENG KNITTING' },
  { id: 'HKK', value: 'HKK | HONG KONG KNITTERS' },
  { id: 'HKL', value: 'HKL | HONG KONG KNITTERS - LANKA' },
  { id: 'HKP', value: 'HKP | WHAMPOA GARMENT MFG. CO. LTD.' },
  { id: 'HKR', value: 'HKR | WHAMPOA GARMENT MFG CO LTS' },
  { id: 'HLY', value: 'HLY | Hurley 999, S.L.U.' },
  { id: 'HNA', value: 'HNA | HANSAE CO LTD (NORTH AMERICA - SLT)' },
  { id: 'HOB', value: 'HOB | HOJEON LIMITED (BOOLIM PRODUCT)' },
  { id: 'HOF', value: 'HOF | EXCELLENT JADE LTD' },
  { id: 'HOJ', value: 'HOJ | HOJEON LIMITED' },
  { id: 'HOL', value: 'HOL | HOLT MANUFACTURING' },
  { id: 'HON', value: 'HON | Prolexus International Limited' },
  { id: 'HOP', value: 'HOP | Prolexus International Limited' },
  { id: 'HPB', value: 'HPB | HOPE LAND ENTERPRISE CO., LTD (BOOL' },
  { id: 'HPL', value: 'HPL | HOPE LAND ENTERPRISE CO LTD' },
  { id: 'HPT', value: 'HPT | HOPE LAND ENTERPRISE CO LTD - F' },
  { id: 'HSB', value: 'HSB | NANJING HHD APPAREL CO., LTD (BOOLI' },
  { id: 'HSC', value: 'HSC | Nanjing HHD APPAREL CO. LTD' },
  { id: 'HSD', value: 'HSD | HONG SENG KNITTING DEMAND PULL' },
  { id: 'HSE', value: 'HSE | HONG SENG KNITTING' },
  { id: 'HSV', value: 'HSV | HONG SENG KNITTING' },
  { id: 'HTN', value: 'HTN | Hopeland Enterprise Co., Ltd.' },
  { id: 'HTP', value: 'HTP | HIGHTIDE PRINT' },
  { id: 'HTV', value: 'HTV | HI TECH APPAREL CO LTD' },
  { id: 'HUC', value: 'HUC | Hurley International, LLC' },
  { id: 'HWP', value: 'HWP | HONG KONG HWA YING TRADE' },
  { id: 'HYC', value: 'HYC | SUZHOU HYTEX APPARELS CO.,LTD' },
  { id: 'HYS', value: 'HYS | HYUNDAI SPORTS' },
  { id: 'HYT', value: 'HYT | HYTEX APPARELS SDN BHD' },
  { id: 'I6', value: 'I6 | SSIPL RETAIL LTD.' },
  { id: 'I9', value: 'I9 | ZZ - SARUP TANNERIES, LTD' },
  { id: 'IA', value: 'IA | PT. ADIS DIMENSION FOOTWEAR' },
  { id: 'IAC', value: 'IAC | INTERNATIONAL APPAREL CONTRACTORS S' },
  { id: 'IB', value: 'IB | ZZ - PT. HARDAYA ANEKA SHOES INDUST' },
  { id: 'IBT', value: 'IBT | IBERTRADING' },
  { id: 'ICH', value: 'ICH | INDUSTRIAS CHARLIE SA DE CV' },
  { id: 'IEN', value: 'IEN | Eagle Nice Development Limited' },
  { id: 'IKC', value: 'IKC | INDIANA KNITWEAR CORPORATION' },
  { id: 'IKN', value: 'IKN | INDIANA KNITWEAR CORPORATION' },
  { id: 'ILM', value: 'ILM | IL LIM' },
  { id: 'IM', value: 'IM | Mega International Trading Limited' },
  { id: 'IMG', value: 'IMG | Fast Track Textile Limited Liabilit' },
  { id: 'IMT', value: 'IMT | IMTEKS GIYIM SANAYI ve TICARET AS' },
  { id: 'INV', value: 'INV | PUNTO CRUZ S.A.' },
  { id: 'IP', value: 'IP | ZZ - PT. NAGASAKTI PARAMASHOES INDU' },
  { id: 'IPS', value: 'IPS | IMPACT SPORTS' },
  { id: 'IR', value: 'IR | PT. PRATAMA ABADI INDUSTRI' },
  { id: 'ISL', value: 'ISL | MANUFACTURAS DEL CARIBE S.A.' },
  { id: 'IW', value: 'IW | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'IY', value: 'IY | Yue Yuen Industrial Limited,' },
  { id: 'J2', value: 'J2 | Ever Radiant Worldwide Limited' },
  { id: 'JAN', value: 'JAN | JAN TEKSTIL' },
  { id: 'JBP', value: 'JBP | JIASHAN BENHUI PRINTING CO., LTD.' },
  { id: 'JC', value: 'JC | PT. ASIA DWIMITRA INDUSTRI' },
  { id: 'JE', value: 'JE | PT. DEAN SHOES' },
  { id: 'JEA', value: 'JEA | EAGLE NICE DEVELOPMENT LTD - TDC' },
  { id: 'JGI', value: 'JGI | PT ALFINDO JABA GARMINDO' },
  { id: 'JJ', value: 'JJ | CHANGSHIN, INC.' },
  { id: 'JLK', value: 'JLK | LAI KO KNITTING FACTORY LTD' },
  { id: 'JMI', value: 'JMI | JMI INTERNATIONAL LTD' },
  { id: 'JNT', value: 'JNT | JOWETT GARMENTS FACTORY INC - NTS' },
  { id: 'JOD', value: 'JOD | Wintan Inc (Demand Pull)' },
  { id: 'JOE', value: 'JOE | ZZ JOWETT GARMENTS FACTORY INC' },
  { id: 'JOM', value: 'JOM | Wintan Inc (Demand Pull)' },
  { id: 'JON', value: 'JON | JOHNSON SPORTSWEAR LTD' },
  { id: 'JPC', value: 'JPC | J&P LEATHERWARE CO. LTD' },
  { id: 'JPR', value: 'JPR | JPM RESOURCES (HK) LTD' },
  { id: 'JPT', value: 'JPT | JOHN PETER' },
  { id: 'JQL', value: 'JQL | JAQALANKA INTERNATIONAL PVT LTD' },
  { id: 'JSM', value: 'JSM | John Smedley Ltd' },
  { id: 'JV', value: 'JV | FALCON UNIVERSAL SERVICES' },
  { id: 'JVC', value: 'JVC | JUDY MAO SHANGHAI GARMENT IND. LTD' },
  { id: 'JVG', value: 'JVG | ZZ - JOCK VAN GARMENT INDUSTRIES-F' },
  { id: 'JWI', value: 'JWI | NEW FASHION PRODUCTS' },
  { id: 'JX', value: 'JX | PT. PRATAMA ABADI INDUSTRI' },
  { id: 'JZN', value: 'JZN | JANTZEN' },
  { id: 'KCK', value: 'KCK | KCK GARMENT FACTORY LIMITED' },
  { id: 'KCT', value: 'KCT | KCK GARMENT FACTORY LIMITED-F' },
  { id: 'KD', value: 'KD | ZZ - QINGDAO CHANGSHIN SHOES CO., L' },
  { id: 'KEL', value: 'KEL | KELLWOOD COMPANY' },
  { id: 'KEY', value: 'KEY | KEYSPORTS APPAREL-F' },
  { id: 'KF', value: 'KF | ZZ - FREETREND INDUSTRIAL' },
  { id: 'KIA', value: 'KIA | KIANGTEX COMPANY LTD' },
  { id: 'KIT', value: 'KIT | KITAI CO., LTD.' },
  { id: 'KJ', value: 'KJ | KAJIURA CO., LTD' },
  { id: 'KKA', value: 'KKA | Grand Max Limited (Cambodia)' },
  { id: 'KKC', value: 'KKC | JIN MEI KNITWEAR' },
  { id: 'KKD', value: 'KKD | GRAND MAX LIMITED CAMBODIA (EUROPE' },
  { id: 'KKG', value: 'KKG | PT KAHATEX' },
  { id: 'KKH', value: 'KKH | KENT KNITTERS LTD' },
  { id: 'KLM', value: 'KLM | K - L Mfg. Co. Inc.' },
  { id: 'KLN', value: 'KLN | KL MANUFACTURING' },
  { id: 'KLW', value: 'KLW | HIWAY TEXTILES LIMITED-F2' },
  { id: 'KME', value: 'KME | KIMS EMBROIDERY-F1' },
  { id: 'KN', value: 'KN | ZZ - LIFENG FOOTWEAR CORPORATION' },
  { id: 'KOC', value: 'KOC | K MOHAN AND COMPANY EXPORTS PRIVATE' },
  { id: 'KQ', value: 'KQ | ZZ - QINGDAO TAE KWANG SHOES CO., L' },
  { id: 'KRI', value: 'KRI | KARLE INTERNATIONAL-F1' },
  { id: 'KRL', value: 'KRL | KARLE INTERNATIONAL' },
  { id: 'KUB', value: 'KUB | PT KUKDONG (BOOLIM PRODUCT)' },
  { id: 'KUK', value: 'KUK | PT Kukdong International' },
  { id: 'KV', value: 'KV | ZZ - CHANGSHIN VIETNAM CO., LTD.' },
  { id: 'KW', value: 'KW | ZZ - TAE KWANG VINA INDUSTRIAL CO.,' },
  { id: 'KWL', value: 'KWL | KRISWILL' },
  { id: 'KX', value: 'KX | ZZ - GRAND SMARTLY GROUP, LTD' },
  { id: 'KYS', value: 'KYS | KEYSPORTS' },
  { id: 'L86', value: 'L86 | JR286' },
  { id: 'LAD', value: 'LAD | Long An General Import Export Duck' },
  { id: 'LAF', value: 'LAF | LATITUDES' },
  { id: 'LAS', value: 'LAS | LAMASZ SPORT INC-F' },
  { id: 'LAT', value: 'LAT | LATITUDES' },
  { id: 'LBE', value: 'LBE | LIEBE' },
  { id: 'LCL', value: 'LCL | LIAND' },
  { id: 'LEU', value: 'LEU | BODYLINE PVT LTD (EUROPE - SLT)' },
  { id: 'LEV', value: 'LEV | LEVI STRAUSS & CO' },
  { id: 'LG', value: 'LG | ZZ - FUJIAN GREAT HOPE FOOTWEAR CO.' },
  { id: 'LGD', value: 'LGD | WING TAI ENTERPRISES LIMITED' },
  { id: 'LIB', value: 'LIB | LIBERTY GARMENT CO LTD' },
  { id: 'LIJ', value: 'LIJ | LIBERTY COMPANIES - JORDAN' },
  { id: 'LIP', value: 'LIP | LIBERTY SCREENPRINT' },
  { id: 'LMB', value: 'LMB | MAS Active Trading (Pvt) Limited' },
  { id: 'LMC', value: 'LMC | SIRIS' },
  { id: 'LMD', value: 'LMD | BODYLINE PVT LTD. (Demand Pull)' },
  { id: 'LMG', value: 'LMG | MAS ACTIVE TRADING (PVT) LIMITED' },
  { id: 'LMH', value: 'LMH | INTIMATE CLOTHING PVT LTD' },
  { id: 'LMI', value: 'LMI | MAS ACTIVE TRADING (PVT) LIMITED' },
  { id: 'LMJ', value: 'LMJ | Mas Al safi Apparel Manufacturing' },
  { id: 'LML', value: 'LML | MAS ACTIVE TRADING (PVT) LIMITED' },
  { id: 'LMN', value: 'LMN | LAMASZ SPORT INC NTS' },
  { id: 'LMP', value: 'LMP | MAS ACTIVE TRADING (PVT) LTD DEMAND' },
  { id: 'LMQ', value: 'LMQ | BODYLINE (PVT) LTD.' },
  { id: 'LMR', value: 'LMR | MAS Active Trading (Pvt) Ltd' },
  { id: 'LMS', value: 'LMS | MAS ACTIVE TRADING (PVT) LIMITED' },
  { id: 'LMU', value: 'LMU | MAS ACTIVE TRADING (PVT) LIMITED-CO' },
  { id: 'LN', value: 'LN | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'LNR', value: 'LNR | BODYLINE PVT LTD (NORTH AMERICA - S' },
  { id: 'LSJ', value: 'LSJ | LIBERTY SCREENPRINT-F' },
  { id: 'LSP', value: 'LSP | LIBERTY SCREENPRINT-F1' },
  { id: 'LTD', value: 'LTD | LATITUDES-F' },
  { id: 'LU', value: 'LU | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'LUE', value: 'LUE | DONGGUAN LUEN THAI GARMENT COMPANY' },
  { id: 'LUM', value: 'LUM | L&T MACAU GARMENT MANUFACTURING COM' },
  { id: 'LWT', value: 'LWT | MANHATTAN OUTDOOR CLOTHING FACTORY' },
  { id: 'LYP', value: 'LYP | LYNKA' },
  { id: 'MAA', value: 'MAA | AL MASIRA' },
  { id: 'MAT', value: 'MAT | MILTEKS TEKSTIL SANAYI VE TICARET' },
  { id: 'MCL', value: 'MCL | MICROCELL COMPOSITE COMPANY--' },
  { id: 'MD', value: 'MD | DIAMANT S.R.L.' },
  { id: 'ME', value: 'ME | ZZ - EUROAMERICANA DE CALZADO' },
  { id: 'MEU', value: 'MEU | PT MORICH INDO FASHION (EUROPE - SL' },
  { id: 'MGF', value: 'MGF | AUSTINS MARMON LTD' },
  { id: 'MH', value: 'MH | ZZ - CO-RI-NO, S.A. DE C.V.' },
  { id: 'MHC', value: 'MHC | MALHARIA CRISTINA LTDA' },
  { id: 'MHG', value: 'MHG | MINH HOANG GARMENT' },
  { id: 'MIB', value: 'MIB | PT MORICH INDO FASHION (BOOLIM PROD' },
  { id: 'MIF', value: 'MIF | PT MORICH INDO FASHION' },
  { id: 'MJ', value: 'MJ | TAKES, INC.' },
  { id: 'MK', value: 'MK | ZZ - GRUPO KAROSSO SA DE CV' },
  { id: 'MLN', value: 'MLN | MILLENIUM SOCKS Sa Cc' },
  { id: 'MMT', value: 'MMT | MEMTEKS TEKSTIL SAN. VE TIC. A.S.' },
  { id: 'MOV', value: 'MOV | MONVISO KNITWEAR' },
  { id: 'MPT', value: 'MPT | MASSIVE PRINTS L.L.C.' },
  { id: 'MQ', value: 'MQ | ZZ - QUIRELLI S.A. DE C.V.' },
  { id: 'MSH', value: 'MSH | MASTER PIECE GARMENT AND TEXTILE CO' },
  { id: 'MSP', value: 'MSP | MASTERPIECE GARMENT' },
  { id: 'MSS', value: 'MSS | Island Ways, Inc dba Morning Sun Sh' },
  { id: 'MTK', value: 'MTK | MILTEKS TEKSTIL SANAYI VE TICARET' },
  { id: 'MTP', value: 'MTP | MILTEKS TEKSTIL SANAYI VE TICARET A' },
  { id: 'MW', value: 'MW | MACNEILL WORLDWIDE PACIFIC, LTD.' },
  { id: 'MX', value: 'MX | DIAMANT S.R.L.' },
  { id: 'MXB', value: 'MXB | MEX MODE SA DE CV (BOOLIM PRODUCT)' },
  { id: 'MXM', value: 'MXM | MEX MODE SA DE CV' },
  { id: 'MXP', value: 'MXP | MAXPORT LIMITED' },
  { id: 'NAC', value: 'NAC | GRAND MAX LIMITED' },
  { id: 'NAD', value: 'NAD | Brio Max Limited (Demand Pull)' },
  { id: 'NAG', value: 'NAG | GRAND MAX LIMITED' },
  { id: 'NAL', value: 'NAL | ESFERA' },
  { id: 'NAY', value: 'NAY | Brio Max Limited' },
  { id: 'NBF', value: 'NBF | NEW BUFFALO SHIRT FACTORY, INC.' },
  { id: 'NBS', value: 'NBS | New Buffalo Shirt Factory, Inc. (NF' },
  { id: 'NDL', value: 'NDL | EAGLE NICE DEVELOPMENT LIMITED-F' },
  { id: 'NEG', value: 'NEG | EAST CONCEPT EXPORT (HK) LTD' },
  { id: 'NEP', value: 'NEP | GRAND MAX LIMITED (DEMAND PULL)' },
  { id: 'NER', value: 'NER | NEW ERA' },
  { id: 'NFK', value: 'NFK | FITWEAR KNITTING CO. LTD.' },
  { id: 'NGP', value: 'NGP | SHENZHOU INTERNATIONAL GROUP HOLDIN' },
  { id: 'NGT', value: 'NGT | NEW GOLD TEXTILE APPAREL LTD.' },
  { id: 'NIC', value: 'NIC | Buddies (Macao Commercial' },
  { id: 'NIG', value: 'NIG | SHENZHOU INTERNATIONAL GROUP' },
  { id: 'NIJ', value: 'NIJ | NINGBO SHENZHOU KNITTING CO., LTD' },
  { id: 'NIV', value: 'NIV | Buddies (Macao Commercial' },
  { id: 'NML', value: 'NML | NAMIL CORPORATION' },
  { id: 'NSK', value: 'NSK | C S Alliance International Company' },
  { id: 'NSS', value: 'NSS | N.S. Socks Knitting Factory, Ltd.' },
  { id: 'NTC', value: 'NTC | ANTICE CO., LTD.' },
  { id: 'NTL', value: 'NTL | NATIONAL EMBLEM' },
  { id: 'NTO', value: 'NTO | COVE SCREEN S.A. DE C.V.' },
  { id: 'NVC', value: 'NVC | GRAND MAX LIMITED' },
  { id: 'NVN', value: 'NVN | BOWKER GARMENT FACTORY CO. LTD' },
  { id: 'NYC', value: 'NYC | NAN YANG GARMENT (QINGDAO) CO., LTD' },
  { id: 'NYK', value: 'NYK | Ningbo Youngor Knitting and Underwe' },
  { id: 'NYV', value: 'NYV | Brio Max Limited' },
  { id: 'OGX', value: 'OGX | CIMA-TECHNOLGIAS TEXTEIS LDA' },
  { id: 'OPT', value: 'OPT | OPTECH' },
  { id: 'ORD', value: 'ORD | COMERCIO E SERVIOS TEXTEIS, SA' },
  { id: 'ORE', value: 'ORE | PETRATEX CONFECCOES S.A.' },
  { id: 'ORI', value: 'ORI | SHANGHAI DOLPHIN TEXTILE PRODUCT CO' },
  { id: 'ORX', value: 'ORX | COMERCIO E SERVICOS TEXTEIS SA.' },
  { id: 'OSS', value: 'OSS | OREGON SCREEN IMPRESSIONS-F' },
  { id: 'OTR', value: 'OTR | OTOYO LTD (RYUTSU CENTER)' },
  { id: 'OWN', value: 'OWN | V-Win Apparel Co Ltd' },
  { id: 'PA', value: 'PA | ZZ - PAN ASIA FOOTWEAR PUBLIC CO.,' },
  { id: 'PAM', value: 'PAM | FU SENG GARMENT KNITTING FTY LTD' },
  { id: 'PAP', value: 'PAP | PACIFIC SPORTSWEAR' },
  { id: 'PAS', value: 'PAS | PLAS INDUSTRIES SDN BHD-F' },
  { id: 'PC', value: 'PC | Yue Yuen Industrial Limited,' },
  { id: 'PCA', value: 'PCA | PACIFIC ATHLETIC WEAR INC.' },
  { id: 'PCP', value: 'PCP | PROMOTORA COMERCIALPANTALONERA' },
  { id: 'PCS', value: 'PCS | PCCS (HONG KONG) LIMITED' },
  { id: 'PDG', value: 'PDG | PHUONG DONG GARMENT COMPANY' },
  { id: 'PHE', value: 'PHE | PHAEL' },
  { id: 'PHF', value: 'PHF | PARAMOUNT HEADWARE INC-F' },
  { id: 'PHI', value: 'PHI | PARAMOUNT HEADWARE INC-F' },
  { id: 'PHK', value: 'PHK | PARAMOUNT HEADWARE INC-F' },
  { id: 'PKL', value: 'PKL | IMPERIAL GARMENT LIMITED' },
  { id: 'PKM', value: 'PKM | PT PANTJATUNGGAL KNITTING MILL' },
  { id: 'PKP', value: 'PKP | PANTJA TUNGGAL KNITTING DEMAND PULL' },
  { id: 'PL2', value: 'PL2 | PRACAL TEXTIL, S.L.' },
  { id: 'PLI', value: 'PLI | PLAS INDUSTRIES SDN BHD' },
  { id: 'PLO', value: 'PLO | PUNTO MARTINEZ LLOPART S.A.' },
  { id: 'PM2', value: 'PM2 | Carry Gear Solutions LLC' },
  { id: 'PMA', value: 'PMA | ZHEJIANG PALMA DRESS CO., LTD' },
  { id: 'PMI', value: 'PMI | ZZ PACIFIC MARKET INTL' },
  { id: 'PMO', value: 'PMO | Spirit Leatherworks, LLC.' },
  { id: 'PMT', value: 'PMT | PARAMOUNT HEADWEAR INC' },
  { id: 'PNH', value: 'PNH | PINEHURST MANUFACTURING' },
  { id: 'PNR', value: 'PNR | PIONEER GARMENTCO LTD' },
  { id: 'PNW', value: 'PNW | PRINT NEWS' },
  { id: 'PNX', value: 'PNX | PARTEX APPAREL MFC INC - F' },
  { id: 'POD', value: 'POD | PODIUM SA' },
  { id: 'POV', value: 'POV | POVOLO' },
  { id: 'POW', value: 'POW | POWERS MANUFACTURING' },
  { id: 'PPJ', value: 'PPJ | Tango Pacific - F' },
  { id: 'PRB', value: 'PRB | PRATHIBA SEZ UNIT' },
  { id: 'PRQ', value: 'PRQ | PROMINENT APPAREL LIMITED-F' },
  { id: 'PRT', value: 'PRT | PRINT INC' },
  { id: 'PTS', value: 'PTS | PATTERN SRL' },
  { id: 'PTT', value: 'PTT | PETTENATI SA INDUSTRIA TEXTIL' },
  { id: 'PUM', value: 'PUM | PUNTO MARTINEZ' },
  { id: 'PUN', value: 'PUN | PUNGKOOK' },
  { id: 'PWE', value: 'PWE | PROMOTIONS WEST' },
  { id: 'PWR', value: 'PWR | POWERS MANUFACTURING-F' },
  { id: 'PX', value: 'PX | ZZ - THAI SUNG SHIN MATERIAL CO., L' },
  { id: 'PYM', value: 'PYM | PERRY MANUFACTURING COMPANY' },
  { id: 'QB', value: 'QB | EVA OVERSEAS INTERNATIONAL LIMITED' },
  { id: 'QD', value: 'QD | CHANGSHIN, INC.' },
  { id: 'QH', value: 'QH | ZZ - QINGDAO SAMHO SHOES CO., LTD.' },
  { id: 'QM', value: 'QM | Mega International Trading Limited' },
  { id: 'QQ', value: 'QQ | Great Ascent Trading Limited' },
  { id: 'QS', value: 'QS | ZZ - QINGDAO SEWON SHOES CO., LTD.' },
  { id: 'QT', value: 'QT | TAE KWANG INDUSTRIAL CO., LTD' },
  { id: 'QTG', value: 'QTG | QINGDAO TAE KWANG SHOES CO., LTD' },
  { id: 'QU', value: 'QU | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'QUD', value: 'QUD | ESQUEL ENTERPRISES LTD F-3 (DEMAND' },
  { id: 'QVC', value: 'QVC | JIAXING QUANG VIET GARMENT CO. LTD.' },
  { id: 'QVE', value: 'QVE | QUANG VIET ENTERPRISE CO LTD' },
  { id: 'RCM', value: 'RCM | ROCKMAUI' },
  { id: 'RCT', value: 'RCT | RANIK GROUP  TOP CROWN CO LTD' },
  { id: 'RD5', value: 'RD5 | ZZ - LIZAL INC.' },
  { id: 'RGL', value: 'RGL | RANIK GROUP - CN' },
  { id: 'RGP', value: 'RGP | The Ranik Group Ltd.' },
  { id: 'RGT', value: 'RGT | THE RANIK GROUP - TAIWAN' },
  { id: 'RH', value: 'RH | PT VICTORY CHINGLUH INDONESIA' },
  { id: 'RIG', value: 'RIG | RA INTERTRADING SA' },
  { id: 'RIP', value: 'RIP | RIPON' },
  { id: 'RJ', value: 'RJ | CHANGSHIN, INC.' },
  { id: 'RJL', value: 'RJL | LIEBE New York' },
  { id: 'RLK', value: 'RLK | HIWAY TEXTILES LIMITED - F3' },
  { id: 'RLM', value: 'RLM | HIWAY TEXTILES LIMITED' },
  { id: 'RMI', value: 'RMI | REGINA MIRACLE INTERNATIONAL' },
  { id: 'RMV', value: 'RMV | REGINA MIRACLE INTERNATIONAL' },
  { id: 'RNK', value: 'RNK | ERENKO' },
  { id: 'ROB', value: 'ROB | ROBINSON MFG' },
  { id: 'RRD', value: 'RRD | BANTA GLOBAL TURNKEY (SHENZHEN) CO.' },
  { id: 'RS', value: 'RS | ZZ - RAMA SHOES INDUSTRIES CO., LTD' },
  { id: 'RSC', value: 'RSC | SHANGHAI RELIABLE SOURCE IND.' },
  { id: 'RSI', value: 'RSI | RELIABLE SOURCE INDUSTRIAL CO' },
  { id: 'RSM', value: 'RSM | R&A INDUMENTARIA SAN MARTIN' },
  { id: 'RTV', value: 'RTV | RIETVELD SERIGRAFIE BV' },
  { id: 'RY', value: 'RY | Yue Yuen Industrial Limited,' },
  { id: 'RYL', value: 'RYL | ROYAL' },
  { id: 'S7', value: 'S7 | ZZ - Taiwan Sakurai MFG Co Ltd' },
  { id: 'S8', value: 'S8 | SPORTS K-PRO LTD' },
  { id: 'SAA', value: 'SAA | SABRINA FASHION INDUSTRIAL CORP.' },
  { id: 'SAB', value: 'SAB | SABRINA FASHION INDUSTRIES-F' },
  { id: 'SAC', value: 'SAC | SABRINA FASHION INDUSTRIAL CORP.' },
  { id: 'SAE', value: 'SAE | CHAMPION ALPHA INC.  (DEMAND PULL)' },
  { id: 'SBD', value: 'SBD | YIELD EXPRESS INC. (DEMAND PULL)' },
  { id: 'SBF', value: 'SBF | Gildan USA Inc (NBSF Clarence - NFL' },
  { id: 'SBS', value: 'SBS | Gildan USA Inc (NBSF Clarence)' },
  { id: 'SCI', value: 'SCI | SPORTS CITY INTERNATIONAL INC' },
  { id: 'SCP', value: 'SCP | SPORTS CITY INTERNATIONAL INC-F' },
  { id: 'SCS', value: 'SCS | SPORTS CITY INTERNATIONAL INC-F1' },
  { id: 'SDP', value: 'SDP | STYLE TEXTILE (PVT) LTD - DEMAND PU' },
  { id: 'SE', value: 'SE | ZZ - SEWON CO LTD' },
  { id: 'SED', value: 'SED | TESSICOT S.A.' },
  { id: 'SEU', value: 'SEU | CHAMPION ALPHA INC. (EUROPE - SLT)' },
  { id: 'SEV', value: 'SEV | VIET HONG GARMENT EXPORT CO LTD (EU' },
  { id: 'SF', value: 'SF | ZZ - FONG BEN FOOTWEAR IND CO., LTD' },
  { id: 'SFI', value: 'SFI | SOFI CLASSIC SA DE CV' },
  { id: 'SGC', value: 'SGC | SHEICO SHEI CHUNG HSIN IND. CO., LT' },
  { id: 'SGF', value: 'SGF | SCREEN GRAPHICS-F' },
  { id: 'SGK', value: 'SGK | SHUI PANG GARMENT & KNITTING' },
  { id: 'SHE', value: 'SHE | SHAHI EXPORTS PVT LTD' },
  { id: 'SHP', value: 'SHP | GILDAN USA INC (HONDURAS DEMAND PUL' },
  { id: 'SID', value: 'SID | SOUTH ISLAND GARMENT SDN BHD' },
  { id: 'SIE', value: 'SIE | SOUTH ISLAND GARMENT DEMAND PULL' },
  { id: 'SIH', value: 'SIH | Shantou S M Yongyi Garment Co., Ltd' },
  { id: 'SIK', value: 'SIK | DNP AND SIG GARMENT LANKA' },
  { id: 'SIM', value: 'SIM | SOUTH ISLAND GARMENT (DEMAND PULL)' },
  { id: 'SIV', value: 'SIV | SOUTH ISLAND GARMENT SDN BHD' },
  { id: 'SJA', value: 'SJA | KANEMATSU TEXTILE CORPORATION - SHA' },
  { id: 'SJC', value: 'SJC | SHANGHAI LAKULING FASHION CO. LTD.' },
  { id: 'SJF', value: 'SJF | JIALE COMPANY LIMITED - FU SENG' },
  { id: 'SJL', value: 'SJL | SEWING JONAH LTD' },
  { id: 'SJW', value: 'SJW | WEIHAI JAEWOO SILICONE CO. LTD.' },
  { id: 'SKC', value: 'SKC | SKY CAMPING' },
  { id: 'SKH', value: 'SKH | Nanan Nanfeng Textiles Co. Ltd.' },
  { id: 'SKK', value: 'SKK | SATO KNIT CO., LTD' },
  { id: 'SKL', value: 'SKL | SOUTH ASIA KNITTING FACT LTD' },
  { id: 'SKP', value: 'SKP | SKIPS CUTTING' },
  { id: 'SKR', value: 'SKR | NANAN NANFENG TEXTILES CO. LTD. - F' },
  { id: 'SLM', value: 'SLM | SELF MAGLIERIA SRL' },
  { id: 'SME', value: 'SME | SUPERTEX (EMERGING MARKETS - SLT)' },
  { id: 'SMM', value: 'SMM | SUMMIT SPORTSWEAR' },
  { id: 'SMX', value: 'SMX | SERIMAX SA DE CV' },
  { id: 'SNA', value: 'SNA | CHAMPION ALPHA INC.' },
  { id: 'SNN', value: 'SNN | SOUTH ISLAND GARMENT' },
  { id: 'SNO', value: 'SNO | SINOTEX (LANKA) LTD.' },
  { id: 'SNP', value: 'SNP | SIMON TOWN INTERNATIONAL - F' },
  { id: 'SNR', value: 'SNR | SUPERTEX (NORTH AMERICA - SLT)' },
  { id: 'SNS', value: 'SNS | SIMON TOWN INTERNATIONAL - NTS' },
  { id: 'SNY', value: 'SNY | Sintex International Ltd.' },
  { id: 'SOK', value: 'SOK | SOCKIT' },
  { id: 'SOR', value: 'SOR | Textil Sport Tech SA' },
  { id: 'SPI', value: 'SPI | SP APPAREL INC' },
  { id: 'SPR', value: 'SPR | SPRINTS LTD' },
  { id: 'SPW', value: 'SPW | SPRINGWEAR' },
  { id: 'SQ', value: 'SQ | STELLA INTERNATIONAL TRADING' },
  { id: 'SRA', value: 'SRA | GILDAN USA INC.' },
  { id: 'SRN', value: 'SRN | GILDAN APPAREL USA INC.' },
  { id: 'SS', value: 'SS | ZZ - K.D.C.' },
  { id: 'SSD', value: 'SSD | SAE SI DAE SPORTS' },
  { id: 'SSJ', value: 'SSJ | SATO TEXTILE CO., LTD' },
  { id: 'SSK', value: 'SSK | SILVER SPIDER KNITTING LTD' },
  { id: 'SSN', value: 'SSN | ANNIC LLC (NORTH AMERICA - SLT)' },
  { id: 'SSW', value: 'SSW | SUTTONS SPORTWEAR' },
  { id: 'ST', value: 'ST | ZZ - SAM YANG TONG SANG CO., LTD.' },
  { id: 'STI', value: 'STI | SIMON TOWN INTERNATIONAL-F' },
  { id: 'STP', value: 'STP | SIMON TOWN INTERNATIONAL' },
  { id: 'STR', value: 'STR | SREETWEAR CORPORATION PRECIEUX' },
  { id: 'STY', value: 'STY | STYLE TEXTILE PVT LTD' },
  { id: 'SUM', value: 'SUM | SUMMIT' },
  { id: 'SV', value: 'SV | SUOLA SA' },
  { id: 'SVT', value: 'SVT | SIMON TOWN INTERNATIONAL - VIETNAM' },
  { id: 'SVV', value: 'SVV | TUNG MUNG TEXTILE CO (SINGAPORE)' },
  { id: 'SWC', value: 'SWC | STONE ISLAND' },
  { id: 'SWR', value: 'SWR | Sportswear Company S.p.A.' },
  { id: 'SXD', value: 'SXD | SUPERTEX DEMAND PULL' },
  { id: 'SXM', value: 'SXM | SUPERTEX' },
  { id: 'SYA', value: 'SYA | SANGYOUNG APPAREL' },
  { id: 'SYC', value: 'SYC | ZHENJIANG TUNTEX' },
  { id: 'SYG', value: 'SYG | PT Tuntex Garment Indonesia' },
  { id: 'SYP', value: 'SYP | PT. TUNTEX GARMENT INDONESIA' },
  { id: 'SYS', value: 'SYS | PT Tuntex Garment Indonesia' },
  { id: 'SZ', value: 'SZ | STELLA INTERNATIONAL TRADING' },
  { id: 'T2', value: 'T2 | TAE KWANG INDUSTRIAL CO., LTD' },
  { id: 'T6', value: 'T6 | SSIPL RETAIL LTD, UNIT-III' },
  { id: 'TAD', value: 'TAD | THAI SOCK CO. LTD. (DEMAND PULL)' },
  { id: 'TAI', value: 'TAI | Taitat Putra Rejeki, PT' },
  { id: 'TDV', value: 'TDV | TEXTIL DEL VALLES A' },
  { id: 'TEF', value: 'TEF | TEFRON LTD' },
  { id: 'TEP', value: 'TEP | TEFRON - DEMAND PULL' },
  { id: 'TEU', value: 'TEU | TAI WAH GARMENTS INDUSTRY SDN BHD' },
  { id: 'TFT', value: 'TFT | TEFRON - NTS' },
  { id: 'TGA', value: 'TGA | ART FX INC' },
  { id: 'TGE', value: 'TGE | THAI GARMENT EXPORT CO LTD' },
  { id: 'TGF', value: 'TGF | Fjord, Ltd' },
  { id: 'TGH', value: 'TGH | NEW HOLLAND APPAREL' },
  { id: 'TGL', value: 'TGL | LATITUDES' },
  { id: 'TGN', value: 'TGN | NEW HOLLAND APPAREL' },
  { id: 'TGR', value: 'TGR | TRAGER' },
  { id: 'TGV', value: 'TGV | DECOTEX' },
  { id: 'THK', value: 'THK | TAY HSIN KNITTING CO LTD-F' },
  { id: 'THN', value: 'THN | I Sports International Company Limi' },
  { id: 'THS', value: 'THS | THAI SOCK CO. LTD.' },
  { id: 'THX', value: 'THX | HITEX' },
  { id: 'TIC', value: 'TIC | LIBERTY GARMENT CO LTD.' },
  { id: 'TIE', value: 'TIE | TIE TEXTILES INT OF EGYPT' },
  { id: 'TIP', value: 'TIP | TIE TEXTILES - DEMAND PULL LOC' },
  { id: 'TIX', value: 'TIX | TIMEX GARMENTS LTD.' },
  { id: 'TKN', value: 'TKN | INOVA TEXTILES, S.A. DE C.V.' },
  { id: 'TMA', value: 'TMA | TUNG MUNG INTERNATIONAL' },
  { id: 'TMD', value: 'TMD | TUNG MUNG INTERNATIONAL PTE LTD' },
  { id: 'TMV', value: 'TMV | TUNG MUNG INTERNATIONAL' },
  { id: 'TNA', value: 'TNA | TAI WAH GARMENTS INDUSTRY SDN BHD' },
  { id: 'TNC', value: 'TNC | RAMATEX INDUSTRIAL (SUZHOU) LTD' },
  { id: 'TP2', value: 'TP2 | TAIWAN PAIHO LIMITED' },
  { id: 'TPC', value: 'TPC | TEXTILES PANTER LTDA' },
  { id: 'TPL', value: 'TPL | TAIWAN PAIHO LIMITED-F' },
  { id: 'TRG', value: 'TRG | THE RANIK GROUP' },
  { id: 'TSP', value: 'TSP | THAI SILP SOUTH EAST' },
  { id: 'TST', value: 'TST | EXCELLENT JADE - HEFEI TRISTATE GAR' },
  { id: 'TT', value: 'TT | PT. TK INDUSTRIAL INDONESIA' },
  { id: 'TTT', value: 'TTT | THONG THAI TEXTILE CO LTD' },
  { id: 'TWA', value: 'TWA | TAI WAH GARMENTS INDUSTRY SDN BHD' },
  { id: 'TWB', value: 'TWB | PT GIMMILL INDUSTRIAL BINTAN' },
  { id: 'TWC', value: 'TWC | RAMATEX INDUSTRIAL (SUZHOU) LTD' },
  { id: 'TWD', value: 'TWD | TRIGOLDENSTAR WISESA INDUSTRIES PT' },
  { id: 'TWG', value: 'TWG | GIMMILL INDUSTRIAL PTE LTD.' },
  { id: 'TWI', value: 'TWI | TRIGOLDENSTAR WISESA INDUSTRIES PT' },
  { id: 'TWJ', value: 'TWJ | Pine Tree Company For Textile' },
  { id: 'TWK', value: 'TWK | VIOLET APPAREL  CO. LTD.' },
  { id: 'TWL', value: 'TWL | TAI WAH GARMENTS INDUSTRY SDN BHD (' },
  { id: 'TWM', value: 'TWM | MAPLE COMPANY LIMITED' },
  { id: 'TWO', value: 'TWO | OLIVE APPAREL (CAMBODIA) CO., LTD' },
  { id: 'TWP', value: 'TWP | TAI WAH GARMENTS INDUSTRY SDN BHD' },
  { id: 'TWY', value: 'TWY | YIXING GUO FENG GARMENT' },
  { id: 'TXT', value: 'TXT | TEXTURERIAS SA DE CV' },
  { id: 'TYC', value: 'TYC | TA YEH CO LTD' },
  { id: 'UA', value: 'UA | ZZ - ATTELLA, LTD' },
  { id: 'UCC', value: 'UCC | HEMIAN INTERNATIONAL TRADING (SHANG' },
  { id: 'UDG', value: 'UDG | UDON GARMENT FACTORY CO LTD' },
  { id: 'UF', value: 'UF | ZZ - UNION FOOTWEAR PUBLIC CO., LTD' },
  { id: 'UFG', value: 'UFG | UNION FRIEND GARMENT' },
  { id: 'UFL', value: 'UFL | UNION FRIEND GARMENT CO. LTD.' },
  { id: 'UGV', value: 'UGV | UPGAIN VIETNAM MANUFACTURING' },
  { id: 'UL', value: 'UL | ZZ - LONG DEAN CO., LTD' },
  { id: 'UP', value: 'UP | ZZ - PERFECT GLOBAL ENTERPRISES, LT' },
  { id: 'UP1', value: 'UP1 | ZZ - PERFECT GLOBAL ENTERPRISES, LT' },
  { id: 'UQ', value: 'UQ | ZZ - XIAMEN REAL-TOP SPORTING GOODS' },
  { id: 'USD', value: 'USD | UNITED SWEETHEARTS GARMENT SDN BHD' },
  { id: 'USE', value: 'USE | US EMBROIDERY' },
  { id: 'USH', value: 'USH | UNITED SWEETHEARTS GARMENT SDN BHD' },
  { id: 'USP', value: 'USP | UNITED SWEETHEARTS GARMENT SDN BHD' },
  { id: 'USV', value: 'USV | UNITED SWEETHEARTS GARMENT SDN BHD' },
  { id: 'UT', value: 'UT | ZZ - TRANS ASIA SHOES CO., LTD' },
  { id: 'UT1', value: 'UT1 | ZZ - TRANS ASIA SHOES CO., LTD' },
  { id: 'UT2', value: 'UT2 | ZZ - TRANS ASIA SHOES CO., LTD' },
  { id: 'UT3', value: 'UT3 | ZZ - TRANS ASIA SHOES CO., LTD' },
  { id: 'UTK', value: 'UTK | UNITEKS Gida Tekstil Sanayii ve Tic' },
  { id: 'UX', value: 'UX | ZZ - UNION FOOTWEAR PUBLIC CO., LTD' },
  { id: 'V2', value: 'V2 | FALCON UNIVERSAL SERVICES CORPORATI' },
  { id: 'V3', value: 'V3 | STELLA INTERNATIONAL TRADING' },
  { id: 'VB', value: 'VB | FALCON UNIVERSAL SERVICES' },
  { id: 'VE', value: 'VE | JIA INTERNATIONAL CO., LTD.' },
  { id: 'VF', value: 'VF | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'VG', value: 'VG | Sports Gear Co., Ltd Taiwan Branch' },
  { id: 'VH', value: 'VH | FALCON UNIVERSAL SERVICES' },
  { id: 'VJ', value: 'VJ | CHANGSHIN, INC.' },
  { id: 'VKM', value: 'VKM | VERTICAL KNITS S.A. DE CV' },
  { id: 'VKT', value: 'VKT | VERTICAL KNITS S.A. DE CV' },
  { id: 'VL', value: 'VL | JIA INTERNATIONAL CO., LTD.' },
  { id: 'VM', value: 'VM | TAE KWANG INDUSTRIAL CO., LTD' },
  { id: 'VML', value: 'VML | VIMAL CLOTHING ENTERPRISE CC' },
  { id: 'VMR', value: 'VMR | EVENT MARKETING GROUP' },
  { id: 'VN', value: 'VN | ZZ - CHANGSHIN VIETNAM CO., LTD (VN' },
  { id: 'VNI', value: 'VNI | IBEMA INDUSTRIA DE CONFECCOES EIREL' },
  { id: 'VNT', value: 'VNT | VANTAGE' },
  { id: 'VNX', value: 'VNX | VANTEX INDUSTRIAL LTDA.' },
  { id: 'VO', value: 'VO | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'VP', value: 'VP | Yue Yuen Industrial Limited,' },
  { id: 'VQ', value: 'VQ | Great Ascent Trading Limited' },
  { id: 'VR', value: 'VR | ZZ - VIETNAM SAMHO CO., LTD.' },
  { id: 'VS', value: 'VS | ZZ - VIETNAM SAMHO CO., LTD.' },
  { id: 'VT', value: 'VT | TAE KWANG INDUSTRIAL CO., LTD' },
  { id: 'VTG', value: 'VTG | VT GARMENT CO LTD' },
  { id: 'VTI', value: 'VTI | VT INDUSTRIES' },
  { id: 'VTT', value: 'VTT | VT GARMENT - BANGKOK' },
  { id: 'VW', value: 'VW | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'VX', value: 'VX | ZZ - DONA VICTOR FOOTWEAR CO., LTD.' },
  { id: 'VY', value: 'VY | Yue Yuen Industrial Limited,' },
  { id: 'VZ', value: 'VZ | JIA INTERNATIONAL CO., LTD.' },
  { id: 'W1', value: 'W1 | ZZ - FLEXTRONICS INTERNATIONAL EURO' },
  { id: 'W2', value: 'W2 | FLEXTRONICS INTERNATIONAL EUROPE BV' },
  { id: 'WAC', value: 'WAC | WACOAL - CHINA' },
  { id: 'WDA', value: 'WDA | SUPERCAP INDUSTRIAL CO LTD' },
  { id: 'WDC', value: 'WDC | SUPERCAP INDUSTRIAL CO LTD-F' },
  { id: 'WDI', value: 'WDI | SUPERCAP INDUSTRIAL CO LTD-F1' },
  { id: 'WLS', value: 'WLS | BARBARA INTERNATIONAL TRADING LTD.' },
  { id: 'WNC', value: 'WNC | WIN HANVERKY LTD' },
  { id: 'WNH', value: 'WNH | WIN HANVERKY LIMITED' },
  { id: 'WS', value: 'WS | ZZ - DONGGUAN WEALTHSTEP SHOES CO.,' },
  { id: 'WWW', value: 'WWW | WECH WIWAT CO LTD' },
  { id: 'WYG', value: 'WYG | WAY YAT INDUSTRIAL LIMITED' },
  { id: 'XB', value: 'XB | EVA OVERSEAS INTERNATIONAL LIMITED' },
  { id: 'XC', value: 'XC | EVA OVERSEAS INTERNATIONAL LIMITED' },
  { id: 'XD', value: 'XD | ZZ - FREETREND INDUSTRIAL LTD.' },
  { id: 'XE', value: 'XE | JIA INTERNATIONAL CO., LTD.' },
  { id: 'XF', value: 'XF | ZZ - CAPITAL CONCORD ENTERPRISES LT' },
  { id: 'XG', value: 'XG | EVA OVERSEAS INTERNATIONAL LIMITED' },
  { id: 'XH', value: 'XH | EVA OVERSEAS INTERNATIONAL LIMITED' },
  { id: 'XI', value: 'XI | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'XJ', value: 'XJ | CHANGSHIN, INC.' },
  { id: 'XK', value: 'XK | SALMOND TRADING LIMITED' },
  { id: 'XL', value: 'XL | ZZ -GROWTH-LINK OVERSEAS COMPANY LI' },
  { id: 'XM', value: 'XM | Mega International Trading Limited' },
  { id: 'XN', value: 'XN | Yue Yuen Industrial Limited,' },
  { id: 'XO', value: 'XO | ZZ - EXO ITALIA S.R.L. MASERA\' PADO' },
  { id: 'XP', value: 'XP | ZZ - POU CHEN VIETNAM ENTERPRISE, L' },
  { id: 'XQ', value: 'XQ | Great Ascent Trading Limited' },
  { id: 'XR', value: 'XR | ZZ - EXO ITALIA S.R.L. MASERA\' PADO' },
  { id: 'XS', value: 'XS | EVA OVERSEAS INTERNATIONAL LIMITED' },
  { id: 'XV', value: 'XV | FENG TAY ENTERPRISES CO. LTD.' },
  { id: 'XY', value: 'XY | Yue Yuen Industrial Limited,' },
  { id: 'Y3', value: 'Y3 | ZZ - IDEA (MACAO COMMERCIAL' },
  { id: 'YAN', value: 'YAN | Nice Apparel Co. Ltd' },
  { id: 'YEP', value: 'YEP | JADE TEXTILE EGYPT SAE (DEMAND PULL' },
  { id: 'YGR', value: 'YGR | NINGBO YOUNGOR FASHION CO. LTD.' },
  { id: 'YNC', value: 'YNC | YOUNGONE CORPORATION-F' },
  { id: 'YNG', value: 'YNG | YOUNGONE CORPORATION' },
  { id: 'YPG', value: 'YPG | YUPOONG INC-F' },
  { id: 'YPV', value: 'YPV | YUPOONG VIETNAM LTD' },
  { id: 'YS', value: 'YS | IDEA (MACAO COMMERCIAL OFFSHORE)' },
  { id: 'YSA', value: 'YSA | YESIM SATIS MAGAZALARI TEKSTIL' },
  { id: 'YSE', value: 'YSE | Jade Textile Egypt SAE' },
  { id: 'YSM', value: 'YSM | YESIM SATIS MAGAZALARI TEKSTIL' },
  { id: 'YSN', value: 'YSN | YU SHIN' },
  { id: 'YSP', value: 'YSP | YESIM DEMAND PULL' },
  { id: 'YUM', value: 'YUM | YUMEI SHANTOU GARMENTS CO' },
  { id: 'YUP', value: 'YUP | YUPOONG INC' },
  { id: 'YVN', value: 'YVN | YOUNGONE NAM DINH CO LTD' },
  { id: 'YX', value: 'YX | ZZ - IDEA (MACAO COMMERCIAL' },
  { id: 'YYA', value: 'YYA | YY Associates Inc dba Evergreen' },
  { id: 'ZCK', value: 'ZCK | ZHONGSHAN' },
  { id: 'ZPD', value: 'ZPD | ZHEJIANG PALMA DRESS CO LTD' },
  { id: 'ZTM', value: 'ZTM | ZENTRIX S DE RL DE CV' },
  { id: 'ZTX', value: 'ZTX | ZENTRIX INDUSTRIES LIMITED' }
];

export const pmoDecCode = [
  { value: 'AR00SA', id: 'AR00SA' },
  { value: 'BR00CS', id: 'BR00CS' },
  { value: 'CA02CA', id: 'CA02CA' },
  { value: 'CN02CN', id: 'CN02CN' },
  { value: 'IN00ID', id: 'IN00ID' },
  { value: 'ID00IN', id: 'ID00IN' },
  { value: 'JP00JP', id: 'JP00JP' },
  { value: 'US01JD', id: 'US01JD' },
  { value: 'KR01KR', id: 'KR01KR' },
  { value: 'SG00SG', id: 'SG00SG' },
  { value: 'SF00SF', id: 'SF00SF' },
  { value: 'US04SX', id: 'US04SX' },
  { value: 'TW00TW', id: 'TW00TW' },
  { value: 'TH00TH', id: 'TH00TH' },
  { value: 'TR00TK', id: 'TR00TK' },
  { value: 'VN00VN', id: 'VN00VN' },
  { value: 'AR00A1', id: 'AR00A1' },
  { value: 'BR00B2', id: 'BR00B2' },
  { value: 'US0701', id: 'US0701' },
  { value: 'CN00N1', id: 'CN00N1' },
  { value: 'JP01J2', id: 'JP01J2' },
  { value: 'KR01K3', id: 'KR01K3' },
  { value: 'ZA00A1', id: 'ZA00A1' },
  { value: 'TH01L1', id: 'TH01L1' },
  { value: 'TR01TE', id: 'TR01TE' },
  { value: 'ID01D1', id: 'ID01D1' },
  { value: 'BR00B1', id: 'BR00B1' },
  { value: 'CN00N1', id: 'CN00N1' },
  { value: 'ID01D1', id: 'ID01D1' },
  { value: 'IT01I1', id: 'IT01I1' },
  { value: 'TW01T1', id: 'TW01T1' },
  { value: 'KR00K1', id: 'KR00K1' },
  { value: 'VN01V1', id: 'VN01V1' }
];
