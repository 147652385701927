/**
 * This file serves the endpoints for all the DPOM api.
 * @exports function that returns the endpoint details.
 * @flow
 */
import apiMapping from '../constants/apiMapping';
import messageText from '../constants/messageText';

/**
  * This function returns the endpoint details for the matched api in the param
  * @param {string} endPoint the endPoint params api to which the endpoint has to be returned
  * @param {boolean} mockType an route param for the mock.
  * @param {boolean} retryType an route param for the retry.
  */
const getEP = ({
  api,
  endPoint,
  mockType = false,
  retryType = true,
  timeoutErrorMessage = '',
  appName = ''
}) => ({
  endPoint,
  mock: mockType,
  retry: retryType,
  api,
  timeoutErrorMessage,
  appName
});

const getEndPointWithParams = (endPoint: string, routeParams: Object) => {
  const routeParamKeys = Object.keys(routeParams);
  let finalEndpoint = endPoint;
  routeParamKeys.forEach((key) => { finalEndpoint = finalEndpoint.replace(`{{${key}}}`, routeParams[key]); });
  return finalEndpoint;
};

/**
  * This function returns the endpoint details for the matched api in the param
  * @param {string} api the api identifier to which the endpoint has to be returned
  * @param {Object} routeParams an optional route param for the endpoint.
  */
const serviceAPI = (api: string, routeParams: Object) => {
  if (!apiMapping[api]) {
    return getEP({
      api: '',
      endPoint: '',
      name: '',
      apiErrorName: '',
      mock: false,
      retry: false,
      appName: ''
    });
  }
  const {
    endPoint, apiErrorName, type
  } = apiMapping[api];

  let errorMessage = messageText.defaultTimeoutErrorMessage;
  let endPointWithParams = endPoint;

  if (apiErrorName) {
    errorMessage = messageText.dynamicText(`timeoutErrorMessage${type}`, apiErrorName);
  }
  if (routeParams) {
    endPointWithParams = getEndPointWithParams(endPoint, routeParams);
  }
  return getEP({
    ...apiMapping[api],
    api,
    endPoint: endPointWithParams,
    timeoutErrorMessage: errorMessage
  });
};
export default serviceAPI;
