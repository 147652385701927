/* @flow */
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import request from '../../services/request';
import {
  updateDialogStates,
  toggleSpinner, togglePaginationSpinner
} from '../dialogStates/dialogStatesSlice';
import getflattenResponse from '../../helpers/common';
import fieldMapping from '../../constants/fieldMappings/poSearchFieldMapping';
import { searchCategory } from '../mock/ConversationThreadMock';
import { initialConversationSearchFields } from '../../constants/staticData';
import appConfig from '../../appConfig';

export const name = 'conversationThreads';

function createInitialState() {
  return {
    conversationData: {},
    searchCategory: {},
    conversationMetaData: [],
    failedConversation: false,
    reDirectConversation: { canRedirect: false, callSuccess: false },
    completeConversation: {},
    lodingSpinner: true,
    conversationsTotalCount: 0,
    conversationsNextResults: {},
    conversationsPrevResults: {},
    page: 0,
    rowsPerPage: 10,
    query: '',
    currentChatMessage: [],

    category: {},
    stackHoldersEmail: [],
    validatedEmail: '',
    convoInputValues: { ...initialConversationSearchFields },
    convoSelectedFields: [],
    isStakeholderValue: true,
    comingFrom: ''
  };
}

export const getUpdatedData = (conversation: Object, updatedConversation: Object) => {
  const currentConversation = { ...conversation };
  const currentConversationArray = [...conversation.objects];
  conversation.objects.forEach((element, index) => {
    if (element.id === updatedConversation.id) {
      // const [updatedItem] = updatedConversation;
      currentConversationArray[index] = updatedConversation;
    }
  });
  const updateData = { ...currentConversation, objects: currentConversationArray };
  return updateData;
};

export const initialState = createInitialState();

function createReducers() {
  function fetchConversationTheards(state: Object, action: Object) {
    // CONVERSATIONS_THEARDS
    return {
      ...state,
      category: action?.payload?.categoryList
    };
  }

  function validateStackHoldersEmailID(state: Object, action: Object) {
    // VALIDATE_STACKHOLDER_EMAILID

    return {
      ...state,
      validatedEmail: action.payload.emaildID
    };
  }

  function updateConversationList(state: Object, action: Object) {
    // UPDATE_CONVERSATION_LIST

    const currentState = current(state);
    return {
      ...state,
      conversationData: getUpdatedData(currentState.conversationData, action.payload)
    };
  }

  function changeToIntialState(state: Object) {
    // RESET_REDUCER
    return {
      ...state,
      ...initialState,
      comingFrom: ''
    };
  }

  function createConversation(state: Object, action: Object) {
    // GET_CONVERSATION_THREADS_SUCCESS
    return {
      ...state,
      reDirectConversation: { canRedirect: true, callSuccess: true },
      conversationData: { objects: [...action.payload.conversations] },
      searchCategory: action.payload.searchCategory,
      completeConversation: action.payload.conversations[0],
      comingFrom: 'POSEARCH',
      conversationsTotalCount: 0,
      convoInputValues: { ...initialConversationSearchFields },
      convoSelectedFields: [],
      isStakeholderValue: true
    };
  }

  function updateConversationMsg(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREADS_SUCCESS

    const currentState = current(state);
    return {
      ...state,
      completeConversation: action.payload.conversations[0],
      conversationData: getUpdatedData(
        currentState.conversationData, action.payload.conversations[0]
      ),
      lodingSpinner: false
    };
  }

  function getMetaData(state: Object, action: Object) {
    // GET_CONVERSATION_META_DATA
    return {
      ...state,
      conversationMetaData: action.payload.conversationCodes.objects
    };
  }

  function failedConversation(state: Object) {
    // CREATE_CONVERSATION_FAILED
    return {
      ...state,
      failedConversation: true,
      reDirectConversation: { canRedirect: false, callSuccess: false }
    };
  }

  function changeFailedState(state: Object, action: Object) {
    // CHANGE_FAILED_STATE
    return {
      ...state,
      failedConversation: action.payload
    };
  }

  function listConversation(state: Object, action: Object) {
    // COLLAB_LIST
    return {
      ...state,
      conversationData: action.payload.data.objects ? action.payload.data
        : { objects: action.payload.data },
      completeConversation: action.payload.data.objects && action.payload.data.objects.length > 0
        ? { ...state.completeConversation } : {},
      lodingSpinner: false,
      conversationsTotalCount: action.payload.data.pages
        ? action.payload.data.pages.totalResources : 0,
      conversationsNextResults: null,
      conversationsPrevResults: null
    };
  }

  function detailedConversation(state: Object, action: Object) {
    // CONVERSATION_WITH_DETAILS
    return {
      ...state,
      completeConversation: action.payload.conversationsDetails
    };
  }

  function updateAttachmentsForCurrentChat(state: Object, action: Object) {
    // UPDATE_ATTACHMENTS_FOR_CURRENT_CHAT
    if (!(action.payload && action.payload.map)) return state;
    const updatedConversation = { ...state.completeConversation };
    updatedConversation.attachments = action.payload;
    updatedConversation.attachmentsCount = action.payload.length;
    return {
      ...state,
      completeConversation: updatedConversation
    };
  }

  function updateAttachmentsById(state: Object, action: Object) {
    // UPDATE_ATTACHMENTS_BY_ID
    const updatedConversation = { ...current(state).conversationData };
    const updatedConversationObject = (current(state).conversationData?.objects)
      ? [...current(state).conversationData.objects] : [];
    const attachments = [...action.payload.attachments];
    const attachmentsCount = attachments.length;
    updatedConversation.objects.forEach((chat, index) => {
      if (chat.collaborationId === action.payload.id) {
        updatedConversationObject[index] = {
          ...chat,
          attachments,
          attachmentsCount
        };
      }
    });
    const updateConversationData = {
      ...updatedConversation,
      objects: updatedConversationObject
    };
    return {
      ...state,
      conversationData: updateConversationData
    };
  }

  function resetRedirection(state: Object) {
    // CONVERSATION_RESET_REDIRECTION
    return {
      ...state,
      reDirectConversation: { canRedirect: false, callSuccess: false }
    };
  }

  function removeAttachmentFromConversation(state: Object, action: Object) {
    // REMOVE_ATTACHMENT_FROM_CONVERSATION
    const updatedConversation = { ...current(state).completeConversation };
    const updatedConversationList = { ...current(state).conversationData };
    const updatedConversationListObject = [...current(state).conversationData.objects];
    if (
      updatedConversation.attachments
      && updatedConversation.attachments.filter
    ) {
      updatedConversation.attachments = updatedConversation.attachments.filter(
        (attachment) => attachment.fileLocation !== action.payload
      );
      updatedConversation.attachmentsCount = updatedConversation.attachments.length;
      const currentConversationIndex = updatedConversationList.objects.findIndex(
        (conversation) => updatedConversation.collaborationId === conversation.collaborationId
      );
      updatedConversationListObject[currentConversationIndex] = {
        ...updatedConversationListObject[currentConversationIndex],
        attachments: updatedConversation.attachments,
        attachmentsCount: updatedConversation.attachmentsCount
      };
    }
    const updatedConversationData = {
      ...updatedConversationList,
      objects: updatedConversationListObject
    };
    return {
      ...state,
      completeConversation: updatedConversation,
      conversationData: updatedConversationData
    };
  }

  function ConversationsOffset(state: Object, action: Object) {
    // CONVERSATIONS_RESULT_OFFSET

    const payload = {
      data: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      conversationData: payload.data.objects
        ? payload.data : { objects: payload.data },
      completeConversation: {},
      lodingSpinner: false,
      conversationsTotalCount: payload.data.pages
        ? payload.data.pages.totalResources : 0,
      conversationsNextResults: null,
      conversationsPrevResults: null
    };
  }

  function updateConversationsNextResult(state: Object, action: Object) {
    // UPDATE_CONVERSATIONS_NEXT_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataNext: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      conversationData: payload.data,
      conversationsNextResults: payload.dataNext,
      conversationsTotalPages: payload.data.pages.totalPages,
      conversationsTotalCount: payload.data.pages.totalResources
    };
  }

  function appendNextResultSet(state: Object) {
    // UPDATE_CONVERSATIONS_NEXT_APPEND
    const updatedCurrentResultSet = state.conversationData;
    const updatedNextResultSet = state.conversationsNextResults;

    const payload = {
      dataPrev: updatedCurrentResultSet,
      data: updatedNextResultSet
    };

    return {
      ...state,
      conversationData: payload.data,
      conversationsPrevResults: payload.dataPrev,
      conversationsNextResults: null
    };
  }

  function appendPrevResultSet(state: Object) {
    // UPDATE_CONVERSATIONS_PREV_APPEND
    const updatedPrevResultSet = state.conversationsPrevResults;
    const updatedCurrentResultSet = state.conversationData;

    const payload = {
      data: updatedPrevResultSet,
      dataNext: updatedCurrentResultSet
    };

    return {
      ...state,
      conversationData: payload.data,
      conversationsNextResults: payload.dataNext,
      conversationsPrevResults: null
    };
  }

  function updateConversationsPrevResult(state: Object, action: Object) {
    // UPDATE_CONVERSATIONS_PREV_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataPrev: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      conversationData: payload.data,
      conversationsPrevResults: payload.dataPrev,
      conversationsTotalPages: payload.data.pages.totalPages,
      conversationsTotalCount: payload.data.pages.totalResources
    };
  }

  function updateConversationsPage(state: Object, action: Object) {
    // UPDATE_CONVERSATIONS_PAGE

    return {
      ...state,
      page: action.payload
    };
  }

  function updateConversationsRowsPerPage(state: Object, action: Object) {
    // UPDATE_CONVERSATIONS_ROWS_PER_PAGE

    return {
      ...state,
      rowsPerPage: action.payload
    };
  }

  function updateConversationsQuery(state: Object, action: Object) {
    // UPDATE_CONVERSATIONS_QUERY

    return {
      ...state,
      query: action.payload
    };
  }

  function updateMessage(state: Object, action: Object) {
    // UPDATE_CONVERSATION_MESSAGE
    const updatedConvoMsg = [...state.currentChatMessage];
    updatedConvoMsg.push(action.payload);

    return {
      ...state,
      currentChatMessage: updatedConvoMsg
    };
  }

  function updateMessageOnCallback(state: Object, action: Object) {
    // UPDATE_SUCCESSFUL_CONVERSATION_MESSAGE
    const updatedConversationMessage = [...state.currentChatMessage];

    return {
      ...state,
      currentChatMessage: updatedConversationMessage.filter((message) => (
        message.messageId !== action.payload
      ))
    };
  }
  function updateConversationInputValues(state: Object, action: Object) {
    return {
      ...state,
      convoInputValues: action.payload
    };
  }
  function updateConvoSelectedFields(state: Object, action: Object) {
    return {
      ...state,
      convoSelectedFields: action.payload
    };
  }
  function updateCollabrationOnlyValue(state: Object, action: Object) {
    return {
      ...state,
      isStakeholderValue: action.payload
    };
  }
  function updateComingFromData(state: Object, action: Object) {
    return {
      ...state,
      comingFrom: action.payload
    };
  }
  return {
    fetchConversationTheards,
    validateStackHoldersEmailID,
    updateConversationList,
    changeToIntialState,
    createConversation,
    updateConversationMsg,
    getMetaData,
    failedConversation,
    changeFailedState,
    listConversation,
    detailedConversation,
    updateAttachmentsForCurrentChat,
    updateAttachmentsById,
    resetRedirection,
    removeAttachmentFromConversation,
    ConversationsOffset,
    updateConversationsNextResult,
    appendNextResultSet,
    appendPrevResultSet,
    updateConversationsPrevResult,
    updateConversationsPage,
    updateConversationsRowsPerPage,
    updateConversationsQuery,
    updateMessage,
    updateMessageOnCallback,
    updateConversationInputValues,
    updateConvoSelectedFields,
    updateCollabrationOnlyValue,
    updateComingFromData
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function getConversationThreadsDetails() {
    return createAsyncThunk(
      `${name}/getConversationThreadsDetails`,
      async ({
        params
      }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        const payload = {
          searchCategory: searchCategory.categoryList,
          conversations: []
        };
        request({
          api: 'getConversations',
          method: 'post',
          data: params,
          cancellable: true
        }, dispatch)
          .then((response) => {
            payload.conversations = [];
            payload.conversations.push(response.data);
            dispatch(actions.createConversation(payload));
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            dispatch(actions.failedConversation(error));
            dispatch(toggleSpinner(false));
          });
      }
    );
  }

  function getConversationCodes() {
    return createAsyncThunk(
      `${name}/getConversationCodes`,
      async ({
        callback
      }, { dispatch }) => {
        const payload = {};
        dispatch(toggleSpinner(true));
        dispatch(updateDialogStates({ getConversationCodesSpinner: true }));
        const fields = 'ConversationCode,ConversationDescription,modifiedBy,modifiedDate,isActive,id,conversationCategory';
        const filters = 'isActive(false),isActive(true)';
        const query = `?fields=${fields}&filter=${filters}`;
        request({
          api: 'getConversationCodes',
          routeParams: { query },
          method: 'get'
        }, dispatch).then((response) => {
          dispatch(updateDialogStates({ getConversationCodesSpinner: false }));
          payload.conversationCodes = response.data;
          dispatch(actions.getMetaData(payload));
          if (callback) {
            callback(response);
          }
          dispatch(toggleSpinner(false));
        }).catch((error) => {
          dispatch(updateDialogStates({ getConversationCodesSpinner: false }));
          if (callback) {
            callback(null, error);
          }
          dispatch(toggleSpinner(false));
        });
      }
    );
  }

  function sendAttachedFile() {
    return createAsyncThunk(
      `${name}/sendAttachedFile`,
      async ({
        fileData,
        callback
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'uploadFileForConversation',
          method: 'fileUpload',
          data: fileData
        }, dispatch)
          .then((response) => {
            dispatch(actions.updateAttachmentsById({
              id: response.data.collaborationId,
              attachments: response.data.attachments,
              attachmentsCount: response.data.attachmentsCount
            }));
            const state = getState();
            if (
              state.conversationThreads.completeConversation
              && (state.conversationThreads.completeConversation.collaborationId
                === response.data.collaborationId)
            ) {
              dispatch(actions.updateAttachmentsForCurrentChat(
                response.data.attachments
              ));
            }
            if (callback) {
              callback(response.data);
            }
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            if (callback) {
              callback(null, error);
            }
            dispatch(toggleSpinner(false));
          });
      }
    );
  }

  function updateConversationData() {
    return createAsyncThunk(
      `${name}/updateConversationData`,
      async ({
        data,
        id
      }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        const payload = {
          searchCategory: searchCategory.categoryList,
          conversations: []
        };
        request({
          api: 'updateConversationData',
          routeParams: { id },
          method: 'put',
          data
        }, dispatch)
          .then((response) => {
            payload.conversations = [];
            payload.conversations.push(response.data);
            dispatch(actions.updateConversationMsg(payload));
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            console.log(error);
          });
      }
    );
  }

  function fetchConversationsResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchConversationsResultsOffset`,
      async ({
        getState
      }, { dispatch }) => {
        const state = getState();
        if (state.conversationThreads.conversationData) {
          const offset = 0;
          const searchQuery = state.conversationThreads.query;
          const fields = `id,collaborationId,stakeHolders,collaborationTopic,createdDate,modifiedDate,lastConversationDate,
          stakeHoldersCount,vendorCodes,poLineItemsCount,attachmentsCount`;
          const searchField = searchQuery || '&filter=collaborationStatus(OPEN)&sort=lastConversationDate';
          const query = `?count=${appConfig.convoSearchCount}&fields=${fields}${searchField}&offset=${offset}`;
          dispatch(toggleSpinner(true));
          request({
            api: 'queryConversationThreads',
            routeParams: { query },
            method: 'get'
          }, dispatch).then((response) => {
            dispatch(actions.ConversationsOffset(response));
            dispatch(toggleSpinner(false));
          }).catch((error) => {
            console.log('NEXT Error ', error);
            dispatch(toggleSpinner(false));
          });
        }
        return () => false;
      }
    );
  }

  function ConversationsDataOffset() {
    return createAsyncThunk(
      `${name}/ConversationsDataOffset`,
      async ({
        pofetchConversationsResultsOffset
      }, { dispatch }) => {
        dispatch(pofetchConversationsResultsOffset());
      }
    );
  }

  function fetchReportNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportNextResultSet`,
      async ({
        getState
      }, { dispatch }) => {
        const state = getState();
        if (state.conversationThreads.conversationData
          && state.conversationThreads.conversationData.pages.next) {
          let offset = 0;
          const searchQuery = state.conversationThreads.query;
          const fields = `id,collaborationId,stakeHolders,collaborationTopic,createdDate,modifiedDate,lastConversationDate,
          stakeHoldersCount,vendorCodes,poLineItemsCount,attachmentsCount`;
          const searchField = searchQuery || '&filter=collaborationStatus(OPEN)&sort=lastConversationDate';
          const currentResultSet = state.conversationThreads.conversationData;
          const searchResultNextOffset = state.conversationThreads.conversationData.pages.next;
          const resNext = searchResultNextOffset.indexOf(',');
          offset = searchResultNextOffset.slice(7, resNext);
          const query = `?count=${appConfig.convoSearchCount}&fields=${fields}${searchField}&offset=${offset}`;
          dispatch(togglePaginationSpinner({ isFetchingNextData: true }));
          request({
            api: 'queryConversationThreads',
            routeParams: { query },
            method: 'get'
          }, dispatch).then((response) => {
            dispatch(actions.updateConversationsNextResult(response, currentResultSet));
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
          }).catch((error) => {
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
            console.log('NEXT Error ', error);
          });
        }
        return () => false;
      }
    );
  }

  function reportsDataNextSet() {
    return createAsyncThunk(
      `${name}/reportsDataNextSet`,
      async ({
        pofetchReportNextResultSet
      }, { dispatch }) => {
        dispatch(pofetchReportNextResultSet());
      }
    );
  }

  function fetchReportPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportPrevResultSet`,
      async ({
        getState
      }, { dispatch }) => {
        const state = getState();
        if (state.conversationThreads.conversationData
          && state.conversationThreads.conversationData.pages.prev) {
          const searchQuery = state.conversationThreads.query;
          let offset = 0;
          const fields = `id,collaborationId,stakeHolders,collaborationTopic,createdDate,modifiedDate,lastConversationDate,
          stakeHoldersCount,vendorCodes,poLineItemsCount,attachmentsCount`;
          const searchField = searchQuery || '&filter=collaborationStatus(OPEN)&sort=lastConversationDate';
          const currentResultSet = state.conversationThreads.conversationData;
          const searchResultPrevOffset = state.conversationThreads.conversationData.pages.prev;
          const res = searchResultPrevOffset.indexOf(',');
          offset = searchResultPrevOffset.slice(7, res);
          const query = `?count=${appConfig.convoSearchCount}&fields=${fields}${searchField}&offset=${offset}`;
          dispatch(togglePaginationSpinner({ isFetchingPrevData: true }));
          request({
            api: 'queryConversationThreads',
            routeParams: { query },
            method: 'get'
          }, dispatch).then((response) => {
            dispatch(actions.updateConversationsPrevResult(response, currentResultSet));
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
          }).catch((error) => {
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
            console.log('PREV Error ', error);
          });
        }
        return () => false;
      }
    );
  }

  function reportsDataPrevSet() {
    return createAsyncThunk(
      `${name}/reportsDataPrevSet`,
      async ({
        pofetchReportPrevResultSet
      }, { dispatch }) => {
        dispatch(pofetchReportPrevResultSet());
      }
    );
  }

  function sendConversationThreadMessage() {
    return createAsyncThunk(
      `${name}/sendConversationThreadMessage`,
      async ({
        data,
        id,
        messageId,
        messageCallback
      }, { dispatch }) => {
        const payload = {
          searchCategory: searchCategory.categoryList,
          conversations: []
        };
        request({
          api: 'updateConversationData',
          routeParams: { id },
          method: 'put',
          data
        }, dispatch)
          .then((response) => {
            if (messageCallback) {
              messageCallback(response.data, null, messageId);
            }
            payload.conversations = [];
            payload.conversations.push(response.data);
            dispatch(actions.updateConversationMsg(payload));
          })
          .catch((error) => {
            if (messageCallback) {
              messageCallback(null, error, messageId);
            }
            console.log(error);
          });
      }
    );
  }

  function queryConversationThreads() {
    return createAsyncThunk(
      `${name}/queryConversationThreads`,
      async ({
        searchQuery,
        callback
      }, { dispatch }) => {
        // complete feilds below for understanding
        /*
        *id,collaborationId,stakeHolders,collaborationStatus,
        *collaborationTopic,createdDate,modifiedDate,
        *lastConversationDate,stakeHoldersCount,poLineItemsCount,
        *attachmentsCount,poData,vendorCodes,
        *attachments,conversations
        */
        const offset = 0;
        const fields = `id,collaborationId,stakeHolders,collaborationTopic,createdDate,modifiedDate,lastConversationDate,
        stakeHoldersCount,vendorCodes,poLineItemsCount,attachmentsCount`;
        const searchField = searchQuery || '&filter=collaborationStatus(OPEN)&sort=lastConversationDate';
        const query = `?count=${appConfig.convoSearchCount}&fields=${fields}${searchField}&offset=${offset}`;
        const payload = {
          searchCategory: searchCategory.categoryList,
          conversations: [],
          pages: {},
          data: {}
        };
        dispatch(toggleSpinner(true));
        request({
          api: 'queryConversationThreads',
          routeParams: { query },
          method: 'get'
          // cancellable: Boolean(searchQuery)
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ conversationSearchSpinner: false }));
          payload.data = response.data;
          dispatch(actions.listConversation(payload));
          if (callback) {
            callback(response.data);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ conversationSearchSpinner: false }));
          console.log(error);
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  function fetchGroupedPONumbers() {
    return createAsyncThunk(
      `${name}/fetchGroupedPONumbers`,
      async ({
        isSelectedAll,
        ids,
        fetchGroupPOCallback
      }, { getState, dispatch }) => {
        const state = getState();
        const searchRequestBody = { ...(state.PoSearch.body || {}) };
        const requestBody = {
          fields: [
            fieldMapping.ID,
            fieldMapping.SIZE_ID,
            fieldMapping.PURCHASE_ORDER_NUMBER,
            fieldMapping.PO_LINE_ITEM_NUMBER,
            fieldMapping.SCHEDULE_LINE_ITEM_NUMBER,
            fieldMapping.SIZE_DESC,
            fieldMapping.GEOGRAPHY_CODE
          ],
          search: [...(searchRequestBody.search || [])],
          filter: [...(searchRequestBody.filter) || []]
        };
        if (ids.length > 0) {
          requestBody.search.push({
            fieldName: 'sizes.sizeId',
            operator: isSelectedAll ? '!=' : '=',
            fieldValue: ids
          });
        }

        dispatch(updateDialogStates({ fetchGroupedPONumbersSpinner: true }));
        dispatch(toggleSpinner(true));
        request({
          api: 'GacInAppUpdate',
          method: 'post',
          data: requestBody
        }, dispatch)
          .then((response) => {
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ fetchGroupedPONumbersSpinner: false }));
            if (fetchGroupPOCallback) {
              fetchGroupPOCallback(getflattenResponse(response.data));
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ fetchGroupedPONumbersSpinner: false }));
            if (fetchGroupPOCallback) {
              fetchGroupPOCallback(null, error);
            }
          });
      }
    );
  }

  function getAttachmentFile() {
    return createAsyncThunk(
      `${name}/getAttachmentFile`,
      async ({
        location
      }, { dispatch }) => {
        request({
          api: 'getAttachmentFile',
          routeParams: { location: location || '' },
          method: 'get'
        }, dispatch)
          .then((response) => {
            window.open(response.data.attachmentSasUrl);
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    );
  }

  function getConversationByID() {
    return createAsyncThunk(
      `${name}/getConversationByID`,
      async ({
        id,
        addToList
      }, { dispatch }) => {
        const payload = {};
        dispatch(toggleSpinner(true));
        request({
          api: 'getConversationById',
          routeParams: { id },
          method: 'get',
          cancellable: true,
          suppressErrorDialog: addToList
        }, dispatch)
          .then((response) => {
            if (addToList) {
              const threadDetails = { ...response.data };
              delete threadDetails.conversations;
              delete threadDetails.attachments;
              dispatch(actions.listConversation({ data: [threadDetails] }));
            }
            payload.conversationsDetails = response.data;
            dispatch(actions.detailedConversation(payload));
            dispatch(actions.updateConversationList(response.data));
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            console.log(error);
          });
      }
    );
  }

  function deleteAttachment() {
    return createAsyncThunk(
      `${name}/deleteAttachment`,
      async ({
        fileLocation,
        callback
      }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'deleteAttachment',
          routeParams: { fileLocation },
          method: 'delete'
        }, dispatch)
          .then((response) => {
            dispatch(actions.removeAttachmentFromConversation(fileLocation));
            if (callback) {
              callback(response);
            }
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function resetConversation() {
    return createAsyncThunk(
      `${name}/resetConversation`,
      async ({
        currentPage
      }, { getState, dispatch }) => {
        const state = getState();
        if (state.conversationThreads && currentPage !== 'CONVERSATION'
          && currentPage !== 'PPMREPORT'
          && state.conversationThreads.comingFrom === 'POSEARCH') {
          dispatch(actions.changeToIntialState());
        }
      }
    );
  }

  return {
    getConversationThreadsDetails: getConversationThreadsDetails(),
    getConversationCodes: getConversationCodes(),
    sendAttachedFile: sendAttachedFile(),
    updateConversationData: updateConversationData(),
    fetchConversationsResultsOffset: fetchConversationsResultsOffset(),
    ConversationsDataOffset: ConversationsDataOffset(),
    fetchReportNextResultSet: fetchReportNextResultSet(),
    reportsDataNextSet: reportsDataNextSet(),
    fetchReportPrevResultSet: fetchReportPrevResultSet(),
    reportsDataPrevSet: reportsDataPrevSet(),
    sendConversationThreadMessage: sendConversationThreadMessage(),
    queryConversationThreads: queryConversationThreads(),
    fetchGroupedPONumbers: fetchGroupedPONumbers(),
    getAttachmentFile: getAttachmentFile(),
    getConversationByID: getConversationByID(),
    deleteAttachment: deleteAttachment(),
    resetConversation: resetConversation()
  };
}

export const extraActions = createExtraActions();

export const conversationThreadsActions = { ...actions, ...extraActions };
export const conversationThreadsReducer = slice.reducer;
