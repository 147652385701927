/**
 * @flow
 */
import { validateEmailId } from '../../helpers/common';

export function validate(value: string) {
  if (this.isRequired && !value) {
    return 'This is a mandatory field.';
  }
  switch (this.primary) {
    case 'emailID': {
      return validateEmailId(value);
    }
    case 'geoEmailList': {
      return validateEmailId(value);
    }
    default: {
      return '';
    }
  }
}
export default {
  emailID: {
    primary: 'emailID', secondary: '', type: 'string', link: false, label: 'Group Email', wrap: true, numeric: 'false', disablePadding: false, group: true, validate, isRequired: true
  },
  geoEmailList: {
    primary: 'geoEmailList', secondary: '', type: 'string', link: false, label: 'GEO Members', wrap: true, numeric: 'true', disablePadding: false, isEditable: true, validate, isRequired: true
  },
  name: {
    primary: 'name', secondary: '', type: 'string', link: false, label: 'Name', wrap: true, numeric: 'true', disablePadding: false, isEditable: false
  },
  lastModifiedBy: {
    primary: 'lastModifiedBy', secondary: '', type: 'string', link: false, label: 'Last Edited By', wrap: true, numeric: 'true', disablePadding: false, isEditable: false
  },
  lastModifiedDate: {
    primary: 'lastModifiedDate', secondary: '', type: 'datetime', link: false, label: 'Last Edited On', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  }
};
