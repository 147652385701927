/**
 * This file is used as Mock data for Conversation Thread.
 * @exports poSearchDataMock
 * @author {Cognizant Technology Solution}
 */

const ppmSearchDataMock = {
  data: {
    pages: {
      next: '',
      prev: '',
      totalPages: 1,
      totalResources: 1
    },
    filters: [],
    objects: [
      {
        'poHeader.poNumber': '3502501629',
        'poHeader.vendorCode': 'A75',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocTypeDescription': 'Standard PO',
        'poHeader.documentDate': '2019-10-10',
        'poLine.itemNumber': '10',
        'poLine.dpomItemStatus': 'Closed',
        'poLine.originalGoodsAtConsolidatorDate': '2019-10-21',
        'vendor.vendorName': 'U-CORPORATION',
        'vendor.productionNikeLiaisonOffice': 'JP00JP',
        'vendor.productionNikeLiaisonOfficeName': 'NLO/JAPAN L4L',
        id: '3502501629-10',
        tsUpdate: 1648707039,
        'sizes.scheduleLineItemNumber': '2',
        'sizes.sizePo.sizeDescription': '2XL',
        'sizes.sizePo.goodsAtConsolidatorDate': '2019-10-31',
        'sizes.sizePo.goodsAtConsolidatorReasonCode': '2',
        'sizes.sizePo.goodsAtConsolidatorReasonDescription': 'N–Deprioritized PO',
        'sizes.sizeManufacturing.initialGoodsAtConsolidatorDate': '2019-10-31',
        'sizes.sizeManufacturing.initialGoodsAtConsolidatorReasonCode': '2',
        'sizes.sizeManufacturing.initialGoodsAtConsolidatorReasonDescription': 'N–Deprioritized PO',
        'sizes.sizeManufacturing.gacVsOgac': '10',
        'sizes.sizeId': '3502501629-10-2',
        'poLine.productCode': 'guddb',
        'sizes.sizeProduct.kr.chestSizeCode': '90',
        'sizes.sizeProduct.kr.waistSizeCode': 'N/A',
        'sizes.sizeProduct.kr.ageBySizeCode': '7-8',
        'sizes.sizeProduct.cn.topSizeCode': 'XS',
        'sizes.sizeProduct.cn.bottomSizeCode': '92'
      }
    ]
  }
};
export default ppmSearchDataMock;
