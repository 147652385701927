export default {
  REPORT_PO_NUMBER: 'poData.poHeader.poNumber',
  REPORT_PO_ITEM_NUMBER: 'poData.poLine.itemNumber',
  REPORT_SCD_LINE_ITEM_NUMBER: 'poData.sizes.scheduleLineItemNumber',
  REPORT_SIZE_DESCRIPTION: 'poData.sizes.sizePo.sizeDescription',
  REPORT_DPOM_ITEM_STATUS: 'poData.poLine.dpomItemStatus',
  REPORT_TR_CO_PO_NUMBER: 'poData.poHeader.trcoPoNumber',
  REPORT_PENDING_GAC_LOCK: 'poData.sizes.pendingGacLock',
  REPORT_GAC_OVERDUE_INDICATOR: 'poData.sizes.gacOverdueIndicator',
  REPORT_REQUEST_INITIATED_DATE: 'requestInitiatedDate',
  REPORT_DATE_APPROVED_OR_REJECTED: 'poData.sizes.dateApprovedOrRejected',
  REPORT_SAP_RESPONSE_DATE: 'poData.sizes.sapResponseDate',
  REPORT_SAP_RESPOSNE: 'poData.sizes.sapResponse',
  REPORT_COLLABORATION_TYPE: 'collaborationType',
  REPORT_APPROVER_ID: 'poData.sizes.approvedOrRejectedBy',
  REPORT_INITIATOR_ID: 'initiatorID',
  REPORT_INITIATOR_ROLE: 'initiatorRole',
  REPORT_CHANGE_REQUEST_NO: 'changeRequestNumber',
  REPORT_CHANGE_STATUS_CODE: 'poData.sizes.changeStatusCode',
  REPORT_GAC_DATE: 'poData.sizes.sizePo.goodsAtConsolidatorDate',
  REPORT_GAC_REASON_CODE: 'poData.sizes.sizePo.goodsAtConsolidatorReasonCode',
  REPORT_GAC_REASON_DESC: 'poData.sizes.sizePo.goodsAtConsolidatorReasonDescription',
  REPORT_PROPOSED_GAC_DATE: 'poData.sizes.proposedGacDate',
  REPORT_PROPOSED_GAC_REASON_CODE: 'poData.sizes.proposedGacReasonCode',
  REPORT_PROPOSED_GAC_DESC: 'poData.sizes.proposedGacReasonDescription',
  REPORT_INITIAL_GAC_DATE: 'poData.sizes.sizeManufacturing.initialGoodsAtConsolidatorDate',
  REPORT_INITIAL_GAC_REASON_CODE: 'poData.sizes.sizeManufacturing.initialGoodsAtConsolidatorReasonCode',
  REPORT_INITIAL_GAC_REASON_DESC: 'poData.sizes.sizeManufacturing.initialGoodsAtConsolidatorReasonDescription',
  REPORT_ORIGINAL_GAC_DATE: 'poData.poLine.originalGoodsAtConsolidatorDate',
  REPORT_MRGAC_DATE: 'poData.planning.mrgacDate',
  REPORT_CREATED_DATE: 'poData.poHeader.poCreatedOn',
  REPORT_DOCUMENT_DATE: 'poData.poHeader.documentDate',
  REPORT_CHANGED_ON_DATE: 'poData.poLine.changedOnDate',
  REPORT_CURRENT_EVENT: 'poData.manufacturing.currentEvent',
  REPORT_CURRENT_EVENT_DATE: 'poData.manufacturing.currentEventDate',
  REPORT_NEXT_EVENT: 'poData.manufacturing.nextEvent',
  REPORT_NEXT_EVENT_DATE: 'poData.manufacturing.nextEventDate',
  REPORT_VENDOR_CODE: 'poData.poHeader.vendorCode',
  REPORT_VENDOR_NAME: 'poData.vendor.vendorName',
  REPORT_PROD_NIKE_LIAISON_OFFICE: 'poData.vendor.productionNikeLiaisonOffice',
  REPORT_PROD_NIKE_LIAISON_OFFICE_DESC: 'poData.vendor.productionNikeLiaisonOfficeName',
  REPORT_PO_COMPANY_CODE: 'poData.poHeader.poCompanyCode',
  REPORT_PO_CO_DESC: 'poData.poHeader.poCompanyCodeDescription',
  REPORT_PO_DOC_TYPE: 'poData.poHeader.poDocTypeCode',
  REPORT_PO_DOC_TYPE_DESC: 'poData.poHeader.poDocTypeDescription',
  REPORT_PURCHASE_ORG_CODE: 'poData.poHeader.purchaseOrgCode',
  REPORT_PURCHASE_ORG_NAME: 'poData.poHeader.purchaseOrgName',
  REPORT_PURCHASE_GROUP_NUMBER: 'poData.poHeader.purchaseGroupNumber',
  REPORT_PURCHASE_GROUP_NAME: 'poData.poHeader.purchaseGroupName',
  REPORT_SIZE_PO_QUANTITY: 'poData.sizes.sizePo.sizeQuantity',
  REPORT_UNIT_OF_MEASURE: 'poData.poLine.unitOfMeasureCode',
  REPORT_PLANT_CODE: 'poData.poLine.plantCode',
  REPORT_PLANT_NAME: 'poData.poLine.plantName',
  REPORT_TR_CO_PLANT_CODE: 'poData.poLine.trcoPlantCode',
  REPORT_TR_CO_PLANT_NAME: 'poData.poLine.trcoPlantName',
  REPORT_SIZE_TRANSPORTATION_CODE: 'poData.sizes.sizePo.transportationModeCode',
  REPORT_SHIPPING_PARTNER_ID: 'poData.poLine.shippingPartnerID',
  REPORT_SHIPPING_PARTNER_NAME: 'poData.poLine.shippingPartnerName',
  REPORT_SHIPPING_TYPE: 'poData.poLine.shippingType',
  REPORT_MFG_COUNTRY_ORIGIN: 'poData.poHeader.manufacturingCountryOfOrigin',
  REPORT_MFG_COUNTRY_ORIGIN_NAME: 'poData.poHeader.manufacturingCountryOfOriginName',
  REPORT_ID: 'id',
  REPORT_ISLINE: 'poData.isLine',
  REPORT_PRODUCT_CODE: 'poData.poLine.productCode',
  REPORT_DESTINATION_COUNTRY_CODE: 'poData.poLine.destinationCountryCode',
  REPORT_DESTINATION_COUNTRY_NAME: 'poData.poLine.destinationCountryName',
  REPORT_SIZE_ID: 'poData.sizes.sizeId',
  REPORT_GAC_USECASE_CODE: 'poData.sizes.proposedGRCUseCaseCode',
  REPORT_GAC_USECASE_CODE_DESC: 'poData.sizes.proposedGRCUseCaseCodeDescription'
};
