import fieldMapping from '../fieldMappings/poSearchFieldMapping';
import { checkMapFields } from '../../helpers/common';

const lblCurrentGACReasonCode = 'Current GAC Reason Code';
const lblCurrentGACDate = 'Current GAC Date';
const lblDocumentType = 'Document Type';
const lblPurchaseOrderNumber = 'Purchase Order Number';
const lblSizeDescription = 'Size Description';
const lblProductCode = 'Product Code';
const MRGACDateValue = 'MRGAC Date';
const lblProposedGACdate = 'Proposed GAC Date';
const lblProposedGACReasonCode = 'Proposed GAC Reason Code';

export function renderLineNsizeLevelValue(row) {
  if ((row.isLine === 'YES')) {
    return checkMapFields(
      row,
      {
        primary: this.additionalFields[0],
        secondary: this.additionalFields[1]
      },
      '-'
    );
  }
  return checkMapFields(
    row,
    {
      primary: this.primary,
      secondary: this.secondary
    },
    '-'
  );
}

export default {
  [fieldMapping.PURCHASE_ORDER_NUMBER]: {
    primary: fieldMapping.PURCHASE_ORDER_NUMBER, secondary: '', type: 'numeric', link: false, label: lblPurchaseOrderNumber, wrap: true, numeric: 'true', disablePadding: false
  },
  [fieldMapping.PO_LINE_ITEM_NUMBER]: {
    primary: fieldMapping.PO_LINE_ITEM_NUMBER, secondary: '', type: 'string', link: false, label: 'Line Item Number', wrap: true, numeric: 'false', disablePadding: false
  },
  [fieldMapping.SCHEDULE_LINE_ITEM_NUMBER]: {
    primary: fieldMapping.SCHEDULE_LINE_ITEM_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.SIZE_DESC]: {
    primary: fieldMapping.SIZE_DESC, secondary: '', type: 'string', link: false, label: lblSizeDescription, wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fieldMapping.PO_GAC]: {
    primary: fieldMapping.PO_GAC, secondary: '', type: 'date', link: false, label: lblCurrentGACDate, wrap: true, numeric: 'false', disablePadding: false
  },
  [fieldMapping.GAC_REASON_CODE]: {
    primary: fieldMapping.GAC_REASON_CODE,
    secondary: fieldMapping.GAC_REASON_DESC,
    type: 'string',
    link: false,
    label: lblCurrentGACReasonCode,
    wrap: true,
    numeric: false,
    disablePadding: false,
    group: true,
    additionalFields: ['poLine.goodsAtConsolidatorReasonCode', 'poLine.goodsAtConsolidatorReasonDescription'],
    renderValue: renderLineNsizeLevelValue
  },
  [fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE]: {
    primary: fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE,
    secondary: fieldMapping.PURCHASING_DOCUMENT_TYPE_DESC,
    type: 'string',
    link: false,
    label: lblDocumentType,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.PRODUCT_CODE]: {
    primary: fieldMapping.PRODUCT_CODE, secondary: '', type: 'string', link: false, label: lblProductCode, wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fieldMapping.MRGAC]: {
    primary: fieldMapping.MRGAC, secondary: '', type: 'date', link: false, label: MRGACDateValue, wrap: true, numeric: 'false', disablePadding: false
  },
  proposedGacDate: {
    primary: 'proposedGacDate', secondary: '', type: 'date', link: false, label: lblProposedGACdate, wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  proposedGacReasonCode: {
    primary: 'proposedGacReasonCode',
    secondary: '',
    type: 'fstring',
    link: false,
    label: lblProposedGACReasonCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  proposedGRCUseCaseCode: {
    primary: 'proposedGRCUseCaseCode',
    secondary: '',
    type: 'string',
    link: false,
    label: 'Proposed GAC Use Case Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  }
};
