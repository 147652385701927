/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner, updateDialogStates } from '../dialogStates/dialogStatesSlice';
import fieldMapping from '../../constants/fieldMappings/poSearchFieldMapping';
import { initialSearchFieldsState } from '../../constants/staticData';
import { poItemStatusSelect } from '../mock/searchPanelMockData';
import { checkUserPermission } from '../../helpers/userPermission';

const name = 'searchpaneldata';

function createInitialState() {
  return {
    [fieldMapping.PO_LINE_ITEM_STATUS]: [],
    [fieldMapping.VENDOR_CODE]: [],
    [fieldMapping.PMO_DEC_CODE]: [],
    'poHeader.searchcriteria': { ...initialSearchFieldsState },
    selectedFilterOptions: [],
    selectedColumnOrderOptions: [],
    rowPerPageOption: ['100']
  };
}

function createReducers() {
  function setLineStatusDropdownValues(state: Object, action: Object) {
    return {
      ...state,
      [fieldMapping.PO_LINE_ITEM_STATUS]: action.payload
    };
  }

  function setFactoryCodeDropdown(state: Object, action: Object) {
    return {
      ...state,
      [fieldMapping.VENDOR_CODE]: action.payload
    };
  }

  function setPMODECCodeDropdownValues(state: Object, action: Object) {
    return {
      ...state,
      [fieldMapping.PMO_DEC_CODE]: action.payload
    };
  }

  // function SET_INITIAL_SEARCH_FIELD_VALUES(state, action) {
  //   return {
  //     ...state,
  //     'poHeader.searchcriteria': action.payload
  //   };
  // }

  function setFilterListValues(state: Object, action: Object) {
    return {
      ...state,
      selectedFilterOptions: action.payload
    };
  }

  function setColumnOrderListValues(state: Object, action: Object) {
    return {
      ...state,
      selectedColumnOrderOptions: action.payload
    };
  }

  function setRowPerPagePreference(state: Object, action: Object) {
    return {
      ...state,
      rowPerPageOption: action.payload
    };
  }

  return {
    setLineStatusDropdownValues,
    setFactoryCodeDropdown,
    setPMODECCodeDropdownValues,
    setFilterListValues,
    setColumnOrderListValues,
    setRowPerPagePreference
  };
}

export const initialState = createInitialState();
export const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function checkForSearchPanelData(state: Object) {
  let panelDataEmpty = true;
  if (state.searchpaneldata
    && (
      state.searchpaneldata[fieldMapping.VENDOR_CODE].length > 0
      || state.searchpaneldata[fieldMapping.PMO_DEC_CODE].length > 0
      || state.searchpaneldata[fieldMapping.PO_LINE_ITEM_STATUS].length > 0
    )
  ) {
    panelDataEmpty = false;
  }
  return panelDataEmpty;
}

function createExtraActions() {
  function getsearchPanelData() {
    return createAsyncThunk(
      `${name}/getsearchPanelData`,
      async (
        { callback },
        { getState, dispatch }
      ) => {
        const state = getState();
        if (checkForSearchPanelData(state)) {
          dispatch(toggleSpinner(true));
          dispatch(updateDialogStates({ lookupApiSpinner: true }));

          request({
            api: 'dropdownLookup',
            method: 'get'
          }, dispatch, getState).then((response: Object) => {
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ lookupApiSpinner: false }));
            const { partnerNumber, productionNikeLiaisonOffice } = response.data;
            const factoryCodeList = partnerNumber.map
              ? partnerNumber.map((option) => ({
                id: option.partnerNo,
                value: option.partnerNo
              }))
              : [];
            dispatch(actions.setFactoryCodeDropdown(factoryCodeList));
            dispatch(actions.setPMODECCodeDropdownValues(productionNikeLiaisonOffice
              .map((option) => ({ id: option, value: option }))));
            if (callback) {
              callback(response.data);
            }
          }).catch((error: Object) => {
            console.log('Error: ', error);
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ lookupApiSpinner: false }));
            if (callback) {
              callback(null, error);
            }
          });
        }

        const currentUserGroup = state.auth && state.auth.userDetails
          && state.auth.userDetails.group;
        let modifiedLineStatusDropdown = [...poItemStatusSelect];
        if (!checkUserPermission('DPOMLineItemStatus', currentUserGroup)) {
          modifiedLineStatusDropdown = modifiedLineStatusDropdown
            .map((option) => {
              if (option.text === 'All') {
                return {
                  value: option.value.split('Unissued,').join(''),
                  text: option.text
                };
              }
              return option;
            })
            .filter((option) => option.value !== 'Unissued');
        }
        dispatch(actions.setLineStatusDropdownValues(modifiedLineStatusDropdown));
        return true;
      }
    );
  }

  function getFilterAndColumnOrderList() {
    return createAsyncThunk(
      `${name}/getFilterAndColumnOrderList`,
      async (
        { columnType, filterType, callback },
        { getState, dispatch }
      ) => {
        dispatch(updateDialogStates({ executeBookmarkSpinner: true }));
        let fields;
        if (!filterType && !columnType) {
          fields = 'rowsPerPagePreference';
        } else if (!filterType) {
          fields = `${columnType},rowsPerPagePreference`;
        } else if (!columnType) {
          fields = `${filterType},rowsPerPagePreference`;
        } else {
          fields = `${columnType},${filterType || ''},rowsPerPagePreference`;
        }
        request({
          api: 'getUserProfileData',
          method: 'get',
          routeParams: { fields }
        }, dispatch, getState).then((response: Object) => {
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          const columnOrderOptions = (response.data.objects
            && response.data.objects[0][columnType]
          ) || [];
          const filteredColumnOrderOptions = columnOrderOptions && columnOrderOptions.filter(
            (item) => !item.toLowerCase().includes('ratetypename')
          );
          const filterOptions = (response.data.objects
            && response.data.objects[0][filterType]
          ) || [];
          const { rowsPerPagePreference } = response.data.objects[0];
          dispatch(actions.setRowPerPagePreference(rowsPerPagePreference ? rowsPerPagePreference[0] : '100'));
          dispatch(actions.setFilterListValues(filterOptions));
          dispatch(actions.setColumnOrderListValues(filteredColumnOrderOptions));
        }).catch((error: Object) => {
          console.log('Error: ', error);
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  return {
    getsearchPanelData: getsearchPanelData(),
    getFilterAndColumnOrderList: getFilterAndColumnOrderList()
  };
}

export const extraActions = createExtraActions();

export const searchPanelDataActions = { ...actions, ...extraActions };
export const searchPanelDataReducer = slice.reducer;
