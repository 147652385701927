/**
 * Slice for sessionContexts
 * @exports sessionContexts
 * @author {Cognizant Technology Solution}
 */

import { createContext } from 'react';

const sessionContexts = createContext('');
export default sessionContexts;
