/**
 * Nike application
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import toolkitStore from './store';
import './index.scss';
import Auth from './components/TWithAuthorization';
import { reactPlugin } from './helpers/AppInsights';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Provider store={toolkitStore}>
      <DndProvider backend={HTML5Backend}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <Auth />
        </AppInsightsContext.Provider>
      </DndProvider>
    </Provider>
  </React.StrictMode>
);
