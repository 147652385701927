export const userGroup = {
  INFOTECH: 'App.GSM.DPOM.InfoTech',
  INFOTECH_SECURITY: 'App.GSM.DPOM.InfoTech.Security',
  GEONA: 'App.GSM.DPOM.GeoNA',
  GEOGC: 'App.GSM.DPOM.GeoGC',
  GEOEMEA: 'App.GSM.DPOM.GeoEMEA',
  GEOAPLA: 'App.GSM.DPOM.GeoAPLA',
  WHQ: 'App.GSM.DPOM.WHQ',
  GEONA_SECURITY: 'App.GSM.DPOM.GeoNA.Security',
  GEOGC_SECURITY: 'App.GSM.DPOM.GeoGC.Security',
  GEOEMEA_SECURITY: 'App.GSM.DPOM.GeoEMEA.Security',
  GEOAPLA_SECURITY: 'App.GSM.DPOM.GeoAPLA.Security',
  BPM_SECURITY: 'App.GSM.DPOM.BPM.Security',
  PMO: 'App.GSM.DPOM.PMO',
  PMO_SECURITY: 'App.GSM.DPOM.PMO.Security',
  LSP_READ: 'EXT.App.GSM.DPOM.LSP.Read',
  FACTORY: 'EXT.App.GSM.DPOM.Factory',
  FACTORY_READ: 'EXT.App.GSM.DPOM.Factory.Read'
};

export const ALLOWED_USER_GROUP = [
  userGroup.FACTORY_READ,
  userGroup.FACTORY,
  userGroup.LSP_READ,
  userGroup.PMO_SECURITY,
  userGroup.PMO,
  userGroup.BPM_SECURITY,
  userGroup.GEOAPLA_SECURITY,
  userGroup.GEOEMEA_SECURITY,
  userGroup.GEOGC_SECURITY,
  userGroup.GEONA_SECURITY,
  userGroup.WHQ,
  userGroup.GEOAPLA,
  userGroup.GEOEMEA,
  userGroup.GEOGC,
  userGroup.GEONA,
  userGroup.INFOTECH_SECURITY,
  userGroup.INFOTECH
];

export default userGroup;
