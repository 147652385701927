import poChangeReportFieldMapping from './fieldMappings/poChangeReportFieldMapping';
import poChangeReportFieldProperties from './fieldProperties/poChangeReportFieldProperties';
import { showForNONPROD } from '../helpers/envServer';

const poChangeReportHeader = [
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PO_NUMBER],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_ITEM_NUMBER],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_VENDOR_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_SCHEDULE_LINE_ITEM_NUMBER],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_SIZE_DESC],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_CHANGE_DATE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_DOC_TYPE_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_DOCUMENT_DATE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_CHANGED_TYPE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_CHANGED_FROM],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_CHANGED_TO],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PRODUCT_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_OGAC_DATE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_GAC_DATE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_GAC_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_CURRENCY_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PO_REASON_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PO_REASON_CODE_DESCRIPTION],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_CANCEL_REASON],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_CANCEL_REASON_DESC],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_TTMI],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_SIZE_QUANTITY],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PLANT_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_ITEM_TEXT],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_SHIP_TO_NUMBER],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_CUSTOMER_PO],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_SALES_ORDER_NUMBER],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_SALES_ORDER_ITEM_NUMBER],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_SALES_SCHEDULE_ITEM_NUMBER],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PURCHASE_ORG],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PURCHASE_GROUP_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_ACTUAL_DATE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_MRGAC],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PLANNING_TARGET_IPD],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PLANNING_PRIORITY_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PLANNING_PRIORITY_DESC],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_SEASON_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_SEASON_YEAR],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_MCO],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_OUTSOLE_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_FOB_COST],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_VAS_INDICATOR],
  (showForNONPROD) && poChangeReportFieldProperties[
    poChangeReportFieldMapping.PO_CHANGE_ITEM_VARIANT_NUMBER
  ],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_PO_SHIPPING_INSTRUCTION_CODE],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PURCHASING_ACCOUNT_ASSIGNMENT_CATEGORY],
  poChangeReportFieldProperties[poChangeReportFieldMapping.PO_CHANGE_QUALITY_CODE]
].filter((el) => el !== false);
export default poChangeReportHeader;
