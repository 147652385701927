/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../appConfig';
import menuListOptions from '../../constants/menuObject';

const name = 'Hamburger';

function createInitialState() {
  return {
    openHamburger: false,
    closeHamburger: false,
    menuListOptions: []
  };
}

export const initialState = createInitialState();

function createReducers() {
  // OPEN_HAMBURGER
  function openHamburger(state: Object, action: Object) {
    return {
      ...state,
      openHamburger: action.payload
    };
  }
  function closeHamburger(state: Object, action: Object) {
    return {
      ...state,
      closeHamburger: action.payload
    };
  }
  function fetchMenuListOptions(state: Object, action: Object) {
    return {
      ...state,
      menuListOptions: action.payload
    };
  }

  return {
    openHamburger,
    closeHamburger,
    fetchMenuListOptions
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function getMenuList() {
    return createAsyncThunk(
      `${name}/getMenuList`,
      async (_, { dispatch, getState }) => {
        const state = getState();
        const group = state.auth.userDetails && state.auth.userDetails.group;
        const getMenuListForUserGroup = () => {
          if (group === appConfig.userGroups.geo) {
            return menuListOptions.filter((menu) => menu.name !== 'Upload');
          }
          return menuListOptions;
        };
        dispatch(actions.fetchMenuListOptions(getMenuListForUserGroup()));
      }
    );
  }

  return {
    getMenuList: getMenuList()
  };
}

export const extraActions = createExtraActions();

export const hamburgerActions = { ...actions, ...extraActions };
export const hamburgerReducer = slice.reducer;
