import gacChangeHistoryFieldMapping from './fieldMappings/gacChangeHistoryReportFieldMapping';
import gacChangeHistoryFieldProperties from './fieldProperties/gacChangeHistoryReportFieldProperties';

const gacChangeHistoryReportHeader = [
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PO_NUMBER],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ITEM_NUMBER],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_TR_CO_PONUMBER],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ITEM_QTY],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_OGAC],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CURRENT_GAC_DATE],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_GAC_REASON_CODE],
  gacChangeHistoryFieldProperties[
    gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_GRC_USECASE_CODE
  ],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_INITIAL_GAC_DATE],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_VENDOR],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_DIVISION_CODE],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PRODUCT_CODE],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_SEASON_CODE],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_SEASON_YEAR],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_DOC_TYPE_CODE],
  gacChangeHistoryFieldProperties[
    gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PURCHASE_ORG_CODE
  ],
  gacChangeHistoryFieldProperties[
    gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PURCHASE_GROUP_CODE
  ],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CURRENT_EVENT],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_NEXT_EVENT],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_GEOGRAPHY_CODE],
  gacChangeHistoryFieldProperties[
    gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ORDER_REASON_CODE
  ],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PMO_DEC_CODE],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_LAUNCH],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_MIDSOLE_CODE],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_OUTSOLE_CODE],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CATEGORY_CODE],
  gacChangeHistoryFieldProperties[
    gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_UNIT_OF_MEASURE_CODE
  ],
  gacChangeHistoryFieldProperties[
    gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_SUB_CATEGORY_CODE
  ],
  gacChangeHistoryFieldProperties[gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_TRANSPORT_MODE]
].filter((el) => el !== false);
export default gacChangeHistoryReportHeader;
