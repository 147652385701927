import { showForDev } from '../helpers/envServer';

export const ACTIVE_FEATURES = showForDev ? {
  DASHBOARD_ANNOUNCEMENT: 'DASHBOARD_ANNOUNCEMENT',
  DASHBOARD_CAROUSEL: 'DASHBOARD_CAROUSEL',
  EXTERNAL_LINK_TOOL: 'LINK_TOOL',
  CONVERSATION_CHAT_BOX: 'CONVERSATION_CHAT_BOX',
  AF_ADDITIONAL_FIELD: 'AF_ADDITIONAL_FIELD',
  AF_EXPORT: 'AF_EXPORT',
  AIR_FREIGHT_REPORT: 'AIR_FREIGHT_REPORT'
} : {
  AF_ADDITIONAL_FIELD: 'airFreight_exclude_status_lastUpdatedTS',
  AF_EXPORT: 'AF_EXPORT'
};

export default ACTIVE_FEATURES;
