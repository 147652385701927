export default {
  GAC_CHANGE_HISTORY_PO_NUMBER: 'poHeader.poNumber',
  GAC_CHANGE_HISTORY_ITEM_NUMBER: 'poLine.itemNumber',
  GAC_CHANGE_HISTORY_OGAC: 'poLine.originalGoodsAtConsolidatorDate',
  GAC_CHANGE_HISTORY_CURRENT_GAC_DATE: 'poLine.goodsAtConsolidatorDate',
  GAC_CHANGE_HISTORY_QUANTITY: 'quantity',
  GAC_CHANGE_HISTORY_NAF: 'dpomAirFreight.naf',
  GAC_CHANGE_HISTORY_FAF: 'dpomAirFreight.faf',
  GAC_CHANGE_HISTORY_GAC_CHANGE_TIMESTAMP: 'gacChangeTimeStamp',
  GAC_CHANGE_HISTORY_DELAY: 'delay',
  GAC_CHANGE_HISTORY_GRC: 'grc',
  GAC_CHANGE_HISTORY_LIABILITY: 'liability',
  GAC_CHANGE_HISTORY_FACTORY: 'factory',
  GAC_CHANGE_HISTORY_TOTAL_DELAY: 'totalDelay',
  GAC_CHANGE_HISTORY_AF: 'af',
  REPORT_ID: 'id',
  GAC_CHANGE_HISTORY_TR_CO_PONUMBER: 'poHeader.trcoPoNumber',
  GAC_CHANGE_HISTORY_VENDOR: 'poHeader.vendorCode',
  GAC_CHANGE_HISTORY_VENDOR_NAME: 'vendor.vendorName',
  GAC_CHANGE_HISTORY_DIVISION_CODE: 'poLine.divisionCode',
  GAC_CHANGE_HISTORY_PRODUCT_CODE: 'poLine.productCode',
  GAC_CHANGE_HISTORY_PRODUCT_NAME: 'poLine.productName',
  GAC_CHANGE_HISTORY_ITEM_QTY: 'poLine.itemQuantity',
  GAC_CHANGE_HISTORY_GAC_REASON_CODE: 'poLine.goodsAtConsolidatorReasonCode',
  GAC_CHANGE_HISTORY_GAC_REASON_CODE_DESC: 'poLine.goodsAtConsolidatorReasonDescription',
  GAC_CHANGE_HISTORY_GRC_USECASE_CODE: 'poLine.grcUseCaseCode',
  GAC_CHANGE_HISTORY_GRC_USECASE_CODE_DESC: 'poLine.grcUseCaseDescription',
  GAC_CHANGE_HISTORY_SEASON_CODE: 'poLine.seasonCode',
  GAC_CHANGE_HISTORY_SEASON_YEAR: 'poLine.seasonYear',
  GAC_CHANGE_HISTORY_LAUNCH: 'product.launchCode',
  GAC_CHANGE_HISTORY_MIDSOLE_CODE: 'product.midsoleCode',
  GAC_CHANGE_HISTORY_OUTSOLE_CODE: 'product.outsoleCode',
  GAC_CHANGE_HISTORY_PROMO_ONLY_INDICATOR: 'product.promotionalIndicator',
  GAC_CHANGE_HISTORY_ORDER_REASON_CODE: 'poLine.reasonForOrderingCode',
  GAC_CHANGE_HISTORY_ORDER_REASON_DESCRIPTION: 'poLine.reasonforOrderingDescription',
  GAC_CHANGE_HISTORY_STYLE_NUMBER: 'product.styleNumber',
  GAC_CHANGE_HISTORY_CURRENT_EVENT: 'manufacturing.currentEvent',
  GAC_CHANGE_HISTORY_NEXT_EVENT: 'manufacturing.nextEvent',
  GAC_CHANGE_HISTORY_CUSTOMER_PO: 'salesOrder.customerPo',
  GAC_CHANGE_HISTORY_DOC_TYPE_CODE: 'poHeader.poDocTypeCode',
  GAC_CHANGE_HISTORY_DOC_TYPE_CODE_DESC: 'poHeader.poDocTypeDescription',
  GAC_CHANGE_HISTORY_PURCHASE_ORG_CODE: 'poHeader.purchaseOrgCode',
  GAC_CHANGE_HISTORY_PURCHASE_ORG_NAME: 'poHeader.purchaseOrgName',
  GAC_CHANGE_HISTORY_PURCHASE_GROUP_CODE: 'poHeader.purchaseGroupCode',
  GAC_CHANGE_HISTORY_PURCHASE_GROUP_NAME: 'poHeader.purchaseGroupName',
  GAC_CHANGE_HISTORY_COLOR_DESC: 'product.colorDescription',
  GAC_CHANGE_HISTORY_CREATED_DATE: 'poHeader.poCreatedOn',
  GAC_CHANGE_HISTORY_DOC_DATE: 'poHeader.documentDate',
  GAC_CHANGE_HISTORY_CHANGED_DATE: 'poLine.changedOnDate',
  GAC_CHANGE_HISTORY_GAC: 'poLine.goodsAtConsolidatorDate',
  GAC_CHANGE_HISTORY_GEOGRAPHY_CODE: 'poLine.geographyCode',
  GAC_CHANGE_HISTORY_LAUNCH_DATE: 'product.launchDate',
  GAC_CHANGE_HISTORY_CURRENT_EVENT_DATE: 'manufacturing.currentEventDate',
  GAC_CHANGE_HISTORY_NEXT_EVENT_DATE: 'manufacturing.nextEventDate',
  GAC_CHANGE_HISTORY_ACCEPT_PO_ACTUAL_DATE: 'manufacturing.acceptPOActualDate',
  GAC_CHANGE_HISTORY_FACTORY_DELIVERY_ACTUAL_DATE: 'manufacturing.factoryDeliveryActualDate',
  GAC_CHANGE_HISTORY_EX_FACTORY_ACTUAL_DATE: 'manufacturing.exFactoryActualDate',
  GAC_CHANGE_HISTORY_MRGAC: 'planning.mrgacDate',
  GAC_CHANGE_HISTORY_PLANT_CODE: 'poLine.plantCode',
  GAC_CHANGE_HISTORY_PLANT_NAME: 'poLine.plantName',
  GAC_CHANGE_HISTORY_FOB_MISMATCH_INDICATOR: 'product.fobMismatchIndicator',
  GAC_CHANGE_HISTORY_SIZE_MISMATCH_ID: 'product.sizeMismatchIndicator',
  GAC_CHANGE_HISTORY_CREATED_BY: 'poHeader.createdBy',
  GAC_CHANGE_HISTORY_TITLE_TRANSFER_MODEL_CODE: 'poHeader.titleTransferModelCode',
  GAC_CHANGE_HISTORY_TITLE_TRANSFER_MODEL_DESC: 'poHeader.titleTransferModelDescription',
  GAC_CHANGE_HISTORY_PO_COMPANY_CODE: 'poHeader.poCompanyCode',
  GAC_CHANGE_HISTORY_PO_COMPANY_DESC: 'poHeader.poCompanyCodeDescription',
  GAC_CHANGE_HISTORY_TR_CO_COMPANY_CODE: 'poHeader.trcoCompanyCode',
  GAC_CHANGE_HISTORY_TR_CO_COMPANY_DESC: 'poHeader.trcoCompanyCodeDescription',
  GAC_CHANGE_HISTORY_ITEM_STATUS: 'poLine.dpomItemStatus',
  GAC_CHANGE_HISTORY_UNIT_OF_MEASURE_CODE: 'poLine.unitOfMeasureCode',
  GAC_CHANGE_HISTORY_DESTINATION_COUNTRY_CODE: 'poLine.destinationCountryCode',
  GAC_CHANGE_HISTORY_DESTINATION_COUNTRY_NAME: 'poLine.destinationCountryName',
  GAC_CHANGE_HISTORY_TR_CO_PLANT_CODE: 'poLine.trcoPlantCode',
  GAC_CHANGE_HISTORY_TR_CO_PLANT_NAME: 'poLine.trcoPlantName',
  GAC_CHANGE_HISTORY_SHIPPING_TYPE: 'poLine.shippingType',
  GAC_CHANGE_HISTORY_DIRECT_SALES_ORDER_NUMBER: 'poLine.directshipSalesOrderNumber',
  GAC_CHANGE_HISTORY_DIRECT_SALES_ORDER_ITEM_NUMBER: 'poLine.directShipSalesOrderItemNumber',
  GAC_CHANGE_HISTORY_CANCEL_DATE: 'poLine.cancelDate',
  GAC_CHANGE_HISTORY_ITEM_TEXT_DETAIL: 'poLine.itemTextDetail',
  GAC_CHANGE_HISTORY_CATEGORY_CODE: 'product.categoryCode',
  GAC_CHANGE_HISTORY_CATEGORY_DESC: 'product.categoryDescription',
  GAC_CHANGE_HISTORY_SUB_CATEGORY_CODE: 'product.subCategoryCode',
  GAC_CHANGE_HISTORY_SUB_CATEGORY_DESC: 'product.subCategoryDescription',
  GAC_CHANGE_HISTORY_GLOBAL_CATEGORY_FOCUS_CODE: 'product.globalCategoryCoreFocusCode',
  GAC_CHANGE_HISTORY_GLOBAL_CATEGORY_FOCUS_DESC: 'product.globalCategoryCoreFocusDescription',
  GAC_CHANGE_HISTORY_GLOABL_CATEGORY_SUMMARY_CODE: 'product.globalCategorySummaryCode',
  GAC_CHANGE_HISTORY_GLOABL_CATEGORY_SUMMARY_DESC: 'product.globalCategorySummaryDescription',
  GAC_CHANGE_HISTORY_GENDER_AGE_CODE: 'product.genderAgeCode',
  GAC_CHANGE_HISTORY_GENDER_AGE_DESC: 'product.genderAgeDescription',
  GAC_CHANGE_HISTORY_MARKETING_ID: 'product.primaryMarketingTypeIdentifier',
  GAC_CHANGE_HISTORY_MARKETING_DESC: 'product.primaryMarketingTypeDescription',
  GAC_CHANGE_HISTORY_SILHOUTTE_TYPE: 'product.silhouetteTypeCode',
  GAC_CHANGE_HISTORY_SILHOUTTE_TYPE_DESC: 'product.silhouetteTypeDescription',
  GAC_CHANGE_HISTORY_DIMENSION_CODE: 'product.dimensionCode',
  GAC_CHANGE_HISTORY_PRODUCT_REFILL_CLASS_CODE: 'product.productRefillClassCode',
  GAC_CHANGE_HISTORY_PRODUCT_REFILL_CLASS_DESC: 'product.productRefillClassDescription',
  GAC_CHANGE_HISTORY_LEAGUE_ID_DESC: 'product.leagueIdDescription',
  GAC_CHANGE_HISTORY_PO_REJECTION_CODE: 'manufacturing.poRejectionCode',
  GAC_CHANGE_HISTORY_PO_REJECTION_CODE_DESC: 'manufacturing.poRejectionCodeDescription',
  GAC_CHANGE_HISTORY_OVERDUE_INDICATOR: 'manufacturing.overdueIndicator',
  GAC_CHANGE_HISTORY_CUSTOMER_SHIP_TO: 'poLine.customerShipToNumber',
  GAC_CHANGE_HISTORY_CUSTOMER_SHIP_TO_NAME: 'poLine.customerShipToName',
  GAC_CHANGE_HISTORY_GLOBAL_PRODUCT_GROUP: 'planning.globalPlanningProductGroupCode',
  GAC_CHANGE_HISTORY_GLOBAL_PRODUCT_GROUP_DESC: 'planning.globalPlanningProductGroupDescription',
  GAC_CHANGE_HISTORY_GLOBAL_PRODUCT_CLASS: 'planning.globalPlanningProductClassificationCode',
  GAC_CHANGE_HISTORY_GLOBAL_PRODUCT_CLASS_DESC: 'planning.globalPlanningProductClassificationDescription',
  GAC_CHANGE_HISTORY_PLANNING_PRIORITY_NUMBER: 'planning.planningPriorityCode',
  GAC_CHANGE_HISTORY_PLANNING_PRIORITY_NUMBER_DESC: 'planning.planningPriorityDescription',
  GAC_CHANGE_HISTORY_GAC_VS_MRGAC: 'planning.gacVsMrgac',
  GAC_CHANGE_HISTORY_OGAC_VS_MRGAC: 'planning.ogacVsMrgac',
  GAC_CHANGE_HISTORY_MRGAC_CAPACITY_CONSUMPTION_WEEK: 'planning.mrgacCapacityConsumptionWeek',
  GAC_CHANGE_HISTORY_PMO_DEC_CODE: 'vendor.productionNikeLiaisonOffice',
  GAC_CHANGE_HISTORY_PMO_DEC_CODE_DESC: 'vendor.productionNikeLiaisonOfficeName',
  GAC_CHANGE_HISTORY_FX_ADJ_REQUIRED_INDICATOR: 'poHeader.fxAdjustmentRequiredIndicator',
  GAC_CHANGE_HISTORY_CUSTOMER_REQ_DATE: 'salesOrder.customerRequestedDate',
  GAC_CHANGE_HISTORY_VAS_ITEM: 'poLine.itemVasDetail',
  GAC_CHANGE_HISTORY_R3_DOC_TYPE: 'poHeader.r3DocType',
  GAC_CHANGE_HISTORY_MFG_ORIGIN_COUNTRY_CODE: 'poHeader.manufacturingCountryOfOrigin',
  GAC_CHANGE_HISTORY_MFG_ORIGIN_COUNTRY_NAME: 'poHeader.manufacturingCountryOfOriginName',
  GAC_CHANGE_HISTORY_TRANSPORT_MODE: 'poLine.transportationModeCode',
  GAC_CHANGE_HISTORY_TRANSPORT_MODE_DESC: 'poLine.transportationMode',
  GAC_CHANGE_HISTORY_DELIVERY_COMPLETE_INDICATOR: 'poLine.deliveryCompleteIndicator',
  GAC_CHANGE_HISTORY_CUST_ISO_COUNTRY_CODE: 'poLine.customerIsoCountryCode',
  GAC_CHANGE_HISTORY_SALES_ORDER_NUMBER: 'poLine.salesOrderNumber',
  GAC_CHANGE_HISTORY_SALES_ORDER_ITEM_NUMBER: 'poLine.salesOrderItemNumber',
  GAC_CHANGE_HISTORY_CUSTOMER_COUNTRY_NAME: 'poLine.customerCountryName',
  GAC_CHANGE_HISTORY_INITIAL_GAC_DATE: 'poLine.initialGoodsAtConsolidatorDate'
};
