const settings = [
  {
    id: 'cadb0ac0-4a63-4f26-9c04-29c28d7b6a65',
    factoryCode: 'XC',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    isActive: true
  },
  {
    id: '8cca64fa-11e8-45b6-a980-9ed7254ee4eb',
    factoryCode: 'XE',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.it01@nike.com',
    modifiedDate: '2023-02-07T05:52:32.432Z',
    isActive: true
  },
  {
    id: 'f2eb09bc-7a48-4148-b05b-6f06943b6e34',
    factoryCode: 'XE',
    factoryName: 'JIA INTERNATIONAL CO., LTD.',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stephanie.Wang@nike.com',
    isActive: true
  },
  {
    id: '30b2b7e3-fd28-4995-b16b-ce1cd5429d5a',
    factoryCode: 'XG',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stephanie.Wang@nike.com',
    isActive: true
  },
  {
    id: '1c1081e8-076d-4fd9-b99f-dd0672cdbe0b',
    factoryCode: 'XG',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wenna.Wu@nike.com',
    isActive: true
  },
  {
    id: '69219a48-2be8-4d3d-b05e-1420b2324857',
    factoryCode: 'XS',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stephanie.Wang@nike.com',
    isActive: true
  },
  {
    id: '6454134a-1b09-4b33-9a32-f712a74bd68b',
    factoryCode: 'XS',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wenna.Wu@nike.com',
    isActive: true
  },
  {
    id: '94608cec-3406-4958-916d-237dab74aa6b',
    factoryCode: 'XB',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wendy.Xie@nike.com',
    isActive: true
  },
  {
    id: 'bfede2dc-d21a-42fb-ad63-a1dce6bac372',
    factoryCode: 'XB',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Marqueen.Chen@nike.com',
    isActive: true
  },
  {
    id: '21c123e9-9f3d-48b8-a9ac-24844d166bba',
    factoryCode: 'XH',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wendy.Xie@nike.com',
    isActive: true
  },
  {
    id: '8eae3000-dc28-4394-91df-41896fcbd255',
    factoryCode: 'XH',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Marqueen.Chen@nike.com',
    isActive: true
  },
  {
    id: '4fb4e8f9-9fea-4d97-8c00-2ca8d4098559',
    factoryCode: 'IA',
    factoryName: 'PT ADIS DIMENSION FOOTWEAR',
    managingOffice: 'ID01D1',
    managingOfficeName: 'NLO/INDONESIA LIAISON OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stephanie.Wang@nike.com',
    isActive: true
  },
  {
    id: '2d56798b-5109-4abc-8a7f-abf23c28f609',
    factoryCode: 'IA',
    factoryName: 'PT ADIS DIMENSION FOOTWEAR',
    managingOffice: 'ID01D1',
    managingOfficeName: 'NLO/INDONESIA LIAISON OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wenna.Wu@nike.com',
    isActive: true
  },
  {
    id: '4e60a34c-b5dd-4f40-a15e-71be6bad4abc',
    factoryCode: 'J2',
    factoryName: 'Ever Radiant Worldwide Limited',
    managingOffice: 'ID01D1',
    managingOfficeName: 'NLO/INDONESIA LIAISON OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stephanie.Wang@nike.com',
    isActive: true
  },
  {
    id: '2c97008c-98bf-4561-8144-8a82738be743',
    factoryCode: 'J2',
    factoryName: 'Ever Radiant Worldwide Limited',
    managingOffice: 'ID01D1',
    managingOfficeName: 'NLO/INDONESIA LIAISON OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wenna.Wu@nike.com',
    isActive: true
  },
  {
    id: '86938861-4fce-4415-a77c-81b76fc51c55',
    factoryCode: 'LW',
    factoryName: 'JIA INTERNATIONAL CO., LTD.',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wenna.Wu@nike.com',
    isActive: true
  },
  {
    id: 'c0c7fcee-4580-4e26-96cd-ba09fb052e9e',
    factoryCode: 'LW',
    factoryName: 'JIA INTERNATIONAL CO., LTD.',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stephanie.Wang@nike.com',
    isActive: true
  },
  {
    id: '5bf1d0ea-3066-4771-82fa-17a40a87e22b',
    factoryCode: 'QB',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wendy.Xie@nike.com',
    isActive: true
  },
  {
    id: '5c9c752f-fced-4f16-92d2-c517d2d01d46',
    factoryCode: 'QB',
    factoryName: 'EVA OVERSEAS INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Marqueen.Chen@nike.com',
    isActive: true
  },
  {
    id: '8e9e7a75-63ab-48ae-8d1a-fce215988de4',
    factoryCode: 'SQ',
    factoryName: 'STELLA INTERNATIONAL TRADING (MACAO',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Marqueen.Chen@nike.com',
    isActive: true
  },
  {
    id: 'c91b92c3-131a-405a-bc34-25deaf156f36',
    factoryCode: 'SQ',
    factoryName: 'STELLA INTERNATIONAL TRADING (MACAO',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wendy.Xie@nike.com',
    isActive: true
  },
  {
    id: '17d0b221-26d2-4dac-a9ab-39f89e7b4f37',
    factoryCode: 'SZ',
    factoryName: 'STELLA INTERNATIONAL TRADING (MACAO',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Marqueen.Chen@nike.com',
    isActive: true
  },
  {
    id: '12e4f92d-e930-45bb-8125-0f9bf09215c5',
    factoryCode: 'SZ',
    factoryName: 'STELLA INTERNATIONAL TRADING (MACAO',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wendy.Xie@nike.com',
    isActive: true
  },
  {
    id: '46c20350-276b-4ac5-a6c4-7781a7ceefb1',
    factoryCode: 'V3',
    factoryName: 'STELLA INTERNATIONAL TRADING (MACAO',
    managingOffice: 'VN01V1',
    managingOfficeName: 'NLO/VIETNAM',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Marqueen.Chen@nike.com',
    isActive: true
  },
  {
    id: '3e19a4e7-e859-4c8e-8ee7-2cb2f4846c87',
    factoryCode: 'V3',
    factoryName: 'STELLA INTERNATIONAL TRADING (MACAO',
    managingOffice: 'VN01V1',
    managingOfficeName: 'NLO/VIETNAM',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wendy.Xie@nike.com',
    isActive: true
  },
  {
    id: '62140799-1cb4-4a0c-b61a-4ad45b399f81',
    factoryCode: 'VE',
    factoryName: 'JIA INTERNATIONAL CO., LTD.',
    managingOffice: 'VN01V1',
    managingOfficeName: 'NLO/VIETNAM',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wenna.Wu@nike.com',
    isActive: true
  },
  {
    id: '46e54b47-a437-411a-b78b-adc35468f2f2',
    factoryCode: 'VE',
    factoryName: 'JIA INTERNATIONAL CO., LTD.',
    managingOffice: 'VN01V1',
    managingOfficeName: 'NLO/VIETNAM',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stephanie.Wang@nike.com',
    isActive: true
  },
  {
    id: 'd623017c-d51c-471b-af62-7c15ae805946',
    factoryCode: 'VL',
    factoryName: 'JIA INTERNATIONAL CO., LTD.',
    managingOffice: 'VN01V1',
    managingOfficeName: 'NLO/VIETNAM',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wenna.Wu@nike.com',
    isActive: true
  },
  {
    id: 'c07676f7-c6f1-488c-ac65-7e444da0580a',
    factoryCode: 'VL',
    factoryName: 'JIA INTERNATIONAL CO., LTD.',
    managingOffice: 'VN01V1',
    managingOfficeName: 'NLO/VIETNAM',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stephanie.Wang@nike.com',
    isActive: true
  },
  {
    id: '4a1f3cba-eeb3-4638-a22d-976ee56ad120',
    factoryCode: 'VZ',
    factoryName: 'JIA INTERNATIONAL CO., LTD.',
    managingOffice: 'VN01V1',
    managingOfficeName: 'NLO/VIETNAM',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stephanie.Wang@nike.com',
    isActive: true
  },
  {
    id: '9cc02a3c-1fdf-4909-8cd0-57a2ce267728',
    factoryCode: 'VZ',
    factoryName: 'JIA INTERNATIONAL CO., LTD.',
    managingOffice: 'VN01V1',
    managingOfficeName: 'NLO/VIETNAM',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Wenna.Wu@nike.com',
    isActive: true
  },
  {
    id: '60beddb4-9056-4b58-9303-237e512896d3',
    factoryCode: 'KUK',
    factoryName: 'Kukdong Corporation',
    managingOffice: 'ID00IN',
    managingOfficeName: 'NLO/AP - INDONESIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'natalia.ng@nike.com',
    isActive: true
  },
  {
    id: '640176f9-ddec-42d0-986a-90dfecc0049d',
    factoryCode: 'POV',
    factoryName: 'POVOLO SRL',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'natalia.ng@nike.com',
    isActive: true
  },
  {
    id: '7ea7b803-6bbb-49d5-8ec2-85add7b75869',
    factoryCode: 'SLM',
    managingOffice: 'Unknown',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'natalia.ng@nike.com',
    isActive: true
  },
  {
    id: 'c768f070-bc77-4b64-8205-0186e2f20902',
    factoryCode: 'AGH',
    factoryName: 'ASI GLOBAL LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'yicoo.li@nike.com',
    isActive: true
  },
  {
    id: 'ce9feacb-1eb1-403f-9363-21bf4d55e654',
    factoryCode: 'AGH',
    factoryName: 'ASI GLOBAL LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: 'a26da5db-b030-4577-ab83-ad3feabe37a4',
    factoryCode: 'ASH',
    factoryName: 'ASI GLOBAL LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'yicoo.li@nike.com',
    isActive: true
  },
  {
    id: 'ace3d25b-5661-47e1-a56f-61d80147ca8c',
    factoryCode: 'ASH',
    factoryName: 'ASI GLOBAL LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: '17ead973-0428-4f7b-a224-4f5869bb1312',
    factoryCode: 'CCH',
    factoryName: 'Chuan Cheng Hat Co., Ltd',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: 'ed33cf3a-6aab-486e-8d52-abab562193e2',
    factoryCode: 'CCH',
    factoryName: 'Chuan Cheng Hat Co., Ltd',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Melody.Song@nike.com',
    isActive: true
  },
  {
    id: 'db926b19-3d73-4dd7-8fe3-97cdbf51230b',
    factoryCode: 'CCV',
    factoryName: 'CCH TOP CO.,LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Melody.Song@nike.com',
    isActive: true
  },
  {
    id: 'f8d10d09-60cc-4a3d-8476-8828380db1d4',
    factoryCode: 'CCV',
    factoryName: 'CCH TOP CO.,LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: '7ad5840a-b210-4328-b74c-8bed090960cd',
    factoryCode: 'CHI',
    factoryName: 'PT. CCH INDONESIA',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Melody.Song@nike.com',
    isActive: true
  },
  {
    id: '48c86fe0-00a3-44de-8826-ab315e32ebce',
    factoryCode: 'CHI',
    factoryName: 'PT. CCH INDONESIA',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: 'f6ad092b-b510-4c8d-9bbb-32e19aa586aa',
    factoryCode: 'CID',
    factoryName: 'PT ECLAT TEXTILE INTERNATIONAL',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Kaya.Sheng@nike.com',
    isActive: true
  },
  {
    id: 'cbb0a2e8-fb3d-45e7-9c38-43fc3ff2af70',
    factoryCode: 'CID',
    factoryName: 'PT ECLAT TEXTILE INTERNATIONAL',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyen.Tran2@nike.com',
    isActive: true
  },
  {
    id: 'f4f83885-5a6d-45e2-9297-7cb226990b2a',
    factoryCode: 'CSC',
    factoryName: 'Crystal SL Global Pte Ltd',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Michelle.Lee@nike.com',
    isActive: true
  },
  {
    id: 'db3dfe04-9c31-4ab2-b15d-4eb3a5e51b60',
    factoryCode: 'CSC',
    factoryName: 'Crystal SL Global Pte Ltd',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Tran@nike.com',
    isActive: true
  },
  {
    id: '254d45a5-d8b3-44fd-b736-2f7ac1d82d5c',
    factoryCode: 'CSV',
    managingOffice: 'Unknown',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Michelle.Lee@nike.com',
    isActive: true
  },
  {
    id: '449919b7-895d-457f-8994-4f9801a46c2b',
    factoryCode: 'CSV',
    managingOffice: 'Unknown',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Tran@nike.com',
    isActive: true
  },
  {
    id: '79078739-fc66-49e8-99a2-501bf27a7119',
    factoryCode: 'CVT',
    factoryName: 'ECLAT TEXTILE CO LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Cara.Yu@nike.com',
    isActive: true
  },
  {
    id: '44a3f3a0-3e08-463a-8735-38c2df41f7dd',
    factoryCode: 'CVT',
    factoryName: 'ECLAT TEXTILE CO LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyen.Tran2@nike.com',
    isActive: true
  },
  {
    id: 'aa63dba2-32df-48c1-9a34-105a877a11f5',
    factoryCode: 'E3P',
    factoryName: 'Wellpower Commerce Holding Co., Ltd',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stacy.Ye@nike.com',
    isActive: true
  },
  {
    id: 'edb1bd1b-7175-4942-8bf9-9074325fa977',
    factoryCode: 'E3P',
    factoryName: 'Wellpower Commerce Holding Co., Ltd',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: 'b047e6c6-813c-44bc-b30c-62712acd133f',
    factoryCode: 'EAG',
    factoryName: 'EAGLE NICE DEVELOPMENT LIMITED-F',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Catherine.Tang@nike.com',
    isActive: true
  },
  {
    id: 'db2b5cec-36d7-4055-8988-7dcb934e9552',
    factoryCode: 'EAG',
    factoryName: 'EAGLE NICE DEVELOPMENT LIMITED-F',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Finn.Zhang@nike.com',
    isActive: true
  },
  {
    id: '56e6eb40-9f7e-4d47-a676-f5a0291af8e8',
    factoryCode: 'EAG',
    factoryName: 'EAGLE NICE DEVELOPMENT LIMITED-F',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '6dbcf99d-31ea-46a9-8c14-f243c25ffe31',
    factoryCode: 'EAV',
    factoryName: 'Eagle Nice Development Limited - F',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Catherine.Tang@nike.com',
    isActive: true
  },
  {
    id: '9a4abe66-1a15-4512-b4d3-1a51006a03c9',
    factoryCode: 'EAV',
    factoryName: 'Eagle Nice Development Limited - F',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Finn.Zhang@nike.com',
    isActive: true
  },
  {
    id: '124d8d6f-936d-4b44-ad4e-bcc4d7bdc5d9',
    factoryCode: 'EAV',
    factoryName: 'Eagle Nice Development Limited - F',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '04fb45a3-c679-4462-8cd6-5f34420dce01',
    factoryCode: 'EC8',
    factoryName: 'Croster Co. Ltd',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'yicoo.li@nike.com',
    isActive: true
  },
  {
    id: '1ddcebfa-d9c6-4b6d-9a57-7e48d4094e98',
    factoryCode: 'EC8',
    factoryName: 'Croster Co. Ltd',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: '296dd77d-73e9-472c-8dfb-2746e8777478',
    factoryCode: 'EF6',
    factoryName: 'PT. Formosa Bag Indonesia',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Cynthia.Liu@nike.com',
    isActive: true
  },
  {
    id: 'cc13de4e-2dac-44d3-8c1b-f27e26360e8d',
    factoryCode: 'EF6',
    factoryName: 'PT. Formosa Bag Indonesia',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '9b10f297-be94-4688-b3ca-2799ec8309c2',
    factoryCode: 'EGN',
    factoryName: 'GUANG DER GROUP HOLDINGS CO., LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stacy.Ye@nike.com',
    isActive: true
  },
  {
    id: 'fb60cc4f-6917-4f80-9fe5-d5a72447673e',
    factoryCode: 'EGN',
    factoryName: 'GUANG DER GROUP HOLDINGS CO., LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: '8637bc37-53be-43ae-abb4-8ebeea2163c1',
    factoryCode: 'EH4',
    factoryName: 'YEGIN INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Alice.Zhang@nike.com',
    isActive: true
  },
  {
    id: '9d4c8c7f-8c28-4267-b552-0c67c30e6c9b',
    factoryCode: 'EH4',
    factoryName: 'YEGIN INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: 'd5cea784-3f3b-4b95-b8b9-e06a89f62aca',
    factoryCode: 'EIP',
    factoryName: 'INTERLOOP LIMITED',
    managingOffice: 'SG01SE',
    managingOfficeName: 'NLO/EQ SINGAPORE BRANCH OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Poon.Vasabhuti@nike.com',
    isActive: true
  },
  {
    id: '191fc2a4-6a4c-489e-9ff4-f1e675170644',
    factoryCode: 'EIP',
    factoryName: 'INTERLOOP LIMITED',
    managingOffice: 'SG01SE',
    managingOfficeName: 'NLO/EQ SINGAPORE BRANCH OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: 'eade1617-90e5-47cf-8771-2777ba81cb9d',
    factoryCode: 'EIS',
    managingOffice: 'Unknown',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Poon.Vasabhuti@nike.com',
    isActive: true
  },
  {
    id: 'b851776c-9a96-4779-84b8-3ee8c3e42726',
    factoryCode: 'EIS',
    managingOffice: 'Unknown',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '4a8b83da-7c38-49c1-8cfc-74125df17dbf',
    factoryCode: 'EJW',
    factoryName: 'GUANG DER GROUP HOLDINGS CO., LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stacy.Ye@nike.com',
    isActive: true
  },
  {
    id: 'fded9d9a-0112-474c-a755-d1621a84fec4',
    factoryCode: 'EJW',
    factoryName: 'GUANG DER GROUP HOLDINGS CO., LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: 'a0275146-c1d7-473a-ad63-46e23d24e78a',
    factoryCode: 'EKD',
    factoryName: 'Kanaan Co., Ltd.',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Megan.Qi@nike.com',
    isActive: true
  },
  {
    id: '3ec5fce9-abf2-4005-92a1-5395da2ecd30',
    factoryCode: 'EKD',
    factoryName: 'Kanaan Co., Ltd.',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: '5ffcc3ad-0678-4204-ab92-7f1aea108916',
    factoryCode: 'EKH',
    managingOffice: 'Unknown',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Cynthia.Liu@nike.com',
    isActive: true
  },
  {
    id: 'ab83f3b1-d0ba-4c12-b072-23b80e4cc055',
    factoryCode: 'EKH',
    managingOffice: 'Unknown',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '9cf8a5f9-478b-4630-8d58-80da10b9d1ff',
    factoryCode: 'EMH',
    factoryName: 'Korrun International PTE LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Cynthia.Liu@nike.com',
    isActive: true
  },
  {
    id: '82ca4a34-b98d-454f-832c-c33ebdd03c3c',
    factoryCode: 'EMH',
    factoryName: 'Korrun International PTE LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '0fc24d35-a01b-4712-b5d3-454ce0a627b1',
    factoryCode: 'EOL',
    factoryName: 'Vung Tau Orient Co., Ltd',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Alice.Zhang@nike.com',
    isActive: true
  },
  {
    id: 'f75f6f3e-b658-4b39-a3ab-d4d5087f9f0b',
    factoryCode: 'EOL',
    factoryName: 'Vung Tau Orient Co., Ltd',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: '100f976e-d7fe-48b9-a1cc-a8a1adb7ff9a',
    factoryCode: 'EPB',
    factoryName: 'KING SPORTS INC.',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Megan.Qi@nike.com',
    isActive: true
  },
  {
    id: '167d23f8-6d34-44d1-a9d7-393073a8a137',
    factoryCode: 'EPB',
    factoryName: 'KING SPORTS INC.',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: 'a76dd271-8c48-46d0-92a8-89619ddc5ac7',
    factoryCode: 'ER5',
    factoryName: 'REGINA MIRACLE INTERNATIONAL (GROUP) LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Lexi.Wu@nike.com',
    isActive: true
  },
  {
    id: '7bc221b8-ee7a-4945-acd7-c1db557b24f4',
    factoryCode: 'ER5',
    factoryName: 'REGINA MIRACLE INTERNATIONAL (GROUP) LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyen.Tran2@nike.com',
    isActive: true
  },
  {
    id: '2ddfa11c-c7f2-4741-b5bd-ce1560cba298',
    factoryCode: 'ESM',
    factoryName: 'GUANG DER GROUP HOLDINGS CO., LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stacy.Ye@nike.com',
    isActive: true
  },
  {
    id: '692b7286-7967-47d5-b430-de9f4dbb2cae',
    factoryCode: 'ESM',
    factoryName: 'GUANG DER GROUP HOLDINGS CO., LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: 'f4671bb1-66df-4c0c-866c-dd7e4d87de65',
    factoryCode: 'EWG',
    factoryName: 'Wellpower Commerce Holding Co., Ltd',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Stacy.Ye@nike.com',
    isActive: true
  },
  {
    id: 'e645e920-2a5d-4028-bfe1-f59ee7a2f789',
    factoryCode: 'EWG',
    factoryName: 'Wellpower Commerce Holding Co., Ltd',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: '4a490322-a330-4aa8-bb4f-fea98ef035d8',
    factoryCode: 'EWT',
    factoryName: 'Walt Technology Group Co. Ltd.',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Cynthia.Liu@nike.com',
    isActive: true
  },
  {
    id: 'e1cceeb8-5b02-4a07-8528-29d6afeabc7c',
    factoryCode: 'EWT',
    factoryName: 'Walt Technology Group Co. Ltd.',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '0846b77e-d384-4474-826f-2e5cebd99735',
    factoryCode: 'EWU',
    factoryName: 'Walt USA LLC',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Cynthia.Liu@nike.com',
    isActive: true
  },
  {
    id: '285303b8-5185-4f46-ae47-e847352b9583',
    factoryCode: 'EWU',
    factoryName: 'Walt USA LLC',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '7eec7674-53de-44ea-8b12-6723a5d0378d',
    factoryCode: 'EWW',
    managingOffice: 'Unknown',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Poon.Vasabhuti@nike.com',
    isActive: true
  },
  {
    id: 'ac5e923d-95ea-4411-bec2-062e36d09934',
    factoryCode: 'EWW',
    managingOffice: 'Unknown',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '13876bd2-1879-4f61-9c90-9d50480e9682',
    factoryCode: 'EYV',
    factoryName: 'YEGIN INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Alice.Zhang@nike.com',
    isActive: true
  },
  {
    id: 'eae53357-0025-4d4d-af16-64931aa1326f',
    factoryCode: 'EYV',
    factoryName: 'YEGIN INTERNATIONAL LIMITED',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Khanh.Cao@nike.com',
    isActive: true
  },
  {
    id: '00ceb65f-a7c1-4c34-ae91-f004ad0e812a',
    factoryCode: 'FEC',
    factoryName: 'FE NEW CENTURY INDUSTRY (SINGAPORE) PTE LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Jean.Lu@nike.com',
    isActive: true
  },
  {
    id: 'ef54db17-3378-4c59-b0eb-5a2881866462',
    factoryCode: 'FEC',
    factoryName: 'FE NEW CENTURY INDUSTRY (SINGAPORE) PTE LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Le2@nike.com',
    isActive: true
  },
  {
    id: '3a153e14-ffac-4f93-8cb4-1dfaaaa4d037',
    factoryCode: 'FVN',
    factoryName: 'FE NEW CENTURY INDUSTRY (SINGAPORE) PTE LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Jean.Lu@nike.com',
    isActive: true
  },
  {
    id: '9d74b5cd-f25b-49fa-987f-8f9759776225',
    factoryCode: 'FVN',
    factoryName: 'FE NEW CENTURY INDUSTRY (SINGAPORE) PTE LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Le2@nike.com',
    isActive: true
  },
  {
    id: 'c1e574b3-2143-4ba2-9a72-d849f3bc2680',
    factoryCode: 'GWM',
    factoryName: 'Sintex International Ltd.',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tina.Ge@nike.com',
    isActive: true
  },
  {
    id: 'ea245e7c-99c1-4ddc-9081-d50fb0575ca5',
    factoryCode: 'GWM',
    factoryName: 'Sintex International Ltd.',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Le2@nike.com',
    isActive: true
  },
  {
    id: '1a467d5b-d308-4f33-a312-a50be3832b1e',
    factoryCode: 'HIC',
    factoryName: 'HI TECH APPAREL CO LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Kritsakorn.D@nike.com',
    isActive: true
  },
  {
    id: 'b656dea3-9cb5-439b-8921-b856d374246f',
    factoryCode: 'HIC',
    factoryName: 'HI TECH APPAREL CO LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: 'd4ff5703-2dc9-4f1c-9105-f93fcd8dafea',
    factoryCode: 'HIG',
    factoryName: 'HI-TECH APPAREL CO., LTD.',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Chanunchida.R@nike.com',
    isActive: true
  },
  {
    id: 'c562b6d6-fb24-48a6-83d9-9ffe2c5d248b',
    factoryCode: 'HIG',
    factoryName: 'HI-TECH APPAREL CO., LTD.',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '8d46a089-f491-45f4-bb59-110c3d0bfece',
    factoryCode: 'HIT',
    factoryName: 'HI-TECH APPAREL CO., LTD.',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Chanunchida.R@nike.com',
    isActive: true
  },
  {
    id: 'cb63b854-9bf3-41cf-ad02-ca8689cd97dc',
    factoryCode: 'HIT',
    factoryName: 'HI-TECH APPAREL CO., LTD.',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '277a0fdc-7904-462d-9e8f-ac69eac503c7',
    factoryCode: 'HIT',
    factoryName: 'HI-TECH APPAREL CO., LTD.',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Poon.Vasabhuti@nike.com',
    isActive: true
  },
  {
    id: 'e129409b-61fa-410d-a960-5b272cc50e2f',
    factoryCode: 'HTV',
    factoryName: 'HI TECH APPAREL CO LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Kritsakorn.D@nike.com',
    isActive: true
  },
  {
    id: '9acd7789-a9ba-41b3-965f-e2b306a4ccc2',
    factoryCode: 'HTV',
    factoryName: 'HI TECH APPAREL CO LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '164e8931-6abd-4e68-b8fe-e0c2c45f3ea3',
    factoryCode: 'IEN',
    factoryName: 'PT EAGLE NICE INDONESIA',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Catherine.Tang@nike.com',
    isActive: true
  },
  {
    id: 'd7d93917-d3c8-4353-9720-79f62a0aeb7c',
    factoryCode: 'IEN',
    factoryName: 'PT EAGLE NICE INDONESIA',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Finn.Zhang@nike.com',
    isActive: true
  },
  {
    id: '740046ef-bb84-4abb-8a7a-50e8fc1014c9',
    factoryCode: 'IEN',
    factoryName: 'PT EAGLE NICE INDONESIA',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '451e7759-4c7a-417b-beda-c1cd06b3ead1',
    factoryCode: 'KKA',
    factoryName: 'GRAND MAX LIMITED',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Akaraya.T@nike.com',
    isActive: true
  },
  {
    id: '011fd9e5-195f-49b5-b8d2-bd86689a0109',
    factoryCode: 'KKA',
    factoryName: 'GRAND MAX LIMITED',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyet.Nguyen@nike.com',
    isActive: true
  },
  {
    id: 'f710a3fa-75d4-41e7-8095-b1c1468c24c0',
    factoryCode: 'LMB',
    factoryName: 'MAS ACTIVE TRADING (PRIVATE) LIMITED',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sharon.Kumar@nike.com',
    isActive: true
  },
  {
    id: '6d5e0ce2-d0a7-40c0-95af-bcb9cd53fcba',
    factoryCode: 'LMB',
    factoryName: 'MAS ACTIVE TRADING (PRIVATE) LIMITED',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Hanh.Tran@nike.com',
    isActive: true
  },
  {
    id: '373535e0-05ca-4142-959e-ed7f2ad3f926',
    factoryCode: 'LMI',
    factoryName: 'MAS ACTIVE TRADING (PRIVATE) LIMITED',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sharon.Kumar@nike.com',
    isActive: true
  },
  {
    id: 'fb9a6b30-dd0e-4c0c-92e0-0da30bc161de',
    factoryCode: 'LMI',
    factoryName: 'MAS ACTIVE TRADING (PRIVATE) LIMITED',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Hanh.Tran@nike.com',
    isActive: true
  },
  {
    id: 'e90ae621-d679-4d86-8bdc-45b71847d080',
    factoryCode: 'LMJ',
    factoryName: 'Mas Al safi Apparel Manufacturing LLC',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Mohit.Makhijani@nike.com',
    isActive: true
  },
  {
    id: 'a334c2b6-9065-488f-805a-68deb4b61f43',
    factoryCode: 'LMJ',
    factoryName: 'Mas Al safi Apparel Manufacturing LLC',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Hanh.Tran@nike.com',
    isActive: true
  },
  {
    id: '9c2d7a8c-ae36-410b-8eca-a1bc4c50bb71',
    factoryCode: 'LMS',
    factoryName: 'MAS ACTIVE TRADING (PRIVATE) LIMITED',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Aparna.Subramanian@nike.com',
    isActive: true
  },
  {
    id: '1a11256f-8604-4e3a-b0d6-75f393b7ec94',
    factoryCode: 'LMS',
    factoryName: 'MAS ACTIVE TRADING (PRIVATE) LIMITED',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Hanh.Tran@nike.com',
    isActive: true
  },
  {
    id: '48d83597-a0c3-4011-a10f-cbe9b93014a2',
    factoryCode: 'LSB',
    factoryName: 'MAS AMITY PTE LTD',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Mohit.Makhijani@nike.com',
    isActive: true
  },
  {
    id: 'fc2fa800-71ed-459e-aece-8234206fcc7e',
    factoryCode: 'LSB',
    factoryName: 'MAS AMITY PTE LTD',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Hanh.Tran@nike.com',
    isActive: true
  },
  {
    id: '8c05c253-b48c-4079-8def-01ea4fb2b40e',
    factoryCode: 'MAT',
    factoryName: 'MILTEKS TEKSTIL SANAYI VE TICARET A',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sarunya.K@nike.com',
    isActive: true
  },
  {
    id: 'b2f78652-2b2c-4953-b4dd-6964c29e7d24',
    factoryCode: 'MAT',
    factoryName: 'MILTEKS TEKSTIL SANAYI VE TICARET A',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: 'd7785fb6-9c33-417f-945d-596c9fd655aa',
    factoryCode: 'MTK',
    factoryName: 'MILTEKS TEKSTIL SANAYI VE TICARET A.S.',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sarunya.K@nike.com',
    isActive: true
  },
  {
    id: '012e4ae6-3a6c-44b9-8305-76b86dd69b35',
    factoryCode: 'MTK',
    factoryName: 'MILTEKS TEKSTIL SANAYI VE TICARET A.S.',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '382dcef6-50e1-4677-a140-e7fd1787558a',
    factoryCode: 'MXP',
    factoryName: 'MAXPORT LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Laura.Gou@nike.com',
    isActive: true
  },
  {
    id: '04cc9b9a-168f-49ad-bbe1-5507c6fb98d9',
    factoryCode: 'MXP',
    factoryName: 'MAXPORT LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: 'e96d86d0-0d67-43b6-b8fb-2d4402735ada',
    factoryCode: 'NAC',
    factoryName: 'Nice Apparel Co. Ltd',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Waraporn.V@nike.com',
    isActive: true
  },
  {
    id: '1f9dcd07-7911-40a5-94c2-b0f0c798c4d4',
    factoryCode: 'NAC',
    factoryName: 'Nice Apparel Co. Ltd',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyet.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '8b460d14-156a-4a51-851b-f86afe57b315',
    factoryCode: 'NAG',
    factoryName: 'Nice Apparel Co. Ltd',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Waraporn.V@nike.com',
    isActive: true
  },
  {
    id: '0463cca1-38ee-47ed-9448-95480d0ff23a',
    factoryCode: 'NAG',
    factoryName: 'Nice Apparel Co. Ltd',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyet.Nguyen@nike.com',
    isActive: true
  },
  {
    id: 'dd5bb5e6-a3b1-4829-bd52-24c00e80a9cd',
    factoryCode: 'NAY',
    factoryName: 'Nan Yang Garment Co, Ltd',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Waraporn.V@nike.com',
    isActive: true
  },
  {
    id: '438d4a00-1d84-476d-b656-eee54b77738d',
    factoryCode: 'NAY',
    factoryName: 'Nan Yang Garment Co, Ltd',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyet.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '4c9838c9-2206-4672-8add-af10bb2d414e',
    factoryCode: 'NIC',
    factoryName: 'MASTER LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Anna.Yuan@Nike.com',
    isActive: true
  },
  {
    id: '67405f0c-0d23-41e4-a0ad-9b0b4b68db7e',
    factoryCode: 'NIC',
    factoryName: 'MASTER LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Le2@nike.com',
    isActive: true
  },
  {
    id: '5cd27f97-6d29-4ba8-a16e-7baf4ac5ea21',
    factoryCode: 'NIG',
    factoryName: 'SHENZHOU INTERNATIONAL GROUP HOLDIN',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Celine.Wang@nike.com',
    isActive: true
  },
  {
    id: '4e1c9c8e-c285-44df-8dff-4c346b0576fd',
    factoryCode: 'NIG',
    factoryName: 'SHENZHOU INTERNATIONAL GROUP HOLDIN',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Le2@nike.com',
    isActive: true
  },
  {
    id: '81a65e3a-de5a-47ad-b13f-4215ac9d2955',
    factoryCode: 'NIV',
    factoryName: 'MASTER LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Yibin.Zhang@nike.com',
    isActive: true
  },
  {
    id: '929496b3-0e52-437c-bd1c-c970442330a3',
    factoryCode: 'NIV',
    factoryName: 'MASTER LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Le2@nike.com',
    isActive: true
  },
  {
    id: '46d80c82-1de6-4163-8e97-bebdc71578ea',
    factoryCode: 'NVC',
    factoryName: 'Grand Max Limited',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Akaraya.T@nike.com',
    isActive: true
  },
  {
    id: '9bf2ade0-7136-4dfe-9568-2e09038ad3bb',
    factoryCode: 'NVC',
    factoryName: 'Grand Max Limited',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyet.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '69c14b43-32e6-4618-955e-671e55d0179d',
    factoryCode: 'QVC',
    factoryName: 'JIAXING QUANG VIET GARMENT CO. LTD.',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Laura.Gou@nike.com',
    isActive: true
  },
  {
    id: 'd2bb27aa-9537-45e4-9d39-9134a4a1a1fe',
    factoryCode: 'QVC',
    factoryName: 'JIAXING QUANG VIET GARMENT CO. LTD.',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: 'ea47fce6-e017-4581-bb63-ea13a52aaaa7',
    factoryCode: 'QVE',
    factoryName: 'KWANG VIET GARMENT CO LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Laura.Gou@nike.com',
    isActive: true
  },
  {
    id: 'd143d16a-f29b-4935-af44-2d896d11b39a',
    factoryCode: 'QVE',
    factoryName: 'KWANG VIET GARMENT CO LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tri.Nguyen@nike.com',
    isActive: true
  },
  {
    id: 'fc56fd4d-8011-4f97-b554-837b4d6a0a4d',
    factoryCode: 'RMI',
    factoryName: 'REGINA MIRACLE INTERNATIONAL (GROUP) LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Lexi.Wu@nike.com',
    isActive: true
  },
  {
    id: '64d9cdb6-a0b1-4561-8838-799b0bf0b110',
    factoryCode: 'RMI',
    factoryName: 'REGINA MIRACLE INTERNATIONAL (GROUP) LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyen.Tran2@nike.com',
    isActive: true
  },
  {
    id: '7a9f1af1-929c-410d-beb9-84991548e34c',
    factoryCode: 'RMV',
    factoryName: 'REGINA MIRACLE INTERNATIONAL (GROUP) LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Lexi.Wu@nike.com',
    isActive: true
  },
  {
    id: '9b099f4c-20d2-40aa-a2d6-9bb7a4cce0f4',
    factoryCode: 'RMV',
    factoryName: 'REGINA MIRACLE INTERNATIONAL (GROUP) LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyen.Tran2@nike.com',
    isActive: true
  },
  {
    id: '96b7996a-4cd2-47c5-aa85-937ffd463272',
    factoryCode: 'SAA',
    factoryName: 'SABRINA FASHION INDUSTRIAL CORP.',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'jerry.mei@nike.com',
    isActive: true
  },
  {
    id: '2aa93155-6cb7-414a-aae3-a1763bef0bf4',
    factoryCode: 'SAA',
    factoryName: 'SABRINA FASHION INDUSTRIAL CORP.',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyen.Tran2@nike.com',
    isActive: true
  },
  {
    id: 'b7aba372-731b-4e8a-81ec-1c6c7d05090d',
    factoryCode: 'SAC',
    factoryName: 'SABRINA FASHION INDUSTRIAL CORP.',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'jerry.mei@nike.com',
    isActive: true
  },
  {
    id: '8b58a6f2-e885-447e-9984-cf1f702ecacd',
    factoryCode: 'SAC',
    factoryName: 'SABRINA FASHION INDUSTRIAL CORP.',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyen.Tran2@nike.com',
    isActive: true
  },
  {
    id: '23025aa1-3785-40b4-8c0d-1a98c4265233',
    factoryCode: 'SHK',
    factoryName: 'Shahi Exports Pvt Ltd',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sharon.Kumar@nike.com',
    isActive: true
  },
  {
    id: '7bf93749-8920-484c-8c0c-127724cd848c',
    factoryCode: 'SHK',
    factoryName: 'Shahi Exports Pvt Ltd',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Hanh.Tran@nike.com',
    isActive: true
  },
  {
    id: 'b75691ea-2d0f-4b3b-80b0-726fa9871471',
    factoryCode: 'SIV',
    factoryName: 'SOUTH ISLAND GARMENT SDN BHD',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Rathi.Rajendran@nike.com',
    isActive: true
  },
  {
    id: 'f6892143-999a-4b52-b7e4-b9903ac3cb6a',
    factoryCode: 'SIV',
    factoryName: 'SOUTH ISLAND GARMENT SDN BHD',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Hanh.Tran@nike.com',
    isActive: true
  },
  {
    id: '6a89012e-4f1b-43cf-bcd8-f22a31c146ba',
    factoryCode: 'SNY',
    factoryName: 'Sintex International Ltd.',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tina.Ge@nike.com',
    isActive: true
  },
  {
    id: '3ba22b72-4049-4c0d-a4d0-18763d5e6176',
    factoryCode: 'SNY',
    factoryName: 'Sintex International Ltd.',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Le2@nike.com',
    isActive: true
  },
  {
    id: '8be4a5f9-a6cf-41e3-bc16-f28f3c60654f',
    factoryCode: 'SPX',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PT',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Thu.Tran@nike.com',
    isActive: true
  },
  {
    id: '860ad971-d207-4eab-ae35-6d892815aefa',
    factoryCode: 'SPX',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PT',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '2144bdb8-9665-4eed-82df-d14de52ab73f',
    factoryCode: 'SPX',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PT',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sarunya.K@nike.com',
    isActive: true
  },
  {
    id: 'd8dfdd57-75fe-476b-b1ad-53a5bd3c0cab',
    factoryCode: 'SVV',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PTE LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Thu.Tran@nike.com',
    isActive: true
  },
  {
    id: '13305de1-de2d-4e60-bfb4-544cc6cb2471',
    factoryCode: 'SVV',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PTE LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '577cd08c-2778-4e60-956c-e92c4f7b1256',
    factoryCode: 'SVV',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PTE LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sarunya.K@nike.com',
    isActive: true
  },
  {
    id: '5a505246-9812-4a9c-b92b-5d09b91daeb8',
    factoryCode: 'SWA',
    factoryName: 'Sintex International Ltd.',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tina.Ge@nike.com',
    isActive: true
  },
  {
    id: '09d46e19-35ae-482a-9417-dbb7c54ddf90',
    factoryCode: 'SWA',
    factoryName: 'Sintex International Ltd.',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Le2@nike.com',
    isActive: true
  },
  {
    id: '35c28b24-d038-47a1-a29b-d6f1e4eb7cb6',
    factoryCode: 'SYC',
    factoryName: 'ZHENJIANG TUNTEX',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Thu.Tran@nike.com',
    isActive: true
  },
  {
    id: 'f33f8228-2828-4952-8a7f-2509a719de78',
    factoryCode: 'SYC',
    factoryName: 'ZHENJIANG TUNTEX',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '8f7a4714-4c08-4b71-999b-c13637729df0',
    factoryCode: 'SYC',
    factoryName: 'ZHENJIANG TUNTEX',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sarunya.K@nike.com',
    isActive: true
  },
  {
    id: '9e72e241-7e0b-430c-a085-bbe2e7789913',
    factoryCode: 'SYG',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PTE LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Thu.Tran@nike.com',
    isActive: true
  },
  {
    id: '77a1a1d2-1f80-49c4-a557-ac910eb65d5d',
    factoryCode: 'SYG',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PTE LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '9c20a4f5-ad78-49a4-9b31-2401a3758310',
    factoryCode: 'SYG',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PTE LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sarunya.K@nike.com',
    isActive: true
  },
  {
    id: '64814b54-57d8-4084-b7e1-4f3d1c01d18b',
    factoryCode: 'SYS',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PTE LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Thu.Tran@nike.com',
    isActive: true
  },
  {
    id: '05736b51-1382-4bf2-930e-1235a6cbe6ce',
    factoryCode: 'SYS',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PTE LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Dat.Vo@nike.com',
    isActive: true
  },
  {
    id: '525d88fd-0cab-47aa-9a9a-b9e41645db1f',
    factoryCode: 'SYS',
    factoryName: 'TUNG MUNG TEXTILE CO (SINGAPORE) PTE LTD',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sarunya.K@nike.com',
    isActive: true
  },
  {
    id: '36a44e52-f2eb-4e05-9a0e-1d6dfcec9e68',
    factoryCode: 'TWA',
    factoryName: 'SONG LIN GARMENTS SDN BHD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Joanna.Loh@nike.com',
    isActive: true
  },
  {
    id: 'c82395a1-4036-42d6-89b5-a1ce7a15747d',
    factoryCode: 'TWA',
    factoryName: 'SONG LIN GARMENTS SDN BHD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Adrienna.Siew@nike.com',
    isActive: true
  },
  {
    id: '4d0660a0-0d24-4cbe-b02c-c13ee789e25f',
    factoryCode: 'TWA',
    factoryName: 'SONG LIN GARMENTS SDN BHD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Lili.Lim@nike.com',
    isActive: true
  },
  {
    id: '98c79576-7ae4-4709-b141-115ff27e2359',
    factoryCode: 'TWA',
    factoryName: 'SONG LIN GARMENTS SDN BHD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sujata.Kaur@nike.com',
    isActive: true
  },
  {
    id: '4d4d9353-6224-4fb9-9f89-acafb6b9f721',
    factoryCode: 'TWA',
    factoryName: 'SONG LIN GARMENTS SDN BHD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Tran@nike.com',
    isActive: true
  },
  {
    id: 'a8ae547e-053d-46f1-a04b-4ca67ebecb3a',
    factoryCode: 'TWC',
    factoryName: 'RAMATEX INDUSTRIAL (SUZHOU) LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Joanna.Loh@nike.com',
    isActive: true
  },
  {
    id: '000b67c6-b63d-474f-a415-3db292fe952a',
    factoryCode: 'TWC',
    factoryName: 'RAMATEX INDUSTRIAL (SUZHOU) LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Adrienna.Siew@nike.com',
    isActive: true
  },
  {
    id: 'aab40df7-9a61-4867-ade8-68cf9469cc3b',
    factoryCode: 'TWC',
    factoryName: 'RAMATEX INDUSTRIAL (SUZHOU) LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Lili.Lim@nike.com',
    isActive: true
  },
  {
    id: 'd68ca9a0-35ca-4f8d-8343-ca75778755e2',
    factoryCode: 'TWC',
    factoryName: 'RAMATEX INDUSTRIAL (SUZHOU) LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sujata.Kaur@nike.com',
    isActive: true
  },
  {
    id: '5a7794b9-a88f-42af-af3e-3b169d862f30',
    factoryCode: 'TWC',
    factoryName: 'RAMATEX INDUSTRIAL (SUZHOU) LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Tran@nike.com',
    isActive: true
  },
  {
    id: '9d3195cd-fe04-4bf0-84a0-bfc458e10851',
    factoryCode: 'TWJ',
    factoryName: 'Pine Tree Company For Textile Manufacturing PSC',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Joanna.Loh@nike.com',
    isActive: true
  },
  {
    id: 'e0ef2a2f-8cdc-497d-8aef-87c29bbc2783',
    factoryCode: 'TWJ',
    factoryName: 'Pine Tree Company For Textile Manufacturing PSC',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Adrienna.Siew@nike.com',
    isActive: true
  },
  {
    id: '8594a0f6-a4b9-4795-a242-294a59da9d76',
    factoryCode: 'TWJ',
    factoryName: 'Pine Tree Company For Textile Manufacturing PSC',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Lili.Lim@nike.com',
    isActive: true
  },
  {
    id: '04b1b048-ed22-4865-bb18-c27910e02d7d',
    factoryCode: 'TWJ',
    factoryName: 'Pine Tree Company For Textile Manufacturing PSC',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sujata.Kaur@nike.com',
    isActive: true
  },
  {
    id: '87602e66-b7ff-426b-9986-af2069e7c058',
    factoryCode: 'TWJ',
    factoryName: 'Pine Tree Company For Textile Manufacturing PSC',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Tran@nike.com',
    isActive: true
  },
  {
    id: '98276a0a-3125-426f-863d-0d3b12f5c48d',
    factoryCode: 'TWM',
    factoryName: 'MAPLE COMPANY LIMITED',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Joanna.Loh@nike.com',
    isActive: true
  },
  {
    id: 'b7286311-ec94-41e6-9365-86ebc153967a',
    factoryCode: 'TWM',
    factoryName: 'MAPLE COMPANY LIMITED',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Adrienna.Siew@nike.com',
    isActive: true
  },
  {
    id: '5ade6e1d-6bea-4269-8681-f045e76d0da8',
    factoryCode: 'TWM',
    factoryName: 'MAPLE COMPANY LIMITED',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Lili.Lim@nike.com',
    isActive: true
  },
  {
    id: '13048ea2-68f4-4eaa-b2a1-6d23e1e4dc40',
    factoryCode: 'TWM',
    factoryName: 'MAPLE COMPANY LIMITED',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sujata.Kaur@nike.com',
    isActive: true
  },
  {
    id: '1a411c51-9f72-46be-bae8-a9890fddd488',
    factoryCode: 'TWM',
    factoryName: 'MAPLE COMPANY LIMITED',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Tran@nike.com',
    isActive: true
  },
  {
    id: '8816c118-f36b-4cc5-9bb0-130f55b979bc',
    factoryCode: 'TWO',
    factoryName: 'OLIVE APPAREL (CAMBODIA) CO., LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Joanna.Loh@nike.com',
    isActive: true
  },
  {
    id: '18a23059-7282-4a86-ba0d-72fb3e86031e',
    factoryCode: 'TWO',
    factoryName: 'OLIVE APPAREL (CAMBODIA) CO., LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Adrienna.Siew@nike.com',
    isActive: true
  },
  {
    id: '52d24a2b-f8db-4510-8fbf-ef383e06e54e',
    factoryCode: 'TWO',
    factoryName: 'OLIVE APPAREL (CAMBODIA) CO., LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Lili.Lim@nike.com',
    isActive: true
  },
  {
    id: 'b3bd5558-4026-4011-9aed-f12008f6ff5e',
    factoryCode: 'TWO',
    factoryName: 'OLIVE APPAREL (CAMBODIA) CO., LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Sujata.Kaur@nike.com',
    isActive: true
  },
  {
    id: '5bc10ea7-cffc-436b-be00-aa2e27161bbf',
    factoryCode: 'TWO',
    factoryName: 'OLIVE APPAREL (CAMBODIA) CO., LTD',
    managingOffice: 'SG00SG',
    managingOfficeName: 'NLO/AP - SINGAPORE FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Nhung.Tran@nike.com',
    isActive: true
  },
  {
    id: '8d98285a-ccb3-4067-9765-0b19fae4b205',
    factoryCode: 'YAN',
    factoryName: 'Nice Apparel Co. Ltd',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Akaraya.T@nike.com',
    isActive: true
  },
  {
    id: '8cb9298d-4fdc-47a4-aa6f-f04104d02d34',
    factoryCode: 'YAN',
    factoryName: 'Nice Apparel Co. Ltd',
    managingOffice: 'TH00TH',
    managingOfficeName: 'NLO/AP - THAILAND FIELD OFFICE',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Tuyet.Nguyen@nike.com',
    isActive: true
  },
  {
    id: '2e20146b-f077-411b-be4b-c9948adbaa5d',
    factoryCode: 'CCV',
    factoryName: 'CCH TOP CO.,LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'GLnu',
    managingUser: 'Garima LNU',
    managingUserEmail: 'Garima.Lnu@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-07-27T15:32:24.965Z',
    isActive: true
  },
  {
    id: '5c7788e3-5fd0-4b20-86c8-817bf6f386c1',
    factoryCode: '10213',
    managingOffice: 'NA',
    managingOfficeName: 'NA',
    managingUserID: 'SSs',
    managingUser: 'Saravanan SS',
    managingUserEmail: 'Saravanan.Ss@nike.com',
    modifiedBy: 'dpom.it01@nike.com',
    modifiedDate: '2023-02-07T05:56:30.395Z',
    isActive: true
  },
  {
    id: '92b10184-fcec-40da-a5ae-e9a1c7e430c3',
    factoryCode: '11011',
    factoryName: 'Inkwright LLC',
    managingUserID: 'JSund1',
    managingUser: 'Jayakumar Sundaramurthy',
    managingUserEmail: 'Jayakumar.Sundaramurthy@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-03T13:12:15.814Z',
    isActive: true
  },
  {
    id: '440e5369-95ff-4d9c-b4f4-4fc23ff6465d',
    factoryCode: 'ACL',
    managingOffice: 'NA',
    managingOfficeName: 'NA',
    managingUserID: 'JSund1',
    managingUser: 'Jayakumar Sundaramurthy',
    managingUserEmail: 'Jayakumar.Sundaramurthy@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-03T13:09:48.094Z',
    isActive: true
  },
  {
    id: 'c1c04d49-29ce-40f4-9507-cf95d346ec60',
    factoryCode: 'BP',
    factoryName: 'ANIGER - CALCADOS SUPRIMENTOS E EMPREENDIMENTOS LTDA',
    managingOffice: 'AR00A1',
    managingOfficeName: 'NLO/Argentina LO',
    managingUserID: 'Unknown',
    managingUser: 'Unknown',
    managingUserEmail: 'Bruna.Winck@nike.com',
    isActive: true
  },
  {
    id: '76cfee66-c1ef-4268-a498-d913ce5f2cb6',
    factoryCode: 'DGV',
    factoryName: 'DELTA GALIL INDUSTRIES LTD',
    managingOffice: 'SG01SE',
    managingOfficeName: 'NLO/EQ SINGAPORE BRANCH OFFICE',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-16T09:23:33.093Z',
    isActive: true
  },
  {
    id: '87294720-1d95-4cd4-a0f2-935bbcde66fa',
    factoryCode: 'ASH',
    factoryName: 'ASI GLOBAL LIMITED',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'SSs',
    managingUser: 'Saravanan SS',
    managingUserEmail: 'Saravanan.Ss@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-16T10:34:36.669Z',
    isActive: true
  },
  {
    id: 'e7c6d904-e421-4307-939d-5f994bbbde87',
    factoryCode: 'AJP',
    factoryName: 'HUAIAN YUANTONG HEADWEAR',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-16T10:37:12.814Z',
    isActive: true
  },
  {
    id: '73ecd88f-ca4a-43b8-b41f-9c3b1946b5dc',
    factoryCode: 'LMS',
    managingOffice: 'IN00ID',
    managingOfficeName: 'NLO/AP - INDIA FIELD OFFICE',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.it01@nike.com',
    modifiedDate: '2022-08-16T11:10:07.783Z',
    isActive: true
  },
  {
    id: '69cb1e46-b212-4b05-94ba-0175936f0ef5',
    factoryCode: 'STY',
    factoryName: 'Styldea SRL',
    managingOffice: 'IT01I1',
    managingOfficeName: 'NLO/ITALY BRANCH',
    managingUserID: 'JSund1',
    managingUser: 'Jayakumar Sundaramurthy',
    managingUserEmail: 'Jayakumar.Sundaramurthy@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-17T06:14:39.861Z',
    isActive: true
  },
  {
    id: '2d359197-1e64-4076-ac28-00f1d8bb8a4a',
    factoryCode: 'AF',
    managingOffice: 'BR00B1',
    managingOfficeName: 'NLO/NOVO HAMBURG',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-17T07:53:04.394Z',
    isActive: true
  },
  {
    id: '392f7f90-faa7-4dc7-a542-7de304a722df',
    factoryCode: 'STY',
    factoryName: 'Styldea SRL',
    managingOffice: 'IT01I1',
    managingOfficeName: 'NLO/ITALY BRANCH',
    managingUserID: 'KTayal',
    managingUser: 'Ketan Tayal',
    managingUserEmail: 'Ketan.Tayal@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-17T10:54:12.060Z',
    isActive: true
  },
  {
    id: 'ab5099f0-9469-4dda-bbd2-e251711d582d',
    factoryCode: 'FEC',
    factoryName: 'FE NEW CENTURY INDUSTRY (SINGAPORE) PTE LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-18T05:40:59.456Z',
    isActive: true
  },
  {
    id: 'da16d21f-e095-4537-aefd-7129c2f78101',
    factoryCode: 'FEC',
    factoryName: 'FE NEW CENTURY INDUSTRY (SINGAPORE) PTE LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'JSund1',
    managingUser: 'Jayakumar Sundaramurthy',
    managingUserEmail: 'Jayakumar.Sundaramurthy@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-19T08:45:35.452Z',
    isActive: true
  },
  {
    id: '8b14addf-956d-4ff7-9059-afb44fecfc5a',
    factoryCode: 'AJN',
    factoryName: 'AJOO NETWORKS',
    managingOffice: 'US03LC',
    managingOfficeName: 'NLO/LICENSEES USA',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-22T07:29:09.656Z',
    isActive: true
  },
  {
    id: 'f07a18ce-565b-44bd-839b-7a12352c6bfa',
    factoryCode: 'FEC',
    factoryName: 'FE NEW CENTURY INDUSTRY (SINGAPORE) PTE LTD',
    managingOffice: 'CN02CN',
    managingOfficeName: 'NLO/AP - CHINA FIELD OFFICE 1',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-22T07:56:20.360Z',
    isActive: true
  },
  {
    id: '5a02fefe-16f1-46fd-a781-7e1c141e107e',
    factoryCode: 'DER',
    factoryName: 'DER WILL SA',
    managingOffice: 'AR00SA',
    managingOfficeName: 'NLO/AP - SOUTH AMERICA FIELD OFFICE',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-22T07:58:39.132Z',
    isActive: true
  },
  {
    id: '3d02db03-9a1a-425c-8db8-7661545fe73f',
    factoryCode: 'DGV',
    factoryName: 'DELTA GALIL INDUSTRIES LTD',
    managingOffice: 'SG01SE',
    managingOfficeName: 'NLO/EQ SINGAPORE BRANCH OFFICE',
    managingUserID: 'JSund1',
    managingUser: 'Jayakumar Sundaramurthy',
    managingUserEmail: 'Jayakumar.Sundaramurthy@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-22T07:59:32.900Z',
    isActive: true
  },
  {
    id: '04885e0b-e7d5-4c06-a921-b360cad3b7bf',
    factoryCode: 'A61',
    managingOffice: 'JP00JP',
    managingOfficeName: 'NLO/JAPAN L4L',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-22T13:29:54.979Z',
    isActive: true
  },
  {
    id: 'fae89e35-2231-4038-b59e-8b952ecc8fa7',
    factoryCode: 'FUN',
    factoryName: 'FUN TEES-F',
    managingOffice: 'US04SX',
    managingOfficeName: 'NLO/AP - AMERICAS',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-22T09:11:54.168Z',
    isActive: true
  },
  {
    id: '4b9590fd-9834-4ffb-82eb-33d775e382e9',
    factoryCode: 'AJN',
    factoryName: 'AJOO NETWORKS',
    managingOffice: 'US03LC',
    managingOfficeName: 'NLO/LICENSEES USA',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-08-22T13:30:33.624Z',
    isActive: true
  },
  {
    id: '997293bb-8d32-4c77-8931-6132624a945b',
    factoryCode: 'LN',
    factoryName: 'LIFENG FOOTWEAR CORPORATION',
    managingOffice: 'CN00N1',
    managingOfficeName: 'NLO/CHINA OFFICE',
    managingUserID: 'SSin81',
    managingUser: 'Shailendra Singh',
    managingUserEmail: 'Shailendra.Singh@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2022-07-28T13:11:11.114Z',
    isActive: true
  },
  {
    id: '119c860d-b89a-4ccb-be9c-18bcd631a156',
    factoryCode: 'XC',
    factoryName: '123',
    managingOffice: 'CN00C1',
    managingOfficeName: 'Test',
    managingUserID: 'NShila',
    managingUser: 'Neha',
    managingUserEmail: 'neha.shilamkar@nike.com',
    modifiedBy: 'dpom.it01@nike.com',
    modifiedDate: '2023-01-31T14:42:51.845Z',
    isActive: true
  },
  {
    id: '2888adc3-9256-488a-b13b-a9a38cc52fd6',
    factoryCode: 'JJ',
    managingOffice: 'ID01D1',
    managingOfficeName: 'NLO/INDONESIA LIAISON OFFICE',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2023-02-07T05:47:32.331Z',
    isActive: true
  },
  {
    id: 'edbdc904-b336-4c6a-b2f0-3fe9fdd397f5',
    factoryCode: '11011',
    managingOffice: 'NA',
    managingOfficeName: 'NA',
    managingUserID: 'SSs',
    managingUser: 'Saravanan SS',
    managingUserEmail: 'Saravanan.Ss@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2023-02-07T05:48:07.483Z',
    isActive: true
  },
  {
    id: 'd1e38352-0cc7-439f-aa4c-dff7ae26208f',
    factoryCode: 'SIV',
    managingOffice: 'VN00VN',
    managingOfficeName: 'NLO/AP-VIETNAM FIELD OFFICE',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.it01@nike.com',
    modifiedDate: '2023-02-07T05:51:27.538Z',
    isActive: true
  },
  {
    id: '7f964d42-d098-4262-8a4b-daa62070efdb',
    factoryCode: 'E57',
    managingOffice: 'AR00A1',
    managingOfficeName: 'NLO/Argentina LO',
    managingUserID: 'SSs',
    managingUser: 'Saravanan SS',
    managingUserEmail: 'Saravanan.Ss@nike.com',
    modifiedBy: 'dpom.it01@nike.com',
    modifiedDate: '2023-02-07T05:52:11.392Z',
    isActive: true
  },
  {
    id: '3b5d7852-bf9e-4c41-8580-aee7a4c81d84',
    factoryCode: '11011',
    managingOffice: 'NA',
    managingOfficeName: 'NA',
    managingUserID: 'TSai',
    managingUser: 'Toramtarun Sai',
    managingUserEmail: 'Toramtarun.Sai@nike.com',
    modifiedBy: 'dpom.it01@nike.com',
    modifiedDate: '2023-02-07T05:56:30.395Z',
    isActive: true
  },
  {
    id: '581acffc-3d35-4113-bb9b-903c37d1d7ef',
    factoryCode: '11011',
    managingOffice: 'NA',
    managingOfficeName: 'NA',
    managingUserID: 'GLnu',
    managingUser: 'Garima LNU',
    managingUserEmail: 'Garima.Lnu@nike.com',
    modifiedBy: 'dpom.pmo04@nike.com',
    modifiedDate: '2023-02-23T07:34:47.190Z',
    isActive: true
  }

];

export const conversationCodeLookup = [{
  conversationCode: '101',
  conversationCodeDescription: 'N-Buy Not Ready',
  modifiedBy: 'Bhupender Sain',
  modifiedDate: '2021-10-04'
}, {
  conversationCode: '101',
  conversationCodeDescription: 'N-Buy Not Ready',
  modifiedBy: 'Bhupender Sain',
  modifiedDate: '2021-10-04'
}, {
  conversationCode: '101',
  conversationCodeDescription: 'N-Buy Not Ready',
  modifiedBy: 'Bhupender Sain',
  modifiedDate: '2021-10-04'
}];

export const gacReasonCodeLookup = [{
  goodsAtConsolidatorReasonCode: '10',
  goodsAtConsolidatorReasonDescription: 'N-Buy Not Ready',
  modifiedBy: 'Bhupender Sain',
  modifiedDate: '2021-10-04'
}, {
  goodsAtConsolidatorReasonCode: '10',
  goodsAtConsolidatorReasonDescription: 'N-Buy Not Ready',
  modifiedBy: 'Bhupender Sain',
  modifiedDate: '2021-10-04'
}, {
  goodsAtConsolidatorReasonCode: '10',
  goodsAtConsolidatorReasonDescription: 'N-Buy Not Ready',
  modifiedBy: 'Bhupender Sain',
  modifiedDate: '2021-10-04'
}];

export default settings;
