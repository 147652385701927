import fieldMapping from '../fieldMappings/poSearchFieldMapping';

const lblCurrentGACReasonCode = 'Current GAC Reason Code';
const lblSizeDescription = 'Size Description';
const lblProductCode = 'Product Code';
const destinationCountryValue = 'Destination Country';
const lblPOLineItemNumber = 'PO Line Item Number';
const lblScheduleLineItemNumber = 'Schedule Line Item Number';
const lblTradingCoPoNumber = 'Trading Co Purchase Order Number';

export default {

  [fieldMapping.CHANGE_ID]: {
    primary: fieldMapping.CHANGE_ID, secondary: '', type: 'string', link: false, label: 'Change ID', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fieldMapping.PO_NUMBER]: {
    primary: fieldMapping.PO_NUMBER, secondary: '', type: 'string', link: false, label: 'PO Number', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fieldMapping.PO_LINE_ITEM_NUMBER_REQUEST]: {
    primary: fieldMapping.PO_LINE_ITEM_NUMBER_REQUEST,
    secondary: '',
    type: 'string',
    link: false,
    label: lblPOLineItemNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.SCHEDULE_LINE_ITEM_NUMBER_REQUEST]: {
    primary: fieldMapping.SCHEDULE_LINE_ITEM_NUMBER_REQUEST,
    secondary: '',
    type: 'string',
    link: false,
    label: lblScheduleLineItemNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.SIZE_DESC_REQUEST]: {
    primary: fieldMapping.SIZE_DESC_REQUEST,
    secondary: '',
    type: 'string',
    link: false,
    label: lblSizeDescription,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.TRADING_CO_PO_NUMBER]: {
    primary: fieldMapping.TRADING_CO_PO_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblTradingCoPoNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.PRODUCT_CODE_REQUEST]: {
    primary: fieldMapping.PRODUCT_CODE_REQUEST,
    secondary: '',
    type: 'string',
    link: false,
    label: lblProductCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.DOC_TYPE_CODE]: {
    primary: fieldMapping.DOC_TYPE_CODE,
    secondary: fieldMapping.DOC_TYPE_DESC,
    type: 'string',
    link: false,
    label: 'Doc Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.CHANGE_TYPE]: {
    primary: fieldMapping.CHANGE_TYPE, secondary: '', type: 'string', link: false, label: 'Change Type', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fieldMapping.CURRENT_GAC_DATE]: {
    primary: fieldMapping.CURRENT_GAC_DATE, secondary: '', type: 'date', link: false, label: 'Current GAC Date', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fieldMapping.CURRENT_GAC_REASON_CODE]: {
    primary: fieldMapping.CURRENT_GAC_REASON_CODE,
    secondary: fieldMapping.CURRENT_GAC_REASON_DESC,
    type: 'string',
    link: false,
    label: lblCurrentGACReasonCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.PROPOSED_GAC_DATE_REQUEST]: {
    primary: fieldMapping.PROPOSED_GAC_DATE_REQUEST,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Proposed GAC Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.PROPOSED_GAC_REASON_CODE_REQUEST]: {
    primary: fieldMapping.PROPOSED_GAC_REASON_CODE_REQUEST,
    secondary: fieldMapping.PROPOSED_GAC_REASON_DESC_REQUEST,
    type: 'string',
    link: false,
    label: 'Proposed GAC Reason Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.VENDOR_CODE_REQUEST]: {
    primary: fieldMapping.VENDOR_CODE_REQUEST,
    secondary: fieldMapping.VENDOR_NAME_REQUEST,
    type: 'string',
    link: false,
    label: 'Vendor',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [fieldMapping.DESTINATION_COUNTRY_CODE_REQUEST]: {
    primary: fieldMapping.DESTINATION_COUNTRY_CODE_REQUEST,
    secondary: fieldMapping.DESTINATION_COUNTRY_NAME_REQUEST,
    type: 'string',
    link: false,
    label: destinationCountryValue,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  }
};
