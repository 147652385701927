const featureFlagMock = {
  message: '',
  statusCode: 200,
  success: true,
  data: {
    links: {
      self: 'domain/resource/v1/'
    },
    objects: [
      {
        featureName: 'GAC Reason Usecase Code',
        featureTag: 'gac-reason-usecase-code',
        status: true,
        groups: [
          'EXT.App.GSM.DPOM.Factory'
        ],
        factoryAffiliations: [
          'EAW',
          'HIN',
          'HEM',
          'EIP',
          'EIU',
          'GEX',
          'EAU',
          'IEN'
        ],
        startTime: '2024-03-30T14:25:07.231Z',
        endTime: '2024-04-30T14:25:07.231Z',
        lastUpdatedBy: 'admin@nike.com',
        lastUpdatedAt: '2024-03-29T14:25:07.231Z'
      },
      {
        featureName: 'Asian C&C Label',
        featureTag: 'asian-cnc-label',
        status: true,
        groups: [
          'EXT.App.GSM.DPOM.Factory',
          'App.GSM.DPOM.PMO.Security'
        ],
        factoryAffiliations: [
          'EAW',
          'HIN',
          'HEM',
          'EIP',
          'EIU',
          'GEX',
          'EAU',
          'IEN'
        ],
        startTime: '2024-03-30T14:25:07.231Z',
        endTime: '2024-04-30T14:25:07.231Z',
        lastUpdatedBy: 'admin@nike.com',
        lastUpdatedAt: '2024-03-29T14:25:07.231Z'
      },
      {
        featureName: 'Large File Download',
        featureTag: 'largefile-download',
        startTime: '2024-03-30T14:25:07.231Z',
        endTime: '2024-04-30T14:25:07.231Z',
        lastUpdatedBy: 'admin@nike.com',
        lastUpdatedAt: '2024-03-29T14:25:07.231Z',
        version: 'CURRENT|HISTORY',
        groups: [
          'EXT.App.GSM.DPOM.Factory',
          'App.GSM.DPOM.PMO.Security',
          'App.GSM.DPOM.InfoTech',
          'App.GSM.DPOM.PMO'
        ],
        factoryAffiliations: [
          'EAW',
          'HIN',
          'HEM',
          'EIP',
          'EIU',
          'GEX',
          'EAU',
          'IEN'
        ],
        dependencyFeatures: [
          'gac-usecase-code'
        ]
      }

    ]
  }
};

export default featureFlagMock;
