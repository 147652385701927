/* @flow */
const sortFilterData = (data: Object) => {
  const sortedData = {};
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      sortedData[key] = data[key].sort((a, b) => {
        const numA = parseInt((a.match(/^\d+/) || [])[0], 10);
        const numB = parseInt((b.match(/^\d+/) || [])[0], 10);
        return numA - numB;
      });
    } else {
      sortedData[key] = data[key];
    }
  });
  return sortedData;
};
export default sortFilterData;
