export default {
  eventDescription: {
    primary: 'eventDescription', secondary: '', type: 'string', link: false, label: 'Report Name', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventCreateTS: {
    primary: 'eventCreateTS', secondary: '', type: 'datetime', link: false, label: 'Request Submitted', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventUpdateTS: {
    primary: 'eventUpdateTS', secondary: '', type: 'datetime', link: false, label: 'Request Completion Time', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventStatus: {
    primary: 'eventStatus', secondary: '', type: 'string', link: false, label: 'Status', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  errorsFoundCount: {
    primary: 'errorsFoundCount', secondary: '', type: 'numeric', link: false, label: 'Errors Found1233', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  totalErrorCount: {
    primary: 'totalErrorCount', secondary: '', type: 'numeric', link: false, label: 'Errors Found', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventErrorList: {
    primary: 'eventErrorList', secondary: '', type: 'string', link: false, label: 'Action', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  pdfDownloadLink: {
    primary: 'pdfDownloadLink', secondary: '', type: 'string', link: false, label: 'Download Link', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  fileName: {
    primary: 'fileName', secondary: '', type: 'string', link: false, label: 'Download Link', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  totalReceivedItemsCount: {
    primary: 'totalReceivedItemsCount', secondary: '', type: 'numeric', link: false, label: 'Received PDFs', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  poNumber: {
    primary: 'poNumber', secondary: '', type: 'string', link: false, label: 'PO Number', wrap: true, numeric: 'true', disablePadding: false
  },
  reason: {
    primary: 'reason', secondary: '', type: 'string', link: false, label: 'Failure Reason', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
