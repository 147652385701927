import convoThreadReportFieldMapping from '../fieldMappings/conversationThreadReportFieldMapping';

export function renderStakeHolders(row) {
  const data = row[this.primary].map((x) => x.emailID);
  return data.join(', ');
}
export function renderConvoLink(row) {
  const convoID = row[convoThreadReportFieldMapping.CONVO_THREAD_CONVO_ID];
  const { origin } = window.location;
  return `${origin}/conversationthreads/?collabid=${convoID}`;
}
export function renderConvoCode(row) {
  const { code, topicName } = row[this.primary][0];
  return `${code} - ${topicName}`;
}
export default {
  [convoThreadReportFieldMapping.CONVO_THREAD_PO_NUMBER]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_PO_NUMBER, secondary: '', type: 'string', link: false, label: 'Purchase Order Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_ITEM_NUMBER]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_ITEM_NUMBER, secondary: '', type: 'string', link: false, label: 'PO Line Item Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_CONVO_ID]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_CONVO_ID, secondary: '', type: 'string', link: false, label: 'Conversation Id', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_CONVO_TOPIC]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_CONVO_TOPIC, secondary: '', type: 'string', link: false, label: 'Conversation Topic', numeric: false, disablePadding: false, group: true, wrap: true, default: true, renderValue: renderConvoCode
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_CONVO_TOPIC_CODE]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_CONVO_TOPIC_CODE, secondary: '', type: 'string', link: false, label: 'Conversation Topic', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_CONVO_STATUS]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_CONVO_STATUS, secondary: '', type: 'string', link: false, label: 'Conversation Status', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_PO_LINE_ITEM_STATUS]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_PO_LINE_ITEM_STATUS, secondary: '', type: 'string', link: false, label: 'DPOM Line Item Status', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_VENDOR_CODE]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_VENDOR_CODE, secondary: '', type: 'string', link: false, label: 'Vendor Code', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_GAC_DATE]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_GAC_DATE, secondary: '', type: 'date', requestDataType: 'dynamicDate', link: false, label: 'GAC', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_DOCUMENT_DATE]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_DOCUMENT_DATE, secondary: '', type: 'date', requestDataType: 'dynamicDate', link: false, label: 'Document Date', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_DOC_TYPE_CODE]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_DOC_TYPE_CODE, secondary: '', type: 'string', link: false, label: 'Doc Type', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_CREATED_BY]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_CREATED_BY, secondary: '', type: 'string', link: false, label: 'Created By', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_CREATE_DATE]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_CREATE_DATE, secondary: '', type: 'datetime', requestDataType: 'dynamicDate', link: false, label: 'Created On', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_LAST_UPDATED_BY]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_LAST_UPDATED_BY, secondary: '', type: 'string', link: false, label: 'Last Updated By', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_LAST_UPDATED_DATE]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_LAST_UPDATED_DATE, secondary: '', type: 'datetime', requestDataType: 'dynamicDate', link: false, label: 'Last Updated', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_CONVO_LINK]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_CONVO_LINK, secondary: '', type: 'string', link: false, label: 'Conversation Link', numeric: false, disablePadding: false, group: true, wrap: true, default: true, renderValue: renderConvoLink
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_ATTACHMENTS]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_ATTACHMENTS, secondary: '', type: 'string', link: false, label: 'Attachments', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_STAKEHOLDERS]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_STAKEHOLDERS, secondary: '', type: 'string', link: false, label: 'Stakeholders', numeric: false, disablePadding: false, group: true, wrap: true, default: true, renderValue: renderStakeHolders
  },
  [convoThreadReportFieldMapping.CONVO_THREAD_PRODUCT_CODE]: {
    primary: convoThreadReportFieldMapping.CONVO_THREAD_PRODUCT_CODE, secondary: '', type: 'string', link: false, label: 'Product Code', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  }
};
