import { createSlice } from '@reduxjs/toolkit';
import { cryptoRand } from '../../helpers/common';

export const initialState = {
  masterSpinner: false,
  requestError: false,
  bookmarksCount_posearch_Spinner: false,
  bookmarksCount_collabreport_Spinner: false,
  getConversationCodesSpinner: false,
  fetchGroupedPONumbersSpinner: false,
  conversationSearchSpinner: false,
  lookupApiSpinner: false,
  defaultStakeHolderSpinner: false,
  executeBookmarkSpinner: false,
  errorCodeText: {
    errCode: '',
    errDesc: '',
    errTitle: ''
  },
  saveCollabReportSpinner: false,
  reportRequestError: false,
  snackbarContent: [],
  isRequestCancellable: null,
  paginationSpinner: {
    isFetchingNextData: false,
    isFetchingPrevData: false
  },
  uploadingSpinner: false
};

const dialogStatesSlice = createSlice({
  name: 'dialogStates',
  initialState,
  reducers: {
    toggleSpinner(state, action) {
      // TOGGLE_SPINNERS
      return { ...state, masterSpinner: action.payload };
    },
    snackbarHandler(state, action) {
      // DELETE_SNACKBAR_CONTENT
      return {
        ...state,
        snackbarContent: state.snackbarContent.filter(
          (snackbarContent) => snackbarContent.id !== action.payload
        )
      };
    },
    resetSnackbar(state) {
      // RESET_SNACKBAR_CONTENT
      return {
        ...state,
        snackbarContent: []
      };
    },
    updateDialogStates(state, action) {
      // UPDATE_DIALOG_STATES
      return { ...state, ...action.payload };
    },
    updateErrorText(state, action) {
      // SET_REQUEST_ERROR_CODE_TEXT
      return {
        ...state,
        errorCodeText: {
          ...state.errorCodeText,
          ...action.payload
        }
      };
    },
    setSessionTimeout(state, action) {
      // SET_SESSION_TIMEOUT
      return {
        ...state,
        errorCodeText: {
          ...state.errorCodeText,
          ...action.payload
        }
      };
    },
    pushSnackbarContent(state, action) {
      // PUSH_SNACKBAR_CONTENT
      return {
        ...state,
        snackbarContent: [
          ...state.snackbarContent,
          { id: cryptoRand().toString().substr(2, 10), content: action.payload }
        ]
      };
    },
    cancelRequest() {
      // if (
      // cancellableRequestInstance.source
      // && typeof cancellableRequestInstance.source.cancel === 'function') {
      //   cancellableRequestInstance.source.cancel('Cancelled by user');
      // }
    },
    togglePaginationSpinner(state, action) {
      // TABLE_PAGINATION_SPINNER
      return { ...state, paginationSpinner: { ...state.paginationSpinner, ...action.payload } };
    },
    setCancellableSpinner(state, action) {
      // SET_CANCELLABLE_SPINNER
      return { ...state, isRequestCancellable: action.payload };
    },
    setRequestError(state, action) {
      // SET_REQUEST_ERROR
      return { ...state, requestError: action.payload, errorCodeText: initialState.errorCodeText };
    },
    fileUploadEvents(state, action) {
      return { ...state, uploadingSpinner: action.payload };
    }
  }
});

export const {
  toggleSpinner,
  snackbarHandler,
  resetSnackbar,
  updateDialogStates,
  updateErrorText,
  setSessionTimeout,
  pushSnackbarContent,
  cancelRequest,
  togglePaginationSpinner,
  setCancellableSpinner,
  setRequestError,
  fileUploadEvents
} = dialogStatesSlice.actions;
export const dialogStatesActions = { ...dialogStatesSlice.actions };
export default dialogStatesSlice.reducer;
