/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import {
  updateDialogStates,
  toggleSpinner
} from '../dialogStates/dialogStatesSlice';
import getflattenResponse from '../../helpers/common';
import airFreightReportFieldMapping from '../../constants/fieldMappings/airFreightReportFieldMapping';
import { initialAirFreightAdjustmentSearchFields } from '../../constants/staticData';
import appConfig from '../../appConfig';
// import airFreightSubtableMock from '../mock/airFreightSubtableMock';
// import serviceAPI from '../../helpers/serviceAPI';

export const name = 'airFreightOverride';

function createInitialState() {
  return {
    airFreightOverrideScreenData: [],
    airFreightOverrideAdjustmentScreenData: [],
    airFreightSubtableResults: {},
    noAdjustmentResults: false,
    initialAdjustmentFieldsState: { ...initialAirFreightAdjustmentSearchFields },
    afAdjustmentTotalCount: 0,
    page: 0,
    offset: 0
  };
}

export const initialState = createInitialState();

function createReducers() {
  function updateAirFreightOverrideScreen(state: Object, action: Object) {
    // UPDATE_AIRFREIGHT_OVERRIDE_SCREEN_RESULTS
    const payload = {
      data: action.payload
    };
    return {
      ...state,
      airFreightOverrideScreenData: payload.data
    };
  }

  function updateAirFreightOverrideAdjustmentScreen(state: Object, action: Object) {
    // UPDATE_AIRFREIGHT_OVERRIDE_ADJUSTMENT_SCREEN_RESULTS
    const afData = action.payload.objects;
    const editableItems = [...new Set(afData.map((item) => item.poId))].map(
      (poId) => {
        let latestItem = {};
        afData.filter((item) => item.poId === poId).forEach(
          (item) => {
            if (latestItem.createdDate === undefined) {
              latestItem = item;
            } else if (
              new Date(item.createdDate).getTime() > new Date(latestItem.createdDate).getTime()
            ) {
              latestItem = item;
            }
          }
        );
        return { ...latestItem, afOverrideId: latestItem.afOverrideId, isEditable: true };
      }
    );

    const eligibleEditPoId = editableItems.map((item) => item?.afOverrideId);
    const modifiedAfData = afData.map((item) => {
      if (eligibleEditPoId.includes(item.afOverrideId)) {
        return {
          ...item,
          isEditable: true
        };
      }
      return item;
    });
    const payload = {
      data: action.payload
    };
    return {
      ...state,
      airFreightOverrideAdjustmentScreenData: modifiedAfData,
      noAdjustmentResults: modifiedAfData.length === 0,
      afAdjustmentTotalCount: payload.data.pages.totalResources
    };
  }

  function updateAFAdjustmentInputData(state: Object, action: Object) {
    // UPDATE_AIRFREIGHT_ADJUSTMENT_REQUEST_DATA
    return {
      ...state,
      initialAdjustmentFieldsState: action.payload
    };
  }

  function clearAirFreightAdjustmentData(state: Object, action: Object) {
    // CLEAR_AIRFREIGHT_ADJUSTMENT_DATA
    return {
      ...state,
      airFreightOverrideAdjustmentScreenData: [],
      initialAdjustmentFieldsState: action.payload,
      noAdjustmentResults: false
    };
  }

  function updateAirFreightOverrideSubtableResults(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_OVERRIDE_SUBTABLE_RESULTS
    const payload = {
      data: getflattenResponse(action.payload),
      dataNext: null
    };
    return {
      ...state,
      airFreightOverrideSubtableResults: payload.data
    };
  }

  function updateAfAdjustmentPage(state: Object, action: Object) {
    // UPDATE_AF_ADJUSTMENT_PAGE

    return {
      ...state,
      page: action.payload?.page ? action.payload?.page : action.payload
    };
  }

  function updateAfAdjustmentPageOffset(state: Object, action: Object) {
    // UPDATE_AF_ADJUSTMENT_PAGE_OFFSET

    return {
      ...state,
      offset: action.payload?.offset ? action.payload?.offset : action.payload
    };
  }

  return {
    updateAirFreightOverrideScreen,
    updateAirFreightOverrideAdjustmentScreen,
    updateAFAdjustmentInputData,
    clearAirFreightAdjustmentData,
    updateAirFreightOverrideSubtableResults,
    updateAfAdjustmentPage,
    updateAfAdjustmentPageOffset
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function getAirFreightOverrideAdjustmentResults() {
    return createAsyncThunk(
      `${name}/getAirFreightOverrideAdjustmentResults`,
      async ({
        searchQuery,
        inputValues,
        callback
      }, { dispatch }) => {
        const offset = 0;
        const searchField = searchQuery || '&filter=geoCode(GC)';
        const query = `?count=${appConfig.airFrieghtAdjustmentCount}${searchField}&offset=${offset}`;
        const payload = {
          data: {}
        };
        dispatch(toggleSpinner(true));
        dispatch(actions.updateAFAdjustmentInputData(inputValues));
        dispatch(actions.updateAfAdjustmentPage(0));
        dispatch(updateDialogStates({ conversationSearchSpinner: true }));
        request({
          api: 'getAirFreightOverrideAdjustment',
          routeParams: { query },
          method: 'get'
          // cancellable: Boolean(searchQuery)
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ conversationSearchSpinner: false }));
          payload.data = response.data;
          dispatch(actions.updateAfAdjustmentPageOffset(payload.data.pages.next.split(',')[0].split('=')[1]));
          dispatch(actions.updateAirFreightOverrideAdjustmentScreen(payload.data));
          if (callback) {
            callback(response.data);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ conversationSearchSpinner: false }));
          console.log(error);
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  function getAirFreightOverrideResults() {
    return createAsyncThunk(
      `${name}/getAirFreightOverrideResults`,
      async ({
        callback
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const state = getState();
        const status = 'Pending';
        const id = state && state.auth && state.auth.parsedToken.accessToken.claims.legacy_username;
        request({
          api: 'getAirFreightOverride',
          routeParams: { id, status },
          method: 'get',
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            const flattenedResponse = getflattenResponse(response.data);
            dispatch(actions.updateAirFreightOverrideScreen(flattenedResponse.objects));
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(response);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            console.log(error);
            callback(error);
          });
      }
    );
  }

  function updatePendingApproval() {
    return createAsyncThunk(
      `${name}/updatePendingApproval`,
      async ({
        data,
        callback
      }, { getState, dispatch }) => {
        if (data) {
          const updateRequestBody = {
            type: 'update',
            airFreightData: data
          };
          dispatch(toggleSpinner(true));
          request({
            api: 'updatePendingApproval',
            method: 'put',
            data: updateRequestBody,
            cancellable: true
          }, dispatch, getState)
            .then((response) => {
              dispatch(toggleSpinner(false));
              callback(response.data);
            })
            .catch((error) => {
              dispatch(toggleSpinner(false));
              callback(null, error);
            });
        }
      }
    );
  }

  function fetchAirFreightOverrideSubTable() {
    return createAsyncThunk(
      `${name}/fetchAirFreightOverrideSubTable`,
      async ({
        rowId,
        poNumber,
        itemNumber,
        callback
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const fields = [
          airFreightReportFieldMapping.AIR_FREIGHT_PO_NUMBER,
          airFreightReportFieldMapping.AIR_FREIGHT_ITEM_NUMBER,
          'dpomGacHistoryData'
        ];
        const requestData = [
          { fieldName: 'poHeader.poNumber', operator: '=', fieldValue: [poNumber] },
          { fieldName: 'poLine.itemNumber', operator: '=', fieldValue: [itemNumber] }
        ];
        const requestBody = {
          search: requestData,
          offset: '0',
          fields: [...fields],
          count: appConfig.SearchThresholdLimit
        };
        request({
          api: 'airFreightReportSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            dispatch(toggleSpinner(false));
            const filteredData = response.data.objects.filter((x) => x.id === rowId);
            callback(filteredData);
            dispatch(actions.updateAirFreightOverrideSubtableResults(filteredData));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function fetchAfAdjustmentNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchAfAdjustmentNextResultSet`,
      async ({
        searchQuery,
        inputValues,
        callback
      }, { dispatch, getState }) => {
        const state = getState();
        const offset = Number(state.afOverride.offset);
        const searchField = searchQuery || '&filter=geoCode(GC)';
        const query = `?count=${appConfig.airFrieghtAdjustmentCount}${searchField}&offset=${offset}`;
        const payload = {
          data: {}
        };
        dispatch(toggleSpinner(true));
        dispatch(actions.updateAFAdjustmentInputData(inputValues));
        dispatch(updateDialogStates({ conversationSearchSpinner: true }));
        request({
          api: 'getAirFreightOverrideAdjustment',
          routeParams: { query },
          method: 'get'
          // cancellable: Boolean(searchQuery)
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ conversationSearchSpinner: false }));
          payload.data = response.data;
          const nextOffset = payload.data.pages?.next?.split(',')[0].split('=')[1];
          const prevOffset = payload.data.pages?.prev?.split(',')[0].split('=')[1];
          dispatch(actions.updateAfAdjustmentPageOffset(nextOffset || prevOffset));
          dispatch(actions.updateAirFreightOverrideAdjustmentScreen(payload.data));
          if (callback) {
            callback(response.data);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ conversationSearchSpinner: false }));
          console.log(error);
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  function fetchAfAdjustmentPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchAfAdjustmentPrevResultSet`,
      async ({
        searchQuery,
        inputValues,
        callback
      }, { dispatch, getState }) => {
        const state = getState();
        const offset = Number(state.afOverride.offset);
        const searchField = searchQuery || '&filter=geoCode(GC)';
        const query = `?count=${appConfig.airFrieghtAdjustmentCount}${searchField}&offset=${offset}`;
        const payload = {
          data: {}
        };
        dispatch(toggleSpinner(true));
        dispatch(actions.updateAFAdjustmentInputData(inputValues));
        dispatch(updateDialogStates({ conversationSearchSpinner: true }));
        request({
          api: 'getAirFreightOverrideAdjustment',
          routeParams: { query },
          method: 'get'
          // cancellable: Boolean(searchQuery)
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ conversationSearchSpinner: false }));
          payload.data = response.data;
          const nextOffset = payload.data.pages?.next?.split(',')[0].split('=')[1];
          const prevOffset = payload.data.pages?.prev?.split(',')[0].split('=')[1];
          dispatch(actions.updateAfAdjustmentPageOffset(prevOffset || nextOffset));
          dispatch(actions.updateAirFreightOverrideAdjustmentScreen(payload.data));
          if (callback) {
            callback(response.data);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ conversationSearchSpinner: false }));
          console.log(error);
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  return {
    getAirFreightOverrideAdjustmentResults: getAirFreightOverrideAdjustmentResults(),
    getAirFreightOverrideResults: getAirFreightOverrideResults(),
    updatePendingApproval: updatePendingApproval(),
    fetchAirFreightOverrideSubTable: fetchAirFreightOverrideSubTable(),
    fetchAfAdjustmentNextResultSet: fetchAfAdjustmentNextResultSet(),
    fetchAfAdjustmentPrevResultSet: fetchAfAdjustmentPrevResultSet()
  };
}

export const extraActions = createExtraActions();

export const airFreightOverrideActions = { ...actions, ...extraActions };
export const airFreightOverrideReducer = slice.reducer;
