/**
 * This file is used as Mock data for Conversation Thread.
 * @exports poSearchDataMock
 * @author {Cognizant Technology Solution}
 */

export const poSearchDataMock = {
  data: {
    pages: {
      prev: 'offset=0, count=1000',
      next: 'offset=2000, count=1000',
      totalPages: 60,
      totalResources: 1
    },
    objects: [
      {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '3502648116',
          tradingPoNumber: '8501048529',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Thailand',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '69.57',
          customerShipTo: 'United States'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f2',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '3502648116',
          tradingPoNumber: '1232132152',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Malaysia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '11',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '129.57',
          customerShipTo: 'France'
        },
        sizes: {
          value: 'M',
          quantity: 200,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '2d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '3502648116',
          tradingPoNumber: '752436457',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'India',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '23.71',
          customerShipTo: 'UK'
        },
        sizes: {
          value: 'L',
          quantity: 300,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368g3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '9786585862',
          tradingPoNumber: '132324321',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2019-07-18',
          poEffectiveFrom: '2019-08-20',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Russia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '321.32',
          customerShipTo: 'Canada'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f0',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '3302648116',
          tradingPoNumber: '2501048529',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-05-08',
          poEffectiveFrom: '2020-05-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Thailand',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '69.57',
          customerShipTo: 'United States'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7388f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '4520234321',
          tradingPoNumber: '1232132255',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-09',
          poEffectiveFrom: '2020-06-04',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Malaysia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '129.57',
          customerShipTo: 'France'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a899-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '45453464586',
          tradingPoNumber: '752436466',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2019-06-08',
          poEffectiveFrom: '2019-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'India',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '23.71',
          customerShipTo: 'UK'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c75ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '9786585873',
          tradingPoNumber: '132323324',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2019-06-19',
          poEffectiveFrom: '2019-07-21',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Russia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '321.32',
          customerShipTo: 'Canada'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca3368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '4520234314',
          tradingPoNumber: '1232132115',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Malaysia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '129.57',
          customerShipTo: 'France'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-493a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '42453464576',
          tradingPoNumber: '722436456',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-07-08',
          poEffectiveFrom: '2020-08-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'India',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '23.71',
          customerShipTo: 'UK'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a998-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '3502648216',
          tradingPoNumber: '8501041529',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Thailand',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '69.57',
          customerShipTo: 'United States'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '4520234324',
          tradingPoNumber: '1232132155',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Malaysia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '129.57',
          customerShipTo: 'France'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '45453464576',
          tradingPoNumber: '752436456',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'India',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '23.71',
          customerShipTo: 'UK'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '9786585863',
          tradingPoNumber: '132324324',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2019-06-18',
          poEffectiveFrom: '2019-07-20',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Russia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '321.32',
          customerShipTo: 'Canada'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '3502648116',
          tradingPoNumber: '8501048529',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Thailand',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '69.57',
          customerShipTo: 'United States'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '4520234324',
          tradingPoNumber: '1232132155',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Malaysia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '129.57',
          customerShipTo: 'France'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '45453464576',
          tradingPoNumber: '752436456',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'India',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '23.71',
          customerShipTo: 'UK'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '9786585863',
          tradingPoNumber: '132324324',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2019-06-18',
          poEffectiveFrom: '2019-07-20',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Russia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '321.32',
          customerShipTo: 'Canada'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '4520234324',
          tradingPoNumber: '1232132155',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'Malaysia',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '129.57',
          customerShipTo: 'France'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }, {
        id: '1d9c74ff-3643-494a-a898-ec6cca7368f3',
        resourceType: 'dpoc/purchaseorder',
        links: {
          self: 'dpoc/purchaseorder/v1/1d9c74ff-3643-494a - a898 - ec6cca7368f3'
        },
        tsUpdate: 'tsUpdate_Value',
        poHeader: {
          poNumber: '45453464576',
          tradingPoNumber: '752436456',
          partnerNumber: 'NAC',
          partnerName: 'partnerName_Value',
          poCreatedOn: '2020-06-08',
          poEffectiveFrom: '2020-06-05',
          poDocTypeCode: 'poDocTypeCode_Value',
          purchaseOrganizationNumber: 'purchaseOrganizationNumber_Value',
          purchaseGroupOrganizationNumber: 'purchaseGroupOrganizationNumber_Value',
          titleTransferModelCode: 'T',
          titleTransferModelCodeDescription: 'Territorial Waters',
          manufacturingCountryofOrigin: 'India',
          purchaseOrderCompanyCodeNumber: 'purchaseOrderCompanyCodeNumber_Value',
          tradingCompanyCode: '#',
          referenceDocumentNumber: 'COUNTRY_REGION_PURCHASE_ORDER'
        },
        poLineItem: {
          poLineItemNumber: '10',
          poLineStatus: 'CREATED',
          productCode: 'productCode_Value',
          quantity: '1',
          unitOfMeasure: 'Each',
          ogacDate: '2020-07-09',
          gacDate: '2020-07-09',
          transportationModeCode: 'transportationModeCode_Value',
          shippingType: 'shippingType_Value',
          purchaseOrderFob: 'purchaseOrderFob_Value',
          tradingCompanyFob: 'tradingCompanyFob_Value',
          poFxAdjustment: 'poFxAdjustment_Value',
          tradingCompanyMarkup: 'tradingCompanyMarkup_Value',
          tradingCompanyNetUnitPrice: '468.00',
          totalAmount: '1234567891234.123',
          netUnitPrice: '23.71',
          customerShipTo: 'UK'
        },
        sizes: {
          value: 'S',
          quantity: 100,
          netPrice: 20,
          netPriceCurrency: '$30',
          grossPrice: 'grossPrice_value',
          grossPriceCurrency: 'grossPriceCurrency_value',
          netPriceWithMarkup: 'netPriceWithMarkup_value',
          grossNetPriceMarkupCurrency: 'grossNetPriceMarkupCurrency_value',
          tradingCoNetPrice: 40,
          tradingCoNetPriceCurrency: '$60',
          xFactoryQuantity: 200
        },
        product: {
          categoryCode: '000785',
          subCategoryCode: '005086',
          styleNumber: 'AC1896'
        },
        manufacturing: {
          fobMismatchIndicator: 'fobMismatchIndicator_Value',
          sizeMismatchIndicator: 'sizeMismatchIndicator_Value'
        },
        planning: {
          mrgacDate: 'mrgacDate_Value',
          gacvsmrgac: 'gacvsmrgac_Value',
          ogacvsmrgac: 'ogacvsmrgac_Value',
          mrgacCapacityConsumptionWeek: 'mrgacCapacityConsumptionWeek_Value',
          planningSeason: 'SU'
        },
        logistics: {
          originReceiptSize: 'originReceiptSize_Value'
        },
        salesOrder: {
          customerShipTo: '8807405'
        }
      }
    ],
    filters: [
      {
        filterName: 'status',
        filterValues: [
          {
            filterValue: 'AD',
            filterCount: 5
          }
        ],
        totalFilterCount: 25
      }
    ]
  }
};

export const poReportDataMock = {
  success: true,
  message: 'OK',
  statusCode: 200,
  data: {
    id: '',
    resourceType: '',
    links: {
      self: ''
    },
    pages: {
      next: 'offset=300, count=300',
      prev: '',
      totalPages: 1,
      totalResources: 333
    },
    filters: [
      {
        filterName: 'poHeader.poDocTypeCode',
        filterValues: [
          {
            primary: 'DPCO',
            secondary: 'Nike DPOC Order Document Type Description',
            filterCount: 141
          },
          {
            primary: 'NEW DOC',
            secondary: 'Nike DPOC Order Document Type Description',
            filterCount: 11
          },
          {
            primary: 'NIKE',
            secondary: 'Nike DPOC Order Document Type Description',
            filterCount: 135
          },
          {
            primary: 'ZP20',
            secondary: 'Standard PO',
            filterCount: 41
          },
          {
            primary: 'ZP23',
            secondary: 'Promo PO',
            filterCount: 4
          },
          {
            primary: 'ZP01',
            secondary: 'Nike DPOC',
            filterCount: 1
          }
        ],
        totalFilterCount: 333
      },
      {
        filterName: 'poHeader.purchaseOrganizationNumber',
        filterValues: [
          {
            primary: '1',
            secondary: 'Regional Single FW',
            filterCount: 5
          },
          {
            primary: '2',
            secondary: 'Regional Single FW',
            filterCount: 2
          },
          {
            primary: '3',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '4',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '5',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '6',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '7',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '8',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '9',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '10',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '11',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '12',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '13',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '14',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '15',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '16',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '17',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '18',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '19',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '20',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '21',
            secondary: 'Regional-Multi AP',
            filterCount: 1
          },
          {
            primary: '22',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '23',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '24',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '25',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '26',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '27',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '28',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '29',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '30',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '31',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '32',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '33',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '34',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '35',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '36',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '37',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '38',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '39',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '40',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '41',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '42',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '43',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '44',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '45',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '46',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '47',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '48',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '49',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '50',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '51',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '52',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '53',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '54',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '55',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '56',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '57',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '58',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '59',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '60',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '61',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '62',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '63',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '64',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '65',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '66',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '67',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '68',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '69',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '70',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '71',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '72',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '73',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '74',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '75',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '76',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '77',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '78',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '79',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '80',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '81',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '82',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '83',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '84',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '85',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '86',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '87',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '88',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '89',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '90',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '91',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '92',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '93',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '94',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '95',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '96',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '97',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '98',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '99',
            secondary: 'Regional Single AP',
            filterCount: 2
          },
          {
            primary: '100',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '101',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '102',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '103',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '104',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '105',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '106',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '107',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '108',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '109',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '110',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '111',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '112',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '113',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '114',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '115',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '116',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '117',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '118',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '119',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '120',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '121',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '122',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '123',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '124',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '125',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '126',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '127',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '128',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '129',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '130',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '131',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '132',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '133',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '134',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '135',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '136',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '137',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '138',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '139',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '140',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '141',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '142',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '143',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '144',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '145',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '146',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '147',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '148',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '149',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '150',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '151',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '152',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '153',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '154',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '155',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '156',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '157',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '158',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '159',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '160',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '161',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '162',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '163',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '164',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '165',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '166',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '167',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '168',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '169',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '170',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '171',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '172',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '173',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '174',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '175',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '176',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '177',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '178',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '179',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '180',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '181',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '182',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '183',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '184',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '185',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '186',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '187',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '188',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '189',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '190',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '191',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '192',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '193',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '194',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '195',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '196',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '197',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '198',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '199',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '200',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '201',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '202',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '203',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '204',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '205',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '206',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '207',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '208',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '209',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '210',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '211',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '212',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '213',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '214',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '215',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '216',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '217',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '218',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '219',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '220',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '221',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '222',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '223',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '224',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '225',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '226',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '227',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '228',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '229',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '230',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '231',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '232',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '233',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '234',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '235',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '236',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '237',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '238',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '239',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '240',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '241',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '242',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '243',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '244',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '245',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '246',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '247',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '248',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '249',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '250',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '251',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '252',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '253',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '254',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '255',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '256',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '257',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '258',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '259',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '260',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '261',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '262',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '263',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '264',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '265',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '266',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '267',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '268',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '269',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '270',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '271',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '272',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '273',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '274',
            secondary: 'Regional Single AP',
            filterCount: 1
          },
          {
            primary: '275',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '276',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '277',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '278',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '279',
            secondary: 'Regional Single FW',
            filterCount: 1
          },
          {
            primary: '280',
            secondary: 'Regional-Multi AP',
            filterCount: 3
          },
          {
            primary: '326',
            secondary: 'Regional Single FW',
            filterCount: 5
          },
          {
            primary: '327',
            secondary: 'Regional Single FW',
            filterCount: 5
          },
          {
            primary: '328',
            secondary: 'Regional Single FW',
            filterCount: 5
          },
          {
            primary: '329',
            secondary: 'Regional Single FW',
            filterCount: 5
          },
          {
            primary: '330',
            secondary: 'Regional Single FW',
            filterCount: 5
          },
          {
            primary: '331',
            secondary: 'Regional Single FW',
            filterCount: 4
          },
          {
            primary: '332',
            secondary: 'Regional Single FW',
            filterCount: 5
          },
          {
            primary: '333',
            secondary: 'Regional Single FW',
            filterCount: 6
          },
          {
            primary: '334',
            secondary: 'Regional Single FW',
            filterCount: 5
          }
        ],
        totalFilterCount: 333
      },
      {
        filterName: 'poLineItem.destinationCountry',
        filterValues: [
          {
            primary: 'India',
            secondary: '',
            filterCount: 170
          },
          {
            primary: 'CANADA',
            secondary: '',
            filterCount: 1
          },
          {
            primary: 'CHINA',
            secondary: '',
            filterCount: 6
          },
          {
            primary: 'KOREA',
            secondary: '',
            filterCount: 1
          },
          {
            primary: 'PAKISTAN',
            secondary: '',
            filterCount: 1
          },
          {
            primary: 'US',
            secondary: '',
            filterCount: 59
          },
          {
            primary: 'SOUTH KOREA',
            secondary: '',
            filterCount: 47
          },
          {
            primary: 'USA',
            secondary: '',
            filterCount: 45
          },
          {
            primary: 'China',
            secondary: '',
            filterCount: 1
          },
          {
            primary: 'Taiwan',
            secondary: '',
            filterCount: 1
          },
          {
            primary: 'Hongkong',
            secondary: '',
            filterCount: 1
          }
        ],
        totalFilterCount: 333
      },
      {
        filterName: 'poLineItem.plantCode',
        filterValues: [
          {
            primary: '1012',
            secondary: 'Nike',
            filterCount: 58
          },
          {
            primary: '1014',
            secondary: 'Jay',
            filterCount: 17
          },
          {
            primary: '1015',
            secondary: 'Jay',
            filterCount: 72
          },
          {
            primary: '1022',
            secondary: 'Nike',
            filterCount: 119
          },
          {
            primary: '1024',
            secondary: 'dpoc user',
            filterCount: 1
          },
          {
            primary: '1025',
            secondary: 'jay nike',
            filterCount: 10
          },
          {
            primary: '1034',
            secondary: 'Nike',
            filterCount: 1
          },
          {
            primary: '1035',
            secondary: 'Nike',
            filterCount: 4
          },
          {
            primary: '1039',
            secondary: 'Jay',
            filterCount: 1
          },
          {
            primary: '1041',
            secondary: 'Nike',
            filterCount: 2
          },
          {
            primary: '1042',
            secondary: 'Nike',
            filterCount: 4
          },
          {
            primary: '1051',
            secondary: 'dpoc user',
            filterCount: 4
          },
          {
            primary: '1064',
            secondary: 'Nike',
            filterCount: 24
          },
          {
            primary: '1083',
            secondary: 'blue',
            filterCount: 2
          },
          {
            primary: '1086',
            secondary: 'Nike',
            filterCount: 1
          },
          {
            primary: '1088',
            secondary: 'Nike',
            filterCount: 1
          },
          {
            primary: '1092',
            secondary: 'Nike',
            filterCount: 2
          },
          {
            primary: '1096',
            secondary: 'nike',
            filterCount: 4
          },
          {
            primary: '1097',
            secondary: 'Jay',
            filterCount: 5
          },
          {
            primary: '1098',
            secondary: 'Jay',
            filterCount: 1
          }
        ],
        totalFilterCount: 333
      },
      {
        filterName: 'poLineItem.transportationMode',
        filterValues: [
          {
            primary: 'Cab',
            secondary: '',
            filterCount: 212
          },
          {
            primary: 'Flight',
            secondary: '',
            filterCount: 47
          },
          {
            primary: 'bike',
            secondary: '',
            filterCount: 26
          },
          {
            primary: 'VL',
            secondary: '',
            filterCount: 21
          },
          {
            primary: 'TR',
            secondary: '',
            filterCount: 26
          },
          {
            primary: 'transportationMode_value',
            secondary: '',
            filterCount: 1
          }
        ],
        totalFilterCount: 333
      },
      {
        filterName: 'product.categoryCode',
        filterValues: [
          {
            primary: '1',
            secondary: 'dpoc user category description',
            filterCount: 5
          },
          {
            primary: '2',
            secondary: 'dpoc user category description',
            filterCount: 2
          },
          {
            primary: '3',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '4',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '5',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '6',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '7',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '8',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '9',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '10',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '11',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '12',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '13',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '14',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '15',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '16',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '17',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '18',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '19',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '20',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '21',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '22',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '23',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '24',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '25',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '26',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '27',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '28',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '29',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '30',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '31',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '32',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '33',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '34',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '35',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '36',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '37',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '38',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '39',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '40',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '41',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '42',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '43',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '44',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '45',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '46',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '47',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '48',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '49',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '50',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '51',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '52',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '53',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '54',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '55',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '56',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '57',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '58',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '59',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '60',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '61',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '62',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '63',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '64',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '65',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '66',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '67',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '68',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '69',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '70',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '71',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '72',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '73',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '74',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '75',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '76',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '77',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '78',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '79',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '80',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '81',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '82',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '83',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '84',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '85',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '86',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '87',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '88',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '89',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '90',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '91',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '92',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '93',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '94',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '95',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '96',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '97',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '98',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '99',
            secondary: 'dpoc user category description',
            filterCount: 2
          },
          {
            primary: '100',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '101',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '102',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '103',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '104',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '105',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '106',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '107',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '108',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '109',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '110',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '111',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '112',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '113',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '114',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '115',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '116',
            secondary: 'dpoc user category description',
            filterCount: 1
          },
          {
            primary: '117',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '118',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '119',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '120',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '121',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '122',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '123',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '124',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '125',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '126',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '127',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '128',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '129',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '130',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '131',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '132',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '133',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '134',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '135',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '136',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '137',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '138',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '139',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '140',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '141',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '142',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '143',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '144',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '145',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '146',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '147',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '148',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '149',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '150',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '151',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '152',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '153',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '154',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '155',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '156',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '157',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '158',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '159',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '160',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '161',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '162',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '163',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '164',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '165',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '166',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '167',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '168',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '169',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '170',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '171',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '172',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '173',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '174',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '175',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '176',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '177',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '178',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '179',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '180',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '181',
            secondary: 'po numbers',
            filterCount: 1
          },
          {
            primary: '182',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '183',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '184',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '185',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '186',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '187',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '188',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '189',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '190',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '191',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '192',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '193',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '194',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '195',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '196',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '197',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '198',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '199',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '200',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '201',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '202',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '203',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '204',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '205',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '206',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '207',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '208',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '209',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '210',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '211',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '212',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '213',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '214',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '215',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '216',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '217',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '218',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '219',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '220',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '221',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '222',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '223',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '224',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '225',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '226',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '227',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '228',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '229',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '230',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '231',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '232',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '233',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '234',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '235',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '236',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '237',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '238',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '239',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '240',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '241',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '242',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '243',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '244',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '245',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '246',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '247',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '248',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '249',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '250',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '251',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '252',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '253',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '254',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '255',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '256',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '257',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '258',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '259',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '260',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '261',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '262',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '263',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '264',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '265',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '266',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '267',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '268',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '269',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '270',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '271',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '272',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '273',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '274',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '275',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '276',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '277',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '278',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '279',
            secondary: 'Jay NIkE',
            filterCount: 1
          },
          {
            primary: '280',
            secondary: 'RUNNING',
            filterCount: 3
          },
          {
            primary: '326',
            secondary: 'JORDAN LEGACY',
            filterCount: 1
          },
          {
            primary: '327',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '328',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '329',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '330',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '331',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '332',
            secondary: 'JORDAN LEGACY',
            filterCount: 1
          },
          {
            primary: '333',
            secondary: 'SOCCER',
            filterCount: 1
          },
          {
            primary: '334',
            secondary: 'SOCCER',
            filterCount: 1
          },
          {
            primary: '335',
            secondary: 'JORDAN LEGACY',
            filterCount: 1
          },
          {
            primary: '336',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '337',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '338',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '339',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '340',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '341',
            secondary: 'JORDAN LEGACY',
            filterCount: 2
          },
          {
            primary: '342',
            secondary: 'SOCCER',
            filterCount: 2
          },
          {
            primary: '343',
            secondary: 'SOCCER',
            filterCount: 1
          },
          {
            primary: '344',
            secondary: 'JORDAN LEGACY',
            filterCount: 1
          },
          {
            primary: '345',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '346',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '347',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '348',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '349',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '350',
            secondary: 'JORDAN LEGACY',
            filterCount: 1
          },
          {
            primary: '351',
            secondary: 'SOCCER',
            filterCount: 1
          },
          {
            primary: '352',
            secondary: 'SOCCER',
            filterCount: 1
          },
          {
            primary: '353',
            secondary: 'JORDAN LEGACY',
            filterCount: 1
          },
          {
            primary: '354',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '355',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '356',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '357',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '358',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '359',
            secondary: 'JORDAN LEGACY',
            filterCount: 1
          },
          {
            primary: '360',
            secondary: 'SOCCER',
            filterCount: 1
          },
          {
            primary: '361',
            secondary: 'SOCCER',
            filterCount: 1
          },
          {
            primary: '362',
            secondary: 'JORDAN LEGACY',
            filterCount: 1
          },
          {
            primary: '363',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '364',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '365',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '366',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '367',
            secondary: 'TENNIS',
            filterCount: 1
          },
          {
            primary: '368',
            secondary: 'JORDAN LEGACY',
            filterCount: 1
          }
        ],
        totalFilterCount: 333
      },
      {
        filterName: 'manufacturing.fobMismatchIndicator',
        filterValues: [
          {
            primary: 'YES',
            secondary: '',
            filterCount: 172
          },
          {
            primary: 'NO',
            secondary: '',
            filterCount: 160
          },
          {
            primary: 'true',
            secondary: '',
            filterCount: 1
          }
        ],
        totalFilterCount: 333
      },
      {
        filterName: 'manufacturing.sizeMismatchIndicator',
        filterValues: [
          {
            primary: '1',
            secondary: '',
            filterCount: 4
          },
          {
            primary: 'YES',
            secondary: '',
            filterCount: 169
          },
          {
            primary: 'NO',
            secondary: '',
            filterCount: 160
          }
        ],
        totalFilterCount: 333
      }
    ],
    objects: [
      {
        'poHeader.poNumber': '4507260131',
        'poHeader.tradingPoNumber': '5803254117',
        'poHeader.partnerNumber': 'A22',
        'poHeader.partnerName': ' WACOAL-VIETNAM',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '162',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '162',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '162',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '162',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '162',
        'poLineItem.quantity': 162,
        'poLineItem.unitOfMeasure': '162',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254117,
        'poLineItem.poFxAdjustment': 1.62,
        'poLineItem.totalAmount': '162',
        'poLineItem.tradingCompanyMarkup': 1.62,
        'poLineItem.netUnitPrice': 1619839,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B82',
        'poLineItem.productCode': '162',
        'product.categoryCode': '162',
        'product.categoryDescription': 'po numbers',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'po numbers',
        'product.styleNumber': '162',
        'planning.mrgacDate': '2020-12-22T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B82',
        'planning.ogacvsmrgac': 'B82',
        'planning.mrgacCapacityConsumptionWeek': '162',
        'planning.planningSeason': 'NIKE A162',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '162',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260131-162',
        tsUpdate: 1604319348
      },
      {
        'poHeader.poNumber': '4507260131',
        'poHeader.tradingPoNumber': '5803254117',
        'poHeader.partnerNumber': 'A21',
        'poHeader.partnerName': ' WACOAL-JAPAN',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '161',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '161',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '161',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '161',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '161',
        'poLineItem.quantity': 161,
        'poLineItem.unitOfMeasure': '161',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254117,
        'poLineItem.poFxAdjustment': 1.61,
        'poLineItem.totalAmount': '161',
        'poLineItem.tradingCompanyMarkup': 1.61,
        'poLineItem.netUnitPrice': 1609840,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A81',
        'poLineItem.productCode': '161',
        'product.categoryCode': '161',
        'product.categoryDescription': 'po numbers',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'po numbers',
        'product.styleNumber': '161',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A81',
        'planning.ogacvsmrgac': 'A81',
        'planning.mrgacCapacityConsumptionWeek': '161',
        'planning.planningSeason': 'NIKE A161',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '161',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260131-161',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260131',
        'poHeader.tradingPoNumber': '5803254117',
        'poHeader.partnerNumber': 'A04',
        'poHeader.partnerName': ' MARUBENI-SONGGANG',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '160',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '160',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '160',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '160',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '160',
        'poLineItem.quantity': 160,
        'poLineItem.unitOfMeasure': '160',
        'poLineItem.ogacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254117,
        'poLineItem.poFxAdjustment': 1.6,
        'poLineItem.totalAmount': '160',
        'poLineItem.tradingCompanyMarkup': 1.6,
        'poLineItem.netUnitPrice': 1599841,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B81',
        'poLineItem.productCode': '160',
        'product.categoryCode': '160',
        'product.categoryDescription': 'po numbers',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'po numbers',
        'product.styleNumber': '160',
        'planning.mrgacDate': '2021-01-05T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B81',
        'planning.ogacvsmrgac': 'B81',
        'planning.mrgacCapacityConsumptionWeek': '160',
        'planning.planningSeason': 'NIKE A160',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '160',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260131-160',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260130',
        'poHeader.tradingPoNumber': '5803254118',
        'poHeader.partnerNumber': 'ZTX',
        'poHeader.partnerName': ' ZENTRIX INDUSTRIES LIMITED',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '159',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '159',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '159',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '159',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '159',
        'poLineItem.quantity': 159,
        'poLineItem.unitOfMeasure': '159',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254118,
        'poLineItem.poFxAdjustment': 1.59,
        'poLineItem.totalAmount': '159',
        'poLineItem.tradingCompanyMarkup': 1.59,
        'poLineItem.netUnitPrice': 1589842,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A80',
        'poLineItem.productCode': '159',
        'product.categoryCode': '159',
        'product.categoryDescription': 'po numbers',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'po numbers',
        'product.styleNumber': '159',
        'planning.mrgacDate': '2020-12-22T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A80',
        'planning.ogacvsmrgac': 'A80',
        'planning.mrgacCapacityConsumptionWeek': '159',
        'planning.planningSeason': 'NIKE A159',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '159',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260130-159',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260130',
        'poHeader.tradingPoNumber': '5803254118',
        'poHeader.partnerNumber': 'ZCK',
        'poHeader.partnerName': ' ZHONGSHAN',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '158',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '158',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '158',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '158',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '158',
        'poLineItem.quantity': 158,
        'poLineItem.unitOfMeasure': '158',
        'poLineItem.ogacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254118,
        'poLineItem.poFxAdjustment': 1.58,
        'poLineItem.totalAmount': '158',
        'poLineItem.tradingCompanyMarkup': 1.58,
        'poLineItem.netUnitPrice': 1579843,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B80',
        'poLineItem.productCode': '158',
        'product.categoryCode': '158',
        'product.categoryDescription': 'po numbers',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'po numbers',
        'product.styleNumber': '158',
        'planning.mrgacDate': '2021-01-05T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B80',
        'planning.ogacvsmrgac': 'B80',
        'planning.mrgacCapacityConsumptionWeek': '158',
        'planning.planningSeason': 'NIKE A158',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '158',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260130-158',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260129',
        'poHeader.tradingPoNumber': '5803254119',
        'poHeader.partnerNumber': 'XK',
        'poHeader.partnerName': ' SALMOND TRADING LIMITED',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '155',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '155',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '155',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '155',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '155',
        'poLineItem.quantity': 155,
        'poLineItem.unitOfMeasure': '155',
        'poLineItem.ogacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254119,
        'poLineItem.poFxAdjustment': 1.55,
        'poLineItem.totalAmount': '155',
        'poLineItem.tradingCompanyMarkup': 1.55,
        'poLineItem.netUnitPrice': 1549846,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A78',
        'poLineItem.productCode': '155',
        'product.categoryCode': '155',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '155',
        'planning.mrgacDate': '2021-01-05T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A78',
        'planning.ogacvsmrgac': 'A78',
        'planning.mrgacCapacityConsumptionWeek': '155',
        'planning.planningSeason': 'NIKE A155',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '155',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260129-155',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260129',
        'poHeader.tradingPoNumber': '5803254119',
        'poHeader.partnerNumber': 'YSN',
        'poHeader.partnerName': ' YU SHIN',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '157',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '157',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '157',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '157',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '157',
        'poLineItem.quantity': 157,
        'poLineItem.unitOfMeasure': '157',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254119,
        'poLineItem.poFxAdjustment': 1.57,
        'poLineItem.totalAmount': '157',
        'poLineItem.tradingCompanyMarkup': 1.57,
        'poLineItem.netUnitPrice': 1569844,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A79',
        'poLineItem.productCode': '157',
        'product.categoryCode': '157',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '157',
        'planning.mrgacDate': '2020-12-22T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A79',
        'planning.ogacvsmrgac': 'A79',
        'planning.mrgacCapacityConsumptionWeek': '157',
        'planning.planningSeason': 'NIKE A157',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '157',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260129-157',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260129',
        'poHeader.tradingPoNumber': '5803254119',
        'poHeader.partnerNumber': 'YPV',
        'poHeader.partnerName': ' YUPOONG VIETNAM LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '156',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '156',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '156',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '156',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '156',
        'poLineItem.quantity': 156,
        'poLineItem.unitOfMeasure': '156',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254119,
        'poLineItem.poFxAdjustment': 1.56,
        'poLineItem.totalAmount': '156',
        'poLineItem.tradingCompanyMarkup': 1.56,
        'poLineItem.netUnitPrice': 1559845,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B79',
        'poLineItem.productCode': '156',
        'product.categoryCode': '156',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '156',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B79',
        'planning.ogacvsmrgac': 'B79',
        'planning.mrgacCapacityConsumptionWeek': '156',
        'planning.planningSeason': 'NIKE A156',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '156',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260129-156',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260128',
        'poHeader.tradingPoNumber': '5803254153',
        'poHeader.partnerNumber': 'WNC',
        'poHeader.partnerName': ' WIN HANVERKY LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '153',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '153',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '153',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '153',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '153',
        'poLineItem.quantity': 153,
        'poLineItem.unitOfMeasure': '153',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254153,
        'poLineItem.poFxAdjustment': 1.53,
        'poLineItem.totalAmount': '153',
        'poLineItem.tradingCompanyMarkup': 1.53,
        'poLineItem.netUnitPrice': 1529848,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A77',
        'poLineItem.productCode': '153',
        'product.categoryCode': '153',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '153',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A77',
        'planning.ogacvsmrgac': 'A77',
        'planning.mrgacCapacityConsumptionWeek': '153',
        'planning.planningSeason': 'NIKE A153',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '153',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260128-153',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260128',
        'poHeader.tradingPoNumber': '5803254153',
        'poHeader.partnerNumber': 'XG',
        'poHeader.partnerName': ' EVA OVERSEAS INTERNATIONAL LIMITED',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '154',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '154',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '154',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '154',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '154',
        'poLineItem.quantity': 154,
        'poLineItem.unitOfMeasure': '154',
        'poLineItem.ogacDate': '2020-11-11T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-11T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254153,
        'poLineItem.poFxAdjustment': 1.54,
        'poLineItem.totalAmount': '154',
        'poLineItem.tradingCompanyMarkup': 1.54,
        'poLineItem.netUnitPrice': 1539847,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B78',
        'poLineItem.productCode': '154',
        'product.categoryCode': '154',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '154',
        'planning.mrgacDate': '2020-12-15T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B78',
        'planning.ogacvsmrgac': 'B78',
        'planning.mrgacCapacityConsumptionWeek': '154',
        'planning.planningSeason': 'NIKE A154',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '154',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260128-154',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260128',
        'poHeader.tradingPoNumber': '5803254153',
        'poHeader.partnerNumber': 'WDA',
        'poHeader.partnerName': ' SUPERCAP INDUSTRIAL CO LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '152',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '152',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '152',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '152',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '152',
        'poLineItem.quantity': 152,
        'poLineItem.unitOfMeasure': '152',
        'poLineItem.ogacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254153,
        'poLineItem.poFxAdjustment': 1.52,
        'poLineItem.totalAmount': '152',
        'poLineItem.tradingCompanyMarkup': 1.52,
        'poLineItem.netUnitPrice': 1519849,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B77',
        'poLineItem.productCode': '152',
        'product.categoryCode': '152',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '152',
        'planning.mrgacDate': '2021-01-05T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B77',
        'planning.ogacvsmrgac': 'B77',
        'planning.mrgacCapacityConsumptionWeek': '152',
        'planning.planningSeason': 'NIKE A152',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '152',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260128-152',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260127',
        'poHeader.tradingPoNumber': '5803254120',
        'poHeader.partnerNumber': 'VNT',
        'poHeader.partnerName': ' VANTAGE',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '151',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '151',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '151',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '151',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '151',
        'poLineItem.quantity': 151,
        'poLineItem.unitOfMeasure': '151',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254120,
        'poLineItem.poFxAdjustment': 1.51,
        'poLineItem.totalAmount': '151',
        'poLineItem.tradingCompanyMarkup': 1.51,
        'poLineItem.netUnitPrice': 1509850,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A76',
        'poLineItem.productCode': '151',
        'product.categoryCode': '151',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '151',
        'planning.mrgacDate': '2020-12-22T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A76',
        'planning.ogacvsmrgac': 'A76',
        'planning.mrgacCapacityConsumptionWeek': '151',
        'planning.planningSeason': 'NIKE A151',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '151',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260127-151',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260127',
        'poHeader.tradingPoNumber': '5803254120',
        'poHeader.partnerNumber': 'VMR',
        'poHeader.partnerName': ' EVENT MARKETING GROUP',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '150',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '150',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '150',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '150',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '150',
        'poLineItem.quantity': 150,
        'poLineItem.unitOfMeasure': '150',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254120,
        'poLineItem.poFxAdjustment': 1.5,
        'poLineItem.totalAmount': '150',
        'poLineItem.tradingCompanyMarkup': 1.5,
        'poLineItem.netUnitPrice': 1499851,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B76',
        'poLineItem.productCode': '150',
        'product.categoryCode': '150',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '150',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B76',
        'planning.ogacvsmrgac': 'B76',
        'planning.mrgacCapacityConsumptionWeek': '150',
        'planning.planningSeason': 'NIKE A150',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '150',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260127-150',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260127',
        'poHeader.tradingPoNumber': '5803254120',
        'poHeader.partnerNumber': 'UGV',
        'poHeader.partnerName': ' UPGAIN VIETNAM MANUFACTURING',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '149',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '149',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '149',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '149',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '149',
        'poLineItem.quantity': 149,
        'poLineItem.unitOfMeasure': '149',
        'poLineItem.ogacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254120,
        'poLineItem.poFxAdjustment': 1.49,
        'poLineItem.totalAmount': '149',
        'poLineItem.tradingCompanyMarkup': 1.49,
        'poLineItem.netUnitPrice': 1489852,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A75',
        'poLineItem.productCode': '149',
        'product.categoryCode': '149',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '149',
        'planning.mrgacDate': '2021-01-05T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A75',
        'planning.ogacvsmrgac': 'A75',
        'planning.mrgacCapacityConsumptionWeek': '149',
        'planning.planningSeason': 'NIKE A149',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '149',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260127-149',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260126',
        'poHeader.tradingPoNumber': '5803254121',
        'poHeader.partnerNumber': 'TGL',
        'poHeader.partnerName': ' LATITUDES',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '147',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '147',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '147',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '147',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '147',
        'poLineItem.quantity': 147,
        'poLineItem.unitOfMeasure': '147',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254121,
        'poLineItem.poFxAdjustment': 1.47,
        'poLineItem.totalAmount': '147',
        'poLineItem.tradingCompanyMarkup': 1.47,
        'poLineItem.netUnitPrice': 1469854,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A74',
        'poLineItem.productCode': '147',
        'product.categoryCode': '147',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '147',
        'planning.mrgacDate': '2021-01-15T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A74',
        'planning.ogacvsmrgac': 'A74',
        'planning.mrgacCapacityConsumptionWeek': '147',
        'planning.planningSeason': 'NIKE A147',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '147',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260126-147',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260126',
        'poHeader.tradingPoNumber': '5803254121',
        'poHeader.partnerNumber': 'SFI',
        'poHeader.partnerName': ' SOFI CLASSIC SA DE CV',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '145',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '145',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '145',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '145',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '145',
        'poLineItem.quantity': 145,
        'poLineItem.unitOfMeasure': '145',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254121,
        'poLineItem.poFxAdjustment': 1.45,
        'poLineItem.totalAmount': '145',
        'poLineItem.tradingCompanyMarkup': 1.45,
        'poLineItem.netUnitPrice': 1449856,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A73',
        'poLineItem.productCode': '145',
        'product.categoryCode': '145',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '145',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A73',
        'planning.ogacvsmrgac': 'A73',
        'planning.mrgacCapacityConsumptionWeek': '145',
        'planning.planningSeason': 'NIKE A145',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '145',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260126-145',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260126',
        'poHeader.tradingPoNumber': '5803254121',
        'poHeader.partnerNumber': 'UFG',
        'poHeader.partnerName': ' UNION FRIEND GARMENT',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '148',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '148',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '148',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '148',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '148',
        'poLineItem.quantity': 148,
        'poLineItem.unitOfMeasure': '148',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254121,
        'poLineItem.poFxAdjustment': 1.48,
        'poLineItem.totalAmount': '148',
        'poLineItem.tradingCompanyMarkup': 1.48,
        'poLineItem.netUnitPrice': 1479853,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B75',
        'poLineItem.productCode': '148',
        'product.categoryCode': '148',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '148',
        'planning.mrgacDate': '2021-01-15T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B75',
        'planning.ogacvsmrgac': 'B75',
        'planning.mrgacCapacityConsumptionWeek': '148',
        'planning.planningSeason': 'NIKE A148',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '148',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260126-148',
        tsUpdate: 1604319347
      },
      {
        'poHeader.poNumber': '4507260126',
        'poHeader.tradingPoNumber': '5803254121',
        'poHeader.partnerNumber': 'TGH',
        'poHeader.partnerName': ' NEW HOLLAND APPAREL',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '146',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '146',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '146',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '146',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '146',
        'poLineItem.quantity': 146,
        'poLineItem.unitOfMeasure': '146',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254121,
        'poLineItem.poFxAdjustment': 1.46,
        'poLineItem.totalAmount': '146',
        'poLineItem.tradingCompanyMarkup': 1.46,
        'poLineItem.netUnitPrice': 1459855,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B74',
        'poLineItem.productCode': '146',
        'product.categoryCode': '146',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '146',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B74',
        'planning.ogacvsmrgac': 'B74',
        'planning.mrgacCapacityConsumptionWeek': '146',
        'planning.planningSeason': 'NIKE A146',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '146',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260126-146',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260125',
        'poHeader.tradingPoNumber': '5803254122',
        'poHeader.partnerNumber': 'SCI',
        'poHeader.partnerName': ' SPORTS CITY INTERNATIONAL INC',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '144',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '144',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '144',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '144',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '144',
        'poLineItem.quantity': 144,
        'poLineItem.unitOfMeasure': '144',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254122,
        'poLineItem.poFxAdjustment': 1.44,
        'poLineItem.totalAmount': '144',
        'poLineItem.tradingCompanyMarkup': 1.44,
        'poLineItem.netUnitPrice': 1439857,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B73',
        'poLineItem.productCode': '144',
        'product.categoryCode': '144',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '144',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B73',
        'planning.ogacvsmrgac': 'B73',
        'planning.mrgacCapacityConsumptionWeek': '144',
        'planning.planningSeason': 'NIKE A144',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '144',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260125-144',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260125',
        'poHeader.tradingPoNumber': '5803254122',
        'poHeader.partnerNumber': 'RCT',
        'poHeader.partnerName': ' RANIK GROUP  TOP CROWN CO LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '143',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '143',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '143',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '143',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '143',
        'poLineItem.quantity': 143,
        'poLineItem.unitOfMeasure': '143',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254122,
        'poLineItem.poFxAdjustment': 1.43,
        'poLineItem.totalAmount': '143',
        'poLineItem.tradingCompanyMarkup': 1.43,
        'poLineItem.netUnitPrice': 1429858,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A72',
        'poLineItem.productCode': '143',
        'product.categoryCode': '143',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '143',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A72',
        'planning.ogacvsmrgac': 'A72',
        'planning.mrgacCapacityConsumptionWeek': '143',
        'planning.planningSeason': 'NIKE A143',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '143',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260125-143',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260125',
        'poHeader.tradingPoNumber': '5803254122',
        'poHeader.partnerNumber': 'RCM',
        'poHeader.partnerName': ' ROCKMAUI',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '142',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '142',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '142',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '142',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '142',
        'poLineItem.quantity': 142,
        'poLineItem.unitOfMeasure': '142',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254122,
        'poLineItem.poFxAdjustment': 1.42,
        'poLineItem.totalAmount': '142',
        'poLineItem.tradingCompanyMarkup': 1.42,
        'poLineItem.netUnitPrice': 1419859,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B72',
        'poLineItem.productCode': '142',
        'product.categoryCode': '142',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '142',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B72',
        'planning.ogacvsmrgac': 'B72',
        'planning.mrgacCapacityConsumptionWeek': '142',
        'planning.planningSeason': 'NIKE A142',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '142',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260125-142',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260124',
        'poHeader.tradingPoNumber': '5803254160',
        'poHeader.partnerNumber': 'PCP',
        'poHeader.partnerName': ' PROMOTORA COMERCIALPANTALONERA',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '139',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '139',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '139',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '139',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '139',
        'poLineItem.quantity': 139,
        'poLineItem.unitOfMeasure': '139',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254160,
        'poLineItem.poFxAdjustment': 1.39,
        'poLineItem.totalAmount': '139',
        'poLineItem.tradingCompanyMarkup': 1.39,
        'poLineItem.netUnitPrice': 1389862,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A70',
        'poLineItem.productCode': '139',
        'product.categoryCode': '139',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '139',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A70',
        'planning.ogacvsmrgac': 'A70',
        'planning.mrgacCapacityConsumptionWeek': '139',
        'planning.planningSeason': 'NIKE A139',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '139',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260124-139',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260124',
        'poHeader.tradingPoNumber': '5803254160',
        'poHeader.partnerNumber': 'QQ',
        'poHeader.partnerName': ' Great Ascent Trading Limited',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '141',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '141',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '141',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '141',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '141',
        'poLineItem.quantity': 141,
        'poLineItem.unitOfMeasure': '141',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254160,
        'poLineItem.poFxAdjustment': 1.41,
        'poLineItem.totalAmount': '141',
        'poLineItem.tradingCompanyMarkup': 1.41,
        'poLineItem.netUnitPrice': 1409860,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A71',
        'poLineItem.productCode': '141',
        'product.categoryCode': '141',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '141',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A71',
        'planning.ogacvsmrgac': 'A71',
        'planning.mrgacCapacityConsumptionWeek': '141',
        'planning.planningSeason': 'NIKE A141',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '141',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260124-141',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260124',
        'poHeader.tradingPoNumber': '5803254160',
        'poHeader.partnerNumber': 'QM',
        'poHeader.partnerName': ' Mega International Trading Limited',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '140',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '140',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '140',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '140',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '140',
        'poLineItem.quantity': 140,
        'poLineItem.unitOfMeasure': '140',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254160,
        'poLineItem.poFxAdjustment': 1.4,
        'poLineItem.totalAmount': '140',
        'poLineItem.tradingCompanyMarkup': 1.4,
        'poLineItem.netUnitPrice': 1399861,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B71',
        'poLineItem.productCode': '140',
        'product.categoryCode': '140',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '140',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B71',
        'planning.ogacvsmrgac': 'B71',
        'planning.mrgacCapacityConsumptionWeek': '140',
        'planning.planningSeason': 'NIKE A140',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '140',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260124-140',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260122',
        'poHeader.tradingPoNumber': '5803254161',
        'poHeader.partnerNumber': 'PC',
        'poHeader.partnerName': ' Yue Yuen Industrial Limited',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '138',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '138',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '138',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '138',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '138',
        'poLineItem.quantity': 138,
        'poLineItem.unitOfMeasure': '138',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254161,
        'poLineItem.poFxAdjustment': 1.38,
        'poLineItem.totalAmount': '138',
        'poLineItem.tradingCompanyMarkup': 1.38,
        'poLineItem.netUnitPrice': 1379863,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B70',
        'poLineItem.productCode': '138',
        'product.categoryCode': '138',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '138',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B70',
        'planning.ogacvsmrgac': 'B70',
        'planning.mrgacCapacityConsumptionWeek': '138',
        'planning.planningSeason': 'NIKE A138',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '138',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260122-138',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260122',
        'poHeader.tradingPoNumber': '5803254161',
        'poHeader.partnerNumber': 'ORI',
        'poHeader.partnerName': ' SHANGHAI DOLPHIN TEXTILE PRODUCT CO',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '137',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '137',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '137',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '137',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '137',
        'poLineItem.quantity': 137,
        'poLineItem.unitOfMeasure': '137',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254161,
        'poLineItem.poFxAdjustment': 1.37,
        'poLineItem.totalAmount': '137',
        'poLineItem.tradingCompanyMarkup': 1.37,
        'poLineItem.netUnitPrice': 1369864,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A69',
        'poLineItem.productCode': '137',
        'product.categoryCode': '137',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '137',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A69',
        'planning.ogacvsmrgac': 'A69',
        'planning.mrgacCapacityConsumptionWeek': '137',
        'planning.planningSeason': 'NIKE A137',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '137',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260122-137',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260122',
        'poHeader.tradingPoNumber': '5803254161',
        'poHeader.partnerNumber': 'OPT',
        'poHeader.partnerName': ' OPTECH',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '136',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '136',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '136',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '136',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '136',
        'poLineItem.quantity': 136,
        'poLineItem.unitOfMeasure': '136',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254161,
        'poLineItem.poFxAdjustment': 1.36,
        'poLineItem.totalAmount': '136',
        'poLineItem.tradingCompanyMarkup': 1.36,
        'poLineItem.netUnitPrice': 1359865,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B69',
        'poLineItem.productCode': '136',
        'product.categoryCode': '136',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '136',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B69',
        'planning.ogacvsmrgac': 'B69',
        'planning.mrgacCapacityConsumptionWeek': '136',
        'planning.planningSeason': 'NIKE A136',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '136',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260122-136',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260122',
        'poHeader.tradingPoNumber': '5803254161',
        'poHeader.partnerNumber': 'NIG',
        'poHeader.partnerName': ' SHENZHOU INTERNATIONAL GROUP',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '135',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '135',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '135',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '135',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '135',
        'poLineItem.quantity': 135,
        'poLineItem.unitOfMeasure': '135',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254161,
        'poLineItem.poFxAdjustment': 1.35,
        'poLineItem.totalAmount': '135',
        'poLineItem.tradingCompanyMarkup': 1.35,
        'poLineItem.netUnitPrice': 1349866,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A68',
        'poLineItem.productCode': '135',
        'product.categoryCode': '135',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '135',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A68',
        'planning.ogacvsmrgac': 'A68',
        'planning.mrgacCapacityConsumptionWeek': '135',
        'planning.planningSeason': 'NIKE A135',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '135',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260122-135',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260121',
        'poHeader.tradingPoNumber': '5803254162',
        'poHeader.partnerNumber': 'NAL',
        'poHeader.partnerName': ' ESFERA',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '134',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '134',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '134',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '134',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '134',
        'poLineItem.quantity': 134,
        'poLineItem.unitOfMeasure': '134',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254162,
        'poLineItem.poFxAdjustment': 1.34,
        'poLineItem.totalAmount': '134',
        'poLineItem.tradingCompanyMarkup': 1.34,
        'poLineItem.netUnitPrice': 1339867,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B68',
        'poLineItem.productCode': '134',
        'product.categoryCode': '134',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '134',
        'planning.mrgacDate': '2021-01-12T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B68',
        'planning.ogacvsmrgac': 'B68',
        'planning.mrgacCapacityConsumptionWeek': '134',
        'planning.planningSeason': 'NIKE A134',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '134',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260121-134',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260121',
        'poHeader.tradingPoNumber': '5803254162',
        'poHeader.partnerNumber': 'MOV',
        'poHeader.partnerName': ' MONVISO KNITWEAR',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '133',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '133',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '133',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '133',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '133',
        'poLineItem.quantity': 133,
        'poLineItem.unitOfMeasure': '133',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254162,
        'poLineItem.poFxAdjustment': 1.33,
        'poLineItem.totalAmount': '133',
        'poLineItem.tradingCompanyMarkup': 1.33,
        'poLineItem.netUnitPrice': 1329868,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A67',
        'poLineItem.productCode': '133',
        'product.categoryCode': '133',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '133',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A67',
        'planning.ogacvsmrgac': 'A67',
        'planning.mrgacCapacityConsumptionWeek': '133',
        'planning.planningSeason': 'NIKE A133',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '133',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260121-133',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260121',
        'poHeader.tradingPoNumber': '5803254162',
        'poHeader.partnerNumber': 'MHC',
        'poHeader.partnerName': ' MALHARIA CRISTINA LTDA',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '132',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '132',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '132',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '132',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '132',
        'poLineItem.quantity': 132,
        'poLineItem.unitOfMeasure': '132',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254162,
        'poLineItem.poFxAdjustment': 1.32,
        'poLineItem.totalAmount': '132',
        'poLineItem.tradingCompanyMarkup': 1.32,
        'poLineItem.netUnitPrice': 1319869,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B67',
        'poLineItem.productCode': '132',
        'product.categoryCode': '132',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '132',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B67',
        'planning.ogacvsmrgac': 'B67',
        'planning.mrgacCapacityConsumptionWeek': '132',
        'planning.planningSeason': 'NIKE A132',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '132',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260121-132',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260120',
        'poHeader.tradingPoNumber': '5803254163',
        'poHeader.partnerNumber': 'LCL',
        'poHeader.partnerName': ' LIAND',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '131',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '131',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '131',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '131',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '131',
        'poLineItem.quantity': 131,
        'poLineItem.unitOfMeasure': '131',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254163,
        'poLineItem.poFxAdjustment': 1.31,
        'poLineItem.totalAmount': '131',
        'poLineItem.tradingCompanyMarkup': 1.31,
        'poLineItem.netUnitPrice': 1309870,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A66',
        'poLineItem.productCode': '131',
        'product.categoryCode': '131',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '131',
        'planning.mrgacDate': '2021-01-15T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A66',
        'planning.ogacvsmrgac': 'A66',
        'planning.mrgacCapacityConsumptionWeek': '131',
        'planning.planningSeason': 'NIKE A131',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '131',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260120-131',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260120',
        'poHeader.tradingPoNumber': '5803254163',
        'poHeader.partnerNumber': 'LAF',
        'poHeader.partnerName': ' LATITUDES',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '130',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '130',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '130',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '130',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '130',
        'poLineItem.quantity': 130,
        'poLineItem.unitOfMeasure': '130',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254163,
        'poLineItem.poFxAdjustment': 1.3,
        'poLineItem.totalAmount': '130',
        'poLineItem.tradingCompanyMarkup': 1.3,
        'poLineItem.netUnitPrice': 1299871,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B66',
        'poLineItem.productCode': '130',
        'product.categoryCode': '130',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '130',
        'planning.mrgacDate': '2021-01-15T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B66',
        'planning.ogacvsmrgac': 'B66',
        'planning.mrgacCapacityConsumptionWeek': '130',
        'planning.planningSeason': 'NIKE A130',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '130',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260120-130',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260120',
        'poHeader.tradingPoNumber': '5803254163',
        'poHeader.partnerNumber': 'KKC',
        'poHeader.partnerName': ' JIN MEI KNITWEAR',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '129',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '129',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '129',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '129',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '129',
        'poLineItem.quantity': 129,
        'poLineItem.unitOfMeasure': '129',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254163,
        'poLineItem.poFxAdjustment': 1.29,
        'poLineItem.totalAmount': '129',
        'poLineItem.tradingCompanyMarkup': 1.29,
        'poLineItem.netUnitPrice': 1289872,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A65',
        'poLineItem.productCode': '129',
        'product.categoryCode': '129',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '129',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A65',
        'planning.ogacvsmrgac': 'A65',
        'planning.mrgacCapacityConsumptionWeek': '129',
        'planning.planningSeason': 'NIKE A129',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '129',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260120-129',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260120',
        'poHeader.tradingPoNumber': '5803254163',
        'poHeader.partnerNumber': 'KEL',
        'poHeader.partnerName': ' KELLWOOD COMPANY',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '128',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '128',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '128',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '128',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '128',
        'poLineItem.quantity': 128,
        'poLineItem.unitOfMeasure': '128',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254163,
        'poLineItem.poFxAdjustment': 1.28,
        'poLineItem.totalAmount': '128',
        'poLineItem.tradingCompanyMarkup': 1.28,
        'poLineItem.netUnitPrice': 1279873,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B65',
        'poLineItem.productCode': '128',
        'product.categoryCode': '128',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '128',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B65',
        'planning.ogacvsmrgac': 'B65',
        'planning.mrgacCapacityConsumptionWeek': '128',
        'planning.planningSeason': 'NIKE A128',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '128',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260120-128',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260119',
        'poHeader.tradingPoNumber': '5803254164',
        'poHeader.partnerNumber': 'JPT',
        'poHeader.partnerName': ' JOHN PETER',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '127',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '127',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '127',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '127',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '127',
        'poLineItem.quantity': 127,
        'poLineItem.unitOfMeasure': '127',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254164,
        'poLineItem.poFxAdjustment': 1.27,
        'poLineItem.totalAmount': '127',
        'poLineItem.tradingCompanyMarkup': 1.27,
        'poLineItem.netUnitPrice': 1269874,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A64',
        'poLineItem.productCode': '127',
        'product.categoryCode': '127',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '127',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A64',
        'planning.ogacvsmrgac': 'A64',
        'planning.mrgacCapacityConsumptionWeek': '127',
        'planning.planningSeason': 'NIKE A127',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '127',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260119-127',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260119',
        'poHeader.tradingPoNumber': '5803254164',
        'poHeader.partnerNumber': 'JON',
        'poHeader.partnerName': ' JOHNSON SPORTSWEAR LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '126',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '126',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '126',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '126',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '126',
        'poLineItem.quantity': 126,
        'poLineItem.unitOfMeasure': '126',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254164,
        'poLineItem.poFxAdjustment': 1.26,
        'poLineItem.totalAmount': '126',
        'poLineItem.tradingCompanyMarkup': 1.26,
        'poLineItem.netUnitPrice': 1259875,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B64',
        'poLineItem.productCode': '126',
        'product.categoryCode': '126',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '126',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B64',
        'planning.ogacvsmrgac': 'B64',
        'planning.mrgacCapacityConsumptionWeek': '126',
        'planning.planningSeason': 'NIKE A126',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '126',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260119-126',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260119',
        'poHeader.tradingPoNumber': '5803254164',
        'poHeader.partnerNumber': 'IPS',
        'poHeader.partnerName': ' IMPACT SPORTS',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '125',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '125',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '125',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '125',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '125',
        'poLineItem.quantity': 125,
        'poLineItem.unitOfMeasure': '125',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254164,
        'poLineItem.poFxAdjustment': 1.25,
        'poLineItem.totalAmount': '125',
        'poLineItem.tradingCompanyMarkup': 1.25,
        'poLineItem.netUnitPrice': 1249876,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A63',
        'poLineItem.productCode': '125',
        'product.categoryCode': '125',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '125',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A63',
        'planning.ogacvsmrgac': 'A63',
        'planning.mrgacCapacityConsumptionWeek': '125',
        'planning.planningSeason': 'NIKE A125',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '125',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260119-125',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260118',
        'poHeader.tradingPoNumber': '5803254165',
        'poHeader.partnerNumber': 'IM',
        'poHeader.partnerName': ' Mega International Trading Limited',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '124',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '124',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '124',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '124',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '124',
        'poLineItem.quantity': 124,
        'poLineItem.unitOfMeasure': '124',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254165,
        'poLineItem.poFxAdjustment': 1.24,
        'poLineItem.totalAmount': '124',
        'poLineItem.tradingCompanyMarkup': 1.24,
        'poLineItem.netUnitPrice': 1239877,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B63',
        'poLineItem.productCode': '124',
        'product.categoryCode': '124',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '124',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B63',
        'planning.ogacvsmrgac': 'B63',
        'planning.mrgacCapacityConsumptionWeek': '124',
        'planning.planningSeason': 'NIKE A124',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '124',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260118-124',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260118',
        'poHeader.tradingPoNumber': '5803254165',
        'poHeader.partnerNumber': 'HIN',
        'poHeader.partnerName': ' HINDUJA SPORTSWEAR',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '123',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '123',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '123',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '123',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '123',
        'poLineItem.quantity': 123,
        'poLineItem.unitOfMeasure': '123',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254165,
        'poLineItem.poFxAdjustment': 1.23,
        'poLineItem.totalAmount': '123',
        'poLineItem.tradingCompanyMarkup': 1.23,
        'poLineItem.netUnitPrice': 1229878,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A62',
        'poLineItem.productCode': '123',
        'product.categoryCode': '123',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '123',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A62',
        'planning.ogacvsmrgac': 'A62',
        'planning.mrgacCapacityConsumptionWeek': '123',
        'planning.planningSeason': 'NIKE A123',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '123',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260118-123',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260118',
        'poHeader.tradingPoNumber': '5803254165',
        'poHeader.partnerNumber': 'HEM',
        'poHeader.partnerName': ' HELA CLOTHING PVT LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '122',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '122',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '122',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '122',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '122',
        'poLineItem.quantity': 122,
        'poLineItem.unitOfMeasure': '122',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254165,
        'poLineItem.poFxAdjustment': 1.22,
        'poLineItem.totalAmount': '122',
        'poLineItem.tradingCompanyMarkup': 1.22,
        'poLineItem.netUnitPrice': 1219879,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B62',
        'poLineItem.productCode': '122',
        'product.categoryCode': '122',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '122',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B62',
        'planning.ogacvsmrgac': 'B62',
        'planning.mrgacCapacityConsumptionWeek': '122',
        'planning.planningSeason': 'NIKE A122',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '122',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260118-122',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260117',
        'poHeader.tradingPoNumber': '5803254166',
        'poHeader.partnerNumber': 'GIH',
        'poHeader.partnerName': ' GIBOR DEMAND PULL',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '121',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '121',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '121',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '121',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '121',
        'poLineItem.quantity': 121,
        'poLineItem.unitOfMeasure': '121',
        'poLineItem.ogacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-07T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254166,
        'poLineItem.poFxAdjustment': 1.21,
        'poLineItem.totalAmount': '121',
        'poLineItem.tradingCompanyMarkup': 1.21,
        'poLineItem.netUnitPrice': 1209880,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A61',
        'poLineItem.productCode': '121',
        'product.categoryCode': '121',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '121',
        'planning.mrgacDate': '2021-01-15T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A61',
        'planning.ogacvsmrgac': 'A61',
        'planning.mrgacCapacityConsumptionWeek': '121',
        'planning.planningSeason': 'NIKE A121',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '121',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260117-121',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260117',
        'poHeader.tradingPoNumber': '5803254166',
        'poHeader.partnerNumber': 'GEX',
        'poHeader.partnerName': ' GOKALDAS EXPORTS',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '120',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '120',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '120',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '120',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '120',
        'poLineItem.quantity': 120,
        'poLineItem.unitOfMeasure': '120',
        'poLineItem.ogacDate': '2020-11-11T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-12-07T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254166,
        'poLineItem.poFxAdjustment': 1.2,
        'poLineItem.totalAmount': '120',
        'poLineItem.tradingCompanyMarkup': 1.2,
        'poLineItem.netUnitPrice': 1199881,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B61',
        'poLineItem.productCode': '120',
        'product.categoryCode': '120',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '120',
        'planning.mrgacDate': '2020-12-16T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B61',
        'planning.ogacvsmrgac': 'B61',
        'planning.mrgacCapacityConsumptionWeek': '120',
        'planning.planningSeason': 'NIKE A120',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '120',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260117-120',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260117',
        'poHeader.tradingPoNumber': '5803254166',
        'poHeader.partnerNumber': 'FHS',
        'poHeader.partnerName': ' FRANKHAUS INTERNATIONAL CORP',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '119',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '119',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '119',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '119',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '119',
        'poLineItem.quantity': 119,
        'poLineItem.unitOfMeasure': '119',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-12-07T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254166,
        'poLineItem.poFxAdjustment': 1.19,
        'poLineItem.totalAmount': '119',
        'poLineItem.tradingCompanyMarkup': 1.19,
        'poLineItem.netUnitPrice': 1189882,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A60',
        'poLineItem.productCode': '119',
        'product.categoryCode': '119',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '119',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A60',
        'planning.ogacvsmrgac': 'A60',
        'planning.mrgacCapacityConsumptionWeek': '119',
        'planning.planningSeason': 'NIKE A119',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '119',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260117-119',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260116',
        'poHeader.tradingPoNumber': '5803254167',
        'poHeader.partnerNumber': 'E3G',
        'poHeader.partnerName': ' Gibor Sport Active Wear LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '116',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '116',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '116',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '116',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '116',
        'poLineItem.quantity': 116,
        'poLineItem.unitOfMeasure': '116',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254167,
        'poLineItem.poFxAdjustment': 1.16,
        'poLineItem.totalAmount': '116',
        'poLineItem.tradingCompanyMarkup': 1.16,
        'poLineItem.netUnitPrice': 1159885,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B59',
        'poLineItem.productCode': '116',
        'product.categoryCode': '116',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '116',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B59',
        'planning.ogacvsmrgac': 'B59',
        'planning.mrgacCapacityConsumptionWeek': '116',
        'planning.planningSeason': 'NIKE A116',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '116',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260116-116',
        tsUpdate: 1604319346
      },
      {
        'poHeader.poNumber': '4507260116',
        'poHeader.tradingPoNumber': '5803254167',
        'poHeader.partnerNumber': 'DGV',
        'poHeader.partnerName': ' DELTA GALIL INDUSTRIES LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '115',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '115',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '115',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '115',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '115',
        'poLineItem.quantity': 115,
        'poLineItem.unitOfMeasure': '115',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254167,
        'poLineItem.poFxAdjustment': 1.15,
        'poLineItem.totalAmount': '115',
        'poLineItem.tradingCompanyMarkup': 1.15,
        'poLineItem.netUnitPrice': 1149886,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A58',
        'poLineItem.productCode': '115',
        'product.categoryCode': '115',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '115',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A58',
        'planning.ogacvsmrgac': 'A58',
        'planning.mrgacCapacityConsumptionWeek': '115',
        'planning.planningSeason': 'NIKE A115',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '115',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260116-115',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260116',
        'poHeader.tradingPoNumber': '5803254167',
        'poHeader.partnerNumber': 'FEC',
        'poHeader.partnerName': ' FE NEW CENTURY INDUSTRY',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '118',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '118',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '118',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '118',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '118',
        'poLineItem.quantity': 118,
        'poLineItem.unitOfMeasure': '118',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254167,
        'poLineItem.poFxAdjustment': 1.18,
        'poLineItem.totalAmount': '118',
        'poLineItem.tradingCompanyMarkup': 1.18,
        'poLineItem.netUnitPrice': 1179883,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B60',
        'poLineItem.productCode': '118',
        'product.categoryCode': '118',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '118',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B60',
        'planning.ogacvsmrgac': 'B60',
        'planning.mrgacCapacityConsumptionWeek': '118',
        'planning.planningSeason': 'NIKE A118',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '118',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260116-118',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260116',
        'poHeader.tradingPoNumber': '5803254167',
        'poHeader.partnerNumber': 'EAG',
        'poHeader.partnerName': ' Eagle Nice Development Limited',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '117',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '117',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '117',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '117',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '117',
        'poLineItem.quantity': 117,
        'poLineItem.unitOfMeasure': '117',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254167,
        'poLineItem.poFxAdjustment': 1.17,
        'poLineItem.totalAmount': '117',
        'poLineItem.tradingCompanyMarkup': 1.17,
        'poLineItem.netUnitPrice': 1169884,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A59',
        'poLineItem.productCode': '117',
        'product.categoryCode': '117',
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIkE',
        'product.styleNumber': '117',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A59',
        'planning.ogacvsmrgac': 'A59',
        'planning.mrgacCapacityConsumptionWeek': '117',
        'planning.planningSeason': 'NIKE A117',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '117',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260116-117',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260115',
        'poHeader.tradingPoNumber': '5803254168',
        'poHeader.partnerNumber': 'DER',
        'poHeader.partnerName': ' DER WILL SA',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '114',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '114',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '114',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '114',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '114',
        'poLineItem.quantity': 114,
        'poLineItem.unitOfMeasure': '114',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254168,
        'poLineItem.poFxAdjustment': 1.14,
        'poLineItem.totalAmount': '114',
        'poLineItem.tradingCompanyMarkup': 1.14,
        'poLineItem.netUnitPrice': 1139887,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B58',
        'poLineItem.productCode': '114',
        'product.categoryCode': '114',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '114',
        'planning.mrgacDate': '2021-01-12T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B58',
        'planning.ogacvsmrgac': 'B58',
        'planning.mrgacCapacityConsumptionWeek': '114',
        'planning.planningSeason': 'NIKE A114',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '114',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260115-114',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260114',
        'poHeader.tradingPoNumber': '5803254169',
        'poHeader.partnerNumber': 'CEM',
        'poHeader.partnerName': ' ECLAT TEXTILE CO LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '113',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '113',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '113',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '113',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '113',
        'poLineItem.quantity': 113,
        'poLineItem.unitOfMeasure': '113',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254169,
        'poLineItem.poFxAdjustment': 1.13,
        'poLineItem.totalAmount': '113',
        'poLineItem.tradingCompanyMarkup': 1.13,
        'poLineItem.netUnitPrice': 1129888,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A57',
        'poLineItem.productCode': '113',
        'product.categoryCode': '113',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '113',
        'planning.mrgacDate': '2021-01-18T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A57',
        'planning.ogacvsmrgac': 'A57',
        'planning.mrgacCapacityConsumptionWeek': '113',
        'planning.planningSeason': 'NIKE A113',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '113',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260114-113',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260114',
        'poHeader.tradingPoNumber': '5803254169',
        'poHeader.partnerNumber': 'CCD',
        'poHeader.partnerName': ' CHUAN CHENG HAT CO LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '112',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '112',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '112',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '112',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '112',
        'poLineItem.quantity': 112,
        'poLineItem.unitOfMeasure': '112',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254169,
        'poLineItem.poFxAdjustment': 1.12,
        'poLineItem.totalAmount': '112',
        'poLineItem.tradingCompanyMarkup': 1.12,
        'poLineItem.netUnitPrice': 1119889,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B57',
        'poLineItem.productCode': '112',
        'product.categoryCode': '112',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '112',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B57',
        'planning.ogacvsmrgac': 'B57',
        'planning.mrgacCapacityConsumptionWeek': '112',
        'planning.planningSeason': 'NIKE A112',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '112',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260114-112',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260114',
        'poHeader.tradingPoNumber': '5803254169',
        'poHeader.partnerNumber': 'B6',
        'poHeader.partnerName': ' DASS NORDESTE CALCADOS E ARTIGOSE',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '111',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '111',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '111',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '111',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '111',
        'poLineItem.quantity': 111,
        'poLineItem.unitOfMeasure': '111',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254169,
        'poLineItem.poFxAdjustment': 1.11,
        'poLineItem.totalAmount': '111',
        'poLineItem.tradingCompanyMarkup': 1.11,
        'poLineItem.netUnitPrice': 1109890,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A56',
        'poLineItem.productCode': '111',
        'product.categoryCode': '111',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '111',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A56',
        'planning.ogacvsmrgac': 'A56',
        'planning.mrgacCapacityConsumptionWeek': '111',
        'planning.planningSeason': 'NIKE A111',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '111',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260114-111',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260113',
        'poHeader.tradingPoNumber': '5803254170',
        'poHeader.partnerNumber': 'B4',
        'poHeader.partnerName': ' ANIGER CALCADOS SUPRIMENTOSE',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '110',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '110',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '110',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '110',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '110',
        'poLineItem.quantity': 110,
        'poLineItem.unitOfMeasure': '110',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254170,
        'poLineItem.poFxAdjustment': 1.1,
        'poLineItem.totalAmount': '110',
        'poLineItem.tradingCompanyMarkup': 1.1,
        'poLineItem.netUnitPrice': 1099891,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B56',
        'poLineItem.productCode': '110',
        'product.categoryCode': '110',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '110',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B56',
        'planning.ogacvsmrgac': 'B56',
        'planning.mrgacCapacityConsumptionWeek': '110',
        'planning.planningSeason': 'NIKE A110',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '110',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260113-110',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260113',
        'poHeader.tradingPoNumber': '5803254170',
        'poHeader.partnerNumber': 'A22',
        'poHeader.partnerName': ' WACOAL-VIETNAM',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '109',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '109',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '109',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '109',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '109',
        'poLineItem.quantity': 109,
        'poLineItem.unitOfMeasure': '109',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254170,
        'poLineItem.poFxAdjustment': 1.09,
        'poLineItem.totalAmount': '109',
        'poLineItem.tradingCompanyMarkup': 1.09,
        'poLineItem.netUnitPrice': 1089892,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A55',
        'poLineItem.productCode': '109',
        'product.categoryCode': '109',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '109',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A55',
        'planning.ogacvsmrgac': 'A55',
        'planning.mrgacCapacityConsumptionWeek': '109',
        'planning.planningSeason': 'NIKE A109',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '109',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260113-109',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260113',
        'poHeader.tradingPoNumber': '5803254170',
        'poHeader.partnerNumber': 'A21',
        'poHeader.partnerName': ' WACOAL-JAPAN',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '108',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '108',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '108',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '108',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '108',
        'poLineItem.quantity': 108,
        'poLineItem.unitOfMeasure': '108',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254170,
        'poLineItem.poFxAdjustment': 1.08,
        'poLineItem.totalAmount': '108',
        'poLineItem.tradingCompanyMarkup': 1.08,
        'poLineItem.netUnitPrice': 1079893,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B55',
        'poLineItem.productCode': '108',
        'product.categoryCode': '108',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '108',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B55',
        'planning.ogacvsmrgac': 'B55',
        'planning.mrgacCapacityConsumptionWeek': '108',
        'planning.planningSeason': 'NIKE A108',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '108',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260113-108',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260112',
        'poHeader.tradingPoNumber': '5803254171',
        'poHeader.partnerNumber': 'A04',
        'poHeader.partnerName': ' MARUBENI-SONGGANG',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '107',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '107',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '107',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '107',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '107',
        'poLineItem.quantity': 107,
        'poLineItem.unitOfMeasure': '107',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254171,
        'poLineItem.poFxAdjustment': 1.07,
        'poLineItem.totalAmount': '107',
        'poLineItem.tradingCompanyMarkup': 1.07,
        'poLineItem.netUnitPrice': 1069894,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A54',
        'poLineItem.productCode': '107',
        'product.categoryCode': '107',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '107',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A54',
        'planning.ogacvsmrgac': 'A54',
        'planning.mrgacCapacityConsumptionWeek': '107',
        'planning.planningSeason': 'NIKE A107',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '107',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260112-107',
        tsUpdate: 1604319345
      },
      {
        'poHeader.poNumber': '4507260112',
        'poHeader.tradingPoNumber': '5803254171',
        'poHeader.partnerNumber': 'ZTX',
        'poHeader.partnerName': ' ZENTRIX INDUSTRIES LIMITED',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '106',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '106',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '106',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '106',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '106',
        'poLineItem.quantity': 106,
        'poLineItem.unitOfMeasure': '106',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254171,
        'poLineItem.poFxAdjustment': 1.06,
        'poLineItem.totalAmount': '106',
        'poLineItem.tradingCompanyMarkup': 1.06,
        'poLineItem.netUnitPrice': 1059895,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B54',
        'poLineItem.productCode': '106',
        'product.categoryCode': '106',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '106',
        'planning.mrgacDate': '2020-11-30T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B54',
        'planning.ogacvsmrgac': 'B54',
        'planning.mrgacCapacityConsumptionWeek': '106',
        'planning.planningSeason': 'NIKE A106',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '106',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260112-106',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260112',
        'poHeader.tradingPoNumber': '5803254171',
        'poHeader.partnerNumber': 'ZCK',
        'poHeader.partnerName': ' ZHONGSHAN',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '105',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '105',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '105',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '105',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '105',
        'poLineItem.quantity': 105,
        'poLineItem.unitOfMeasure': '105',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254171,
        'poLineItem.poFxAdjustment': 1.05,
        'poLineItem.totalAmount': '105',
        'poLineItem.tradingCompanyMarkup': 1.05,
        'poLineItem.netUnitPrice': 1049896,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A53',
        'poLineItem.productCode': '105',
        'product.categoryCode': '105',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '105',
        'planning.mrgacDate': '2020-11-30T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A53',
        'planning.ogacvsmrgac': 'A53',
        'planning.mrgacCapacityConsumptionWeek': '105',
        'planning.planningSeason': 'NIKE A105',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '105',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260112-105',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260111',
        'poHeader.tradingPoNumber': '5803254172',
        'poHeader.partnerNumber': 'YSN',
        'poHeader.partnerName': ' YU SHIN',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '104',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '104',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '104',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '104',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '104',
        'poLineItem.quantity': 104,
        'poLineItem.unitOfMeasure': '104',
        'poLineItem.ogacDate': '2020-12-02T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-23T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254172,
        'poLineItem.poFxAdjustment': 1.04,
        'poLineItem.totalAmount': '104',
        'poLineItem.tradingCompanyMarkup': 1.04,
        'poLineItem.netUnitPrice': 1039897,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B53',
        'poLineItem.productCode': '104',
        'product.categoryCode': '104',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '104',
        'planning.mrgacDate': '2021-01-15T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B53',
        'planning.ogacvsmrgac': 'B53',
        'planning.mrgacCapacityConsumptionWeek': '104',
        'planning.planningSeason': 'NIKE A104',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '104',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260111-104',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260111',
        'poHeader.tradingPoNumber': '5803254172',
        'poHeader.partnerNumber': 'YPV',
        'poHeader.partnerName': ' YUPOONG VIETNAM LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '103',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '103',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '103',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '103',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '103',
        'poLineItem.quantity': 103,
        'poLineItem.unitOfMeasure': '103',
        'poLineItem.ogacDate': '2020-11-11T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-12-23T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254172,
        'poLineItem.poFxAdjustment': 1.03,
        'poLineItem.totalAmount': '103',
        'poLineItem.tradingCompanyMarkup': 1.03,
        'poLineItem.netUnitPrice': 1029898,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A52',
        'poLineItem.productCode': '103',
        'product.categoryCode': '103',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '103',
        'planning.mrgacDate': '2020-12-16T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A52',
        'planning.ogacvsmrgac': 'A52',
        'planning.mrgacCapacityConsumptionWeek': '103',
        'planning.planningSeason': 'NIKE A103',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '103',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260111-103',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260111',
        'poHeader.tradingPoNumber': '5803254172',
        'poHeader.partnerNumber': 'XK',
        'poHeader.partnerName': ' SALMOND TRADING LIMITED',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '102',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '102',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '102',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '102',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '102',
        'poLineItem.quantity': 102,
        'poLineItem.unitOfMeasure': '102',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-12-23T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254172,
        'poLineItem.poFxAdjustment': 1.02,
        'poLineItem.totalAmount': '102',
        'poLineItem.tradingCompanyMarkup': 1.02,
        'poLineItem.netUnitPrice': 1019899,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B52',
        'poLineItem.productCode': '102',
        'product.categoryCode': '102',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '102',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B52',
        'planning.ogacvsmrgac': 'B52',
        'planning.mrgacCapacityConsumptionWeek': '102',
        'planning.planningSeason': 'NIKE A102',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '102',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260111-102',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260110',
        'poHeader.tradingPoNumber': '5803253714',
        'poHeader.partnerNumber': 'XG',
        'poHeader.partnerName': ' EVA OVERSEAS INTERNATIONAL LIMITED',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '101',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '101',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '101',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '101',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '101',
        'poLineItem.quantity': 101,
        'poLineItem.unitOfMeasure': '101',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253714,
        'poLineItem.poFxAdjustment': 1.01,
        'poLineItem.totalAmount': '101',
        'poLineItem.tradingCompanyMarkup': 1.01,
        'poLineItem.netUnitPrice': 1009900,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A51',
        'poLineItem.productCode': '101',
        'product.categoryCode': '101',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '101',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A51',
        'planning.ogacvsmrgac': 'A51',
        'planning.mrgacCapacityConsumptionWeek': '101',
        'planning.planningSeason': 'NIKE A101',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '101',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260110-101',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260110',
        'poHeader.tradingPoNumber': '5803253714',
        'poHeader.partnerNumber': 'VNT',
        'poHeader.partnerName': ' VANTAGE',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '98',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '98',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '98',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '98',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '98',
        'poLineItem.quantity': 98,
        'poLineItem.unitOfMeasure': '98',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253714,
        'poLineItem.poFxAdjustment': 0.98,
        'poLineItem.totalAmount': '98',
        'poLineItem.tradingCompanyMarkup': 0.98,
        'poLineItem.netUnitPrice': 979903,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B50',
        'poLineItem.productCode': '98',
        'product.categoryCode': '98',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '98',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B50',
        'planning.ogacvsmrgac': 'B50',
        'planning.mrgacCapacityConsumptionWeek': '98',
        'planning.planningSeason': 'NIKE A98',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '98',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260110-98',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260110',
        'poHeader.tradingPoNumber': '5803253714',
        'poHeader.partnerNumber': 'WNC',
        'poHeader.partnerName': ' WIN HANVERKY LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '100',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '100',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '100',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '100',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '100',
        'poLineItem.quantity': 100,
        'poLineItem.unitOfMeasure': '100',
        'poLineItem.ogacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-25T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253714,
        'poLineItem.poFxAdjustment': 1,
        'poLineItem.totalAmount': '100',
        'poLineItem.tradingCompanyMarkup': 1,
        'poLineItem.netUnitPrice': 999901,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B51',
        'poLineItem.productCode': '100',
        'product.categoryCode': '100',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '100',
        'planning.mrgacDate': '2021-01-11T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B51',
        'planning.ogacvsmrgac': 'B51',
        'planning.mrgacCapacityConsumptionWeek': '100',
        'planning.planningSeason': 'NIKE A100',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '100',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260110-100',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260110',
        'poHeader.tradingPoNumber': '5803253714',
        'poHeader.partnerNumber': 'WDA',
        'poHeader.partnerName': ' SUPERCAP INDUSTRIAL CO LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '99',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '99',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '99',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '99',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '99',
        'poLineItem.quantity': 99,
        'poLineItem.unitOfMeasure': '99',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253714,
        'poLineItem.poFxAdjustment': 0.99,
        'poLineItem.totalAmount': '99',
        'poLineItem.tradingCompanyMarkup': 0.99,
        'poLineItem.netUnitPrice': 989902,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A50',
        'poLineItem.productCode': '99',
        'product.categoryCode': '99',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '99',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A50',
        'planning.ogacvsmrgac': 'A50',
        'planning.mrgacCapacityConsumptionWeek': '99',
        'planning.planningSeason': 'NIKE A99',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '99',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260110-99',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260109',
        'poHeader.tradingPoNumber': '5803253715',
        'poHeader.partnerNumber': 'VMR',
        'poHeader.partnerName': ' EVENT MARKETING GROUP',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '97',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '97',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '97',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '97',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '97',
        'poLineItem.quantity': 97,
        'poLineItem.unitOfMeasure': '97',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253715,
        'poLineItem.poFxAdjustment': 0.97,
        'poLineItem.totalAmount': '97',
        'poLineItem.tradingCompanyMarkup': 0.97,
        'poLineItem.netUnitPrice': 969904,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A49',
        'poLineItem.productCode': '97',
        'product.categoryCode': '97',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '97',
        'planning.mrgacDate': '2021-01-12T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A49',
        'planning.ogacvsmrgac': 'A49',
        'planning.mrgacCapacityConsumptionWeek': '97',
        'planning.planningSeason': 'NIKE A97',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '97',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260109-97',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260109',
        'poHeader.tradingPoNumber': '5803253715',
        'poHeader.partnerNumber': 'UGV',
        'poHeader.partnerName': ' UPGAIN VIETNAM MANUFACTURING',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '96',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '96',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '96',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '96',
        'poHeader.tradingCompanyName': 'DPOC USER',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '96',
        'poLineItem.quantity': 96,
        'poLineItem.unitOfMeasure': '96',
        'poLineItem.ogacDate': '2020-11-11T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-12-07T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253715,
        'poLineItem.poFxAdjustment': 0.96,
        'poLineItem.totalAmount': '96',
        'poLineItem.tradingCompanyMarkup': 0.96,
        'poLineItem.netUnitPrice': 959905,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B49',
        'poLineItem.productCode': '96',
        'product.categoryCode': '96',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '96',
        'planning.mrgacDate': '2020-12-16T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B49',
        'planning.ogacvsmrgac': 'B49',
        'planning.mrgacCapacityConsumptionWeek': '96',
        'planning.planningSeason': 'NIKE A96',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '96',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260109-96',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260109',
        'poHeader.tradingPoNumber': '5803253715',
        'poHeader.partnerNumber': 'UFG',
        'poHeader.partnerName': ' UNION FRIEND GARMENT',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '95',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '95',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '95',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '95',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '95',
        'poLineItem.quantity': 95,
        'poLineItem.unitOfMeasure': '95',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-12-07T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253715,
        'poLineItem.poFxAdjustment': 0.95,
        'poLineItem.totalAmount': '95',
        'poLineItem.tradingCompanyMarkup': 0.95,
        'poLineItem.netUnitPrice': 949906,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A48',
        'poLineItem.productCode': '95',
        'product.categoryCode': '95',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '95',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A48',
        'planning.ogacvsmrgac': 'A48',
        'planning.mrgacCapacityConsumptionWeek': '95',
        'planning.planningSeason': 'NIKE A95',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '95',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260109-95',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260108',
        'poHeader.tradingPoNumber': '5803253716',
        'poHeader.partnerNumber': 'TGL',
        'poHeader.partnerName': ' LATITUDES',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '94',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '94',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '94',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '94',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '94',
        'poLineItem.quantity': 94,
        'poLineItem.unitOfMeasure': '94',
        'poLineItem.ogacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-09T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253716,
        'poLineItem.poFxAdjustment': 0.94,
        'poLineItem.totalAmount': '94',
        'poLineItem.tradingCompanyMarkup': 0.94,
        'poLineItem.netUnitPrice': 939907,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B48',
        'poLineItem.productCode': '94',
        'product.categoryCode': '94',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '94',
        'planning.mrgacDate': '2021-01-18T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'B48',
        'planning.ogacvsmrgac': 'B48',
        'planning.mrgacCapacityConsumptionWeek': '94',
        'planning.planningSeason': 'NIKE A94',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '94',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260108-94',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260108',
        'poHeader.tradingPoNumber': '5803253716',
        'poHeader.partnerNumber': 'TGH',
        'poHeader.partnerName': ' NEW HOLLAND APPAREL',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '93',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '93',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '93',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '93',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '93',
        'poLineItem.quantity': 93,
        'poLineItem.unitOfMeasure': '93',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253716,
        'poLineItem.poFxAdjustment': 0.93,
        'poLineItem.totalAmount': '93',
        'poLineItem.tradingCompanyMarkup': 0.93,
        'poLineItem.netUnitPrice': 929908,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A47',
        'poLineItem.productCode': '93',
        'product.categoryCode': '93',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '93',
        'planning.mrgacDate': '2020-12-23T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A47',
        'planning.ogacvsmrgac': 'A47',
        'planning.mrgacCapacityConsumptionWeek': '93',
        'planning.planningSeason': 'NIKE A93',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '93',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507260108-93',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507260108',
        'poHeader.tradingPoNumber': '5803253716',
        'poHeader.partnerNumber': 'SFI',
        'poHeader.partnerName': ' SOFI CLASSIC SA DE CV',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '92',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '92',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '92',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '92',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '92',
        'poLineItem.quantity': 92,
        'poLineItem.unitOfMeasure': '92',
        'poLineItem.ogacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-18T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803253716,
        'poLineItem.poFxAdjustment': 0.92,
        'poLineItem.totalAmount': '92',
        'poLineItem.tradingCompanyMarkup': 0.92,
        'poLineItem.netUnitPrice': 919909,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B47',
        'poLineItem.productCode': '92',
        'product.categoryCode': '92',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '92',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B47',
        'planning.ogacvsmrgac': 'B47',
        'planning.mrgacCapacityConsumptionWeek': '92',
        'planning.planningSeason': 'NIKE A92',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '92',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507260108-92',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'RCM',
        'poHeader.partnerName': ' ROCKMAUI',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '89',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '89',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '89',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '89',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '89',
        'poLineItem.quantity': 89,
        'poLineItem.unitOfMeasure': '89',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.89,
        'poLineItem.totalAmount': '89',
        'poLineItem.tradingCompanyMarkup': 0.89,
        'poLineItem.netUnitPrice': 889912,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A45',
        'poLineItem.productCode': '89',
        'product.categoryCode': '89',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '89',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A45',
        'planning.ogacvsmrgac': 'A45',
        'planning.mrgacCapacityConsumptionWeek': '89',
        'planning.planningSeason': 'NIKE A89',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '89',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-89',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'SCI',
        'poHeader.partnerName': ' SPORTS CITY INTERNATIONAL INC',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '91',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '91',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '91',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '91',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '91',
        'poLineItem.quantity': 91,
        'poLineItem.unitOfMeasure': '91',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.91,
        'poLineItem.totalAmount': '91',
        'poLineItem.tradingCompanyMarkup': 0.91,
        'poLineItem.netUnitPrice': 909910,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A46',
        'poLineItem.productCode': '91',
        'product.categoryCode': '91',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '91',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A46',
        'planning.ogacvsmrgac': 'A46',
        'planning.mrgacCapacityConsumptionWeek': '91',
        'planning.planningSeason': 'NIKE A91',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '91',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-91',
        tsUpdate: 1604319344
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'RCT',
        'poHeader.partnerName': ' RANIK GROUP  TOP CROWN CO LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '90',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '90',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '90',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '90',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '90',
        'poLineItem.quantity': 90,
        'poLineItem.unitOfMeasure': '90',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.9,
        'poLineItem.totalAmount': '90',
        'poLineItem.tradingCompanyMarkup': 0.9,
        'poLineItem.netUnitPrice': 899911,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B46',
        'poLineItem.productCode': '90',
        'product.categoryCode': '90',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '90',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B46',
        'planning.ogacvsmrgac': 'B46',
        'planning.mrgacCapacityConsumptionWeek': '90',
        'planning.planningSeason': 'NIKE A90',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '90',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-90',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'QQ',
        'poHeader.partnerName': ' Great Ascent Trading Limited',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '88',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '88',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '88',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '88',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '88',
        'poLineItem.quantity': 88,
        'poLineItem.unitOfMeasure': '88',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.88,
        'poLineItem.totalAmount': '88',
        'poLineItem.tradingCompanyMarkup': 0.88,
        'poLineItem.netUnitPrice': 879913,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B45',
        'poLineItem.productCode': '88',
        'product.categoryCode': '88',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '88',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B45',
        'planning.ogacvsmrgac': 'B45',
        'planning.mrgacCapacityConsumptionWeek': '88',
        'planning.planningSeason': 'NIKE A88',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '88',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-88',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'QM',
        'poHeader.partnerName': ' Mega International Trading Limited',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '87',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '87',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '87',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '87',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '87',
        'poLineItem.quantity': 87,
        'poLineItem.unitOfMeasure': '87',
        'poLineItem.ogacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.87,
        'poLineItem.totalAmount': '87',
        'poLineItem.tradingCompanyMarkup': 0.87,
        'poLineItem.netUnitPrice': 869914,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A44',
        'poLineItem.productCode': '87',
        'product.categoryCode': '87',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '87',
        'planning.mrgacDate': '2020-11-09T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A44',
        'planning.ogacvsmrgac': 'A44',
        'planning.mrgacCapacityConsumptionWeek': '87',
        'planning.planningSeason': 'NIKE A87',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '87',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-87',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'PCP',
        'poHeader.partnerName': ' PROMOTORA COMERCIALPANTALONERA',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '86',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '86',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '86',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '86',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '86',
        'poLineItem.quantity': 86,
        'poLineItem.unitOfMeasure': '86',
        'poLineItem.ogacDate': '2020-11-12T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-12T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.86,
        'poLineItem.totalAmount': '86',
        'poLineItem.tradingCompanyMarkup': 0.86,
        'poLineItem.netUnitPrice': 859915,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B44',
        'poLineItem.productCode': '86',
        'product.categoryCode': '86',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '86',
        'planning.mrgacDate': '2020-11-23T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B44',
        'planning.ogacvsmrgac': 'B44',
        'planning.mrgacCapacityConsumptionWeek': '86',
        'planning.planningSeason': 'NIKE A86',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '86',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-86',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'PC',
        'poHeader.partnerName': ' Yue Yuen Industrial Limited',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '85',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '85',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '85',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '85',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '85',
        'poLineItem.quantity': 85,
        'poLineItem.unitOfMeasure': '85',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.85,
        'poLineItem.totalAmount': '85',
        'poLineItem.tradingCompanyMarkup': 0.85,
        'poLineItem.netUnitPrice': 849916,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A43',
        'poLineItem.productCode': '85',
        'product.categoryCode': '85',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '85',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A43',
        'planning.ogacvsmrgac': 'A43',
        'planning.mrgacCapacityConsumptionWeek': '85',
        'planning.planningSeason': 'NIKE A85',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '85',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-85',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'ORI',
        'poHeader.partnerName': ' SHANGHAI DOLPHIN TEXTILE PRODUCT CO',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '84',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '84',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '84',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '84',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '84',
        'poLineItem.quantity': 84,
        'poLineItem.unitOfMeasure': '84',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.84,
        'poLineItem.totalAmount': '84',
        'poLineItem.tradingCompanyMarkup': 0.84,
        'poLineItem.netUnitPrice': 839917,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B43',
        'poLineItem.productCode': '84',
        'product.categoryCode': '84',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '84',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B43',
        'planning.ogacvsmrgac': 'B43',
        'planning.mrgacCapacityConsumptionWeek': '84',
        'planning.planningSeason': 'NIKE A84',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '84',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-84',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'OPT',
        'poHeader.partnerName': ' OPTECH',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '83',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '83',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '83',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '83',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '83',
        'poLineItem.quantity': 83,
        'poLineItem.unitOfMeasure': '83',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.83,
        'poLineItem.totalAmount': '83',
        'poLineItem.tradingCompanyMarkup': 0.83,
        'poLineItem.netUnitPrice': 829918,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A42',
        'poLineItem.productCode': '83',
        'product.categoryCode': '83',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '83',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A42',
        'planning.ogacvsmrgac': 'A42',
        'planning.mrgacCapacityConsumptionWeek': '83',
        'planning.planningSeason': 'NIKE A83',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '83',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-83',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'NIG',
        'poHeader.partnerName': ' SHENZHOU INTERNATIONAL GROUP',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '82',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '82',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '82',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '82',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '82',
        'poLineItem.quantity': 82,
        'poLineItem.unitOfMeasure': '82',
        'poLineItem.ogacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.82,
        'poLineItem.totalAmount': '82',
        'poLineItem.tradingCompanyMarkup': 0.82,
        'poLineItem.netUnitPrice': 819919,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B42',
        'poLineItem.productCode': '82',
        'product.categoryCode': '82',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '82',
        'planning.mrgacDate': '2020-11-16T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B42',
        'planning.ogacvsmrgac': 'B42',
        'planning.mrgacCapacityConsumptionWeek': '82',
        'planning.planningSeason': 'NIKE A82',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '82',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-82',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'NAL',
        'poHeader.partnerName': ' ESFERA',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '81',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '81',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '81',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '81',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '81',
        'poLineItem.quantity': 81,
        'poLineItem.unitOfMeasure': '81',
        'poLineItem.ogacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.81,
        'poLineItem.totalAmount': '81',
        'poLineItem.tradingCompanyMarkup': 0.81,
        'poLineItem.netUnitPrice': 809920,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A41',
        'poLineItem.productCode': '81',
        'product.categoryCode': '81',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '81',
        'planning.mrgacDate': '2020-11-16T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A41',
        'planning.ogacvsmrgac': 'A41',
        'planning.mrgacCapacityConsumptionWeek': '81',
        'planning.planningSeason': 'NIKE A81',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '81',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-81',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'MOV',
        'poHeader.partnerName': ' MONVISO KNITWEAR',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '80',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '80',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '80',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '80',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '80',
        'poLineItem.quantity': 80,
        'poLineItem.unitOfMeasure': '80',
        'poLineItem.ogacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.8,
        'poLineItem.totalAmount': '80',
        'poLineItem.tradingCompanyMarkup': 0.8,
        'poLineItem.netUnitPrice': 799921,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B41',
        'poLineItem.productCode': '80',
        'product.categoryCode': '80',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '80',
        'planning.mrgacDate': '2020-11-16T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B41',
        'planning.ogacvsmrgac': 'B41',
        'planning.mrgacCapacityConsumptionWeek': '80',
        'planning.planningSeason': 'NIKE A80',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '80',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-80',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'MHC',
        'poHeader.partnerName': ' MALHARIA CRISTINA LTDA',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '79',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '79',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '79',
        'poHeader.purchaseOrderCompanyCodeName': 'DPOC',
        'poHeader.tradingCompanyCode': '79',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '79',
        'poLineItem.quantity': 79,
        'poLineItem.unitOfMeasure': '79',
        'poLineItem.ogacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.79,
        'poLineItem.totalAmount': '79',
        'poLineItem.tradingCompanyMarkup': 0.79,
        'poLineItem.netUnitPrice': 789922,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A40',
        'poLineItem.productCode': '79',
        'product.categoryCode': '79',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '79',
        'planning.mrgacDate': '2020-11-16T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A40',
        'planning.ogacvsmrgac': 'A40',
        'planning.mrgacCapacityConsumptionWeek': '79',
        'planning.planningSeason': 'NIKE A79',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '79',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-79',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'LAF',
        'poHeader.partnerName': ' LATITUDES',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '77',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '77',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '77',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '77',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '77',
        'poLineItem.quantity': 77,
        'poLineItem.unitOfMeasure': '77',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.77,
        'poLineItem.totalAmount': '77',
        'poLineItem.tradingCompanyMarkup': 0.77,
        'poLineItem.netUnitPrice': 769924,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A39',
        'poLineItem.productCode': '77',
        'product.categoryCode': '77',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '77',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A39',
        'planning.ogacvsmrgac': 'A39',
        'planning.mrgacCapacityConsumptionWeek': '77',
        'planning.planningSeason': 'NIKE A77',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '77',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-77',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'LCL',
        'poHeader.partnerName': ' LIAND',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '78',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '78',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '78',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '78',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '78',
        'poLineItem.quantity': 78,
        'poLineItem.unitOfMeasure': '78',
        'poLineItem.ogacDate': '2020-11-15T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-15T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.78,
        'poLineItem.totalAmount': '78',
        'poLineItem.tradingCompanyMarkup': 0.78,
        'poLineItem.netUnitPrice': 779923,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B40',
        'poLineItem.productCode': '78',
        'product.categoryCode': '78',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '78',
        'planning.mrgacDate': '2020-11-30T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B40',
        'planning.ogacvsmrgac': 'B40',
        'planning.mrgacCapacityConsumptionWeek': '78',
        'planning.planningSeason': 'NIKE A78',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '78',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-78',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'KKC',
        'poHeader.partnerName': ' JIN MEI KNITWEAR',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '76',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '76',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '76',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '76',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '76',
        'poLineItem.quantity': 76,
        'poLineItem.unitOfMeasure': '76',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-11-11T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.76,
        'poLineItem.totalAmount': '76',
        'poLineItem.tradingCompanyMarkup': 0.76,
        'poLineItem.netUnitPrice': 759925,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B39',
        'poLineItem.productCode': '76',
        'product.categoryCode': '76',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '76',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B39',
        'planning.ogacvsmrgac': 'B39',
        'planning.mrgacCapacityConsumptionWeek': '76',
        'planning.planningSeason': 'NIKE A76',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '76',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-76',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'KEL',
        'poHeader.partnerName': ' KELLWOOD COMPANY',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '75',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '75',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '75',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '75',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '75',
        'poLineItem.quantity': 75,
        'poLineItem.unitOfMeasure': '75',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-11-11T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.75,
        'poLineItem.totalAmount': '75',
        'poLineItem.tradingCompanyMarkup': 0.75,
        'poLineItem.netUnitPrice': 749926,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A38',
        'poLineItem.productCode': '75',
        'product.categoryCode': '75',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '75',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A38',
        'planning.ogacvsmrgac': 'A38',
        'planning.mrgacCapacityConsumptionWeek': '75',
        'planning.planningSeason': 'NIKE A75',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '75',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-75',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'JPT',
        'poHeader.partnerName': ' JOHN PETER',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '74',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '74',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '74',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '74',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '74',
        'poLineItem.quantity': 74,
        'poLineItem.unitOfMeasure': '74',
        'poLineItem.ogacDate': '2020-09-30T05:09:00.000+05:30',
        'poLineItem.gacDate': '2020-09-30T05:09:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.74,
        'poLineItem.totalAmount': '74',
        'poLineItem.tradingCompanyMarkup': 0.74,
        'poLineItem.netUnitPrice': 739927,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B38',
        'poLineItem.productCode': '74',
        'product.categoryCode': '74',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '74',
        'planning.mrgacDate': '2020-10-12T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B38',
        'planning.ogacvsmrgac': 'B38',
        'planning.mrgacCapacityConsumptionWeek': '74',
        'planning.planningSeason': 'NIKE A74',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '74',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-74',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'JON',
        'poHeader.partnerName': ' JOHNSON SPORTSWEAR LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '73',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '73',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '73',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '73',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '73',
        'poLineItem.quantity': 73,
        'poLineItem.unitOfMeasure': '73',
        'poLineItem.ogacDate': '2020-09-30T05:09:00.000+05:30',
        'poLineItem.gacDate': '2020-09-30T05:09:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.73,
        'poLineItem.totalAmount': '73',
        'poLineItem.tradingCompanyMarkup': 0.73,
        'poLineItem.netUnitPrice': 729928,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A37',
        'poLineItem.productCode': '73',
        'product.categoryCode': '73',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '73',
        'planning.mrgacDate': '2020-10-12T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A37',
        'planning.ogacvsmrgac': 'A37',
        'planning.mrgacCapacityConsumptionWeek': '73',
        'planning.planningSeason': 'NIKE A73',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '73',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-73',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'IPS',
        'poHeader.partnerName': ' IMPACT SPORTS',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '72',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '72',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '72',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '72',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '72',
        'poLineItem.quantity': 72,
        'poLineItem.unitOfMeasure': '72',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.72,
        'poLineItem.totalAmount': '72',
        'poLineItem.tradingCompanyMarkup': 0.72,
        'poLineItem.netUnitPrice': 719929,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B37',
        'poLineItem.productCode': '72',
        'product.categoryCode': '72',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '72',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B37',
        'planning.ogacvsmrgac': 'B37',
        'planning.mrgacCapacityConsumptionWeek': '72',
        'planning.planningSeason': 'NIKE A72',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '72',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-72',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'IM',
        'poHeader.partnerName': ' Mega International Trading Limited',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '71',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '71',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '71',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '71',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '71',
        'poLineItem.quantity': 71,
        'poLineItem.unitOfMeasure': '71',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.71,
        'poLineItem.totalAmount': '71',
        'poLineItem.tradingCompanyMarkup': 0.71,
        'poLineItem.netUnitPrice': 709930,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A36',
        'poLineItem.productCode': '71',
        'product.categoryCode': '71',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '71',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A36',
        'planning.ogacvsmrgac': 'A36',
        'planning.mrgacCapacityConsumptionWeek': '71',
        'planning.planningSeason': 'NIKE A71',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '71',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-71',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'HIN',
        'poHeader.partnerName': ' HINDUJA SPORTSWEAR',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '70',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '70',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '70',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '70',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '70',
        'poLineItem.quantity': 70,
        'poLineItem.unitOfMeasure': '70',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.7,
        'poLineItem.totalAmount': '70',
        'poLineItem.tradingCompanyMarkup': 0.7,
        'poLineItem.netUnitPrice': 699931,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B36',
        'poLineItem.productCode': '70',
        'product.categoryCode': '70',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '70',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B36',
        'planning.ogacvsmrgac': 'B36',
        'planning.mrgacCapacityConsumptionWeek': '70',
        'planning.planningSeason': 'NIKE A70',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '70',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-70',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'HEM',
        'poHeader.partnerName': ' HELA CLOTHING PVT LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '69',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '69',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '69',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '69',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '69',
        'poLineItem.quantity': 69,
        'poLineItem.unitOfMeasure': '69',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.69,
        'poLineItem.totalAmount': '69',
        'poLineItem.tradingCompanyMarkup': 0.69,
        'poLineItem.netUnitPrice': 689932,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A35',
        'poLineItem.productCode': '69',
        'product.categoryCode': '69',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '69',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A35',
        'planning.ogacvsmrgac': 'A35',
        'planning.mrgacCapacityConsumptionWeek': '69',
        'planning.planningSeason': 'NIKE A69',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '69',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-69',
        tsUpdate: 1604319343
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'GEX',
        'poHeader.partnerName': ' GOKALDAS EXPORTS',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '67',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '67',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '67',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '67',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '67',
        'poLineItem.quantity': 67,
        'poLineItem.unitOfMeasure': '67',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.67,
        'poLineItem.totalAmount': '67',
        'poLineItem.tradingCompanyMarkup': 0.67,
        'poLineItem.netUnitPrice': 669934,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A34',
        'poLineItem.productCode': '67',
        'product.categoryCode': '67',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '67',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A34',
        'planning.ogacvsmrgac': 'A34',
        'planning.mrgacCapacityConsumptionWeek': '67',
        'planning.planningSeason': 'NIKE A67',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '67',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-67',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'FHS',
        'poHeader.partnerName': ' FRANKHAUS INTERNATIONAL CORP',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '66',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '66',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '66',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '66',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '66',
        'poLineItem.quantity': 66,
        'poLineItem.unitOfMeasure': '66',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.66,
        'poLineItem.totalAmount': '66',
        'poLineItem.tradingCompanyMarkup': 0.66,
        'poLineItem.netUnitPrice': 659935,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B34',
        'poLineItem.productCode': '66',
        'product.categoryCode': '66',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '66',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B34',
        'planning.ogacvsmrgac': 'B34',
        'planning.mrgacCapacityConsumptionWeek': '66',
        'planning.planningSeason': 'NIKE A66',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '66',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-66',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'GIH',
        'poHeader.partnerName': ' GIBOR DEMAND PULL',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '68',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '68',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '68',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '68',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '68',
        'poLineItem.quantity': 68,
        'poLineItem.unitOfMeasure': '68',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.68,
        'poLineItem.totalAmount': '68',
        'poLineItem.tradingCompanyMarkup': 0.68,
        'poLineItem.netUnitPrice': 679933,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B35',
        'poLineItem.productCode': '68',
        'product.categoryCode': '68',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '68',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B35',
        'planning.ogacvsmrgac': 'B35',
        'planning.mrgacCapacityConsumptionWeek': '68',
        'planning.planningSeason': 'NIKE A68',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '68',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-68',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'FEC',
        'poHeader.partnerName': ' FE NEW CENTURY INDUSTRY',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '65',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '65',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '65',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '65',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '65',
        'poLineItem.quantity': 65,
        'poLineItem.unitOfMeasure': '65',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.65,
        'poLineItem.totalAmount': '65',
        'poLineItem.tradingCompanyMarkup': 0.65,
        'poLineItem.netUnitPrice': 649936,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A33',
        'poLineItem.productCode': '65',
        'product.categoryCode': '65',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '65',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A33',
        'planning.ogacvsmrgac': 'A33',
        'planning.mrgacCapacityConsumptionWeek': '65',
        'planning.planningSeason': 'NIKE A65',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '65',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-65',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'EAG',
        'poHeader.partnerName': ' Eagle Nice Development Limited',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '64',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '64',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '64',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '64',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '64',
        'poLineItem.quantity': 64,
        'poLineItem.unitOfMeasure': '64',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.64,
        'poLineItem.totalAmount': '64',
        'poLineItem.tradingCompanyMarkup': 0.64,
        'poLineItem.netUnitPrice': 639937,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B33',
        'poLineItem.productCode': '64',
        'product.categoryCode': '64',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '64',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B33',
        'planning.ogacvsmrgac': 'B33',
        'planning.mrgacCapacityConsumptionWeek': '64',
        'planning.planningSeason': 'NIKE A64',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '64',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-64',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'E3G',
        'poHeader.partnerName': ' Gibor Sport Active Wear LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '63',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '63',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '63',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '63',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '63',
        'poLineItem.quantity': 63,
        'poLineItem.unitOfMeasure': '63',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.63,
        'poLineItem.totalAmount': '63',
        'poLineItem.tradingCompanyMarkup': 0.63,
        'poLineItem.netUnitPrice': 629938,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A32',
        'poLineItem.productCode': '63',
        'product.categoryCode': '63',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '63',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A32',
        'planning.ogacvsmrgac': 'A32',
        'planning.mrgacCapacityConsumptionWeek': '63',
        'planning.planningSeason': 'NIKE A63',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '63',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-63',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'DGV',
        'poHeader.partnerName': ' DELTA GALIL INDUSTRIES LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '62',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '62',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '62',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '62',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '62',
        'poLineItem.quantity': 62,
        'poLineItem.unitOfMeasure': '62',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.62,
        'poLineItem.totalAmount': '62',
        'poLineItem.tradingCompanyMarkup': 0.62,
        'poLineItem.netUnitPrice': 619939,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B32',
        'poLineItem.productCode': '62',
        'product.categoryCode': '62',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '62',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B32',
        'planning.ogacvsmrgac': 'B32',
        'planning.mrgacCapacityConsumptionWeek': '62',
        'planning.planningSeason': 'NIKE A62',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '62',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-62',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'DER',
        'poHeader.partnerName': ' DER WILL SA',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '61',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '61',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '61',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '61',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '61',
        'poLineItem.quantity': 61,
        'poLineItem.unitOfMeasure': '61',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.61,
        'poLineItem.totalAmount': '61',
        'poLineItem.tradingCompanyMarkup': 0.61,
        'poLineItem.netUnitPrice': 609940,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A31',
        'poLineItem.productCode': '61',
        'product.categoryCode': '61',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '61',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A31',
        'planning.ogacvsmrgac': 'A31',
        'planning.mrgacCapacityConsumptionWeek': '61',
        'planning.planningSeason': 'NIKE A61',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '61',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-61',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'CEM',
        'poHeader.partnerName': ' ECLAT TEXTILE CO LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '60',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '60',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '60',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '60',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '60',
        'poLineItem.quantity': 60,
        'poLineItem.unitOfMeasure': '60',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.6,
        'poLineItem.totalAmount': '60',
        'poLineItem.tradingCompanyMarkup': 0.6,
        'poLineItem.netUnitPrice': 599941,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B31',
        'poLineItem.productCode': '60',
        'product.categoryCode': '60',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '60',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B31',
        'planning.ogacvsmrgac': 'B31',
        'planning.mrgacCapacityConsumptionWeek': '60',
        'planning.planningSeason': 'NIKE A60',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '60',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-60',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'CCD',
        'poHeader.partnerName': ' CHUAN CHENG HAT CO LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '59',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '59',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '59',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '59',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '59',
        'poLineItem.quantity': 59,
        'poLineItem.unitOfMeasure': '59',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.59,
        'poLineItem.totalAmount': '59',
        'poLineItem.tradingCompanyMarkup': 0.59,
        'poLineItem.netUnitPrice': 589942,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A30',
        'poLineItem.productCode': '59',
        'product.categoryCode': '59',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '59',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A30',
        'planning.ogacvsmrgac': 'A30',
        'planning.mrgacCapacityConsumptionWeek': '59',
        'planning.planningSeason': 'NIKE A59',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '59',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-59',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'B6',
        'poHeader.partnerName': ' DASS NORDESTE CALCADOS E ARTIGOSE',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '58',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '58',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '58',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '58',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '58',
        'poLineItem.quantity': 58,
        'poLineItem.unitOfMeasure': '58',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.58,
        'poLineItem.totalAmount': '58',
        'poLineItem.tradingCompanyMarkup': 0.58,
        'poLineItem.netUnitPrice': 579943,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B30',
        'poLineItem.productCode': '58',
        'product.categoryCode': '58',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '58',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B30',
        'planning.ogacvsmrgac': 'B30',
        'planning.mrgacCapacityConsumptionWeek': '58',
        'planning.planningSeason': 'NIKE A58',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '58',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-58',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'B4',
        'poHeader.partnerName': ' ANIGER CALCADOS SUPRIMENTOSE',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '57',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '57',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '57',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '57',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '57',
        'poLineItem.quantity': 57,
        'poLineItem.unitOfMeasure': '57',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.57,
        'poLineItem.totalAmount': '57',
        'poLineItem.tradingCompanyMarkup': 0.57,
        'poLineItem.netUnitPrice': 569944,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A29',
        'poLineItem.productCode': '57',
        'product.categoryCode': '57',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '57',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A29',
        'planning.ogacvsmrgac': 'A29',
        'planning.mrgacCapacityConsumptionWeek': '57',
        'planning.planningSeason': 'NIKE A57',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '57',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-57',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'A04',
        'poHeader.partnerName': ' MARUBENI-SONGGANG',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '54',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '54',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '54',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '54',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '54',
        'poLineItem.quantity': 54,
        'poLineItem.unitOfMeasure': '54',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.54,
        'poLineItem.totalAmount': '54',
        'poLineItem.tradingCompanyMarkup': 0.54,
        'poLineItem.netUnitPrice': 539947,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B28',
        'poLineItem.productCode': '54',
        'product.categoryCode': '54',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '54',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B28',
        'planning.ogacvsmrgac': 'B28',
        'planning.mrgacCapacityConsumptionWeek': '54',
        'planning.planningSeason': 'NIKE A54',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '54',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-54',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'A22',
        'poHeader.partnerName': ' WACOAL-VIETNAM',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '56',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '56',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '56',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '56',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '56',
        'poLineItem.quantity': 56,
        'poLineItem.unitOfMeasure': '56',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.56,
        'poLineItem.totalAmount': '56',
        'poLineItem.tradingCompanyMarkup': 0.56,
        'poLineItem.netUnitPrice': 559945,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'B29',
        'poLineItem.productCode': '56',
        'product.categoryCode': '56',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '56',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B29',
        'planning.ogacvsmrgac': 'B29',
        'planning.mrgacCapacityConsumptionWeek': '56',
        'planning.planningSeason': 'NIKE A56',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '56',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-56',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'A21',
        'poHeader.partnerName': ' WACOAL-JAPAN',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '55',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '55',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '55',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '55',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '55',
        'poLineItem.quantity': 55,
        'poLineItem.unitOfMeasure': '55',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.55,
        'poLineItem.totalAmount': '55',
        'poLineItem.tradingCompanyMarkup': 0.55,
        'poLineItem.netUnitPrice': 549946,
        'poLineItem.shippingType': 'cog',
        'poLineItem.transportationModeCode': 'A28',
        'poLineItem.productCode': '55',
        'product.categoryCode': '55',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '55',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A28',
        'planning.ogacvsmrgac': 'A28',
        'planning.mrgacCapacityConsumptionWeek': '55',
        'planning.planningSeason': 'NIKE A55',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '55',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-55',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'ZTX',
        'poHeader.partnerName': ' ZENTRIX INDUSTRIES LIMITED',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '53',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '53',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '53',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '53',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '53',
        'poLineItem.quantity': 53,
        'poLineItem.unitOfMeasure': '53',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.53,
        'poLineItem.totalAmount': '53',
        'poLineItem.tradingCompanyMarkup': 0.53,
        'poLineItem.netUnitPrice': 529948,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A27',
        'poLineItem.productCode': '53',
        'product.categoryCode': '53',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '53',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A27',
        'planning.ogacvsmrgac': 'A27',
        'planning.mrgacCapacityConsumptionWeek': '53',
        'planning.planningSeason': 'NIKE A53',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '53',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-53',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'ZCK',
        'poHeader.partnerName': ' ZHONGSHAN',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '52',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '52',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '52',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '52',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '52',
        'poLineItem.quantity': 52,
        'poLineItem.unitOfMeasure': '52',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.52,
        'poLineItem.totalAmount': '52',
        'poLineItem.tradingCompanyMarkup': 0.52,
        'poLineItem.netUnitPrice': 519949,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B27',
        'poLineItem.productCode': '52',
        'product.categoryCode': '52',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '52',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B27',
        'planning.ogacvsmrgac': 'B27',
        'planning.mrgacCapacityConsumptionWeek': '52',
        'planning.planningSeason': 'NIKE A52',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '52',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-52',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'YSN',
        'poHeader.partnerName': ' YU SHIN',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '51',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '51',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '51',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '51',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '51',
        'poLineItem.quantity': 51,
        'poLineItem.unitOfMeasure': '51',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.51,
        'poLineItem.totalAmount': '51',
        'poLineItem.tradingCompanyMarkup': 0.51,
        'poLineItem.netUnitPrice': 509950,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A26',
        'poLineItem.productCode': '51',
        'product.categoryCode': '51',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '51',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A26',
        'planning.ogacvsmrgac': 'A26',
        'planning.mrgacCapacityConsumptionWeek': '51',
        'planning.planningSeason': 'NIKE A51',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '51',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-51',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'XG',
        'poHeader.partnerName': ' EVA OVERSEAS INTERNATIONAL LIMITED',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '48',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '48',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '48',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '48',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '48',
        'poLineItem.quantity': 48,
        'poLineItem.unitOfMeasure': '48',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.48,
        'poLineItem.totalAmount': '48',
        'poLineItem.tradingCompanyMarkup': 0.48,
        'poLineItem.netUnitPrice': 479953,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B25',
        'poLineItem.productCode': '48',
        'product.categoryCode': '48',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '48',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B25',
        'planning.ogacvsmrgac': 'B25',
        'planning.mrgacCapacityConsumptionWeek': '48',
        'planning.planningSeason': 'NIKE A48',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '48',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-48',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'YPV',
        'poHeader.partnerName': ' YUPOONG VIETNAM LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '50',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '50',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '50',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '50',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '50',
        'poLineItem.quantity': 50,
        'poLineItem.unitOfMeasure': '50',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.5,
        'poLineItem.totalAmount': '50',
        'poLineItem.tradingCompanyMarkup': 0.5,
        'poLineItem.netUnitPrice': 499951,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B26',
        'poLineItem.productCode': '50',
        'product.categoryCode': '50',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '50',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B26',
        'planning.ogacvsmrgac': 'B26',
        'planning.mrgacCapacityConsumptionWeek': '50',
        'planning.planningSeason': 'NIKE A50',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '50',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-50',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'XK',
        'poHeader.partnerName': ' SALMOND TRADING LIMITED',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '49',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '49',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '49',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '49',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '49',
        'poLineItem.quantity': 49,
        'poLineItem.unitOfMeasure': '49',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-11-11T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.49,
        'poLineItem.totalAmount': '49',
        'poLineItem.tradingCompanyMarkup': 0.49,
        'poLineItem.netUnitPrice': 489952,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A25',
        'poLineItem.productCode': '49',
        'product.categoryCode': '49',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '49',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A25',
        'planning.ogacvsmrgac': 'A25',
        'planning.mrgacCapacityConsumptionWeek': '49',
        'planning.planningSeason': 'NIKE A49',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '49',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-49',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'WDA',
        'poHeader.partnerName': ' SUPERCAP INDUSTRIAL CO LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '46',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '46',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '46',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '46',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '46',
        'poLineItem.quantity': 46,
        'poLineItem.unitOfMeasure': '46',
        'poLineItem.ogacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-21T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.46,
        'poLineItem.totalAmount': '46',
        'poLineItem.tradingCompanyMarkup': 0.46,
        'poLineItem.netUnitPrice': 459955,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B24',
        'poLineItem.productCode': '46',
        'product.categoryCode': '46',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '46',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B24',
        'planning.ogacvsmrgac': 'B24',
        'planning.mrgacCapacityConsumptionWeek': '46',
        'planning.planningSeason': 'NIKE A46',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '46',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-46',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'WNC',
        'poHeader.partnerName': ' WIN HANVERKY LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '47',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '47',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '47',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '47',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '47',
        'poLineItem.quantity': 47,
        'poLineItem.unitOfMeasure': '47',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.47,
        'poLineItem.totalAmount': '47',
        'poLineItem.tradingCompanyMarkup': 0.47,
        'poLineItem.netUnitPrice': 469954,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A24',
        'poLineItem.productCode': '47',
        'product.categoryCode': '47',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '47',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A24',
        'planning.ogacvsmrgac': 'A24',
        'planning.mrgacCapacityConsumptionWeek': '47',
        'planning.planningSeason': 'NIKE A47',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '47',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-47',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'VNT',
        'poHeader.partnerName': ' VANTAGE',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '45',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '45',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '45',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '45',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '45',
        'poLineItem.quantity': 45,
        'poLineItem.unitOfMeasure': '45',
        'poLineItem.ogacDate': '2020-09-30T05:09:00.000+05:30',
        'poLineItem.gacDate': '2020-09-30T05:09:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.45,
        'poLineItem.totalAmount': '45',
        'poLineItem.tradingCompanyMarkup': 0.45,
        'poLineItem.netUnitPrice': 449956,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A23',
        'poLineItem.productCode': '45',
        'product.categoryCode': '45',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '45',
        'planning.mrgacDate': '2020-10-12T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A23',
        'planning.ogacvsmrgac': 'A23',
        'planning.mrgacCapacityConsumptionWeek': '45',
        'planning.planningSeason': 'NIKE A45',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '45',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-45',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'VMR',
        'poHeader.partnerName': ' EVENT MARKETING GROUP',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '44',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '44',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '44',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '44',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '44',
        'poLineItem.quantity': 44,
        'poLineItem.unitOfMeasure': '44',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.44,
        'poLineItem.totalAmount': '44',
        'poLineItem.tradingCompanyMarkup': 0.44,
        'poLineItem.netUnitPrice': 439957,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B23',
        'poLineItem.productCode': '44',
        'product.categoryCode': '44',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '44',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B23',
        'planning.ogacvsmrgac': 'B23',
        'planning.mrgacCapacityConsumptionWeek': '44',
        'planning.planningSeason': 'NIKE A44',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '44',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-44',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'UGV',
        'poHeader.partnerName': ' UPGAIN VIETNAM MANUFACTURING',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '43',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '43',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '43',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '43',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '43',
        'poLineItem.quantity': 43,
        'poLineItem.unitOfMeasure': '43',
        'poLineItem.ogacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.43,
        'poLineItem.totalAmount': '43',
        'poLineItem.tradingCompanyMarkup': 0.43,
        'poLineItem.netUnitPrice': 429958,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A22',
        'poLineItem.productCode': '43',
        'product.categoryCode': '43',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '43',
        'planning.mrgacDate': '2020-11-09T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A22',
        'planning.ogacvsmrgac': 'A22',
        'planning.mrgacCapacityConsumptionWeek': '43',
        'planning.planningSeason': 'NIKE A43',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '43',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-43',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'UFG',
        'poHeader.partnerName': ' UNION FRIEND GARMENT',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '42',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '42',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '42',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '42',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '42',
        'poLineItem.quantity': 42,
        'poLineItem.unitOfMeasure': '42',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.42,
        'poLineItem.totalAmount': '42',
        'poLineItem.tradingCompanyMarkup': 0.42,
        'poLineItem.netUnitPrice': 419959,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B22',
        'poLineItem.productCode': '42',
        'product.categoryCode': '42',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '42',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B22',
        'planning.ogacvsmrgac': 'B22',
        'planning.mrgacCapacityConsumptionWeek': '42',
        'planning.planningSeason': 'NIKE A42',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '42',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258253-42',
        tsUpdate: 1604319342
      },
      {
        'poHeader.poNumber': '4507258253',
        'poHeader.tradingPoNumber': '5803256477',
        'poHeader.partnerNumber': 'TGL',
        'poHeader.partnerName': ' LATITUDES',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '41',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '41',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '41',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '41',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '41',
        'poLineItem.quantity': 41,
        'poLineItem.unitOfMeasure': '41',
        'poLineItem.ogacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': 0.41,
        'poLineItem.totalAmount': '41',
        'poLineItem.tradingCompanyMarkup': 0.41,
        'poLineItem.netUnitPrice': 409960,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A21',
        'poLineItem.productCode': '41',
        'product.categoryCode': '41',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '41',
        'planning.mrgacDate': '2020-11-09T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A21',
        'planning.ogacvsmrgac': 'A21',
        'planning.mrgacCapacityConsumptionWeek': '41',
        'planning.planningSeason': 'NIKE A41',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '41',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507258253-41',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507258190',
        'poHeader.tradingPoNumber': '',
        'poHeader.partnerNumber': 'TGH',
        'poHeader.partnerName': ' NEW HOLLAND APPAREL',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '40',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Canada',
        'poHeader.purchaseOrganizationNumber': '40',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '40',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '40',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '40',
        'poLineItem.quantity': 40,
        'poLineItem.unitOfMeasure': '40',
        'poLineItem.ogacDate': '2020-09-26T05:09:00.000+05:30',
        'poLineItem.gacDate': '2020-10-24T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': '',
        'poLineItem.poFxAdjustment': 0.4,
        'poLineItem.totalAmount': '40',
        'poLineItem.tradingCompanyMarkup': 0.4,
        'poLineItem.netUnitPrice': 399961,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B21',
        'poLineItem.productCode': '40',
        'product.categoryCode': '40',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '40',
        'planning.mrgacDate': '2020-11-03T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B21',
        'planning.ogacvsmrgac': 'B21',
        'planning.mrgacCapacityConsumptionWeek': '40',
        'planning.planningSeason': 'NIKE A40',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '40',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507258190-40',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507254243',
        'poHeader.tradingPoNumber': '8501062967',
        'poHeader.partnerNumber': 'SFI',
        'poHeader.partnerName': ' SOFI CLASSIC SA DE CV',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '39',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Chile',
        'poHeader.purchaseOrganizationNumber': '39',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '39',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '39',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '39',
        'poLineItem.quantity': 39,
        'poLineItem.unitOfMeasure': '39',
        'poLineItem.ogacDate': '2020-11-12T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-12T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062967,
        'poLineItem.poFxAdjustment': 0.39,
        'poLineItem.totalAmount': '39',
        'poLineItem.tradingCompanyMarkup': 0.39,
        'poLineItem.netUnitPrice': 389962,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A20',
        'poLineItem.productCode': '39',
        'product.categoryCode': '39',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '39',
        'planning.mrgacDate': '2021-01-28T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A20',
        'planning.ogacvsmrgac': 'A20',
        'planning.mrgacCapacityConsumptionWeek': '39',
        'planning.planningSeason': 'NIKE A39',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '39',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507254243-39',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252656',
        'poHeader.tradingPoNumber': '5803255102',
        'poHeader.partnerNumber': 'SCI',
        'poHeader.partnerName': ' SPORTS CITY INTERNATIONAL INC',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '38',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '38',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '38',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '38',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '38',
        'poLineItem.quantity': 38,
        'poLineItem.unitOfMeasure': '38',
        'poLineItem.ogacDate': '2020-11-05T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-11T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803255102,
        'poLineItem.poFxAdjustment': 0.38,
        'poLineItem.totalAmount': '38',
        'poLineItem.tradingCompanyMarkup': 0.38,
        'poLineItem.netUnitPrice': 379963,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B20',
        'poLineItem.productCode': '38',
        'product.categoryCode': '38',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '38',
        'planning.mrgacDate': '2020-12-08T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B20',
        'planning.ogacvsmrgac': 'B20',
        'planning.mrgacCapacityConsumptionWeek': '38',
        'planning.planningSeason': 'NIKE A38',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '38',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507252656-38',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252650',
        'poHeader.tradingPoNumber': '5803255112',
        'poHeader.partnerNumber': 'RCT',
        'poHeader.partnerName': ' RANIK GROUP  TOP CROWN CO LTD',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '37',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '37',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '37',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '37',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '37',
        'poLineItem.quantity': 37,
        'poLineItem.unitOfMeasure': '37',
        'poLineItem.ogacDate': '2020-11-24T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-24T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803255112,
        'poLineItem.poFxAdjustment': 0.37,
        'poLineItem.totalAmount': '37',
        'poLineItem.tradingCompanyMarkup': 0.37,
        'poLineItem.netUnitPrice': 369964,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A19',
        'poLineItem.productCode': '37',
        'product.categoryCode': '37',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '37',
        'planning.mrgacDate': '2021-01-12T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A19',
        'planning.ogacvsmrgac': 'A19',
        'planning.mrgacCapacityConsumptionWeek': '37',
        'planning.planningSeason': 'NIKE A37',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '37',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507252650-37',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'QQ',
        'poHeader.partnerName': ' Great Ascent Trading Limited',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '35',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '35',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '35',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '35',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '35',
        'poLineItem.quantity': 35,
        'poLineItem.unitOfMeasure': '35',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.35,
        'poLineItem.totalAmount': '35',
        'poLineItem.tradingCompanyMarkup': 0.35,
        'poLineItem.netUnitPrice': 349966,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A18',
        'poLineItem.productCode': '35',
        'product.categoryCode': '35',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '35',
        'planning.mrgacDate': '2020-11-07T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A18',
        'planning.ogacvsmrgac': 'A18',
        'planning.mrgacCapacityConsumptionWeek': '35',
        'planning.planningSeason': 'NIKE A35',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '35',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507252271-35',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'RCM',
        'poHeader.partnerName': ' ROCKMAUI',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '36',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '36',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '36',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '36',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '36',
        'poLineItem.quantity': 36,
        'poLineItem.unitOfMeasure': '36',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.36,
        'poLineItem.totalAmount': '36',
        'poLineItem.tradingCompanyMarkup': 0.36,
        'poLineItem.netUnitPrice': 359965,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B19',
        'poLineItem.productCode': '36',
        'product.categoryCode': '36',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '36',
        'planning.mrgacDate': '2020-11-09T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B19',
        'planning.ogacvsmrgac': 'B19',
        'planning.mrgacCapacityConsumptionWeek': '36',
        'planning.planningSeason': 'NIKE A36',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '36',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507252271-36',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'QM',
        'poHeader.partnerName': ' Mega International Trading Limited',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '34',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '34',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '34',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '34',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '34',
        'poLineItem.quantity': 34,
        'poLineItem.unitOfMeasure': '34',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.34,
        'poLineItem.totalAmount': '34',
        'poLineItem.tradingCompanyMarkup': 0.34,
        'poLineItem.netUnitPrice': 339967,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B18',
        'poLineItem.productCode': '34',
        'product.categoryCode': '34',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '34',
        'planning.mrgacDate': '2020-11-10T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B18',
        'planning.ogacvsmrgac': 'B18',
        'planning.mrgacCapacityConsumptionWeek': '34',
        'planning.planningSeason': 'NIKE A34',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '34',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507252271-34',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'PCP',
        'poHeader.partnerName': ' PROMOTORA COMERCIALPANTALONERA',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '33',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '33',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '33',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '33',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '33',
        'poLineItem.quantity': 33,
        'poLineItem.unitOfMeasure': '33',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.33,
        'poLineItem.totalAmount': '33',
        'poLineItem.tradingCompanyMarkup': 0.33,
        'poLineItem.netUnitPrice': 329968,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A17',
        'poLineItem.productCode': '33',
        'product.categoryCode': '33',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '33',
        'planning.mrgacDate': '2020-11-07T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A17',
        'planning.ogacvsmrgac': 'A17',
        'planning.mrgacCapacityConsumptionWeek': '33',
        'planning.planningSeason': 'NIKE A33',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '33',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507252271-33',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'PC',
        'poHeader.partnerName': ' Yue Yuen Industrial Limited',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '32',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '32',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '32',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '32',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '32',
        'poLineItem.quantity': 32,
        'poLineItem.unitOfMeasure': '32',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.32,
        'poLineItem.totalAmount': '32',
        'poLineItem.tradingCompanyMarkup': 0.32,
        'poLineItem.netUnitPrice': 319969,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B17',
        'poLineItem.productCode': '32',
        'product.categoryCode': '32',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '32',
        'planning.mrgacDate': '2020-10-28T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B17',
        'planning.ogacvsmrgac': 'B17',
        'planning.mrgacCapacityConsumptionWeek': '32',
        'planning.planningSeason': 'NIKE A32',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '32',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507252271-32',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'ORI',
        'poHeader.partnerName': ' SHANGHAI DOLPHIN TEXTILE PRODUCT CO',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '31',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '31',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '31',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '31',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '31',
        'poLineItem.quantity': 31,
        'poLineItem.unitOfMeasure': '31',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.31,
        'poLineItem.totalAmount': '31',
        'poLineItem.tradingCompanyMarkup': 0.31,
        'poLineItem.netUnitPrice': 309970,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A16',
        'poLineItem.productCode': '31',
        'product.categoryCode': '31',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '31',
        'planning.mrgacDate': '2020-11-05T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A16',
        'planning.ogacvsmrgac': 'A16',
        'planning.mrgacCapacityConsumptionWeek': '31',
        'planning.planningSeason': 'NIKE A31',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '31',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507252271-31',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'NIG',
        'poHeader.partnerName': ' SHENZHOU INTERNATIONAL GROUP',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '29',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '29',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '29',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '29',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '29',
        'poLineItem.quantity': 29,
        'poLineItem.unitOfMeasure': '29',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.29,
        'poLineItem.totalAmount': '29',
        'poLineItem.tradingCompanyMarkup': 0.29,
        'poLineItem.netUnitPrice': 289972,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A15',
        'poLineItem.productCode': '29',
        'product.categoryCode': '29',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '29',
        'planning.mrgacDate': '2020-11-07T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A15',
        'planning.ogacvsmrgac': 'A15',
        'planning.mrgacCapacityConsumptionWeek': '29',
        'planning.planningSeason': 'NIKE A29',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '29',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507252271-29',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'OPT',
        'poHeader.partnerName': ' OPTECH',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '30',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '30',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '30',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '30',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '30',
        'poLineItem.quantity': 30,
        'poLineItem.unitOfMeasure': '30',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.3,
        'poLineItem.totalAmount': '30',
        'poLineItem.tradingCompanyMarkup': 0.3,
        'poLineItem.netUnitPrice': 299971,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B16',
        'poLineItem.productCode': '30',
        'product.categoryCode': '30',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '30',
        'planning.mrgacDate': '2020-11-09T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B16',
        'planning.ogacvsmrgac': 'B16',
        'planning.mrgacCapacityConsumptionWeek': '30',
        'planning.planningSeason': 'NIKE A30',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '30',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507252271-30',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'NAL',
        'poHeader.partnerName': ' ESFERA',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '28',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '28',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '28',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '28',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '28',
        'poLineItem.quantity': 28,
        'poLineItem.unitOfMeasure': '28',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.28,
        'poLineItem.totalAmount': '28',
        'poLineItem.tradingCompanyMarkup': 0.28,
        'poLineItem.netUnitPrice': 279973,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B15',
        'poLineItem.productCode': '28',
        'product.categoryCode': '28',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '28',
        'planning.mrgacDate': '2020-11-07T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B15',
        'planning.ogacvsmrgac': 'B15',
        'planning.mrgacCapacityConsumptionWeek': '28',
        'planning.planningSeason': 'NIKE A28',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '28',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507252271-28',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'MOV',
        'poHeader.partnerName': ' MONVISO KNITWEAR',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '27',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '27',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '27',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '27',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '27',
        'poLineItem.quantity': 27,
        'poLineItem.unitOfMeasure': '27',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.27,
        'poLineItem.totalAmount': '27',
        'poLineItem.tradingCompanyMarkup': 0.27,
        'poLineItem.netUnitPrice': 269974,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'A14',
        'poLineItem.productCode': '27',
        'product.categoryCode': '27',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '27',
        'planning.mrgacDate': '2020-11-07T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A14',
        'planning.ogacvsmrgac': 'A14',
        'planning.mrgacCapacityConsumptionWeek': '27',
        'planning.planningSeason': 'NIKE A27',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '27',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507252271-27',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'LAF',
        'poHeader.partnerName': ' LATITUDES',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '24',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '24',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '24',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '24',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '24',
        'poLineItem.quantity': 24,
        'poLineItem.unitOfMeasure': '24',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.24,
        'poLineItem.totalAmount': '24',
        'poLineItem.tradingCompanyMarkup': 0.24,
        'poLineItem.netUnitPrice': 239977,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B13',
        'poLineItem.productCode': '24',
        'product.categoryCode': '24',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '24',
        'planning.mrgacDate': '2020-10-28T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B13',
        'planning.ogacvsmrgac': 'B13',
        'planning.mrgacCapacityConsumptionWeek': '24',
        'planning.planningSeason': 'NIKE A24',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '24',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507252271-24',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'LCL',
        'poHeader.partnerName': ' LIAND',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '25',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '25',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '25',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '25',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '25',
        'poLineItem.quantity': 25,
        'poLineItem.unitOfMeasure': '25',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.25,
        'poLineItem.totalAmount': '25',
        'poLineItem.tradingCompanyMarkup': 0.25,
        'poLineItem.netUnitPrice': 249976,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A13',
        'poLineItem.productCode': '25',
        'product.categoryCode': '25',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '25',
        'planning.mrgacDate': '2020-11-04T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A13',
        'planning.ogacvsmrgac': 'A13',
        'planning.mrgacCapacityConsumptionWeek': '25',
        'planning.planningSeason': 'NIKE A25',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '25',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507252271-25',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507252271',
        'poHeader.tradingPoNumber': '5803256635',
        'poHeader.partnerNumber': 'MHC',
        'poHeader.partnerName': ' MALHARIA CRISTINA LTDA',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '26',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Europe',
        'poHeader.purchaseOrganizationNumber': '26',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '26',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '26',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '26',
        'poLineItem.quantity': 26,
        'poLineItem.unitOfMeasure': '26',
        'poLineItem.ogacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256635,
        'poLineItem.poFxAdjustment': 0.26,
        'poLineItem.totalAmount': '26',
        'poLineItem.tradingCompanyMarkup': 0.26,
        'poLineItem.netUnitPrice': 259975,
        'poLineItem.shippingType': 'cost',
        'poLineItem.transportationModeCode': 'B14',
        'poLineItem.productCode': '26',
        'product.categoryCode': '26',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '26',
        'planning.mrgacDate': '2020-11-07T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B14',
        'planning.ogacvsmrgac': 'B14',
        'planning.mrgacCapacityConsumptionWeek': '26',
        'planning.planningSeason': 'NIKE A26',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '26',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507252271-26',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507248400',
        'poHeader.tradingPoNumber': '8501062887',
        'poHeader.partnerNumber': 'KKC',
        'poHeader.partnerName': ' JIN MEI KNITWEAR',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '23',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Chile',
        'poHeader.purchaseOrganizationNumber': '23',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '23',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '23',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '23',
        'poLineItem.quantity': 23,
        'poLineItem.unitOfMeasure': '23',
        'poLineItem.ogacDate': '2020-10-30T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-30T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062887,
        'poLineItem.poFxAdjustment': 0.23,
        'poLineItem.totalAmount': '23',
        'poLineItem.tradingCompanyMarkup': 0.23,
        'poLineItem.netUnitPrice': 229978,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A12',
        'poLineItem.productCode': '23',
        'product.categoryCode': '23',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '23',
        'planning.mrgacDate': '2021-01-15T05:01:00.000+05:30',
        'planning.gacvsmrgac': 'A12',
        'planning.ogacvsmrgac': 'A12',
        'planning.mrgacCapacityConsumptionWeek': '23',
        'planning.planningSeason': 'NIKE A23',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '23',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '4507248400-23',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '4507247720',
        'poHeader.tradingPoNumber': '5803254306',
        'poHeader.partnerNumber': 'KEL',
        'poHeader.partnerName': ' KELLWOOD COMPANY',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '22',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '22',
        'poHeader.purchaseOrganizationName': 'Regional Single AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '22',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '22',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '22',
        'poLineItem.quantity': 22,
        'poLineItem.unitOfMeasure': '22',
        'poLineItem.ogacDate': '2020-10-15T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-11-07T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803254306,
        'poLineItem.poFxAdjustment': 0.22,
        'poLineItem.totalAmount': '22',
        'poLineItem.tradingCompanyMarkup': 0.22,
        'poLineItem.netUnitPrice': 219979,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B12',
        'poLineItem.productCode': '22',
        'product.categoryCode': '22',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '22',
        'planning.mrgacDate': '2020-11-17T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B12',
        'planning.ogacvsmrgac': 'B12',
        'planning.mrgacCapacityConsumptionWeek': '22',
        'planning.planningSeason': 'NIKE A22',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '22',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '4507247720-22',
        tsUpdate: 1604319341
      },
      {
        'poHeader.poNumber': '3502751115',
        'poHeader.tradingPoNumber': '8501111365',
        'poHeader.partnerNumber': 'VT',
        'poHeader.partnerName': 'GRAND MAX LIMITED',
        'poHeader.poCreatedOn': '2020-10-01T05:10:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-10-01T05:10:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '280',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '280',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'VN',
        'poHeader.purchaseOrderCompanyCodeNumber': '280',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '402',
        'poHeader.tradingCompanyName': 'DPOC',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': 2,
        'poLineItem.unitOfMeasure': 'EA',
        'poLineItem.ogacDate': '2021-01-20T05:01:00.000+05:30',
        'poLineItem.gacDate': '2021-01-20T05:01:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501056450,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.01,
        'poLineItem.netUnitPrice': '72.57 CNY',
        'poLineItem.shippingType': 'DISTRIBUTION_CENTER',
        'poLineItem.transportationModeCode': 'VL',
        'poLineItem.productCode': '280',
        'product.categoryCode': '280',
        'product.categoryDescription': 'RUNNING',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'FUNDAMENTALS',
        'product.styleNumber': '280',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A1',
        'planning.ogacvsmrgac': 'A1',
        'planning.mrgacCapacityConsumptionWeek': '1',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '280',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502751115-10',
        tsUpdate: 1604319357
      },
      {
        'poHeader.poNumber': '3502702669',
        'poHeader.tradingPoNumber': '8501083388',
        'poHeader.partnerNumber': 'NAC',
        'poHeader.partnerName': 'GRAND MAX LIMITED',
        'poHeader.poCreatedOn': '2020-08-12T05:08:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '280',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '280',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '280',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '402',
        'poHeader.tradingCompanyName': 'DPOC',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': 2,
        'poLineItem.unitOfMeasure': 'EA',
        'poLineItem.ogacDate': '2021-01-20T05:01:00.000+05:30',
        'poLineItem.gacDate': '2021-01-20T05:01:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501056450,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.01,
        'poLineItem.netUnitPrice': '72.57 CNY',
        'poLineItem.shippingType': 'DISTRIBUTION_CENTER',
        'poLineItem.transportationModeCode': 'VL',
        'poLineItem.productCode': '280',
        'product.categoryCode': '280',
        'product.categoryDescription': 'RUNNING',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'FUNDAMENTALS',
        'product.styleNumber': '280',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A1',
        'planning.ogacvsmrgac': 'A1',
        'planning.mrgacCapacityConsumptionWeek': '1',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '280',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502702669-10',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502691116',
        'poHeader.tradingPoNumber': '8501063278',
        'poHeader.partnerNumber': 'JPT',
        'poHeader.partnerName': ' JOHN PETER',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '21',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '21',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '21',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '21',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '21',
        'poLineItem.quantity': 21,
        'poLineItem.unitOfMeasure': '21',
        'poLineItem.ogacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501063278,
        'poLineItem.poFxAdjustment': 0.21,
        'poLineItem.totalAmount': '21',
        'poLineItem.tradingCompanyMarkup': 0.21,
        'poLineItem.netUnitPrice': 209980,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A11',
        'poLineItem.productCode': '21',
        'product.categoryCode': '21',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '21',
        'planning.mrgacDate': '2020-11-11T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A11',
        'planning.ogacvsmrgac': 'A11',
        'planning.mrgacCapacityConsumptionWeek': '21',
        'planning.planningSeason': 'NIKE A21',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '21',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502691116-21',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502691116',
        'poHeader.tradingPoNumber': '8501063278',
        'poHeader.partnerNumber': 'IPS',
        'poHeader.partnerName': ' IMPACT SPORTS',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '19',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '19',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '19',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '19',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '19',
        'poLineItem.quantity': 19,
        'poLineItem.unitOfMeasure': '19',
        'poLineItem.ogacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-28T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 7878787887,
        'poLineItem.tradingCompanyFob': 8501063278,
        'poLineItem.poFxAdjustment': 0.19,
        'poLineItem.totalAmount': '19',
        'poLineItem.tradingCompanyMarkup': 0.19,
        'poLineItem.netUnitPrice': 189982,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A10',
        'poLineItem.productCode': '19',
        'product.categoryCode': '19',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '19',
        'planning.mrgacDate': '2020-11-11T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A10',
        'planning.ogacvsmrgac': 'A10',
        'planning.mrgacCapacityConsumptionWeek': '19',
        'planning.planningSeason': 'NIKE A19',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '19',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502691116-19',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502691116',
        'poHeader.tradingPoNumber': '8501063278',
        'poHeader.partnerNumber': 'JON',
        'poHeader.partnerName': ' JOHNSON SPORTSWEAR LTD',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '20',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '20',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '20',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '20',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '20',
        'poLineItem.quantity': 20,
        'poLineItem.unitOfMeasure': '20',
        'poLineItem.ogacDate': '2020-11-03T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-03T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501063278,
        'poLineItem.poFxAdjustment': 0.2,
        'poLineItem.totalAmount': '20',
        'poLineItem.tradingCompanyMarkup': 0.2,
        'poLineItem.netUnitPrice': 199981,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B11',
        'poLineItem.productCode': '20',
        'product.categoryCode': '20',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '20',
        'planning.mrgacDate': '2020-11-11T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B11',
        'planning.ogacvsmrgac': 'B11',
        'planning.mrgacCapacityConsumptionWeek': '20',
        'planning.planningSeason': 'NIKE A20',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '20',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502691116-20',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502691116',
        'poHeader.tradingPoNumber': '8501063278',
        'poHeader.partnerNumber': 'IM',
        'poHeader.partnerName': ' Mega International Trading Limited',
        'poHeader.poCreatedOn': '2020-07-07T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-07T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '18',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '18',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '18',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '18',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '18',
        'poLineItem.quantity': 18,
        'poLineItem.unitOfMeasure': '18',
        'poLineItem.ogacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-04T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501063278,
        'poLineItem.poFxAdjustment': 0.18,
        'poLineItem.totalAmount': '18',
        'poLineItem.tradingCompanyMarkup': 0.18,
        'poLineItem.netUnitPrice': 179983,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B10',
        'poLineItem.productCode': '18',
        'product.categoryCode': '18',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '18',
        'planning.mrgacDate': '2020-11-18T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B10',
        'planning.ogacvsmrgac': 'B10',
        'planning.mrgacCapacityConsumptionWeek': '18',
        'planning.planningSeason': 'NIKE A18',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '18',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502691116-18',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502690733',
        'poHeader.tradingPoNumber': '8501063005',
        'poHeader.partnerNumber': 'HIN',
        'poHeader.partnerName': ' HINDUJA SPORTSWEAR',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '17',
        'poHeader.purchaseGroupOrganizationName': 'Aprl SEA',
        'poHeader.purchaseOrganizationNumber': '17',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '17',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '17',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '17',
        'poLineItem.quantity': 17,
        'poLineItem.unitOfMeasure': '17',
        'poLineItem.ogacDate': '2020-09-30T05:09:00.000+05:30',
        'poLineItem.gacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501063005,
        'poLineItem.poFxAdjustment': 0.17,
        'poLineItem.totalAmount': '17',
        'poLineItem.tradingCompanyMarkup': 0.17,
        'poLineItem.netUnitPrice': 169984,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A9',
        'poLineItem.productCode': '17',
        'product.categoryCode': '17',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '17',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A9',
        'planning.ogacvsmrgac': 'A9',
        'planning.mrgacCapacityConsumptionWeek': '17',
        'planning.planningSeason': 'NIKE A17',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '17',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502690733-17',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502690606',
        'poHeader.tradingPoNumber': '8501062992',
        'poHeader.partnerNumber': 'HEM',
        'poHeader.partnerName': ' HELA CLOTHING PVT LTD',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '16',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '16',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'BR',
        'poHeader.purchaseOrderCompanyCodeNumber': '16',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '16',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '16',
        'poLineItem.quantity': 16,
        'poLineItem.unitOfMeasure': '16',
        'poLineItem.ogacDate': '2020-10-30T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-30T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062992,
        'poLineItem.poFxAdjustment': 0.16,
        'poLineItem.totalAmount': '16',
        'poLineItem.tradingCompanyMarkup': 0.16,
        'poLineItem.netUnitPrice': 159985,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B9',
        'poLineItem.productCode': '16',
        'product.categoryCode': '16',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '16',
        'planning.mrgacDate': '2020-11-23T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B9',
        'planning.ogacvsmrgac': 'B9',
        'planning.mrgacCapacityConsumptionWeek': '16',
        'planning.planningSeason': 'NIKE A16',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '16',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502690606-16',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502690605',
        'poHeader.tradingPoNumber': '8501062993',
        'poHeader.partnerNumber': 'GIH',
        'poHeader.partnerName': ' GIBOR DEMAND PULL',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '15',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '15',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '15',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '15',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '15',
        'poLineItem.quantity': 15,
        'poLineItem.unitOfMeasure': '15',
        'poLineItem.ogacDate': '2020-10-30T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-30T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062993,
        'poLineItem.poFxAdjustment': 0.15,
        'poLineItem.totalAmount': '15',
        'poLineItem.tradingCompanyMarkup': 0.15,
        'poLineItem.netUnitPrice': 149986,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A8',
        'poLineItem.productCode': '15',
        'product.categoryCode': '15',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '15',
        'planning.mrgacDate': '2020-11-23T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A8',
        'planning.ogacvsmrgac': 'A8',
        'planning.mrgacCapacityConsumptionWeek': '15',
        'planning.planningSeason': 'NIKE A15',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '15',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502690605-15',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502690604',
        'poHeader.tradingPoNumber': '8501062996',
        'poHeader.partnerNumber': 'GEX',
        'poHeader.partnerName': ' GOKALDAS EXPORTS',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '14',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '14',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '14',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '14',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '14',
        'poLineItem.quantity': 14,
        'poLineItem.unitOfMeasure': '14',
        'poLineItem.ogacDate': '2020-10-30T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-30T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062996,
        'poLineItem.poFxAdjustment': 0.14,
        'poLineItem.totalAmount': '14',
        'poLineItem.tradingCompanyMarkup': 0.14,
        'poLineItem.netUnitPrice': 139987,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B8',
        'poLineItem.productCode': '14',
        'product.categoryCode': '14',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '14',
        'planning.mrgacDate': '2020-12-14T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B8',
        'planning.ogacvsmrgac': 'B8',
        'planning.mrgacCapacityConsumptionWeek': '14',
        'planning.planningSeason': 'NIKE A14',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '14',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502690604-14',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502690603',
        'poHeader.tradingPoNumber': '8501062986',
        'poHeader.partnerNumber': 'FHS',
        'poHeader.partnerName': ' FRANKHAUS INTERNATIONAL CORP',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '13',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '13',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '13',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '13',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '13',
        'poLineItem.quantity': 13,
        'poLineItem.unitOfMeasure': '13',
        'poLineItem.ogacDate': '2020-11-15T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-15T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062986,
        'poLineItem.poFxAdjustment': 0.13,
        'poLineItem.totalAmount': '13',
        'poLineItem.tradingCompanyMarkup': 0.13,
        'poLineItem.netUnitPrice': 129988,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A7',
        'poLineItem.productCode': '13',
        'product.categoryCode': '13',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '13',
        'planning.mrgacDate': '2020-12-18T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A7',
        'planning.ogacvsmrgac': 'A7',
        'planning.mrgacCapacityConsumptionWeek': '13',
        'planning.planningSeason': 'NIKE A13',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '13',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502690603-13',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502690602',
        'poHeader.tradingPoNumber': '8501062982',
        'poHeader.partnerNumber': 'FEC',
        'poHeader.partnerName': ' FE NEW CENTURY INDUSTRY',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '12',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '12',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '12',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '12',
        'poHeader.tradingCompanyName': 'NIKE DPOC',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '12',
        'poLineItem.quantity': 12,
        'poLineItem.unitOfMeasure': '12',
        'poLineItem.ogacDate': '2020-11-09T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-09T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062982,
        'poLineItem.poFxAdjustment': 0.12,
        'poLineItem.totalAmount': '12',
        'poLineItem.tradingCompanyMarkup': 0.12,
        'poLineItem.netUnitPrice': 119989,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B7',
        'poLineItem.productCode': '12',
        'product.categoryCode': '12',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '12',
        'planning.mrgacDate': '2020-12-09T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B7',
        'planning.ogacvsmrgac': 'B7',
        'planning.mrgacCapacityConsumptionWeek': '12',
        'planning.planningSeason': 'NIKE A12',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '12',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502690602-12',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502690601',
        'poHeader.tradingPoNumber': '8501062985',
        'poHeader.partnerNumber': 'EAG',
        'poHeader.partnerName': ' Eagle Nice Development Limited',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '11',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '11',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '11',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '11',
        'poHeader.tradingCompanyName': 'NIKE DPOC',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '11',
        'poLineItem.quantity': 11,
        'poLineItem.unitOfMeasure': '11',
        'poLineItem.ogacDate': '2020-11-26T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-26T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062985,
        'poLineItem.poFxAdjustment': 0.11,
        'poLineItem.totalAmount': '11',
        'poLineItem.tradingCompanyMarkup': 0.11,
        'poLineItem.netUnitPrice': 109990,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A6',
        'poLineItem.productCode': '11',
        'product.categoryCode': '11',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '11',
        'planning.mrgacDate': '2020-12-29T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A6',
        'planning.ogacvsmrgac': 'A6',
        'planning.mrgacCapacityConsumptionWeek': '11',
        'planning.planningSeason': 'NIKE A11',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '11',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502690601-11',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502689940',
        'poHeader.tradingPoNumber': '8501062984',
        'poHeader.partnerNumber': 'E3G',
        'poHeader.partnerName': ' Gibor Sport Active Wear LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '10',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Korea',
        'poHeader.purchaseOrganizationNumber': '10',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '10',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '10',
        'poHeader.tradingCompanyName': 'NIKE DPOC',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': 10,
        'poLineItem.unitOfMeasure': '10',
        'poLineItem.ogacDate': '2020-09-26T05:09:00.000+05:30',
        'poLineItem.gacDate': '2020-09-26T05:09:00.000+05:30',
        'poLineItem.purchaseOrderFob': 7878787878,
        'poLineItem.tradingCompanyFob': 8501062935,
        'poLineItem.poFxAdjustment': 0.1,
        'poLineItem.totalAmount': '10',
        'poLineItem.tradingCompanyMarkup': 0.1,
        'poLineItem.netUnitPrice': 99991,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B6',
        'poLineItem.productCode': '10',
        'product.categoryCode': '10',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '10',
        'planning.mrgacDate': '2020-10-14T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B6',
        'planning.ogacvsmrgac': 'B6',
        'planning.mrgacCapacityConsumptionWeek': '10',
        'planning.planningSeason': 'NIKE A10',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '10',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502689940-10',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502688940',
        'poHeader.tradingPoNumber': '8501062984',
        'poHeader.partnerNumber': 'DGV',
        'poHeader.partnerName': ' DELTA GALIL INDUSTRIES LTD',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '9',
        'poHeader.purchaseGroupOrganizationName': 'Aprl SEA',
        'poHeader.purchaseOrganizationNumber': '9',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NEW DOC',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '9',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '9',
        'poHeader.tradingCompanyName': 'NIKE DPOC',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '9',
        'poLineItem.quantity': 9,
        'poLineItem.unitOfMeasure': '9',
        'poLineItem.ogacDate': '2020-10-07T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-14T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': '',
        'poLineItem.poFxAdjustment': 0.09,
        'poLineItem.totalAmount': '9',
        'poLineItem.tradingCompanyMarkup': 0.09,
        'poLineItem.netUnitPrice': 89992,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A5',
        'poLineItem.productCode': '9',
        'product.categoryCode': '9',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '9',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A5',
        'planning.ogacvsmrgac': 'A5',
        'planning.mrgacCapacityConsumptionWeek': '9',
        'planning.planningSeason': 'NIKE A9',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '9',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502688940-9',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502687200',
        'poHeader.tradingPoNumber': '8501062984',
        'poHeader.partnerNumber': 'DER',
        'poHeader.partnerName': ' DER WILL SA',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '8',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '8',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '8',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '8',
        'poHeader.tradingCompanyName': 'NIKE DPOC',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '8',
        'poLineItem.quantity': 8,
        'poLineItem.unitOfMeasure': '8',
        'poLineItem.ogacDate': '2020-10-27T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-27T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': '',
        'poLineItem.poFxAdjustment': 0.08,
        'poLineItem.totalAmount': '8',
        'poLineItem.tradingCompanyMarkup': 0.08,
        'poLineItem.netUnitPrice': 79993,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B5',
        'poLineItem.productCode': '8',
        'product.categoryCode': '8',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '8',
        'planning.mrgacDate': '2020-11-23T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B5',
        'planning.ogacvsmrgac': 'B5',
        'planning.mrgacCapacityConsumptionWeek': '8',
        'planning.planningSeason': 'NIKE A8',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '8',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502687200-8',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502687198',
        'poHeader.tradingPoNumber': '3502687200',
        'poHeader.partnerNumber': 'CCD',
        'poHeader.partnerName': ' CHUAN CHENG HAT CO LTD',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '6',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '6',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '6',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '6',
        'poHeader.tradingCompanyName': 'NIKE DPOC',
        'poLineItem.poLineStatus': 'Cancelled',
        'poLineItem.poLineItemNumber': '6',
        'poLineItem.quantity': 6,
        'poLineItem.unitOfMeasure': '6',
        'poLineItem.ogacDate': '2020-11-26T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-26T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062984,
        'poLineItem.poFxAdjustment': 0.06,
        'poLineItem.totalAmount': '6',
        'poLineItem.tradingCompanyMarkup': 0.06,
        'poLineItem.netUnitPrice': 59995,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B4',
        'poLineItem.productCode': '6',
        'product.categoryCode': '6',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '6',
        'planning.mrgacDate': '2020-12-28T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B4',
        'planning.ogacvsmrgac': 'B4',
        'planning.mrgacCapacityConsumptionWeek': '6',
        'planning.planningSeason': 'NIKE A6',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '6',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502687198-6',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502687198',
        'poHeader.tradingPoNumber': '8501062984',
        'poHeader.partnerNumber': 'CEM',
        'poHeader.partnerName': ' ECLAT TEXTILE CO LTD',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '7',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '7',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '7',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '7',
        'poHeader.tradingCompanyName': 'NIKE DPOC',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '7',
        'poLineItem.quantity': 7,
        'poLineItem.unitOfMeasure': '7',
        'poLineItem.ogacDate': '2020-11-26T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-26T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': '7878787878',
        'poLineItem.tradingCompanyFob': 8501062994,
        'poLineItem.poFxAdjustment': 0.07,
        'poLineItem.totalAmount': '7',
        'poLineItem.tradingCompanyMarkup': 0.07,
        'poLineItem.netUnitPrice': 69994,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A4',
        'poLineItem.productCode': '7',
        'product.categoryCode': '7',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '7',
        'planning.mrgacDate': '2020-12-18T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A4',
        'planning.ogacvsmrgac': 'A4',
        'planning.mrgacCapacityConsumptionWeek': '7',
        'planning.planningSeason': 'NIKE A7',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '7',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502687198-7',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502687197',
        'poHeader.tradingPoNumber': '8501062984',
        'poHeader.partnerNumber': 'B6',
        'poHeader.partnerName': ' DASS NORDESTE CALCADOS E ARTIGOSE',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '5',
        'poHeader.purchaseGroupOrganizationName': 'Aprl NBA',
        'poHeader.purchaseOrganizationNumber': '5',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '5',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '5',
        'poHeader.tradingCompanyName': 'NIKE DPOC',
        'poLineItem.poLineStatus': 'Closed',
        'poLineItem.poLineItemNumber': '5',
        'poLineItem.quantity': 5,
        'poLineItem.unitOfMeasure': '5',
        'poLineItem.ogacDate': '2020-11-26T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-26T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062984,
        'poLineItem.poFxAdjustment': 0.05,
        'poLineItem.totalAmount': '5',
        'poLineItem.tradingCompanyMarkup': 0.05,
        'poLineItem.netUnitPrice': 49996,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A3',
        'poLineItem.productCode': '5',
        'product.categoryCode': '5',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '5',
        'planning.mrgacDate': '2020-12-28T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'A3',
        'planning.ogacvsmrgac': 'A3',
        'planning.mrgacCapacityConsumptionWeek': '5',
        'planning.planningSeason': 'NIKE A5',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '5',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502687197-5',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502687193',
        'poHeader.tradingPoNumber': '8501062995',
        'poHeader.partnerNumber': 'B4',
        'poHeader.partnerName': ' ANIGER CALCADOS SUPRIMENTOSE',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '4',
        'poHeader.purchaseGroupOrganizationName': 'Aprl Promo NBA',
        'poHeader.purchaseOrganizationNumber': '4',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'TH',
        'poHeader.purchaseOrderCompanyCodeNumber': '4',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '4',
        'poHeader.tradingCompanyName': 'NIKE DPOC',
        'poLineItem.poLineStatus': 'Rejected',
        'poLineItem.poLineItemNumber': '4',
        'poLineItem.quantity': 4,
        'poLineItem.unitOfMeasure': '4',
        'poLineItem.ogacDate': '2020-11-16T05:11:00.000+05:30',
        'poLineItem.gacDate': '2020-11-16T05:11:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501062995,
        'poLineItem.poFxAdjustment': 0.04,
        'poLineItem.totalAmount': '4',
        'poLineItem.tradingCompanyMarkup': 0.04,
        'poLineItem.netUnitPrice': 39997,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B3',
        'poLineItem.productCode': '4',
        'product.categoryCode': '4',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '4',
        'planning.mrgacDate': '2020-12-08T05:12:00.000+05:30',
        'planning.gacvsmrgac': 'B3',
        'planning.ogacvsmrgac': 'B3',
        'planning.mrgacCapacityConsumptionWeek': '4',
        'planning.planningSeason': 'NIKE A4',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '4',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502687193-4',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502685006',
        'poHeader.tradingPoNumber': '8501056450',
        'poHeader.partnerNumber': 'A04 ',
        'poHeader.partnerName': 'JORDAN LEGACY',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': 1,
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Hong Kong',
        'poHeader.purchaseOrganizationNumber': 1,
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': 1,
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': 1,
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': 1,
        'poLineItem.unitOfMeasure': 1000,
        'poLineItem.ogacDate': '2020-10-10T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-09T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501056450,
        'poLineItem.poFxAdjustment': 0.01,
        'poLineItem.totalAmount': 1,
        'poLineItem.tradingCompanyMarkup': 0.01,
        'poLineItem.netUnitPrice': 10000,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'VL',
        'poLineItem.productCode': '1',
        'product.categoryCode': 1,
        'product.categoryDescription': 'Jay NIkE',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'Jay NIKE',
        'product.styleNumber': '1',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A1',
        'planning.ogacvsmrgac': 'A1',
        'planning.mrgacCapacityConsumptionWeek': 1,
        'planning.planningSeason': 'NIKE A1',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': 1,
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 1,
        id: '3502685006-10',
        tsUpdate: 1604319354
      },
      {
        'poHeader.poNumber': '3502684686',
        'poHeader.tradingPoNumber': '8501062969',
        'poHeader.partnerNumber': 'A22',
        'poHeader.partnerName': ' WACOAL-VIETNAM',
        'poHeader.poCreatedOn': '2020-07-02T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-02T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '3',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Japan',
        'poHeader.purchaseOrganizationNumber': '3',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '3',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '3',
        'poHeader.tradingCompanyName': 'DPOC',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '3',
        'poLineItem.quantity': 3,
        'poLineItem.unitOfMeasure': '3',
        'poLineItem.ogacDate': '2020-10-31T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-31T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': '2222222222',
        'poLineItem.tradingCompanyFob': 8501062969,
        'poLineItem.poFxAdjustment': 0.03,
        'poLineItem.totalAmount': '3',
        'poLineItem.tradingCompanyMarkup': 0.03,
        'poLineItem.netUnitPrice': 29998,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A2',
        'poLineItem.productCode': '3',
        'product.categoryCode': '3',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '3',
        'planning.mrgacDate': '2020-11-24T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'A2',
        'planning.ogacvsmrgac': 'A2',
        'planning.mrgacCapacityConsumptionWeek': '3',
        'planning.planningSeason': 'NIKE A3',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '3',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502684686-3',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502683495',
        'poHeader.tradingPoNumber': '8501056451',
        'poHeader.partnerNumber': 'A21',
        'poHeader.partnerName': ' WACOAL-JAPAN',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '2',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Hong Kong',
        'poHeader.purchaseOrganizationNumber': '2',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '2',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '2',
        'poHeader.tradingCompanyName': 'DPOC',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '2',
        'poLineItem.quantity': 2,
        'poLineItem.unitOfMeasure': '2',
        'poLineItem.ogacDate': '2020-10-10T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-09T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': '1111111111',
        'poLineItem.tradingCompanyFob': 8501056451,
        'poLineItem.poFxAdjustment': 0.02,
        'poLineItem.totalAmount': '2',
        'poLineItem.tradingCompanyMarkup': 0.02,
        'poLineItem.netUnitPrice': 19999,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B2',
        'poLineItem.productCode': '2',
        'product.categoryCode': '2',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '2',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B2',
        'planning.ogacvsmrgac': 'B2',
        'planning.mrgacCapacityConsumptionWeek': '2',
        'planning.planningSeason': 'NIKE A2',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '2',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502683495-2',
        tsUpdate: 1604319340
      },
      {
        'poHeader.poNumber': '3502683494',
        'poHeader.tradingPoNumber': '8501056450',
        'poHeader.partnerNumber': 'LIP01',
        'poHeader.partnerName': ' MARUBENI-SONGGANG',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '1',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Hong Kong',
        'poHeader.purchaseOrganizationNumber': '1',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '1',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '1',
        'poHeader.tradingCompanyName': 'DPOC',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '1',
        'poLineItem.quantity': 1,
        'poLineItem.unitOfMeasure': '1',
        'poLineItem.ogacDate': '2020-10-10T05:10:00+05:30',
        'poLineItem.gacDate': '2020-10-09T05:10:00+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501056450,
        'poLineItem.poFxAdjustment': 0.01,
        'poLineItem.totalAmount': '1',
        'poLineItem.tradingCompanyMarkup': 0.01,
        'poLineItem.netUnitPrice': 10000,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'A1',
        'poLineItem.productCode': '1',
        'product.categoryCode': '1',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '1',
        'planning.mrgacDate': '2020-10-19T05:10:00+05:30',
        'planning.gacvsmrgac': 'A1',
        'planning.ogacvsmrgac': 'A1',
        'planning.mrgacCapacityConsumptionWeek': '1',
        'planning.planningSeason': 'NIKE A1',
        'salesOrder.customerShipTo': 'YES',
        'logistics.originReceiptSize': '1',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502683494-1',
        tsUpdate: 1604319339
      },
      {
        'poHeader.poNumber': '3502683000',
        'poHeader.tradingPoNumber': '8501056451',
        'poHeader.partnerNumber': 'A21',
        'poHeader.partnerName': ' WACOAL-JAPAN',
        'poHeader.poCreatedOn': '2020-07-01T05:07:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '2',
        'poHeader.purchaseGroupOrganizationName': 'Ftwr Hong Kong',
        'poHeader.purchaseOrganizationNumber': '2',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'NIKE',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '2',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '2',
        'poHeader.tradingCompanyName': 'DPOC',
        'poLineItem.poLineStatus': 'Accepted',
        'poLineItem.poLineItemNumber': '1',
        'poLineItem.quantity': 2,
        'poLineItem.unitOfMeasure': '2',
        'poLineItem.ogacDate': '2020-10-10T05:10:00.000+05:30',
        'poLineItem.gacDate': '2020-10-09T05:10:00.000+05:30',
        'poLineItem.purchaseOrderFob': '1111111111',
        'poLineItem.tradingCompanyFob': 8501056451,
        'poLineItem.poFxAdjustment': 0.02,
        'poLineItem.totalAmount': '2',
        'poLineItem.tradingCompanyMarkup': 0.02,
        'poLineItem.netUnitPrice': 19999,
        'poLineItem.shippingType': 'FOC',
        'poLineItem.transportationModeCode': 'B2',
        'poLineItem.productCode': '2',
        'product.categoryCode': '2',
        'product.categoryDescription': 'dpoc user category description',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'dpoc user category description',
        'product.styleNumber': '2',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B2',
        'planning.ogacvsmrgac': 'B2',
        'planning.mrgacCapacityConsumptionWeek': '2',
        'planning.planningSeason': 'NIKE A2',
        'salesOrder.customerShipTo': 'NO',
        'logistics.originReceiptSize': '2',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502683495-222',
        tsUpdate: 1604319357
      },
      {
        'poHeader.poNumber': '3502677349',
        'poHeader.tradingPoNumber': '8501104973',
        'poHeader.partnerNumber': 'NAC',
        'poHeader.partnerName': 'nike ',
        'poHeader.poCreatedOn': '2020-09-21T05:09:00.000+05:30',
        'poHeader.poEffectiveFrom': '2020-07-01T05:07:00.000+05:30',
        'poHeader.purchaseGroupOrganizationNumber': '280',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '280',
        'poHeader.purchaseOrganizationName': 'Regional-Multi AP',
        'poHeader.poDocTypeCode': 'DPCO',
        'poHeader.poDocType': 'Nike DPOC Order Document Type Description',
        'poHeader.manufacturingCountryofOrigin': 'manufacturingCountryofOrigin_value',
        'poHeader.purchaseOrderCompanyCodeNumber': '280',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '402',
        'poHeader.tradingCompanyName': 'DPOC',
        'poLineItem.poLineStatus': 'Unissued',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': 2,
        'poLineItem.unitOfMeasure': 'EA',
        'poLineItem.ogacDate': '2021-01-20T05:01:00.000+05:30',
        'poLineItem.gacDate': '2021-01-20T05:01:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 8501056450,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.01,
        'poLineItem.netUnitPrice': '72.57 CNY',
        'poLineItem.shippingType': 'DISTRIBUTION_CENTER',
        'poLineItem.transportationModeCode': 'A1',
        'poLineItem.productCode': '280',
        'product.categoryCode': '280',
        'product.categoryDescription': 'RUNNING',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'FUNDAMENTALS',
        'product.styleNumber': '280',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A1',
        'planning.ogacvsmrgac': 'A1',
        'planning.mrgacCapacityConsumptionWeek': '1',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '280',
        'manufacturing.fobMismatchIndicator': true,
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502677349-10',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670347',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '368',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '334',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '368',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '30',
        'poLineItem.quantity': '5',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-05T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-05T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.89,
        'poLineItem.netUnitPrice': '$ 19.107',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'A31',
        'poLineItem.productCode': '368',
        'product.categoryCode': '368',
        'product.categoryDescription': 'JORDAN LEGACY',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'JORDAN LEGACY',
        'product.styleNumber': '368',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A27',
        'planning.ogacvsmrgac': 'A27',
        'planning.mrgacCapacityConsumptionWeek': '89',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '368',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502670347-30',
        tsUpdate: 1604319357
      },
      {
        'poHeader.poNumber': '3502670347',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '367',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '333',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '367',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '20',
        'poLineItem.quantity': '2',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.88,
        'poLineItem.netUnitPrice': '$ 19.106',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'B31',
        'poLineItem.productCode': '367',
        'product.categoryCode': '367',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'WMNS CORE TENNIS',
        'product.styleNumber': '367',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B27',
        'planning.ogacvsmrgac': 'B27',
        'planning.mrgacCapacityConsumptionWeek': '88',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '367',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502670347-20',
        tsUpdate: 1604319357
      },
      {
        'poHeader.poNumber': '3502670347',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '366',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '334',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '366',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': '1',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.87,
        'poLineItem.netUnitPrice': '$ 19.105',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'A30',
        'poLineItem.productCode': '366',
        'product.categoryCode': '366',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'WMNS CORE TENNIS',
        'product.styleNumber': '366',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A26',
        'planning.ogacvsmrgac': 'A26',
        'planning.mrgacCapacityConsumptionWeek': '87',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '366',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502670347-10',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670346',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '365',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '333',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '365',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '90',
        'poLineItem.quantity': '10',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.86,
        'poLineItem.netUnitPrice': '$ 19.104',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'B30',
        'poLineItem.productCode': '365',
        'product.categoryCode': '365',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'WMNS CORE TENNIS',
        'product.styleNumber': '365',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B26',
        'planning.ogacvsmrgac': 'B26',
        'planning.mrgacCapacityConsumptionWeek': '86',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '365',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502670346-90',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670346',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '364',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '332',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '364',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '80',
        'poLineItem.quantity': '150',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.85,
        'poLineItem.netUnitPrice': '$ 19.103',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'A29',
        'poLineItem.productCode': '364',
        'product.categoryCode': '364',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'MENS CORE TENNIS',
        'product.styleNumber': '364',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A25',
        'planning.ogacvsmrgac': 'A25',
        'planning.mrgacCapacityConsumptionWeek': '85',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '364',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502670346-80',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670346',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '363',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '331',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '363',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '70',
        'poLineItem.quantity': '200',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.84,
        'poLineItem.netUnitPrice': '$ 19.102',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'B29',
        'poLineItem.productCode': '363',
        'product.categoryCode': '363',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'MENS CORE TENNIS',
        'product.styleNumber': '363',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B25',
        'planning.ogacvsmrgac': 'B25',
        'planning.mrgacCapacityConsumptionWeek': '84',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '363',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502670346-70',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670346',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '360',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '328',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '360',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '40',
        'poLineItem.quantity': '20',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2021-01-02T05:01:00.000+05:30',
        'poLineItem.gacDate': '2021-01-02T05:01:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.81,
        'poLineItem.netUnitPrice': '$ 19.99',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'B31',
        'poLineItem.productCode': '360',
        'product.categoryCode': '360',
        'product.categoryDescription': 'SOCCER',
        'product.subCategoryCode': 'PO NUMBER',
        'product.subCategoryDescription': 'MENS SOCCER PERFORM',
        'product.styleNumber': '360',
        'planning.mrgacDate': '2020-11-02T05:11:00.000+05:30',
        'planning.gacvsmrgac': 'B28',
        'planning.ogacvsmrgac': 'B28',
        'planning.mrgacCapacityConsumptionWeek': '81',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '360',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502670346-40',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670346',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '362',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '330',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '362',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '60',
        'poLineItem.quantity': '100',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2021-01-02T05:01:00.000+05:30',
        'poLineItem.gacDate': '2021-01-02T05:01:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.83,
        'poLineItem.netUnitPrice': '$ 19.101',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'A28',
        'poLineItem.productCode': '362',
        'product.categoryCode': '362',
        'product.categoryDescription': 'JORDAN LEGACY',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'JORDAN LEGACY',
        'product.styleNumber': '362',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A24',
        'planning.ogacvsmrgac': 'A24',
        'planning.mrgacCapacityConsumptionWeek': '83',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '362',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502670346-60',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670346',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '361',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '329',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '361',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '50',
        'poLineItem.quantity': '100',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2021-01-02T05:01:00.000+05:30',
        'poLineItem.gacDate': '2021-01-02T05:01:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.82,
        'poLineItem.netUnitPrice': '$ 19.100',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'A31',
        'poLineItem.productCode': '361',
        'product.categoryCode': '361',
        'product.categoryDescription': 'SOCCER',
        'product.subCategoryCode': 'CATEGORY',
        'product.subCategoryDescription': 'MENS SOCCER PERFORM',
        'product.styleNumber': '361',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A28',
        'planning.ogacvsmrgac': 'A28',
        'planning.mrgacCapacityConsumptionWeek': '82',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '361',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502670346-50',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670346',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '359',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '327',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '359',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '30',
        'poLineItem.quantity': '5',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-05T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-05T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.8,
        'poLineItem.netUnitPrice': '$ 19.98',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'A30',
        'poLineItem.productCode': '359',
        'product.categoryCode': '359',
        'product.categoryDescription': 'JORDAN LEGACY',
        'product.subCategoryCode': 'NIKE DPOC',
        'product.subCategoryDescription': 'JORDAN LEGACY',
        'product.styleNumber': '359',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A27',
        'planning.ogacvsmrgac': 'A27',
        'planning.mrgacCapacityConsumptionWeek': '80',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '359',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502670346-30',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670346',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '358',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '326',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '358',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '20',
        'poLineItem.quantity': '2',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.79,
        'poLineItem.netUnitPrice': '$ 19.97',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'B30',
        'poLineItem.productCode': '358',
        'product.categoryCode': '358',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'DPOC',
        'product.subCategoryDescription': 'WMNS CORE TENNIS',
        'product.styleNumber': '358',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B27',
        'planning.ogacvsmrgac': 'B27',
        'planning.mrgacCapacityConsumptionWeek': '79',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '358',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502670346-20',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670346',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '357',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '330',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '357',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': '1',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.78,
        'poLineItem.netUnitPrice': '$ 19.96',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'A29',
        'poLineItem.productCode': '357',
        'product.categoryCode': '357',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'WMNS CORE TENNIS',
        'product.styleNumber': '357',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A26',
        'planning.ogacvsmrgac': 'A26',
        'planning.mrgacCapacityConsumptionWeek': '78',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '357',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502670346-10',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670345',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '356',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '329',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '356',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': '10',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.77,
        'poLineItem.netUnitPrice': '$ 19.95',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'B29',
        'poLineItem.productCode': '356',
        'product.categoryCode': '356',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'WMNS CORE TENNIS',
        'product.styleNumber': '356',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B26',
        'planning.ogacvsmrgac': 'B26',
        'planning.mrgacCapacityConsumptionWeek': '77',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '356',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502670345-10',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670344',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '355',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '328',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '355',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': '150',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.76,
        'poLineItem.netUnitPrice': '$ 19.94',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'A28',
        'poLineItem.productCode': '355',
        'product.categoryCode': '355',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'MENS CORE TENNIS',
        'product.styleNumber': '355',
        'planning.mrgacDate': '2020-10-26T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A25',
        'planning.ogacvsmrgac': 'A25',
        'planning.mrgacCapacityConsumptionWeek': '76',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '355',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502670344-10',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670343',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '354',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '327',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '354',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '20',
        'poLineItem.quantity': '200',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.gacDate': '2020-12-26T05:12:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.75,
        'poLineItem.netUnitPrice': '$ 19.93',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'B28',
        'poLineItem.productCode': '354',
        'product.categoryCode': '354',
        'product.categoryDescription': 'TENNIS',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'MENS CORE TENNIS',
        'product.styleNumber': '354',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'B25',
        'planning.ogacvsmrgac': 'B25',
        'planning.mrgacCapacityConsumptionWeek': '75',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '354',
        'manufacturing.fobMismatchIndicator': 'NO',
        'manufacturing.sizeMismatchIndicator': 'NO',
        id: '3502670343-20',
        tsUpdate: 1604319356
      },
      {
        'poHeader.poNumber': '3502670343',
        'poHeader.tradingPoNumber': 'tradingPoNumber_value',
        'poHeader.partnerNumber': 'partnerNumber_value',
        'poHeader.partnerName': 'partnerName_value',
        'poHeader.poCreatedOn': 'poCreatedOn_value',
        'poHeader.poEffectiveFrom': 'poEffectiveFrom_value',
        'poHeader.purchaseGroupOrganizationNumber': '353',
        'poHeader.purchaseGroupOrganizationName': 'GBL TOP Purchasing',
        'poHeader.purchaseOrganizationNumber': '326',
        'poHeader.purchaseOrganizationName': 'Regional Single FW',
        'poHeader.poDocTypeCode': 'ZP20',
        'poHeader.poDocType': 'Standard PO',
        'poHeader.manufacturingCountryofOrigin': 'CN',
        'poHeader.purchaseOrderCompanyCodeNumber': '353',
        'poHeader.purchaseOrderCompanyCodeName': 'Nike PO',
        'poHeader.tradingCompanyCode': '401',
        'poHeader.tradingCompanyName': 'Jay NIKE',
        'poLineItem.poLineStatus': 'Unaccepted',
        'poLineItem.poLineItemNumber': '10',
        'poLineItem.quantity': '100',
        'poLineItem.unitOfMeasure': 'PR',
        'poLineItem.ogacDate': '2021-01-02T05:01:00.000+05:30',
        'poLineItem.gacDate': '2021-01-02T05:01:00.000+05:30',
        'poLineItem.purchaseOrderFob': 1234567890,
        'poLineItem.tradingCompanyFob': 5803256477,
        'poLineItem.poFxAdjustment': '0.000',
        'poLineItem.totalAmount': '0.00',
        'poLineItem.tradingCompanyMarkup': 0.74,
        'poLineItem.netUnitPrice': '$ 19.92',
        'poLineItem.shippingType': 'shippingType_value',
        'poLineItem.transportationModeCode': 'A27',
        'poLineItem.productCode': '353',
        'product.categoryCode': '353',
        'product.categoryDescription': 'JORDAN LEGACY',
        'product.subCategoryCode': 'NIKE',
        'product.subCategoryDescription': 'JORDAN LEGACY',
        'product.styleNumber': '353',
        'planning.mrgacDate': '2020-10-19T05:10:00.000+05:30',
        'planning.gacvsmrgac': 'A24',
        'planning.ogacvsmrgac': 'A24',
        'planning.mrgacCapacityConsumptionWeek': '74',
        'planning.planningSeason': 'SU',
        'salesOrder.customerShipTo': 'customerShipTo_value',
        'logistics.originReceiptSize': '353',
        'manufacturing.fobMismatchIndicator': 'YES',
        'manufacturing.sizeMismatchIndicator': 'YES',
        id: '3502670343-10',
        tsUpdate: 1604319356
      }
    ]
  }
};

export const poSearchDataMock0 = {
  status: 200,
  message: 'Search Success',
  data: {
    totalRecordCount: 60,
    moreRecordsAvailable: true,
    count: 20,
    anchor: 1,
    searchResult: [
      {
        season: 'Autumn(AT)',
        gacDate: '2019-07-16T17:00:00.000Z',
        unitPrice: 320,
        ogacDate: '2019-07-16T17:00:00.000Z',
        trackingNo: 6183472548,
        poNumber: 6754891051,
        vendorName: 'GH',
        poCategory: 'Accepted',
        podcsDate: '2019-07-16T17:00:00.000Z',
        shipTo: 'NIKE JAPAN GROUP LLC',
        totalQuantity: 66,
        plantCode: 999,
        material: 'FT3075-100',
        country: 'France'
      },
      {
        season: 'Spring(SP)',
        gacDate: '2019-09-11T14:00:00.000Z',
        unitPrice: 113,
        ogacDate: '2019-09-11T14:00:00.000Z',
        trackingNo: 6764323575,
        poNumber: 6754891693,
        vendorName: 'JP',
        poCategory: 'Accepted',
        podcsDate: '2019-09-11T14:00:00.000Z',
        shipTo: 'ASIA SAMPLE PROGRAM',
        totalQuantity: 71,
        plantCode: 1045,
        material: 'FT3075-100',
        country: 'Malaysia'
      },
      {
        season: 'Autumn(AT)',
        gacDate: '2019-07-23T14:00:00.000Z',
        unitPrice: 302,
        ogacDate: '2019-07-23T14:00:00.000Z',
        trackingNo: 6680310088,
        poNumber: 6754893869,
        vendorName: 'MK',
        poCategory: 'Accepted',
        podcsDate: '2019-07-23T14:00:00.000Z',
        shipTo: 'NIKE SPORTS(CHINA) CO LTD',
        totalQuantity: 23,
        plantCode: 1026,
        material: 'CK6047-100',
        country: 'Taiwan'
      },
      {
        season: 'Summer(SU)',
        gacDate: '2019-04-24T13:00:00.000Z',
        unitPrice: 39,
        ogacDate: '2019-04-24T13:00:00.000Z',
        trackingNo: 6198444888,
        poNumber: 6754891553,
        vendorName: 'XC',
        poCategory: 'Accepted',
        podcsDate: '2019-04-24T13:00:00.000Z',
        shipTo: 'AMERICAS SAMPLE PROGRAM',
        totalQuantity: 49,
        plantCode: 1023,
        material: 'DS2089-100',
        country: 'Singapore'
      },
      {
        season: 'Autumn(AT)',
        gacDate: '2019-03-23T12:00:00.000Z',
        unitPrice: 284,
        ogacDate: '2019-03-23T12:00:00.000Z',
        trackingNo: 6852545578,
        poNumber: 6754892369,
        vendorName: 'XC',
        poCategory: 'Accepted',
        podcsDate: '2019-03-23T12:00:00.000Z',
        shipTo: 'NIKE JAPAN GROUP LLC',
        totalQuantity: 5,
        plantCode: 1029,
        material: 'CK6047-100',
        country: 'Vietnam'
      },
      {
        season: 'Summer(SU)',
        gacDate: '2019-06-11T22:00:00.000Z',
        unitPrice: 80,
        ogacDate: '2019-06-11T22:00:00.000Z',
        trackingNo: 6972677938,
        poNumber: 6754893083,
        vendorName: 'DW',
        poCategory: 'Accepted',
        podcsDate: '2019-06-11T22:00:00.000Z',
        shipTo: 'ASIA SAMPLE PROGRAM',
        totalQuantity: 98,
        plantCode: 1071,
        material: 'CK6047-100',
        country: 'Taiwan'
      },
      {
        season: 'Summer(SU)',
        gacDate: '2019-04-14T21:00:00.000Z',
        unitPrice: 168,
        ogacDate: '2019-04-14T21:00:00.000Z',
        trackingNo: 6729829281,
        poNumber: 6754886145,
        vendorName: 'LS',
        poCategory: 'Accepted',
        podcsDate: '2019-04-14T21:00:00.000Z',
        shipTo: 'ASIA SAMPLE PROGRAM',
        totalQuantity: 1,
        plantCode: 1087,
        material: 'DS2089-100',
        country: 'Singapore'
      },
      {
        season: 'Autumn(AT)',
        gacDate: '2019-02-18T19:00:00.000Z',
        unitPrice: 36,
        ogacDate: '2019-02-18T19:00:00.000Z',
        trackingNo: 6717457507,
        poNumber: 6754888689,
        vendorName: 'MK',
        poCategory: 'Accepted',
        podcsDate: '2019-02-18T19:00:00.000Z',
        shipTo: 'AMERICAS SAMPLE PROGRAM',
        totalQuantity: 88,
        plantCode: 1022,
        material: 'FT3075-100',
        country: 'US'
      },
      {
        season: 'Summer(SU)',
        gacDate: '2019-06-20T13:00:00.000Z',
        unitPrice: 312,
        ogacDate: '2019-06-20T13:00:00.000Z',
        trackingNo: 6705117493,
        poNumber: 6754888839,
        vendorName: 'DW',
        poCategory: 'Accepted',
        podcsDate: '2019-06-20T13:00:00.000Z',
        shipTo: 'NIKE SPORTS(CHINA) CO LTD',
        totalQuantity: 24,
        plantCode: 1026,
        material: 'AT5582-100',
        country: 'France'
      },
      {
        season: 'Falls(FA)',
        gacDate: '2019-07-14T15:00:00.000Z',
        unitPrice: 254,
        ogacDate: '2019-07-14T15:00:00.000Z',
        trackingNo: 6948841345,
        poNumber: 6754887731,
        vendorName: 'LS',
        poCategory: 'Accepted',
        podcsDate: '2019-07-14T15:00:00.000Z',
        shipTo: 'NIKE JAPAN GROUP LLC',
        totalQuantity: 48,
        plantCode: 1084,
        material: 'AT5582-100',
        country: 'Malaysia'
      },
      {
        season: 'Autumn(AT)',
        gacDate: '2019-07-16T17:00:00.000Z',
        unitPrice: 320,
        ogacDate: '2019-07-16T17:00:00.000Z',
        trackingNo: 6183472548,
        poNumber: 6754891052,
        vendorName: 'GH',
        poCategory: 'Accepted',
        podcsDate: '2019-07-16T17:00:00.000Z',
        shipTo: 'NIKE JAPAN GROUP LLC',
        totalQuantity: 66,
        plantCode: 1071,
        material: 'FT3075-100',
        country: 'Taiwan'
      },
      {
        season: 'Spring(SP)',
        gacDate: '2019-09-11T14:00:00.000Z',
        unitPrice: 113,
        ogacDate: '2019-09-11T14:00:00.000Z',
        trackingNo: 6764323575,
        poNumber: 6754891694,
        vendorName: 'JP',
        poCategory: 'Accepted',
        podcsDate: '2019-09-11T14:00:00.000Z',
        shipTo: 'ASIA SAMPLE PROGRAM',
        totalQuantity: 71,
        plantCode: 1045,
        material: 'FT3075-100',
        country: 'Singapore'
      },
      {
        season: 'Autumn(AT)',
        gacDate: '2019-07-23T14:00:00.000Z',
        unitPrice: 302,
        ogacDate: '2019-07-23T14:00:00.000Z',
        trackingNo: 6680310088,
        poNumber: 6754893870,
        vendorName: 'MK',
        poCategory: 'Accepted',
        podcsDate: '2019-07-23T14:00:00.000Z',
        shipTo: 'NIKE SPORTS(CHINA) CO LTD',
        totalQuantity: 23,
        plantCode: 1026,
        material: 'CK6047-100',
        country: 'Malaysia'
      },
      {
        season: 'Summer(SU)',
        gacDate: '2019-04-24T13:00:00.000Z',
        unitPrice: 39,
        ogacDate: '2019-04-24T13:00:00.000Z',
        trackingNo: 6198444888,
        poNumber: 6754891554,
        vendorName: 'XC',
        poCategory: 'Accepted',
        podcsDate: '2019-04-24T13:00:00.000Z',
        shipTo: 'AMERICAS SAMPLE PROGRAM',
        totalQuantity: 49,
        plantCode: 1023,
        material: 'DS2089-100',
        country: 'France'
      },
      {
        season: 'Autumn(AT)',
        gacDate: '2019-03-23T12:00:00.000Z',
        unitPrice: 284,
        ogacDate: '2019-03-23T12:00:00.000Z',
        trackingNo: 6852545578,
        poNumber: 6754892370,
        vendorName: 'XC',
        poCategory: 'Accepted',
        podcsDate: '2019-03-23T12:00:00.000Z',
        shipTo: 'NIKE JAPAN GROUP LLC',
        totalQuantity: 5,
        plantCode: 1029,
        material: 'CK6047-100',
        country: 'Singapore'
      },
      {
        season: 'Summer(SU)',
        gacDate: '2019-06-11T22:00:00.000Z',
        unitPrice: 80,
        ogacDate: '2019-06-11T22:00:00.000Z',
        trackingNo: 6972677938,
        poNumber: 6754893084,
        vendorName: 'DW',
        poCategory: 'Accepted',
        podcsDate: '2019-06-11T22:00:00.000Z',
        shipTo: 'ASIA SAMPLE PROGRAM',
        totalQuantity: 98,
        plantCode: 1071,
        material: 'CK6047-100',
        country: 'Taiwan'
      },
      {
        season: 'Summer(SU)',
        gacDate: '2019-04-14T21:00:00.000Z',
        unitPrice: 168,
        ogacDate: '2019-04-14T21:00:00.000Z',
        trackingNo: 6729829281,
        poNumber: 6754886146,
        vendorName: 'LS',
        poCategory: 'Accepted',
        podcsDate: '2019-04-14T21:00:00.000Z',
        shipTo: 'ASIA SAMPLE PROGRAM',
        totalQuantity: 1,
        plantCode: 1087,
        material: 'DS2089-100',
        country: 'France'
      },
      {
        season: 'Autumn(AT)',
        gacDate: '2019-02-18T19:00:00.000Z',
        unitPrice: 36,
        ogacDate: '2019-02-18T19:00:00.000Z',
        trackingNo: 6717457507,
        poNumber: 6754888690,
        vendorName: 'MK',
        poCategory: 'Accepted',
        podcsDate: '2019-02-18T19:00:00.000Z',
        shipTo: 'AMERICAS SAMPLE PROGRAM',
        totalQuantity: 88,
        plantCode: 1022,
        material: 'FT3075-100',
        country: 'Taiwan'
      },
      {
        season: 'Summer(SU)',
        gacDate: '2019-06-20T13:00:00.000Z',
        unitPrice: 312,
        ogacDate: '2019-06-20T13:00:00.000Z',
        trackingNo: 6705117493,
        poNumber: 6754888840,
        vendorName: 'DW',
        poCategory: 'Accepted',
        podcsDate: '2019-06-20T13:00:00.000Z',
        shipTo: 'NIKE SPORTS(CHINA) CO LTD',
        totalQuantity: 24,
        plantCode: 1026,
        material: 'AT5582-100',
        country: 'France'
      },
      {
        season: 'Falls(FA)',
        gacDate: '2019-07-14T15:00:00.000Z',
        unitPrice: 254,
        ogacDate: '2019-07-14T15:00:00.000Z',
        trackingNo: 6948841345,
        poNumber: 6754887732,
        vendorName: 'LS',
        poCategory: 'Accepted',
        podcsDate: '2019-07-14T15:00:00.000Z',
        shipTo: 'NIKE JAPAN GROUP LLC',
        totalQuantity: 48,
        plantCode: 1084,
        material: 'AT5582-100',
        country: 'Singapore'
      }
    ]
  }
};

export const poEmptySearchDataMock = {
  status: 200,
  message: 'Search Success',
  data: '[]'
};

export const poSearchDataMock400 = {
  status: 400,
  message: 'Nothing to See here, Please try again.',
  data: {}
};

export const poSearchDataMock500 = {
  status: 500,
  message: 'Search Success',
  data: {}
};

export const newContractPOSearchDataMock = {
  data: {
    id: '',
    resourceType: '',
    links: { self: '' },
    pages: {
      totalPages: 1,
      totalResources: 4,
      continuationToken: '{\'token\':\'+RID:0H0QAPtwSoZkAAAAAAAAAA==#RT:1#SRC:2#TRC:4#ISV:2#IEO:65551#QCF:1\',\'range\':{\'min\':\'\',\'max\':\'FF\'}}'
    },
    objects: [
      {
        tsUpdate: '',
        id: '',
        isLine: '',
        poHeader: {
          poNumber: '',
          istrcoRelevant: '',
          trcoPoNumber: '',
          incoTerms: '',
          createdBy: '',
          vendorCode: '',
          poCreatedOn: '',
          documentDate: '',
          buyGroupCode: '',
          r3DocType: '',
          poDocTypeCode: '',
          poDocTypeDescription: '',
          purchaseOrgCode: '',
          purchaseOrgName: '',
          purchaseGroupCode: '',
          purchaseGroupName: '',
          titleTransferModelCode: '',
          titleTransferModelDescription: '',
          manufacturingCountryOfOrigin: '',
          poCompanyCode: '',
          poCompanyCodeDescription: '',
          trcoCompanyCode: '',
          trcoCompanyCodeDescription: ''
        },
        poLine: {
          itemNumber: '20',
          sapStatus: '',
          dpomItemStatus: 'closed',
          changedOnDate: '',
          divisionCode: '',
          productCode: '',
          productName: '',
          itemQuantity: '',
          unitOfMeasureCode: '',
          destinationCountryCode: '',
          destinatitonCountryName: '',
          originalGoodsAtConsolidatorDate: '',
          goodsAtConsolidatorDate: '',
          goodsAtConsolidatorReasonCode: '',
          goodsAtConsolidatorReasonDescription: '',
          plantCode: '',
          plantName: 'Nike Japan CSC',
          trcoPlantCode: '',
          trcoPlantName: '',
          transportationModeCode: '',
          shippingType: '',
          directshipSalesOrderNumber: '',
          salesOrderItemNumber: '',
          factoryAirFreight: '',
          seasonCode: '',
          seasonYear: '',
          cancelDate: '',
          customerIsoCountryCode: '',
          customerCountryName: '',
          itemTextDetail: '',
          sizes: {
            sizeProduct: {
              koreaSize: {
                koreaChestSizeCode: '90',
                koreaWaistSizeCode: 'N/A',
                koreaAgeBySizeCode: '7-8'
              },
              chinaSize: {
                chinaTopSizeCode: 'XS',
                chinaBottomSizeCode: '92'
              }
            }
          },
          itemForeignExchange: {
            crpoRateTypeName: '',
            crpoRateUnitValue: '',
            crpoTotalValueAmount: '',
            crpoTotalValueAmountCurrencyCode: '',
            trcoRateTypeName: '',
            trcoRateUnitValue: '',
            trcoTotalValueAmount: '',
            trcoTotalValueAmountCurrencyCode: ''
          },
          itemGrossPrice: {
            crpoRateTypeName: '',
            crpoRateUnitValue: '',
            crpoTotalValueAmount: '',
            crpoTotalValueAmountCurrencyCode: '',
            trcoRateTypeName: '',
            trcoRateUnitValue: '',
            trcoTotalValueAmount: '',
            trcoTotalValueAmountCurrencyCode: ''
          },
          itemNetIncludingDiscounts: {
            crpoRateTypeName: '',
            crpoRateUnitValue: '',
            crpoTotalValueAmount: '',
            crpoTotalValueAmountCurrencyCode: '',
            trcoRateTypeName: '',
            trcoRateUnitValue: '',
            trcoTotalValueAmount: '',
            trcoTotalValueAmountCurrencyCode: ''
          },
          itemNetIncludingSurcharges: {
            crpoRateTypeName: '',
            crpoRateUnitValue: '',
            crpoTotalValueAmount: '',
            crpoTotalValueAmountCurrencyCode: '',
            trcoRateTypeName: '',
            trcoRateUnitValue: '',
            trcoTotalValueAmount: '',
            trcoTotalValueAmountCurrencyCode: ''
          },
          itemSubTotal: {
            crpoRateTypeName: '',
            crpoRateUnitValue: '',
            crpoTotalValueAmount: '',
            crpoTotalValueAmountCurrencyCode: '',
            trcoRateTypeName: '',
            trcoRateUnitValue: '',
            trcoTotalValueAmount: '',
            trcoTotalValueAmountCurrencyCode: ''
          },
          itemPoFob: {
            crpoRateTypeName: '',
            crpoRateUnitValue: '',
            crpoTotalValueAmount: '',
            crpoTotalValueAmountCurrencyCode: '',
            trcoRateTypeName: '',
            trcoRateUnitValue: '',
            trcoTotalValueAmount: '',
            trcoTotalValueAmountCurrencyCode: ''
          },
          vas: [{
            salesOrderNumber: '',
            salesOrderItemNumber: '',
            valueAddedServiceCode: '',
            valueAddedServiceMaterialNumber: '',
            valueAddedServiceTypeIndicator: '',
            valueAddedServiceInstructions: ''
          }]
        },
        size: {
          sizeId: '',
          itemNumber: '',
          po: {
            sizeDescription: '',
            sizeQuantity: '',
            goodsAtConsolidatorDate: '',
            goodsAtConsolidatorReasonCode: '',
            goodsAtConsolidatorReasonDescription: '',
            inventorysegmentCode: '',
            transportationModeCode: '',
            exFactoryQuantity: '',
            sizeForeignExchange: {
              crpoRateTypeName: '',
              crpoRateUnitValue: '',
              crpoTotalValueAmount: '',
              crpoTotalValueAmountCurrencyCode: '',
              trcoRateTypeName: '',
              trcoRateUnitValue: '',
              trcoTotalValueAmount: '',
              trcoTotalValueAmountCurrencyCode: ''
            },
            sizeGrossPrice: {
              crpoRateTypeName: '',
              crpoRateUnitValue: '',
              crpoTotalValueAmount: '',
              crpoTotalValueAmountCurrencyCode: '',
              trcoRateTypeName: '',
              trcoRateUnitValue: '',
              trcoTotalValueAmount: '',
              trcoTotalValueAmountCurrencyCode: ''
            },
            sizeNetIncludingDiscounts: {
              crpoRateTypeName: '',
              crpoRateUnitValue: '',
              crpoTotalValueAmount: '',
              crpoTotalValueAmountCurrencyCode: '',
              trcoRateTypeName: '',
              trcoRateUnitValue: '',
              trcoTotalValueAmount: '',
              trcoTotalValueAmountCurrencyCode: ''
            },
            sizeNetIncludingSurcharges: {
              crpoRateTypeName: '',
              crpoRateUnitValue: '',
              crpoTotalValueAmount: '',
              crpoTotalValueAmountCurrencyCode: '',
              trcoRateTypeName: '',
              trcoRateUnitValue: '',
              trcoTotalValueAmount: '',
              trcoTotalValueAmountCurrencyCode: ''
            },
            sizeSubTotal: {
              crpoRateTypeName: '',
              crpoRateUnitValue: '',
              crpoTotalValueAmount: '',
              crpoTotalValueAmountCurrencyCode: '',
              trcoRateTypeName: '',
              trcoRateUnitValue: '',
              trcoTotalValueAmount: '',
              trcoTotalValueAmountCurrencyCode: ''
            },
            sizePoFob: {
              crpoRateTypeName: '',
              crpoRateUnitValue: '',
              crpoTotalValueAmount: '',
              crpoTotalValueAmountCurrencyCode: '',
              trcoRateTypeName: '',
              trcoRateUnitValue: '',
              trcoTotalValueAmount: '',
              trcoTotalValueAmountCurrencyCode: ''
            }
          },
          product: {
            upc: 3,
            upc2ndquality: 10,
            sizeproductFob: 4.20,
            sizeproductFobCurrencyCode: 'INR'
          },
          vas: [{
            salesOrderNumber: '',
            salesOrderItemNumber: '',
            valueAddedServiceCode: '',
            valueAddedServiceMaterialNumber: '',
            valueAddedServiceTypeIndicator: '',
            valueAddedServiceInstructions: ''
          }]
        },
        product: {
          productFob: '',
          launchDate: '',
          launchCode: '',
          categoryCode: '',
          categoryDescription: '',
          subCategoryCode: '',
          subCategoryDescription: '',
          fobMismatchIndicator: '',
          sizeMismatchIndicator: '',
          globalCategoryCoreFocusCode: '',
          globalCategoryCoreFocusDescription: '',
          globalCategorySummaryCode: '',
          globalCategorySummaryDescription: '',
          genderAgeCode: '',
          genderAgeDescription: '',
          sportActivityCode: '',
          colorDescription: '',
          midsoleCode: '',
          outsoleCode: '',
          sourceType: '',
          toolId: '',
          silhouetteTypeCode: '',
          silhouetteTypeDescription: '',
          dimensionCode: '',
          athleteIdentifier: '',
          athleteFullName: '',
          teamIdentifier: '',
          teamName: '',
          teamSilhouetteId: '',
          teamSilhouetteDescription: '',
          blankColor: '',
          blankProductCode: '',
          blankUsageIndicator: '',
          blankStyle: '',
          styleNumber: '',
          siloCode: '',
          siloDescription: '',
          productRefillClassCode: '',
          productRefillClassDescription: '',
          leagueIdCode: '',
          leagueIdDescription: ''
        },
        manufacturing: {
          poRejectionCode: '',
          currentEvent: '',
          currentEventDate: '',
          nextEvent: '',
          nextEventDate: '',
          pendingChanges: '',
          initialGoodsAtConsolidatorDate: '',
          initialGoodsAtConsolidatorReasonCode: '',
          initialGoodsAtConsolidatorReasonDescription: '',
          previousGoodsAtConsolidator: '',
          previousGoodsAtConsolidatorReasonCode: '',
          previousGoodsAtConsolidatorReasonDescription: '',
          overdueIndicator: '',
          issuePOExpectedDate: '',
          issuePOActualDate: '',
          acceptPOExpectedDate: '',
          acceptPOActualDate: '',
          materialOrderExpectedDate: '',
          materialOrderActualDate: '',
          materialTrimReceiptExpectedDate: '',
          materialTrimReceiptActualDate: '',
          qrsExpectedDate: '',
          qrsActualDate: '',
          productionStartExpectedDate: '',
          productionStartActualDate: '',
          productionEndExpectedDate: '',
          productionEndActualDate: '',
          exFactoryExpectedDate: '',
          exFactoryActualDate: '',
          originReceiptExpectedDate: '',
          originReceiptActualDate: ''
        },
        salesOrder: {
          customerShipTo: '',
          customerShipToName: '',
          customerStore: '',
          customerDepartment: '',
          customerCancelDate: '',
          customerRequestedDate: '',
          billingDate: '',
          customerPo: '',
          distributionChannelCode: '',
          distributionChannelDescription: '',
          salesOrgCode: ''
        },
        logisticsASN: {
          asnActualDate: '',
          factoryProofOfDeliveryDate: '',
          asnSize: '',
          asnQuantity: ''
        },
        logisticsOR: {
          originReceiptActualDate: '',
          factoryProofOfDeliveryDate: '',
          originReceiptSize: '',
          originReceiptQuantity: ''
        },
        planning: {
          mrgacDate: '',
          targetIpd: '',
          initialCapacityConsumptionWeek: '',
          initialProductionWeek: '',
          globalPlanningProductGroupDescription: '',
          globalPlanningProductGroupCode: '',
          globalPlanningProductClassificationCode: '',
          globalPlanningProductClassificationDescription: '',
          PlanningPriorityCode: '',
          PlanningPriorityDescription: '',
          gacVsMrgac: '',
          ogacVsMrgac: '',
          mrgacCapacityConsumptionWeek: ''
        },
        vendor: {
          vendorName: '',
          productionNikeLiaisonOffice: '',
          fsp: '',
          paymentTerm: '',
          paymentTermDescription: ''
        }
      }
    ],
    filters: [{
      filterName: 'poHeader.poDocTypeCode',
      filterValues: [{
        primary: 'A12',
        secondary: 'A12',
        filterCount: 2
      }],
      totalFilterCount: 25
    }]
  }
};

export const PoSearchDownloadDataMock = {
  data: {
    objects: [
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Processing',
        id: 'da51b953-1b9f-4fd1-961a-8732dfe1d3e4',
        pdfDownloadLink: ''
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Completed',
        id: '0bb930ea-cf2e-47db-8c0a-cebd20e43c0d',
        pdfDownloadLink: 'https://n5gsmdpomdevstr01.blob.core.windows.net/dpocdeliveryinstructions/SIRE_profile_pdfs_0bb930ea-cf2e-47db-8c0a-cebd20e43c0d.zip?sv=2021-08-06&se=2022-12-14T14%3A22%3A49Z&sr=b&sp=r&sig=RIiZy5EyBGNmCQi5dkivZG6%2FiOsRm3mIIg%2FwRagTY1s%3D'
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Processing',
        id: 'da51b953-1b9f-4fd1-961a-8732dfe1d3e4',
        pdfDownloadLink: '',
        reportName: 'PO Change Report'
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Completed',
        id: '0bb930ea-cf2e-47db-8c0a-cebd20e43c0d',
        pdfDownloadLink: 'https://n5gsmdpomdevstr01.blob.core.windows.net/dpocdeliveryinstructions/SIRE_profile_pdfs_0bb930ea-cf2e-47db-8c0a-cebd20e43c0d.zip?sv=2021-08-06&se=2022-12-14T14%3A22%3A49Z&sr=b&sp=r&sig=RIiZy5EyBGNmCQi5dkivZG6%2FiOsRm3mIIg%2FwRagTY1s%3D'
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Completed',
        id: '0bb930ea-cf2e-47db-8c0a-cebd20e43c0d',
        pdfDownloadLink: 'https://n5gsmdpomdevstr01.blob.core.windows.net/dpocdeliveryinstructions/SIRE_profile_pdfs_0bb930ea-cf2e-47db-8c0a-cebd20e43c0d.zip?sv=2021-08-06&se=2022-12-14T14%3A22%3A49Z&sr=b&sp=r&sig=RIiZy5EyBGNmCQi5dkivZG6%2FiOsRm3mIIg%2FwRagTY1s%3D'
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Processing',
        id: 'da51b953-1b9f-4fd1-961a-8732dfe1d3e4',
        pdfDownloadLink: ''
      }
    ]
  }
};

export const ReportDownloadDataMock = {
  data: {
    objects: [
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Processing',
        id: 'da51b953-1b9f-4fd1-961a-8732dfe1d3e4',
        pdfDownloadLink: '',
        reportName: 'Production Progress & Metrics'
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Completed',
        id: '0bb930ea-cf2e-47db-8c0a-cebd20e43c0d',
        pdfDownloadLink: 'https://n5gsmdpomdevstr01.blob.core.windows.net/dpocdeliveryinstructions/SIRE_profile_pdfs_0bb930ea-cf2e-47db-8c0a-cebd20e43c0d.zip?sv=2021-08-06&se=2022-12-14T14%3A22%3A49Z&sr=b&sp=r&sig=RIiZy5EyBGNmCQi5dkivZG6%2FiOsRm3mIIg%2FwRagTY1s%3D',
        reportName: 'Collaboration History Report'
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Processing',
        id: 'da51b953-1b9f-4fd1-961a-8732dfe1d3e4',
        pdfDownloadLink: '',
        reportName: 'PO Change Report'
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Completed',
        id: '0bb930ea-cf2e-47db-8c0a-cebd20e43c0d',
        pdfDownloadLink: 'https://n5gsmdpomdevstr01.blob.core.windows.net/dpocdeliveryinstructions/SIRE_profile_pdfs_0bb930ea-cf2e-47db-8c0a-cebd20e43c0d.zip?sv=2021-08-06&se=2022-12-14T14%3A22%3A49Z&sr=b&sp=r&sig=RIiZy5EyBGNmCQi5dkivZG6%2FiOsRm3mIIg%2FwRagTY1s%3D',
        reportName: 'Line Item Summary Report'
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Completed',
        id: '0bb930ea-cf2e-47db-8c0a-cebd20e43c0d',
        pdfDownloadLink: 'https://n5gsmdpomdevstr01.blob.core.windows.net/dpocdeliveryinstructions/SIRE_profile_pdfs_0bb930ea-cf2e-47db-8c0a-cebd20e43c0d.zip?sv=2021-08-06&se=2022-12-14T14%3A22%3A49Z&sr=b&sp=r&sig=RIiZy5EyBGNmCQi5dkivZG6%2FiOsRm3mIIg%2FwRagTY1s%3D',
        reportName: 'Conversation Thread Report'
      },
      {
        eventCreateTS: '2022-12-13T11:36:36.133Z',
        eventStatus: 'Processing',
        id: 'da51b953-1b9f-4fd1-961a-8732dfe1d3e4',
        pdfDownloadLink: '',
        reportName: 'Production Progress & Metrics'
      }
    ]
  }
};
export const userDetailsFromADApi = {
  sAMAccountName: 'a.DPOM.PMO01',
  userId: 'dpom.pmo01@nike.com',
  password: null,
  firstName: 'a.DPOM.PMO01',
  lastName: 'GID',
  displayName: 'a.dpom.pmo01',
  email: 'dpom.pmo01@nike.com',
  company: null,
  telephoneNumber: null,
  guid: '0b901295-73c9-4807-9b9f-fd899d48bff8',
  userType: 'InternalUser',
  userStatus: {
    disabled: true,
    passwordExpired: true,
    locked: false
  },
  passwordInformation: {
    lastChanged: null,
    passwordExpires: null,
    temporaryPasswordExpirationDate: null
  },
  accountExpirationDate: null,
  sponsor: null
};
