import collabReportFieldMapping from '../fieldMappings/collabReportFieldMapping';

const lblProposedGACdate = 'Proposed GAC Date';
const lblProposedGACReasonCode = 'Proposed GAC Reason Code';
const lblPurchaseOrderNumber = 'Purchase Order Number';
const lblSizeDescription = 'Size Description';
const lblProductCode = 'Product Code';
const destinationCountryValue = 'Destination Country';
const lblPOLineItemNumber = 'PO Line Item Number';
const lblScheduleLineItemNumber = 'Schedule Line Item Number';
const lblTradingCoPoNumber = 'Trading Co Purchase Order Number';
const lblModeOfTransportation = 'Mode of Transportation';
const lblCompanyCode = 'Company Code';
const lblCurrentEvent = 'Current Event';
const lblInitGACReasonCode = 'Initial GAC Reason Code';
const lblNextEvent = 'Next Event';
const lblPurchaseGroup = 'Purchase Group';
const lblPurchaseOrg = 'Purchase Org';
const lblMeasureUnit = 'Unit of Measure';
const lblDocumentDate = 'Document Date';
const lblChangeDate = 'Change Date';
const lblShippingType = 'Shipping Type';
const lblCreateDate = 'Create Date';
const lblCurrentEventDate = 'Current Event Date';
const lblNextEventDate = 'Next Event Date';
const lblDPOMLineItemStatus = 'DPOM Line Item Status';
const lblCurrentGACReasonCode = 'Current GAC Reason Code';

export default {
  [collabReportFieldMapping.REPORT_PO_NUMBER]: {
    primary: collabReportFieldMapping.REPORT_PO_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblPurchaseOrderNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PO_ITEM_NUMBER]: {
    primary: collabReportFieldMapping.REPORT_PO_ITEM_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblPOLineItemNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_SCD_LINE_ITEM_NUMBER]: {
    primary: collabReportFieldMapping.REPORT_SCD_LINE_ITEM_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblScheduleLineItemNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_SIZE_DESCRIPTION]: {
    primary: collabReportFieldMapping.REPORT_SIZE_DESCRIPTION,
    secondary: '',
    type: 'string',
    link: false,
    label: lblSizeDescription,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PENDING_GAC_LOCK]: {
    primary: collabReportFieldMapping.REPORT_PENDING_GAC_LOCK,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Pending GAC Lock',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_GAC_OVERDUE_INDICATOR]: {
    primary: collabReportFieldMapping.REPORT_GAC_OVERDUE_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'GAC Overdue Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_REQUEST_INITIATED_DATE]: {
    primary: collabReportFieldMapping.REPORT_REQUEST_INITIATED_DATE,
    secondary: '',
    type: 'datetime',
    link: false,
    label: 'Request Initiated Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: true
  },
  [collabReportFieldMapping.REPORT_DATE_APPROVED_OR_REJECTED]: {
    primary: collabReportFieldMapping.REPORT_DATE_APPROVED_OR_REJECTED,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Date Approved/Rejected',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: true
  },
  [collabReportFieldMapping.REPORT_SAP_RESPONSE_DATE]: {
    primary: collabReportFieldMapping.REPORT_SAP_RESPONSE_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'SAP Response Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_SAP_RESPOSNE]: {
    primary: collabReportFieldMapping.REPORT_SAP_RESPOSNE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'SAP Response',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_COLLABORATION_TYPE]: {
    primary: collabReportFieldMapping.REPORT_COLLABORATION_TYPE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Collaboration Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_APPROVER_ID]: {
    primary: collabReportFieldMapping.REPORT_APPROVER_ID,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Approver ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_INITIATOR_ID]: {
    primary: collabReportFieldMapping.REPORT_INITIATOR_ID,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Initiator ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_INITIATOR_ROLE]: {
    primary: collabReportFieldMapping.REPORT_INITIATOR_ROLE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Initiator role',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_CHANGE_REQUEST_NO]: {
    primary: collabReportFieldMapping.REPORT_CHANGE_REQUEST_NO,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Change Request #',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_CHANGE_STATUS_CODE]: {
    primary: collabReportFieldMapping.REPORT_CHANGE_STATUS_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Change Status Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_GAC_DATE]: {
    primary: collabReportFieldMapping.REPORT_GAC_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Current GAC date (at time of request)',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_GAC_REASON_CODE]: {
    primary: collabReportFieldMapping.REPORT_GAC_REASON_CODE,
    secondary: collabReportFieldMapping.REPORT_GAC_REASON_DESC,
    type: 'string',
    link: false,
    label: lblCurrentGACReasonCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PROPOSED_GAC_DATE]: {
    primary: collabReportFieldMapping.REPORT_PROPOSED_GAC_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblProposedGACdate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PROPOSED_GAC_REASON_CODE]: {
    primary: collabReportFieldMapping.REPORT_PROPOSED_GAC_REASON_CODE,
    secondary: collabReportFieldMapping.REPORT_PROPOSED_GAC_DESC,
    type: 'string',
    link: false,
    label: lblProposedGACReasonCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_INITIAL_GAC_DATE]: {
    primary: collabReportFieldMapping.REPORT_INITIAL_GAC_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Initial GAC Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_INITIAL_GAC_REASON_CODE]: {
    primary: collabReportFieldMapping.REPORT_INITIAL_GAC_REASON_CODE,
    secondary: collabReportFieldMapping.REPORT_INITIAL_GAC_REASON_DESC,
    type: 'string',
    link: false,
    label: lblInitGACReasonCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_GAC_USECASE_CODE]: {
    primary: collabReportFieldMapping.REPORT_GAC_USECASE_CODE,
    secondary: collabReportFieldMapping.REPORT_GAC_USECASE_CODE_DESC,
    type: 'string',
    link: false,
    label: 'Proposed GRC User Case Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_ORIGINAL_GAC_DATE]: {
    primary: collabReportFieldMapping.REPORT_ORIGINAL_GAC_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'OGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_CREATED_DATE]: {
    primary: collabReportFieldMapping.REPORT_CREATED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblCreateDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: true
  },
  [collabReportFieldMapping.REPORT_DOCUMENT_DATE]: {
    primary: collabReportFieldMapping.REPORT_DOCUMENT_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblDocumentDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    requestDataType: 'dynamicDate',
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_CHANGED_ON_DATE]: {
    primary: collabReportFieldMapping.REPORT_CHANGED_ON_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblChangeDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PO_COMPANY_CODE]: {
    primary: collabReportFieldMapping.REPORT_PO_COMPANY_CODE,
    secondary: collabReportFieldMapping.REPORT_PO_CO_DESC,
    type: 'string',
    link: false,
    label: lblCompanyCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PO_DOC_TYPE]: {
    primary: collabReportFieldMapping.REPORT_PO_DOC_TYPE,
    secondary: collabReportFieldMapping.REPORT_PO_DOC_TYPE_DESC,
    type: 'string',
    link: false,
    label: 'Doc Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PURCHASE_ORG_CODE]: {
    primary: collabReportFieldMapping.REPORT_PURCHASE_ORG_CODE,
    secondary: collabReportFieldMapping.REPORT_PURCHASE_ORG_NAME,
    type: 'string',
    link: false,
    label: lblPurchaseOrg,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PURCHASE_GROUP_NUMBER]: {
    primary: collabReportFieldMapping.REPORT_PURCHASE_GROUP_NUMBER,
    secondary: collabReportFieldMapping.REPORT_PURCHASE_GROUP_NAME,
    type: 'string',
    link: false,
    label: lblPurchaseGroup,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PLANT_CODE]: {
    primary: collabReportFieldMapping.REPORT_PLANT_CODE,
    secondary: collabReportFieldMapping.REPORT_PLANT_NAME,
    type: 'string',
    link: false,
    label: 'Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_MFG_COUNTRY_ORIGIN]: {
    primary: collabReportFieldMapping.REPORT_MFG_COUNTRY_ORIGIN,
    secondary: collabReportFieldMapping.REPORT_MFG_COUNTRY_ORIGIN_NAME,
    type: 'string',
    link: false,
    label: 'MCO',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_PRODUCT_CODE]: {
    primary: collabReportFieldMapping.REPORT_PRODUCT_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblProductCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_DESTINATION_COUNTRY_CODE]: {
    primary: collabReportFieldMapping.REPORT_DESTINATION_COUNTRY_CODE,
    secondary: collabReportFieldMapping.REPORT_DESTINATION_COUNTRY_NAME,
    type: 'string',
    link: false,
    label: destinationCountryValue,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [collabReportFieldMapping.REPORT_DPOM_ITEM_STATUS]: {
    primary: collabReportFieldMapping.REPORT_DPOM_ITEM_STATUS,
    secondary: '',
    type: 'string',
    link: false,
    label: lblDPOMLineItemStatus,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_TR_CO_PO_NUMBER]: {
    primary: collabReportFieldMapping.REPORT_TR_CO_PO_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblTradingCoPoNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_MRGAC_DATE]: {
    primary: collabReportFieldMapping.REPORT_MRGAC_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'MRGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_CURRENT_EVENT]: {
    primary: collabReportFieldMapping.REPORT_CURRENT_EVENT,
    secondary: '',
    type: 'string',
    link: false,
    label: lblCurrentEvent,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_CURRENT_EVENT_DATE]: {
    primary: collabReportFieldMapping.REPORT_CURRENT_EVENT_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblCurrentEventDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_NEXT_EVENT]: {
    primary: collabReportFieldMapping.REPORT_NEXT_EVENT,
    secondary: '',
    type: 'string',
    link: false,
    label: lblNextEvent,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_NEXT_EVENT_DATE]: {
    primary: collabReportFieldMapping.REPORT_NEXT_EVENT_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblNextEventDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_VENDOR_CODE]: {
    primary: collabReportFieldMapping.REPORT_VENDOR_CODE,
    secondary: collabReportFieldMapping.REPORT_VENDOR_NAME,
    type: 'string',
    link: false,
    label: 'Vendor Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_PROD_NIKE_LIAISON_OFFICE]: {
    primary: collabReportFieldMapping.REPORT_PROD_NIKE_LIAISON_OFFICE,
    secondary: collabReportFieldMapping.REPORT_PROD_NIKE_LIAISON_OFFICE_DESC,
    type: 'string',
    link: false,
    label: 'PMO/DEC Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_SIZE_PO_QUANTITY]: {
    primary: collabReportFieldMapping.REPORT_SIZE_PO_QUANTITY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_UNIT_OF_MEASURE]: {
    primary: collabReportFieldMapping.REPORT_UNIT_OF_MEASURE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblMeasureUnit,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_TR_CO_PLANT_CODE]: {
    primary: collabReportFieldMapping.REPORT_TR_CO_PLANT_CODE,
    secondary: collabReportFieldMapping.REPORT_TR_CO_PLANT_NAME,
    type: 'string',
    link: false,
    label: 'Trading Co Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_SIZE_TRANSPORTATION_CODE]: {
    primary: collabReportFieldMapping.REPORT_SIZE_TRANSPORTATION_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblModeOfTransportation,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_SHIPPING_PARTNER_ID]: {
    primary: collabReportFieldMapping.REPORT_SHIPPING_PARTNER_ID,
    secondary: collabReportFieldMapping.REPORT_SHIPPING_PARTNER_NAME,
    type: 'string',
    link: false,
    label: 'Shipping Partner',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_SHIPPING_TYPE]: {
    primary: collabReportFieldMapping.REPORT_SHIPPING_TYPE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblShippingType,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [collabReportFieldMapping.REPORT_ID]: {
    primary: collabReportFieldMapping.REPORT_ID,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Collaboration ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true,
    inactive: true
  },
  [collabReportFieldMapping.REPORT_ISLINE]: {
    primary: collabReportFieldMapping.REPORT_ISLINE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'isLineItem',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true,
    inactive: true
  },
  [collabReportFieldMapping.REPORT_SIZE_ID]: {
    primary: collabReportFieldMapping.REPORT_SIZE_ID,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true,
    inactive: true
  }
};
