import poChangeReportFieldMapping from '../fieldMappings/poChangeReportFieldMapping';

export default {
  [poChangeReportFieldMapping.PO_CHANGE_VENDOR_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_VENDOR_CODE, secondary: '', type: 'string', link: false, label: 'Vendor Code', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_PO_NUMBER]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PO_NUMBER, secondary: '', type: 'string', link: false, label: 'Purchase Order Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_ITEM_NUMBER]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_ITEM_NUMBER, secondary: '', type: 'string', link: false, label: 'PO Line Item Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_SCHEDULE_LINE_ITEM_NUMBER]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_SCHEDULE_LINE_ITEM_NUMBER, secondary: '', type: 'string', link: false, label: 'Schedule Line Item Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_SIZE_DESC]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_SIZE_DESC, secondary: '', type: 'string', link: false, label: 'Size Description', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_CHANGE_DATE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_CHANGE_DATE, secondary: '', type: 'date', requestDataType: 'dynamicDate', link: false, label: 'Change Date', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_DOC_TYPE_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_DOC_TYPE_CODE, secondary: poChangeReportFieldMapping.PO_CHANGE_DOC_TYPE_DESC, type: 'string', link: false, label: 'Doc Type', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_DOCUMENT_DATE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_DOCUMENT_DATE, secondary: '', type: 'date', requestDataType: 'dynamicDate', link: false, label: 'Document Date', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_CHANGED_TYPE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_CHANGED_TYPE, secondary: '', type: 'string', link: false, label: 'Change Type', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_CHANGED_FROM]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_CHANGED_FROM, secondary: '', type: 'string', link: false, label: 'Changed From', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_CHANGED_TO]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_CHANGED_TO, secondary: '', type: 'string', link: false, label: 'Changed To', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_PRODUCT_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PRODUCT_CODE, secondary: '', type: 'string', link: false, label: 'Product Code', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_OGAC_DATE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_OGAC_DATE, secondary: '', type: 'date', requestDataType: 'dynamicDate', link: false, label: 'OGAC', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_GAC_DATE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_GAC_DATE, secondary: '', type: 'date', requestDataType: 'dynamicDate', link: false, label: 'GAC', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_GAC_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_GAC_CODE, secondary: '', type: 'string', link: false, label: 'GAC Reason Code', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [poChangeReportFieldMapping.PO_CHANGE_CURRENCY_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_CURRENCY_CODE, secondary: '', type: 'string', link: false, label: 'Currency', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_CANCEL_REASON]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_CANCEL_REASON, secondary: '', type: 'string', link: false, label: 'Reason for Cancel', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_CANCEL_REASON_DESC]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_CANCEL_REASON_DESC, secondary: '', type: 'string', link: false, label: 'Cancel Reason Description', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_TTMI]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_TTMI, secondary: '', type: 'string', link: false, label: 'TTMI', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_SIZE_QUANTITY]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_SIZE_QUANTITY, secondary: '', type: 'string', link: false, label: 'Size Quantity', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_PLANT_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PLANT_CODE, secondary: '', type: 'string', link: false, label: 'Plant', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_ITEM_TEXT]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_ITEM_TEXT, secondary: '', type: 'string', link: false, label: 'Item Text', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_SHIP_TO_NUMBER]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_SHIP_TO_NUMBER, secondary: '', type: 'string', link: false, label: 'Ship To Number', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_CUSTOMER_PO]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_CUSTOMER_PO, secondary: '', type: 'string', link: false, label: 'Customer PO Number', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_SALES_ORDER_NUMBER]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_SALES_ORDER_NUMBER, secondary: '', type: 'string', link: false, label: 'Sales Order Number', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_SALES_ORDER_ITEM_NUMBER]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_SALES_ORDER_ITEM_NUMBER, secondary: '', type: 'string', link: false, label: 'Sales Order Item', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_SALES_SCHEDULE_ITEM_NUMBER]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_SALES_SCHEDULE_ITEM_NUMBER, secondary: '', type: 'string', link: false, label: 'Sales Order Size Line', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_PURCHASE_ORG]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PURCHASE_ORG, secondary: poChangeReportFieldMapping.PO_CHANGE_PURCHASE_ORG_NAME, type: 'string', link: false, label: 'Purchase Org', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_PURCHASE_GROUP_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PURCHASE_GROUP_CODE, secondary: poChangeReportFieldMapping.PO_CHANGE_PURCHASE_GROUP_NAME, type: 'string', link: false, label: 'Purchase Group', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_ACTUAL_DATE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_ACTUAL_DATE, secondary: '', type: 'date', link: false, label: 'Acceptance Date', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_MRGAC]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_MRGAC, secondary: '', type: 'date', link: false, label: 'MRGAC', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_PLANNING_TARGET_IPD]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PLANNING_TARGET_IPD, secondary: '', type: 'string', link: false, label: 'Planning Target IPD', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_PLANNING_PRIORITY_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PLANNING_PRIORITY_CODE, secondary: '', type: 'string', link: false, label: 'Planning Priority Number', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_PLANNING_PRIORITY_DESC]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PLANNING_PRIORITY_DESC, secondary: '', type: 'string', link: false, label: 'Planning Priority Description', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_SEASON_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_SEASON_CODE, secondary: '', type: 'string', link: false, label: 'Season', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_SEASON_YEAR]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_SEASON_YEAR, secondary: '', type: 'string', link: false, label: 'Season Year', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_MCO]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_MCO, secondary: '', type: 'string', link: false, label: 'MCO', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_OUTSOLE_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_OUTSOLE_CODE, secondary: '', type: 'string', link: false, label: 'Outsole Code', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_FOB_COST]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_FOB_COST, secondary: '', type: 'string', link: false, label: 'FOB Cost', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_PO_REASON_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PO_REASON_CODE, secondary: '', type: 'string', link: false, label: 'PO Reason Code', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_PO_REASON_CODE_DESCRIPTION]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PO_REASON_CODE_DESCRIPTION, secondary: '', type: 'string', link: false, label: 'PO Reason Code Description', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_PO_SHIPPING_INSTRUCTION_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_PO_SHIPPING_INSTRUCTION_CODE, secondary: '', type: 'string', link: false, label: 'Mode of transportation', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PURCHASING_ACCOUNT_ASSIGNMENT_CATEGORY]: {
    primary: poChangeReportFieldMapping.PURCHASING_ACCOUNT_ASSIGNMENT_CATEGORY, secondary: '', type: 'string', link: false, label: 'Purchasing Account Assignment Category Code', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_QUALITY_CODE]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_QUALITY_CODE, secondary: '', type: 'string', link: false, label: 'Quality Code', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_VAS_INDICATOR]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_VAS_INDICATOR, secondary: '', type: 'string', link: false, label: 'VAS Indicator', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  },
  [poChangeReportFieldMapping.PO_CHANGE_ITEM_VARIANT_NUMBER]: {
    primary: poChangeReportFieldMapping.PO_CHANGE_ITEM_VARIANT_NUMBER, secondary: '', type: 'string', link: false, label: 'Item Variant Number', numeric: false, disablePadding: false, group: true, wrap: true, default: false
  }
};
