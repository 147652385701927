import convoThreadReportFieldMapping from './fieldMappings/conversationThreadReportFieldMapping';
import convoThreadReportFieldProperties from './fieldProperties/conversationThreadReportFieldProperties';

const conversationThreadReportHeader = [
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_PO_NUMBER],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_ITEM_NUMBER],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_CONVO_ID],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_CONVO_TOPIC],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_CONVO_STATUS],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_PO_LINE_ITEM_STATUS],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_VENDOR_CODE],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_GAC_DATE],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_DOCUMENT_DATE],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_DOC_TYPE_CODE],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_PRODUCT_CODE],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_CREATED_BY],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_CREATE_DATE],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_LAST_UPDATED_BY],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_LAST_UPDATED_DATE],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_CONVO_LINK],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_ATTACHMENTS],
  convoThreadReportFieldProperties[convoThreadReportFieldMapping.CONVO_THREAD_STAKEHOLDERS]
];
export default conversationThreadReportHeader;
