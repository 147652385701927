export default {
  poNumber: {
    primary: 'poNumber', secondary: '', type: 'string', link: false, label: 'PO Number', wrap: true, numeric: 'true', disablePadding: false
  },
  itemNumber: {
    primary: 'itemNumber', secondary: '', type: 'string', link: false, label: 'PO Item Number', wrap: true, numeric: 'true', disablePadding: false
  },
  poRejectionCode: {
    primary: 'poRejectionCode', secondary: '', type: 'string', link: false, label: 'Reason Code', wrap: true, numeric: 'false', disablePadding: false, group: false
  },
  poRejectionDescription: {
    primary: 'poRejectionReason', secondary: '', type: 'string', link: false, label: 'Reason Description', wrap: true, numeric: 'false', disablePadding: false, group: false
  },
  reasonForFailure: {
    primary: 'reasonForFailure', secondary: '', type: 'string', link: false, label: 'Reason for failure', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
