/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import { cryptoRand } from '../../helpers/common';
import { getFilterDropdownValuesFromResponse, getFilteredResults, sortDataAscendingConfig } from '../../helpers/dataHelpers';

const name = 'geoGlobal';

function createInitialState() {
  return {
    countryList: [],
    selectedFilters: {
      ruleName: [],
      destinationCountryCode: [],
      purchaseGroup: [],
      division: [],
      docType: [],
      plantCode: [],
      email: [],
      emailType: []
    },
    dataId: '',
    geoFilterId: '',
    // pmoConfigScreenData: null,
    geoConfigScreenData: null,
    unfilteredGeoConfigScreenData: null
  };
}

export const initialState = createInitialState();
function createReducers() {
  function setCountryCodeDropdown(state: Object, action: Object) {
    return {
      ...state,
      countryList: action.payload
    };
  }
  function updateGeoFilterId(state: Object) {
    return {
      ...state,
      geoFilterId: cryptoRand().toString().substr(2, 8)
    };
  }
  function updateFilterValues(state: Object, action: Object) {
    if (action.payload.values instanceof Array && action.payload.filter) {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.filter]: action.payload.values
        }
      };
    }
    return { ...state };
  }
  function updateFilterDropdownValues(state: Object, action: Object) {
    if (
      action.payload
        && Object.keys(action.payload).length > 0
    ) {
      return {
        ...state,
        filterDropdownValues: sortDataAscendingConfig(action.payload)
      };
    }
    return { ...state };
  }
  function updateGeoConfigScreen(state: Object, action: Object) {
    const payload = {
      data: action.payload.flattenedResponse,
      filteredResults: action.payload.filteredResults || action.payload.flattenedResponse
    };
    return {
      ...state,
      geoConfigScreenData: payload.filteredResults,
      unfilteredGeoConfigScreenData: payload.data,
      dataId: cryptoRand().toString().substr(2, 8)
    };
  }
  function updateFilteredGeoConfigScreen(state: Object, action: Object) {
    const payload = {
      data: action.payload
    };
    return {
      ...state,
      geoConfigScreenData: payload.data,
      dataId: cryptoRand().toString().substr(2, 8)
    };
  }

  function clearGeoFilters(state: Object) {
    return {
      ...state,
      selectedFilters: {
        ...initialState.selectedFilters
      },
      geoConfigScreenData: state.unfilteredGeoConfigScreenData
    };
  }

  return {
    setCountryCodeDropdown,
    updateGeoFilterId,
    updateFilterValues,
    updateFilterDropdownValues,
    updateGeoConfigScreen,
    updateFilteredGeoConfigScreen,
    clearGeoFilters
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

export const actions = { ...slice.actions };

function createExtraActions() {
  function getGeoGlobalResults() {
    return createAsyncThunk(
      `${name}/getGeoGlobalResults`,
      async ({
        callback
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const query = '?filter=isActive(true),rule(destCountryDivRule,docTypePurchaseGroupRule,divDocTypeRule,divPlantRule,docTypeRule)';
        request({
          api: 'getGeoStakeholdersEmail',
          method: 'get',
          routeParams: { query }
        }, dispatch).then((response) => {
          const flattenedResponse = response.data;
          const state = getState();
          const { selectedFilters } = state.geoGlobal;
          const filteredResults = getFilteredResults(
            flattenedResponse.objects.geoResponse,
            selectedFilters,
            'geoGlobal'
          );
          dispatch(actions.updateGeoConfigScreen(
            { flattenedResponse: flattenedResponse.objects.geoResponse, filteredResults }
          ));
          dispatch(actions.updateFilterDropdownValues(
            getFilterDropdownValuesFromResponse(flattenedResponse.objects.geoResponse, [
              'ruleName',
              'destinationCountryCode',
              'division',
              'plantCode',
              'docType',
              'purchaseGroup',
              'email',
              'emailType'
            ], 'geoGlobal')
          ));
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(response);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          console.log(error);
          callback(error);
        });
      }
    );
  }

  function addGeoStakeholderDetails() {
    return createAsyncThunk(
      `${name}/addGeoStakeholderDetails`,
      async ({
        data, callback, selectedRule
      }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        const updatedData = data.map((row) => {
          const { value } = row.emailType;
          const { ruleName: _, ...updatedRow } = row;
          if (selectedRule === 'docTypePurchaseGroupRule' || selectedRule === 'docTypeRule') {
            const docTypeVal = row.docType.value;
            return ({
              ...updatedRow,
              emailType: value,
              docType: docTypeVal
            });
          }
          if (selectedRule === 'destCountryDivRule') {
            const destVal = row.destinationCountryCode.value;
            const divVal = row.division.value;
            return ({
              ...updatedRow,
              emailType: value,
              destinationCountryCode: destVal,
              division: divVal
            });
          }
          if (selectedRule === 'divDocTypeRule') {
            const divVal = row.division.value;
            const docTypeVal = row.docType.value;
            return ({
              ...updatedRow,
              emailType: value,
              division: divVal,
              docType: docTypeVal
            });
          }
          if (selectedRule === 'divPlantRule') {
            const divVal = row.division.value;
            return ({
              ...updatedRow,
              emailType: value,
              division: divVal
            });
          }
          return updatedRow;
        });

        const geoStakeholderRequestBody = {
          rule: selectedRule,
          geoData: updatedData
        };
        request({
          api: 'geoStakeholdersEmail',
          method: 'post',
          data: geoStakeholderRequestBody,
          cancellable: true
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          callback(response.data);
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          callback(null, error);
        });
      }
    );
  }
  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async (
        {
          payload
        },
        { getState, dispatch }
      ) => {
        dispatch(actions.updateFilterValues(payload));
        const state = getState();
        const { selectedFilters, unfilteredGeoConfigScreenData } = state.geoGlobal;
        const filteredResults = getFilteredResults(unfilteredGeoConfigScreenData, selectedFilters, 'geoGlobal');
        dispatch(actions.updateGeoFilterId());
        dispatch(actions.updateFilteredGeoConfigScreen(filteredResults));
      }
    );
  }
  function clearFilters() {
    return createAsyncThunk(
      `${name}/clearFilters`,
      async (_, { dispatch }) => {
        dispatch(actions.clearGeoFilters());
      }
    );
  }
  function updateGEOStakeholder() {
    return createAsyncThunk(
      `${name}/updateGEOStakeholder`,
      async ({
        data, callback
      }, { getState, dispatch }) => {
        if (data) {
          const updateRequestBody = {
            geoData: data
          };
          dispatch(toggleSpinner(true));
          request({
            api: 'geoStakeholdersEmail',
            method: 'put',
            data: updateRequestBody,
            cancellable: true
          },
          dispatch,
          getState).then((response) => {
            dispatch(toggleSpinner(false));
            callback(response.data);
          })
            .catch((error) => {
              dispatch(toggleSpinner(false));
              callback(null, error);
            });
        }
      }
    );
  }

  return {
    getGeoGlobalResults: getGeoGlobalResults(),
    addGeoStakeholderDetails: addGeoStakeholderDetails(),
    applyFilters: applyFilters(),
    clearFilters: clearFilters(),
    updateGEOStakeholder: updateGEOStakeholder()
  };
}

const extraActions = createExtraActions();

export const geoGlobalActions = { ...actions, ...extraActions };
export const geoGlobalReducer = slice.reducer;
