import lineSummaryReportFieldMapping from '../fieldMappings/lineSummaryReportFieldMapping';
import operationalMetricsFieldMapping from '../fieldMappings/operationalMetricsFieldMapping';

export default {
  [operationalMetricsFieldMapping.poCreatedOn]: { label: 'Create Date', type: 'date', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_CREATED_DATE },
  [operationalMetricsFieldMapping.documentDate]: { label: 'Document Date', type: 'date', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_DOC_DATE },
  [operationalMetricsFieldMapping.originalGoodsAtConsolidatorDate]: { label: 'OGAC', type: 'date', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_OGAC },
  [operationalMetricsFieldMapping.goodsAtConsolidatorDate]: { label: 'GAC', type: 'date', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_GAC },
  [operationalMetricsFieldMapping.vendorCode]: { label: 'Vendor', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_VENDOR, fieldType: 'dropdown' },
  [operationalMetricsFieldMapping.poDocTypeCode]: { label: 'Doc Type', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_DOC_TYPE_CODE, fieldType: 'dropdown' },
  [operationalMetricsFieldMapping.productCode]: { label: 'Product Code', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_PRODUCT_CODE },
  [operationalMetricsFieldMapping.seasonCode]: { label: 'Season', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_SEASON_CODE, fieldType: 'dropdown' },
  [operationalMetricsFieldMapping.purchaseOrgCode]: { label: 'Purchase Org', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_PURCHASE_ORG_CODE, fieldType: 'dropdown' },
  [operationalMetricsFieldMapping.categoryCode]: { label: 'Category', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_CATEGORY_CODE },
  [operationalMetricsFieldMapping.productionNikeLiaisonOffice]: { label: 'PMO', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_PMO_DEC_CODE, fieldType: 'dropdown' },
  [operationalMetricsFieldMapping.geographyCode]: { label: 'GEO', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_GEOGRAPHY_CODE, fieldType: 'singleSelect' },
  [operationalMetricsFieldMapping.division]: { label: 'Division', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_DIVISION_CODE, fieldType: 'dropdown' },
  [operationalMetricsFieldMapping.seasonYear]: { label: 'Season Year', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_SEASON_YEAR, fieldType: 'dropdown' },
  [operationalMetricsFieldMapping.subCategoryCode]: { label: 'Sub Category', lineSummaryField: lineSummaryReportFieldMapping.LINE_SUMMARY_SUB_CATEGORY_CODE }
};
