/**
 * nike custom component - NikeButton
 * default color theme will be primary from MUI.
 * can be customized based on the variant
 * @exports NikeButton
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import React from 'react';
import './styles.scss';
import { Button } from '@mui/material';

interface Props {
  color?: string;
  buttonText?: Object;
  className?: string;
  variant?: string;
  onClick?: Function;
  testid?: string;
}

export default function NikeButton(props: Props) {
  const {
    color,
    buttonText,
    variant,
    onClick,
    className,
    testid,
    ...other
  } = props;

  const getVariant = () => {
    switch (variant) {
      case 'contained':
        return 'contained';
      case 'outlined':
        return 'outlined';
      default:
        return 'text';
    }
  };

  const classname = `btn-nike-${variant || 'default'} nikeButton ${className || ''}`;
  const buttonTheme = color || 'primary';
  return (
    <Button
      color={buttonTheme}
      variant={getVariant()}
      className={classname}
      onClick={onClick}
      data-testid={testid || buttonText}
      {...other}
    >
      <span className="MuiButton-label">{buttonText}</span>
    </Button>
  );
}
