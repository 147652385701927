/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';

const name = 'externalLink';

function createInitialState() {
  return {
    usefulLinks: {}
  };
}

function createReducers() {
  function updateUsefulLinkData(state, action) {
    return {
      ...state,
      usefulLinks: action.payload
    };
  }

  function resetToInitialState(state) {
    return {
      ...state
    };
  }

  return {
    updateUsefulLinkData,
    resetToInitialState
  };
}

const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function fetchUsefulLink() {
    return createAsyncThunk(
      `${name}/fetchUsefulLink`,
      async (_, { dispatch }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'EXTERNALLINK',
          method: 'get'
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          dispatch(actions.updateUsefulLinkData(response.data.usefulLinks));
        }).catch((error) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }

  function fetchDashboard() {
    return createAsyncThunk(
      `${name}/fetchDashboard`,
      async (props, { dispatch }) => {
        const { test, callback } = props;
        console.log(test);
        dispatch(toggleSpinner(true));
        request({
          api: 'DASHBOARD',
          method: 'get'
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          console.log('response', response);
          callback(response);
        }).catch((error) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }
  function resetExternalLink() {
    return createAsyncThunk(
      `${name}/resetExternalLink`,
      (currentPage, { getState, dispatch }) => {
        const state = getState();
        if (state.externalLink.usefulLinks && currentPage !== 'EXTERNALINK') {
          dispatch(actions.resetToInitialState());
        }
      }
    );
  }

  return {
    resetExternalLink: resetExternalLink(),
    fetchUsefulLink: fetchUsefulLink(),
    fetchDashboard: fetchDashboard()
  };
}

const extraActions = createExtraActions();

export const externalLinkActions = { ...actions, ...extraActions };
export const externalLinkReducer = slice.reducer;
