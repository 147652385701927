import Axios from 'axios';
import env from '../env.json';
import {
  setCancellableSpinner, updateErrorText, pushSnackbarContent, setRequestError, setSessionTimeout
} from '../features/dialogStates/dialogStatesSlice';
import messageText from '../constants/messageText';
import appConfig from '../appConfig';
import { getEndpointURL } from '../helpers/common';

export const axios = Axios.create({
  timeout: appConfig.apiTimeoutDuration,
  headers: {
    'Ocp-Apim-Subscription-Key': env.SUBSCRIPTION_KEY
    // 'Ocp-Apim-Subscription-Key': process.env[`REACT_APP_DPOM_SUBSCRIPTION_KEY_${env.APISource}`]
  }
});

export const cancellableRequestInstance = {
  source: null,
  pendingCancellableRequests: 0
};

export const cancelRequest = () => () => {
  if (cancellableRequestInstance.source && typeof cancellableRequestInstance.source.cancel === 'function') {
    cancellableRequestInstance.source.cancel('Cancelled by user');
  }
};

/**
 * Requests a URL, returning a promise
 * @param  {string} url The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object} The response data
 */
export const defaultInterceptor = async (config) => {
  const modifiedConfig = { ...config };
  let bearerJWT = '';
  let legacyUsername = '';
  let email = '';
  try {
    const token = await localStorage.getItem('okta-token-storage');
    const parsedToken = JSON.parse(token);
    bearerJWT = parsedToken.accessToken.value;
    email = parsedToken.idToken.claims.email;
    legacyUsername = parsedToken.idToken.claims.legacy_username;
  } catch (error) {
    console.log('parsing okta token failed');
  }

  if (bearerJWT === '') {
    throw new Axios.Cancel('Session Expired');
  }

  modifiedConfig.headers['Ocp-Apim-Subscription-Key'] = env.SUBSCRIPTION_KEY;
  modifiedConfig.headers['bearer-jwt'] = bearerJWT;
  modifiedConfig.headers.legacy_username = legacyUsername;
  modifiedConfig.headers.username = email;
  modifiedConfig.headers.emailid = email;

  return modifiedConfig;
};

axios.interceptors.request.use(defaultInterceptor);

const fetch = (options) => {
  const {
    url, method, data, responseType, source, headers = {}
  } = options;
  switch (method.toLowerCase()) {
    case 'get': {
      const config = { params: data };
      if (responseType) {
        config.responseType = responseType;
      }
      return axios({
        url,
        method,
        headers,
        config,
        cancelToken: source ? source.token : undefined,
        responseType
      });
    }
    case 'post':
      return axios({
        url,
        headers,
        method,
        cancelToken: source ? source.token : undefined,
        data
      });
    case 'delete':
    case 'put':
      return axios({
        url,
        headers,
        method,
        data
      });
    case 'fileupload':
      return axios({
        url,
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          type: 'formData'
        }
      });
    default:
      return axios(options);
  }
};

export function getMessageFromApi(data, statusText) {
  return (typeof data === 'string')
    ? data : (typeof data === 'object' && data?.message) || statusText;
}

export function captureTimedOutRequest(
  error, statusCode, title, desc, timeoutErrorMessage, dispatch
) {
  /* eslint-disable no-param-reassign */
  switch (error.code) {
    case 'SOMETHING':
    case 'ECONNABORTED': {
      dispatch(pushSnackbarContent(timeoutErrorMessage));
      break;
    }
    default: {
      statusCode = 600;
      title = messageText.error;
      desc = 'Internal Error';
      console.log(error);
      dispatch(updateErrorText({ errCode: statusCode, errDesc: desc, errTitle: title }));
    }
  }
  return { statusCode, title, desc };
}

export function captureApiError(response, statusCode, msg, dispatch) {
  switch (response.status) {
    case 404:
    case 500:
    case 503:
    case 401:
    case 403:
      dispatch(updateErrorText({
        errCode: statusCode,
        errDesc: messageText.appErrorDescription,
        errTitle: messageText.error
      }));
      break;
    default:
      dispatch(updateErrorText({ errCode: statusCode, errDesc: msg, errTitle: messageText.error }));
  }
}

export function checkIfSessionExpired(error) {
  return error instanceof Axios.Cancel && error.message === 'Session Expired';
}

export function checkForNetworkAndTimedoutError(
  error, statusCode, msg, responseData, timeoutErrMsg, dispatch
) {
  const { response } = error;
  const { data, statusText } = response || {};
  if (response && response instanceof Object) {
    statusCode = response.status;
    msg = getMessageFromApi(data, statusText);
    responseData = data;
    captureApiError(response, statusCode, msg, dispatch);
  } else {
    const title = '';
    const desc = '';
    try {
      ({ statusCode } = captureTimedOutRequest(error, statusCode, title, desc, timeoutErrMsg));
      msg = (error && error.message) || 'Network Error';
    } catch (err) {
      console.log('Unexpected Error:', err);
    }
  }
  return { statusCode, msg, responseData };
}

function callResetCancelToken(cancellable, dispatch) {
  if (cancellable) {
    if (cancellableRequestInstance.pendingCancellableRequests) {
      cancellableRequestInstance.pendingCancellableRequests -= 1;
    }
    if (cancellableRequestInstance.pendingCancellableRequests === 0) {
      cancellableRequestInstance.source = null;
      dispatch(setCancellableSpinner(false));
    }
  }
}

function checkForCancellableInstance(source, dispatch) {
  cancellableRequestInstance.pendingCancellableRequests += 1;
  dispatch(setCancellableSpinner(true));
  if (!cancellableRequestInstance.source) {
    cancellableRequestInstance.source = source;
  }
}

export function checkIfCancelledByUser(error) {
  return error instanceof Axios.Cancel && error.message === 'Cancelled by user';
}

export default async function request(options, dispatch) {
  const source = Axios.CancelToken.source();
  if (options.cancellable) {
    checkForCancellableInstance(source, dispatch);
  }

  const resetCancelToken = (cancellable) => {
    callResetCancelToken(cancellable, dispatch);
  };
  let endpointUrl = '';
  let timeoutErrMsg = '';
  if (options.url) {
    endpointUrl = options.url;
  } else {
    const {
      url, timeoutErrorMessage
    } = getEndpointURL(options.api, options.routeParams);
    endpointUrl = url;
    timeoutErrMsg = timeoutErrorMessage;
  }
  return fetch({ ...options, url: endpointUrl, source: cancellableRequestInstance.source })
    .then((response) => {
      const { statusText, status } = response || {};
      let { data } = response || {};
      if (data instanceof Array) {
        data = {
          list: data
        };
      }
      return Promise.resolve({
        success: true,
        message: statusText,
        statusCode: status,
        data
      });
    })
    .catch((error = {}) => {
      let msg;
      let statusCode;
      let responseData;
      if (checkIfCancelledByUser(error)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          success: false,
          statusCode: 'ABORTED_BY_USER',
          message: error.message
        });
      }

      if (!options.suppressErrorDialog && error.code !== 'ECONNABORTED') {
        dispatch(setRequestError(true));
      }

      if (checkIfSessionExpired(error)) {
        dispatch(setSessionTimeout({
          errCode: 419,
          errDesc: messageText.sessionTimeoutDesc,
          errTitle: messageText.sessionTimeout,
          errSessionTimeout: true
        }));
      } else {
        ({ statusCode, msg, responseData } = checkForNetworkAndTimedoutError(
          error, statusCode, msg, responseData, timeoutErrMsg, dispatch
        ));
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        success: false,
        statusCode,
        message: msg,
        data: responseData
      });
    }).finally(() => {
      resetCancelToken(options.cancellable);
    });
}
