/* @flow */
import lineSummaryReportsHeader from '../../constants/lineSummaryReportsHeader';
import lineSummaryReportFieldProperties from '../../constants/fieldProperties/lineSummaryReportFieldProperties';

export function getFieldsList(lineItemSecondaryFields: Object) {
  lineSummaryReportsHeader
    .filter((header) => header.default)
    .forEach((header) => {
      lineItemSecondaryFields.push(header.primary);
      if (header.secondary) {
        lineItemSecondaryFields.push(header.secondary);
      }
      if (header.additionalFields) {
        lineItemSecondaryFields.push(...header.additionalFields);
      }
    });
  lineSummaryReportsHeader.forEach((headRow: Object) => {
    if (headRow.includeInRequest) {
      lineItemSecondaryFields.push(headRow.primary);
      if (headRow.secondary && !lineItemSecondaryFields.includes(headRow.secondary)) {
        lineItemSecondaryFields.push(headRow.secondary);
      }
    }
  });
}

export function getRequestedFilterValuesInLineItemResults(requestBody: Object, state: Object) {
  const requestedBody = requestBody;
  requestedBody.filtersInResponse = Object.keys(state.lineSummaryReportFilters.selectedFilters)
    .filter((filter) => state.lineSummaryReportFilters.selectedFilters[filter])
    .map((filter) => {
      const lineItemFilterInResponse = {};
      const lineItemFieldProperties = lineSummaryReportFieldProperties[filter] || {};
      if (!lineItemFieldProperties) {
        return null;
      }
      lineItemFilterInResponse.primary = lineItemFieldProperties.primary;
      if (lineItemFieldProperties.secondary) {
        lineItemFilterInResponse.secondary = lineItemFieldProperties.secondary;
      }
      return lineItemFilterInResponse;
    })
    .filter((element) => element);
}
export function getRequestedFilterValues(requestBody: Object, selectedFilterKeys: Object) {
  const requestedBody = requestBody;
  requestedBody.filtersInResponse = selectedFilterKeys.map((filter) => {
    const filterInResponse = {};
    const fieldProperties = lineSummaryReportFieldProperties[filter] || {};
    if (!fieldProperties) {
      return null;
    }
    filterInResponse.primary = fieldProperties.primary;
    if (fieldProperties.secondary) {
      filterInResponse.secondary = fieldProperties.secondary;
    }
    return filterInResponse;
  })
    .filter((element) => element);
}
export function getSelectedFilterValues(requestBody: Object, state: Object) {
  const requestedBody = requestBody;
  requestedBody.filtersInResponse = (state.searchpaneldata
        && state.searchpaneldata.selectedFilterOptions)
    .map((filter) => {
      const filterInResponse = {};
      const fieldProperties = lineSummaryReportFieldProperties[filter] || {};
      if (!fieldProperties) {
        return null;
      }
      filterInResponse.primary = fieldProperties.primary;
      if (fieldProperties.secondary) {
        filterInResponse.secondary = fieldProperties.secondary;
      }
      return filterInResponse;
    });
}

export function getFiltersInResponseRequestBody(filterData: Object) {
  const filtersInRequestBody = filterData && filterData
    .map((filter) => {
      const lineItemFilterInResponse = {};
      const lineItemFieldProperties = lineSummaryReportFieldProperties[filter.fieldName] || {};
      if (!lineItemFieldProperties) {
        return null;
      }
      lineItemFilterInResponse.primary = lineItemFieldProperties.primary;
      if (lineItemFieldProperties.secondary) {
        lineItemFilterInResponse.secondary = lineItemFieldProperties.secondary;
      }
      return lineItemFilterInResponse;
    });
  return filtersInRequestBody.filter((element) => element);
}

export function getSelectedFilterValuesFromOpMetrics(filterValues: Object) {
  const filtersFromOperationalMetrics = filterValues && filterValues
    .map((filter) => {
      const filterInResponse = {};
      const fieldProperties = lineSummaryReportFieldProperties[filter] || {};
      if (!fieldProperties.primary) {
        return null;
      }
      filterInResponse.primary = fieldProperties.primary;
      if (fieldProperties.secondary) {
        filterInResponse.secondary = fieldProperties.secondary;
      }
      return filterInResponse;
    });
  return filtersFromOperationalMetrics?.filter((element) => element);
}
export function setFieldsData(isOperationalCall: Boolean, fieldsData: Object) {
  if (isOperationalCall) {
    lineSummaryReportsHeader.forEach((headRow: Object) => {
      if (headRow.includeInRequest) {
        fieldsData.push(headRow.primary);
        if (headRow.secondary) {
          fieldsData.push(headRow.secondary);
        }
      }
    });
  }
  return fieldsData;
}
export function getLineItemSelectedFilterKeys(
  selectedFilterKeys: Object,
  modifiedSelectedFilters: Object,
  filterDropdownValues: Object,
  lineItemFiltersToBeRequested: Object,
  filtersInResponse: Object
) {
  selectedFilterKeys.forEach((filter) => {
    const filterRequest: { primary: string; secondary?: string; } = { primary: filter };
    if (lineSummaryReportFieldProperties[filter]
        && lineSummaryReportFieldProperties[filter].secondary) {
      filterRequest.secondary = lineSummaryReportFieldProperties[filter].secondary;
    }
    if (modifiedSelectedFilters[filter]) {
      if (!filterDropdownValues[filter]) {
        lineItemFiltersToBeRequested.push(filterRequest);
      }
      filtersInResponse.push(filterRequest);
    }
  });
}
export function getLineItemModifiedSelectedFilters(
  modifiedSelectedFilters: Object,
  filtersSelected: Object
) {
  Object.keys(modifiedSelectedFilters).forEach((filter: string) => {
    const modifiedSelectedFiltersNew = modifiedSelectedFilters;
    if (filtersSelected.includes(filter)) {
      if (!modifiedSelectedFilters[filter]) modifiedSelectedFiltersNew[filter] = [];
    } else {
      modifiedSelectedFiltersNew[filter] = null;
    }
  });
}
