/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import { cryptoRand } from '../../helpers/common';

export const name = 'conversationCategoryCodeConfig';

function createInitialState() {
  return {
    dataId: '',
    dataRows: []
  };
}

export const initialState = createInitialState();

function createReducers() {
  function setConversationCategoryCodes(state: Object, action: Object) {
    // SET_CONVERSATION_CATEGORY_CODES

    const dataId = cryptoRand().toString().substr(2, 8);
    const dataRows = action.payload.objects;

    return {
      ...state,
      dataRows,
      dataId
    };
  }

  function addConversationCategoryCodes(state: Object) {
    // ADD_CONVERSATION_CATEGORY_CODES

    return state;
  }

  function updateConversationCategoryCodes(state: Object) {
    // UPDATE_CONVERSATION_CATEGORY_CODES

    return state;
  }

  return {
    setConversationCategoryCodes,
    addConversationCategoryCodes,
    updateConversationCategoryCodes
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function callGetConversationCodeAPI() {
    return createAsyncThunk(
      `${name}/callGetConversationCodeAPI`,
      async (
        { callback },
        { getState, dispatch }
      ) => {
        const fields = 'ConversationCode,ConversationDescription,modifiedBy,modifiedDate,isActive,id,conversationCategory';
        const filters = 'isActive(true)';
        const query = `?fields=${fields}&filter=${filters}`;
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'getConversationCategoryCodes',
            routeParams: { query },
            method: 'get'
          },
          dispatch,
          getState
        )
          .then((response) => {
            dispatch(toggleSpinner(false));
            dispatch(actions.setConversationCategoryCodes(response.data));
            if (callback) callback(response.data);
          })
          .catch((error) => {
            console.log('callGetConversationCodeAPI->error', error);
            dispatch(toggleSpinner(false));
            if (callback) callback(error);
          });
      }
    );
  }

  function callAddConversationCodeAPI() {
    return createAsyncThunk(
      `${name}/callAddConversationCodeAPI`,
      async (
        { payload, callback },
        { getState, dispatch }
      ) => {
        const inputList = [];
        payload.forEach((row, index) => {
          const element = {};
          element.ConversationCode = payload[index].ConversationCode;
          if (row.conversationCategory === true) {
            element.conversationCategory = 'REJECT';
            element.ConversationDescription = 'PO Reject - '.concat(payload[index].ConversationDescription);
          } else {
            element.conversationCategory = 'Other';
            element.ConversationDescription = payload[index].ConversationDescription;
          }
          inputList.push(element);
        });
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'addConversationCodes',
            method: 'post',
            data: { conversationData: inputList }
          },
          dispatch,
          getState
        )
          .then((response) => {
            dispatch(toggleSpinner(false));
            if (callback) callback(response.data);
          })
          .catch((error) => {
            console.log('callAddConversationCodeAPI->error', error);
            dispatch(toggleSpinner(false));
            if (callback) callback(error);
          });
      }
    );
  }

  function callUpdateConversationCodeAPI() {
    return createAsyncThunk(
      `${name}/callUpdateConversationCodeAPI`,
      async (
        { payload, callback },
        { getState, dispatch }
      ) => {
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'updateConversationCodes',
            method: 'put',
            data: {
              type: 'update',
              conversationData: payload
            }
          },
          dispatch,
          getState
        )
          .then((response) => {
            dispatch(toggleSpinner(false));
            if (callback) callback(response.data);
          })
          .catch((error) => {
            console.log('callUpdateConversationCodeAPI error', error);
            dispatch(toggleSpinner(false));
            if (callback) callback(error);
          });
      }
    );
  }

  return {
    callGetConversationCodeAPI: callGetConversationCodeAPI(),
    callAddConversationCodeAPI: callAddConversationCodeAPI(),
    callUpdateConversationCodeAPI: callUpdateConversationCodeAPI()
  };
}

export const extraActions = createExtraActions();

export const conversationCategoryCodeConfigActions = { ...actions, ...extraActions };
export const conversationCategoryCodeConfigReducer = slice.reducer;
