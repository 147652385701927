/**
 * This file is used for functions and static data for date range component
 * @author {Cognizant Technology Solutions}
 * @flow
 */

export const adjustDate = (
  date: Date = new Date(), days: number = 0, isEndDate: boolean = false
) => {
  if (Number.isNaN(days)) {
    return date;
  }
  const adjustedDate = new Date(date);
  adjustedDate.setDate(adjustedDate.getDate() + days);
  if (isEndDate) {
    adjustedDate.setHours(23, 59, 59, 999);
  } else {
    adjustedDate.setHours(0, 0, 0, 0);
  }
  return adjustedDate;
};

export const getCorrectSelectionOrder = ({ startDate, endDate, key = 'selection' }: {
  startDate: Date, endDate: Date, key?: string
} = {}) => {
  if (startDate && endDate && (startDate > endDate)) {
    return {
      key,
      startDate: endDate,
      endDate: startDate
    };
  }
  return {
    key,
    startDate,
    endDate
  };
};

export const calculateInputRange = ({ startDate, endDate }: {
  startDate?: Object, endDate?: Object
}) => {
  let startDaysFromToday = null;
  let endDaysFromToday = null;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (startDate && startDate.getDate && !Number.isNaN(startDate.getDate())) {
    const startDateWithoutTime = startDate;
    startDateWithoutTime.setHours(0, 0, 0, 0);
    startDaysFromToday = Math.round((startDateWithoutTime - today) / 86400000);
  }
  if (endDate && endDate.getDate && !Number.isNaN(endDate.getDate())) {
    const endDateWithoutTime = endDate;
    endDateWithoutTime.setHours(0, 0, 0, 0);
    endDaysFromToday = Math.round((endDateWithoutTime - today) / 86400000);
  }
  return {
    startDays: startDaysFromToday,
    endDays: endDaysFromToday
  };
};

export const calculateDateFromInputRange = ({
  startFromDays,
  endFromDays
}: { startFromDays?: Object, endFromDays?: Object } = {}) => {
  const today = new Date();
  let startDate = null;
  let endDate = null;
  const startDaysInNumber = parseInt(startFromDays, 10);
  const endDaysInNumber = parseInt(endFromDays, 10);
  if (!Number.isNaN(startDaysInNumber)) {
    startDate = adjustDate(today, startDaysInNumber);
  }
  if (!Number.isNaN(endDaysInNumber)) {
    endDate = adjustDate(today, endDaysInNumber);
  }
  return {
    startDate,
    endDate
  };
};

export const getDifferenceInDays = (startDate: Object, endDate: Object) => {
  const diffTime = (endDate || adjustDate(new Date(), 0, true))
    - (startDate || adjustDate(new Date(), 0));
  return Math.ceil(diffTime / 86400000);
};

export const predefinedRanges = [
  {
    label: 'Last Month',
    range: () => {
      const today = new Date();
      const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
      return ({
        startDate: adjustDate(lastMonthStart, 0),
        endDate: adjustDate(lastMonthEnd, 0, true)
      });
    },
    id: 'LAST_MONTH',
    hasCustomRendering: true
  },
  {
    label: 'Last week',
    range: () => {
      const today = new Date();
      const thisDay = today.getDay();

      return ({
        startDate: adjustDate(today, -((thisDay === 0) ? 7 : thisDay) - 6),
        endDate: adjustDate(today, -((thisDay === 0) ? 7 : thisDay), true)
      });
    },
    id: 'LAST_WEEK',
    hasCustomRendering: true
  },
  {
    label: 'Today',
    range: () => ({
      startDate: adjustDate(new Date(), 0),
      endDate: adjustDate(new Date(), 0, true)
    }),
    hasCustomRendering: true,
    id: 'TODAY'
  },
  {
    label: 'Next week',
    range: () => {
      const today = new Date();
      const thisDay = today.getDay();
      return ({
        startDate: adjustDate(today, 8 - ((thisDay === 0) ? 7 : thisDay)),
        endDate: adjustDate(today, 14 - ((thisDay === 0) ? 7 : thisDay), true)
      });
    },
    hasCustomRendering: true,
    id: 'NEXT_WEEK'
  },

  {
    label: 'Next month',
    range: () => {
      const today = new Date();
      const nextMonthStart = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const nextMonthEnd = new Date(nextMonthStart.getFullYear(), nextMonthStart.getMonth() + 1, 0);
      return ({
        startDate: adjustDate(nextMonthStart, 0),
        endDate: adjustDate(nextMonthEnd, 0, true)
      });
    },
    hasCustomRendering: true,
    id: 'NEXT_MONTH'
  }
];

export const getDateString = (date: any) => {
  if (typeof date === 'string') {
    return date;
  }
  if (date instanceof Date) {
    return date.toDateString();
  }
  return '';
};

export const getDateRangeFromStaticRange = (id: string) => {
  const currentRange = predefinedRanges
    .find((predefinedRange) => predefinedRange.id === id);
  const today = new Date();
  return (currentRange ? currentRange.range() : {
    startDate: adjustDate(today, 0),
    endDate: adjustDate(today, 0, true)
  });
};
