/**
 * Store
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import dialogStatesReducer from './features/dialogStates/dialogStatesSlice';
import { searchPanelDataReducer } from './features/searchPanelData/searchPanelDataSlice';
import { ppmReportRequestReducer } from './features/ppmReport/ppmReportRequestSlice';
import { poReportReducer } from './features/poReport/poReportSlice';
import { poChangeReportReducer } from './features/poChangeReport/poChangeReportSlice';
import { conversationThreadReportReducer } from './features/conversationThreadReport/conversationThreadReportSlice';
import { externalLinkReducer } from './features/externalLink/externalLinkSlice';
import { dashboardReducer } from './features/Dashboard/dashboardSlice';
import { settingsReducer } from './features/profileSettings/settingSlice';
import { lineSummaryReducer } from './features/lineSummaryReport/lineSummaryReportSlice';
import { poSearchResultReducer } from './features/poSearch/poSearchResultSlice';
import { gacChangeReportReducer } from './features/gacChangeHistoryReport/gacChangeHistoryReportSlice';
import { sireDeliveryReducer } from './features/sireDelivery/sireDeliverySlice';
import { fileUDReducer } from './features/fileUD/fileUDSlice';
import { conversationModalReducer } from './features/conversationModal/conversationModalSlice';
import { schedulerReducer } from './features/scheduler/schedulerSlice';
import { conversationThreadsReducer } from './features/conversationThread/conversationThreadSlice';
import { operationalMetricsReducer } from './features/operationMetricsDashboard/operationMetricsSlice';
import { airFreightReportReducer } from './features/airFreightReport/airFreightReportSlice';
import { conversationCategoryCodeConfigReducer } from './features/conversationCategoryCode/conversationCategoryCodeSlice';
import { geoEmailGroupReducer } from './features/geoEmailGroup/geoEmailGroupSlice';
import { geoGlobalReducer } from './features/geoGlobalLookup/geoGlobalSlice';
import { pmoDecConfigReducer } from './features/pmoDecConfig/pmoDecConfigSlice';
import { gacReasonCodeReducer } from './features/gacReasonCode/gacReasonCodeSlice';
import { gacReasonUseCasesReducer } from './features/gacReasonUsecases/gacReasonUsecasesSlice';
import { airFreightOverrideReducer } from './features/airFreightOverride/airFreightOverrideSlice';
import { hamburgerReducer } from './features/hamburger/hamburgerSlice';
import { featureFlagReducer } from './features/featureFlag/featureFlagSlice';

const toolkitStore = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  reducer: {
    // Define a top-level state field named `todos`, handled by `todosReducer`
    // redux Toolkit reucers
    auth: authReducer,
    Hamburger: hamburgerReducer,
    searchpaneldata: searchPanelDataReducer,
    ppmReport: ppmReportRequestReducer,
    poReport: poReportReducer,
    poChangeReport: poChangeReportReducer,
    conversationThreadReport: conversationThreadReportReducer,
    externalLink: externalLinkReducer,
    dialogStates: dialogStatesReducer,
    Dashboard: dashboardReducer,
    settings: settingsReducer,
    lineSummaryReport: lineSummaryReducer,
    PoSearch: poSearchResultReducer,
    gacChangeHistoryReport: gacChangeReportReducer,
    sireDelivery: sireDeliveryReducer,
    fileUD: fileUDReducer,
    conversationModal: conversationModalReducer,
    conversationThreads: conversationThreadsReducer,
    scheduler: schedulerReducer,
    OperationalMetrics: operationalMetricsReducer,
    airFreightReport: airFreightReportReducer,
    conversationCategoryCodeConfig: conversationCategoryCodeConfigReducer,
    geoEmail: geoEmailGroupReducer,
    geoGlobal: geoGlobalReducer,
    pmodecLookup: pmoDecConfigReducer,
    GacReasonCode: gacReasonCodeReducer,
    GacReasonUseCases: gacReasonUseCasesReducer,
    afOverride: airFreightOverrideReducer,
    featureFlag: featureFlagReducer
  }
});
export default toolkitStore;
