/**
 * @flow
 */
import { showForNONPROD } from '../../helpers/envServer';
import gacReasonCodeFieldMapping from '../fieldMappings/gacReasonCodeFieldMapping';

export function validateNumeric(value: string) {
  const regex = /^\d+$/;
  if (!regex.test(value) && value) {
    return 'Enter Valid Input Value.';
  }
  return '';
}

export function validate(value: string) {
  if (this.isRequired && !value) {
    return 'This is a mandatory field.';
  }

  switch (this.primary) {
    case 'reasonCode': {
      return validateNumeric(value);
    }
    case 'reasonCodeDescription':
    default: {
      return '';
    }
  }
}

export default {
  [gacReasonCodeFieldMapping.GAC_REASON_CODE]: {
    primary: gacReasonCodeFieldMapping.GAC_REASON_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'GAC Reason Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    validate,
    validationType: 'numeric',
    isRequired: true,
    gridSpan: 2
  },
  [gacReasonCodeFieldMapping.GAC_REASON_CODE_DESCRIPTION]: {
    primary: gacReasonCodeFieldMapping.GAC_REASON_CODE_DESCRIPTION,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Description',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    validate,
    isEditable: true,
    isRequired: true,
    gridSpan: 4
  },
  [gacReasonCodeFieldMapping.GAC_REASON_CODE_ISACTIVE]: showForNONPROD ? {
    primary: gacReasonCodeFieldMapping.GAC_REASON_CODE_ISACTIVE,
    secondary: '',
    type: 'boolean',
    link: false,
    label: 'Status',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    isEditable: true,
    isRequired: true,
    gridSpan: 4
  } : {
    primary: gacReasonCodeFieldMapping.GAC_REASON_CODE_ISACTIVE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Status',
    wrap: true,
    numeric: 'false',
    disablePadding: false
  },
  [gacReasonCodeFieldMapping.GAC_MODIFIED_BY]: {
    primary: gacReasonCodeFieldMapping.GAC_MODIFIED_BY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Last Edited By',
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    isEditable: false
  },
  [gacReasonCodeFieldMapping.GAC_MODIFY_DATE]: {
    primary: gacReasonCodeFieldMapping.GAC_MODIFY_DATE,
    secondary: '',
    type: 'datetime',
    link: false,
    label: 'Last Edited On',
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    isEditable: false
  }
};
