export default {
  PO_PDF_EVENTCREATETS: 'eventCreateTS',
  PO_PDF_EVENTSTATUS: 'eventStatus',
  PO_PDF_ERRORSFOUNDCOUNT: 'errorsFoundCount',
  PO_PDF_TOTALERRORCOUNT: 'totalErrorCount',
  PO_PDF_EVENTERRORLIST: 'eventErrorList',
  PO_PDF_PDFDOWNLOADLIST: 'pdfDownloadLink',
  PO_PDF_TOTALRECEIVEDITEMCOUNT: 'totalReceivedItemsCount',
  PO_PDF_PONUMBER: 'poNumber',
  PO_PDF_REASON: 'reason'
};
