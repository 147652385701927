import env from '../env.json';

const fxCalculation = `REACT_APP_DPOM_FXCALCULATION_API_PARAMS_${env.APISource}`;
const announcement = `REACT_APP_DPOM_ANNOUCEMENT_API_PARAMS_${env.APISource}`;
const externalLink = `REACT_APP_DPOM_EXTERNAL_LINK_API_PARAMS_${env.APISource}`;

export default {
  POSearch: {
    endPoint: 'dpom_purchaseorder/purchaseorder/v0',
    apiErrorName: 'PO Search',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  collaborationReportSearch: {
    endPoint: 'dpom_collaborationreport/collabsearch/v0',
    apiErrorName: 'Collab History Search',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  collaborationReportExport: {
    endPoint: 'dpom_collaborationreport/collabexport/v0',
    apiErrorName: 'Collab History Export',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  collaborationReportExportLargeFile: {
    endPoint: 'dpom_report/collabexport/v0',
    apiErrorName: 'Collab History Export',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  poChangeReportSearch: {
    endPoint: 'dpom_changereport/changereportsearch/v0',
    apiErrorName: 'Change Report Search',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  poChangeReportExport: {
    endPoint: 'dpom_changereport/changereportexport/v0',
    apiErrorName: 'Change Report Export',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  poChangeReportExportLargeFile: {
    endPoint: 'dpom_report/changereportexport/v0',
    apiErrorName: 'Change Report Export',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  conversationThreadReportSearch: {
    endPoint: 'dpom_conversationreport/conversationsearch/v0',
    apiErrorName: 'Conversation Thread Report Search',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  conversationThreadReportExport: {
    endPoint: 'dpom_conversationreport/conversationexport/v0',
    apiErrorName: 'Conversation Thread Report Export',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  conversationThreadReportExportLargeFile: {
    endPoint: 'dpom_report/conversationexport/v0',
    apiErrorName: 'Conversation Thread Report Export',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  ppmReportSearch: {
    endPoint: 'dpom_ppmreport/ppmsearch/v0',
    apiErrorName: 'PPM Search',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  fxAdjustment: {
    endPoint: 'dpom_fxadjustment/update/v0',
    apiErrorName: 'FX Adjustment request',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  ppmReportExport: {
    endPoint: 'dpom_ppmreport/ppmexport/v0',
    apiErrorName: 'PPM Export',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  ppmReportExportLargeFile: {
    endPoint: 'dpom_report/ppmexport/v0',
    apiErrorName: 'PPM Export',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  POExport: {
    endPoint: 'dpom_purchaseorder/purchaseorderexport/v0',
    apiErrorName: 'PO Search Export',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  ProfileSummary: {
    endPoint: 'dpom_sireprofile/customsearch/v0',
    apiErrorName: 'Sire Profile summary',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  SIREPROFILESEARCH: {
    endPoint: 'dpom_sireprofile/search/v0',
    apiErrorName: 'Sire Profile',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  DOWNLOADSIREPROFILE: {
    endPoint: 'dpom_siredeliveryinstructions/export/v0',
    apiErrorName: 'Delivery instructions download',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  SireDeliveryInstructions: {
    endPoint: 'dpom_siredeliveryinstructions/view/v0',
    apiErrorName: 'Sire profile View',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  defaultStakeHolders: {
    endPoint: 'dpom_conversation/stakeholders/v0',
    apiErrorName: 'Stakeholders list',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  getConversationCodes: {
    endPoint: 'dpom_config/conversationcode/v0/{{query}}',
    apiErrorName: 'Conversation codes',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  getConversationCategoryCodes: {
    endPoint: 'dpom_config/conversationcode/v0/{{query}}',
    apiErrorName: 'Conversation codes get',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  addConversationCodes: {
    endPoint: 'dpom_config/conversationcode/v0',
    apiErrorName: 'Conversation codes add',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  updateConversationCodes: {
    endPoint: 'dpom_config/conversationcode/v0',
    apiErrorName: 'Conversation codes update',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  getGeoEmailGroups: {
    endPoint: 'dpom_config/GeoEmailGroups/v0/{{query}}',
    apiErrorName: 'Geo Email Groups get',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  getGeoStakeholdersEmail: {
    endPoint: 'dpom_config/geoStakeholdersEmail/v0/{{query}}',
    apiErrorName: 'Get GeoStakeholders Email',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  geoStakeholdersEmail: {
    endPoint: 'dpom_config/geoStakeholdersEmail/v0',
    apiErrorName: 'Stakeholder Email',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  updateGeoEmailGroups: {
    endPoint: 'dpom_config/GeoEmailGroups/v0',
    apiErrorName: 'Geo Email Groups update',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  uploadFileForConversation: {
    endPoint: 'dpom_conversation/attachment/v0',
    apiErrorName: 'File upload',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  getConversations: {
    endPoint: 'dpom_conversation/conversation/v0',
    apiErrorName: 'Conversation list',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  gacInAppUpdate: {
    endPoint: 'dpom_poupdate/update/v0',
    apiErrorName: 'PO Update',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  gacUploadEvents: {
    endPoint: 'dpom_poupdate/eventstatus/v0?eventType=PO_GAC_UPDATE',
    apiErrorName: 'GAC Upload Status',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  wipEvents: {
    endPoint: 'dpom_poupdate/eventstatus/v0?eventType=PO_WIP_UPDATE',
    apiErrorName: 'WIP Upload Status',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  poPdfEvents: {
    endPoint: 'dpom_popdf/viewpopdf/v0',
    apiErrorName: 'PO PDF Download Status',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  sireDeliveryEvents: {
    endPoint: 'dpom_siredeliveryinstructions/viewsireevent/v0',
    apiErrorName: 'Sire Delivery Download Status',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  poSearchEvents: {
    endPoint: 'dpom_siredeliveryinstructions/viewsearchevent/v0',
    apiErrorName: 'po Search Download Status',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  reportEvents: {
    endPoint: 'dpom_report/getlargefileeventstatus/v0?{{query}}',
    apiErrorName: 'report Download Status',
    mockType: true,
    retry: true,
    type: 'Type1'
  },
  acceptanceEvents: {
    endPoint: 'dpom_poupdate/eventstatus/v0?eventType=PO_ACCEPTANCE',
    apiErrorName: 'PO Acceptance Upload Status',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  gacMassUpload: {
    endPoint: 'dpom_poupdate/bulkupdate/v0',
    apiErrorName: 'File upload',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  poRejectionEvents: {
    endPoint: 'dpom_poupdate/eventstatus/v0?eventType=PO_REJECTION',
    apiErrorName: 'PO Rejection Upload Status',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  createSavedSearch: {
    endPoint: 'dpom_savesearch/createsearch/v0',
    apiErrorName: 'Saved Search create request',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  updateUserProfile: {
    endPoint: 'dpom_userprofile/profile/v0?type=update',
    apiErrorName: 'Settings update',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  CREATEUSERPROFILE: {
    endPoint: 'dpom_userprofile/profile/v0',
    apiErrorName: 'User profile create request',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  dropdownLookup: {
    endPoint: 'dpom_purchaseorder/partnerinfo/v0',
    apiErrorName: 'Search values',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  METADATA: {
    endPoint: 'dpom_purchaseorder/metadata/v0?fields=countryList,poItemStatusByUser',
    apiErrorName: 'Sire Search Values',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  pmoOfficeData: {
    endPoint: 'dpom_purchaseorder/metadata/v0?fields=pmoVendorList',
    apiErrorName: 'Sire Search Values',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  GacInAppUpdate: {
    endPoint: 'dpom_purchaseorder/pohierarchysearch/v0',
    apiErrorName: 'Distinct PO Number',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  DASHBOARD: {
    endPoint: 'dpom_dashboard/dashboard/v0',
    apiErrorName: 'Dashboard content',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  POAPPROVEORREJECT: {
    endPoint: 'dpom_collaborationreport/updatestatus/v0',
    apiErrorName: 'Update',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  POPDF: {
    endPoint: 'dpom_popdf/export/v0',
    apiErrorName: 'PO PDF Download',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  getUserProfile: {
    endPoint: 'dpom_userprofile/profile/v0?&fields=userID,emailID,emailNotificationPreferenceForConvo,metricsMessagePopUpPreference,dateFormatPreference,dashboardNotificationPreference,id,rowsPerPagePreference',
    apiErrorName: 'User settings',
    mockType: false,
    retry: true,
    type: 'Type1'
  },

  deleteSavedSearch: {
    endPoint: 'dpom_savesearch/delete/v0/{{id}}?type={{type}}',
    apiErrorName: 'Saved Search Delete Request',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  updateSavedSearch: {
    endPoint: 'dpom_savesearch/updatesearch/v0/{{id}}',
    apiErrorName: 'Saved Search Update Request',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  orderSavedSearch: {
    endPoint: 'dpom_savesearch/ordersearch/v0',
    apiErrorName: 'Saved Search Order Request',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  getAttachmentFile: {
    endPoint: 'dpom_conversation/attachment/v0/{{location}}',
    apiErrorName: 'File download',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  getSavedSearch: {
    endPoint: 'dpom_savesearch/viewsearch/v0?fields=id,name,savedDate,isFavourite,type,searchCriteria,saveToUserEmail,filter&sort=savedDateDesc&type={{type}}',
    apiErrorName: 'Saved Search',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  updateConversationData: {
    endPoint: 'dpom_conversation/conversation/v0/{{id}}',
    apiErrorName: 'Conversation search',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  getConversationById: {
    endPoint: 'dpom_conversation/conversation/v0/{{id}}',
    apiErrorName: 'Conversation search',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  queryConversationThreads: {
    endPoint: 'dpom_conversation/conversation/v0/{{query}}',
    apiErrorName: 'Conversation search',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  FXCALCULATION: {
    endPoint: `${process.env[fxCalculation] || ''} `,
    apiErrorName: 'Fx calculation',
    mockType: false,
    retry: true,
    type: 'Type1',
    appName: 'FXCALCULATION'
  },
  ANNOUNCEMENT: {
    endPoint: `${process.env[announcement] || ''} `,
    apiErrorName: 'Dashboard announcements',
    mockType: false,
    retry: true,
    type: 'Type1',
    appName: 'ANNOUNCEMENT'
  },
  EXTERNALLINK: {
    endPoint: `${process.env[externalLink] || ''} `,
    apiErrorName: 'Useful Links',
    mockType: false,
    retry: true,
    type: 'Type1',
    appName: 'EXTERNAL_LINK'
  },
  deleteAttachment: {
    endPoint: 'dpom_conversation/attachment/v0/{{fileLocation}}',
    apiErrorName: 'Delete attachment',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  ADUSERDETAILSSECURITYAPI: {
    endPoint: 'dpom_security/userprofile/v0/{{emailId}}',
    apiErrorName: 'Delete attachment',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  lineSummaryReportSearch: {
    endPoint: 'dpom_lineitemreport/lineitemsearch/v0',
    apiErrorName: 'Line Item Search',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  lineSummaryReportExport: {
    endPoint: 'dpom_lineitemreport/lineitemexport/v0',
    apiErrorName: 'Line Item Export',
    mock: false,
    retry: true,
    type: 'Type2'
  },
  lineSummaryReportExportLargeFile: {
    endPoint: 'dpom_report/lineitemexport/v0',
    apiErrorName: 'Line Item Export',
    mock: false,
    retry: true,
    type: 'Type2'
  },
  pmoContacts: {
    endPoint: 'dpom_config/pmocontact/v0',
    apiErrorName: 'PMO Contacts',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  getFeatureFlag: {
    endPoint: 'dpom_config/featureflag/v0{{query}}',
    apiErrorName: 'Get Feature Flag',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  featureFlag: {
    endPoint: 'dpom_config/featureflag/v0',
    apiErrorName: 'Feature Flag',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  getGeoEmailGroup: {
    endPoint: 'dpom_config/geoGroupEmail/v0/{{query}}',
    apiErrorName: 'get GEO Email Group',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  geoEmailGroup: {
    endPoint: 'dpom_config/geoGroupEmail/v0',
    apiErrorName: 'GEO Email Group',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  metricsChartData: {
    endPoint: 'dpom_metrics/search/v0',
    apiErrorName: 'Chart Data',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  metricsExport: {
    endPoint: 'dpom_metrics/export/v0',
    apiErrorName: 'Chart Export',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  metricsExportLargeFile: {
    endPoint: 'dpom_report/metricsexport/v0',
    apiErrorName: 'Chart Export',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  airFreightReportSearch: {
    endPoint: 'dpom_lineitemreport/lineitemsearch/v0',
    apiErrorName: 'Air Freight Search',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  airFreightReportExport: {
    endPoint: 'dpom_airfreightreport/airfreightexport/v0',
    apiErrorName: 'Air Freight Export',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  airFreightReportExportLargeFile: {
    endPoint: 'dpom_report/lineitemexport/v0',
    apiErrorName: 'Air Freight Export',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  airFreightSubtable: {
    endPoint: 'dpom_airFreight/airFreightReport/v0',
    apiErrorName: 'Air Freight Subtable',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  airFreightApprovalHistoryTable: {
    endPoint: 'dpom_airfreightOverride/getAFOverrideStatusById/v0?{{query}}',
    apiErrorName: 'Air Freight History Table',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  airFreightCreateOverrideRequest: {
    endPoint: 'dpom_airfreightOverride/createAFOverride/v0',
    apiErrorName: 'Air Freight Create Override',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  uploadFileForOverride: {
    endPoint: 'dpom_airfreightOverride/afoverrideattachment/v0',
    apiErrorName: 'Override Attachment',
    mock: false,
    retry: true,
    type: 'Type2'
  },
  getOverrideAttachmentFile: {
    endPoint: 'dpom_airfreightOverride/afoverrideattachment/v0/{{location}}',
    apiErrorName: 'File Override download',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  deleteOverrideAttachment: {
    endPoint: 'dpom_airfreightOverride/afoverrideattachment/v0/{{fileLocation}}',
    apiErrorName: 'Delete Override attachment',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  getAirFreightOverride: {
    endPoint: 'dpom_airfreightOverride/getAFOverrideStatus/v0?assignedPMOUserID={{id}}&status={{status}}',
    apiErrorName: 'Get Air Freight Override',
    mock: false,
    retry: true,
    type: 'Type2'
  },
  getAirFreightOverrideAdjustment: {
    endPoint: 'dpom_airfreightOverride/afoverridesearch/v0/{{query}}',
    apiErrorName: 'Get Air Freight Override Adjustment',
    mock: true,
    retry: true,
    type: 'Type2'
  },
  gacChangeHistorySearch: {
    endPoint: 'dpom_lineitemreport/lineitemsearch/v0',
    apiErrorName: 'GAC Change History Search',
    mock: true,
    retry: true,
    type: 'Type1'
  },
  gacChangeHistoryReportExport: {
    endPoint: 'dpom_gacChangeHistoryReport/gacChangeHistoryexport/v0',
    apiErrorName: 'GAC Change History Export',
    mock: true,
    retry: true,
    type: 'Type1'
  },
  gacChangeHistoryReportExportLargeFile: {
    endPoint: 'dpom_report/lineitemexport/v0',
    apiErrorName: 'GAC Change History Export',
    mock: true,
    retry: true,
    type: 'Type1'
  },
  gacChangeHistorySubtable: {
    endPoint: 'dpom_lineitemreport/lineitemsearch/v0',
    apiErrorName: 'GAC Change History Subtable',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  airFreightOverrideSubtable: {
    endPoint: 'dpom_airfreightOverride/getAFOverrideStatus/v0',
    apiErrorName: 'Get Air Freight Override Subtable',
    mock: true,
    retry: true,
    type: 'Type2'
  },
  getUserProfileData: {
    endPoint: 'dpom_userprofile/profile/v0?fields={{fields}}',
    apiErrorName: 'Get User Profile',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  getGACReasonCode: {
    endPoint: 'dpom_config/gacreasoncode/v0?fields=reasonCode,reasonCodeDescription,id,modifiedBy,modifiedDate,isActive',
    apiErrorName: 'GAC Reason codes',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  addGacReasonCodes: {
    endPoint: 'dpom_config/gacreasoncode/v0',
    apiErrorName: 'GAC Reason codes',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  updateGacReasonCodes: {
    endPoint: 'dpom_config/gacreasoncode/v0',
    apiErrorName: 'GAC Reason codes',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  getSchedular: {
    endPoint: 'dpom_report/getlargefileschedulerstatus/v0',
    apiErrorName: 'GET Saved Schedular request',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  createSchedular: {
    endPoint: 'dpom_report/largefiledownloadschedule/v0',
    apiErrorName: 'Saved Schedular create request',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  updateSchedular: {
    endPoint: 'dpom_report/largefiledownloadschedule/v0',
    apiErrorName: 'Saved Schedular update request',
    mockType: false,
    retry: true,
    type: 'Type2'
  },
  updatePendingApproval: {
    endPoint: 'dpom_airfreightOverride/updateAFOverride/v0',
    apiErrorName: 'Pending Approval',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  updateTelemetry: {
    endPoint: 'dpom_report/updatelargefileeventstatus/v0?{{query}}',
    apiErrorName: 'Large File Download Telemetry',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  getReasonCodeUsecases: {
    endPoint: 'dpom_config/gacusecasecode/v0',
    apiErrorName: 'Get  reason code usecase',
    mockType: false,
    retry: true,
    type: 'Type1'
  },
  AddGacUsecaseContacts: {
    endPoint: 'dpom_config/gacusecasecode/v0',
    apiErrorName: 'Add Use Case Contacts',
    mock: false,
    retry: true,
    type: 'Type1'
  },
  updateGacUsecaseContacts: {
    endPoint: 'dpom_config/gacusecasecode/v0',
    apiErrorName: 'Update Use Case Contacts',
    mock: false,
    retry: true,
    type: 'Type1'
  }
};
