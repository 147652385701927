/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import getflattenResponse from '../../helpers/common';

export const name = 'fileUD';

function createInitialState() {
  return {
    uploadEvents: {},
    uploadFailure: {},
    downloadEvents: {},
    downloadFailure: {},
    loadingSpinner: true,
    clickedError: false,
    uploadingSpinner: false
  };
}

export const initialState = createInitialState();

function createReducers() {
  // UPLOAD_DATA_PROCESSING
  function toggleUploadProcessingSpinner(state: Object) {
    return {
      ...state,
      loadingSpinner: true
    };
  }

  // DOWNLOAD_DATA_PROCESSING
  function toggleDownloadProcessingSpinner(state: Object) {
    return {
      ...state,
      loadingSpinner: true
    };
  }

  // UPLOAD_DATA_EVENTS
  function getUploadDataEvents(state: Object, action: Object) {
    const payload = {
      data: action.payload.data
    };
    return {
      ...state,
      uploadEvents: payload.data.objects,
      loadingSpinner: false
    };
  }

  // CLEAR_UPLOAD_EVENT_DATA_ON_UNMOUNT
  function clearUploadEventsDataOnUnmount(state: Object) {
    return {
      ...state,
      uploadEvents: initialState.uploadEvents
    };
  }

  // RESET_UPLOAD_DATA
  function resetUploadData() {
    return {
      ...initialState
    };
  }

  // UPLOAD_DATA_FAILURE
  function setRequestFailure(state: Object, action: Object) {
    return {
      ...state,
      uploadFailure: action.payload,
      loadingSpinner: false
    };
  }

  // DOWNLOAD_DATA_EVENTS
  function getDownloadDataEvents(state: Object, action: Object) {
    const payload = {
      data: action.payload.data
    };
    return {
      ...state,
      downloadEvents: payload.data.objects,
      loadingSpinner: false
    };
  }

  // CLEAR_DOWNLOAD_EVENT_DATA_ON_UNMOUNT
  function clearDownloadEventsDataOnUnmount(state: Object) {
    return {
      ...state,
      downloadEvents: initialState.downloadEvents
    };
  }

  // fileUpload action
  // FILE_UPLOADED_SUCCESS_EVENTS
  function fileUploadSucessSpinner(state: Object) {
    return {
      ...state,
      uploadingSpinner: false
    };
  }

  // fileUpload action
  // FILE_UPLOAD_EVENTS
  function fileUploadProcessingSpinner(state: Object) {
    return {
      ...state,
      uploadingSpinner: true
    };
  }
  function gacUpdateDivisionCode(state: Object, action: Object) {
    return {
      ...state,
      divisionCode: action.payload
    };
  }

  return {
    toggleUploadProcessingSpinner,
    toggleDownloadProcessingSpinner,
    getUploadDataEvents,
    clearUploadEventsDataOnUnmount,
    resetUploadData,
    setRequestFailure,
    getDownloadDataEvents,
    clearDownloadEventsDataOnUnmount,
    fileUploadSucessSpinner,
    fileUploadProcessingSpinner,
    gacUpdateDivisionCode
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function resetBulkUpload() {
    return createAsyncThunk(
      `${name}/resetBulkUpload`,
      (currentPage, { getState, dispatch }) => {
        const state = getState();
        if (state.fileUD && currentPage !== 'GACUPLOAD') {
          dispatch(actions.resetUploadData());
        }
      }
    );
  }

  function updateTelemetry() {
    return createAsyncThunk(
      `${name}/updateTelemetry`,
      async ({
        eventId,
        callback
      }, { dispatch }) => {
        const query = `eventID=${eventId}`;
        const updateRequestBody = {
          message: 'Report has been viewed by the user'
        };
        dispatch(toggleSpinner(true));
        request({
          api: 'updateTelemetry',
          method: 'put',
          routeParams: { query },
          data: updateRequestBody
        })
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(response.data);
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function fetchDownloadEvents() {
    return createAsyncThunk(
      `${name}/fetchDownloadEvents`,
      async ({
        eventType
      }, { getState, dispatch }) => {
        const state = getState();
        const userID = state.auth && state.auth.parsedToken.idToken.claims.legacy_username;
        const query = userID ? `userID=${userID.toLowerCase()}` : '';
        if (eventType !== null && eventType !== undefined) {
          const endPointCodes = ['poPdfEvents', 'sireDeliveryEvents', 'reportEvents'];
          const endPointCode = endPointCodes[eventType || 0] || endPointCodes[0];

          dispatch(actions.clearDownloadEventsDataOnUnmount());
          dispatch(actions.toggleDownloadProcessingSpinner());
          request({
            api: endPointCode,
            routeParams: { query },
            method: 'get'
          })
            .then((response) => {
              dispatch(actions.getDownloadDataEvents(response));
            })
            .catch((error) => {
              dispatch(actions.setRequestFailure(error));
            });
        }
      }
    );
  }

  function fetchUploadEvents() {
    return createAsyncThunk(
      `${name}/fetchUploadEvents`,
      async ({
        eventType
      }, { dispatch }) => {
        if (eventType !== null && eventType !== undefined) {
          const endPointCodes = ['gacUploadEvents', 'wipEvents', 'acceptanceEvents', 'poRejectionEvents'];
          const endPointCode = endPointCodes[eventType || 0] || endPointCodes[0];
          dispatch(actions.clearUploadEventsDataOnUnmount());
          dispatch(actions.toggleUploadProcessingSpinner());
          request({
            api: endPointCode,
            method: 'get'
          })
            .then((response) => {
              dispatch(actions.getUploadDataEvents(response));
            })
            .catch((error) => {
              dispatch(actions.setRequestFailure(error));
            });
        }
      }
    );
  }
  function getDivisionCode(data) {
    const { objects } = data;
    const firstRow = objects?.[0];
    return firstRow['poLine.divisionCode'] || null;
  }
  function requestGacUpdate() {
    return createAsyncThunk(
      `${name}/requestGacUpdate`,
      async ({
        ids,
        selectedRecordsCount,
        callback,
        type = 'includes'
      }, { getState, dispatch }) => {
        const state = getState();
        console.log('selectedRecordsCount', selectedRecordsCount);
        const searchRequestBody = { ...state.PoSearch.body || {} };
        const requestBody = {
          search: [...(searchRequestBody.search || []), {
            fieldName: 'poLine.dpomItemStatus',
            operator: '!=',
            fieldValue: ['Closed', 'Cancelled']
          }],
          fields: [
            'poHeader.poDocTypeCode',
            'poHeader.poDocTypeDescription',
            'poLine.productCode',
            'poLine.goodsAtConsolidatorDate',
            'sizes.sizePo.goodsAtConsolidatorDate',
            'sizes.sizePo.sizeDescription',
            'poLine.goodsAtConsolidatorReasonCode',
            'poLine.goodsAtConsolidatorReasonDescription',
            'sizes.sizePo.goodsAtConsolidatorReasonCode',
            'sizes.sizePo.goodsAtConsolidatorReasonDescription',
            'planning.mrgacDate',
            'poLine.divisionCode',
            'id',
            'sizes.sizeId',
            'poHeader.poNumber',
            'poLine.itemNumber',
            'sizes.scheduleLineItemNumber'
          ],
          filter: [...(searchRequestBody.filter) || []]
        };

        if (ids.length > 0) {
          requestBody.search.push({
            fieldName: 'sizes.sizeId',
            operator: type === 'includes' ? '=' : '!=',
            fieldValue: ids
          });
        }
        dispatch(toggleSpinner(true));
        request({
          api: 'GacInAppUpdate',
          method: 'post',
          data: requestBody
          // cancellable: true // not working fine here
        })
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(getflattenResponse(response.data));
            const divisionCode = getDivisionCode(getflattenResponse(response.data));
            dispatch(actions.gacUpdateDivisionCode(divisionCode));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  // fileUpload action
  function updateFileUpload() {
    return createAsyncThunk(
      `${name}/updateFileUpload`,
      async ({
        uploadedFileData,
        callback
      }, { getState, dispatch }) => {
        dispatch(actions.fileUploadProcessingSpinner());
        const state = getState();
        const loggedInUserName = state.auth.userDetails.name;
        request({
          api: 'gacMassUpload',
          method: 'post',
          data: uploadedFileData,
          headers: { name: loggedInUserName }
        })
          .then((response) => {
            dispatch(actions.fileUploadSucessSpinner());
            callback(getflattenResponse(response.data));
          })
          .catch((error) => {
            dispatch(actions.fileUploadSucessSpinner());
            callback(null, error);
          });
      }
    );
  }

  return {
    resetBulkUpload: resetBulkUpload(), // resetRedux
    updateTelemetry: updateTelemetry(),
    fetchDownloadEvents: fetchDownloadEvents(),
    fetchUploadEvents: fetchUploadEvents(),
    requestGacUpdate: requestGacUpdate(), // posearch
    updateFileUpload: updateFileUpload() // fileUpload Action
  };
}

export const extraActions = createExtraActions();

export const fileUDActions = { ...actions, ...extraActions };
export const fileUDReducer = slice.reducer;
