import poRejectionEventTableFieldMapping from '../fieldMappings/poRejectionEventTableFieldMapping';

export default {
  [poRejectionEventTableFieldMapping.PO_REJECT_FILENAME]: {
    primary: poRejectionEventTableFieldMapping.PO_REJECT_FILENAME, secondary: '', type: 'string', link: false, label: 'File Name', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [poRejectionEventTableFieldMapping.PO_REJECT_EVENTCREATETS]: {
    primary: poRejectionEventTableFieldMapping.PO_REJECT_EVENTCREATETS, secondary: '', type: 'datetime', link: false, label: 'Request Submitted', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [poRejectionEventTableFieldMapping.PO_REJECT_EVENTSTATUS]: {
    primary: poRejectionEventTableFieldMapping.PO_REJECT_EVENTSTATUS, secondary: '', type: 'string', link: false, label: 'Status', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [poRejectionEventTableFieldMapping.PO_REJECT_RECEIVEDITEMS]: {
    primary: poRejectionEventTableFieldMapping.PO_REJECT_RECEIVEDITEMS, secondary: '', type: 'numeric', link: false, label: 'Received Items', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [poRejectionEventTableFieldMapping.PO_REJECT_ERRORSFOUNDCOUNT]: {
    primary: poRejectionEventTableFieldMapping.PO_REJECT_ERRORSFOUNDCOUNT, secondary: '', type: 'numeric', link: false, label: 'Errors Found', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [poRejectionEventTableFieldMapping.PO_REJECT_EVENTERRORLIST]: {
    primary: poRejectionEventTableFieldMapping.PO_REJECT_EVENTERRORLIST, secondary: '', type: 'string', link: false, label: 'Action', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
