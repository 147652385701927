/**
 * This file is used for storing the Images and its path.
 * @exports imagesPath
 * @author {Cognizant Technology Solution}
 */
import nikeLogo from './images/logo.svg';
import question from './images/Question.png';
import hamburger from './images/icon_hamburger.png';
import conversation from './images/icon_conversation.svg';
import dashboard from './images/icon_dashboard.svg';
import reporting from './images/icon_reporting.svg';
import search from './images/icon_search.svg';
import upload from './images/icon_upload.svg';
import cancel from './images/icon_cancel.png';
import errorPage from './images/errorPage.png';
import exportAll from './images/exportAll.png';
import spinner from './images/spinner.svg';
import dataReceived from './images/icon_dataReceived.svg';
import nikeShoeBox from './images/icon_nikeShoeBox.svg';
import iconBookmark from './images/icon_bookmark.png';
import iconSettings from './images/icon_settings.png';
import iconSire from './images/icon_sire.png';
import Logo from './images/nike_logo.png';
import error from './images/error.png';
import warningGacShoe from './images/warningGacShoe.png';
import gacSettings from './images/icon_gacSettings.png';
import iconXls from './images/icon_xls_file.svg';
import iconPng from './images/icon_file_png.svg';
import iconTxt from './images/icon_file_txt.svg';
import noError from './images/NoError.png';
import processing from './images/Processing.png';
import queued from './images/Queued.png';
import gacError from './images/gacError.png';
import viewError from './images/ViewError.png';
import fileIcon from './images/icon_file.png';
import close from './images/close.png';
import conversationIcon from './images/conversation_icon.svg';
import dpomLogo from './images/dpom_logo.svg';
import nikeBackground from './images/nike_background2.png';
import banner1 from './images/banner1.jpg';
import banner2 from './images/banner2.jpg';
import banner5 from './images/banner5.jpg';
import banner3 from './images/banner3.jpg';
import banner4 from './images/banner4.jpg';
import banner6 from './images/banner6.jpg';
import banner7 from './images/banner7.jpg';
import banner001 from './images/banner001.jpg';
import banner002 from './images/banner002.jpg';
import banner003 from './images/banner003.jpg';
import banner004 from './images/banner004.jpg';
import banner005 from './images/banner005.jpg';
import conversationThread from './images/conversationThread.png';
import PendingApproval from './images/PendingApproval.png';
import GAC from './images/GAC.png';
import GACUpdate from './images/GACUpdate.png';
import SearchSmall from './images/search_small.png';
import Rejected from './images/rejected.png';
import warningSign from './images/warning_sign.svg';
import ExternalLink from './images/external_Link.png';
import DPOMLOGO from './images/DPOMLOGO.png';
import Layer1 from './images/layer1.png';
import timeout from './images/timeout.svg';
import MySettings from './images/MySettings.svg';
import geoSettings from './images/geoSettings.svg';
import factoryContact from './images/factoryContact.svg';
import wrapTextIcon from './images/textWrap.svg';

const imagesPath = {
  timeout: {
    src: timeout,
    alt: 'session Timeout'
  },
  MySettings: {
    src: MySettings,
    alt: 'My Settings'
  },
  geoSettings: {
    src: geoSettings,
    alt: 'Geo'
  },
  factoryContact: {
    src: factoryContact,
    alt: 'Factory'
  },
  nikeLogo: {
    src: nikeLogo,
    alt: 'Nike Logo'
  },
  dpomLogo: {
    src: dpomLogo,
    alt: 'Nike Logo'
  },
  poSearchBackground: {
    src: nikeBackground,
    alt: 'PO Search'
  },
  iconHamburger: {
    src: hamburger,
    alt: 'Menu'
  },
  close: {
    src: close,
    alt: 'Close Logo'
  },
  question: {
    src: question,
    alt: 'Question Logo'
  },
  iconConversation: {
    src: conversation,
    alt: 'Conversation'
  },
  iconDashboard: {
    src: dashboard,
    alt: 'Dashboard'
  },
  iconReporting: {
    src: reporting,
    alt: 'Reporting'
  },
  iconSearch: {
    src: search,
    alt: 'POSearch'
  },
  iconUpload: {
    src: upload,
    alt: 'Upload'
  },
  iconCancel: {
    src: cancel,
    alt: 'Cancel'
  },
  iconExportAll: {
    src: exportAll,
    alt: 'Export'
  },
  imgErrorPage: {
    src: errorPage,
    alt: 'ErrorImage'
  },
  imgAvatar: {
    src: '/static/images/avatar/1.jpg',
    alt: 'AvatarImage'
  },
  spinner: {
    src: spinner,
    alt: 'spinner'
  },
  dataReceived: {
    src: dataReceived,
    alt: 'dataReceived'
  },
  nikeShoeBox: {
    src: nikeShoeBox,
    alt: 'Limit Reached'
  },
  Logo: {
    src: Logo,
    alt: 'Logo'
  },
  error: {
    src: error,
    alt: 'ErrorImage'
  },
  iconBookmark: {
    src: iconBookmark,
    alt: 'iconBookmark'
  },
  iconSettings: {
    src: iconSettings,
    alt: 'iconSettings'
  },
  iconSire: {
    src: iconSire,
    alt: 'iconSire'
  },
  warningGacShoe: {
    src: warningGacShoe,
    alt: 'warningGacShoe'
  },
  gacSettings: {
    src: gacSettings,
    alt: 'gacSettings'
  },
  iconXls: {
    src: iconXls,
    alt: '.xls format'
  },
  iconPng: {
    src: iconPng,
    alt: 'image'
  },
  iconTxt: {
    src: iconTxt,
    alt: 'text format'
  },
  noError: {
    src: noError,
    alt: 'no error'
  },
  processing: {
    src: processing,
    alt: 'processing'
  },
  queued: {
    src: queued,
    alt: 'queued'
  },
  gacError: {
    src: gacError,
    alt: 'GAC Error'
  },
  viewError: {
    src: viewError,
    alt: 'view Error'
  },
  fileIcon: {
    src: fileIcon,
    alt: 'fileIcon Logo'
  },
  conversationIcon: {
    src: conversationIcon,
    alt: 'Conversations'
  },
  banner001: {
    src: banner001,
    alt: 'banner001'
  },
  banner002: {
    src: banner002,
    alt: 'banner002'
  },
  banner003: {
    src: banner003,
    alt: 'banner003'
  },
  banner004: {
    src: banner004,
    alt: 'banner004'
  },
  banner005: {
    src: banner005,
    alt: 'banner005'
  },
  banner1: {
    src: banner1,
    alt: 'banner1'
  },
  banner2: {
    src: banner2,
    alt: 'banner2'
  },
  banner3: {
    src: banner3,
    alt: 'banner3'
  },
  banner4: {
    src: banner4,
    alt: 'banner4'
  },
  banner5: {
    src: banner5,
    alt: 'banner5'
  },
  banner6: {
    src: banner6,
    alt: 'banner6'
  },
  banner7: {
    src: banner7,
    alt: 'banner7'
  },
  conversationThread: {
    src: conversationThread,
    alt: 'conversationThread'
  },
  PendingApproval: {
    src: PendingApproval,
    alt: 'PendingApproval'
  },
  GAC: {
    src: GAC,
    alt: 'GAC'
  },
  GACUpdate: {
    src: GACUpdate,
    alt: 'GACUpdate'
  },
  SearchSmall: {
    src: SearchSmall,
    alt: 'SearchSmall'
  },
  Rejected: {
    src: Rejected,
    alt: 'Rejected'
  },
  warningSign: {
    src: warningSign,
    alt: 'warning'
  },
  ExternalLink: {
    src: ExternalLink,
    alt: 'ExternalLink'
  },
  DPOMLOGO: {
    src: DPOMLOGO,
    alt: 'DPOMLOGO',
    width: 320,
    height: 92
  },
  Layer1: {
    src: Layer1,
    alt: 'Layer1'
  },
  wrapTextIcon: {
    src: wrapTextIcon,
    alt: 'Airfreight Status'
  }
};

export default imagesPath;
