import USERGROUP, { ALLOWED_USER_GROUP } from '../constants/userGroup';
import { showForAF, showForFeatureFlag } from './envServer';

export const userAccess = {
  Dashboard: {
    primaryDashboard: {
      allowedUser: ALLOWED_USER_GROUP
    },
    operationalmetrics: {
      allowedUser: ALLOWED_USER_GROUP
    },
    dashboard: {
      allowedUser: ALLOWED_USER_GROUP
    }
  },
  PoSearch: {
    allowedUser: ALLOWED_USER_GROUP,
    allowedSearchAction: {
      poAcceptance: {
        allowedUser: [
          USERGROUP.PMO_SECURITY,
          USERGROUP.PMO,
          USERGROUP.INFOTECH_SECURITY,
          USERGROUP.FACTORY
        ],
        showForStatus: [
          'Unissued',
          'Unaccepted',
          'Rejected'
        ]
      },
      gacInApp: {
        allowedUser: [
          USERGROUP.PMO_SECURITY,
          USERGROUP.PMO,
          USERGROUP.INFOTECH_SECURITY,
          USERGROUP.FACTORY
        ],
        showForStatus: [
          'Unissued',
          'Accepted',
          'Unaccepted',
          'Rejected'
        ]
      },
      WIP: {
        allowedUser: [
          USERGROUP.FACTORY
        ]
      },
      legalPoPdf: {
        allowedUser: ALLOWED_USER_GROUP,
        showForStatus: [
          'Accepted',
          'Closed'
        ]
      },
      fxAdjustment: {
        allowedUser: [
          USERGROUP.PMO_SECURITY,
          USERGROUP.PMO,
          USERGROUP.FACTORY
        ],
        showForStatus: [
          'Accepted'
        ]
      },
      issuePO: {
        allowedUser: [
          USERGROUP.WHQ
        ]
      }
    },
    restrictedSearchCriteriaUser: {
      DPOMLineItemStatus: {
        allowedUser: [
          USERGROUP.PMO_SECURITY,
          USERGROUP.PMO,
          USERGROUP.INFOTECH_SECURITY,
          USERGROUP.INFOTECH,
          USERGROUP.WHQ,
          USERGROUP.BPM_SECURITY,
          USERGROUP.GEONA_SECURITY,
          USERGROUP.GEOGC_SECURITY,
          USERGROUP.GEOEMEA_SECURITY,
          USERGROUP.GEOAPLA_SECURITY,
          USERGROUP.GEONA,
          USERGROUP.GEOGC,
          USERGROUP.GEOEMEA,
          USERGROUP.GEOAPLA
        ]
      }
    }
  },
  Reports: {
    ppmreport: {
      allowedUser: ALLOWED_USER_GROUP
    },
    poreport: {
      allowedUser: ALLOWED_USER_GROUP
    },
    pochangereport: {
      allowedUser: ALLOWED_USER_GROUP
    },
    lineitemreport: {
      allowedUser: ALLOWED_USER_GROUP
    },
    conversationreport: {
      allowedUser: ALLOWED_USER_GROUP
    },
    airfreightreport: {
      allowedUser: ALLOWED_USER_GROUP,
      overideEligible: {
        allowedUser: [
          USERGROUP.FACTORY
        ]
      },
      overideWorkflow: {
        allowedUser: [
          USERGROUP.PMO,
          USERGROUP.PMO_SECURITY
        ]
      }
    },
    gacchangehistoryreport: {
      allowedUser: ALLOWED_USER_GROUP
    },
    todos: {
      allowedUser: ALLOWED_USER_GROUP
    }
  },
  Conversations: {
    allowedUser: ALLOWED_USER_GROUP,
    stakeholderDelete: {
      allowedUser: [
        USERGROUP.PMO_SECURITY,
        USERGROUP.PMO,
        USERGROUP.INFOTECH_SECURITY,
        USERGROUP.INFOTECH,
        USERGROUP.WHQ,
        USERGROUP.BPM_SECURITY,
        USERGROUP.GEONA_SECURITY,
        USERGROUP.GEOGC_SECURITY,
        USERGROUP.GEOEMEA_SECURITY,
        USERGROUP.GEOAPLA_SECURITY,
        USERGROUP.GEONA,
        USERGROUP.GEOGC,
        USERGROUP.GEOEMEA,
        USERGROUP.GEOAPLA
      ]
    }
  },
  Upload: {
    uploadPOAcceptance: {
      allowedUser: [
        USERGROUP.INFOTECH_SECURITY,
        USERGROUP.PMO_SECURITY,
        USERGROUP.PMO,
        USERGROUP.FACTORY
      ]
    },
    uploadGac: {
      allowedUser: [
        USERGROUP.INFOTECH_SECURITY,
        USERGROUP.PMO_SECURITY,
        USERGROUP.PMO,
        USERGROUP.FACTORY
      ]
    },
    uploadWIP: {
      allowedUser: [
        USERGROUP.FACTORY
      ]
    },
    uploadPORejection: {
      allowedUser: [
        USERGROUP.INFOTECH_SECURITY,
        USERGROUP.PMO_SECURITY,
        USERGROUP.PMO,
        USERGROUP.FACTORY
      ]
    }
  },
  Download: {
    downloadPOPdf: {
      allowedUser: ALLOWED_USER_GROUP
    },
    downloadSireInstruction: {
      allowedUser: ALLOWED_USER_GROUP
    },
    downloadReports: {
      allowedUser: ALLOWED_USER_GROUP
    }
  },
  SireDelivery: {
    allowedUser: ALLOWED_USER_GROUP
  },
  SavedSearches: {
    allowedUser: ALLOWED_USER_GROUP
  },
  AFOverride: {
    AFApproval: {
      allowedUser: [
        USERGROUP.PMO,
        USERGROUP.PMO_SECURITY,
        USERGROUP.WHQ,
        USERGROUP.GEONA_SECURITY,
        USERGROUP.GEOGC_SECURITY,
        USERGROUP.GEOEMEA_SECURITY,
        USERGROUP.GEOAPLA_SECURITY,
        USERGROUP.GEONA,
        USERGROUP.GEOGC,
        USERGROUP.GEOEMEA,
        USERGROUP.GEOAPLA
      ]
    },
    AFOverrideAdjustment: {
      allowedUser: [
        USERGROUP.PMO_SECURITY
      ]
    }
  },
  AFAdjustments: {
    allowedUser: showForAF ? [
      USERGROUP.PMO,
      USERGROUP.PMO_SECURITY
    ] : []
  },
  Settings: {
    allowedUser: ALLOWED_USER_GROUP,
    pmodec: {
      allowedUser: [
        USERGROUP.PMO_SECURITY
      ]
    },
    geoglobal: {
      allowedUser: [
        USERGROUP.GEONA_SECURITY,
        USERGROUP.GEOGC_SECURITY,
        USERGROUP.GEOEMEA_SECURITY,
        USERGROUP.GEOAPLA_SECURITY
      ]
    },
    geoEmailGroup: {
      allowedUser: [
        USERGROUP.GEONA_SECURITY,
        USERGROUP.GEOGC_SECURITY,
        USERGROUP.GEOEMEA_SECURITY,
        USERGROUP.GEOAPLA_SECURITY
      ]
    },
    conversationcategorycodes: {
      allowedUser: [
        USERGROUP.PMO_SECURITY
      ]
    },
    gacreasoncodes: {
      // for testing PMO_SECURITY added
      allowedUser: [
        USERGROUP.BPM_SECURITY,
        USERGROUP.PMO_SECURITY
      ]
    },
    gacreasonusecases: {
      allowedUser: [
        USERGROUP.PMO_SECURITY,
        USERGROUP.INFOTECH_SECURITY
      ]
    },
    featureFlag: {
      allowedUser: showForFeatureFlag ? [
        USERGROUP.INFOTECH_SECURITY,
        USERGROUP.INFOTECH
      ] : []
    }
  },
  ExternalLink: {
    allowedUser: ALLOWED_USER_GROUP
  }
};
function findNested(obj, key) {
  if (obj[key]) {
    return obj[key];
  }
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    const k = keys[i];
    if (obj[k] && typeof obj[k] === 'object') {
      const found = findNested(obj[k], key);
      if (found) {
        return found;
      }
    }
  }
  return null;
}
// Check if the user group is allowed
export const checkUserPermission = (permission, userGroup, dpomLineItemStatus = '') => {
  const allowedArr = findNested(userAccess, permission);
  const allowedUserIndex = Object.keys(allowedArr).indexOf('allowedUser');

  if (allowedUserIndex > -1) {
    let allowedGrp = allowedArr.allowedUser.indexOf(userGroup) > -1;
    if (Array.isArray(userGroup)) {
      allowedGrp = USERGROUP.filter((e) => allowedArr.allowedUser.indexOf(e) !== -1).length > 0;
    }

    if (dpomLineItemStatus) {
      return (
        allowedGrp
        && allowedArr.showForStatus.indexOf(dpomLineItemStatus) > -1
      );
    }
    return allowedGrp;
  }
  return null;
};
