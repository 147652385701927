/* @flow */
import gacChangeHistoryReportHeader from '../../constants/gacChangeHistoryReportHeader';

export default function getFieldsList(GACChangeHistorySecondaryFields: Object) {
  gacChangeHistoryReportHeader
    .filter((header) => header.default)
    .forEach((header) => {
      GACChangeHistorySecondaryFields.push(header.primary);
      if (header.secondary) {
        GACChangeHistorySecondaryFields.push(header.secondary);
      }
      if (header.additionalFields) {
        GACChangeHistorySecondaryFields.push(...header.additionalFields);
      }
      if (header.includeInRequest) {
        GACChangeHistorySecondaryFields.push(header.primary);
      }
    });
}
