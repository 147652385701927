import { pricingNonProd } from '../../helpers/envServer';
import ppmReportFieldMapping from '../fieldMappings/ppmReportFieldMapping';

const lblPurchaseOrderNumber = 'Purchase Order Number';
const lblSizeDescription = 'Size Description';
const lblProductCode = 'Product Code';
const destinationCountryValue = 'Destination Country';
const lblPOLineItemNumber = 'PO Line Item Number';
const lblScheduleLineItemNumber = 'Schedule Line Item Number';
const lblTradingCoPoNumber = 'Trading Co Purchase Order Number';
const lblModeOfTransportation = 'Mode of Transportation';
const lblCompanyCode = 'Company Code';
const lblCurrentEvent = 'Current Event';
const lblInitGACReasonCode = 'Initial GAC Reason Code';
const lblNextEvent = 'Next Event';
const lblPurchaseGroup = 'Purchase Group';
const lblPurchaseOrg = 'Purchase Org';
const lblMeasureUnit = 'Unit of Measure';
const lblDocumentDate = 'Document Date';
const lblChangeDate = 'Change Date';
const lblShippingType = 'Shipping Type';
const lblASNQuantity = 'ASN Quantity';
const lblProofOfDelivery = 'Proof of Delivery Date';
const lblFxDate = 'FX Adjustment Date';
const lblCreateDate = 'Create Date';
const lblCurrentEventDate = 'Current Event Date';
const lblInitGAC = 'Initial GAC';
const lblNextEventDate = 'Next Event Date';
const lblDPOMLineItemStatus = 'DPOM Line Item Status';
const lblProductName = 'Product Name';
const lblAddressOverrideIndicator = 'Address Override Indicator';

export function ppmRenderRateUnitValue(row) {
  if ((row[this.additionalFields[0]] || '').toLowerCase() === 'percent') {
    return `${row[this.primary] || ''} %`;
  }
  return `${row[this.primary] || ''} ${row[this.secondary] || ''}`.trim() || undefined;
}
const ppmReportFields = {
  [ppmReportFieldMapping.PPM_REPORT_PONUMBER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PONUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblPurchaseOrderNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_LINE_NUMBER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_LINE_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblPOLineItemNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_SCHEDULE_LINE_NUMBER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SCHEDULE_LINE_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblScheduleLineItemNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_SIZE_DESC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SIZE_DESC,
    secondary: '',
    type: 'string',
    link: false,
    label: lblSizeDescription,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_TR_CO_PONUMBER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TR_CO_PONUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblTradingCoPoNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_VENDOR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_VENDOR,
    secondary: ppmReportFieldMapping.PPM_REPORT_VENDOR_NAME,
    type: 'string',
    link: false,
    label: 'Vendor Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE_DESC,
    type: 'string',
    link: false,
    label: 'PMO/DEC code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_ITEM_STATUS]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ITEM_STATUS,
    secondary: '',
    type: 'string',
    link: false,
    label: lblDPOMLineItemStatus,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE_DESC,
    type: 'string',
    link: false,
    label: 'Doc Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_DOC_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DOC_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblDocumentDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_CHANGED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CHANGED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblChangeDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_PLANNING_PRIORITY_NUMBER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PLANNING_PRIORITY_NUMBER,
    secondary: ppmReportFieldMapping.PPM_REPORT_PLANNING_PRIORITY_NUMBER_DESC,
    type: 'string',
    link: false,
    label: 'Planning Priority Number; EDP',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_MRGAC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_MRGAC,
    secondary: '',
    type: 'date',
    link: false,
    label: 'MRGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_OGAC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_OGAC,
    secondary: '',
    type: 'date',
    link: false,
    label: 'OGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_GAC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GAC,
    secondary: '',
    type: 'date',
    link: false,
    label: 'GAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE_DESC,
    type: 'string',
    link: false,
    label: 'GAC Reason Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_USECASE_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_USECASE_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_USECASE_CODE_DESC,
    type: 'string',
    link: false,
    label: 'GRC Usecase Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_PREV_GAC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PREV_GAC,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Previous GAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_PREV_GAC_REASON_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PREV_GAC_REASON_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_PREV_GAC_REASON_CODE_DESC,
    type: 'string',
    link: false,
    label: 'Previous GAC Reason',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_INITIAL_GAC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_INITIAL_GAC,
    secondary: '',
    type: 'date',
    link: false,
    label: lblInitGAC,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_INITIAL_GAC_REASON_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_INITIAL_GAC_REASON_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_INITIAL_GAC_REASON_CODE_DESC,
    type: 'string',
    link: false,
    label: lblInitGACReasonCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_OGAC_VS_MRGAC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_OGAC_VS_MRGAC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'OGAC vs MRGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_GAC_VS_MRGAC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GAC_VS_MRGAC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'GAC vs MRGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_GAC_VS_OGAC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GAC_VS_OGAC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'GAC/OGAC Difference',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_MRGAC_CAPACITY_CONSUMPTION_WEEK]: {
    primary: ppmReportFieldMapping.PPM_REPORT_MRGAC_CAPACITY_CONSUMPTION_WEEK,
    secondary: '',
    type: 'string',
    link: false,
    label: 'MRGAC Cpty Cnsmptn Wk',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_GAC_VS_PREVGAC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GAC_VS_PREVGAC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'GAC Slippage',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_CLASS]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_CLASS,
    secondary: ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_CLASS_DESC,
    type: 'string',
    link: false,
    label: 'Global Planning Product Classification',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_GROUP]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_GROUP,
    secondary: ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_GROUP_DESC,
    type: 'string',
    link: false,
    label: 'Global Planning Product Group',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ORDER_REASON_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ORDER_REASON_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_ORDER_REASON_DESCRIPTION,
    type: 'string',
    link: false,
    label: 'Reason for Ordering',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SEASON_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SEASON_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Season',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_GEOGRAPHY_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GEOGRAPHY_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Geo Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SIZE_QUANTITY]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SIZE_QUANTITY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Total Size Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TRANSPORT_MODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TRANSPORT_MODE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblModeOfTransportation,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PLANT_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PLANT_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_PLANT_NAME,
    type: 'string',
    link: false,
    label: 'Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TR_CO_PLANT_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TR_CO_PLANT_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_TR_CO_PLANT_NAME,
    type: 'string',
    link: false,
    label: 'Trading Company Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SHIPPING_TYPE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SHIPPING_TYPE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblShippingType,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FX_ADJ_REQUIRED_INDICATOR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FX_ADJ_REQUIRED_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'FX Adjustment Required Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FX_ADJUSTMENT_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FX_ADJUSTMENT_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblFxDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ASN_QUANTITY]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ASN_QUANTITY,
    secondary: '',
    type: 'string',
    link: false,
    label: lblASNQuantity,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FACTORY_PROOF_OF_DELIVERY_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FACTORY_PROOF_OF_DELIVERY_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblProofOfDelivery,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ASN_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ASN_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'ASN Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_QTY]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_QTY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Origin Receipt Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Origin Receipt Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_CREATED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CREATED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblCreateDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_EXPECTED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Acceptance Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Acceptance Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_EXPECTED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Ex Factory Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Ex Factory Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_EXPECTED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Factory Delivery Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Factory Delivery Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ISSUE_PO_EXPECTED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ISSUE_PO_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Issuance Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ISSUE_PO_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ISSUE_PO_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Issuance Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_MATERIAL_ORDER_EXPECTED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_MATERIAL_ORDER_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Material Order Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_MATERIAL_ORDER_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_MATERIAL_ORDER_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Material Order Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_MATERIAL_TRIM_RECEIPT_EXPECTED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_MATERIAL_TRIM_RECEIPT_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Material Trim Receipt Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_MATERIAL_TRIM_RECEIPT_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_MATERIAL_TRIM_RECEIPT_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Material Trim Receipt Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_QRS_EXPECTED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_QRS_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'QRS Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_QRS_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_QRS_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'QRS Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PROD_START_EXPECTED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PROD_START_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Production Start Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PROD_START_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PROD_START_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Production Start Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PROD_END_EXPECTED_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PROD_END_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Production End Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PROD_END_ACTUAL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PROD_END_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Production End Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_CANCEL_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CANCEL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Item Cancel Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_STATISTICAL_DELIVERY_DATE]: {
    primary: ppmReportFieldMapping.PPM_STATISTICAL_DELIVERY_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Statistical Delivery Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT,
    secondary: '',
    type: 'string',
    link: false,
    label: lblCurrentEvent,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblCurrentEventDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT]: {
    primary: ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT,
    secondary: '',
    type: 'string',
    link: false,
    label: lblNextEvent,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT_DATE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: lblNextEventDate,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    requestDataType: 'dynamicDate',
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ADDRESS_OVERRIDE_INDICATOR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ADDRESS_OVERRIDE_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: lblAddressOverrideIndicator,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_CUSTOMER_SHIP_TO]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CUSTOMER_SHIP_TO,
    secondary: ppmReportFieldMapping.PPM_REPORT_CUSTOMER_SHIP_TO_NAME,
    type: 'string',
    link: false,
    label: 'Ship To Customer',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_CUSTOMER_PO]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CUSTOMER_PO,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Customer PO',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SALES_ORDER_ITEM_NUMBER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SALES_ORDER_ITEM_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Direct Ship Sales Order Item',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_DIRECT_SALES_ORDER_NUMBER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DIRECT_SALES_ORDER_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Direct Ship Sales Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_DIST_CHANNEL_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DIST_CHANNEL_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_DIST_CHANNEL_DESC,
    type: 'string',
    link: false,
    label: 'Distribution Channel',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SALES_ORG_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SALES_ORG_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Sales Organization Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BALANCE_TO_SHIP]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BALANCE_TO_SHIP,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Balance to Ship',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BALANCE_TO_SHIP_PERCENTAGE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BALANCE_TO_SHIP_PERCENTAGE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Balance to Ship %',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BALANCE_QTY]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BALANCE_QTY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Balance Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_QTY]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_QTY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Factory Delivered Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_QTY]: {
    primary: ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_QTY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Ex Factory Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ITEM_QTY]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ITEM_QTY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Total Item Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_LAUNCH]: {
    primary: ppmReportFieldMapping.PPM_REPORT_LAUNCH,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Launch Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_CATEGORY_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CATEGORY_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_CATEGORY_DESC,
    type: 'string',
    link: false,
    label: 'Category',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SUB_CATEGORY_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SUB_CATEGORY_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_SUB_CATEGORY_DESC,
    type: 'string',
    link: false,
    label: 'Sub Category',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ATHLETE_ID]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ATHLETE_ID,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Athlete ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ATHLETE_NAME]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ATHLETE_NAME,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Athlete Name',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BLANK_COLOR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BLANK_COLOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Blank Color',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BLANK_PRODUCT_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BLANK_PRODUCT_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Blank Product Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BLANK_STYLE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BLANK_STYLE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Blank Style',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_COLOR_DESC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_COLOR_DESC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Color Description',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_GENDER_AGE_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GENDER_AGE_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_GENDER_AGE_DESC,
    type: 'string',
    link: false,
    label: 'Gender Age',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_GLOBAL_CATEGORY_FOCUS_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GLOBAL_CATEGORY_FOCUS_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_GLOBAL_CATEGORY_FOCUS_DESC,
    type: 'string',
    link: false,
    label: 'Global Category Core Focus',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_GLOABL_CATEGORY_SUMMARY_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_GLOABL_CATEGORY_SUMMARY_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_GLOABL_CATEGORY_SUMMARY_DESC,
    type: 'string',
    link: false,
    label: 'Global Category Summary',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_LAUNCH_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_LAUNCH_CODE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Launch Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_LEAGUE_ID]: {
    primary: ppmReportFieldMapping.PPM_REPORT_LEAGUE_ID,
    secondary: ppmReportFieldMapping.PPM_REPORT_LEAGUE_ID_DESC,
    type: 'string',
    link: false,
    label: 'League ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_MARKETING_ID]: {
    primary: ppmReportFieldMapping.PPM_REPORT_MARKETING_ID,
    secondary: ppmReportFieldMapping.PPM_REPORT_MARKETING_DESC,
    type: 'string',
    link: false,
    label: 'Marketing Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_MIDSOLE_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_MIDSOLE_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Midsole Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_OUTSOLE_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_OUTSOLE_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Outsole Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PROMO_ONLY_INDICATOR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PROMO_ONLY_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Promo Only Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PRODUCT_REFILL_CLASS_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PRODUCT_REFILL_CLASS_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_PRODUCT_REFILL_CLASS_DESC,
    type: 'string',
    link: false,
    label: 'Product Refill Class ',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SILHOUTTE_TYPE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SILHOUTTE_TYPE,
    secondary: ppmReportFieldMapping.PPM_REPORT_SILHOUTTE_TYPE_DESC,
    type: 'string',
    link: false,
    label: 'Silhouette',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SILO_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SILO_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_SILO_DESC,
    type: 'string',
    link: false,
    label: 'Silo',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_DIMENSION_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DIMENSION_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size Dimension',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SIZE_SORT_SEQ_NUMBER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SIZE_SORT_SEQ_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size Sort sequence number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SPORT_ACTIVITY_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SPORT_ACTIVITY_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_SPORT_ACTIVITY_CODE_DESC,
    type: 'string',
    link: false,
    label: 'Sport Activity Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_STYLE_NUMBER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_STYLE_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Style Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TEAM_ID]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TEAM_ID,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Team ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TEAM_NAME]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TEAM_NAME,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Team Name',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TEAM_SILHOUTTE_ID]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TEAM_SILHOUTTE_ID,
    secondary: ppmReportFieldMapping.PPM_REPORT_TEAM_SILHOUTTE_ID_DESC,
    type: 'string',
    link: false,
    label: 'Team Silhouette',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_UPC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_UPC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'UPC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SIZE_MISMATCH_ID]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SIZE_MISMATCH_ID,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size Mismatch Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_DESTINATION_COUNTRY_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DESTINATION_COUNTRY_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_DESTINATION_COUNTRY_NAME,
    type: 'string',
    link: false,
    label: destinationCountryValue,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_NAME,
    type: 'string',
    link: false,
    label: lblPurchaseOrg,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PO_REJECTION_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PO_REJECTION_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_PO_REJECTION_CODE_DESC,
    type: 'string',
    link: false,
    label: 'DPOM Rejection Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_INVENTORY_SEGMENT_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_INVENTORY_SEGMENT_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Inventory Segment Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PO_COMPANY_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PO_COMPANY_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_PO_COMPANY_DESC,
    type: 'string',
    link: false,
    label: lblCompanyCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_CREATED_BY]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CREATED_BY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Created By',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_DIVISION_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DIVISION_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Division',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_INCO_TERMS]: {
    primary: ppmReportFieldMapping.PPM_REPORT_INCO_TERMS,
    secondary: '',
    type: 'string',
    link: false,
    label: 'In Co Terms',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ITEM_TEXT_DETAIL]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ITEM_TEXT_DETAIL,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Item Text',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_MFG_ORIGIN_COUNTRY_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_MFG_ORIGIN_COUNTRY_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_MFG_ORIGIN_COUNTRY_NAME,
    type: 'string',
    link: false,
    label: 'MCO',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_OVERDUE_INDICATOR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_OVERDUE_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Over Due Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PRODUCT_NAME]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PRODUCT_NAME,
    secondary: '',
    type: 'string',
    link: false,
    label: lblProductName,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PURCHASE_GROUP_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PURCHASE_GROUP_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_PURCHASE_GROUP_NAME,
    type: 'string',
    link: false,
    label: lblPurchaseGroup,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TR_CO_COMPANY_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TR_CO_COMPANY_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Trading Company Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TITLE_TRANSFER_MODEL_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TITLE_TRANSFER_MODEL_CODE,
    secondary: ppmReportFieldMapping.PPM_REPORT_TITLE_TRANSFER_MODEL_DESC,
    type: 'string',
    link: false,
    label: 'TTMI',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_UNIT_OF_MEASURE_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_UNIT_OF_MEASURE_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblMeasureUnit,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Season Year',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SIZE_PRODUCT_FOB]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SIZE_PRODUCT_FOB,
    secondary: ppmReportFieldMapping.PPM_REPORT_SIZE_PRODUCT_FOB_CURR_CODE,
    type: 'string',
    link: false,
    label: 'Product FOB',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FOB_MISMATCH_INDICATOR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FOB_MISMATCH_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'FOB Mismatch Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FOB_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FOB_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_FOB_CRPO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_FOB_CRPO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Gross Price/FOB',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TRADING_CO_FOB_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TRADING_CO_FOB_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_TRADING_CO_FOB_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_TRADING_CO_FOB_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Gross Price/FOB',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TOOLING_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TOOLING_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_TOOLING_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_TOOLING_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Tooling',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_DOMESTIC_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DOMESTIC_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_DOMESTIC_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_DOMESTIC_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Domestic Sourcing',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_CRPO_RUV_TYPE]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'B Grade Disc Auto',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'B Grade Disc Manual',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge SLT',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge SLT %',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge Min Mat Main Body',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge Min Material Trim',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge Min Productivity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge Disc Production Delay',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOLUME_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOLUME_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOLUME_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOLUME_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge Disc Volume',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge Disc Quality',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge Disc Other',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge Misc',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge VAS',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_PERCENT_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_PERCENT_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_PERCENT_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_PERCENT_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Surcharge VAS %',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_CRPO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_CRPO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Fashion VAS',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_CRPO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_CRPO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Fashion VAS Abs',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Net including surcharges',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_HASH_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_HASH_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_HASH_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_HASH_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Commission Value #',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_PERCENT_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_PERCENT_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_PERCENT_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_PERCENT_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Commission Value %',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Foreign Exchange',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_CRPO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_CRPO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Net including discounts',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_DUTY_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DUTY_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_DUTY_CRPO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_DUTY_CRPO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Duty ',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FACTORY_FREIGHT_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FACTORY_FREIGHT_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_FACTORY_FREIGHT_CRPO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_FACTORY_FREIGHT_CRPO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Factory freight',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_NIKE_FRIEGHT_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_NIKE_FRIEGHT_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_NIKE_FRIEGHT_CRPO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_NIKE_FRIEGHT_CRPO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Nike freight',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_TRCO_RUV_CURR_CODE,
    additionalFields: !pricingNonProd
      ? [ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_TRCO_RUV_TYPE_NAME]
      : [],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Net including discounts',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_VAS_SIZE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_VAS_SIZE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size VAS',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_VAS_ITEM]: {
    primary: ppmReportFieldMapping.PPM_REPORT_VAS_ITEM,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Item VAS',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TRCO_RELEVANT_INDICATOR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TRCO_RELEVANT_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Trd Co rel',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  }
};
// To avoid the number lines go beyond 1000.
export default pricingNonProd ? {
  ...ppmReportFields,
  [ppmReportFieldMapping.PPM_REPORT_PRODUCT_TOOLING_COST]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PRODUCT_TOOLING_COST,
    secondary: ppmReportFieldMapping.PPM_REPORT_PRODUCT_TOOLING_CURRENCY_CODE,
    type: 'string',
    link: false,
    label: 'Product Tooling Cost',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TOOLING_MISMATCH_INDICATOR]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TOOLING_MISMATCH_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Tooling Mismatch Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblProductCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false,
    includeInRequest: false
  },
  // [ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Markup Accrual',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // // },
  // [ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Net Including Trading Co Markup',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co  Gross Price/FOB',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Tooling',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co B Grade Disc Auto',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co B Grade Disc Manual',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge SLT',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge SLT %',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV_CURR_CODE,
  //   additionalFields: [
  //     ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV_TYPE_NAME
  //   ],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge Min Mat Main Body',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge Min Material Trim',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV_CURR_CODE,
  //   additionalFields: [
  //     ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV_TYPE_NAME
  //   ],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge Min Productivity',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV_CURR_CODE,
  //   additionalFields: [
  //     ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV_TYPE_NAME
  //   ],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge Disc Prodution Delay',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge Disc Volume',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge Disc Quality',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge Disc Other',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge Misc',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Surcharge VAS',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Fashion VAS',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Fashio VAS abs',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Net including surcharges',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  // [ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV]: {
  //   primary: ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV,
  //   secondary: ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV_CURR_CODE,
  //   additionalFields: [ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV_TYPE_NAME],
  //   renderValue: ppmRenderRateUnitValue,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Foreign Exchange',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   default: false
  // },
  [ppmReportFieldMapping.PPM_REPORT_DIVERT_FLAG]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DIVERT_FLAG,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Convert/Divert Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_DIVERTED_FROM]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DIVERTED_FROM,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Created From',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_DIVERTED_TO]: {
    primary: ppmReportFieldMapping.PPM_REPORT_DIVERTED_TO,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Moved To',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_ASIAN_CC]: {
    primary: ppmReportFieldMapping.PPM_REPORT_ASIAN_CC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Asian C&C Label',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false,
    groupedColumn: true
  },
  [ppmReportFieldMapping.PPM_REPORT_KOREA_CHEST_SIZE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_KOREA_CHEST_SIZE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Korea Size',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_CHINA_TOP_SIZE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_CHINA_TOP_SIZE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'China Size',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  }
} : {
  ...ppmReportFields,
  [ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE]: {
    primary: ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblProductCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Markup Accrual',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Net Including Trading Co Markup',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co  Gross Price/FOB',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Tooling',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co B Grade Disc Auto',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co B Grade Disc Manual',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge SLT',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge SLT %',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge Min Mat Main Body',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge Min Material Trim',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge Min Productivity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge Disc Prodution Delay',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge Disc Volume',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge Disc Quality',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge Disc Other',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge Misc',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Surcharge VAS',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Fashion VAS',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Fashio VAS abs',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Net including surcharges',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV]: {
    primary: ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV,
    secondary: ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV_CURR_CODE,
    additionalFields: [ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV_TYPE_NAME],
    renderValue: ppmRenderRateUnitValue,
    type: 'string',
    link: false,
    label: 'Trading Co Foreign Exchange',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  }
};
