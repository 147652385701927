/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner, updateDialogStates } from '../dialogStates/dialogStatesSlice';
import getflattenResponse from '../../helpers/common';
import appConfig from '../../appConfig';
import { getDefaultStakeHoldersRequest, setFieldItemVas } from '../../helpers/dataHelpers';
import setFieldSrcSysNm from './conversationModalHelper';

const name = 'conversationModal';

function createInitialState() {
  return {
    category: {},
    stackHoldersEmail: [],
    validatedEmail: ''
  };
}

function createReducers() {
  function fetchConversationTheards(state: Object, action: Object) {
    return {
      ...state,
      category: action.payload.categoryList
    };
  }

  function validateStackHoldersEmailID(state: Object, action: Object) {
    return {
      ...state,
      validatedEmail: action.payload.emaildID
    };
  }
  function updateDefaultStakeHolders(state: Object, action: Object) {
    return {
      ...state,
      stackHoldersEmail: action.payload
    };
  }
  function resetConversationModal(state: Object) {
    return {
      ...state,
      category: {},
      stackHoldersEmail: [],
      validatedEmail: ''
    };
  }

  return {
    fetchConversationTheards,
    validateStackHoldersEmailID,
    updateDefaultStakeHolders,
    resetConversationModal
  };
}

export const initialState = createInitialState();
export const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function requestPoNumber() {
    return createAsyncThunk(
      `${name}/requestPoNumber`,
      async ({
        ids, selectedRecordCounts, callback, type = 'includes'
      }, { getState, dispatch }) => {
        const state = getState();
        const searchRequestBody = { ...state.PoSearch.body || {} };
        let requestBody = {
          search: [...(searchRequestBody.search) || []],
          fields: [...(searchRequestBody.fields) || []].filter((field: string) => (
            !appConfig.excludedPOFieldsInExportedFile.includes(field)
          )),
          filter: [...(searchRequestBody.filter) || []],
          // sort: { ...(searchRequestBody.sort) || {} },
          offset: '0',
          count: selectedRecordCounts
        };
        requestBody = setFieldSrcSysNm(requestBody);
        requestBody = setFieldItemVas(requestBody);

        if (ids.length > 0) {
          requestBody.search.push({
            fieldName: 'id',
            operator: type === 'includes' ? '=' : '!=',
            fieldValue: ids
          });
        }
        dispatch(toggleSpinner(true));
        request({
          api: 'POSearch',
          method: 'post',
          data: requestBody
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(getflattenResponse(response.data));
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  function fetchDefaultStakeHolders() {
    return createAsyncThunk(
      `${name}/fetchDefaultStakeHolders`,
      async ({
        ids, fetchStakeHoldersCallback, type = 'includes'
      }, { getState, dispatch }) => {
        dispatch(actions.updateDefaultStakeHolders([]));
        dispatch(actions.resetConversationModal());
        const state = getState();
        const email = state.auth && state.auth.userDetails && state.auth.userDetails.email;
        const poSearchResultData = state.PoSearch.PoSearchResult.objects;
        const requestBody = getDefaultStakeHoldersRequest(
          poSearchResultData, type, ids
        );
        dispatch(updateDialogStates({ defaultStakeHolderSpinner: true }));
        dispatch(toggleSpinner(true));
        request({
          api: 'defaultStakeHolders',
          method: 'post',
          data: requestBody
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          // The below change was made to avoid duplicate stakeholders when
          // the stakeholderEmailIds from response is same as the default stake holder mail.
          const uniqueStakeholderEmailIds = response.data.stakeholderEmailIds.filter(
            (item) => item !== email
          );
          dispatch(actions.updateDefaultStakeHolders(
            [...(uniqueStakeholderEmailIds || []), email]
          ));
          dispatch(updateDialogStates({ defaultStakeHolderSpinner: false }));
          if (fetchStakeHoldersCallback) {
            fetchStakeHoldersCallback(response.data);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ defaultStakeHolderSpinner: false }));
          if (fetchStakeHoldersCallback) {
            fetchStakeHoldersCallback(null, error);
          }
        });
      }
    );
  }

  return {
    requestPoNumber: requestPoNumber(),
    fetchDefaultStakeHolders: fetchDefaultStakeHolders()
  };
}

const extraActions = createExtraActions();

export const conversationModalActions = { ...actions, ...extraActions };
export const conversationModalReducer = slice.reducer;
