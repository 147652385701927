/**
 * This file is used for the actions for Authentication.
 * @exports Actions
 * @author {Cognizant Technology Solution}
 * @flow
 */
import { createSlice } from '@reduxjs/toolkit';
import appConfig from '../../appConfig';

const initialState = {};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthKey(state, action) {
      let parsedToken = null;
      if (action.payload['okta-token-storage']) {
        parsedToken = JSON.parse(action.payload['okta-token-storage']);
      }

      const claims = parsedToken
        ? parsedToken
        && parsedToken.idToken
        && parsedToken.idToken.claims
        : {};
      const oktaUserGroupMapping = claims.groups;
      let userGroup = '';
      if (oktaUserGroupMapping instanceof Array) {
        userGroup = appConfig.userGroupPrecedence
          .find((group) => oktaUserGroupMapping.includes(group));
      }
      const userDetails = {
        group: userGroup,
        email: claims.email,
        name: claims.name,
        userID: claims.legacy_username
      };

      localStorage.setItem('okta-token-storage', JSON.stringify(parsedToken));

      return {
        ...state,
        ...action.payload,
        parsedToken,
        userDetails,
        isMetricsVisible: appConfig.enableMultiDashboard
      };
    },
    resetToInitialState() {
      return { ...initialState };
    }
  }
});

export const {
  setAuthKey,
  resetToInitialState
} = authSlice.actions;

export default authSlice.reducer;
