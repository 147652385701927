export default {
  poNumber: {
    primary: 'poNumber', secondary: '', type: 'string', link: false, label: 'PO Number', wrap: true, numeric: 'true', disablePadding: false
  },
  itemNumber: {
    primary: 'itemNumber', secondary: '', type: 'string', link: false, label: 'PO Item Number', wrap: true, numeric: 'true', disablePadding: false
  },
  docType: {
    primary: 'docType', secondary: '', type: 'string', link: false, label: 'Doc Type', wrap: true, numeric: 'false', disablePadding: false
  },
  productCode: {
    primary: 'productCode', secondary: '', type: 'numeric', link: false, label: 'Product Code', wrap: true, numeric: 'true', disablePadding: false, group: true
  },
  currentGACDate: {
    primary: 'currentGACDate', secondary: '', type: 'date', link: false, label: 'GAC Date', wrap: true, numeric: 'true', disablePadding: false, group: true
  },
  currentGACReasonCode: {
    primary: 'currentGACReasonCode',
    secondary: 'currentGACReasonDescription',
    type: 'string',
    link: false,
    label: 'GAC Reason Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false
  },
  mrgacDate: {
    primary: 'mrgacDate', secondary: '', type: 'date', link: false, label: 'MRGAC', wrap: true, numeric: 'true', disablePadding: false, group: true
  },
  vendorCode: {
    primary: 'vendorCode',
    type: 'string',
    link: false,
    label: 'Vendor Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  reasonForFailure: {
    primary: 'reasonForFailure', secondary: '', type: 'string', link: false, label: 'Reason for failure', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
