/* @flow */
import ppmReportsHeader from '../../constants/ppmReportsHeader';
import ppmReportFieldProperties from '../../constants/fieldProperties/ppmReportFieldProperties';

export function getFieldsList(secondaryFields: Object) {
  ppmReportsHeader
    .filter((header) => header.default)
    .forEach((header) => {
      secondaryFields.push(header.primary);
      if (header.secondary) {
        secondaryFields.push(header.secondary);
      }
      if (header.additionalFields) {
        secondaryFields.push(...header.additionalFields);
      }
    });
  ppmReportsHeader.forEach((headRow: Object) => {
    if (headRow.includeInRequest) {
      secondaryFields.push(headRow.primary);
    }
  });
}

export function getRequestedFilterValues(requestBody: Object, state: Object) {
  const requestedBody = requestBody;
  requestedBody.filtersInResponse = Object.keys(state.ppmReport.selectedFilters)
    .filter((filter) => state.ppmReport.selectedFilters[filter])
    .map((filter) => {
      const filterInResponse = {};
      const fieldProperties = ppmReportFieldProperties[filter] || {};
      if (!fieldProperties) {
        return null;
      }
      filterInResponse.primary = fieldProperties.primary;
      if (fieldProperties.secondary) {
        filterInResponse.secondary = fieldProperties.secondary;
      }
      return filterInResponse;
    })
    .filter((element) => element);
}

export function getSelectedFilterValues(requestBody: Object, state: Object) {
  const requestedBody = requestBody;
  requestedBody.filtersInResponse = (state.searchpaneldata
    && state.searchpaneldata.selectedFilterOptions)
    .map((filter) => {
      const filterInResponse = {};
      const fieldProperties = ppmReportFieldProperties[filter] || {};
      if (!fieldProperties) {
        return null;
      }
      filterInResponse.primary = fieldProperties.primary;
      if (fieldProperties.secondary) {
        filterInResponse.secondary = fieldProperties.secondary;
      }
      return filterInResponse;
    });
}

export function getSelectedFilterValuesFromOpMetrics(filterValues: Object) {
  const filtersFromOperationalMetrics = filterValues && filterValues
    .map((filter) => {
      const filterInResponse = {};
      const fieldProperties = ppmReportFieldProperties[filter] || {};
      if (!fieldProperties.primary) {
        return null;
      }
      filterInResponse.primary = fieldProperties.primary;
      if (fieldProperties.secondary) {
        filterInResponse.secondary = fieldProperties.secondary;
      }
      return filterInResponse;
    });
  return filtersFromOperationalMetrics?.filter((element) => element);
}

export function getFiltersInResponseRequestBody(filterData: Object) {
  const filtersInRequestBody = filterData && filterData
    .map((filter) => {
      const filterInResponse = {};
      const fieldProperties = ppmReportFieldProperties[filter.fieldName] || {};
      if (!fieldProperties) {
        return null;
      }
      filterInResponse.primary = fieldProperties.primary;
      if (fieldProperties.secondary) {
        filterInResponse.secondary = fieldProperties.secondary;
      }
      return filterInResponse;
    });

  return filtersInRequestBody.filter((element) => element);
}

export function getFiltersForRequest(requestBody: Object, selectedFilterKeys: Object) {
  const requestedBody = requestBody;
  requestedBody.filtersInResponse = selectedFilterKeys.map((filter) => {
    const filterInResponse = {};
    const fieldProperties = ppmReportFieldProperties[filter] || {};
    if (!fieldProperties) {
      return null;
    }
    filterInResponse.primary = fieldProperties.primary;
    if (fieldProperties.secondary) {
      filterInResponse.secondary = fieldProperties.secondary;
    }
    return filterInResponse;
  })
    .filter((element) => element);
}

export function asyncCallToNewFiltersCallback(requestNewFiltersCallback: Function, error: Object) {
  if (requestNewFiltersCallback) {
    requestNewFiltersCallback(null, error);
  }
}

export function getModifiedSelectedFilters(
  filtersSelected: Object,
  filter: string,
  modifiedSelectedFilters: Object
) {
  const modifiedSelectedFiltersNew = modifiedSelectedFilters;
  if (filtersSelected.includes(filter)) {
    if (!modifiedSelectedFilters[filter]) modifiedSelectedFiltersNew[filter] = [];
  } else {
    modifiedSelectedFiltersNew[filter] = null;
  }
}

export function getSelectedFilterKeys(
  filter: string,
  modifiedSelectedFilters: Object,
  filterDropdownValues: Object,
  filtersToBeRequested: Object,
  filtersInResponse: Object
) {
  const filterRequest: { primary: string; secondary?: string; } = { primary: filter };
  if (ppmReportFieldProperties[filter] && ppmReportFieldProperties[filter].secondary) {
    filterRequest.secondary = ppmReportFieldProperties[filter].secondary;
  }
  if (modifiedSelectedFilters[filter]) {
    if (!filterDropdownValues[filter]) {
      filtersToBeRequested.push(filterRequest);
    }
    filtersInResponse.push(filterRequest);
  }
}
