export default {
  id: '',
  resourceType: '',
  links: {
    self: ''
  },
  pages: {
    next: 'offset=1000, count=1000',
    prev: '',
    totalPages: 3,
    totalResources: 3006
  },
  objects: [
    {
      poHeader: {
        poNumber: '3502008601',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '315122-001',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-09',
        productName: 'AIR FORCE 1 07'
      },
      dpomAirFreight: {
        naf: '10',
        faf: '90'
      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'FC Factory Vendor'
      },
      id: '3502008601-10',
      tsUpdate: 1664362024
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {
        naf: '10',
        faf: '90'
      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-100',
      tsUpdate: 1664742173
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '110',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {
        naf: '10',
        faf: '90'
      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-110',
      tsUpdate: 1664742176
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '120',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-120',
      tsUpdate: 1664742175
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '130',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-130',
      tsUpdate: 1664742146
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '140',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-140',
      tsUpdate: 1664742177
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-20',
      tsUpdate: 1664742177
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '290',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-290',
      tsUpdate: 1664742336
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-30',
      tsUpdate: 1664742189
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-300',
      tsUpdate: 1664742331
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '310',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-310',
      tsUpdate: 1664742334
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '320',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-320',
      tsUpdate: 1664742332
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '330',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-330',
      tsUpdate: 1664742331
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '340',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-340',
      tsUpdate: 1664742336
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '350',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-350',
      tsUpdate: 1664742330
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '360',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-360',
      tsUpdate: 1664742335
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '40',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-40',
      tsUpdate: 1664742178
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-400',
      tsUpdate: 1664742331
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '50',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-50',
      tsUpdate: 1664742190
    },
    {
      poHeader: {
        poNumber: '3502008701',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '90',
        divisionCode: '20',
        productCode: '130690-004',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008701-90',
      tsUpdate: 1664742185
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-100',
      tsUpdate: 1664742339
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '110',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-110',
      tsUpdate: 1664742340
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '150',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-150',
      tsUpdate: 1664742339
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '170',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-170',
      tsUpdate: 1664742421
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '180',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-180',
      tsUpdate: 1664742421
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '190',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-190',
      tsUpdate: 1664742423
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-200',
      tsUpdate: 1664742425
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '240',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-240',
      tsUpdate: 1664742424
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '250',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-250',
      tsUpdate: 1664742427
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '260',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-260',
      tsUpdate: 1664742443
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '270',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-270',
      tsUpdate: 1664742432
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '280',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-280',
      tsUpdate: 1664742428
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '290',
        divisionCode: '20',
        productCode: '130690-410',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-290',
      tsUpdate: 1664742429
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '40',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-40',
      tsUpdate: 1664742352
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '50',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-50',
      tsUpdate: 1664742342
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '60',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-60',
      tsUpdate: 1664742354
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '70',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-70',
      tsUpdate: 1664742353
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '80',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-80',
      tsUpdate: 1664742343
    },
    {
      poHeader: {
        poNumber: '3502008702',
        vendorCode: 'LN'
      },
      poLine: {
        itemNumber: '90',
        divisionCode: '20',
        productCode: '130690-133',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AIR JORDAN 12 RETRO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'TZzznbnnNh'
      },
      id: '3502008702-90',
      tsUpdate: 1664742353
    },
    {
      poHeader: {
        poNumber: '3502829407',
        vendorCode: 'LMS'
      },
      poLine: {
        itemNumber: '50',
        divisionCode: '10',
        productCode: 'DD1727-469',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2021-05-12',
        goodsAtConsolidatorReasonCode: '3',
        productName: 'AS M NK DF SC TOP SS'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'MAS ACTIVE TRADING (Pvt'
      },
      id: '3502829407-50',
      tsUpdate: 1665134236
    },
    {
      poHeader: {
        poNumber: '3503196834',
        vendorCode: 'NIG'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '10',
        productCode: 'DQ5192-063',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-14',
        productName: 'AS W NSW CLUB FLC MR PANT STD'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'SHENZHOU INTERNATIONAL GROUP'
      },
      id: '3503196834-30',
      tsUpdate: 1665134795
    },
    {
      poHeader: {
        poNumber: '4300586300',
        vendorCode: 'XE'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '844881-006',
        itemQuantity: '100',
        productName: 'NIKE COURT BOROUGH LOW PREM'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'HPbyYjeLDf'
      },
      id: '4300586300-10',
      tsUpdate: 1664362033
    },
    {
      poHeader: {
        poNumber: '4300586400',
        vendorCode: 'XE'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '844881-006',
        itemQuantity: '40',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'NIKE COURT BOROUGH LOW PREM'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'HPbyYjeLDf'
      },
      id: '4300586400-10',
      tsUpdate: 1664362034
    },
    {
      poHeader: {
        poNumber: '4300586401',
        vendorCode: 'XE'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '844881-006',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'NIKE COURT BOROUGH LOW PREM'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'HPbyYjeLDf'
      },
      id: '4300586401-10',
      tsUpdate: 1664362034
    },
    {
      poHeader: {
        poNumber: '4505091005',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '10',
        productCode: '363934-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'CORE 2.5\' COMP SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '4505091005-20',
      tsUpdate: 1664362063
    },
    {
      poHeader: {
        poNumber: '4505091035',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: '423994-100',
        itemQuantity: '160',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'ARSENAL SS TRAINING TOP 1'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Soerlvkuvo'
      },
      id: '4505091035-10',
      tsUpdate: 1664362066
    },
    {
      poHeader: {
        poNumber: '4505091035',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '10',
        productCode: '423994-100',
        itemQuantity: '50',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'ARSENAL SS TRAINING TOP 1'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Soerlvkuvo'
      },
      id: '4505091035-20',
      tsUpdate: 1664362066
    },
    {
      poHeader: {
        poNumber: '4505091035',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '10',
        productCode: '423994-100',
        itemQuantity: '160',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'ARSENAL SS TRAINING TOP 1'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Soerlvkuvo'
      },
      id: '4505091035-30',
      tsUpdate: 1664362066
    },
    {
      poHeader: {
        poNumber: '4505091035',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '40',
        divisionCode: '10',
        productCode: '423994-100',
        itemQuantity: '160',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'ARSENAL SS TRAINING TOP 1'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Soerlvkuvo'
      },
      id: '4505091035-40',
      tsUpdate: 1664362066
    },
    {
      poHeader: {
        poNumber: '4505091036',
        vendorCode: 'TMV'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '10',
        productCode: '425017-600',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'SHOWDOWN CROSSHATCH CREW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QbLEHRiOMH'
      },
      id: '4505091036-20',
      tsUpdate: 1664362066
    },
    {
      poHeader: {
        poNumber: '4505091037',
        vendorCode: 'TMV'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: '425017-600',
        itemQuantity: '6057',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'SHOWDOWN CROSSHATCH CREW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QbLEHRiOMH'
      },
      id: '4505091037-10',
      tsUpdate: 1664362066
    },
    {
      poHeader: {
        poNumber: '4505091038',
        vendorCode: 'TMV'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: '425017-600',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'SHOWDOWN CROSSHATCH CREW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QbLEHRiOMH'
      },
      id: '4505091038-10',
      tsUpdate: 1664362066
    },
    {
      poHeader: {
        poNumber: '4505091039',
        vendorCode: 'TMV'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: '425017-600',
        itemQuantity: '6057',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'SHOWDOWN CROSSHATCH CREW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QbLEHRiOMH'
      },
      id: '4505091039-10',
      tsUpdate: 1664362067
    },
    {
      poHeader: {
        poNumber: '4505091039',
        vendorCode: 'TMV'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '10',
        productCode: '425017-600',
        itemQuantity: '3127',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'SHOWDOWN CROSSHATCH CREW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QbLEHRiOMH'
      },
      id: '4505091039-20',
      tsUpdate: 1664362067
    },
    {
      poHeader: {
        poNumber: '4505091039',
        vendorCode: 'TMV'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '10',
        productCode: '425017-600',
        itemQuantity: '6057',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'SHOWDOWN CROSSHATCH CREW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QbLEHRiOMH'
      },
      id: '4505091039-30',
      tsUpdate: 1664362067
    },
    {
      poHeader: {
        poNumber: '4505091044',
        vendorCode: 'LMS'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: 'AA0017-010',
        itemQuantity: '310',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'G NSW TEE HILO METALLIC JDI'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QBiJBeupnr'
      },
      id: '4505091044-10',
      tsUpdate: 1664362068
    },
    {
      poHeader: {
        poNumber: '4505091045',
        vendorCode: 'LMS'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: 'AA0017-010',
        itemQuantity: '200',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'G NSW TEE HILO METALLIC JDI'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QBiJBeupnr'
      },
      id: '4505091045-10',
      tsUpdate: 1664362068
    },
    {
      poHeader: {
        poNumber: '4505091045',
        vendorCode: 'LMS'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '10',
        productCode: 'AA0017-010',
        itemQuantity: '120',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'G NSW TEE HILO METALLIC JDI'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QBiJBeupnr'
      },
      id: '4505091045-20',
      tsUpdate: 1664362068
    },
    {
      poHeader: {
        poNumber: '4505091046',
        vendorCode: 'LMS'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: 'AA0017-010',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'G NSW TEE HILO METALLIC JDI'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QBiJBeupnr'
      },
      id: '4505091046-10',
      tsUpdate: 1664362068
    },
    {
      poHeader: {
        poNumber: '4505091048',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '307960-108',
        itemQuantity: '210',
        originalGoodsAtConsolidatorDate: '2022-10-20',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091048-10',
      tsUpdate: 1664362068
    },
    {
      poHeader: {
        poNumber: '4505091048',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '20',
        productCode: '307960-108',
        originalGoodsAtConsolidatorDate: '2022-09-16',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091048-20',
      tsUpdate: 1664362068
    },
    {
      poHeader: {
        poNumber: '4505091049',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '307960-108',
        itemQuantity: '210',
        originalGoodsAtConsolidatorDate: '2022-09-16',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091049-10',
      tsUpdate: 1664362068
    },
    {
      poHeader: {
        poNumber: '4505091052',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '307960-108',
        itemQuantity: '390',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091052-10',
      tsUpdate: 1664362069
    },
    {
      poHeader: {
        poNumber: '4505091054',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '307960-110',
        itemQuantity: '290',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091054-10',
      tsUpdate: 1665228639
    },
    {
      poHeader: {
        poNumber: '4505091056',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '307960-108',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091056-10',
      tsUpdate: 1664362069
    },
    {
      poHeader: {
        poNumber: '4505091056',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '20',
        productCode: '307960-110',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091056-20',
      tsUpdate: 1665228629
    },
    {
      poHeader: {
        poNumber: '4505091057',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '307960-108',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091057-10',
      tsUpdate: 1664362070
    },
    {
      poHeader: {
        poNumber: '4505091061',
        vendorCode: 'EMH'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '30',
        productCode: 'BA5446-010',
        itemQuantity: '1000',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'W NK GYM TOTE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'xaZnqRaoeE'
      },
      id: '4505091061-10',
      tsUpdate: 1664362071
    },
    {
      poHeader: {
        poNumber: '4505091064',
        vendorCode: 'EMH'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '30',
        productCode: 'BA5446-010',
        originalGoodsAtConsolidatorDate: '2022-10-17',
        goodsAtConsolidatorReasonCode: '4',
        productName: 'W NK GYM TOTE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'xaZnqRaoeE'
      },
      id: '4505091064-10',
      tsUpdate: 1664362071
    },
    {
      poHeader: {
        poNumber: '4505091066',
        vendorCode: 'EMH'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '30',
        productCode: 'BA5446-010',
        itemQuantity: '1000',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'W NK GYM TOTE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'xaZnqRaoeE'
      },
      id: '4505091066-30',
      tsUpdate: 1664362074
    },
    {
      poHeader: {
        poNumber: '4505091068',
        vendorCode: 'EA4'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '30',
        productCode: 'SC3023-810',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'NK MERC FADE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'MPPPFfshDm'
      },
      id: '4505091068-10',
      tsUpdate: 1664362074
    },
    {
      poHeader: {
        poNumber: '4505091068',
        vendorCode: 'EA4'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '30',
        productCode: 'SC3023-810',
        originalGoodsAtConsolidatorDate: '2022-10-29',
        productName: 'NK MERC FADE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'MPPPFfshDm'
      },
      id: '4505091068-20',
      tsUpdate: 1664362074
    },
    {
      poHeader: {
        poNumber: '4505091069',
        vendorCode: 'EA4'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '30',
        productCode: 'SC3023-810',
        itemQuantity: '1041',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'NK MERC FADE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'MPPPFfshDm'
      },
      id: '4505091069-30',
      tsUpdate: 1664362075
    },
    {
      poHeader: {
        poNumber: '4505091070',
        vendorCode: 'EA4'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '30',
        productCode: 'SC3023-810',
        itemQuantity: '952',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'NK MERC FADE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'MPPPFfshDm'
      },
      id: '4505091070-10',
      tsUpdate: 1664362075
    },
    {
      poHeader: {
        poNumber: '4505091795',
        vendorCode: 'LMS'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '10',
        productCode: 'AA0017-010',
        originalGoodsAtConsolidatorDate: '2022-08-19',
        goodsAtConsolidatorReasonCode: '53',
        productName: 'G NSW TEE HILO METALLIC JDI'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QBiJBeupnr'
      },
      id: '4505091795-30',
      tsUpdate: 1664362090
    },
    {
      poHeader: {
        poNumber: '4505091799',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '40',
        divisionCode: '20',
        productCode: '307960-110',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091799-40',
      tsUpdate: 1665228628
    },
    {
      poHeader: {
        poNumber: '4505091799',
        vendorCode: 'VT'
      },
      poLine: {
        itemNumber: '60',
        divisionCode: '20',
        productCode: '307960-110',
        itemQuantity: '40',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'WMNS AIR MAX 95'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: '--VT'
      },
      id: '4505091799-60',
      tsUpdate: 1665228628
    },
    {
      poHeader: {
        poNumber: '4505092108',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: '425017-600',
        itemQuantity: '18',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'SHOWDOWN CROSSHATCH CREW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'SWEZQjsHwV'
      },
      id: '4505092108-10',
      tsUpdate: 1664362110
    },
    {
      poHeader: {
        poNumber: '4505092125',
        vendorCode: 'NIG'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: '883729-091',
        itemQuantity: '100',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'W NSW GYM VNTG HOODIE FZ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'DJNuGnutFp'
      },
      id: '4505092125-10',
      tsUpdate: 1664362111
    },
    {
      poHeader: {
        poNumber: '4505092126',
        vendorCode: 'QB'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '923114-016',
        itemQuantity: '100',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'NIKE SB ZOOM JANOSKI HT SO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'LFCAbWOttU'
      },
      id: '4505092126-10',
      tsUpdate: 1664362111
    },
    {
      poHeader: {
        poNumber: '4505092127',
        vendorCode: 'EZU'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '30',
        productCode: 'SX4120-001',
        itemQuantity: '100',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'U NK ACDMY OTC'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Delta Galil Industries Limited'
      },
      id: '4505092127-10',
      tsUpdate: 1665136042
    },
    {
      poHeader: {
        poNumber: '4505092294',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: 'AA0017-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'G NSW TEE HILO METALLIC JDI'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Soerlvkuvo'
      },
      id: '4505092294-10',
      tsUpdate: 1664362114
    },
    {
      poHeader: {
        poNumber: '4505092490',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: '425017-600',
        itemQuantity: '18',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'SHOWDOWN CROSSHATCH CREW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'SWEZQjsHwV'
      },
      id: '4505092490-10',
      tsUpdate: 1664362116
    },
    {
      poHeader: {
        poNumber: '4505092600',
        vendorCode: 'LMS'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: 'AA0017-010',
        itemQuantity: '560',
        originalGoodsAtConsolidatorDate: '2022-08-29',
        goodsAtConsolidatorReasonCode: '4',
        productName: 'G NSW TEE HILO METALLIC JDI'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'QBiJBeupnr'
      },
      id: '4505092600-10',
      tsUpdate: 1664362116
    },
    {
      poHeader: {
        poNumber: '4505093588',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093588-10',
      tsUpdate: 1664362136
    },
    {
      poHeader: {
        poNumber: '4505093589',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '10',
        productCode: '804395-071',
        itemQuantity: '5000',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'M NSW CLUB PANT OH BB'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'fgHXnJryMI'
      },
      id: '4505093589-10',
      tsUpdate: 1664362136
    },
    {
      poHeader: {
        poNumber: '4505093688',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '500',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093688-10',
      tsUpdate: 1664362136
    },
    {
      poHeader: {
        poNumber: '4505093688',
        trcoPoNumber: '5801947368',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '230',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093688-20',
      tsUpdate: 1664362136
    },
    {
      poHeader: {
        poNumber: '4505093688',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '360',
        originalGoodsAtConsolidatorDate: '2022-09-19',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093688-30',
      tsUpdate: 1664362136
    },
    {
      poHeader: {
        poNumber: '4505093688',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '40',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '480',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093688-40',
      tsUpdate: 1664362136
    },
    {
      poHeader: {
        poNumber: '4505093688',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '50',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '200',
        originalGoodsAtConsolidatorDate: '2022-09-18',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093688-50',
      tsUpdate: 1664362136
    },
    {
      poHeader: {
        poNumber: '4505093688',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '60',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '800',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093688-60',
      tsUpdate: 1664362137
    },
    {
      poHeader: {
        poNumber: '4505093688',
        trcoPoNumber: '5801947368',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '70',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '100',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093688-70',
      tsUpdate: 1664362137
    },
    {
      poHeader: {
        poNumber: '4505093688',
        trcoPoNumber: '5801947368',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '80',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '80',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093688-80',
      tsUpdate: 1664362137
    },
    {
      poHeader: {
        poNumber: '4505093688',
        trcoPoNumber: '5801947368',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '90',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '190',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093688-90',
      tsUpdate: 1664362137
    },
    {
      poHeader: {
        poNumber: '4505093689',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '100',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093689-10',
      tsUpdate: 1664362137
    },
    {
      poHeader: {
        poNumber: '4505093689',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '600',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093689-20',
      tsUpdate: 1664362137
    },
    {
      poHeader: {
        poNumber: '4505093689',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '360',
        originalGoodsAtConsolidatorDate: '2022-09-19',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093689-30',
      tsUpdate: 1664362137
    },
    {
      poHeader: {
        poNumber: '4505093689',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '40',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '400',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093689-40',
      tsUpdate: 1664362137
    },
    {
      poHeader: {
        poNumber: '4505093689',
        vendorCode: 'YS'
      },
      poLine: {
        itemNumber: '50',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '400',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'hJreybPmRO'
      },
      id: '4505093689-50',
      tsUpdate: 1664362138
    },
    {
      poHeader: {
        poNumber: '4505093790',
        vendorCode: 'XG'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '500',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'nuRPOobAAk'
      },
      id: '4505093790-10',
      tsUpdate: 1664362138
    },
    {
      poHeader: {
        poNumber: '4505093791',
        vendorCode: 'XG'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '700',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'nuRPOobAAk'
      },
      id: '4505093791-10',
      tsUpdate: 1664362138
    },
    {
      poHeader: {
        poNumber: '4505093791',
        vendorCode: 'XG'
      },
      poLine: {
        itemNumber: '20',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'nuRPOobAAk'
      },
      id: '4505093791-20',
      tsUpdate: 1664362138
    },
    {
      poHeader: {
        poNumber: '4505093791',
        vendorCode: 'XG'
      },
      poLine: {
        itemNumber: '30',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '25',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'nuRPOobAAk'
      },
      id: '4505093791-30',
      tsUpdate: 1664362138
    },
    {
      poHeader: {
        poNumber: '4505093791',
        vendorCode: 'XG'
      },
      poLine: {
        itemNumber: '40',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-27',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'nuRPOobAAk'
      },
      id: '4505093791-40',
      tsUpdate: 1664362138
    },
    {
      poHeader: {
        poNumber: '4505093794',
        vendorCode: 'XG'
      },
      poLine: {
        itemNumber: '10',
        divisionCode: '20',
        productCode: '812654-014',
        itemQuantity: '2500',
        originalGoodsAtConsolidatorDate: '2022-09-26',
        productName: 'NIKE TANJUN'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'nuRPOobAAk'
      },
      id: '4505093794-10',
      tsUpdate: 1664362138
    },
    {
      poHeader: {
        poNumber: '4508802347',
        vendorCode: 'EGN'
      },
      poLine: {
        itemNumber: '130',
        divisionCode: '30',
        productCode: 'DN2557-004',
        itemQuantity: '295',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'NK ELMNTL PRM CRSSBDY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'GUANG DER GROUP HOLDING CO.,'
      },
      id: '4508802347-130',
      tsUpdate: 1665273391
    },
    {
      poHeader: {
        poNumber: '6000007923',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '100',
        productCode: 'CU5495-102',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'W NK DF GET FIT FL TP PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000007923-100',
      tsUpdate: 1664362173
    },
    {
      poHeader: {
        poNumber: '6000008822',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '100',
        productCode: 'CU5495-102',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'W NK DF GET FIT FL TP PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000008822-100',
      tsUpdate: 1664362174
    },
    {
      poHeader: {
        poNumber: '6000009423',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'CK8103-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LAL M NK TEE CTS LS'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000009423-100',
      tsUpdate: 1665021422
    },
    {
      poHeader: {
        poNumber: '6000011285',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'AT1175-403',
        itemQuantity: '28',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011285-100',
      tsUpdate: 1664362320
    },
    {
      poHeader: {
        poNumber: '6000011288',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'AT1175-403',
        itemQuantity: '28',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011288-100',
      tsUpdate: 1664362329
    },
    {
      poHeader: {
        poNumber: '6000011419',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'AT1175-403',
        itemQuantity: '25',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011419-200',
      tsUpdate: 1664362430
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '10400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '167',
        originalGoodsAtConsolidatorDate: '2022-09-07',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-10400',
      tsUpdate: 1664362457
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '10900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '166',
        originalGoodsAtConsolidatorDate: '2022-09-06',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-10900',
      tsUpdate: 1664362457
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '161',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-11000',
      tsUpdate: 1664362458
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11100',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '162',
        originalGoodsAtConsolidatorDate: '2022-09-02',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-11100',
      tsUpdate: 1664362458
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '163',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-11200',
      tsUpdate: 1664362458
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '164',
        originalGoodsAtConsolidatorDate: '2022-09-04',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-11300',
      tsUpdate: 1664362458
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '165',
        originalGoodsAtConsolidatorDate: '2022-09-05',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-11400',
      tsUpdate: 1664362458
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '180',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-11500',
      tsUpdate: 1664362458
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '179',
        originalGoodsAtConsolidatorDate: '2022-09-19',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-12000',
      tsUpdate: 1664362458
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '178',
        originalGoodsAtConsolidatorDate: '2022-09-18',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-12500',
      tsUpdate: 1664362459
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '177',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-13000',
      tsUpdate: 1664362459
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '176',
        originalGoodsAtConsolidatorDate: '2022-09-16',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-13500',
      tsUpdate: 1664362459
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '185',
        originalGoodsAtConsolidatorDate: '2022-09-25',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-1400',
      tsUpdate: 1664362460
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '14000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '175',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-14000',
      tsUpdate: 1664362461
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '14500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '174',
        originalGoodsAtConsolidatorDate: '2022-09-14',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-14500',
      tsUpdate: 1664362461
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '173',
        originalGoodsAtConsolidatorDate: '2022-09-13',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-15000',
      tsUpdate: 1664362461
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '1900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '184',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-1900',
      tsUpdate: 1664362463
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '2400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '183',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-2400',
      tsUpdate: 1664362464
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '2900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '182',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-2900',
      tsUpdate: 1664362464
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '181',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-3400',
      tsUpdate: 1664362465
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '187',
        originalGoodsAtConsolidatorDate: '2022-09-27',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-400',
      tsUpdate: 1664362465
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '6500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '190',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-6500',
      tsUpdate: 1664362468
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '7000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '189',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-7000',
      tsUpdate: 1664362468
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '7500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '188',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-7500',
      tsUpdate: 1664362468
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '7900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '172',
        originalGoodsAtConsolidatorDate: '2022-09-12',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-7900',
      tsUpdate: 1664362468
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '8400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '171',
        originalGoodsAtConsolidatorDate: '2022-09-11',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-8400',
      tsUpdate: 1664362469
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '8900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '170',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-8900',
      tsUpdate: 1664362469
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '186',
        originalGoodsAtConsolidatorDate: '2022-09-26',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-900',
      tsUpdate: 1664362470
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '9400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '169',
        originalGoodsAtConsolidatorDate: '2022-09-09',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-9400',
      tsUpdate: 1664362471
    },
    {
      poHeader: {
        poNumber: '6000011475',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '9900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '168',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011475-9900',
      tsUpdate: 1664362471
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '10600',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '370',
        originalGoodsAtConsolidatorDate: '2022-09-27',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-10600',
      tsUpdate: 1664362472
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11600',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '372',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-11600',
      tsUpdate: 1664362472
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '346',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-13000',
      tsUpdate: 1664362473
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '348',
        originalGoodsAtConsolidatorDate: '2022-09-16',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-13900',
      tsUpdate: 1664362474
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '364',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-1400',
      tsUpdate: 1664362474
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '14900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '350',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-14900',
      tsUpdate: 1664362474
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '338',
        originalGoodsAtConsolidatorDate: '2022-09-11',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-15400',
      tsUpdate: 1664362474
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '336',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-15500',
      tsUpdate: 1664362474
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '340',
        originalGoodsAtConsolidatorDate: '2022-09-12',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-15700',
      tsUpdate: 1664362475
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15800',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '334',
        originalGoodsAtConsolidatorDate: '2022-09-09',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-15800',
      tsUpdate: 1664362475
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '332',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-16000',
      tsUpdate: 1664362475
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16100',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '330',
        originalGoodsAtConsolidatorDate: '2022-09-07',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-16100',
      tsUpdate: 1664362475
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '328',
        originalGoodsAtConsolidatorDate: '2022-09-06',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-16300',
      tsUpdate: 1664362476
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '326',
        originalGoodsAtConsolidatorDate: '2022-09-05',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-16400',
      tsUpdate: 1664362476
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16600',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '342',
        originalGoodsAtConsolidatorDate: '2022-09-13',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-16600',
      tsUpdate: 1664362476
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '324',
        originalGoodsAtConsolidatorDate: '2022-09-04',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-16700',
      tsUpdate: 1664362476
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '17000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '322',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-17000',
      tsUpdate: 1664362476
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '17200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '320',
        originalGoodsAtConsolidatorDate: '2022-09-02',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-17200',
      tsUpdate: 1664362476
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '17300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '318',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-17300',
      tsUpdate: 1664362476
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '17600',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '344',
        originalGoodsAtConsolidatorDate: '2022-09-14',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-17600',
      tsUpdate: 1664362477
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '18100',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '354',
        originalGoodsAtConsolidatorDate: '2022-09-19',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-18100',
      tsUpdate: 1664362477
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '18500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '352',
        originalGoodsAtConsolidatorDate: '2022-09-18',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-18500',
      tsUpdate: 1664362478
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '2500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '366',
        originalGoodsAtConsolidatorDate: '2022-09-25',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-2500',
      tsUpdate: 1664362479
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3800',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '356',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-3800',
      tsUpdate: 1664362480
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '358',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-4700',
      tsUpdate: 1664362481
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '362',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-500',
      tsUpdate: 1664362481
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '360',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-5700',
      tsUpdate: 1664362482
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '6300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '374',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-6300',
      tsUpdate: 1664362483
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '7300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '376',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-7300',
      tsUpdate: 1664362484
    },
    {
      poHeader: {
        poNumber: '6000011476',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '9700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '368',
        originalGoodsAtConsolidatorDate: '2022-09-26',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011476-9700',
      tsUpdate: 1664362494
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '10500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '98',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-10500',
      tsUpdate: 1664362495
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '10700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '97',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-10700',
      tsUpdate: 1664362496
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '10900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '96',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-10900',
      tsUpdate: 1664362496
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '11200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '95',
        originalGoodsAtConsolidatorDate: '2022-09-27',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-11200',
      tsUpdate: 1664362496
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '11300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '94',
        originalGoodsAtConsolidatorDate: '2022-09-26',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-11300',
      tsUpdate: 1664362497
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '11500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '93',
        originalGoodsAtConsolidatorDate: '2022-09-25',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-11500',
      tsUpdate: 1664362497
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '11800',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '92',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-11800',
      tsUpdate: 1664362497
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '11900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '91',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-11900',
      tsUpdate: 1664362497
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '12100',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '90',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-12100',
      tsUpdate: 1664362497
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '12200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '89',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-12200',
      tsUpdate: 1664362497
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '12400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '88',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-12400',
      tsUpdate: 1664362498
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '7100',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '84',
        originalGoodsAtConsolidatorDate: '2022-09-16',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-7100',
      tsUpdate: 1664362506
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '7200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '83',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-7200',
      tsUpdate: 1664362506
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '8600',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '70',
        originalGoodsAtConsolidatorDate: '2022-09-02',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-8600',
      tsUpdate: 1664362507
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '8700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '76',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-8700',
      tsUpdate: 1664362507
    },
    {
      poHeader: {
        poNumber: '6000011477',
        vendorCode: 'FC'
      },
      poLine: {
        itemNumber: '8800',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '75',
        originalGoodsAtConsolidatorDate: '2022-09-07',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'PANYU PEGASUS FOOTWEAR CO., LTD'
      },
      id: '6000011477-8800',
      tsUpdate: 1664362507
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '10100',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '146',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-10100',
      tsUpdate: 1664362508
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '10200',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '120',
        originalGoodsAtConsolidatorDate: '2022-09-04',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-10200',
      tsUpdate: 1664362508
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '10400',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '121',
        originalGoodsAtConsolidatorDate: '2022-09-05',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-10400',
      tsUpdate: 1664362508
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '10500',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '122',
        originalGoodsAtConsolidatorDate: '2022-09-06',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-10500',
      tsUpdate: 1664362508
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '10700',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '123',
        originalGoodsAtConsolidatorDate: '2022-09-07',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-10700',
      tsUpdate: 1664362508
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '10800',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '124',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-10800',
      tsUpdate: 1664362508
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11000',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '145',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-11000',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11100',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '125',
        originalGoodsAtConsolidatorDate: '2022-09-09',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-11100',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11400',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '126',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-11400',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11500',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '127',
        originalGoodsAtConsolidatorDate: '2022-09-11',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-11500',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11700',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '128',
        originalGoodsAtConsolidatorDate: '2022-09-12',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-11700',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '11900',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '129',
        originalGoodsAtConsolidatorDate: '2022-09-13',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-11900',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12000',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '144',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-12000',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12100',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '130',
        originalGoodsAtConsolidatorDate: '2022-09-14',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-12100',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12300',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '131',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-12300',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12400',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '132',
        originalGoodsAtConsolidatorDate: '2022-09-16',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-12400',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12600',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '133',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-12600',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12900',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '134',
        originalGoodsAtConsolidatorDate: '2022-09-18',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-12900',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13000',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '143',
        originalGoodsAtConsolidatorDate: '2022-09-27',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-13000',
      tsUpdate: 1664362509
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13200',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '135',
        originalGoodsAtConsolidatorDate: '2022-09-19',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-13200',
      tsUpdate: 1664362510
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13300',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '136',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-13300',
      tsUpdate: 1664362510
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13500',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '137',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-13500',
      tsUpdate: 1664362510
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13600',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '138',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-13600',
      tsUpdate: 1664362510
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13800',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '139',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-13800',
      tsUpdate: 1664362510
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13900',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '142',
        originalGoodsAtConsolidatorDate: '2022-09-26',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-13900',
      tsUpdate: 1664362510
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '14100',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '140',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-14100',
      tsUpdate: 1664362510
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '14200',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '141',
        originalGoodsAtConsolidatorDate: '2022-09-25',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-14200',
      tsUpdate: 1664362510
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15000',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '118',
        originalGoodsAtConsolidatorDate: '2022-09-02',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-15000',
      tsUpdate: 1664362511
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15500',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '119',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-15500',
      tsUpdate: 1664362511
    },
    {
      poHeader: {
        poNumber: '6000011478',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16000',
        divisionCode: '10',
        productCode: 'AT1175-420',
        itemQuantity: '117',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS OKC M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011478-16000',
      tsUpdate: 1664362511
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12100',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '24',
        originalGoodsAtConsolidatorDate: '2022-09-05',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-12100',
      tsUpdate: 1664362521
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '12700',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '25',
        originalGoodsAtConsolidatorDate: '2022-09-06',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-12700',
      tsUpdate: 1664362521
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '46',
        originalGoodsAtConsolidatorDate: '2022-09-27',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-1300',
      tsUpdate: 1664362522
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13200',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '26',
        originalGoodsAtConsolidatorDate: '2022-09-07',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-13200',
      tsUpdate: 1664362522
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '13800',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '27',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-13800',
      tsUpdate: 1664362522
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '14500',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-14500',
      tsUpdate: 1664362523
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15200',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '21',
        originalGoodsAtConsolidatorDate: '2022-09-02',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-15200',
      tsUpdate: 1664362523
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15700',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '22',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-15700',
      tsUpdate: 1664362524
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '1600',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '47',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-1600',
      tsUpdate: 1664362524
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16200',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '23',
        originalGoodsAtConsolidatorDate: '2022-09-04',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-16200',
      tsUpdate: 1664362524
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16600',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '32',
        originalGoodsAtConsolidatorDate: '2022-09-13',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-16600',
      tsUpdate: 1664362525
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '17400',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '31',
        originalGoodsAtConsolidatorDate: '2022-09-12',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-17400',
      tsUpdate: 1664362525
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '17500',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '33',
        originalGoodsAtConsolidatorDate: '2022-09-14',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-17500',
      tsUpdate: 1664362525
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '18000',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-11',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-18000',
      tsUpdate: 1664362526
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '18800',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '28',
        originalGoodsAtConsolidatorDate: '2022-09-09',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-18800',
      tsUpdate: 1664362526
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '1900',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '48',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-1900',
      tsUpdate: 1664362526
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19000',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '29',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-19000',
      tsUpdate: 1664362526
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19700',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '34',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-19700',
      tsUpdate: 1664362527
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '43',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-200',
      tsUpdate: 1664362527
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '2300',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '49',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-2300',
      tsUpdate: 1664362527
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3200',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '35',
        originalGoodsAtConsolidatorDate: '2022-09-16',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-3200',
      tsUpdate: 1664362527
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3500',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '36',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-3500',
      tsUpdate: 1664362528
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3900',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '37',
        originalGoodsAtConsolidatorDate: '2022-09-18',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-3900',
      tsUpdate: 1664362528
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4300',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '38',
        originalGoodsAtConsolidatorDate: '2022-09-19',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-4300',
      tsUpdate: 1664362529
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4600',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '39',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-4600',
      tsUpdate: 1664362529
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4900',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '40',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-4900',
      tsUpdate: 1664362529
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '44',
        originalGoodsAtConsolidatorDate: '2022-09-25',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-500',
      tsUpdate: 1664362530
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5300',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '41',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-5300',
      tsUpdate: 1664362530
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5700',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '42',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-5700',
      tsUpdate: 1664362530
    },
    {
      poHeader: {
        poNumber: '6000011479',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'AT1179-495',
        itemQuantity: '45',
        originalGoodsAtConsolidatorDate: '2022-09-26',
        productName: 'AS PHI M NK DRY TEE FNW SP NBR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000011479-900',
      tsUpdate: 1664362533
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '162',
        originalGoodsAtConsolidatorDate: '2022-09-02',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-1000',
      tsUpdate: 1664362534
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '161',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-1200',
      tsUpdate: 1664362536
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '14300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '190',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-14300',
      tsUpdate: 1664362538
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '14500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '189',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-14500',
      tsUpdate: 1664362539
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '14900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '188',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-14900',
      tsUpdate: 1664362539
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '187',
        originalGoodsAtConsolidatorDate: '2022-09-27',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-15200',
      tsUpdate: 1664362539
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '186',
        originalGoodsAtConsolidatorDate: '2022-09-26',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-15500',
      tsUpdate: 1664362539
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '15800',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '185',
        originalGoodsAtConsolidatorDate: '2022-09-25',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-15800',
      tsUpdate: 1664362539
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16100',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '184',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-16100',
      tsUpdate: 1664362540
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '16400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '183',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-16400',
      tsUpdate: 1664362540
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '181',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-19200',
      tsUpdate: 1664362541
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '180',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-19300',
      tsUpdate: 1664362541
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '182',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-19500',
      tsUpdate: 1664362542
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '165',
        originalGoodsAtConsolidatorDate: '2022-09-05',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-200',
      tsUpdate: 1664362542
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '179',
        originalGoodsAtConsolidatorDate: '2022-09-19',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-3700',
      tsUpdate: 1664362544
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '178',
        originalGoodsAtConsolidatorDate: '2022-09-18',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-4000',
      tsUpdate: 1664362544
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '177',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-4200',
      tsUpdate: 1664362544
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '176',
        originalGoodsAtConsolidatorDate: '2022-09-16',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-4500',
      tsUpdate: 1664362544
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '175',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-4700',
      tsUpdate: 1664362544
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '164',
        originalGoodsAtConsolidatorDate: '2022-09-04',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-500',
      tsUpdate: 1664362544
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '174',
        originalGoodsAtConsolidatorDate: '2022-09-14',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-5000',
      tsUpdate: 1664362544
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '173',
        originalGoodsAtConsolidatorDate: '2022-09-13',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-5200',
      tsUpdate: 1664362545
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '172',
        originalGoodsAtConsolidatorDate: '2022-09-12',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-5500',
      tsUpdate: 1664362545
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '171',
        originalGoodsAtConsolidatorDate: '2022-09-11',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-5700',
      tsUpdate: 1664362545
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '6000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '170',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-6000',
      tsUpdate: 1664362545
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '6200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '169',
        originalGoodsAtConsolidatorDate: '2022-09-09',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-6200',
      tsUpdate: 1664362545
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '6500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '168',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-6500',
      tsUpdate: 1664362545
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '6700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '167',
        originalGoodsAtConsolidatorDate: '2022-09-07',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-6700',
      tsUpdate: 1664362545
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '163',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-700',
      tsUpdate: 1664362545
    },
    {
      poHeader: {
        poNumber: '6000011576',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '7000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '166',
        originalGoodsAtConsolidatorDate: '2022-09-06',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000011576-7000',
      tsUpdate: 1664362545
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '18000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '268',
        originalGoodsAtConsolidatorDate: '2022-09-23',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-18000',
      tsUpdate: 1664362566
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '18200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '278',
        originalGoodsAtConsolidatorDate: '2022-09-28',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-18200',
      tsUpdate: 1664362566
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '18400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '264',
        originalGoodsAtConsolidatorDate: '2022-09-21',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-18400',
      tsUpdate: 1664362566
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '18600',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '274',
        originalGoodsAtConsolidatorDate: '2022-09-26',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-18600',
      tsUpdate: 1664362566
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '18800',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '280',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-18800',
      tsUpdate: 1664362567
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '270',
        originalGoodsAtConsolidatorDate: '2022-09-24',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-19000',
      tsUpdate: 1664362567
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '266',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-19300',
      tsUpdate: 1664362567
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '262',
        originalGoodsAtConsolidatorDate: '2022-09-20',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-19400',
      tsUpdate: 1664362567
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '276',
        originalGoodsAtConsolidatorDate: '2022-09-27',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-19500',
      tsUpdate: 1664362567
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19600',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '272',
        originalGoodsAtConsolidatorDate: '2022-09-25',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-19600',
      tsUpdate: 1664362567
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '19900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '282',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-19900',
      tsUpdate: 1664362568
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '228',
        originalGoodsAtConsolidatorDate: '2022-09-03',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-300',
      tsUpdate: 1664362568
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '260',
        originalGoodsAtConsolidatorDate: '2022-09-19',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-3400',
      tsUpdate: 1664362569
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3500',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '258',
        originalGoodsAtConsolidatorDate: '2022-09-18',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-3500',
      tsUpdate: 1664362569
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '256',
        originalGoodsAtConsolidatorDate: '2022-09-17',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-3700',
      tsUpdate: 1664362569
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '3900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '254',
        originalGoodsAtConsolidatorDate: '2022-09-16',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-3900',
      tsUpdate: 1664362569
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '226',
        originalGoodsAtConsolidatorDate: '2022-09-02',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-400',
      tsUpdate: 1664362569
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4100',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '252',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-4100',
      tsUpdate: 1664362570
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '250',
        originalGoodsAtConsolidatorDate: '2022-09-14',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-4300',
      tsUpdate: 1664362570
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '248',
        originalGoodsAtConsolidatorDate: '2022-09-13',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-4400',
      tsUpdate: 1664362570
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '4600',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '246',
        originalGoodsAtConsolidatorDate: '2022-09-12',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-4600',
      tsUpdate: 1664362570
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '244',
        originalGoodsAtConsolidatorDate: '2022-09-11',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-5000',
      tsUpdate: 1664362570
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5100',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '242',
        originalGoodsAtConsolidatorDate: '2022-09-10',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-5100',
      tsUpdate: 1664362570
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5300',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '240',
        originalGoodsAtConsolidatorDate: '2022-09-09',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-5300',
      tsUpdate: 1664362570
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5400',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '238',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-5400',
      tsUpdate: 1664362570
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5600',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '236',
        originalGoodsAtConsolidatorDate: '2022-09-07',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-5600',
      tsUpdate: 1664362570
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '5900',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '234',
        originalGoodsAtConsolidatorDate: '2022-09-06',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-5900',
      tsUpdate: 1664362571
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '6000',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '232',
        originalGoodsAtConsolidatorDate: '2022-09-05',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-6000',
      tsUpdate: 1664362571
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '6200',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '230',
        originalGoodsAtConsolidatorDate: '2022-09-04',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-6200',
      tsUpdate: 1664362571
    },
    {
      poHeader: {
        poNumber: '6000012092',
        vendorCode: 'NYV'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'AT0943-063',
        itemQuantity: '224',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS MIL M NK DRY TEE FNW SP PLR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {
        vendorName: 'Brio Max Limited'
      },
      id: '6000012092-700',
      tsUpdate: 1664362571
    },
    {
      poHeader: {
        poNumber: '6000017674',
        trcoPoNumber: '2500013903',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000017674-100',
      tsUpdate: 1665136996
    },
    {
      poHeader: {
        poNumber: '6000018233',
        trcoPoNumber: '2500013904',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000018233-200',
      tsUpdate: 1665136989
    },
    {
      poHeader: {
        poNumber: '6000018474',
        trcoPoNumber: '2500013811',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '1040',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000018474-100',
      tsUpdate: 1665137004
    },
    {
      poHeader: {
        poNumber: '6000018475',
        trcoPoNumber: '2500013620',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000018475-100',
      tsUpdate: 1665136997
    },
    {
      poHeader: {
        poNumber: '6000018535',
        trcoPoNumber: '2500014502',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '1050',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000018535-100',
      tsUpdate: 1665137047
    },
    {
      poHeader: {
        poNumber: '6000019377',
        trcoPoNumber: '2500013703',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '1040',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000019377-100',
      tsUpdate: 1665137037
    },
    {
      poHeader: {
        poNumber: '6000019378',
        trcoPoNumber: '2500014010',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000019378-300',
      tsUpdate: 1665136999
    },
    {
      poHeader: {
        poNumber: '6000019711',
        trcoPoNumber: '2500014303',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '50',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000019711-100',
      tsUpdate: 1665136998
    },
    {
      poHeader: {
        poNumber: '6000019711',
        trcoPoNumber: '2500014303',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000019711-200',
      tsUpdate: 1665136998
    },
    {
      poHeader: {
        poNumber: '6000019802',
        trcoPoNumber: '2500013923',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '420',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000019802-300',
      tsUpdate: 1665137006
    },
    {
      poHeader: {
        poNumber: '6000019811',
        trcoPoNumber: '2500014118',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '50',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000019811-200',
      tsUpdate: 1665137026
    },
    {
      poHeader: {
        poNumber: '6000019811',
        trcoPoNumber: '2500014118',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000019811-300',
      tsUpdate: 1665137025
    },
    {
      poHeader: {
        poNumber: '6000019903',
        trcoPoNumber: '2500014428',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '50',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000019903-300',
      tsUpdate: 1665137042
    },
    {
      poHeader: {
        poNumber: '6000019903',
        trcoPoNumber: '2500014428',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000019903-400',
      tsUpdate: 1665137040
    },
    {
      poHeader: {
        poNumber: '6000020104',
        trcoPoNumber: '2500013845',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '420',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000020104-300',
      tsUpdate: 1665137021
    },
    {
      poHeader: {
        poNumber: '6000020410',
        trcoPoNumber: '2500014434',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '200',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000020410-300',
      tsUpdate: 1665137016
    },
    {
      poHeader: {
        poNumber: '6000020505',
        trcoPoNumber: '2500013644',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000020505-300',
      tsUpdate: 1665136994
    },
    {
      poHeader: {
        poNumber: '6000020506',
        trcoPoNumber: '2500014233',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '50',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000020506-300',
      tsUpdate: 1665137010
    },
    {
      poHeader: {
        poNumber: '6000020506',
        trcoPoNumber: '2500014233',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000020506-400',
      tsUpdate: 1665137009
    },
    {
      poHeader: {
        poNumber: '6000020508',
        trcoPoNumber: '2500013844',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '50',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000020508-300',
      tsUpdate: 1665137000
    },
    {
      poHeader: {
        poNumber: '6000020508',
        trcoPoNumber: '2500013844',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000020508-400',
      tsUpdate: 1665137000
    },
    {
      poHeader: {
        poNumber: '6000020700',
        trcoPoNumber: '2500014543',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000020700-300',
      tsUpdate: 1665137032
    },
    {
      poHeader: {
        poNumber: '6000020701',
        trcoPoNumber: '2500014237',
        vendorCode: 'VF'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '20',
        productCode: 'CW2289-111',
        itemQuantity: '50',
        originalGoodsAtConsolidatorDate: '2022-09-30',
        productName: 'AIR FORCE 1 MID 07'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000020701-300',
      tsUpdate: 1665137029
    },
    {
      poHeader: {
        poNumber: '6000114183',
        vendorCode: 'VJ'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '20',
        productCode: 'DH2512-100',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-25',
        productName: 'WMNS NIKE ESCAPE RN CZY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114183-100',
      tsUpdate: 1664940528
    },
    {
      poHeader: {
        poNumber: '6000114301',
        trcoPoNumber: '2500092805',
        vendorCode: 'AGH'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM8272-010',
        itemQuantity: '120',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'JORDAN BEANIE UTILITY METAL JM'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114301-100',
      tsUpdate: 1665138105
    },
    {
      poHeader: {
        poNumber: '6000114302',
        trcoPoNumber: '2500092913',
        vendorCode: 'AGH'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM8272-010',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'JORDAN BEANIE UTILITY METAL JM'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114302-100',
      tsUpdate: 1665138090
    },
    {
      poHeader: {
        poNumber: '6000114303',
        trcoPoNumber: '2500093804',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM4706-010',
        itemQuantity: '80',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'U NRG ACG CUFFED BEANIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114303-100',
      tsUpdate: 1665137250
    },
    {
      poHeader: {
        poNumber: '6000114303',
        trcoPoNumber: '2500093804',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DM4706-222',
        itemQuantity: '47',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'U NRG ACG CUFFED BEANIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114303-200',
      tsUpdate: 1664803149
    },
    {
      poHeader: {
        poNumber: '6000114304',
        trcoPoNumber: '2500092722',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM8310-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'U NSW BEANIE UTILITY PATCH'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114304-100',
      tsUpdate: 1664547868
    },
    {
      poHeader: {
        poNumber: '6000114304',
        trcoPoNumber: '2500092722',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DM8310-087',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'U NSW BEANIE UTILITY PATCH'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114304-200',
      tsUpdate: 1664548121
    },
    {
      poHeader: {
        poNumber: '6000114304',
        trcoPoNumber: '2500092722',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DM8310-246',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'U NSW BEANIE UTILITY PATCH'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114304-300',
      tsUpdate: 1664545094
    },
    {
      poHeader: {
        poNumber: '6000114304',
        trcoPoNumber: '2500092722',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DM8310-326',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'U NSW BEANIE UTILITY PATCH'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114304-400',
      tsUpdate: 1664547562
    },
    {
      poHeader: {
        poNumber: '6000114306',
        trcoPoNumber: '2500093306',
        vendorCode: 'CSC'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD6628-011',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP ENG SWEATER AOJ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114306-100',
      tsUpdate: 1664367034
    },
    {
      poHeader: {
        poNumber: '6000114306',
        trcoPoNumber: '2500093306',
        vendorCode: 'CSC'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD6628-011',
        itemQuantity: '42',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP ENG SWEATER AOJ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114306-200',
      tsUpdate: 1664367034
    },
    {
      poHeader: {
        poNumber: '6000114306',
        trcoPoNumber: '2500093306',
        vendorCode: 'CSC'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD6628-011',
        itemQuantity: '99',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP ENG SWEATER AOJ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114306-300',
      tsUpdate: 1664367035
    },
    {
      poHeader: {
        poNumber: '6000114306',
        trcoPoNumber: '2500093306',
        vendorCode: 'CSC'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD6628-011',
        itemQuantity: '72',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP ENG SWEATER AOJ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114306-400',
      tsUpdate: 1664367034
    },
    {
      poHeader: {
        poNumber: '6000114306',
        trcoPoNumber: '2500093306',
        vendorCode: 'CSC'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD6628-011',
        itemQuantity: '23',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP ENG SWEATER AOJ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114306-500',
      tsUpdate: 1664367036
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '478',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-100',
      tsUpdate: 1664361118
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-1000',
      tsUpdate: 1664361119
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '514',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-200',
      tsUpdate: 1664361121
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '183',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-300',
      tsUpdate: 1664361124
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '258',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-400',
      tsUpdate: 1664361122
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-500',
      tsUpdate: 1664361124
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '227',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-600',
      tsUpdate: 1664361135
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '249',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-700',
      tsUpdate: 1664361134
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '101',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-800',
      tsUpdate: 1664361137
    },
    {
      poHeader: {
        poNumber: '6000114307',
        trcoPoNumber: '2500093109',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '124',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114307-900',
      tsUpdate: 1664361136
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-100',
      tsUpdate: 1665135855
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DC9143-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-1000',
      tsUpdate: 1665137523
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DC9143-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-1100',
      tsUpdate: 1665136819
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DC9143-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-1200',
      tsUpdate: 1665136821
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DC9143-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-1300',
      tsUpdate: 1665136824
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DC9143-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-1400',
      tsUpdate: 1665136823
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'DC9143-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-1500',
      tsUpdate: 1665136822
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-200',
      tsUpdate: 1665135857
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-300',
      tsUpdate: 1665135857
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-400',
      tsUpdate: 1665135857
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-500',
      tsUpdate: 1665135858
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DC9143-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-600',
      tsUpdate: 1665137526
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DC9143-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-700',
      tsUpdate: 1665137529
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DC9143-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-800',
      tsUpdate: 1665137525
    },
    {
      poHeader: {
        poNumber: '6000114308',
        trcoPoNumber: '2500093310',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DC9143-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114308-900',
      tsUpdate: 1665137528
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC9154-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-100',
      tsUpdate: 1664846617
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DC9154-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-1000',
      tsUpdate: 1664846645
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DC9154-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-1100',
      tsUpdate: 1664846511
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DC9154-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-1200',
      tsUpdate: 1664846522
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DC9154-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-1300',
      tsUpdate: 1664846528
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DC9154-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-1400',
      tsUpdate: 1664846521
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'DC9154-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-1500',
      tsUpdate: 1664846523
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DC9154-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-200',
      tsUpdate: 1664846601
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DC9154-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-300',
      tsUpdate: 1664846636
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DC9154-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-400',
      tsUpdate: 1664846634
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DC9154-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-500',
      tsUpdate: 1664846618
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DC9154-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-600',
      tsUpdate: 1664846646
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DC9154-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-700',
      tsUpdate: 1664846646
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DC9154-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-800',
      tsUpdate: 1664846647
    },
    {
      poHeader: {
        poNumber: '6000114309',
        trcoPoNumber: '2500093402',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DC9154-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114309-900',
      tsUpdate: 1664846646
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC9158-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-100',
      tsUpdate: 1664846221
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DC9158-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-1000',
      tsUpdate: 1664846152
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DC9158-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-200',
      tsUpdate: 1664846224
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DC9158-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-300',
      tsUpdate: 1664846222
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DC9158-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-400',
      tsUpdate: 1664846224
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DC9158-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-500',
      tsUpdate: 1664846212
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DC9158-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-600',
      tsUpdate: 1664846155
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DC9158-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-700',
      tsUpdate: 1664846159
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DC9158-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-800',
      tsUpdate: 1664846156
    },
    {
      poHeader: {
        poNumber: '6000114310',
        trcoPoNumber: '2500092713',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DC9158-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114310-900',
      tsUpdate: 1664846169
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC9169-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-100',
      tsUpdate: 1665138109
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-1000',
      tsUpdate: 1665134782
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-1100',
      tsUpdate: 1665137792
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-1200',
      tsUpdate: 1665137793
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-1300',
      tsUpdate: 1665137793
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-1400',
      tsUpdate: 1665137794
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-1500',
      tsUpdate: 1665137795
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DC9169-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-200',
      tsUpdate: 1665138114
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DC9169-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-300',
      tsUpdate: 1665138111
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DC9169-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-400',
      tsUpdate: 1665138113
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DC9169-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-500',
      tsUpdate: 1665138113
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-600',
      tsUpdate: 1665134786
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-700',
      tsUpdate: 1665134789
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-800',
      tsUpdate: 1665134784
    },
    {
      poHeader: {
        poNumber: '6000114311',
        trcoPoNumber: '2500093307',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114311-900',
      tsUpdate: 1665134787
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-100',
      tsUpdate: 1664805183
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-1000',
      tsUpdate: 1664804877
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-200',
      tsUpdate: 1664805182
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-300',
      tsUpdate: 1664805185
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-400',
      tsUpdate: 1664805171
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-500',
      tsUpdate: 1664805172
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-600',
      tsUpdate: 1664804922
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '13',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-700',
      tsUpdate: 1664804887
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-800',
      tsUpdate: 1664804920
    },
    {
      poHeader: {
        poNumber: '6000114312',
        trcoPoNumber: '2500093811',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114312-900',
      tsUpdate: 1664804876
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-100',
      tsUpdate: 1664358990
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD1459-357',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-1000',
      tsUpdate: 1664357906
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-1100',
      tsUpdate: 1664361148
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '14',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-1200',
      tsUpdate: 1664361147
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-1300',
      tsUpdate: 1664361148
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-1400',
      tsUpdate: 1664361148
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-1500',
      tsUpdate: 1664361157
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-200',
      tsUpdate: 1664358991
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-300',
      tsUpdate: 1664359002
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-400',
      tsUpdate: 1664359001
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-500',
      tsUpdate: 1664359004
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD1459-357',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-600',
      tsUpdate: 1664357909
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD1459-357',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-700',
      tsUpdate: 1664357906
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD1459-357',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-800',
      tsUpdate: 1664357908
    },
    {
      poHeader: {
        poNumber: '6000114313',
        trcoPoNumber: '2500093710',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD1459-357',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114313-900',
      tsUpdate: 1664357918
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-100',
      tsUpdate: 1664360048
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-1000',
      tsUpdate: 1664359757
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-200',
      tsUpdate: 1664360051
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-300',
      tsUpdate: 1664360050
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-400',
      tsUpdate: 1664360050
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-500',
      tsUpdate: 1664360049
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-600',
      tsUpdate: 1664359775
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-700',
      tsUpdate: 1664359784
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-800',
      tsUpdate: 1664359789
    },
    {
      poHeader: {
        poNumber: '6000114314',
        trcoPoNumber: '2500093504',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114314-900',
      tsUpdate: 1664359792
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-100',
      tsUpdate: 1664878512
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-1000',
      tsUpdate: 1664877740
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-200',
      tsUpdate: 1664878522
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-300',
      tsUpdate: 1664878545
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-400',
      tsUpdate: 1664878533
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-500',
      tsUpdate: 1664878543
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-600',
      tsUpdate: 1664877748
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-700',
      tsUpdate: 1664877746
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-800',
      tsUpdate: 1664877748
    },
    {
      poHeader: {
        poNumber: '6000114315',
        trcoPoNumber: '2500093808',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114315-900',
      tsUpdate: 1664877747
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-100',
      tsUpdate: 1665136090
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-1000',
      tsUpdate: 1665136568
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-1100',
      tsUpdate: 1665137857
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-1200',
      tsUpdate: 1665137857
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-1300',
      tsUpdate: 1665137857
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-1400',
      tsUpdate: 1665137858
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-1500',
      tsUpdate: 1665137858
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-200',
      tsUpdate: 1665136092
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-300',
      tsUpdate: 1665136091
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-400',
      tsUpdate: 1665136093
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-500',
      tsUpdate: 1665136091
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-600',
      tsUpdate: 1665136569
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-700',
      tsUpdate: 1665136569
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-800',
      tsUpdate: 1665136570
    },
    {
      poHeader: {
        poNumber: '6000114316',
        trcoPoNumber: '2500093809',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114316-900',
      tsUpdate: 1665136569
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5468-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-100',
      tsUpdate: 1664875749
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-1000',
      tsUpdate: 1664876238
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5468-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-200',
      tsUpdate: 1664875749
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5468-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-300',
      tsUpdate: 1664875749
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5468-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-400',
      tsUpdate: 1664875750
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5468-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-500',
      tsUpdate: 1664875750
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-600',
      tsUpdate: 1664876250
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-700',
      tsUpdate: 1664876247
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-800',
      tsUpdate: 1664876239
    },
    {
      poHeader: {
        poNumber: '6000114317',
        trcoPoNumber: '2500092508',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114317-900',
      tsUpdate: 1664876245
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-100',
      tsUpdate: 1664633524
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-1000',
      tsUpdate: 1664631249
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-200',
      tsUpdate: 1664633536
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-300',
      tsUpdate: 1664633557
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-400',
      tsUpdate: 1664633538
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-500',
      tsUpdate: 1664633555
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-600',
      tsUpdate: 1664631325
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-700',
      tsUpdate: 1664631343
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-800',
      tsUpdate: 1664631294
    },
    {
      poHeader: {
        poNumber: '6000114318',
        trcoPoNumber: '2500094008',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114318-900',
      tsUpdate: 1664631256
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-100',
      tsUpdate: 1662843382
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-1000',
      tsUpdate: 1662838138
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-1100',
      tsUpdate: 1662172488
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-1200',
      tsUpdate: 1662172477
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-1300',
      tsUpdate: 1662172481
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-1400',
      tsUpdate: 1662172493
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-1500',
      tsUpdate: 1662172512
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-200',
      tsUpdate: 1662843393
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-300',
      tsUpdate: 1662843402
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-400',
      tsUpdate: 1662843390
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-500',
      tsUpdate: 1662843406
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-600',
      tsUpdate: 1662838130
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-700',
      tsUpdate: 1662838136
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-800',
      tsUpdate: 1662838133
    },
    {
      poHeader: {
        poNumber: '6000114319',
        trcoPoNumber: '2500093215',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114319-900',
      tsUpdate: 1662838134
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM2884-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-100',
      tsUpdate: 1664457618
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DM2884-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-1000',
      tsUpdate: 1664457504
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DM2884-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-200',
      tsUpdate: 1664457625
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DM2884-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-300',
      tsUpdate: 1664457626
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DM2884-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-400',
      tsUpdate: 1664457614
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DM2884-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-500',
      tsUpdate: 1664457619
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DM2884-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-600',
      tsUpdate: 1664457505
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DM2884-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-700',
      tsUpdate: 1664457503
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DM2884-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-800',
      tsUpdate: 1664457503
    },
    {
      poHeader: {
        poNumber: '6000114320',
        trcoPoNumber: '2500092716',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DM2884-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114320-900',
      tsUpdate: 1664457504
    },
    {
      poHeader: {
        poNumber: '6000114324',
        trcoPoNumber: '2500093905',
        vendorCode: 'DDV'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM8271-133',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'JORDAN AW84 MT SIDE CAP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114324-100',
      tsUpdate: 1663325440
    },
    {
      poHeader: {
        poNumber: '6000114326',
        trcoPoNumber: '2500093812',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD6818-010',
        itemQuantity: '26',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114326-100',
      tsUpdate: 1664368223
    },
    {
      poHeader: {
        poNumber: '6000114326',
        trcoPoNumber: '2500093812',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD6818-010',
        itemQuantity: '75',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114326-200',
      tsUpdate: 1664368223
    },
    {
      poHeader: {
        poNumber: '6000114326',
        trcoPoNumber: '2500093812',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD6818-010',
        itemQuantity: '73',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114326-300',
      tsUpdate: 1664368214
    },
    {
      poHeader: {
        poNumber: '6000114326',
        trcoPoNumber: '2500093812',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD6818-010',
        itemQuantity: '21',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114326-400',
      tsUpdate: 1664368224
    },
    {
      poHeader: {
        poNumber: '6000114326',
        trcoPoNumber: '2500093812',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD6818-010',
        itemQuantity: '53',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114326-500',
      tsUpdate: 1664368223
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '21',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-100',
      tsUpdate: 1664373526
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '16',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-1000',
      tsUpdate: 1664373238
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-1100',
      tsUpdate: 1664373222
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '39',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-1200',
      tsUpdate: 1664373236
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '16',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-200',
      tsUpdate: 1664373525
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '21',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-300',
      tsUpdate: 1664373526
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '16',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-400',
      tsUpdate: 1664373526
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-500',
      tsUpdate: 1664373527
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '39',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-600',
      tsUpdate: 1664373527
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '21',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-700',
      tsUpdate: 1664373238
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '16',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-800',
      tsUpdate: 1664373239
    },
    {
      poHeader: {
        poNumber: '6000114327',
        trcoPoNumber: '2500093708',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '21',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114327-900',
      tsUpdate: 1664373238
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DO2966-010',
        itemQuantity: '45',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-100',
      tsUpdate: 1659450716
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DO2966-379',
        itemQuantity: '77',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-1000',
      tsUpdate: 1659449740
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DO2966-010',
        itemQuantity: '169',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-200',
      tsUpdate: 1659450720
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DO2966-010',
        itemQuantity: '147',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-300',
      tsUpdate: 1659450720
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DO2966-010',
        itemQuantity: '42',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-400',
      tsUpdate: 1659450719
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DO2966-010',
        itemQuantity: '91',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-500',
      tsUpdate: 1659450720
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DO2966-379',
        itemQuantity: '38',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-600',
      tsUpdate: 1659449743
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DO2966-379',
        itemQuantity: '102',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-700',
      tsUpdate: 1659449742
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DO2966-379',
        itemQuantity: '96',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-800',
      tsUpdate: 1659449742
    },
    {
      poHeader: {
        poNumber: '6000114328',
        trcoPoNumber: '2500092820',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DO2966-379',
        itemQuantity: '36',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114328-900',
      tsUpdate: 1659449742
    },
    {
      poHeader: {
        poNumber: '6000114329',
        trcoPoNumber: '2500092707',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '16',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114329-100',
      tsUpdate: 1664636995
    },
    {
      poHeader: {
        poNumber: '6000114329',
        trcoPoNumber: '2500092707',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '29',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114329-200',
      tsUpdate: 1664636993
    },
    {
      poHeader: {
        poNumber: '6000114329',
        trcoPoNumber: '2500092707',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '32',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114329-300',
      tsUpdate: 1664636989
    },
    {
      poHeader: {
        poNumber: '6000114329',
        trcoPoNumber: '2500092707',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '29',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114329-400',
      tsUpdate: 1664637013
    },
    {
      poHeader: {
        poNumber: '6000114329',
        trcoPoNumber: '2500092707',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '16',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114329-500',
      tsUpdate: 1664637019
    },
    {
      poHeader: {
        poNumber: '6000114330',
        trcoPoNumber: '2500093305',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114330-100',
      tsUpdate: 1664311995
    },
    {
      poHeader: {
        poNumber: '6000114330',
        trcoPoNumber: '2500093305',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114330-200',
      tsUpdate: 1664311994
    },
    {
      poHeader: {
        poNumber: '6000114330',
        trcoPoNumber: '2500093305',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114330-300',
      tsUpdate: 1664311999
    },
    {
      poHeader: {
        poNumber: '6000114330',
        trcoPoNumber: '2500093305',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114330-400',
      tsUpdate: 1664311999
    },
    {
      poHeader: {
        poNumber: '6000114330',
        trcoPoNumber: '2500093305',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114330-500',
      tsUpdate: 1664311999
    },
    {
      poHeader: {
        poNumber: '6000114330',
        trcoPoNumber: '2500093305',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114330-600',
      tsUpdate: 1664312000
    },
    {
      poHeader: {
        poNumber: '6000114330',
        trcoPoNumber: '2500093305',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114330-700',
      tsUpdate: 1664311997
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-100',
      tsUpdate: 1664362114
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-1000',
      tsUpdate: 1664362101
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-200',
      tsUpdate: 1664362110
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-300',
      tsUpdate: 1664362135
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-400',
      tsUpdate: 1664362105
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-500',
      tsUpdate: 1664362115
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-600',
      tsUpdate: 1664362107
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-700',
      tsUpdate: 1664362115
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-800',
      tsUpdate: 1664362110
    },
    {
      poHeader: {
        poNumber: '6000114334',
        trcoPoNumber: '2500093208',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114334-900',
      tsUpdate: 1664362113
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-100',
      tsUpdate: 1664367614
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-1000',
      tsUpdate: 1664367913
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-200',
      tsUpdate: 1664367614
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-300',
      tsUpdate: 1664367613
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-400',
      tsUpdate: 1664367615
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-500',
      tsUpdate: 1664367616
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-600',
      tsUpdate: 1664367914
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '16',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-700',
      tsUpdate: 1664367914
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-800',
      tsUpdate: 1664367913
    },
    {
      poHeader: {
        poNumber: '6000114335',
        trcoPoNumber: '2500093505',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114335-900',
      tsUpdate: 1664367924
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-100',
      tsUpdate: 1664373545
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-1000',
      tsUpdate: 1664373306
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-200',
      tsUpdate: 1664373544
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-300',
      tsUpdate: 1664373544
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-400',
      tsUpdate: 1664373545
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-500',
      tsUpdate: 1664373544
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-600',
      tsUpdate: 1664373307
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-700',
      tsUpdate: 1664373306
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-800',
      tsUpdate: 1664373307
    },
    {
      poHeader: {
        poNumber: '6000114337',
        trcoPoNumber: '2500092612',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114337-900',
      tsUpdate: 1664373307
    },
    {
      poHeader: {
        poNumber: '6000114338',
        trcoPoNumber: '2500093112',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114338-100',
      tsUpdate: 1662843981
    },
    {
      poHeader: {
        poNumber: '6000114338',
        trcoPoNumber: '2500093112',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114338-200',
      tsUpdate: 1662843993
    },
    {
      poHeader: {
        poNumber: '6000114338',
        trcoPoNumber: '2500093112',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114338-300',
      tsUpdate: 1662843996
    },
    {
      poHeader: {
        poNumber: '6000114338',
        trcoPoNumber: '2500093112',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114338-400',
      tsUpdate: 1662843988
    },
    {
      poHeader: {
        poNumber: '6000114339',
        trcoPoNumber: '2500093611',
        vendorCode: 'EAV'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DJ4658-010',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS NO W NKCT NJC BOMBER JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114339-100',
      tsUpdate: 1664548021
    },
    {
      poHeader: {
        poNumber: '6000114339',
        trcoPoNumber: '2500093611',
        vendorCode: 'EAV'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DJ4658-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS NO W NKCT NJC BOMBER JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114339-200',
      tsUpdate: 1664548021
    },
    {
      poHeader: {
        poNumber: '6000114339',
        trcoPoNumber: '2500093611',
        vendorCode: 'EAV'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DJ4658-010',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS NO W NKCT NJC BOMBER JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114339-300',
      tsUpdate: 1664548019
    },
    {
      poHeader: {
        poNumber: '6000114339',
        trcoPoNumber: '2500093611',
        vendorCode: 'EAV'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DJ4658-010',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS NO W NKCT NJC BOMBER JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114339-400',
      tsUpdate: 1664548020
    },
    {
      poHeader: {
        poNumber: '6000114339',
        trcoPoNumber: '2500093611',
        vendorCode: 'EAV'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DJ4658-072',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS NO W NKCT NJC BOMBER JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114339-500',
      tsUpdate: 1664545478
    },
    {
      poHeader: {
        poNumber: '6000114339',
        trcoPoNumber: '2500093611',
        vendorCode: 'EAV'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DJ4658-072',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS NO W NKCT NJC BOMBER JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114339-600',
      tsUpdate: 1664545494
    },
    {
      poHeader: {
        poNumber: '6000114339',
        trcoPoNumber: '2500093611',
        vendorCode: 'EAV'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DJ4658-072',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS NO W NKCT NJC BOMBER JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114339-700',
      tsUpdate: 1664545492
    },
    {
      poHeader: {
        poNumber: '6000114339',
        trcoPoNumber: '2500093611',
        vendorCode: 'EAV'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DJ4658-072',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS NO W NKCT NJC BOMBER JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114339-800',
      tsUpdate: 1664545455
    },
    {
      poHeader: {
        poNumber: '6000114340',
        trcoPoNumber: '2500093411',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'BV9388-010',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M NK DF TOP SL CRSSOVER SL'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114340-1000',
      tsUpdate: 1664099131
    },
    {
      poHeader: {
        poNumber: '6000114340',
        trcoPoNumber: '2500093411',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'BV9388-010',
        itemQuantity: '72',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M NK DF TOP SL CRSSOVER SL'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114340-1100',
      tsUpdate: 1664099130
    },
    {
      poHeader: {
        poNumber: '6000114340',
        trcoPoNumber: '2500093411',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'BV9388-010',
        itemQuantity: '58',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M NK DF TOP SL CRSSOVER SL'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114340-1200',
      tsUpdate: 1664099130
    },
    {
      poHeader: {
        poNumber: '6000114340',
        trcoPoNumber: '2500093411',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'BV9388-010',
        itemQuantity: '17',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M NK DF TOP SL CRSSOVER SL'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114340-1300',
      tsUpdate: 1664099132
    },
    {
      poHeader: {
        poNumber: '6000114340',
        trcoPoNumber: '2500093411',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'BV9388-010',
        itemQuantity: '61',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M NK DF TOP SL CRSSOVER SL'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114340-1400',
      tsUpdate: 1664099131
    },
    {
      poHeader: {
        poNumber: '6000114340',
        trcoPoNumber: '2500093411',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'BV9388-010',
        itemQuantity: '39',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M NK DF TOP SL CRSSOVER SL'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114340-900',
      tsUpdate: 1664099129
    },
    {
      poHeader: {
        poNumber: '6000114342',
        trcoPoNumber: '2500093606',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '107',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114342-100',
      tsUpdate: 1664804852
    },
    {
      poHeader: {
        poNumber: '6000114342',
        trcoPoNumber: '2500093606',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '38',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114342-200',
      tsUpdate: 1664804879
    },
    {
      poHeader: {
        poNumber: '6000114342',
        trcoPoNumber: '2500093606',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '13',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114342-300',
      tsUpdate: 1664804856
    },
    {
      poHeader: {
        poNumber: '6000114342',
        trcoPoNumber: '2500093606',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '204',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114342-400',
      tsUpdate: 1664804849
    },
    {
      poHeader: {
        poNumber: '6000114342',
        trcoPoNumber: '2500093606',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '174',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114342-500',
      tsUpdate: 1664804875
    },
    {
      poHeader: {
        poNumber: '6000114342',
        trcoPoNumber: '2500093606',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '95',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114342-600',
      tsUpdate: 1664804867
    },
    {
      poHeader: {
        poNumber: '6000114342',
        trcoPoNumber: '2500093606',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '159',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114342-700',
      tsUpdate: 1664804866
    },
    {
      poHeader: {
        poNumber: '6000114342',
        trcoPoNumber: '2500093606',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114342-800',
      tsUpdate: 1664804876
    },
    {
      poHeader: {
        poNumber: '6000114344',
        trcoPoNumber: '2500092719',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD1975-289',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STORY HD FZ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114344-100',
      tsUpdate: 1664878729
    },
    {
      poHeader: {
        poNumber: '6000114344',
        trcoPoNumber: '2500092719',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD1975-289',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STORY HD FZ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114344-200',
      tsUpdate: 1664878754
    },
    {
      poHeader: {
        poNumber: '6000114344',
        trcoPoNumber: '2500092719',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD1975-289',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STORY HD FZ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114344-300',
      tsUpdate: 1664878728
    },
    {
      poHeader: {
        poNumber: '6000114344',
        trcoPoNumber: '2500092719',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD1975-289',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STORY HD FZ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114344-400',
      tsUpdate: 1664878730
    },
    {
      poHeader: {
        poNumber: '6000114344',
        trcoPoNumber: '2500092719',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD1975-289',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STORY HD FZ'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114344-500',
      tsUpdate: 1664878750
    },
    {
      poHeader: {
        poNumber: '6000114345',
        trcoPoNumber: '2500092609',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114345-100',
      tsUpdate: 1664804754
    },
    {
      poHeader: {
        poNumber: '6000114345',
        trcoPoNumber: '2500092609',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114345-200',
      tsUpdate: 1664804737
    },
    {
      poHeader: {
        poNumber: '6000114345',
        trcoPoNumber: '2500092609',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114345-300',
      tsUpdate: 1664804760
    },
    {
      poHeader: {
        poNumber: '6000114345',
        trcoPoNumber: '2500092609',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114345-400',
      tsUpdate: 1664804748
    },
    {
      poHeader: {
        poNumber: '6000114345',
        trcoPoNumber: '2500092609',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114345-500',
      tsUpdate: 1664804759
    },
    {
      poHeader: {
        poNumber: '6000114345',
        trcoPoNumber: '2500092609',
        vendorCode: 'FEC'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DH1111-133',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS STMT MNTN FLC PO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114345-600',
      tsUpdate: 1664804762
    },
    {
      poHeader: {
        poNumber: '6000114346',
        trcoPoNumber: '2500093007',
        vendorCode: 'FVN'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DJ5832-816',
        itemQuantity: '29',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'G NSW JACKET WINTERIZED'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114346-100',
      tsUpdate: 1664631587
    },
    {
      poHeader: {
        poNumber: '6000114346',
        trcoPoNumber: '2500093007',
        vendorCode: 'FVN'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DJ5832-816',
        itemQuantity: '19',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'G NSW JACKET WINTERIZED'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114346-200',
      tsUpdate: 1664631586
    },
    {
      poHeader: {
        poNumber: '6000114346',
        trcoPoNumber: '2500093007',
        vendorCode: 'FVN'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DJ5832-816',
        itemQuantity: '19',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'G NSW JACKET WINTERIZED'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114346-300',
      tsUpdate: 1664631589
    },
    {
      poHeader: {
        poNumber: '6000114346',
        trcoPoNumber: '2500093007',
        vendorCode: 'FVN'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DJ5832-816',
        itemQuantity: '29',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'G NSW JACKET WINTERIZED'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114346-400',
      tsUpdate: 1664631557
    },
    {
      poHeader: {
        poNumber: '6000114346',
        trcoPoNumber: '2500093007',
        vendorCode: 'FVN'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DJ5832-816',
        itemQuantity: '29',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'G NSW JACKET WINTERIZED'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114346-500',
      tsUpdate: 1664631585
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC9160-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-100',
      tsUpdate: 1664457308
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DC9160-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-1000',
      tsUpdate: 1664457412
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DC9160-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-200',
      tsUpdate: 1664457309
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DC9160-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-300',
      tsUpdate: 1664457303
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DC9160-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-400',
      tsUpdate: 1664457302
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DC9160-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-500',
      tsUpdate: 1664457306
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DC9160-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-600',
      tsUpdate: 1664457403
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DC9160-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-700',
      tsUpdate: 1664457413
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DC9160-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-800',
      tsUpdate: 1664457416
    },
    {
      poHeader: {
        poNumber: '6000114347',
        trcoPoNumber: '2500093818',
        vendorCode: 'HSE'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DC9160-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NK TF STRIKE PANT KWPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114347-900',
      tsUpdate: 1664457415
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '282',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-100',
      tsUpdate: 1664252372
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '77',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-1000',
      tsUpdate: 1664252069
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '13',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-1100',
      tsUpdate: 1664252071
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '575',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-1200',
      tsUpdate: 1664252071
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '457',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-1300',
      tsUpdate: 1664252070
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '128',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-1400',
      tsUpdate: 1664252071
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '453',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-1500',
      tsUpdate: 1664252070
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '1600',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-1600',
      tsUpdate: 1664252072
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '69',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-200',
      tsUpdate: 1664252381
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '18',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-300',
      tsUpdate: 1664252371
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '746',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-400',
      tsUpdate: 1664252371
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '609',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-500',
      tsUpdate: 1664252364
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '177',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-600',
      tsUpdate: 1664252370
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '517',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-700',
      tsUpdate: 1664252371
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-800',
      tsUpdate: 1664252381
    },
    {
      poHeader: {
        poNumber: '6000114348',
        trcoPoNumber: '2500093114',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '278',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114348-900',
      tsUpdate: 1664252072
    },
    {
      poHeader: {
        poNumber: '6000114349',
        trcoPoNumber: '2500092518',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114349-100',
      tsUpdate: 1664252361
    },
    {
      poHeader: {
        poNumber: '6000114349',
        trcoPoNumber: '2500092518',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114349-200',
      tsUpdate: 1664252354
    },
    {
      poHeader: {
        poNumber: '6000114349',
        trcoPoNumber: '2500092518',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'CV3087-011',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114349-300',
      tsUpdate: 1664252336
    },
    {
      poHeader: {
        poNumber: '6000114349',
        trcoPoNumber: '2500092518',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114349-400',
      tsUpdate: 1664252068
    },
    {
      poHeader: {
        poNumber: '6000114349',
        trcoPoNumber: '2500092518',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114349-500',
      tsUpdate: 1664252067
    },
    {
      poHeader: {
        poNumber: '6000114349',
        trcoPoNumber: '2500092518',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114349-600',
      tsUpdate: 1664252069
    },
    {
      poHeader: {
        poNumber: '6000114349',
        trcoPoNumber: '2500092518',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114349-700',
      tsUpdate: 1664252068
    },
    {
      poHeader: {
        poNumber: '6000114349',
        trcoPoNumber: '2500092518',
        vendorCode: 'HTV'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'CV3087-687',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS M J DF AIR DIAMOND SHORT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114349-800',
      tsUpdate: 1664252069
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD2187-010',
        itemQuantity: '20',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-100',
      tsUpdate: 1664804376
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '29',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-1000',
      tsUpdate: 1664803800
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '14',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-1100',
      tsUpdate: 1664803799
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '33',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-1200',
      tsUpdate: 1664803801
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD2187-010',
        itemQuantity: '34',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-200',
      tsUpdate: 1664804389
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD2187-010',
        itemQuantity: '26',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-300',
      tsUpdate: 1664804386
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD2187-010',
        itemQuantity: '14',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-400',
      tsUpdate: 1664804391
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD2187-010',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-500',
      tsUpdate: 1664804390
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '24',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-600',
      tsUpdate: 1664803802
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-700',
      tsUpdate: 1664803804
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-800',
      tsUpdate: 1664803803
    },
    {
      poHeader: {
        poNumber: '6000114350',
        trcoPoNumber: '2500093904',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '34',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114350-900',
      tsUpdate: 1664803808
    },
    {
      poHeader: {
        poNumber: '6000114351',
        trcoPoNumber: '2500092512',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114351-100',
      tsUpdate: 1664803783
    },
    {
      poHeader: {
        poNumber: '6000114351',
        trcoPoNumber: '2500092512',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114351-200',
      tsUpdate: 1664803782
    },
    {
      poHeader: {
        poNumber: '6000114351',
        trcoPoNumber: '2500092512',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114351-300',
      tsUpdate: 1664803786
    },
    {
      poHeader: {
        poNumber: '6000114351',
        trcoPoNumber: '2500092512',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114351-400',
      tsUpdate: 1664803784
    },
    {
      poHeader: {
        poNumber: '6000114351',
        trcoPoNumber: '2500092512',
        vendorCode: 'NAY'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD2187-068',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NK DF FL RESTORE CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114351-500',
      tsUpdate: 1664803797
    },
    {
      poHeader: {
        poNumber: '6000114711',
        trcoPoNumber: '2500093308',
        vendorCode: 'AGH'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM8272-010',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'JORDAN BEANIE UTILITY METAL JM'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114711-100',
      tsUpdate: 1665138099
    },
    {
      poHeader: {
        poNumber: '6000114712',
        trcoPoNumber: '2500092616',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM8310-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'U NSW BEANIE UTILITY PATCH'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114712-100',
      tsUpdate: 1664547901
    },
    {
      poHeader: {
        poNumber: '6000114712',
        trcoPoNumber: '2500092616',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DM8310-087',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'U NSW BEANIE UTILITY PATCH'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114712-200',
      tsUpdate: 1664548124
    },
    {
      poHeader: {
        poNumber: '6000114712',
        trcoPoNumber: '2500092616',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DM8310-246',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'U NSW BEANIE UTILITY PATCH'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114712-300',
      tsUpdate: 1664545107
    },
    {
      poHeader: {
        poNumber: '6000114712',
        trcoPoNumber: '2500092616',
        vendorCode: 'ASH'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DM8310-326',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'U NSW BEANIE UTILITY PATCH'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114712-400',
      tsUpdate: 1664547573
    },
    {
      poHeader: {
        poNumber: '6000114714',
        trcoPoNumber: '2500093912',
        vendorCode: 'CCV'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: '913011-010',
        itemQuantity: '2836',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'U NSW H86 FUTURA WASH CAP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114714-100',
      tsUpdate: 1665236780
    },
    {
      poHeader: {
        poNumber: '6000114714',
        trcoPoNumber: '2500093912',
        vendorCode: 'CCV'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: '913011-100',
        itemQuantity: '1891',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'U NSW H86 FUTURA WASH CAP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114714-200',
      tsUpdate: 1665145616
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-100',
      tsUpdate: 1665135841
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DC9143-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-1000',
      tsUpdate: 1665136807
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DC9143-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-1100',
      tsUpdate: 1665136808
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DC9143-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-1200',
      tsUpdate: 1665136811
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DC9143-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-1300',
      tsUpdate: 1665136810
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-200',
      tsUpdate: 1665135847
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-300',
      tsUpdate: 1665135845
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-400',
      tsUpdate: 1665135844
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DC9143-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-500',
      tsUpdate: 1665135843
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DC9143-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-600',
      tsUpdate: 1665137507
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DC9143-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-700',
      tsUpdate: 1665137509
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DC9143-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-800',
      tsUpdate: 1665137512
    },
    {
      poHeader: {
        poNumber: '6000114715',
        trcoPoNumber: '2500094014',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DC9143-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114715-900',
      tsUpdate: 1665137510
    },
    {
      poHeader: {
        poNumber: '6000114716',
        trcoPoNumber: '2500092831',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC9154-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114716-100',
      tsUpdate: 1664846648
    },
    {
      poHeader: {
        poNumber: '6000114716',
        trcoPoNumber: '2500092831',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DC9154-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114716-200',
      tsUpdate: 1664846648
    },
    {
      poHeader: {
        poNumber: '6000114716',
        trcoPoNumber: '2500092831',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DC9154-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114716-300',
      tsUpdate: 1664846648
    },
    {
      poHeader: {
        poNumber: '6000114716',
        trcoPoNumber: '2500092831',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DC9154-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114716-400',
      tsUpdate: 1664846647
    },
    {
      poHeader: {
        poNumber: '6000114716',
        trcoPoNumber: '2500092831',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DC9154-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114716-500',
      tsUpdate: 1664846548
    },
    {
      poHeader: {
        poNumber: '6000114716',
        trcoPoNumber: '2500092831',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DC9154-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114716-600',
      tsUpdate: 1664846559
    },
    {
      poHeader: {
        poNumber: '6000114716',
        trcoPoNumber: '2500092831',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DC9154-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114716-700',
      tsUpdate: 1664846530
    },
    {
      poHeader: {
        poNumber: '6000114716',
        trcoPoNumber: '2500092831',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DC9154-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114716-800',
      tsUpdate: 1664846559
    },
    {
      poHeader: {
        poNumber: '6000114717',
        trcoPoNumber: '2500093815',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC9158-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114717-100',
      tsUpdate: 1664846176
    },
    {
      poHeader: {
        poNumber: '6000114717',
        trcoPoNumber: '2500093815',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DC9158-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114717-200',
      tsUpdate: 1664846179
    },
    {
      poHeader: {
        poNumber: '6000114717',
        trcoPoNumber: '2500093815',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DC9158-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114717-300',
      tsUpdate: 1664846180
    },
    {
      poHeader: {
        poNumber: '6000114717',
        trcoPoNumber: '2500093815',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DC9158-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'Y NK TF ACD PNT KPZ WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114717-400',
      tsUpdate: 1664846183
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC9169-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-100',
      tsUpdate: 1665138104
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-1000',
      tsUpdate: 1665137783
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-1100',
      tsUpdate: 1665137791
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-1200',
      tsUpdate: 1665137786
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-1300',
      tsUpdate: 1665137788
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DC9169-492',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-1400',
      tsUpdate: 1665137789
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DC9169-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-200',
      tsUpdate: 1665138107
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DC9169-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-300',
      tsUpdate: 1665138108
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DC9169-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-400',
      tsUpdate: 1665138105
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-500',
      tsUpdate: 1665134770
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-600',
      tsUpdate: 1665134761
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-700',
      tsUpdate: 1665134767
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-800',
      tsUpdate: 1665134773
    },
    {
      poHeader: {
        poNumber: '6000114718',
        trcoPoNumber: '2500093915',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DC9169-084',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS M NK TF ACD DRL TOP WW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114718-900',
      tsUpdate: 1665134771
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-100',
      tsUpdate: 1664805169
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-1000',
      tsUpdate: 1664804851
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-200',
      tsUpdate: 1664805163
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-300',
      tsUpdate: 1664805170
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-400',
      tsUpdate: 1664805164
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD1427-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-500',
      tsUpdate: 1664805164
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-600',
      tsUpdate: 1664804874
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-700',
      tsUpdate: 1664804856
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-800',
      tsUpdate: 1664804857
    },
    {
      poHeader: {
        poNumber: '6000114719',
        trcoPoNumber: '2500092916',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD1427-601',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH RUNDVSN AP BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114719-900',
      tsUpdate: 1664804853
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-100',
      tsUpdate: 1664358969
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-1000',
      tsUpdate: 1664361138
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-1100',
      tsUpdate: 1664361139
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-1200',
      tsUpdate: 1664361140
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-1300',
      tsUpdate: 1664361137
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DD1459-601',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-1400',
      tsUpdate: 1664361138
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-200',
      tsUpdate: 1664358956
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-300',
      tsUpdate: 1664358956
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-400',
      tsUpdate: 1664358954
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD1459-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-500',
      tsUpdate: 1664358967
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD1459-357',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-600',
      tsUpdate: 1664357880
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD1459-357',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-700',
      tsUpdate: 1664357883
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD1459-357',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-800',
      tsUpdate: 1664357893
    },
    {
      poHeader: {
        poNumber: '6000114720',
        trcoPoNumber: '2500092630',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD1459-357',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'AS W NK DF SWSH MTLC GX BRA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114720-900',
      tsUpdate: 1664357882
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-100',
      tsUpdate: 1664360051
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-1000',
      tsUpdate: 1664359805
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-200',
      tsUpdate: 1664360061
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-300',
      tsUpdate: 1664360062
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-400',
      tsUpdate: 1664360052
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD4418-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-500',
      tsUpdate: 1664360061
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-600',
      tsUpdate: 1664359801
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-700',
      tsUpdate: 1664359793
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-800',
      tsUpdate: 1664359793
    },
    {
      poHeader: {
        poNumber: '6000114721',
        trcoPoNumber: '2500093128',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD4418-273',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK AIR DF MIDLAYER'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114721-900',
      tsUpdate: 1664359800
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-100',
      tsUpdate: 1664878584
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-1000',
      tsUpdate: 1664877764
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-200',
      tsUpdate: 1664878599
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-300',
      tsUpdate: 1664878588
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-400',
      tsUpdate: 1664878586
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5020-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-500',
      tsUpdate: 1664878589
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-600',
      tsUpdate: 1664877777
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-700',
      tsUpdate: 1664877767
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-800',
      tsUpdate: 1664877765
    },
    {
      poHeader: {
        poNumber: '6000114722',
        trcoPoNumber: '2500093012',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5020-100',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK ONE DF HBR SS STD CRP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114722-900',
      tsUpdate: 1664877766
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-100',
      tsUpdate: 1665136114
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-1000',
      tsUpdate: 1665136578
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-1100',
      tsUpdate: 1665137868
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-1200',
      tsUpdate: 1665137867
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-1300',
      tsUpdate: 1665137867
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-1400',
      tsUpdate: 1665137867
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'DD5099-133',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-1500',
      tsUpdate: 1665137866
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-200',
      tsUpdate: 1665136109
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-300',
      tsUpdate: 1665136110
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-400',
      tsUpdate: 1665136113
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5099-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-500',
      tsUpdate: 1665136112
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-600',
      tsUpdate: 1665136576
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-700',
      tsUpdate: 1665136578
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-800',
      tsUpdate: 1665136576
    },
    {
      poHeader: {
        poNumber: '6000114723',
        trcoPoNumber: '2500093214',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5099-017',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW ICN CLSH MOCK LS TOP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114723-900',
      tsUpdate: 1665136575
    },
    {
      poHeader: {
        poNumber: '6000114724',
        trcoPoNumber: '2500093210',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5468-010',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114724-100',
      tsUpdate: 1664875750
    },
    {
      poHeader: {
        poNumber: '6000114724',
        trcoPoNumber: '2500093210',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5468-010',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114724-200',
      tsUpdate: 1664875751
    },
    {
      poHeader: {
        poNumber: '6000114724',
        trcoPoNumber: '2500093210',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5468-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114724-300',
      tsUpdate: 1664875751
    },
    {
      poHeader: {
        poNumber: '6000114724',
        trcoPoNumber: '2500093210',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5468-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114724-400',
      tsUpdate: 1664875752
    },
    {
      poHeader: {
        poNumber: '6000114724',
        trcoPoNumber: '2500093210',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114724-500',
      tsUpdate: 1664876254
    },
    {
      poHeader: {
        poNumber: '6000114724',
        trcoPoNumber: '2500093210',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114724-600',
      tsUpdate: 1664876261
    },
    {
      poHeader: {
        poNumber: '6000114724',
        trcoPoNumber: '2500093210',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114724-700',
      tsUpdate: 1664876262
    },
    {
      poHeader: {
        poNumber: '6000114724',
        trcoPoNumber: '2500093210',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114724-800',
      tsUpdate: 1664876259
    },
    {
      poHeader: {
        poNumber: '6000114724',
        trcoPoNumber: '2500093210',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5468-100',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC CRW'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114724-900',
      tsUpdate: 1664876252
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-100',
      tsUpdate: 1664633726
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-1000',
      tsUpdate: 1664631543
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-200',
      tsUpdate: 1664633728
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-300',
      tsUpdate: 1664633779
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-400',
      tsUpdate: 1664633849
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD6792-133',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-500',
      tsUpdate: 1664633803
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-600',
      tsUpdate: 1664631536
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-700',
      tsUpdate: 1664631522
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-800',
      tsUpdate: 1664631524
    },
    {
      poHeader: {
        poNumber: '6000114725',
        trcoPoNumber: '2500093120',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD6792-357',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DFADV HOODIE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114725-900',
      tsUpdate: 1664631524
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-100',
      tsUpdate: 1662843344
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-1000',
      tsUpdate: 1662838121
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-1100',
      tsUpdate: 1662172465
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-1200',
      tsUpdate: 1662172426
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-1300',
      tsUpdate: 1662172448
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-1400',
      tsUpdate: 1662172456
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'DJ6944-533',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-1500',
      tsUpdate: 1662172437
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-200',
      tsUpdate: 1662843371
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-300',
      tsUpdate: 1662843352
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-400',
      tsUpdate: 1662843374
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DJ6944-004',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-500',
      tsUpdate: 1662843360
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-600',
      tsUpdate: 1662838123
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-700',
      tsUpdate: 1662838128
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-800',
      tsUpdate: 1662838127
    },
    {
      poHeader: {
        poNumber: '6000114726',
        trcoPoNumber: '2500093626',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DJ6944-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NSW DF TP HR TROUSER PNT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114726-900',
      tsUpdate: 1662838124
    },
    {
      poHeader: {
        poNumber: '6000114727',
        trcoPoNumber: '2500092523',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM2884-010',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114727-100',
      tsUpdate: 1664457601
    },
    {
      poHeader: {
        poNumber: '6000114727',
        trcoPoNumber: '2500092523',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DM2884-010',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114727-200',
      tsUpdate: 1664457602
    },
    {
      poHeader: {
        poNumber: '6000114727',
        trcoPoNumber: '2500092523',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DM2884-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114727-300',
      tsUpdate: 1664457606
    },
    {
      poHeader: {
        poNumber: '6000114727',
        trcoPoNumber: '2500092523',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DM2884-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114727-400',
      tsUpdate: 1664457604
    },
    {
      poHeader: {
        poNumber: '6000114727',
        trcoPoNumber: '2500092523',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DM2884-601',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114727-500',
      tsUpdate: 1664457484
    },
    {
      poHeader: {
        poNumber: '6000114727',
        trcoPoNumber: '2500092523',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DM2884-601',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114727-600',
      tsUpdate: 1664457482
    },
    {
      poHeader: {
        poNumber: '6000114727',
        trcoPoNumber: '2500092523',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DM2884-601',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114727-700',
      tsUpdate: 1664457484
    },
    {
      poHeader: {
        poNumber: '6000114727',
        trcoPoNumber: '2500092523',
        vendorCode: 'CVT'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DM2884-601',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'AS W NK DF HBR FLC HOODY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114727-800',
      tsUpdate: 1664457483
    },
    {
      poHeader: {
        poNumber: '6000114728',
        trcoPoNumber: '2500092818',
        vendorCode: 'DDV'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DC3673-533',
        itemQuantity: '178',
        originalGoodsAtConsolidatorDate: '2022-09-01',
        productName: 'JORDAN H86 JM WASHED CAP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114728-100',
      tsUpdate: 1665135898
    },
    {
      poHeader: {
        poNumber: '6000114731',
        trcoPoNumber: '2500092729',
        vendorCode: 'DDV'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DM8271-133',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-08',
        productName: 'JORDAN AW84 MT SIDE CAP'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114731-100',
      tsUpdate: 1663325442
    },
    {
      poHeader: {
        poNumber: '6000114732',
        trcoPoNumber: '2500093313',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114732-100',
      tsUpdate: 1664312013
    },
    {
      poHeader: {
        poNumber: '6000114732',
        trcoPoNumber: '2500093313',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '37',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114732-200',
      tsUpdate: 1664312017
    },
    {
      poHeader: {
        poNumber: '6000114732',
        trcoPoNumber: '2500093313',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '41',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114732-300',
      tsUpdate: 1664312016
    },
    {
      poHeader: {
        poNumber: '6000114732',
        trcoPoNumber: '2500093313',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114732-400',
      tsUpdate: 1664312012
    },
    {
      poHeader: {
        poNumber: '6000114732',
        trcoPoNumber: '2500093313',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '19',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114732-500',
      tsUpdate: 1664312017
    },
    {
      poHeader: {
        poNumber: '6000114735',
        trcoPoNumber: '2500094016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DA9835-010',
        itemQuantity: '38',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS WOVEN PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114735-100',
      tsUpdate: 1665136395
    },
    {
      poHeader: {
        poNumber: '6000114735',
        trcoPoNumber: '2500094016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DA9835-010',
        itemQuantity: '112',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS WOVEN PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114735-200',
      tsUpdate: 1665136396
    },
    {
      poHeader: {
        poNumber: '6000114735',
        trcoPoNumber: '2500094016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DA9835-010',
        itemQuantity: '129',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS WOVEN PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114735-300',
      tsUpdate: 1665136395
    },
    {
      poHeader: {
        poNumber: '6000114735',
        trcoPoNumber: '2500094016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DA9835-010',
        itemQuantity: '67',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS WOVEN PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114735-400',
      tsUpdate: 1665136394
    },
    {
      poHeader: {
        poNumber: '6000114735',
        trcoPoNumber: '2500094016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DA9835-010',
        itemQuantity: '75',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M J ESS WOVEN PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114735-500',
      tsUpdate: 1665136394
    },
    {
      poHeader: {
        poNumber: '6000114736',
        trcoPoNumber: '2500093817',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '27',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114736-100',
      tsUpdate: 1662840340
    },
    {
      poHeader: {
        poNumber: '6000114736',
        trcoPoNumber: '2500093817',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '39',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114736-200',
      tsUpdate: 1662840349
    },
    {
      poHeader: {
        poNumber: '6000114736',
        trcoPoNumber: '2500093817',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '40',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114736-300',
      tsUpdate: 1662840326
    },
    {
      poHeader: {
        poNumber: '6000114736',
        trcoPoNumber: '2500093817',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114736-400',
      tsUpdate: 1662840342
    },
    {
      poHeader: {
        poNumber: '6000114736',
        trcoPoNumber: '2500093817',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114736-500',
      tsUpdate: 1662840324
    },
    {
      poHeader: {
        poNumber: '6000114737',
        trcoPoNumber: '2500093909',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114737-100',
      tsUpdate: 1661523322
    },
    {
      poHeader: {
        poNumber: '6000114737',
        trcoPoNumber: '2500093909',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '33',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114737-200',
      tsUpdate: 1661523316
    },
    {
      poHeader: {
        poNumber: '6000114737',
        trcoPoNumber: '2500093909',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '40',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114737-300',
      tsUpdate: 1661523323
    },
    {
      poHeader: {
        poNumber: '6000114737',
        trcoPoNumber: '2500093909',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114737-400',
      tsUpdate: 1661523320
    },
    {
      poHeader: {
        poNumber: '6000114737',
        trcoPoNumber: '2500093909',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '21',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114737-500',
      tsUpdate: 1661523319
    },
    {
      poHeader: {
        poNumber: '6000114738',
        trcoPoNumber: '2500092618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5914-010',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW CITY MADE UL PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114738-100',
      tsUpdate: 1664805908
    },
    {
      poHeader: {
        poNumber: '6000114738',
        trcoPoNumber: '2500092618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5914-010',
        itemQuantity: '64',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW CITY MADE UL PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114738-200',
      tsUpdate: 1664805919
    },
    {
      poHeader: {
        poNumber: '6000114738',
        trcoPoNumber: '2500092618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5914-010',
        itemQuantity: '68',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW CITY MADE UL PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114738-300',
      tsUpdate: 1664805910
    },
    {
      poHeader: {
        poNumber: '6000114738',
        trcoPoNumber: '2500092618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5914-010',
        itemQuantity: '43',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW CITY MADE UL PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114738-400',
      tsUpdate: 1664805917
    },
    {
      poHeader: {
        poNumber: '6000114738',
        trcoPoNumber: '2500092618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5914-010',
        itemQuantity: '42',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW CITY MADE UL PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114738-500',
      tsUpdate: 1664805899
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5968-010',
        itemQuantity: '170',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-100',
      tsUpdate: 1664631717
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD5968-077',
        itemQuantity: '228',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-1000',
      tsUpdate: 1664365375
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5968-010',
        itemQuantity: '752',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-200',
      tsUpdate: 1664631670
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5968-010',
        itemQuantity: '836',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-300',
      tsUpdate: 1664631678
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5968-010',
        itemQuantity: '606',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-400',
      tsUpdate: 1664631795
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5968-010',
        itemQuantity: '467',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-500',
      tsUpdate: 1664631770
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5968-077',
        itemQuantity: '72',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-600',
      tsUpdate: 1664365376
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5968-077',
        itemQuantity: '399',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-700',
      tsUpdate: 1664365377
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5968-077',
        itemQuantity: '434',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-800',
      tsUpdate: 1664365377
    },
    {
      poHeader: {
        poNumber: '6000114739',
        trcoPoNumber: '2500094012',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5968-077',
        itemQuantity: '261',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS M NSW SWOOSH WVN LND JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114739-900',
      tsUpdate: 1664365376
    },
    {
      poHeader: {
        poNumber: '6000114740',
        trcoPoNumber: '2500092724',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD7033-010',
        itemQuantity: '71',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW STE WVN UL SNKR PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114740-100',
      tsUpdate: 1665136724
    },
    {
      poHeader: {
        poNumber: '6000114740',
        trcoPoNumber: '2500092724',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD7033-010',
        itemQuantity: '111',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW STE WVN UL SNKR PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114740-200',
      tsUpdate: 1665136722
    },
    {
      poHeader: {
        poNumber: '6000114740',
        trcoPoNumber: '2500092724',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD7033-010',
        itemQuantity: '110',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW STE WVN UL SNKR PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114740-300',
      tsUpdate: 1665136723
    },
    {
      poHeader: {
        poNumber: '6000114740',
        trcoPoNumber: '2500092724',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD7033-010',
        itemQuantity: '55',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW STE WVN UL SNKR PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114740-400',
      tsUpdate: 1665136721
    },
    {
      poHeader: {
        poNumber: '6000114740',
        trcoPoNumber: '2500092724',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD7033-010',
        itemQuantity: '27',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW STE WVN UL SNKR PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114740-500',
      tsUpdate: 1665136722
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DH3867-010',
        itemQuantity: '121',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-100',
      tsUpdate: 1665137447
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DH3867-258',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-1000',
      tsUpdate: 1664805468
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DH3867-010',
        itemQuantity: '189',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-200',
      tsUpdate: 1665137441
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DH3867-010',
        itemQuantity: '185',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-300',
      tsUpdate: 1665137439
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DH3867-010',
        itemQuantity: '99',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-400',
      tsUpdate: 1665137440
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DH3867-010',
        itemQuantity: '33',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-500',
      tsUpdate: 1665137442
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DH3867-258',
        itemQuantity: '37',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-600',
      tsUpdate: 1664805490
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DH3867-258',
        itemQuantity: '49',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-700',
      tsUpdate: 1664805490
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DH3867-258',
        itemQuantity: '46',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-800',
      tsUpdate: 1664805489
    },
    {
      poHeader: {
        poNumber: '6000114741',
        trcoPoNumber: '2500094010',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DH3867-258',
        itemQuantity: '21',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW TE WVN UL U PANT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114741-900',
      tsUpdate: 1664805478
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '23',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-100',
      tsUpdate: 1664373543
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-1000',
      tsUpdate: 1664373303
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '36',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-1100',
      tsUpdate: 1664373303
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '36',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-200',
      tsUpdate: 1664373542
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '23',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-300',
      tsUpdate: 1664373542
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-400',
      tsUpdate: 1664373543
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '23',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-500',
      tsUpdate: 1664373543
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-600',
      tsUpdate: 1664373304
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '12',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-700',
      tsUpdate: 1664373305
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '23',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-800',
      tsUpdate: 1664373304
    },
    {
      poHeader: {
        poNumber: '6000114742',
        trcoPoNumber: '2500093016',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '23',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114742-900',
      tsUpdate: 1664373305
    },
    {
      poHeader: {
        poNumber: '6000114743',
        trcoPoNumber: '2500093022',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '30',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114743-100',
      tsUpdate: 1662844081
    },
    {
      poHeader: {
        poNumber: '6000114743',
        trcoPoNumber: '2500093022',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '42',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114743-200',
      tsUpdate: 1662844075
    },
    {
      poHeader: {
        poNumber: '6000114743',
        trcoPoNumber: '2500093022',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '35',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114743-300',
      tsUpdate: 1662844083
    },
    {
      poHeader: {
        poNumber: '6000114743',
        trcoPoNumber: '2500093022',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114743-400',
      tsUpdate: 1662844080
    },
    {
      poHeader: {
        poNumber: '6000114743',
        trcoPoNumber: '2500093022',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114743-500',
      tsUpdate: 1662844084
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-100',
      tsUpdate: 1664312011
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DA6714-604',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-1000',
      tsUpdate: 1664311403
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DA6714-604',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-1100',
      tsUpdate: 1664311405
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DA6714-604',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-1200',
      tsUpdate: 1664311405
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DA6714-604',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-1300',
      tsUpdate: 1664311407
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-200',
      tsUpdate: 1664312011
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-300',
      tsUpdate: 1664312012
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DA6714-010',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-400',
      tsUpdate: 1664312012
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DA6714-345',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-500',
      tsUpdate: 1664311406
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DA6714-345',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-600',
      tsUpdate: 1664311407
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DA6714-345',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-700',
      tsUpdate: 1664311406
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DA6714-345',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-800',
      tsUpdate: 1664311407
    },
    {
      poHeader: {
        poNumber: '6000114744',
        trcoPoNumber: '2500092731',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DA6714-604',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS LJ M NK DF JKT PRM UTILITY'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114744-900',
      tsUpdate: 1664311408
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD4631-220',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-100',
      tsUpdate: 1664360137
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-1000',
      tsUpdate: 1662840384
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD4631-220',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-200',
      tsUpdate: 1664360136
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD4631-220',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-300',
      tsUpdate: 1664360136
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD4631-220',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-400',
      tsUpdate: 1664360136
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD4631-220',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-500',
      tsUpdate: 1664360137
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-600',
      tsUpdate: 1662840406
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '16',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-700',
      tsUpdate: 1662840416
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '16',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-800',
      tsUpdate: 1662840378
    },
    {
      poHeader: {
        poNumber: '6000114747',
        trcoPoNumber: '2500093918',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD4631-715',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW TF TP INSUL SHIRT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114747-900',
      tsUpdate: 1662840419
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5113-010',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-100',
      tsUpdate: 1664362413
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD5113-013',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-1000',
      tsUpdate: 1662507644
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5113-010',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-200',
      tsUpdate: 1664362414
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5113-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-300',
      tsUpdate: 1664362413
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5113-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-400',
      tsUpdate: 1664362415
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5113-010',
        itemQuantity: '1',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-500',
      tsUpdate: 1664362415
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5113-013',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-600',
      tsUpdate: 1662507650
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5113-013',
        itemQuantity: '11',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-700',
      tsUpdate: 1662507646
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5113-013',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-800',
      tsUpdate: 1662507645
    },
    {
      poHeader: {
        poNumber: '6000114748',
        trcoPoNumber: '2500093040',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5113-013',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN HR PNT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114748-900',
      tsUpdate: 1662507648
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-100',
      tsUpdate: 1664362173
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-1000',
      tsUpdate: 1664362139
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-200',
      tsUpdate: 1664362173
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-300',
      tsUpdate: 1664362174
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-400',
      tsUpdate: 1664362174
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5115-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-500',
      tsUpdate: 1664362175
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-600',
      tsUpdate: 1664362140
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-700',
      tsUpdate: 1664362140
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-800',
      tsUpdate: 1664362152
    },
    {
      poHeader: {
        poNumber: '6000114749',
        trcoPoNumber: '2500093618',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD5115-013',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-22',
        productName: 'AS W NSW ESSNTL WVN SS TOP QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114749-900',
      tsUpdate: 1664362140
    },
    {
      poHeader: {
        poNumber: '6000114750',
        trcoPoNumber: '2500092922',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114750-100',
      tsUpdate: 1661523323
    },
    {
      poHeader: {
        poNumber: '6000114750',
        trcoPoNumber: '2500092922',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114750-200',
      tsUpdate: 1661523325
    },
    {
      poHeader: {
        poNumber: '6000114750',
        trcoPoNumber: '2500092922',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114750-300',
      tsUpdate: 1661523324
    },
    {
      poHeader: {
        poNumber: '6000114750',
        trcoPoNumber: '2500092922',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114750-400',
      tsUpdate: 1661523323
    },
    {
      poHeader: {
        poNumber: '6000114750',
        trcoPoNumber: '2500092922',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD5121-013',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114750-500',
      tsUpdate: 1661523324
    },
    {
      poHeader: {
        poNumber: '6000114750',
        trcoPoNumber: '2500092922',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD5121-825',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W NSW ESSNTL WVN JKT QLT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114750-600',
      tsUpdate: 1664361798
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD6789-077',
        itemQuantity: '2',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-100',
      tsUpdate: 1664367808
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD6789-410',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-1000',
      tsUpdate: 1664368105
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD6789-077',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-200',
      tsUpdate: 1664367809
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD6789-077',
        itemQuantity: '9',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-300',
      tsUpdate: 1664367810
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD6789-077',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-400',
      tsUpdate: 1664367810
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD6789-077',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-500',
      tsUpdate: 1664367809
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD6789-410',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-600',
      tsUpdate: 1664368105
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD6789-410',
        itemQuantity: '14',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-700',
      tsUpdate: 1664368105
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD6789-410',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-800',
      tsUpdate: 1664368106
    },
    {
      poHeader: {
        poNumber: '6000114751',
        trcoPoNumber: '2500092569',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD6789-410',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER PARKA'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114751-900',
      tsUpdate: 1664368106
    },
    {
      poHeader: {
        poNumber: '6000114752',
        trcoPoNumber: '2500093224',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD6796-326',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER HD JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114752-100',
      tsUpdate: 1664367538
    },
    {
      poHeader: {
        poNumber: '6000114752',
        trcoPoNumber: '2500093224',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD6796-326',
        itemQuantity: '14',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER HD JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114752-200',
      tsUpdate: 1664367539
    },
    {
      poHeader: {
        poNumber: '6000114752',
        trcoPoNumber: '2500093224',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD6796-326',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER HD JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114752-300',
      tsUpdate: 1664367538
    },
    {
      poHeader: {
        poNumber: '6000114752',
        trcoPoNumber: '2500093224',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD6796-326',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER HD JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114752-400',
      tsUpdate: 1664367539
    },
    {
      poHeader: {
        poNumber: '6000114752',
        trcoPoNumber: '2500093224',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD6796-326',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SF WINDRUNNER HD JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114752-500',
      tsUpdate: 1664367539
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-100',
      tsUpdate: 1664367620
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DD6818-326',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-1000',
      tsUpdate: 1664367612
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-1100',
      tsUpdate: 1664367926
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1200',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-1200',
      tsUpdate: 1664367926
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1300',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-1300',
      tsUpdate: 1664367927
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1400',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-1400',
      tsUpdate: 1664367927
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1500',
        divisionCode: '10',
        productCode: 'DD6818-410',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-1500',
      tsUpdate: 1664367928
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-200',
      tsUpdate: 1664367620
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-300',
      tsUpdate: 1664367621
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-400',
      tsUpdate: 1664367621
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DD6818-100',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-500',
      tsUpdate: 1664367621
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DD6818-326',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-600',
      tsUpdate: 1664367613
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DD6818-326',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-700',
      tsUpdate: 1664367615
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DD6818-326',
        itemQuantity: '14',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-800',
      tsUpdate: 1664367614
    },
    {
      poHeader: {
        poNumber: '6000114753',
        trcoPoNumber: '2500093828',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DD6818-326',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-15',
        productName: 'AS M NSW SF WINDRUNNER VEST'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114753-900',
      tsUpdate: 1664367615
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-100',
      tsUpdate: 1664373542
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-1000',
      tsUpdate: 1664373300
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1100',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-1100',
      tsUpdate: 1664373302
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-200',
      tsUpdate: 1664373540
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-300',
      tsUpdate: 1664373540
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-400',
      tsUpdate: 1664373541
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DJ5868-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-500',
      tsUpdate: 1664373541
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-600',
      tsUpdate: 1664373300
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '5',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-700',
      tsUpdate: 1664373301
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-800',
      tsUpdate: 1664373302
    },
    {
      poHeader: {
        poNumber: '6000114754',
        trcoPoNumber: '2500092617',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '900',
        divisionCode: '10',
        productCode: 'DJ5868-246',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M J ZION FLT JKT'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114754-900',
      tsUpdate: 1664373302
    },
    {
      poHeader: {
        poNumber: '6000114755',
        trcoPoNumber: '2500093512',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '10',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114755-100',
      tsUpdate: 1662844045
    },
    {
      poHeader: {
        poNumber: '6000114755',
        trcoPoNumber: '2500093512',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '13',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114755-200',
      tsUpdate: 1662844043
    },
    {
      poHeader: {
        poNumber: '6000114755',
        trcoPoNumber: '2500093512',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '15',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114755-300',
      tsUpdate: 1662844058
    },
    {
      poHeader: {
        poNumber: '6000114755',
        trcoPoNumber: '2500093512',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114755-400',
      tsUpdate: 1662844066
    },
    {
      poHeader: {
        poNumber: '6000114755',
        trcoPoNumber: '2500093512',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DN9693-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS W J FLT PUFF SLD JKT CORE'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114755-500',
      tsUpdate: 1662844060
    },
    {
      poHeader: {
        poNumber: '6000114756',
        trcoPoNumber: '2500093021',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DO2966-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114756-100',
      tsUpdate: 1659450715
    },
    {
      poHeader: {
        poNumber: '6000114756',
        trcoPoNumber: '2500093021',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DO2966-010',
        itemQuantity: '4',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW SWOOSH JKT+ WMCR'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114756-200',
      tsUpdate: 1659450716
    },
    {
      poHeader: {
        poNumber: '6000114757',
        trcoPoNumber: '2500093314',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '100',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114757-100',
      tsUpdate: 1664636811
    },
    {
      poHeader: {
        poNumber: '6000114757',
        trcoPoNumber: '2500093314',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '1000',
        divisionCode: '10',
        productCode: 'DO4885-087',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114757-1000',
      tsUpdate: 1664636122
    },
    {
      poHeader: {
        poNumber: '6000114757',
        trcoPoNumber: '2500093314',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '200',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114757-200',
      tsUpdate: 1664636819
    },
    {
      poHeader: {
        poNumber: '6000114757',
        trcoPoNumber: '2500093314',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '300',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114757-300',
      tsUpdate: 1664636818
    },
    {
      poHeader: {
        poNumber: '6000114757',
        trcoPoNumber: '2500093314',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '400',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '8',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114757-400',
      tsUpdate: 1664636843
    },
    {
      poHeader: {
        poNumber: '6000114757',
        trcoPoNumber: '2500093314',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '500',
        divisionCode: '10',
        productCode: 'DO4885-010',
        itemQuantity: '7',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114757-500',
      tsUpdate: 1664636829
    },
    {
      poHeader: {
        poNumber: '6000114757',
        trcoPoNumber: '2500093314',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '600',
        divisionCode: '10',
        productCode: 'DO4885-087',
        itemQuantity: '3',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114757-600',
      tsUpdate: 1664636089
    },
    {
      poHeader: {
        poNumber: '6000114757',
        trcoPoNumber: '2500093314',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '700',
        divisionCode: '10',
        productCode: 'DO4885-087',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114757-700',
      tsUpdate: 1664636107
    },
    {
      poHeader: {
        poNumber: '6000114757',
        trcoPoNumber: '2500093314',
        vendorCode: 'EAG'
      },
      poLine: {
        itemNumber: '800',
        divisionCode: '10',
        productCode: 'DO4885-087',
        itemQuantity: '6',
        originalGoodsAtConsolidatorDate: '2022-09-29',
        productName: 'AS M NSW TP RFLCT WVN UL CRGO'
      },
      dpomAirFreight: {

      },
      poData: {
        sizes: {
          sizeManufacturing: {

          }
        }
      },
      vendor: {

      },
      id: '6000114757-800',
      tsUpdate: 1664636118
    }
  ]
};
