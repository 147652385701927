export default {
  CONVO_THREAD_PO_NUMBER: 'poNumber',
  CONVO_THREAD_ITEM_NUMBER: 'itemNumber',
  CONVO_THREAD_CONVO_ID: 'conversations.collaborationId',
  CONVO_THREAD_CONVO_TOPIC: 'conversations.collaborationTopic',
  CONVO_THREAD_CONVO_TOPIC_CODE: 'conversations.collaborationTopic.code',
  CONVO_THREAD_CONVO_TOPIC_NAME: 'conversations.collaborationTopic.topicName',
  CONVO_THREAD_CONVO_STATUS: 'conversations.collaborationStatus',
  CONVO_THREAD_PO_LINE_ITEM_STATUS: 'dpomItemStatus',
  CONVO_THREAD_VENDOR_CODE: 'vendorCode',
  CONVO_THREAD_GAC_DATE: 'goodsAtConsolidatorDate',
  CONVO_THREAD_DOCUMENT_DATE: 'documentDate',
  CONVO_THREAD_DOC_TYPE_CODE: 'poDocTypeCode',
  CONVO_THREAD_CREATED_BY: 'conversations.createdBy',
  CONVO_THREAD_CREATE_DATE: 'conversations.createdDate',
  CONVO_THREAD_LAST_UPDATED_BY: 'conversations.lastUpdatedBy',
  CONVO_THREAD_LAST_UPDATED_DATE: 'conversations.modifiedDate',
  CONVO_THREAD_CONVO_LINK: 'conversations.collaborationLink',
  CONVO_THREAD_ATTACHMENTS: 'conversations.attachments',
  CONVO_THREAD_STAKEHOLDERS: 'conversations.stakeHolders',
  CONVO_THREAD_PRODUCT_CODE: 'productCode'
};
