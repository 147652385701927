/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import { cryptoRand } from '../../helpers/common';

export const name = 'gacReasonCode';

function createInitialState() {
  return {
    dataId: '',
    dataRows: []
  };
}

export const initialState = createInitialState();

function createReducers() {
  // SET_GAC_REASON_CODE
  function setGACReasonCodes(state: Object, action: Object) {
    const dataId = cryptoRand().toString().substr(2, 8);
    const dataRows = action.payload.objects;
    return {
      ...state,
      dataRows,
      dataId
    };
  }

  return {
    setGACReasonCodes
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function callGetGACReasonCodeAPI() {
    return createAsyncThunk(
      `${name}/callGetGACReasonCodeAPI`,
      (callback, { dispatch }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'getGACReasonCode',
          method: 'get'
        })
          .then((response) => {
            dispatch(toggleSpinner(false));
            const resData = response.data;
            const oldData = resData.objects;
            const modifiedData = [];
            oldData.forEach((row) => {
              const updatedRow = row;
              updatedRow.isActive = (row.isActive) ? 'Active' : 'Inactive';
              modifiedData.push(updatedRow);
            });
            dispatch(actions.setGACReasonCodes(resData));
            if (callback) callback(resData);
          })
          .catch((error) => {
            console.log('callGetGACReasonCodeAPI->error', error);
            dispatch(toggleSpinner(false));
            if (callback) callback(error);
          });
      }
    );
  }

  function callUpdateReasonCodeAPI() {
    return createAsyncThunk(
      `${name}/callUpdateReasonCodeAPI`,
      async ({
        payload,
        callback
      }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'updateGacReasonCodes',
          method: 'put',
          data: {
            type: 'update',
            gacData: payload
          }
        })
          .then((response) => {
            dispatch(toggleSpinner(false));
            if (callback) callback(response.data);
          })
          .catch((error) => {
            console.log('callUpdateReasonCodeAPI error', error);
            dispatch(toggleSpinner(false));
            if (callback) callback(error);
          });
      }
    );
  }

  function callAddGacReasonCodeAPI() {
    return createAsyncThunk(
      `${name}/callAddGacReasonCodeAPI`,
      async ({
        payload,
        callback
      }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'addGacReasonCodes',
          method: 'post',
          data: { gacData: payload }
        })
          .then((response) => {
            dispatch(toggleSpinner(false));
            if (callback) callback(response.data);
          })
          .catch((error) => {
            console.log('callAddGacReasonCodeAPI->error', error);
            dispatch(toggleSpinner(false));
            if (callback) callback(error);
          });
      }
    );
  }

  return {
    callGetGACReasonCodeAPI: callGetGACReasonCodeAPI(),
    callUpdateReasonCodeAPI: callUpdateReasonCodeAPI(),
    callAddGacReasonCodeAPI: callAddGacReasonCodeAPI()
  };
}

export const extraActions = createExtraActions();

export const gacReasonCodeActions = { ...actions, ...extraActions };
export const gacReasonCodeReducer = slice.reducer;
