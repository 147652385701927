/**
 * @flow
 */
import { validateFactoryCode, validateEmailId, validateAlphanumeric } from '../../helpers/common';

export function validate(value: any) {
  if (this.isRequired && !value) {
    return 'This is a mandatory field.';
  }
  switch (this.primary) {
    case 'factoryCode': {
      return validateFactoryCode(value);
    }
    case 'managingUserEmail': {
      return validateEmailId(value);
    }
    case 'managingUser':
    case 'managingUserID': {
      return validateAlphanumeric(value);
    }
    default: {
      return '';
    }
  }
}

export default {
  factoryCode: {
    primary: 'factoryCode', secondary: '', type: 'string', link: false, label: 'Vendor Code', wrap: true, numeric: 'true', disablePadding: false, width: 180, validate, isEditable: false, gridSpan: 2, isRequired: true
  },
  managingOffice: {
    primary: 'managingOffice', secondary: 'managingOfficeName', type: 'string', link: false, label: 'PMO/DEC Office', wrap: true, numeric: 'true', disablePadding: false, width: 180, validate, isEditable: false, gridSpan: 2
  },
  managingOfficeName: {
    primary: 'managingOfficeName', secondary: '', type: 'string', link: false, label: 'PMO/DEC Office Name', wrap: true, numeric: 'true', disablePadding: false, width: 180, validate, isEditable: false, gridSpan: 2
  },
  managingUserID: {
    primary: 'managingUserID', secondary: '', type: 'string', link: false, label: 'PMO Analyst ID', wrap: true, numeric: 'true', disablePadding: false, width: 180, validate, isEditable: true, gridSpan: 2, isRequired: true
  },
  managingUser: {
    primary: 'managingUser', secondary: '', type: 'string', link: false, label: 'PMO Analyst Name', wrap: true, numeric: 'true', disablePadding: false, width: 180, validate, isEditable: false, gridSpan: 2
  },
  managingUserEmail: {
    primary: 'managingUserEmail', secondary: '', type: 'string', link: false, label: 'PMO Analyst Email ', wrap: true, numeric: 'true', disablePadding: false, width: 180, validate, isEditable: true, gridSpan: 2, isRequired: true
  },
  modifiedBy: {
    primary: 'modifiedBy', secondary: '', type: 'string', link: false, label: 'Last Edited By', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  },
  modifiedDate: {
    primary: 'modifiedDate', secondary: '', type: 'datetime', link: false, label: 'Last Edited On', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  }
};
