/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import { mapFiltersWithDatesToRequest } from '../../helpers/dataHelpers';
import { cryptoRand, getArrayofYears } from '../../helpers/common';
import operationalMetricsFieldMapping from '../../constants/fieldMappings/operationalMetricsFieldMapping';
import {
  docTypeOptions
} from '../../constants/staticData';
import { calculateDateFromInputRange } from '../../nikeCustomComponents/nikeFormInput/DateRange/staticDateRangeData';

export const name = 'operationMetricsDashboard';
const defaultFilters = {
  [operationalMetricsFieldMapping.poCreatedOn]: {},
  [operationalMetricsFieldMapping.documentDate]: {},
  [operationalMetricsFieldMapping.originalGoodsAtConsolidatorDate]: {},
  [operationalMetricsFieldMapping.goodsAtConsolidatorDate]: {},
  [operationalMetricsFieldMapping.geographyCode]: '',
  [operationalMetricsFieldMapping.division]: [],
  [operationalMetricsFieldMapping.vendorCode]: [],
  [operationalMetricsFieldMapping.poDocTypeCode]: [],
  [operationalMetricsFieldMapping.purchaseOrgCode]: [],
  [operationalMetricsFieldMapping.productionNikeLiaisonOffice]: [],
  [operationalMetricsFieldMapping.seasonCode]: [],
  [operationalMetricsFieldMapping.seasonYear]: [],
  [operationalMetricsFieldMapping.productCode]: [],
  [operationalMetricsFieldMapping.categoryCode]: [],
  [operationalMetricsFieldMapping.subCategoryCode]: []
};

const docDatePo = calculateDateFromInputRange({
  startFromDays: -13,
  endFromDays: 0
});

const gacDateGIP = calculateDateFromInputRange({
  startFromDays: -60,
  endFromDays: 0
});

const defaultPOFilters = {
  [operationalMetricsFieldMapping.documentDate]: {
    from: docDatePo.startDate,
    to: docDatePo.endDate
  }
};

const defaultGACFilters = {
  [operationalMetricsFieldMapping.goodsAtConsolidatorDate]: {
    from: gacDateGIP.startDate,
    to: gacDateGIP.endDate
  }
};

const modifier = (arrayOfObjects) => arrayOfObjects.map((obj) => obj.value);

function createInitialState() {
  return {
    itemViewStatus: {
      poCount: false,
      rejectedPOCount: false,
      modeOfTransportationCount: false,
      manualVsSystemPoCount: false,
      gacDateCount: false
    },
    tableViewStatus: {
      PO: {},
      GAC: {}
    },
    currentTab: 0,
    userProfile: [],
    // filters
    currentTabName: 'PO',
    filterDropdownValues: {
      [operationalMetricsFieldMapping.seasonYear]: modifier(
        getArrayofYears(2019, 2025)
      ),
      [operationalMetricsFieldMapping.geographyCode]: [
        { text: 'NA', value: 'NA' },
        { text: 'EMEA', value: 'EMEA' },
        { text: 'GC', value: 'GC' },
        { text: 'APLA', value: 'APLA' }
      ],
      [operationalMetricsFieldMapping.seasonCode]: modifier([
        { id: 'FA', value: 'FA' },
        { id: 'SU', value: 'SU' },
        { id: 'HO', value: 'HO' },
        { id: 'SP', value: 'SP' }
      ]),
      [operationalMetricsFieldMapping.division]: modifier([
        { id: '10', value: '10 | Apparel' },
        { id: '20', value: '20 | Footwear' },
        { id: '30', value: '30 | Accessories' }
      ]),
      [operationalMetricsFieldMapping.purchaseOrgCode]: modifier([
        { id: '3100', value: '3100 | Regional Single AP' },
        { id: '3101', value: '3101 | Reg.Single Sample AP' },
        { id: '3150', value: '3150 | Regional-Multi AP' },
        { id: '3151', value: '3151 | Reg-Multi Sample AP' },
        { id: '3155', value: '3155 | Country Managed AP' },
        { id: '3200', value: '3200 | Regional Single EQ' },
        { id: '3201', value: '3201 | Equipment CMP' },
        { id: '3300', value: '3300 | Regional Single FW' },
        { id: '3301', value: '3301 | Country Managed FW' },
        { id: 'FW00', value: 'FW00 | Footwear' },
        { id: 'AP00', value: 'AP00 | Apparel' },
        { id: 'EQ00', value: 'EQ00 | Equipment' }
      ]),
      [operationalMetricsFieldMapping.poDocTypeCode]: modifier(docTypeOptions)
    },
    selectedFiltersPO: {
      ...defaultFilters,
      ...defaultPOFilters
    },
    prevSelectedFiltersPO: {
      ...defaultFilters,
      ...defaultPOFilters
    },
    prevSelectedFiltersGAC: {
      ...defaultFilters,
      ...defaultGACFilters
    },
    selectedFiltersGAC: {
      ...defaultFilters,
      ...defaultGACFilters
    },
    filterErrorPO: {},
    filterErrorGAC: {},
    POChartsId: '',
    GACChartsId: ''
  };
}

export const initialState = createInitialState();

function createReducers() {
  // UPDATE_OPERATIONAL_METRIC_PO__SELECTED_FILTER_VALUES
  function updateOperationMetricsPOSelectedFilterValues(state: Object, action: Object) {
    const payload = {
      filter: action.payload.filter,
      values: action.payload.values
    };

    if (!payload.filter) return { ...state };
    const modifiedSelectedFilters = {
      ...state.selectedFiltersPO,
      [payload.filter]: payload.values
    };
    return {
      ...state,
      selectedFiltersPO: modifiedSelectedFilters,
      poChartsId: cryptoRand().toString().substr(2, 8)
    };
  }

  // UPDATE_OPERATIONAL_METRIC_GAC__SELECTED_FILTER_VALUES
  function updateOperationMetricsGACSelectedFilterValues(state: Object, action: Object) {
    const payload = {
      filter: action.payload.filter,
      values: action.payload.values
    };

    if (!payload.filter) return { ...state };
    const modifiedSelectedFilters = {
      ...state.selectedFiltersGAC,
      [payload.filter]: payload.values
    };
    return {
      ...state,
      selectedFiltersGAC: modifiedSelectedFilters,
      gacChartsId: cryptoRand().toString().substr(2, 8)
    };
  }

  // UPDATE_CHART_DATA
  function updateChartData(state: Object, action: Object) {
    return {
      ...state,
      [action.payload.chart]: action.payload.data
    };
  }

  // UPDATE_CHART_FAILURE
  function updateChartFailure(state: Object, action: Object) {
    return {
      ...state,
      [`${action.payload.chart}_failed`]: action.payload.data
    };
  }

  // UPDATE_CHART_LOADING
  function updateChartLoading(state: Object, action: Object) {
    const payload = {
      chart: action.payload.chart,
      data: action.payload.data
    };
    return {
      ...state,
      [`${payload.chart}_loading`]: payload.data
    };
  }

  // UPDATE_CHART_ITEM_VIEW
  function updateChartItemView(state: Object, action: Object) {
    return {
      ...state,
      itemViewStatus: action.payload
    };
  }

  // UPDATE_CHART_TABLE_VIEW
  function updateChartTableView(state: Object, action: Object) {
    return {
      ...state,
      tableViewStatus: {
        ...state.tableViewStatus,
        [action.payload.tab]: {
          ...state.tableViewStatus[action.payload.tab],
          [action.payload.chart]: action.payload.value
        }
      }
    };
  }

  // UPDATE_CHART_TAB
  function updateChartTab(state: Object, action: Object) {
    return {
      ...state,
      currentTab: action.payload
    };
  }

  // RESET_METRICS_FILTERS
  function resetMetricsSearch(state: Object, action: Object) {
    return {
      ...state,
      [`selectedFilters${action.payload}`]: { ...initialState[`selectedFilters${action.payload}`] },
      [`prevSelectedFilters${action.payload}`]: { ...initialState[`prevSelectedFilters${action.payload}`] },
      [`filterError${action.payload}`]: { ...initialState[`filterError${action.payload}`] },
      [`${action.payload}ChartsId`]: ''
    };
  }

  // UPDATE_OPERATIONAL_METRICS_PREV_SEARCH
  function updateOMPreviousSelectedFiltersForPO(state: Object, action: Object) {
    return {
      ...state,
      prevSelectedFiltersPO: action.payload
    };
  }
  function updateOMPreviousSelectedFiltersForGAC(state: Object, action: Object) {
    return {
      ...state,
      prevSelectedFiltersGAC: action.payload
    };
  }

  // UPDATE_OPERATIONAL_METRIC_PO_ERROR
  // UPDATE_OPERATIONAL_METRIC_GAC_ERROR
  function updateOperationMetricsPOError(state: Object, action: Object) {
    if (action.payload.tab === 'PO') {
      return {
        ...state,
        filterErrorPO: { ...state.filterErrorPO, [action.payload.field]: action.payload.error }
      };
    }
    return {
      ...state,
      filterErrorGAC: { ...state.filterErrorGAC, [action.payload.field]: action.payload.error }
    };
  }

  return {
    updateOperationMetricsPOSelectedFilterValues,
    updateOperationMetricsGACSelectedFilterValues,
    updateChartData,
    updateChartFailure,
    updateChartLoading,
    updateChartItemView,
    updateChartTableView,
    updateChartTab,
    resetMetricsSearch,
    updateOMPreviousSelectedFiltersForPO,
    updateOMPreviousSelectedFiltersForGAC,
    updateOperationMetricsPOError
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async (
        {
          payload,
          tab,
          callback
        },
        { getState, dispatch }
      ) => {
        const state = getState();
        const selectedFilters = state.OperationalMetrics[`selectedFilters${tab}`];
        const selectedFilter = selectedFilters[payload.filter];
        const oldValue = JSON.stringify(selectedFilter);
        const newValue = JSON.stringify(payload.values);
        const hasChanged = oldValue !== newValue;
        if (!hasChanged) {
          return false;
        }
        if (tab === 'PO') {
          dispatch(actions.updateOperationMetricsPOSelectedFilterValues(payload));
        } else if (tab === 'GAC') {
          dispatch(actions.updateOperationMetricsGACSelectedFilterValues(payload));
        }
        if (callback) {
          callback();
        }
        return true;
      }
    );
  }

  function addValueToFilters() {
    return createAsyncThunk(
      `${name}/addValueToFilters`,
      async (
        {
          payload,
          tab,
          callback
        },
        { getState, dispatch }
      ) => {
        const state = getState();
        const selectedFilters = state.OperationalMetrics[`selectedFilters${tab}`];
        const filterValues = selectedFilters[payload.filter] || [];
        const newPayload = { ...payload, values: [...filterValues, payload.value] };
        if (tab === 'PO') {
          dispatch(actions.updateOperationMetricsPOSelectedFilterValues(newPayload));
        } else {
          dispatch(actions.updateOperationMetricsGACSelectedFilterValues(newPayload));
        }
        if (callback) {
          callback();
        }
      }
    );
  }
  function updateOperationalMetricsPreviousSearch() {
    return createAsyncThunk(
      `${name}/updateOperationalMetricsPreviousSearch`,
      async (
        {
          tab,
          callback
        },
        { getState, dispatch }
      ) => {
        const state = getState();
        let currentFilters;
        let prevFilters;
        if (tab === 'PO') {
          currentFilters = state.OperationalMetrics.selectedFiltersPO;
          prevFilters = state.OperationalMetrics.prevSelectedFiltersPO;
        } else {
          currentFilters = state.OperationalMetrics.selectedFiltersGAC;
          prevFilters = state.OperationalMetrics.prevSelectedFiltersGAC;
        }
        const hasChanged = JSON.stringify(currentFilters) !== JSON.stringify(prevFilters);
        if (hasChanged) {
          if (tab === 'PO') {
            dispatch(actions.updateOMPreviousSelectedFiltersForPO(currentFilters));
          } else {
            dispatch(actions.updateOMPreviousSelectedFiltersForGAC(currentFilters));
          }
        }
        callback(hasChanged);
      }
    );
  }

  function callMetricsChartDataAPI() {
    return createAsyncThunk(
      `${name}/callMetricsChartDataAPI`,
      async ({
        chart,
        search,
        callback
      }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'metricsChartData',
          method: 'post',
          cancellable: true,
          suppressErrorDialog: true,
          data: {
            graph: chart,
            search
          }
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          dispatch(actions.updateChartLoading({ chart, data: false }));
          let data = response.data[chart];
          if (data && (chart === 'rejectedPOCount' || chart === 'rejectedPOItemQtySum')) {
            data = data.map((rejectionData) => (
              {
                ...rejectionData,
                poRejectionCode: `${rejectionData.poRejectionCode} | ${rejectionData.poRejectionCodeDescription}`
              }
            ));
          }
          dispatch(actions.updateChartData({ chart, data }));
          if (callback) {
            callback(response.data);
          }
        }).catch((error) => {
          if (callback) {
            callback(null, error);
          }
          dispatch(toggleSpinner(false));
          dispatch(actions.updateChartLoading({ chart, data: false }));
          dispatch(actions.updateChartFailure({ chart, data: true }));
        });
      }
    );
  }

  function requestChartData() {
    return createAsyncThunk(
      `${name}/requestChartData`,
      async (
        {
          chart, tab, forceLoad, newRequest, callback, OMCallMetricsChartDataAPI
        },
        { getState, dispatch }
      ) => {
        //   dispatch(poSearchfetchPoSearchResultsNextSet());
        const state = getState();
        const searchData = state.OperationalMetrics[newRequest ? `selectedFilters${tab}` : `prevSelectedFilters${tab}`];
        const search = mapFiltersWithDatesToRequest(searchData, 'operationalMetrics');
        // This has been purposefully done for Open PO's
        if (chart === 'modeOfTransportationQtySum' || chart === 'modeOfTransportationCount'
          || chart === 'manualVsSystemPoCount' || chart === 'manualVsSystemQtySum'
          || chart === 'gacDateCount' || chart === 'gacDateQtySum'
        ) {
          search.push(
            {
              fieldName: 'dpomItemStatus',
              operator: '=',
              fieldValue: [
                'Rejected', 'Accepted', 'Unissued', 'Unaccepted'
              ]
            }
          );
        }
        const chartData = state.OperationalMetrics[chart];
        const errorState = state.OperationalMetrics.filterErrorPO;
        const hasError = Object.keys(errorState).find((filter) => errorState[filter]);

        if ((chartData && !forceLoad) || hasError) {
          if (callback) {
            callback(chartData);
          }
          return false;
        }
        dispatch(actions.updateChartFailure({ chart, data: false }));
        dispatch(actions.updateChartLoading({ chart, data: true }));
        dispatch(OMCallMetricsChartDataAPI({
          chart, search, callback
        }));
        return true;
      }
    );
  }

  return {
    applyFilters: applyFilters(),
    addValueToFilters: addValueToFilters(),
    updateOperationalMetricsPreviousSearch: updateOperationalMetricsPreviousSearch(),
    callMetricsChartDataAPI: callMetricsChartDataAPI(),
    requestChartData: requestChartData()
  };
}
export const extraActions = createExtraActions();

export const operationalMetricsActions = { ...actions, ...extraActions };
export const operationalMetricsReducer = slice.reducer;
