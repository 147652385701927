import fileDownloadPOPDFTableFieldMapping from '../fieldMappings/fileDownloadPOPDFTableFieldMapping';

export default {
  [fileDownloadPOPDFTableFieldMapping.PO_PDF_EVENTCREATETS]: {
    primary: fileDownloadPOPDFTableFieldMapping.PO_PDF_EVENTCREATETS, secondary: '', type: 'datetime', link: false, label: 'Request Submitted', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fileDownloadPOPDFTableFieldMapping.PO_PDF_EVENTSTATUS]: {
    primary: fileDownloadPOPDFTableFieldMapping.PO_PDF_EVENTSTATUS, secondary: '', type: 'string', link: false, label: 'Status', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fileDownloadPOPDFTableFieldMapping.PO_PDF_ERRORSFOUNDCOUNT]: {
    primary: fileDownloadPOPDFTableFieldMapping.PO_PDF_ERRORSFOUNDCOUNT, secondary: '', type: 'numeric', link: false, label: 'Errors Found1233', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fileDownloadPOPDFTableFieldMapping.PO_PDF_TOTALERRORCOUNT]: {
    primary: fileDownloadPOPDFTableFieldMapping.PO_PDF_TOTALERRORCOUNT, secondary: '', type: 'numeric', link: false, label: 'Errors Found', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fileDownloadPOPDFTableFieldMapping.PO_PDF_EVENTERRORLIST]: {
    primary: fileDownloadPOPDFTableFieldMapping.PO_PDF_EVENTERRORLIST, secondary: '', type: 'string', link: false, label: 'Action', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fileDownloadPOPDFTableFieldMapping.PO_PDF_PDFDOWNLOADLIST]: {
    primary: fileDownloadPOPDFTableFieldMapping.PO_PDF_PDFDOWNLOADLIST, secondary: '', type: 'string', link: false, label: 'Download Link', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fileDownloadPOPDFTableFieldMapping.PO_PDF_TOTALRECEIVEDITEMCOUNT]: {
    primary: fileDownloadPOPDFTableFieldMapping.PO_PDF_TOTALRECEIVEDITEMCOUNT, secondary: '', type: 'numeric', link: false, label: 'Received PDFs', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  [fileDownloadPOPDFTableFieldMapping.PO_PDF_PONUMBER]: {
    primary: fileDownloadPOPDFTableFieldMapping.PO_PDF_PONUMBER, secondary: '', type: 'string', link: false, label: 'PO Number', wrap: true, numeric: 'true', disablePadding: false
  },
  [fileDownloadPOPDFTableFieldMapping.PO_PDF_REASON]: {
    primary: fileDownloadPOPDFTableFieldMapping.PO_PDF_REASON, secondary: '', type: 'string', link: false, label: 'Failure Reason', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
