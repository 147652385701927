/**
 * spinner component
 * @exports NikeSpinner of @type {ReactComponent}
 * @author {Cognizant Technology Solution}
 * @flow
 */
import React from 'react';
import { DialogContent, Dialog } from '@mui/material';
import './styles.scss';
import NikeButton from '../nikeButton';
import imagesPath from '../../constants/imagesPath';
import messageText from '../../constants/messageText';

interface Props {
  onClose?: Function;
  open?: boolean;
  children?: any;
  className?: string;
  onCancel?: Function;
}

function NikeSpinner(props: Props) {
  const {
    onClose, open, children, className, onCancel
  } = props;

  const getCancelButton = () => (onCancel
    ? (
      <NikeButton
        buttonText={messageText.buttonText.btnCancel}
        onClick={onCancel}
        variant="rounded-black"
      />
    )
    : null
  );

  return (
    <Dialog
      classes={{
        paper: 'spinner-dialog'
      }}
      className={className}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      open={open}
    >
      <div className="nike-spinner-wrapper"><img src={imagesPath.spinner.src} alt={imagesPath.spinner.alt} /></div>
      <DialogContent>{children}</DialogContent>
      {getCancelButton()}
    </Dialog>
  );
}

export default NikeSpinner;
