const lblCurrentGACReasonCode = 'Current GAC Reason Code';
const lblCurrentGACDate = 'Current GAC Date';
const lblDocumentType = 'Document Type';
const lblSizeDescription = 'Size Description';
const lblProductCode = 'Product Code';
const MRGACDateValue = 'MRGAC Date';
const lblProposedGACdate = 'Proposed GAC Date';
const lblProposedGACReasonCode = 'Proposed GAC Reason Code';

export default {
  poNumber: {
    primary: 'poNumber', secondary: '', type: 'string', link: false, label: 'PO Number', wrap: true, numeric: 'true', disablePadding: false
  },
  itemNumber: {
    primary: 'itemNumber', secondary: '', type: 'string', link: false, label: 'PO Item Number', wrap: true, numeric: 'true', disablePadding: false
  },
  scheduleLineItemNumber: {
    primary: 'scheduleLineItemNumber', secondary: '', type: 'string', link: false, label: 'Schedule Line Number', wrap: true, numeric: 'true', disablePadding: false
  },
  sizeDescription: {
    primary: 'sizeDescription', secondary: '', type: 'string', link: false, label: lblSizeDescription, wrap: true, numeric: 'true', disablePadding: false
  },
  proposedGACDate: {
    primary: 'proposedGACDate', secondary: '', type: 'date', link: false, label: lblProposedGACdate, wrap: true, numeric: 'false', disablePadding: false
  },
  proposedGACCode: {
    primary: 'proposedGACCode',
    secondary: 'proposedGacReasonCodeDescription',
    type: 'string',
    link: false,
    label: lblProposedGACReasonCode,
    wrap: true,
    numeric: 'false',
    disablePadding: true
  },
  currentGACDate: {
    primary: 'currentGACDate', secondary: '', type: 'date', link: false, label: lblCurrentGACDate, wrap: true, numeric: 'true', disablePadding: false, group: false
  },
  currentGACReasonCode: {
    primary: 'currentGACReasonCode',
    secondary: 'currentGACReasonDescription',
    type: 'string',
    link: false,
    label: lblCurrentGACReasonCode,
    wrap: true,
    numeric: 'false',
    disablePadding: true
  },
  docType: {
    primary: 'docType', secondary: '', type: 'string', link: false, label: lblDocumentType, wrap: true, numeric: 'false', disablePadding: false
  },
  productCode: {
    primary: 'productCode', secondary: '', type: 'string', link: false, label: lblProductCode, wrap: true, numeric: 'true', disablePadding: false, group: false
  },
  mrgacDate: {
    primary: 'mrgacDate', secondary: '', type: 'date', link: false, label: MRGACDateValue, wrap: true, numeric: 'false', disablePadding: false, group: false
  },
  reasonForFailure: {
    primary: 'reasonForFailure', secondary: '', type: 'string', link: false, label: 'Reason for failure', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
