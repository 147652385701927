export default {
  eventCreateTS: {
    primary: 'eventCreateTS', secondary: '', type: 'date', link: false, label: 'Request Submitted', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  fileName: {
    primary: 'fileName', secondary: '', type: 'string', link: false, label: 'File Name', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventEntryPoint: {
    primary: 'eventEntryPoint', secondary: '', type: 'string', link: false, label: 'Entry Point', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventStatus: {
    primary: 'eventStatus', secondary: '', type: 'string', link: false, label: 'Status', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  receivedItems: {
    primary: 'receivedItems', secondary: '', type: 'numeric', link: false, label: 'Received Items', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  errorsFoundCount: {
    primary: 'errorsFoundCount', secondary: '', type: 'numeric', link: false, label: 'Errors Found', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  eventErrorList: {
    primary: 'eventErrorList', secondary: '', type: 'string', link: false, label: 'Action', wrap: true, numeric: 'false', disablePadding: false, group: true
  }
};
